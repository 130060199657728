import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Chip,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
    alpha,
    useMediaQuery,
    useTheme,
} from "@mui/material"
import { RemoveRedEyeRounded } from "@mui/icons-material"
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams, GridToolbar } from "@mui/x-data-grid"

import { ThemeDataTable } from "../../../components/theme-data-table-container"
import API from "../../../api"
import PageContainer from '../../../components/container/page-container'
import TitleBar from '../../../components/container/TitleBar'
import TableLoading from '../../../components/theme-table-container/TableLoading'
import NoDataView from '../../../components/theme-table-container/NoData-'
import { useNavigate } from 'react-router'
import ThemeDialog from '../../../components/dialog-box/theme-dialog'
import { Controller, useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { ThemeTextField } from '../../../components/inputs/theme-text-field'
import operatorIllustration from '../../../assets/images/illustartions/support.png'
import { useDispatch, useSelector } from 'react-redux'
import { updateProcessState } from '../../../slices/process-slice'
import toast from 'react-hot-toast'
import { useAllBatteries } from "../../../hooks/useAllBatteries";
import { RootState } from '../../../store/store'
import { User } from '../../../models/user'


export default function Batteries() {

    const theme = useTheme()
    const { fetchAllBatteries, fetchingAllBatteries, allBatteries } = useAllBatteries()
    const [battery, setBattery] = useState({})
    const [batteryDetailType, setBatteryDetailType] = useState<'create' | 'edit'>('create')
    const [batteryDetailDialogOpen, setBatteryDetailDialogOpen] = useState(false)
    const closeBatteryDetailDialog = () => {
        setBatteryDetailDialogOpen(false)
        setBatteryDetailType('create')
        setBattery({})
    }

    useEffect(() => {
        fetchAllBatteries()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Batteries'} secondChild={<CreateOptions
                closeDialog={closeBatteryDetailDialog}
                fetchAllBatteries={fetchAllBatteries}
                setBatteryDetailDialogOpen={setBatteryDetailDialogOpen} />} />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    '& .buttonGradient': {
                        backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        outline: `0px solid ${theme.palette.primary.main}`,
                        outlineOffset: 0,
                        boxShadow: 0,
                        textTransform: 'none',
                        transition: 'all 100ms ease',
                        '&:hover': {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: '2px',
                            '&:active': {
                                transform: 'scale(0.98) !important'
                            }
                        },
                    },

                }}>

                <BatteriesTable
                    data={allBatteries}
                    fetchingAllBatteries={fetchingAllBatteries}
                    setBattery={setBattery}
                    setBatteryDetailDialogOpen={setBatteryDetailDialogOpen}
                    setBatteryDetailType={setBatteryDetailType}
                />
            </Box>

            {batteryDetailDialogOpen &&
                <ThemeDialog
                    open={batteryDetailDialogOpen}
                    dialogBody={
                        <BatteryDetailForm
                            closeDialog={closeBatteryDetailDialog}
                            type={batteryDetailType}
                            battery={battery}
                            fetchAllBatteries={fetchAllBatteries}
                        />
                    } />
            }
        </PageContainer>
    )
}


const BatteriesTable = (props: any) => {

    const navigate = useNavigate()
    const { data, fetchingAllBatteries, setBattery, setBatteryDetailDialogOpen, setBatteryDetailType } = props
    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser

    const columns: GridColDef[] = [
        {
            field: 'battery_status', headerName: 'Battery Id', flex: 1, minWidth: 120,
            renderCell: (params: GridRenderCellParams) =>
                <Chip
                    size={'small'}
                    variant='outlined'
                    label={params.row?.battery_id}
                    color={params.row?.status == '1' ? 'success' : 'error'}
                    sx={{
                        fontSize: '12px',
                        lineHeight: '12px',
                        minWidth: '60px',
                        p: '2px',
                        height: '18px',
                        textTransform: 'capitalize',
                        boxShadow: `0 0 12px -4px currentcolor inset`,
                    }} />
        },
        {
            field: 'battery_id', headerName: 'Battery Id', flex: 1, minWidth: 120
        },
        { field: 'battery_code', headerName: 'Code', flex: 1, minWidth: 160 },
        {
            field: 'battery_type', headerName: 'Type', flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams) => (
                <span style={{ textTransform: 'capitalize' }}>{params.value}</span>
            )
        },
        {
            field: 'owned_by_name', headerName: 'Provider Name', flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams) => (
                <span style={{ textTransform: 'capitalize' }}>{params.value}</span>
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            type: 'actions',
            cellClassName: 'stickyRight',
            headerClassName: 'stickyLeft',
            getActions: (params: GridRowParams<any>) => [
                <Tooltip title={`View${params.row?.id}`} arrow>
                    <GridActionsCellItem
                        icon={<RemoveRedEyeRounded />}
                        label="View Farmer"
                        onClick={() => {
                            if (currentUser?.provider_id != params.row?.owned_by_id) {
                                toast.error(`Sorry, Not owned by you.`)
                            } else {
                                if (params.row?.status == '1') {
                                    setBattery(params.row)
                                    setBatteryDetailDialogOpen(true)
                                    setBatteryDetailType('edit')
                                } else {
                                    toast.error('Can not view inactive battery.')
                                }
                            }
                        }}
                    />
                </Tooltip>,
            ],
        },
    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetchingAllBatteries}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            columnVisibilityModel={{
                battery_id: false,
            }} slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}

const CreateOptions = (props: any) => {

    const { closeDialog, fetchAllBatteries, setBatteryDetailDialogOpen } = props

    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <Button
                id="basic-button"
                variant='contained'
                className={'buttonGradient animate__animated animate__fadeInUp'}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                Add New
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    setBatteryDetailDialogOpen(true)
                    handleClose()
                }}>Create</MenuItem>
                <MenuItem onClick={handleClose}>Import</MenuItem>
            </Menu>
        </div >
    )
}


const ImportFormDialog = () => {

    return (
        <Box>
        </Box>
    )
}


const BatteryDetailForm = (props: any) => {

    const { closeDialog, type, battery, fetchAllBatteries } = props

    const theme = useTheme()
    const dispatch = useDispatch()
    const [batteryStatus, setBatteryStatus] = useState<string>(battery?.status == '1' ? 'active' : 'inactive')


    const { control, handleSubmit, setError, register, reset, formState: { errors } } = useForm({
        defaultValues: {
            battery_id: battery?.battery_id ?? '',
            battery_code: battery?.battery_code ?? '',
            battery_type: battery?.battery_type ?? '',
        }
    })

    const [submitting, setSubmitting] = useState<boolean>(false)

    const onSubmit = (data: any) => {
        dispatch(updateProcessState(true))
        setSubmitting(true)
        console.log(data)
        if (type == 'create') {
            // console.log(data.mfg_year)
            API.post('/submit_battery_details', { ...data })
                .then((res) => {
                    if (res.data?.status == 'success') {
                        toast.success('Battery created successfully!')
                        reset()
                        closeDialog()
                        fetchAllBatteries()
                    }
                    if (res.data?.status == 'error') {
                        toast.error(`Error! \n ${res.data?.msg}`)
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    setSubmitting(false)
                    dispatch(updateProcessState(false))
                })
        } else if (type == 'edit') {
            const newData = { ...data, battery_id: battery?.battery_id, status: (batteryStatus == 'active') ? '1' : '0' }
            API.post('/edit_battery_details', { ...newData })
                .then((res) => {
                    if (res.data?.status == 'success') {
                        toast.success('Battery updated successfully!')
                        reset()
                        closeDialog()
                        fetchAllBatteries()
                    }
                    if (res.data?.status == 'error') {
                        toast.error(`Error! \n ${res.data?.msg}`)
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    setSubmitting(false)
                    dispatch(updateProcessState(false))
                })
        }
    }

    return (
        <Box sx={{
            minWidth: '280px',
            width: { xs: '100%', md: '70vw' },
            maxWidth: '650px',
            minHeight: '200px',
            '& .boxTitle': {
                fontWeight: 500,
                fontSize: '18px',
                mb: 3,
            },
            '& .formInput': {
                flex: '1 1 260px',
            },
        }}>

            <Typography className={'boxTitle'}>
                {type == 'edit'
                    ? 'Update Battery'
                    : 'Create Battery'
                }
            </Typography>

            <Box component={'form'}
                sx={{
                    '& .actionBox': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        mt: 3,
                        '& .MuiButton-root': {
                            textTransform: 'none',
                            minWidth: { xs: 'auto', md: '150px' },
                            flex: { xs: 1, md: 0 },
                            '&.buttonGradient': {
                                backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                outline: `0px solid ${theme.palette.primary.main}`,
                                outlineOffset: 0,
                                boxShadow: 0,
                                transition: 'all 100ms ease',
                                '&:hover': {
                                    outline: `2px solid ${theme.palette.primary.main}`,
                                    outlineOffset: '2px',
                                    '&:active': {
                                        transform: 'scale(0.98) !important'
                                    }
                                },
                            },
                        },
                    },
                }}>



                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 3,
                    mx: { xs: 0, sm: 3, md: 5 },
                }}>

                    <Box sx={{
                        flex: '1 1 300px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        '& img': {
                            objectFit: 'contain',
                            maxHeight: '120px',
                            maxWidth: '90%',
                        },
                        '& .assetDetail': {
                            flex: '1 1 200px',
                            display: 'flex',
                            flexFlow: 'column',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            '& .detail': {
                                fontSize: "14px",
                                fontWeight: 500,
                                fontFamily: theme.typography.h1.fontFamily,
                                color: theme.palette.text.secondary,
                            },

                        },
                    }}>
                        <img src={operatorIllustration} alt={'battery Illustration'} />
                        {type == 'edit' &&
                            <Box className={'assetDetail'}>
                                <Box sx={{
                                    boxShadow: `0 0 12px -3px #83838390`,
                                    borderRadius: '16px',
                                    p: '4px',
                                    textAlign: 'center',
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    width: '100px',
                                    mb: 1,
                                    '& .MuiInput-root': {
                                        color: theme.palette.primary.contrastText,
                                        background: batteryStatus == 'active'
                                            ? `${alpha(theme.palette.success.main, 1)} !important`
                                            : `${alpha(theme.palette.error.main, 1)} !important`,
                                        borderRadius: '12px',
                                        '&:before, &:after': {
                                            display: 'none',
                                        },
                                        '& .MuiSelect-select': {
                                            borderRadius: '12px',
                                            padding: '2px 16px 4px 12px',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            fontWeight: 500,
                                        },
                                    },

                                }}>
                                    <TextField
                                        required select variant="standard" value={batteryStatus}
                                        onChange={(e) => setBatteryStatus(e.target.value)}
                                        size={'small'} placeholder={'Choose option'}
                                        sx={{ flex: 1 }}
                                    >
                                        <MenuItem key={'active'} value={'active'}>Active</MenuItem>
                                        <MenuItem key={'inactive'} value={'inactive'}>In-active</MenuItem>
                                    </TextField>
                                </Box>
                                <Typography className={'detail'}>Battery Id : <span>{battery?.battery_id}</span></Typography>
                            </Box>
                        }
                    </Box>
                    <Box sx={{
                        flex: '1 1 300px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        columnGap: 3,
                    }}>
                        {type == 'create' && <Controller name={`battery_id`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.battery_id)} type={'number'}
                                    helperText={(errors?.battery_id?.message ?? '').toString()}
                                    size={'small'} label={'Battery Id'}
                                    placeholder={'eg 20-A'}
                                />
                            )} />}
                        <Controller name={`battery_code`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.battery_code)}
                                    helperText={(errors?.battery_code?.message ?? '').toString()}
                                    size={'small'} label={'Serial Number'}
                                    placeholder={'eg. XXXXXXXXXXXXX'}
                                />
                            )} />
                        <Controller name={`battery_type`}
                            control={control}
                            rules={{
                                required: { value: true, message: 'Required' }
                            }}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.battery_type)}
                                    helperText={(errors?.battery_type?.message ?? 'Required').toString()}
                                    size={'small'} label={'Battery Type'}
                                    placeholder={'select type'} select
                                >
                                    <MenuItem value="li-ion">Li-ion</MenuItem>
                                    <MenuItem value="alkaline">Alkaline</MenuItem>
                                </ThemeTextField>
                            )} />
                    </Box>
                </Box>



                <Box className={'actionBox'}>
                    <Button className={''} variant={'outlined'} onClick={closeDialog}>Discard & Close</Button>
                    <LoadingButton loading={submitting} endIcon={<></>} loadingPosition={'end'}
                        className={'buttonGradient'} variant={'contained'}
                        onClick={handleSubmit(onSubmit)}>
                        {type.type == 'create' ? 'Save & Create' : 'Save & Update'}
                    </LoadingButton>
                </Box>
            </Box>
        </Box>
    )
}