import { Autocomplete, Box, IconButton, alpha, useMediaQuery, useTheme } from '@mui/material';
import { GridColDef, GridToolbar } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react'
import { ThemeDataTable } from '../../components/theme-data-table-container';
import TableLoading from '../../components/theme-table-container/TableLoading';
import NoDataView from '../../components/noDataView';
import TitleBar from '../../components/container/TitleBar';
import PageContainer from '../../components/container/page-container';
import API from '../../api';
import { Sync } from '@mui/icons-material';
import { useDocumentTitle } from '../../hooks/useDocumnetTitle';
import moment, { Moment } from 'moment';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ThemeTextField, themeTextFieldSx } from '../../components/inputs/theme-text-field';
import { Operator } from '../../models/operator';
import { useAssets } from '../../hooks/useAssets';

interface FilterOptionInterface {
    from: Moment | null,
    to: Moment | null,
    operator: null | Operator,
    asset: null | any,
}

export default function AssetSprayReport() {

    useDocumentTitle('Asset Spray Report')

    const theme = useTheme()
    const [assetSprayData, setAssetSprayData] = useState<any[]>([])
    const [fetching, setFetching] = useState<boolean>(true)
    const [operators, setOperators] = useState<Operator[]>([])
    const { assets, fetchAssets } = useAssets()

    const [filterOptions, setFilterOptions] = useState<FilterOptionInterface>({
        from: moment().subtract(1, 'days'),
        to: moment(),
        operator: null,
        asset: null,
    })

    const fetchAssetSprayReport = useCallback(() => {
        setFetching(true)
        API.post('/daily_drone_data_api', {
            from: moment(filterOptions.from).format('YYYY-MM-DD'),
            to: moment(filterOptions.to).format('YYYY-MM-DD'),
            ...(filterOptions.operator ? { operator_id: filterOptions.operator?.id } : []),
            ...(filterOptions.asset ? { asset_id: filterOptions.asset?.id } : []),
        })
            .then((res) => {
                setAssetSprayData(res.data?.data)
            })
            .catch((err) => console.error(err))
            .finally(() => setFetching(false))
    }, [filterOptions])

    const fetchOperators = useCallback(() => {
        API.get('/fetch_operators_to_assign', { headers: { "Accept": "application/json" } })
            .then((res) => {
                res.data.status == 'success' &&
                    setOperators(res.data?.data)
            })
            .catch((err) => console.log(err))
            .finally(() => { })
    }, [])

    useEffect(() => {
        fetchAssetSprayReport()
        fetchOperators()
        fetchAssets()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Asset Spray Report'} />

            <Box
                className={'flexBottomCenter'}
                sx={{
                    flex: '1 0 450px',
                    flexWrap: 'wrap',
                    gap: 1,
                    '& .formInput': {
                        minWidth: '110px',
                        maxWidth: '110px'
                    },
                }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDatePicker
                        disableFuture
                        label="From"
                        format='DD-MM-YYYY'
                        className='formInput'
                        value={filterOptions.from}
                        onChange={(newValue) => setFilterOptions({ ...filterOptions, from: newValue })}
                        slotProps={{
                            textField: {
                                size: 'small',
                                required: true,
                                sx: themeTextFieldSx,
                            }
                        }}
                    />
                    <MobileDatePicker
                        disableFuture
                        label="To"
                        format='DD-MM-YYYY'
                        className='formInput'
                        value={filterOptions.to}
                        onChange={(newValue) => setFilterOptions({ ...filterOptions, to: newValue })}
                        slotProps={{
                            textField: {
                                size: 'small',
                                required: true,
                                sx: themeTextFieldSx,
                            }
                        }}
                    />
                </LocalizationProvider>

                <Autocomplete
                    options={assets}
                    autoHighlight
                    value={filterOptions.asset}
                    onChange={(e, data) => setFilterOptions({ ...filterOptions, asset: data })}
                    sx={{ width: '150px' }}
                    getOptionLabel={(option: any) => `${option.asset_id}`}
                    renderOption={(props, option: any) => (
                        <Box component="li" {...props} sx={{
                            fontSize: '14px',
                            fontWeight: 600,
                        }}>
                            {option.asset_id}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <ThemeTextField
                            {...params}
                            size={'small'} label={'Asset'}
                            placeholder={'eg. AWD-01'}
                            sx={{ minHeight: 'max-content' }}
                        />
                    )}
                />
                <Autocomplete
                    options={operators}
                    autoHighlight
                    value={filterOptions.operator}
                    onChange={(e, data) => setFilterOptions({ ...filterOptions, operator: data })}
                    sx={{ width: '150px' }}
                    getOptionLabel={(option: Operator) => `${option.code}`}
                    renderOption={(props, option: Operator) => (
                        <Box component="li" {...props} sx={{
                            fontSize: '14px',
                            fontWeight: 600,
                        }}>
                            {option.code}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <ThemeTextField
                            {...params}
                            size={'small'} label={'Operator'}
                            placeholder={'eg. Amit Thakur'}
                            sx={{ minHeight: 'max-content' }}
                        />
                    )}
                />

                <IconButton
                    onClick={fetchAssetSprayReport}
                    size='small'
                    sx={{
                        mr: 2,
                        background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                        boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                        border: `1px solid ${theme.palette.background.paper}`,
                    }}>
                    <Sync />
                </IconButton>
            </Box>

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                }}>

                <AssetsTable data={assetSprayData} fetching={fetching} />
            </Box>
        </PageContainer >
    )
}

const AssetsTable = (props: any) => {

    const { data, fetching } = props
    const theme = useTheme()

    const columns: GridColDef[] = [
        { field: 'date', headerName: 'Date', width: 110, valueFormatter: ({ value }) => value ? moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-' },
        { field: 'agriwings_asset_id', headerName: 'Asset Code', width: 120, valueFormatter: ({ value }) => value ? value?.toUpperCase() : '-' },
        { field: 'asset_uin', headerName: 'Asset UIN', flex: 1, minWidth: 160, valueFormatter: ({ value }) => value ? value?.toUpperCase() : '' },
        { field: 'operator_code', headerName: 'Operator Code', flex: 1, minWidth: 120, valueFormatter: ({ value }) => value != '0' ? value?.toUpperCase() : '-' },
        { field: 'operator_name', headerName: 'Operator Name', flex: 1, minWidth: 160, valueFormatter: ({ value }) => value != '0' ? value?.toUpperCase() : '-' },
        { field: 'manual_sprayed_acerage', headerName: 'Manual Sprayed (Acre)', flex: 1, minWidth: 100 },
        { field: 'drone_api_sprayed_acerage', headerName: 'Drone Sprayed', flex: 1, minWidth: 100 },
    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetching}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}


