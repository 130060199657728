import {
    Box,
    Button,
    Typography,
    alpha,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ArrowRightIcon, CalendarIcon } from "../../../../components/icons";
import moment from "moment";
import { FormBlockTitle } from "../../../../components/title/FormBlockTitle";
import MeetingItem from "./meeting-item";
import toast from "react-hot-toast";
import API from "../../../../api";
import { useNavigate } from "react-router";
import { MeetingDetailsDrawer } from "./meeting-details-drawer";
import NoDataView from "../../../../components/noDataView";

const Meeting = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const [selectedMeeting, setSelectedMeeting] = useState({} as any);

    const [todaysMeetings, setTodaysMeetings] = useState<any[]>([]);
    const [fetchingTodaysMeetings, setFetchingTodaysMeetings] = useState(false);

    const fetchTodaysMeetings = () => {
        setFetchingTodaysMeetings(true)
        API.get(
            `/meeting-details-list?from_date=${moment().format("YYYY-MM-DD")}&to_date=${moment().format("YYYY-MM-DD")}`
        ).then((res) => {
            if (res.data?.status == "success") {
                setTodaysMeetings(res.data?.data)
            } else {
                toast.error(res.data?.message);
            }
        })
            .catch((err) => console.error(err))
            .finally(() => {
                setFetchingTodaysMeetings(false)
            })
    };

    useEffect(() => {
        fetchTodaysMeetings();
    }, []);

    return (
        <React.Fragment>
            <Box
                sx={{
                    minHeight: "75svh",
                    display: "flex",
                    flexFlow: "column",
                    gap: 3,
                    maxWidth: "550px",
                    mx: "auto",
                }}
            >
                <Box
                    className={"animate__animated animate__fadeIn"}
                    sx={{
                        borderRadius: "32px",
                        position: "relative",
                        display: "flex",
                        flexFlow: "column",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        boxShadow: `0 0 9px -3px ${alpha(
                            theme.palette.primary.main,
                            0.6
                        )}, 0 0 69px -3px ${alpha(theme.palette.primary.main, 0.5)} inset`,
                        p: 2,
                        minHeight: "140px",
                        border: `3px solid ${theme.palette.background.default}`,
                        gap: 2,
                        "& .MuiTypography-root": {
                            fontFamily: theme.typography.h1.fontFamily,
                            color: theme.palette.secondary.main,
                            fontSize: theme.typography.pxToRem(24),
                            lineHeight: theme.typography.pxToRem(24),
                            fontWeight: 600,
                        },
                        "& .dateBlock": {
                            borderRadius: "24px",
                            p: "4px",
                            background: alpha(theme.palette.primary.main, 0.4),
                            boxShadow: "0 2px 12px -3px #83838380",
                            minWidth: "100px",
                            "& .month": {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "4px",
                                fontSize: theme.typography.pxToRem(13),
                                "& svg": {
                                    height: "16px",
                                    width: "16px",
                                },
                            },
                            "& .day": {
                                background: theme.palette.background.default,
                                borderRadius: "18px",
                                fontSize: theme.typography.pxToRem(24),
                                fontWeight: 600,
                                display: "grid",
                                placeItems: "center",
                                minHeight: "50px",
                            },
                        },
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 1,
                        }}
                    >
                        <Box>
                            <Typography className={"title animate__animated animate__fadeInUp"}>
                                Meetings
                            </Typography>
                            <Button
                                onClick={() => navigate('/activity/meeting/list')}
                                size={'small'}
                                sx={{
                                    background: alpha(theme.palette.primary.main, 0.1),
                                    fontSize: '10px',
                                    fontWeight: 600,
                                    p: '2px 2px 2px 12px',
                                    mt: 1,
                                    '& svg': {
                                        height: '14px',
                                    },


                                }}>
                                View All <ArrowRightIcon />
                            </Button>
                        </Box>

                        <Box className={"dateBlock"}>
                            <Box className={"month"}>
                                <CalendarIcon /> {moment().format("MMMM")}
                            </Box>
                            <Box className={"day"}>{moment().format("DD")}</Box>
                        </Box>
                    </Box>

                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => navigate("create")}
                    >
                        Add Meeting
                    </Button>
                </Box>

                <FormBlockTitle title={`Today's`} icon={false} />
                {fetchingTodaysMeetings
                    ?
                    <>
                        {
                            Array.from(Array(4)).map((_, i) => (
                                <Box
                                    key={i}
                                    className={'animate__animated animate__fadeIn'}
                                    sx={{
                                        minHeight: '80px',
                                        background: alpha(theme.palette.text.disabled, 0.1),
                                        borderRadius: '18px',
                                    }} />
                            ))
                        }

                    </>
                    : todaysMeetings?.length > 0
                        ? todaysMeetings?.map((meeting: any, i: number) => (
                            <MeetingItem
                                key={i}
                                data={meeting}
                                handleClick={() => {
                                    setSelectedMeeting(meeting);
                                }}
                            />
                        ))
                        : <NoDataView />
                }
            </Box>

            <MeetingDetailsDrawer
                open={!!selectedMeeting?.meeting_details?.id}
                data={selectedMeeting}
                handleClose={() => setSelectedMeeting({} as any)}
            />
        </React.Fragment>
    );
}


export default Meeting;