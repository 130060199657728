import React, { useEffect } from "react"
import { Outlet, Navigate, useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import API from "../api"
import { updateAuth } from "../slices/auth-slice"
import { User } from "../models/user";
import { getCookieValue } from "../utils/app-helper";
import toast from "react-hot-toast"
import { RootState } from "../store/store"
import axios from "axios"


export default function ProtectedRouteAuthCheck() {

    const apiKey = 'secretlesson' //process.env.REACT_APP_API_KEY
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const location = useLocation()
    const accessToken = getCookieValue('accessToken')
    const user = useSelector((state: RootState) => state.userAuth)


    API.defaults.headers.common['Content-Type'] = `application/json`
    API.defaults.headers.common['x-api-key'] = btoa(`secretlesson`)
    API.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
    API.defaults.headers.common['crossorigin'] = `anonymous`
    API.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

    API.interceptors.response.use(
        function (response) {
            if (response.data.statuscode == 401) {
                document.cookie = `accessToken=`;
                dispatch(updateAuth({ isLoggedIn: false, currentUser: {} as User }));
                navigate("/auth");
            }
            return response
        }, function (error) {
            if (error.toJSON().message === 'Network Error') {
                toast.error('No Internet connection.')
                return
            }
            else if (error.message.includes('net::ERR_NETWORK_CHANGED')) {
                toast.error('Network Error, Please try again.')
                console.error('Network changed error occurred:', error.message);
            } else if (axios.isCancel(error)) {
                toast.error('Session closed')
                navigate("/auth");
                return
            } else {
                toast.error('Something bad at our end.')
            }
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error)
        }
    )



    useEffect(() => {

    }, [location])

    return (
        accessToken
            ? <Outlet />
            : <Navigate to={'/auth'} state={{ fromLocation: location }} replace />
    )
}