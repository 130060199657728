import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {PaletteMode, useMediaQuery} from "@mui/material";


interface drawerState {
    open: boolean
}

const smallScreen = window.innerWidth < 962

const initialState: drawerState = {open: smallScreen ? false : true}

export const drawerStateSlice = createSlice({
    name: 'drawerState',
    initialState,
    reducers: {
        updateDrawerState: (state, action: PayloadAction<boolean>) => {
            state.open = action.payload
        },
    },
})

export const {updateDrawerState} = drawerStateSlice.actions

export default drawerStateSlice.reducer