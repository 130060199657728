import React from 'react'
import { Box, ButtonBase, Chip, Typography, alpha, useTheme } from '@mui/material'
import { CalendarIcon, LocationIcon } from '../../../../components/icons'
import moment from 'moment'

function MeetingItem(props: any) {

    const { handleClick, data } = props

    const theme = useTheme()

    return (
        <ButtonBase
            onClick={handleClick}
            sx={{
                width: '100%',
                px: 1.5, py: 0.5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                gap: 0.5,
                borderBottom: `1px solid ${theme.palette.mode == 'dark' ? alpha(theme.palette.text.secondary, 0.2) : alpha(theme.palette.text.disabled, 0.05)}`,
                mb: 1,
                borderRadius: '18px',
                transition: 'transform 200ms ease-in-out',
                '&:last-of-type': {
                    borderBottom: 'none',
                },
                '&:active': {
                    boxShadow: '0 2px 12px -3px #83838380',
                    borderBottom: 'none',
                    transform: 'scale(0.98)',
                },

            }}>
            <Box className={'flexBetweenCenter'}
                sx={{
                    minWidth: '100%',
                    gap: 0.5,
                    '& .ctgry': {
                        fontSize: theme.typography.pxToRem(14),
                        color: theme.palette.primary.main,
                        textAlign: 'left',
                    },
                    '& .MuiChip-root': {
                        fontSize: '12px',
                        height: '20px',
                    },
                }}>
                <Typography className={'ctgry line-clamp clamp-1'}>
                    {data?.meeting_details?.meeting_type}
                </Typography>
                <Chip size={'small'} sx={{ minWidth: '90px' }} label={`ID: ${data?.meeting_details?.id}`} />

            </Box>

            <Box
                className={'flexBetweenTop'}
                sx={{
                    background: theme.palette.mode == 'dark' ? alpha(theme.palette.text.secondary, 0.08) : alpha(theme.palette.primary.main, 0.08),
                    p: 1,
                    borderRadius: '18px',
                    width: '100%',
                    gap: 1,
                }}>

                <Box
                    sx={{
                        display: 'grid',
                        gap: 0.25,
                        '& span': {
                            gap: 0.5,
                            textAlign: 'left',
                            '& svg': {
                                minHeight: '16px',
                                minWidth: '16px',
                                height: '16px',
                                width: '16px',
                            },
                        },

                    }}>
                    <span className={'flexStartCenter'}><CalendarIcon /> {moment(data?.meeting_details?.meeting_date).format('DD MMM YYYY')}</span>
                    <span className={'flexStartCenter'}><LocationIcon />
                        <span className={'line-clamp clamp-2'} style={{ flex: 1 }}>
                            {data?.meeting_details?.village}, {data?.meeting_details?.sub_district}, {data?.meeting_details?.district}
                        </span>
                    </span>
                </Box>
                <Box
                    sx={{
                        textAlign: 'left',
                        minWidth: '100px',
                    }}>
                    Farmers: {data?.farmer_details?.length}
                    {/* Old: <strong>{data?.new_farmers?.length}</strong> | New: <strong>{data?.old_farmers?.length}</strong> */}
                </Box>
            </Box>
        </ButtonBase>
    )
}

export default MeetingItem
