import { alpha, Stack, Typography, useTheme } from '@mui/material'
import { camelCaseWords, currencyFormatter } from '../utils/app-helper'


interface SchemeStackProps {
    name: string
    amount: string
}

const SchemeStack = ({ name, amount }: SchemeStackProps) => {

    const theme = useTheme()

    return (
        <Stack direction={'row'}
            className={'animate__animated animate__fadeIn'}
            sx={{
                background: 'linear-gradient(45deg, lime, yellow, red, magenta, blue, aqua)',
                p: '0 1px 2px 0',
                borderRadius: '12px',
                width: '100%',
                flex: 1,
                mb: 1,
                animation: 'borderAnimationKeyframes 3000ms infinite linear',
                '& .detailStack': {
                    flex: 1,
                    boxShadow: '0 0 12px -3px #83838370',
                    p: '3px 3px 3px 6px',
                    background: theme.palette.background.paper,
                    borderRadius: '11px',
                    '& .detail': {
                        fontWeight: 500,
                        flex: 1,
                        fontSize: '12px',
                        px: 1,
                        textTransform: 'capitalize',
                        '&:last-of-type': {
                            textAlign: 'center',
                            maxWidth: '25%',
                            background: alpha(theme.palette.success.main, 0.1),
                            color: theme.palette.success.main,
                            borderRadius: '8px',
                            fontWeight: 600,
                        },
                    },
                },
                '&:last-of-type': {
                    mb: 2,
                },
                '@keyframes borderAnimationKeyframes': {
                    '0%': {
                        background: 'linear-gradient(90deg,lime, yellow, red, magenta, blue, aqua)',
                    },
                    '50%': {
                        background: 'linear-gradient(-270deg, aqua, blue, magenta, red, yellow, lime )',
                    },
                    '100%': {
                        background: 'linear-gradient(90deg,lime, yellow, red, magenta, blue, aqua)',
                    },
                },
            }}>
            <Stack direction={'row'} className={'detailStack'}>
                <Typography className='detail animate__animated animate__fadeInUp'>{camelCaseWords(name)}</Typography>
                <Typography className='detail flexCenter animate__animated animate__fadeInUp'>{currencyFormatter(+amount)}</Typography>
            </Stack>
        </Stack>
    )
}

export default SchemeStack