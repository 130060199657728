import React, { useCallback, useEffect, useState } from 'react'
import PageContainer from '../../components/container/page-container'
import TitleBar from '../../components/container/TitleBar'
import { Autocomplete, Box, Button, Chip, InputAdornment, InputLabel, MenuItem, TextField, Typography, alpha, createFilterOptions, useTheme } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import operatorIllustration from '../../assets/images/illustartions/support.png'
import { LoadingButton } from '@mui/lab'
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router'
import moment from 'moment'
import API from '../../api'

import { RootState } from '../../store/store'
import { User } from '../../models/user'
import ElevatedContainer from '../../components/container/elevated-container'
import { DgeForm } from './createDge'
import { DGE } from '../../models/dge'
import LoadingView from '../../components/loading-view'
import { updateProcessState } from '../../slices/process-slice'



function UpdateDge() {

    const theme = useTheme()
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const selectedDge: DGE = useSelector((state: RootState) => state.dgeDetails.selectedDge)

    useEffect(() => {
        (Object.entries(selectedDge).length < 1) && navigate('/fleet-management/dge')
    }, [selectedDge?.id])

    return (
        <PageContainer>
            <TitleBar title={'Update DGE'} backIcon />

            <Box
                sx={{
                    flex: 1,
                    maxWidth: { xs: '100vw', sm: 'min(80vw, 1200px)' },
                    px: {
                        xs: 0, sm: 10
                    },
                    mx: 'auto',
                    '& .formRow': {
                        padding: '0.5rem ',
                        borderRadius: '18px',
                        background: theme.palette.mode == 'dark'
                            ? '#0e0e0e'
                            : `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.07)}, ${theme.palette.background.paper})`,
                        boxShadow: theme.palette.mode == 'dark' ? 'none' : `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                        border: `2px solid ${theme.palette.background.paper}`,
                    }
                }}>
                {selectedDge?.id
                    ? <UpdateDgeForm selectedDge={selectedDge} />
                    : <LoadingView />
                }

            </Box>

        </PageContainer >
    )
}

export default UpdateDge


const UpdateDgeForm = ({ selectedDge }: { selectedDge: DGE }) => {

    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [submitting, setSubmitting] = useState(false)
    const [taggedAreas, setTaggedAreas] = useState<any[]>(JSON.parse(`${selectedDge.tagged_area}`) ?? [])


    const { handleSubmit, register, clearErrors, control, reset, setValue, watch, formState: { errors } } = useForm({
        defaultValues: {
            id: selectedDge?.id,
            emp_id: selectedDge?.emp_id,
            mobile_no: selectedDge?.mobile_no,
            date_of_joining: moment(selectedDge?.date_of_joining),
            user_id: selectedDge?.user_id,

            name: selectedDge?.name,
            dl_no: selectedDge?.dl_no,
            state: selectedDge?.state,
            district: selectedDge?.district,
            sub_district: selectedDge?.sub_district,
            tagged_area: taggedAreas,
            reporting_manager_id: selectedDge?.reporting_manager_id,
            reporting_manager: selectedDge?.reporting_manager,
            remarks: selectedDge?.remarks,
            headQuarter: {
                subdistrict_name: selectedDge?.sub_district,
                district_name: selectedDge?.district,
                state_name: selectedDge?.state,
            },
            supervisor: {
                id: selectedDge?.reporting_manager_id,
                name: selectedDge?.reporting_manager,
            },

            dl_photo: null,

            user_password: selectedDge?.user_password,
            date_of_leaving: selectedDge?.date_of_leaving ? moment(selectedDge?.date_of_leaving) : null,

        }
    })
    const inputData = watch()

    const resetForm = () => {
        reset()
        setValue('dl_photo', null)
    }

    const onSubmit = (data: any) => {
        const dlSize = data?.dl_photo ? (data?.dl_photo[0]?.size / (1024 * 1024)) > 2 : false// to compare size max is 2mb
        if (dlSize) {
            toast.error(`Error! Please change  DL Image' \n Max 2mb allowed.`)
        } else {
            setSubmitting(true)
            dispatch(updateProcessState(true))
            const newData = {
                id: selectedDge?.id,
                emp_id: selectedDge?.emp_id,
                mobile_no: selectedDge?.mobile_no,
                date_of_joining: selectedDge?.date_of_joining,
                user_id: selectedDge?.user_id,

                name: data?.name,
                dl_no: data?.dl_no,
                state: data?.state,
                district: data?.district,
                sub_district: data?.sub_district,
                tagged_area: JSON.stringify(taggedAreas),
                reporting_manager_id: data?.supervisor?.id,
                reporting_manager: data?.supervisor?.name,
                remarks: data?.remarks,

                user_password: data?.user_password,
                date_of_leaving: data?.date_of_leaving ? `${moment(data?.date_of_leaving).format('YYYY-MM-DD')}` : null,

                // date_of_leaving: `${moment(data?.date_of_leaving).format('YYYY-MM-DD')}`,

                ...(data?.dl_photo?.length > 0 ? { dl_photo: data?.dl_photo[0] } : {}),
            }
            console.log(newData)
            API.post('/update_dge_details', { ...newData },
                {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then((res) => {
                    if (res.data?.status == 'success') {
                        toast.success('Dge updated successfully!')
                        reset()
                        navigate('/fleet-management/dge')
                    }
                    if (res.data?.status == 'error') {
                        toast.error(`Error! \n ${res.data?.msg}`)
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    setSubmitting(false)
                    dispatch(updateProcessState(false))
                })
        }
    }

    useEffect(() => {
        if (Object.entries(selectedDge).length > 0) {
            setValue('district', selectedDge?.district, { shouldValidate: true })
            setValue('sub_district', selectedDge?.sub_district, { shouldValidate: true })
            setValue('state', selectedDge?.state, { shouldValidate: true })
        }
    }, [selectedDge?.id])

    return (
        <Box
            sx={{
                flex: '1 1 500px',
                '& .formRow': {
                    padding: '0.5rem ',
                    borderRadius: '18px',
                    background: theme.palette.mode == 'dark'
                        ? '#0e0e0e'
                        : `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.07)}, ${theme.palette.background.paper})`,
                    boxShadow: theme.palette.mode == 'dark' ? 'none' : `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                    border: `2px solid ${theme.palette.background.paper}`,
                }
            }}>
            <DgeForm
                control={control}
                setValue={setValue}
                clearErrors={clearErrors}
                errors={errors}
                register={register}
                data={inputData}
                taggedAreas={taggedAreas}
                setTaggedAreas={setTaggedAreas}
            />

            {selectedDge?.date_of_leaving == null &&
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap-reverse',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        my: 4,
                        '& .MuiButton-root': {
                            flex: '1 0 160px',
                            maxWidth: { xs: '100%', sm: '190px' },
                            textWrap: 'nowrap',
                            textTransform: 'capitalize'
                        },
                    }}>

                    <Button
                        size={'large'}
                        variant={'outlined'}
                        onClick={resetForm}>
                        Discard Changes
                    </Button>
                    <LoadingButton
                        variant={'contained'}
                        size={'large'}
                        loading={submitting}
                        endIcon={<></>}
                        loadingPosition={'end'}
                        onClick={handleSubmit(onSubmit)}>
                        Update
                    </LoadingButton>

                </Box>
            }
        </Box>
    )
}