import React from 'react'
import { Box, useTheme, Drawer, Toolbar, Divider, alpha, } from "@mui/material"
import ThemeColorToggle from "../../components/theme-color-toggle";
import NavigationList from "./navigation-items";


export default function Sidebar(props: any) {

    const { open, handleDrawerClose, drawerWidth, smallScreen, hovered, setHovered } = props

    const theme = useTheme()

    return (
        <Drawer
            variant={"temporary"}
            anchor="left"
            open={open || hovered}
            onClose={handleDrawerClose}
            ModalProps={{
                keepMounted: false,
            }}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    border: 'none',
                    background: theme.palette.background.paper,
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: 'transparent',
                    },
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                    '&:hover': {
                        '&::-webkit-scrollbar': {
                            display: 'revert',
                        },
                        msOverflowStyle: 'auto',
                        scrollbarWidth: 'thin',
                    },
                },
                '& a': {
                    color: theme.palette.text.primary,
                    textDecoration: 'none',
                    cursor: 'default',
                },
                '& .MuiListItemButton-root': {
                    height: '36px',
                    borderRadius: '0 50vh 50vh 0',
                    mr: open || hovered ? 2 : 0,
                    whiteSpace: 'nowrap',
                    position: 'relative',
                    '& .MuiTypography-root': {
                        fontSize: '14px',
                        lineHeight: '14px',
                    },
                    '& svg': {
                        height: '32px',
                        width: '32px',
                        p: 0.9,
                        borderRadius: '50vh',
                        color: theme.palette.text.disabled,
                        '&.submenuArrow': {
                            position: 'absolute',
                            height: '16px',
                            width: '16px',
                            left: '4px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            p: 0,

                        },
                    },
                },
                '& .activeMenu .MuiListItemButton-root': {
                    background: open || hovered
                        ? `radial-gradient(${theme.palette.secondary.main}, ${theme.palette.primary.main})`
                        : alpha(theme.palette.action.hover, 0),
                    color: theme.palette.primary.contrastText,
                    '& svg': {
                        background: open || hovered ? alpha(theme.palette.action.hover, 0) : theme.palette.action.hover,
                        color: theme.palette.primary.contrastText,
                    },
                },
                '& .MuiBackdrop-root': {
                    background: 'none'
                },
                '& .navItem.open': {
                    background: open || hovered ? alpha(theme.palette.text.disabled, 0.05) : alpha(theme.palette.text.disabled, 0),
                    borderRadius: open || hovered ? '18px 18px 0 0' : 0,
                    '& +.MuiCollapse-root': {
                        background: open || hovered ? alpha(theme.palette.text.disabled, 0.05) : alpha(theme.palette.text.disabled, 0),
                        borderRadius: open || hovered ? '0 0 18px 18px' : 0,
                    },
                },
            }}
        >
            <Box onMouseOver={open ? () => false : () => setHovered(true)}
                onMouseLeave={open ? () => false : () => setHovered(false)}
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    width: drawerWidth,
                    transition: 'all 1300ms ease-in-out',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                }}>
                <Toolbar sx={{ mb: 3 }} />
                <NavigationList open={open} toggleDrawer={handleDrawerClose} hovered={hovered} />

                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    py: 1,
                    '& hr': {
                        width: '100%',
                        borderWidth: '1px',
                        my: 2,
                    },
                }}>
                    {smallScreen && <ThemeColorToggle size={'large'} />}
                    <Divider />
                </Box>
            </Box>
        </Drawer>
    )
}