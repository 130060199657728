import { Box, IconButton, Tab, Tabs, alpha, useMediaQuery, useTheme } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react'
import { ThemeDataTable } from '../../components/theme-data-table-container';
import TableLoading from '../../components/theme-table-container/TableLoading';
import NoDataView from '../../components/noDataView';
import TitleBar from '../../components/container/TitleBar';
import PageContainer from '../../components/container/page-container';
import API from '../../api';
import { capitalizeWords, currencyFormatter } from '../../utils/app-helper';
import { AddBoxSharp, Sync } from '@mui/icons-material';
import { useDocumentTitle } from '../../hooks/useDocumnetTitle';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { User } from '../../models/user';
import LoadingView from '../../components/loading-view';

export default function ServiceReport() {


    const theme = useTheme()
    const [services, setServices] = useState<any[]>([])
    const [fetching, setFetching] = useState<boolean>(true)
    const [dataType, setDataType] = useState<'normal' | 'removed'>('normal')
    useDocumentTitle(dataType == 'normal' ? 'Services' : `Deleted Services`)

    const changeDataType = (event: React.SyntheticEvent, newValue: 'normal' | 'removed') => setDataType(newValue)

    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser
    const isAdmin = (currentUser?.role?.name == 'super admin' || currentUser?.role?.name == 'admin')

    const fetchServices = useCallback(() => {
        setFetching(true)
        API.get('/download_service_report')
            .then((res) => {
                setServices(res.data?.data?.length > 0 ? res.data?.data.reverse() : res.data?.data)
            })
            .catch((err) => console.error(err))
            .finally(() => setFetching(false))
    }, [])

    const fetchDeletedServices = useCallback(() => {
        setFetching(true)
        API.get('/fetch_deleted_orders')
            .then((res) => {
                setServices(res.data?.data?.length > 0 ? res.data?.data.reverse() : res.data?.data)
            })
            .catch((err) => console.error(err))
            .finally(() => setFetching(false))
    }, [])

    useEffect(() => {
        dataType == 'normal'
            ? fetchServices()
            : fetchDeletedServices()
    }, [dataType])

    return (
        <PageContainer>
            <TitleBar title={'Services Report'} secondChild={
                <Box className={'flexCenter'} sx={{ gap: 1 }}>
                    {isAdmin &&
                        <Box
                            sx={{
                                width: { xs: 'min(80vw, 350px)', sm: 'min(90vw, 350px)' },
                                '& .MuiTabs-root': {
                                    p: '2px',
                                    borderRadius: '18px',
                                    boxShadow: `0 0 16px ${alpha(theme.palette.text.disabled, 0.1)} inset, 0 0 19px ${alpha(theme.palette.text.disabled, 0.1)}`,
                                    border: `2px solid ${theme.palette.background.default}`,
                                    minHeight: 'auto',
                                    '& .MuiTab-root': {
                                        p: '8px 12px',
                                        borderRadius: '16px',
                                        minHeight: '28px',
                                        zIndex: 1,
                                        textTransform: 'none',
                                        '&.Mui-selected': {
                                            color: theme.palette.primary.main
                                        },
                                    },
                                    '& .MuiTabs-indicator': {
                                        zIndex: 0,
                                        borderRadius: '16px',
                                        height: '100%',
                                        boxShadow: `0 0 14px ${theme.palette.primary.main} inset`,
                                        background: theme.palette.background.paper
                                    },
                                },
                            }}>
                            <Tabs value={dataType} onChange={changeDataType} variant='fullWidth'>
                                <Tab disabled={fetching} sx={{ color: theme.palette.primary.main }} value={'normal'} label="Normal" />
                                <Tab disabled={fetching} sx={{ color: theme.palette.primary.main }} value={'removed'} label="Removed" />
                            </Tabs>
                        </Box>
                    }
                    <IconButton
                        onClick={() => {
                            dataType == 'normal'
                                ? fetchServices()
                                : fetchDeletedServices()
                        }}
                        size='small'
                        sx={{
                            mr: 2,
                            background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                            boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                            border: `1px solid ${theme.palette.background.paper}`,
                        }}>
                        <Sync />
                    </IconButton>
                </Box>
            } />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                }}>
                <ServicesTable data={services} fetching={fetching} dataType={dataType} />
            </Box>
        </PageContainer>
    )
}

const ServicesTable = (props: any) => {

    const { data, fetching, dataType } = props
    const theme = useTheme()

    const columns: GridColDef[] = [
        { field: 's_no', headerName: 'SR No', flex: 1, minWidth: 160, },
        { field: 'client_name', headerName: 'Client Name', flex: 1, minWidth: 160 },
        { field: 'client_order_id', headerName: 'Client Order Id', flex: 1, minWidth: 100 },
        { field: 'sr_booking', headerName: 'SR Booking Date', flex: 1, minWidth: 100 },
        {
            field: 'sr_type', headerName: 'SR Type', flex: 1, minWidth: 100,
            valueFormatter: ({ value }) => `${capitalizeWords(value ?? '')}`
        },
        { field: 'sr_date', headerName: 'SR  Date', flex: 1, minWidth: 100 },
        // {field: 'actual_service_date', headerName: 'Complete Date', flex: 1, minWidth: 100 },
        { field: 'actual_service_date', headerName: 'Actual Service Date', flex: 1, minWidth: 100 },
        { field: 'payment_date', headerName: 'Payment  Date', flex: 1, minWidth: 100 },
        { field: 'delivery_date', headerName: 'Delivery  Date', flex: 1, minWidth: 100 },
        { field: 'farmer_mobile_no', headerName: 'Farmer Mob No', flex: 1, minWidth: 100 },
        { field: 'farmer_name', headerName: 'Farmer Name', flex: 1, minWidth: 100 },
        { field: 'farmer_location', headerName: 'Farmer Location', flex: 1, minWidth: 100 },
        { field: 'farmer_sub_district', headerName: 'Farmer Sub.Dist', flex: 1, minWidth: 100 },
        { field: 'farmer_district', headerName: 'Farmer District', flex: 1, minWidth: 100 },
        { field: 'farmer_state', headerName: 'Farmer State', flex: 1, minWidth: 100 },
        { field: 'crop_name', headerName: 'Crop Name', flex: 1, minWidth: 100 },

        { field: 'farm_location', headerName: 'Farm Location', flex: 1, minWidth: 100 },
        { field: 'farm_village', headerName: 'Farm Village', flex: 1, minWidth: 100 },
        { field: 'farm_sub_district', headerName: 'Farm Sub.Dist', flex: 1, minWidth: 100 },
        { field: 'farm_district', headerName: 'Farm District', flex: 1, minWidth: 100 },
        { field: 'farm_state', headerName: 'Farm State', flex: 1, minWidth: 100 },

        { field: 'requested_acreage', headerName: 'Requested  Acreage', flex: 1, minWidth: 100 },
        { field: 'actual_acreage', headerName: 'Actual Acreage', flex: 1, minWidth: 100 },
        {
            field: 'drone_estimated_acreage', headerName: 'Drone Acreage', flex: 1, minWidth: 100,
            valueFormatter: ({ value }) => value ? value : ''
        },
        { field: 'chemicals', headerName: 'Chemical Used', flex: 1, minWidth: 160 },
        {
            field: 'base_price', headerName: 'Base Price', flex: 1, minWidth: 100,
            renderCell: (params: GridRenderCellParams) => <span>{currencyFormatter(params?.value ?? 0)}</span>
        },
        {
            field: 'total_discount', headerName: 'Total Discount', flex: 1, minWidth: 100,
            renderCell: (params: GridRenderCellParams) => <span>{currencyFormatter(params?.value ?? 0)}</span>
        },
        {
            field: 'total_service_value', headerName: 'Total Service Value', flex: 1, minWidth: 100,
            renderCell: (params: GridRenderCellParams) => <span>{currencyFormatter(params?.value ?? 0)}</span>
        },
        { field: 'asset_code', headerName: 'Asset Code', flex: 1, minWidth: 100 },
        { field: 'asset_operator', headerName: 'Asset Operator', flex: 1, minWidth: 100 },
        { field: 'ao_mobile_no', headerName: 'AO Mobile No', flex: 1, minWidth: 100 },
        {
            field: 'battery', headerName: 'Battery', flex: 1, minWidth: 100,
            valueFormatter: ({ value }) => `${value?.length > 0 ? value.toString() : '-'}`
        },
        {
            field: 'service_status', headerName: 'Service Status', flex: 1, minWidth: 100,
            valueFormatter: ({ value }) => `${capitalizeWords(value ?? '')}`
        },
        { field: 'amount_recieved', headerName: 'Amount Received', flex: 1, minWidth: 100 },
        { field: 'transaction_ids', headerName: 'Transaction ID', flex: 1, minWidth: 100 },
        { field: 'payment_status', headerName: 'Payment Status', flex: 1, minWidth: 100 },
        { field: 'payment_type', headerName: 'Payment Type', flex: 1, minWidth: 100 },
        { field: 'service_request_by', headerName: 'Service Created By', flex: 1, minWidth: 100 },
        { field: 'invoice_link', headerName: 'Invoice', flex: 1, minWidth: 100 },
        { field: 'payment_image', headerName: 'Farm Image', flex: 1, minWidth: 100 },
        { field: 'farm_image', headerName: 'Farmer Image', flex: 1, minWidth: 100 },
        { field: 'sign_image', headerName: 'Sign Image', flex: 1, minWidth: 100 },
        { field: 'noc', headerName: 'NOC', flex: 1, minWidth: 100 },
        { field: 'coordinates', headerName: 'Coordinates', flex: 1, minWidth: 100 },
        { field: 'available_person_name', headerName: 'Available Person name', flex: 1, minWidth: 100 },
        { field: 'available_person_phone', headerName: 'Available Person Phone', flex: 1, minWidth: 140 },
        { field: 'service_provider_name', headerName: 'Provider Name', flex: 1, minWidth: 160 },
        { field: 'cancel_remarks', headerName: 'Cancel Remarks', flex: 1, minWidth: 100 },
        { field: 'spray_remarks', headerName: 'Spray Remarks', flex: 1, minWidth: 100 },
        { field: 'remarks', headerName: 'Extra Discount Remarks', flex: 1, minWidth: 100 },

        ...((dataType == 'removed')
            ? [
                { field: 'deleted_by', headerName: 'Deleted By', flex: 1, minWidth: 160 },
                { field: 'deleted_at', headerName: 'Deleted At', flex: 1, minWidth: 160 },
                { field: 'delete_remarks', headerName: 'Deleted Remarks', flex: 1, minWidth: 160 },
            ]
            : []),
    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetching}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}