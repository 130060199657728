import { Box, IconButton, Tab, Tabs, alpha, useMediaQuery, useTheme } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react'
import { ThemeDataTable } from '../../components/theme-data-table-container';
import TableLoading from '../../components/theme-table-container/TableLoading';
import NoDataView from '../../components/noDataView';
import TitleBar from '../../components/container/TitleBar';
import PageContainer from '../../components/container/page-container';
import API from '../../api';
import { camelCaseWords, capitalizeWords, currencyFormatter } from '../../utils/app-helper';
import { Sync } from '@mui/icons-material';
import { useSchemesData } from '../../hooks/useSchemesData';
import { useDocumentTitle } from '../../hooks/useDocumnetTitle';

export default function SchemeReport() {


    const theme = useTheme()
    const [dataType, setDataType] = useState<'active' | 'inactive'>('active')
    useDocumentTitle(dataType == 'active' ? 'Active Schemes' : `Inactive Schemes`)

    const { fetchingSchemes, schemes, fetchSchemes } = useSchemesData()
    const [allSchemes, setAllSchemes] = useState<any[]>(schemes)
    const [sorting, setSorting] = useState(true)

    const changeDataType = (event: React.SyntheticEvent, newValue: 'active' | 'inactive') => setDataType(newValue)

    const sortSchemes = (type: string) => {
        setSorting(true);
        const filteredSchemes = type == 'active'
            ? schemes.filter((scheme: any) => scheme.status === true)
            : schemes.filter((scheme: any) => scheme.status === false);
        setAllSchemes(filteredSchemes)
        setTimeout(() => {
            setSorting(false)
        }, 800)
    }


    useEffect(() => {
        fetchSchemes()
    }, [])

    useEffect(() => {
        if (schemes?.length > 0) sortSchemes(dataType)
    }, [schemes, dataType])

    return (
        <PageContainer>
            <TitleBar title={'Schemes Report'} secondChild={
                <Box className={'flexCenter'} sx={{ gap: 1 }}>
                    <Box
                        sx={{
                            width: { xs: 'min(80vw, 350px)', sm: 'min(90vw, 350px)' },
                            '& .MuiTabs-root': {
                                p: '2px',
                                borderRadius: '18px',
                                boxShadow: `0 0 16px ${alpha(theme.palette.text.disabled, 0.1)} inset, 0 0 19px ${alpha(theme.palette.text.disabled, 0.1)}`,
                                border: `2px solid ${theme.palette.background.default}`,
                                minHeight: 'auto',
                                '& .MuiTab-root': {
                                    p: '8px 12px',
                                    borderRadius: '16px',
                                    minHeight: '28px',
                                    zIndex: 1,
                                    textTransform: 'none',
                                    '&.Mui-selected': {
                                        color: theme.palette.primary.main
                                    },
                                },
                                '& .MuiTabs-indicator': {
                                    zIndex: 0,
                                    borderRadius: '16px',
                                    height: '100%',
                                    boxShadow: `0 0 14px ${theme.palette.primary.main} inset`,
                                    background: theme.palette.background.paper
                                },
                            },
                        }}>
                        <Tabs value={dataType} onChange={changeDataType} variant='fullWidth'>
                            <Tab disabled={sorting} sx={{ color: theme.palette.primary.main }} value={'active'} label="Active" />
                            <Tab disabled={sorting} sx={{ color: theme.palette.primary.main }} value={'inactive'} label="Inactive" />
                        </Tabs>
                    </Box>
                    <IconButton
                        onClick={() => {
                            dataType == 'active'
                                ? fetchSchemes()
                                : fetchSchemes()
                        }}
                        size='small'
                        sx={{
                            mr: 2,
                            background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                            boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                            border: `1px solid ${theme.palette.background.paper}`,
                        }}>
                        <Sync />
                    </IconButton>
                </Box>
            } />


            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                }}>

                <SchemesTable data={allSchemes} fetching={fetchingSchemes || sorting} />
            </Box>
        </PageContainer >
    )
}

const SchemesTable = (props: any) => {

    const { data, fetching } = props
    const theme = useTheme()

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', flex: 1, minWidth: 80 },
        {
            field: 'type', headerName: 'Scheme Type', flex: 1, minWidth: 140,
            valueFormatter: ({ value }) => `${value == '1' ? 'General' : value == '2' ? 'Client' : value == '3' ? 'Subvention' : value == '4' ? 'R & D' : value == '5' ? 'Demo' : 'Unknown'}`

        },
        { field: 'scheme_name', headerName: 'Scheme Name', flex: 1, minWidth: 200 },
        { field: 'client_id', headerName: 'Client ID', flex: 1, minWidth: 120 },
        { field: 'crop_name', headerName: 'Crop', flex: 1, minWidth: 100 },
        {
            field: 'crop_base_price', headerName: 'Base Price', flex: 1, minWidth: 100,
            renderCell: (params: GridRenderCellParams) =>
                <span>{currencyFormatter(+params.value ?? 0)}</span>
        },
        { field: 'min_acreage', headerName: 'Min Acrage', flex: 1, minWidth: 100 },
        { field: 'max_acreage', headerName: 'Max Acrage', flex: 1, minWidth: 100 },
        { field: 'period_from', headerName: 'Start Date', flex: 1, minWidth: 120 },
        { field: 'period_to', headerName: 'Valid Upto', flex: 1, minWidth: 120 },
        {
            field: 'discount_price', headerName: 'Discounted Amount', flex: 1, minWidth: 100,
            renderCell: (params: GridRenderCellParams) =>
                <span>{currencyFormatter(+params.value ?? 0)}</span>
        },
        {
            field: 'status', headerName: 'Status', flex: 1, minWidth: 80,
            valueFormatter: ({ value }) => `${value ? 'Active' : 'Inactive'}`
        },
    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetching}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}


