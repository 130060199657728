import React, { ReactNode } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material'
import { TransitionProps } from "@mui/material/transitions"


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>) {
    return <Slide direction="up" ref={ref} {...props} />
})

interface ThemeDialogProps {
    title?: string,
    open: boolean,
    onClickClose?: () => void,
    dialogBody: ReactNode,
    dialogAction?: ReactNode,
    size?: 'large' | 'small'

}

export default function ThemeDialog(props: ThemeDialogProps) {

    const { title, open = false, onClickClose, dialogBody, dialogAction, size = 'small' } = props

    return (
        <Dialog open={open} TransitionComponent={Transition}
            keepMounted onClose={onClickClose} aria-describedby={title} fullWidth={size == 'large'}
            sx={{
                '& .MuiDialog-paper': {
                    width: size == 'large' ? '100vw' : 'auto',
                    maxWidth: size == 'large' ? '100%' : '95%',
                    borderRadius: size == 'large' ? '20px 20px 0 0' : '20px',
                    height: size == 'large' ? '100dvh' : 'auto',
                    m: size == 'large' ? 'auto' : 1,
                    mb: size == 'large' ? 0 : 1,

                    '& .MuiDialogTitle-root': {
                        fontSize: '16px',
                        padding: '8px 16px',
                        boxShadow: '0 0 12px #83838330',
                    },
                    '& .MuiDialogActions-root': {
                        boxShadow: '0 0 12px #83838330',
                        justifyContent: 'center',
                        '& .MuiButton-root': {
                            borderRadius: '50vh',
                            fontSize: '14px',
                            minWidth: '100px',
                            textTransform: 'capitalize',
                            letterSpacing: '1px',
                            boxShadow: 0
                        },
                    },

                },
            }}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>{dialogBody}</DialogContent>
            {dialogAction && <DialogActions>{dialogAction}</DialogActions>}
        </Dialog>
    )
}