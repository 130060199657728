import React, { useCallback, useEffect, useState } from 'react'
import {
    alpha,
    Box,
    Button,
    Chip,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material"
import { RemoveRedEyeRounded, Sync } from "@mui/icons-material"
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams, GridToolbar, GridValueFormatterParams } from "@mui/x-data-grid"

import { ThemeDataTable } from "../../components/theme-data-table-container"
import API from "../../api"
import PageContainer from '../../components/container/page-container'
import TitleBar from '../../components/container/TitleBar'
import TableLoading from '../../components/theme-table-container/TableLoading'
import NoDataView from '../../components/theme-table-container/NoData-'
import { useNavigate } from 'react-router'
import { updateProcessState } from '../../slices/process-slice'
import { useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { camelCaseWords, capitalizeWords } from '../../utils/app-helper'
import ThemeDialog from '../../components/dialog-box/theme-dialog'
import FileUploadInput from '../../components/inputs/fileUploadInput'
import { useForm } from 'react-hook-form'
import { FormBlockTitle } from '../../components/title/FormBlockTitle'
import { CopyIcon, DoubleTickIcon, FileDownloadIcon } from '../../components/icons'


export default function Farmers() {

    const theme = useTheme()
    const dispatch = useDispatch()
    const [farmers, setFarmers] = useState<any[]>([])
    const [fetchingFarmers, setFetchingFarmers] = useState(false)
    const [importDialog, setImportDialog] = useState(false)



    const fetchFarmers = useCallback(() => {
        setFetchingFarmers(true)
        dispatch(updateProcessState(true));

        API.get(`/get-farmers`)
            .then((res) => {
                if (res.data?.statuscode == "200") {
                    setFarmers(res.data?.farmer_details)
                }
            })
            .catch((err) => {
                toast.success("Oops! Something bad at our end");
            })
            .finally(() => {
                setFetchingFarmers(false)
                dispatch(updateProcessState(false))
            });
    }, [])


    useEffect(() => {
        fetchFarmers()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Farmers'}
                secondChild={
                    <>
                        <IconButton
                            onClick={fetchFarmers}
                            size='small'
                            sx={{
                                mr: 2,
                                background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                                boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                                border: `1px solid ${theme.palette.background.paper}`,
                            }}>
                            <Sync />
                        </IconButton>
                        <CreateOptions setImportDialog={setImportDialog} />
                    </>
                } />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    '& .buttonGradient': {
                        backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        outline: `0px solid ${theme.palette.primary.main}`,
                        outlineOffset: 0,
                        boxShadow: 0,
                        textTransform: 'none',
                        transition: 'all 100ms ease',
                        '&:hover': {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: '2px',
                            '&:active': {
                                transform: 'scale(0.98) !important'
                            }
                        },
                    },

                }}>

                <FarmersTable data={farmers} fetchingFarmers={fetchingFarmers} />
            </Box>

            <ThemeDialog
                open={importDialog}
                dialogBody={<ImportFormDialog setImportDialog={setImportDialog} />}
            />

        </PageContainer>
    )
}


const FarmersTable = (props: any) => {

    const navigate = useNavigate()
    const { data, fetchingFarmers } = props

    const columns: GridColDef[] = [
        {
            field: 'farmer_code', headerName: 'AWF Code', flex: 1, minWidth: 130,
            renderCell: (params: GridRenderCellParams) =>
                <Chip
                    size={'small'}
                    variant='outlined'
                    label={params.row?.farmer_code}
                    color={params.row?.status == '1' ? 'success' : 'error'}
                    sx={{
                        fontSize: '12px',
                        lineHeight: '12px',
                        minWidth: '60px',
                        p: '2px',
                        height: '18px',
                        textTransform: 'capitalize',
                        boxShadow: `0 0 12px -4px currentcolor inset`,
                    }} />
        },
        {
            field: 'farmer_name', headerName: 'Name', flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams<Date>) => (
                <Typography sx={{ fontSize: '14px', textTransform: 'capitalize' }}>{camelCaseWords(params.value)}</Typography>
            )
        },
        {
            field: 'farmer_mobile_no', headerName: 'Mobile', width: 160, flex: 1, minWidth: 130,
            renderCell: (params: GridRenderCellParams<Date>) => (
                <Tooltip title={params.value != '' ? params.value : ''} arrow>
                    <Typography
                        sx={{ fontSize: '13px' }}>{params.value != '' ? `+91 ${params.value}` : '-na-'}</Typography>
                </Tooltip>
            )
        },
        {
            field: 'farmer_sub_district', headerName: 'Sub-district', flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams<Date>) => (
                <Typography sx={{ fontSize: '14px', textTransform: 'capitalize' }}>{camelCaseWords(params.value)}</Typography>
            )
        },
        {
            field: 'farmer_district', headerName: 'District', flex: 1, minWidth: 120,
            renderCell: (params: GridRenderCellParams<Date>) => (
                <Typography sx={{ fontSize: '14px', textTransform: 'capitalize' }}>{camelCaseWords(params.value)}</Typography>
            )
        },
        {
            field: 'farmer_state', headerName: 'State', flex: 1, minWidth: 120,
            renderCell: (params: GridRenderCellParams<Date>) => (
                <Typography sx={{ fontSize: '14px', textTransform: 'capitalize' }}>{camelCaseWords(params.value)}</Typography>
            )
        },
        { field: 'farmer_pincode', headerName: 'Pincode', flex: 1, minWidth: 100 },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            type: 'actions',
            cellClassName: 'stickyRight',
            headerClassName: 'stickyLeft',
            getActions: (params: GridRowParams<any>) => [
                <Tooltip title={`View ${params.row?.farmer_code}`} arrow>
                    <GridActionsCellItem
                        icon={<RemoveRedEyeRounded />}
                        label="View Farmer"
                        onClick={() => {
                            navigate(`${btoa(params.row?.id)}`)
                        }}
                    />
                </Tooltip>,
            ],
        },
        {
            field: 'farm_info', headerName: 'Farms - Acreage', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => `${value?.length ?? 0} - ${value?.length > 0 ? value?.reduce((total: any, farm: any) => total + Number(farm.acerage), 0).toFixed(2) : 0}`
        },

    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetchingFarmers}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data}
            columns={columns}
            columnVisibilityModel={{
                farm_info: false,
            }}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,

            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}

const CreateOptions = (props: any) => {

    const { setImportDialog } = props

    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <Button
                id="basic-button"
                variant='contained'
                className={'buttonGradient animate__animated animate__fadeInUp'}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                Add New
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => navigate('create')}>Create</MenuItem>
                <MenuItem onClick={() => {
                    setImportDialog(true)
                    handleClose()
                }}>
                    Import
                </MenuItem>
            </Menu>
        </div >
    )
}

const ImportFormDialog = (props: any) => {

    const { setImportDialog } = props

    const theme = useTheme()
    const dispatch = useDispatch()
    const [result, setResult] = useState<null | any>(null)

    const { setValue, register, handleSubmit, formState: { errors }, watch } = useForm({ defaultValues: { upload_farmer: null } })
    const inputData = watch('upload_farmer')
    const uploadFarmers = useCallback((data: any) => {

        if (data?.upload_farmer[0].type.includes('text/csv')) {
            dispatch(updateProcessState(true))
            API.post("/import-farmer", { upload_farmer: data?.upload_farmer[0] }, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": '*'
                },
                timeout: 60000,
            })
                .then((res) => {
                    res.data?.status == 'success'
                        ? setResult(res.data?.data)
                        : toast.error(res.data?.message)
                })
                .catch((err) => console.error(err))
                .finally(() => {
                    dispatch(updateProcessState(false))
                })
        } else {
            toast.error('Invalid file type')
        }
    }, [inputData])

    const copyFailed = () => {
        const textToCopy = result?.failedFarmers?.map((farmer: any, i: number) => `${farmer?.phone}`).join(', ') ?? ''
        navigator.clipboard.writeText(textToCopy)
    }
    const copySuccess = () => {
        const textToCopy = result?.successfulFarmers?.map((farmer: any, i: number) => `${farmer?.phone}`).join(', ') ?? ''
        navigator.clipboard.writeText(textToCopy)
    }


    return (
        <Box
            className={'flexCenter'}
            sx={{
                minHeight: '280px',
                width: 'min(85vw, 450px)',
                flexWrap: 'wrap',
                '& .MuiButton-root': {
                    textTransform: 'none',
                }
            }}>

            {!result
                ? <>
                    <Typography
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                            fontWeight: 600,
                            fontFamily: theme.typography.h1.fontFamily,
                        }}>Import Farmers</Typography>
                    <FileUploadInput
                        accept={'.csv'}
                        // accept={'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
                        reactHookProps={{ ...register('upload_farmer', { required: 'File required', }) }}
                        onClear={() => setValue('upload_farmer', null)}
                        errorMessage={errors.upload_farmer?.message?.toString()}
                    />
                    <Box className={'flexBetweenCenter'} sx={{
                        width: '100%',
                    }}>
                        <Typography variant='caption' color={theme.palette.info.main}>Note: only .csv file allowed</Typography>
                        <Box
                            component={'a'}
                            href={'https://development-api.d2f.co.in/farmer-sample-download'}
                            target={'_blank'}
                            className={'flexCenter'}
                            sx={{
                                gap: 0.5,
                                borderRadius: '50vh',
                                width: 'max-content',
                                color: theme.palette.info.main,
                                p: '0 6px 0 10px',
                                cursor: 'pointer',
                                textDecoration: 'none',
                                mt: 1,
                                '& svg': {
                                    height: '16px',
                                    width: '16px'
                                },
                            }}>Sample <FileDownloadIcon /></Box>

                    </Box>
                    <Box sx={{
                        mt: 2,
                        width: '100%',
                        px: '10%',
                    }}>
                        <Button
                            fullWidth
                            variant='contained'
                            className={'animate__animated animate__fadeIn'}
                            onClick={handleSubmit(uploadFarmers)}
                        >
                            Import
                        </Button>
                        <Button
                            fullWidth
                            className={'animate__animated animate__fadeIn'}
                            onClick={() => setImportDialog(false)}
                        >
                            Close
                        </Button>
                    </Box>
                </>
                : <>
                    <Typography
                        className={'flexCenter animate__animated animate__fadeIn'}
                        sx={{
                            gap: 2,
                            width: '100%',
                            textAlign: 'center',
                            fontWeight: 600,
                            fontFamily: theme.typography.h1.fontFamily,
                            color: theme.palette.success.main,
                            '& svg': {
                                height: '38px',
                                width: '38px'
                            },
                        }}>
                        <DoubleTickIcon /> Farmers imported successfully!
                    </Typography>

                    <TableContainer component={Paper} sx={{ mb: '2rem !important', }} className='animate__animated animate__fadeIn' >
                        <Table sx={{ width: '100%' }} size="small" aria-label="a dense table">
                            <TableBody>
                                <TableRow className='animate__animated animate__fadeIn' sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell><strong>Success</strong></TableCell>
                                    <TableCell>{result?.successfulFarmers?.length}</TableCell>
                                </TableRow>
                                <TableRow className='animate__animated animate__fadeIn' sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell><strong>Failed</strong></TableCell>
                                    <TableCell>{result?.failedFarmers?.length}</TableCell>
                                </TableRow>
                                <TableRow className='animate__animated animate__fadeIn' sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell><strong>Total Entries</strong></TableCell>
                                    <TableCell>{result?.successfulFarmers?.length + result?.failedFarmers?.length}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box sx={{
                        width: '100%',
                        maxHeight: '300px',
                        overflowY: 'auto'
                    }}>
                        {result?.successfulFarmers?.length > 0 &&
                            <>
                                <Box className={'flexCenterBottom animate__animated animate__fadeIn'} sx={{ width: '100%' }}>
                                    <FormBlockTitle title='Success Enteries' icon={false} color={theme.palette.success.main} />
                                    <Tooltip title={'Copy entries'}>
                                        <IconButton size='small' onClick={copySuccess}><CopyIcon /></IconButton>
                                    </Tooltip>
                                </Box>
                                <TableContainer component={Paper} className='animate__animated animate__fadeIn'
                                    sx={{
                                        mb: '1rem !important',
                                        background: alpha(theme.palette.success.main, 0.1),
                                        '& *': {
                                            color: `${theme.palette.success.main} !important`
                                        },
                                    }}>
                                    <Table sx={{ width: '100%' }} size="small" aria-label="success entries table">
                                        <TableHead>
                                            <TableRow className='animate__animated animate__fadeIn' >
                                                <TableCell sx={{ width: '30px' }}>Sr</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell sx={{ width: '160px' }}>Mobile</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {result?.successfulFarmers?.map((farmer: any, i: number) => (
                                                <TableRow
                                                    key={i}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    className='animate__animated animate__fadeIn'
                                                >
                                                    <TableCell>{i + 1}</TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {farmer?.name}
                                                    </TableCell>
                                                    <TableCell>+91 {farmer?.phone}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        }
                        {result?.failedFarmers?.length > 0 &&
                            <>
                                <Box className={'flexCenterBottom animate__animated animate__fadeIn'} sx={{ width: '100%' }}>
                                    <FormBlockTitle title='Failed Enteries' icon={false} color={theme.palette.warning.main} />
                                    <Tooltip title={'Copy entries'}>
                                        <IconButton size='small' onClick={copyFailed}><CopyIcon /></IconButton>
                                    </Tooltip>
                                </Box>
                                <TableContainer component={Paper} className='animate__animated animate__fadeIn'
                                    sx={{
                                        mb: '1rem !important',
                                        background: alpha(theme.palette.warning.main, 0.1),
                                        '& *': {
                                            color: theme.palette.warning.main
                                        },
                                    }}>
                                    <Table sx={{ width: '100%' }} size="small" aria-label="failed entries table">
                                        <TableHead>
                                            <TableRow className='animate__animated animate__fadeIn' >
                                                <TableCell sx={{ width: '30px' }}>Sr</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell sx={{ width: '160px' }}>Mobile</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {result?.failedFarmers?.map((farmer: any, i: number) => (
                                                <TableRow
                                                    key={i}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    className='animate__animated animate__fadeIn'
                                                >
                                                    <TableCell>{i + 1}</TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {farmer?.name}
                                                    </TableCell>
                                                    <TableCell>+91 {farmer?.phone}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        }
                    </Box>

                    <Button
                        onClick={() => {
                            setImportDialog(false)
                            setResult(null)
                        }}
                        variant='contained'
                        sx={{
                            mx: 'auto',
                        }}>
                        Done
                    </Button>
                </>
            }
        </Box>
    )
}