import React from 'react'
import CreateSchemeForm from './create-scheme-form'
import PageContainer from '../../../components/container/page-container'
import TitleBar from '../../../components/container/TitleBar'

function CreateScheme() {

    return (
        <PageContainer>
            <TitleBar title={'Create Scheme'} backIcon />
            <CreateSchemeForm />
        </PageContainer>
    )
}

export default CreateScheme