import { alpha, Backdrop, Box, Button, SwipeableDrawer, Typography, useTheme } from "@mui/material";
import { CalendarIcon, CropIcon, LocationIcon, PieChartIcon, UserIcon } from "../../../../components/icons";
import React from "react";
import moment from "moment";
import MeetingItem from "./meeting-item";
import { FormBlockTitle } from "../../../../components/title/FormBlockTitle";
import { s3BucketUrl } from "../../../../utils/app-helper";

export const MeetingDetailsDrawer = (props: any) => {
    const { open, handleClose, data } = props;

    const theme = useTheme();

    return (
        <React.Fragment>
            <Backdrop
                open={open}
                sx={{
                    color: "#fff",
                    alignItems: "flex-end",
                }}
            >
                <SwipeableDrawer
                    anchor={"bottom"}
                    open={open}
                    onOpen={() => { }}
                    onClose={handleClose}
                    sx={{
                        "& .MuiDrawer-paper": {
                            minHeight: "85svh",
                            maxHeight: '90svh',
                            borderRadius: "32px 32px 0 0",
                        },
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                            display: "flex",
                            flexFlow: "column",
                            justifyContent: "flex-end",
                            gap: 2,
                        }}
                    >
                        {/* content */}
                        <Box
                            sx={{
                                flex: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    background: theme.palette.mode == 'dark' ? '#262626' : theme.palette.background.default,
                                    position: 'sticky',
                                    zIndex: 1,
                                    top: 0,
                                    transition: 'all 260ms ease-in-out',
                                    p: 2,
                                    mb: 3,
                                }}>
                                <Typography
                                    sx={{
                                        fontSize: "clamp(1.3rem, 3vw, 1rem)",
                                        fontWeight: 600,
                                        mb: 2,
                                        textAlign: "center",
                                        fontFamily: theme.typography.h1.fontFamily,
                                    }}
                                >
                                    Meeting Details
                                </Typography>

                                <Typography
                                    className="flexCenter"
                                    sx={{
                                        background: alpha(theme.palette.text.disabled, 0.1),
                                        borderRadius: "8px",
                                        p: "1px 8px",
                                        fontSize: "12px",
                                        gap: 0.5,
                                        maxWidth: 'max-content',
                                        mb: 2,
                                        "& svg": {
                                            height: "14px",
                                            width: "14px",
                                        },
                                    }}
                                >
                                    <CalendarIcon /> {moment().format("DD MMM YYYY")}
                                </Typography>

                                <MeetingItem data={data} />
                            </Box>

                            {data?.farmer_details?.length > 0 &&
                                <>
                                    <FormBlockTitle title={`Farmers`} icon={false} />
                                    <Box sx={{
                                        px: 1,
                                        display: 'grid',
                                        gap: 1,
                                        justifyContent: 'stretch',
                                        mb: 3,
                                    }}>
                                        {data?.farmer_details?.map((farmer: any, i: number) => (
                                            <VisitedFarmerItem key={i} data={farmer} />
                                        ))}
                                    </Box>
                                </>
                            }
                            <FormBlockTitle title={`Meeting Iamges`} icon={false} />
                            <Box sx={{
                                px: 2,
                                '& img': {
                                    width: '100%',
                                    objectFit: 'contain',
                                    maxHeight: '200px',
                                    borderRadius: '18px',
                                    background: alpha(theme.palette.primary.main, 0.1),
                                },
                            }}>
                                {(data?.meeting_details ? JSON.parse(data?.meeting_details?.images) : []).map((image: any, index: number) => (
                                    <img src={`${s3BucketUrl}/${image}`} alt={'meeting image'} key={index} />
                                ))}
                            </Box>

                        </Box>

                        {/* footer */}
                        <Box
                            className={'flexCenter'}
                            sx={{
                                background: theme.palette.mode == 'dark' ? '#262626' : theme.palette.background.default,
                                position: 'sticky',
                                zIndex: 1,
                                bottom: 0,
                                transition: 'all 260ms ease-in-out',
                                pb: 1,
                            }}>
                            <Button onClick={handleClose}>Close</Button>
                        </Box>
                    </Box>
                </SwipeableDrawer>
            </Backdrop>
        </React.Fragment >
    );
};

const VisitedFarmerItem = (props: any) => {

    const { data } = props
    const theme = useTheme()

    return (
        <Box
            sx={{
                p: 0.25,
                borderRadius: '1rem',
                background: theme.palette.background.paper,
                fontSize: '13px',
                '& .contentBlock': {
                    background: theme.palette.mode == 'dark' ? '#262626' : alpha(theme.palette.primary.main, 0.1),
                    borderRadius: '0.8rem',
                    fontSize: '13px',
                    p: 0.5, px: 1,
                    flexWrap: 'wrap',

                },
                '& svg': {
                    height: '14px',
                    width: '14px',
                },
                '& .detailItem': {
                    gap: 0.5,
                },
            }}>
            <Box className={'flexBetweenCenter'} sx={{ px: 1 }}>
                <span className={'flexStartCenter detailItem line-clamp clamp-1 '}><UserIcon /> <strong>{data?.farmer_name?.toUpperCase()}</strong></span>
                <span className={'detailItem line-clamp clamp-1'}>+91 {data?.farmer_mobile_no}</span>
            </Box>
            <Box className={'contentBlock flexBetweenCenter'}>
                <span className={'flexStartCenter  detailItem line-clamp clamp-1'}><CropIcon /> {data?.crop_name ?? 'na'}</span>
                <span className={'flexStartCenter  detailItem'}><PieChartIcon /> {data?.total_acerage} Acres</span>
                <span className={'flexStartCenter  detailItem line-clamp clamp-1'} style={{ minWidth: '100%' }}>
                    <LocationIcon />
                    {/* {data?.farmer_village}, */}
                    {data?.farmer_sub_district}, {data?.farmer_district}
                </span>
            </Box>

        </Box>
    )
}
