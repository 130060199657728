import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, Box, MenuItem, Tab, Tabs, alpha, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import API from '../../api'
import { ThemeTextField } from '../../components/inputs/theme-text-field'
import { FormBlockTitle } from '../../components/title/FormBlockTitle'
import { updateProcessState } from '../../slices/process-slice'
import { User } from '../../models/user'
import { Operator } from '../../models/operator'
import { RootState } from '../../store/store'

const RmActionForm = (props: any) => {

    const { selectedRequest, operators, setSelectedRequest, fetchServices, providers } = props
    const theme = useTheme()
    const dispatch = useDispatch()
    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser

    const [submitting, setSubmitting] = useState(false)

    const [actionType, setActionType] = useState<'assign' | 'cancel'>('assign')
    const [operator, setOperator] = useState<Operator | null>(null)
    const [provider, setProvider] = useState<any | null>(providers?.filter((el: any) => el?.id == selectedRequest?.provider_id)[0] ?? null)
    const { handleSubmit, control, setError, clearErrors, reset, setValue, register, watch, formState: { errors } } = useForm()
    const inputData = watch()
    const changeActionType = (event: React.SyntheticEvent, newValue: 'assign' | 'cancel') => {
        setActionType(newValue)
        reset()
    }
    const statusColor = actionType == 'assign' ? theme.palette.primary.main : theme.palette.error.main

    const onSubmit = (data: any) => {
        setSubmitting(true)
        const newData = {
            id: selectedRequest.id,
            ...(actionType == 'cancel'
                ? { cancel_remarks: data?.cancel_remarks == 'Others' ? data?.other_remarks : data?.cancel_remarks }
                : {
                    asset_operator_id: ((currentUser?.provider_id == '1') && (provider && provider?.id != '1'))
                        ? ''
                        : `${data.operator}`,
                    //, // if empty then created stage else assigned stage
                    provider_id: (currentUser?.provider_id == '1') ? `${provider?.id}` : `${selectedRequest?.provider_id}`, // required
                    provider_name: (currentUser?.provider_id == '1') ? `${provider?.name}` : `${selectedRequest?.provider_name}`,  // required
                }
            )
        }
        const url = actionType == 'assign' ? 'submit_assigned_operator' : 'cancel_order'
        API.post(url, newData)
            .then((res) => {
                toast.success(actionType == 'assign' ? 'Operator assigned successfully!' : 'Service request cancelled.')
                setSelectedRequest && setSelectedRequest(null)
                fetchServices && fetchServices()
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setSubmitting(false)
                dispatch(updateProcessState(false))
            })
    }

    return (
        <Box
            sx={{
                width: { xs: 'min(80vw, 350px)', sm: 'min(90vw, 350px)' },
                pt: 3,
                '& .MuiTabs-root': {
                    p: '2px',
                    borderRadius: '18px',
                    boxShadow: `0 0 16px ${alpha(theme.palette.text.disabled, 0.1)} inset, 0 0 19px ${alpha(theme.palette.text.disabled, 0.1)}`,
                    border: `2px solid ${statusColor}`,
                    minHeight: 'auto',
                    mb: 2,
                    '& .MuiTab-root': {
                        p: '8px 12px',
                        borderRadius: '16px',
                        minHeight: '28px',
                        zIndex: 1,
                        textTransform: 'none',
                        // color: statusColor,
                        '&.Mui-selected': {
                            color: statusColor
                        },
                    },
                    '& .MuiTabs-indicator': {
                        zIndex: 0,
                        borderRadius: '16px',
                        height: '100%',
                        boxShadow: `0 0 14px ${statusColor} inset`,
                        background: theme.palette.background.paper
                    },
                },
            }}>
            <FormBlockTitle icon={false} title={selectedRequest?.orderId} />

            <Tabs value={actionType} onChange={changeActionType} variant='fullWidth'>
                <Tab sx={{ color: theme.palette.primary.main }} value={'assign'} label="Assign" />
                <Tab sx={{ color: theme.palette.error.main }} value={'cancel'} label="Cancel" />
            </Tabs>


            <Box sx={{
                minHeight: '90px',
                mb: 2,
            }}>
                {actionType == 'assign'
                    ? <>
                        {currentUser?.provider_id == '1' &&
                            <Controller name={`provider`}
                                control={control}
                                rules={{ required: { value: true, message: 'Required' } }}
                                render={({ field }) => (
                                    <Autocomplete
                                        options={providers}
                                        autoHighlight
                                        value={provider}
                                        onChange={(e, data) => {
                                            setValue('provider', data?.id)
                                            setProvider(data ?? null)
                                        }}
                                        getOptionLabel={(option: any) => `${option.name}`}
                                        renderOption={(props, option: any) => (
                                            <Box component="li" {...props} sx={{ fontSize: '14px', fontWeight: 600 }}>
                                                {option.name}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <ThemeTextField
                                                {...params} required
                                                error={Boolean(errors?.provider)}
                                                helperText={(errors?.provider?.message ?? '').toString()}
                                                size={'small'} label={'Provider'}
                                                placeholder={'eg. select provider'}
                                            />
                                        )}
                                    />
                                )} />
                        }

                        <Controller name={`operator`}
                            control={control}
                            rules={{
                                required: {
                                    value: ((currentUser?.provider_id != '1') || (provider && provider?.id == '1')),
                                    message: 'Required'
                                }
                            }}
                            render={({ field }) => (
                                <Autocomplete
                                    options={operators}
                                    autoHighlight
                                    value={operator}
                                    disabled={provider?.id != '1' && currentUser?.provider_id == '1'}
                                    onChange={(e, data) => {
                                        setValue('operator', data?.id)
                                        setOperator(data ?? null)
                                    }}
                                    getOptionLabel={(option: Operator) => `${option.name}`}
                                    renderOption={(props, option: Operator) => (
                                        <Box component="li" {...props} sx={{ fontSize: '14px', fontWeight: 600 }}>
                                            {option.name} - {option?.phone}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <ThemeTextField
                                            {...params} required={(provider && provider?.id != '1') || !selectedRequest?.provider_id}
                                            error={Boolean(errors?.operator)}
                                            helperText={(errors?.operator?.message ?? '').toString()}
                                            size={'small'} label={'Operator'}
                                            placeholder={'eg. Amit Thakur'}
                                        />
                                    )}
                                />
                            )} />
                    </>

                    : <>
                        <Controller name={`cancel_remarks`}
                            control={control}
                            rules={{ required: { value: actionType == 'cancel', message: 'Required' } }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.cancel_remarks)} select
                                    helperText={(errors?.cancel_remarks?.message ?? '').toString()}
                                    size={'small'} label={'Remarks'}
                                    placeholder={'cancel_remarks'}
                                >
                                    <MenuItem value="Regulatory compliance">Regulatory compliance</MenuItem>
                                    <MenuItem value="Unforeseen obstacles">Unforeseen obstacles</MenuItem>
                                    <MenuItem value="Safety concerns">Safety concerns</MenuItem>
                                    <MenuItem value="Crop conditions">Crop conditions</MenuItem>
                                    <MenuItem value="Pest population dynamics">Pest population dynamics</MenuItem>
                                    <MenuItem value="Chemical supply issues">Chemical supply issues</MenuItem>
                                    <MenuItem value="Operational constraints">Operational constraints</MenuItem>
                                    <MenuItem value="Customer request">Customer request</MenuItem>

                                    <MenuItem value="Unsupportive weather conditions">Unsupportive weather conditions</MenuItem>
                                    <MenuItem value="Technical issue with Drone">Technical issue with Drone</MenuItem>
                                    <MenuItem value="Drone not available">Drone not available</MenuItem>
                                    <MenuItem value="Spray Service with another provider undertaken by Farmer">Spray Service with another provider undertaken by Farmer</MenuItem>
                                    <MenuItem value="Farmer rescheduled the request date">Farmer rescheduled the request date</MenuItem>
                                    <MenuItem value="Unable to contact the Farmer">Unable to contact the Farmer</MenuItem>
                                    <MenuItem value="No slot available for service delivery">No slot available for service delivery</MenuItem>
                                    <MenuItem value="Service representative not available">Service representative not available</MenuItem>

                                    <MenuItem value="Others">Others</MenuItem>
                                </ThemeTextField>
                            )}
                        />

                        {inputData?.cancel_remarks == 'Others' &&
                            <Controller name={`other_remarks`}
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Required' },
                                    pattern: { value: /^[a-zA-Z0-9 ]*$/, message: 'Special characters not allowed' },
                                }}
                                defaultValue={''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} required className={'formInput'} multiline rows={3}
                                        error={Boolean(errors?.other_remarks)}
                                        helperText={(errors?.other_remarks?.message ?? '').toString()}
                                        size={'small'} label={'Others Remarks'}
                                        placeholder={'other_remarks'}
                                    />
                                )}
                            />
                        }

                    </>
                }
            </Box>


            <LoadingButton
                variant='contained'
                fullWidth
                onClick={handleSubmit(onSubmit)}
            >
                Update
            </LoadingButton>
        </Box>
    )
}
export default RmActionForm
