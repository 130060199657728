import { Box, IconButton, alpha, useMediaQuery, useTheme } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react'
import { ThemeDataTable } from '../../components/theme-data-table-container';
import TableLoading from '../../components/theme-table-container/TableLoading';
import NoDataView from '../../components/noDataView';
import TitleBar from '../../components/container/TitleBar';
import PageContainer from '../../components/container/page-container';
import API from '../../api';
import { camelCaseWords, capitalizeWords, currencyFormatter } from '../../utils/app-helper';
import { Sync } from '@mui/icons-material';
import moment from 'moment';
import { useDocumentTitle } from '../../hooks/useDocumnetTitle';

export default function VehicleReport() {

    useDocumentTitle('Vehicles')

    const theme = useTheme()
    const [vehicles, setVehicles] = useState<any[]>([])
    const [fetching, setFetching] = useState<boolean>(true)

    const fetchVehicles = useCallback(() => {
        setFetching(true)
        API.get('/fetch_vehicle_list')
            .then((res) => {
                res.data.status == 'success' &&
                    res.data?.data && setVehicles(res.data?.data)
            })
            .catch((err) => console.error(err))
            .finally(() => setFetching(false))
    }, [])

    useEffect(() => {
        fetchVehicles()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Vehicles Report'} secondChild={
                <IconButton
                    onClick={fetchVehicles}
                    size='small'
                    sx={{
                        mr: 2,
                        background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                        boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                        border: `1px solid ${theme.palette.background.paper}`,
                    }}>
                    <Sync />
                </IconButton>
            } />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                }}>

                <VehiclesTable data={vehicles} fetching={fetching} />
            </Box>
        </PageContainer>
    )
}

const VehiclesTable = (props: any) => {

    const { data, fetching } = props
    const theme = useTheme()

    const columns: GridColDef[] = [
        {
            field: 'registration_no', headerName: 'Registration Number', flex: 1, minWidth: 140,
            renderCell: (params: GridRenderCellParams) =>
                <span style={{ textTransform: 'uppercase' }}>{params.value}</span>
        },
        { field: 'type', headerName: 'Type', flex: 1, minWidth: 100 },
        { field: 'manufacturer', headerName: 'Manufacturer', flex: 1, minWidth: 100 },
        { field: 'year_of_make', headerName: 'Make Year', flex: 1, minWidth: 100 },
        { field: 'chassis_no', headerName: 'Chassis Number', flex: 1, minWidth: 100 },
        { field: 'engine_no', headerName: 'Engine Number', flex: 1, minWidth: 100 },
        {
            field: 'asset_operator_detail', headerName: 'Operator', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => `${value?.name ?? ''} - ${value?.code ?? ''}`
        },
        { field: 'owned_by', headerName: 'Owned By', flex: 1, minWidth: 100 },
        { field: 'owned_by_name', headerName: 'Provider Name', flex: 1, minWidth: 160 },
        {
            field: 'updated_at', headerName: 'Updated At', flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams) =>
                <span>{moment(params.value).format('DD-MM-YYYY HH:mm:ss')}</span>
        },
        {
            field: 'crop', headerName: 'Status', flex: 1, minWidth: 100,
            valueFormatter: ({ value }) => `${value == '1' ? 'Active' : 'Inactive'}`
        },
    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetching}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}


