import React from 'react'
import { alpha, Avatar, Box, IconButton, Paper, Stack, Toolbar, Typography, useTheme } from "@mui/material"
import NavigationList from "./navigation-items"
import { useNavigate } from 'react-router'
import { LogoutRounded, ManageAccountsRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import useLogout from '../../hooks/useLogout'
import { useSelector } from 'react-redux'
import { User } from '../../models/user'
import { RootState } from '../../store/store'
import { camelCaseWords } from '../../utils/app-helper'


export default function LgDrawer(props: any) {

    const { open, handleDrawerClose, drawerWidth, hovered, setHovered } = props

    const theme = useTheme()

    return (
        <Paper
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            sx={{
                position: 'fixed',
                left: 0,
                height: '100vh',
                overflowX: 'hidden',
                // overflowY: 'auto',
                width: open || hovered ? `${drawerWidth}px` : '60px',
                background: theme.palette.background.paper,
                transition: 'width 300ms ease-in-out',
                zIndex: theme.zIndex.drawer,
                boxShadow: !open && hovered ? `3px 0 12px -5px #83838360` : 0,
                display: 'flex',
                flexFlow: 'column',
                '&::-webkit-scrollbar-thumb': {
                    background: `transparent`,
                    borderRadius: '50vh',
                    transition: 'all 300ms ease-in-out',
                },
                '&::-webkit-scrollbar-track': {
                    background: `transparent`,
                },
                '&:hover': {
                    '&::-webkit-scrollbar-thumb': {
                        background: `linear-gradient(135deg, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
                    },
                    '&::-webkit-scrollbar-track': {
                        background: theme.palette.action.hover,
                    },
                    '& .MuiList-root': {
                        gap: 1,
                        py: 0,
                        overflowX: 'hidden',
                        overflowY: 'auto',
                    },
                },
                '& .MuiList-root': {
                    gap: 1,
                    py: 0,
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                    '& a': {
                        color: theme.palette.text.primary,
                        textDecoration: 'none',
                        cursor: 'default',
                    },
                    '& .MuiListItemButton-root': {
                        height: '36px',
                        borderRadius: '0 50vh 50vh 0',
                        mr: open || hovered ? 2 : 0,
                        whiteSpace: 'nowrap',
                        mb: 1,
                        border: '1px solid #ffffff00',
                        '& .MuiListItemIcon-root': {
                            minWidth: '46px',
                        },
                        '& svg': {
                            height: '32px',
                            width: '32px',
                            p: 0.9,
                            borderRadius: '50vh',
                            color: theme.palette.text.disabled,
                            '&.submenuArrow': {
                                position: 'absolute',
                                height: '16px',
                                width: '16px',
                                left: '4px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                p: 0,

                            },
                        },
                    },
                    '& .activeMenu .MuiListItemButton-root': {
                        borderWidth: (open || hovered) ? `1px 1px 1px 0` : 0,
                        borderStyle: 'solid',
                        borderColor: (open || hovered) ? theme.palette.primary.main : 'none',
                        color: theme.palette.primary.main,
                        '& svg': {
                            background: open || hovered ? alpha(theme.palette.action.hover, 0) : theme.palette.action.hover,
                            color: theme.palette.primary.main,
                        },
                    },
                    '& .navItem.open': {
                        background: open || hovered ? alpha(theme.palette.text.disabled, 0.05) : alpha(theme.palette.text.disabled, 0),
                        borderRadius: open || hovered ? '18px 18px 0 0' : 0,
                        '& +.MuiCollapse-root': {
                            background: open || hovered ? alpha(theme.palette.text.disabled, 0.05) : alpha(theme.palette.text.disabled, 0),
                            borderRadius: open || hovered ? '0 0 18px 18px' : 0,
                        },
                    },
                },
            }}>
            <Toolbar sx={{ mb: 3, }} />
            <NavigationList toggleDrawer={handleDrawerClose} open={open} hovered={hovered} />
            <UserBlockLg open={open} hovered={hovered} />
        </Paper >
    )
}


const UserBlockLg = ({ open, hovered }: any) => {

    const theme = useTheme()
    const navigate = useNavigate();
    const logout = useLogout()
    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser


    return (
        <>
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'flex-end',
                    width: '100%',
                    maxWidth: '100%',
                    p: '6px',
                    position: 'sticky',
                    bottom: 2,
                    background: theme.palette.background.paper,
                    '& *': {
                        whiteSpace: 'nowrap',
                    },
                    '& .userBlock': {
                        boxShadow: '0 0 12px -3px #83838370 inset',
                        borderRadius: '24px',
                        p: '8px 8px 8px',
                        flex: 1,
                        maxWidth: '100%',
                        '& .MuiTypography-root': {
                            maxWidth: '120px',
                        },
                        '& .MuiTypography-body2': {
                            fontWeight: 500,
                            lineHeight: '0.9rem',
                        }
                    }
                }}>
                <Box className="userBlock">
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1, height: 32 }}>
                        <Avatar sx={{ height: 32, width: 32 }} />
                        {(open || hovered) &&
                            <>
                                <Stack direction={'column'} sx={{ flex: 1 }} className='animate__animated animate__fadeIn'>
                                    <Typography variant='body2' className={'line-clamp clamp-1'}>
                                        {camelCaseWords(currentUser?.name ?? 'Agriwings User')}
                                    </Typography>
                                    <Typography variant='caption' className={'line-clamp clamp-1'} sx={{ fontSize: '10px' }}>
                                        {camelCaseWords(currentUser?.role?.name == 'hr'
                                            ? 'HR Department'
                                            : currentUser?.role?.name == 'rm' ? 'Regional Manager'
                                                : currentUser?.role?.name == 'rtl' ? 'Regional Territory Lead'
                                                    : currentUser?.role?.name == 'cso' ? 'CSO'
                                                        : currentUser?.role?.name == 'accounts' ? 'Account Department'
                                                            : currentUser?.role?.name == 'client' ? 'Authorised Client'
                                                                : (currentUser?.role?.name ?? 'Authorised User'))}
                                    </Typography>
                                </Stack>
                                <IconButton
                                    sx={{ height: 32, width: 32 }}
                                    onClick={() => navigate('/my-account')}>
                                    <ManageAccountsRounded sx={{ fontSize: '16px' }} />
                                </IconButton>
                            </>
                        }
                    </Box>
                    <LoadingButton
                        onClick={logout}
                        size="small"
                        variant='outlined'
                        color="warning"
                        sx={{
                            width: '100%',
                            px: 0,
                            height: '32px',
                            minWidth: '24px',
                            borderRadius: '50vh',
                            boxShadow: 'none',
                            border: `2px solid currentcolor !important`,
                            gap: 2,
                        }}>
                        {(open || hovered) && <span className='animate__animated animate__fadeInLeft'>Logout</span>}
                        <LogoutRounded sx={{ fontSize: '14px' }} />
                    </LoadingButton>
                </Box>
            </Box>
        </>

    )
}