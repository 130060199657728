import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import { updateAuth } from "../slices/auth-slice";
import { User } from "../models/user";
import API from "../api";
import { updateProcessState } from "../slices/process-slice";
import { AttendanceInterface, initialStateAttendance, updateAttendance } from "../slices/attendance-slice";

const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(updateProcessState(true));
    API.get(`/logout`)
      .then((res) => {
        toast.success("See you soon...");
      })
      .catch((err) => {
        toast.success("Oops! Something bad at our end");
      })
      .finally(() => {
        document.cookie = `accessToken=`;
        dispatch(updateAuth({ isLoggedIn: false, currentUser: {} as User }));
        dispatch(updateAttendance({ ...initialStateAttendance }))
        navigate("/auth");
        dispatch(updateProcessState(false));
      });
  };

  return logout;
};

export default useLogout;
