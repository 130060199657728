import { Box, IconButton, alpha, useMediaQuery, useTheme } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react'
import { ThemeDataTable } from '../../components/theme-data-table-container';
import TableLoading from '../../components/theme-table-container/TableLoading';
import NoDataView from '../../components/noDataView';
import TitleBar from '../../components/container/TitleBar';
import PageContainer from '../../components/container/page-container';
import API from '../../api';
import { camelCaseWords, capitalizeWords, currencyFormatter } from '../../utils/app-helper';
import { Sync } from '@mui/icons-material';
import moment from 'moment';
import { useDocumentTitle } from '../../hooks/useDocumnetTitle';

export default function OperatorReport() {

    useDocumentTitle('Operators')

    const theme = useTheme()
    const [operators, setOperators] = useState<any[]>([])
    const [fetching, setFetching] = useState<boolean>(true)

    const fetchOperators = useCallback(() => {
        setFetching(true)
        API.get('/download_opeartor_report')
            .then((res) => {
                setOperators(res.data?.data)
            })
            .catch((err) => console.error(err))
            .finally(() => setFetching(false))
    }, [])

    useEffect(() => {
        fetchOperators()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Operator Report'} secondChild={
                <IconButton
                    onClick={fetchOperators}
                    size='small'
                    sx={{
                        mr: 2,
                        background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                        boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                        border: `1px solid ${theme.palette.background.paper}`,
                    }}>
                    <Sync />
                </IconButton>
            } />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                }}>

                <OperatorsTable data={operators} fetching={fetching} />
            </Box>
        </PageContainer>
    )
}

const OperatorsTable = (props: any) => {

    const { data, fetching } = props
    const theme = useTheme()

    const columns: GridColDef[] = [
        { field: 'ao_code', headerName: 'AO Code', flex: 1, minWidth: 100 },
        { field: 'emp_id', headerName: 'Employee ID', flex: 1, minWidth: 100 },
        { field: 'ao_name', headerName: 'AO Name', flex: 1, minWidth: 160 },
        { field: 'mobile', headerName: 'AO Mobile', flex: 1, minWidth: 160 },
        { field: 'joining_date', headerName: 'Joining Date', flex: 1, minWidth: 100 },
        { field: 'is_captain', headerName: 'Is Captain', flex: 1, minWidth: 100 },
        { field: 'supervisor_name', headerName: 'Supervisor Name', flex: 1, minWidth: 100 },
        { field: 'dl_no', headerName: 'DL number', flex: 1, minWidth: 160 },
        { field: 'aadhar_no', headerName: 'Aadhar Number', flex: 1, minWidth: 140 },
        { field: 'end_date', headerName: 'End Date', flex: 1, minWidth: 100 },
        { field: 'assigned_vehicle', headerName: 'Assigned Vehicle', flex: 1, minWidth: 160 },
        { field: 'assigned_asset', headerName: 'Assigned Asset', flex: 1, minWidth: 160 },
        { field: 'status', headerName: 'Active/Inactive', flex: 1, minWidth: 100 },
        { field: 'total_acerage', headerName: 'Total Acreage', flex: 1, minWidth: 100 },
        { field: 'total_sprayed_acreage', headerName: 'Sprayed Acreage', flex: 1, minWidth: 100 },
        { field: 'owned_by_name', headerName: 'Provider Name', flex: 1, minWidth: 160 },
        { field: 'aadhaar_img', headerName: 'Aadhar Image', flex: 1, minWidth: 160 },
        { field: 'dl_img', headerName: 'DL Image', flex: 1, minWidth: 160 },
        { field: 'rpc_img', headerName: 'RPC Image', flex: 1, minWidth: 160 },
    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetching}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}


