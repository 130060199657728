import { useCallback, useEffect, useState } from "react";
import API from "../api";

export function useAvailableCrops() {
  const [availableCrops, setAvailableCrops] = useState<any[]>([]);
  const [fetchingAvailableCrops, setFetchingAvailableCrops] = useState(false);

  const fetchAvailableCrops = useCallback(async () => {
    try {
      setFetchingAvailableCrops(true);
      const response = await API.get("/get_crop_price_list", {
        headers: { Accept: "application/json" },
      });

      if (response.data?.statuscode === "200") {
        setAvailableCrops(response.data?.data || []);
      } else {
        console.error(
          "Failed to fetch crops:",
          response.data?.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching crops:", error);
    } finally {
      setFetchingAvailableCrops(false);
    }
  }, []);

  // useEffect(() => {
  //   fetchAvailableCrops();
  // }, [fetchAvailableCrops]);

  return {
    availableCrops,
    fetchAvailableCrops,
    fetchingAvailableCrops,
  };
}
