import { CalendarMonthRounded } from '@mui/icons-material'
import { alpha, Box, Button, Chip, Tab, Tabs, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowParams, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { ThemeDataTable } from '../../components/theme-data-table-container'
import TableLoading from '../../components/theme-table-container/TableLoading'
import NoDataView from '../../components/theme-table-container/NoData-'
import moment from 'moment'
import PageContainer from '../../components/container/page-container'
import TitleBar from '../../components/container/TitleBar'
import { camelCaseWords, currencyFormatter } from '../../utils/app-helper'
import ThemeDialog from '../../components/dialog-box/theme-dialog'
import { useDispatch } from 'react-redux'
import { updateProcessState } from '../../slices/process-slice'
import { Controller, useForm } from 'react-hook-form'
import { ThemeTextField } from '../../components/inputs/theme-text-field'
import { useNavigate } from 'react-router'
import { useSchemesData } from '../../hooks/useSchemesData'
import toast from 'react-hot-toast'
import API from '../../api'
import { LoadingButton } from '@mui/lab'
import { useDocumentTitle } from '../../hooks/useDocumnetTitle'
import LoadingView from '../../components/loading-view'



function Schemes() {

    const theme = useTheme()
    const navigate = useNavigate()
    const [scheme, setScheme] = useState<{ id: number | string, name: string } | null>(null)
    const closeSchemeCreateDialog = () => setScheme(null)

    const [dataType, setDataType] = useState<'active' | 'inactive'>('active')
    useDocumentTitle(dataType == 'active' ? 'Active Schemes' : `Inactive Schemes`)

    const { fetchingSchemes, schemes, fetchSchemes } = useSchemesData()
    const [allSchemes, setAllSchemes] = useState<any[]>(schemes)
    const [sorting, setSorting] = useState(true)


    const sortSchemes = (type: string) => {
        setSorting(true);
        const filteredSchemes = type == 'active'
            ? schemes.filter((scheme: any) => scheme.status === true)
            : schemes.filter((scheme: any) => scheme.status === false);
        setAllSchemes(filteredSchemes)
        setTimeout(() => {
            setSorting(false)
        }, 1000)
    }

    const changeDataType = (event: React.SyntheticEvent, newValue: 'active' | 'inactive') => setDataType(newValue)


    useEffect(() => {
        fetchSchemes()
    }, [])

    useEffect(() => {
        if (schemes?.length > 0) sortSchemes(dataType)
    }, [schemes, dataType])

    return (
        <PageContainer>
            <TitleBar title={'Schemes List'}
                secondChild={
                    <Box className={'flexCenter'} sx={{ gap: 1 }}>
                        <Box
                            sx={{
                                width: { xs: 'min(80vw, 350px)', sm: 'min(90vw, 350px)' },
                                '& .MuiTabs-root': {
                                    p: '2px',
                                    borderRadius: '18px',
                                    boxShadow: `0 0 16px ${alpha(theme.palette.text.disabled, 0.1)} inset, 0 0 19px ${alpha(theme.palette.text.disabled, 0.1)}`,
                                    border: `2px solid ${theme.palette.background.default}`,
                                    minHeight: 'auto',
                                    '& .MuiTab-root': {
                                        p: '8px 12px',
                                        borderRadius: '16px',
                                        minHeight: '28px',
                                        zIndex: 1,
                                        textTransform: 'none',
                                        '&.Mui-selected': {
                                            color: theme.palette.primary.main
                                        },
                                    },
                                    '& .MuiTabs-indicator': {
                                        zIndex: 0,
                                        borderRadius: '16px',
                                        height: '100%',
                                        boxShadow: `0 0 14px ${theme.palette.primary.main} inset`,
                                        background: theme.palette.background.paper
                                    },
                                },
                            }}>
                            <Tabs value={dataType} onChange={changeDataType} variant='fullWidth'>
                                <Tab disabled={sorting} sx={{ color: theme.palette.primary.main }} value={'active'} label="Active" />
                                <Tab disabled={sorting} sx={{ color: theme.palette.primary.main }} value={'inactive'} label="Inactive" />
                            </Tabs>
                        </Box>
                        <Button
                            variant='contained'
                            className={'buttonGradient animate__animated animate__fadeInUp'}
                            onClick={() => navigate('create')}
                        >Add New</Button>
                    </Box>
                }
            />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    '& .buttonGradient': {
                        backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        outline: `0px solid ${theme.palette.primary.main}`,
                        outlineOffset: 0,
                        boxShadow: 0,
                        textTransform: 'none',
                        transition: 'all 100ms ease',
                        '&:hover': {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: '2px',
                            '&:active': {
                                transform: 'scale(0.98) !important'
                            }
                        },
                    },

                }}>
                {sorting && <LoadingView />}
                <SchemesTable data={allSchemes} fetchingSchemes={fetchingSchemes} setScheme={setScheme} />
            </Box>


            {scheme != null &&
                <ThemeDialog
                    title={'Deactivate Scheme'}
                    open={scheme != null}
                    dialogBody={
                        <InactiveRemarksForm
                            closeDialog={closeSchemeCreateDialog}
                            scheme={scheme}
                            fetchSchemes={fetchSchemes}
                        />
                    } />
            }

        </PageContainer>
    )
}

export default Schemes


const SchemesTable = (props: any) => {

    const theme = useTheme()

    const { data, fetchingSchemes, setScheme } = props

    const columns: GridColDef[] = [
        {
            field: 'type', headerName: 'Type', width: 120, headerAlign: 'center', cellClassName: 'animate__animated animate__fadeIn',
            renderCell: (params: GridRenderCellParams) =>
                <Chip
                    size={'small'}
                    variant='outlined'
                    label={
                        params.row?.type == '2' ? 'Client'
                            : params.row?.type == '3' ? 'Subvention'
                                : params.row?.type == '4' ? 'R & D'
                                    : params.row?.type == '5' ? 'Demo'
                                        : 'General'}
                    color={params.row?.type == '2' ? 'success'
                        : params.row?.type == '3' ? 'warning'
                            : params.row?.type == '4' ? 'primary'
                                : params.row?.type == '5' ? 'secondary'
                                    : 'info'}
                    sx={{
                        fontSize: '12px',
                        lineHeight: '12px',
                        p: '2px',
                        height: '18px',
                        minWidth: '100px',
                        textTransform: 'capitalize',
                        boxShadow: `0 0 12px -4px currentcolor inset`,
                    }} />
        },
        {
            field: 'scheme_name', headerName: 'Scheme Name', flex: 1, minWidth: 180, cellClassName: 'animate__animated animate__fadeIn',
            renderCell: (params: GridRenderCellParams) =>
                <Tooltip title={camelCaseWords(params.value)} arrow placement='top'>
                    <Typography sx={{ fontSize: '13px' }}>
                        {camelCaseWords(params.value)}
                    </Typography>
                </Tooltip>
        },
        {
            field: 'discount_price', headerName: 'Discount', flex: 1, minWidth: 120, cellClassName: 'animate__animated animate__fadeIn',
            renderCell: (params: GridRenderCellParams) =>
                <Typography sx={{ fontSize: '13px', fontWeight: 600, color: theme.palette.warning.main }}>
                    {currencyFormatter(params.value)}
                </Typography>
        },
        {
            field: 'crop_name', headerName: 'Crop', minWidth: 100, cellClassName: 'animate__animated animate__fadeIn',
            renderCell: (params: GridRenderCellParams) =>
                <Typography sx={{
                    fontSize: '13px',
                    display: 'grid',
                    '& span': {
                        fontSize: '11px',
                        fontWeight: 600,
                        color: theme.palette.warning.main
                    }
                }} >
                    {camelCaseWords(params.value)}
                    <span>{currencyFormatter(params.row.crop_base_price)}</span>
                </Typography >
        },


        {
            field: 'period_from', headerName: 'Period', flex: 1, minWidth: 120, cellClassName: 'animate__animated animate__fadeIn',
            renderCell: (params: GridRenderCellParams) =>
                <Typography sx={{
                    fontSize: '13px',
                    display: 'grid',
                    '& span': {
                        fontSize: '11px',
                        fontWeight: 600,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        color: theme.palette.text.secondary,
                        '& svg': {
                            color: theme.palette.text.disabled,
                        },
                    }
                }} >
                    <span><CalendarMonthRounded sx={{ fontSize: '12px' }} /> {moment(params.row.period_from, 'YYYY-MM-DD').format('DD MMM YYYY')}</span>
                    <span><CalendarMonthRounded sx={{ fontSize: '12px' }} /> {moment(params.row.period_to, 'YYYY-MM-DD').format('DD MMM YYYY')}</span>
                </Typography >
        },
        {
            field: 'minAcreage', headerName: 'Acreage', flex: 1, minWidth: 100, cellClassName: 'animate__animated animate__fadeIn',
            renderCell: (params: GridRenderCellParams) =>
                <Typography sx={{
                    fontSize: '11px',
                    display: 'grid',
                    color: theme.palette.text.secondary,
                }}>
                    <span>Min - <strong>{params.row.min_acreage}</strong></span>
                    <span>Max - <strong>{params.row.max_acreage}</strong></span>
                </Typography>
        },

        {
            field: 'action',
            headerName: 'Status',
            width: 100,
            type: 'actions',
            cellClassName: 'stickyRight animate__animated animate__fadeIn',
            headerClassName: 'stickyLeft',
            getActions: (params: GridRowParams<any>) => [
                <Tooltip title={params.row?.status ? `Change Status` : 'Inactive'} arrow placement='top'>
                    <Chip
                        size={'small'}
                        variant='outlined'
                        label={params.row?.status ? 'Active' : 'inactive'}
                        color={params.row?.status ? 'success' : 'error'}
                        sx={{
                            fontSize: '12px',
                            lineHeight: '12px',
                            p: '2px',
                            height: '18px',
                            minWidth: '72px',
                            textTransform: 'capitalize',
                            boxShadow: `0 0 12px -4px currentcolor inset`,
                        }}
                        onClick={() => {
                            if (params.row?.status) {
                                setScheme({ id: params.row?.id, name: params.row?.scheme_name })
                            }
                        }}
                    />
                </Tooltip>,
            ],
        },
    ]

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetchingSchemes}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}


const InactiveRemarksForm = (props: any) => {

    const dispatch = useDispatch()

    const { closeDialog, scheme, fetchSchemes } = props

    const [submitting, setSubmitting] = useState<boolean>(false)

    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            scheme_id: '',
            remarks: '',
        }
    })

    const submit = (data: any) => {
        setSubmitting(true)
        dispatch(updateProcessState(true))

        API.post('update_scheme', { id: `${scheme.id}`, status: '0', remarks: data.remarks })
            .then((res) => {
                if (res.data?.status == 'success') {
                    toast.success('Success! Scheme deactivated successfully.')
                    fetchSchemes()
                    closeDialog()
                }
                if (res.data?.status == 'error') {
                    toast.error(`Error! \n ${res.data?.msg}`)
                }
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setSubmitting(false)
                dispatch(updateProcessState(false))
            })
    }

    return (
        <Box sx={{
            display: 'grid',
            p: 3,
            minWidth: 'min(90vw, 400px)',
        }}>
            <Typography sx={{ mb: 2, textAlign: 'center' }}>
                {camelCaseWords(scheme.name)}
            </Typography>

            <Controller name={`remarks`}
                control={control}
                rules={{ required: { value: true, message: 'Required' } }}
                render={({ field }) => (
                    <ThemeTextField
                        {...field} required
                        sx={{ width: '100%' }}
                        error={Boolean(errors?.remarks)}
                        helperText={(errors?.remarks?.message ?? '').toString()}
                        size={'small'} label={'Remarks'}
                        placeholder={'eg. remarks for inactive'}
                    />
                )} />
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
                '& .MuiButton-root': {
                    flex: '0 1 150px',
                    textTransform: 'none'
                },
            }}>
                <Button variant='outlined' onClick={closeDialog}>Discrad & Close</Button>
                <LoadingButton
                    variant={'contained'}
                    size={'large'}
                    loading={submitting}
                    endIcon={<></>}
                    color='error'
                    loadingPosition={'end'}
                    onClick={handleSubmit(submit)}>
                    Deactivate
                </LoadingButton>
            </Box>
        </Box>
    )
}