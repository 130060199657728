import { useCallback, useState } from "react";
import API from "../api";

export function useSupervisors(roles?: string) {

  const [supervisors, setSupervisors] = useState<any[]>([]);
  const [fetchingSupervisors, setFetchingSupervisors] = useState(false);

  const fetchSupervisors = useCallback(async (roles?: string) => {
    try {
      setFetchingSupervisors(true);
      const response = await API.post("/fetch_users_details", { roles: roles });

      if (response.data?.statuscode === "200") {
        setSupervisors(response.data?.data || []);
      } else {
        console.error(
          "Failed to fetch supervisors:",
          response.data?.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching supervisors:", error);
    } finally {
      setFetchingSupervisors(false);
    }
  }, []);

  return {
    fetchSupervisors,
    supervisors,
    fetchingSupervisors,
  };
}
