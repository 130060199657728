import React from 'react'
import './auth-illustrator.css'

export default function AuthIllustrator() {

    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 333.33">
            <g id="BACKGROUND">
                <path className="st0" d="M44.26,237.08c64.22,81.87,201.21,64.31,289.05,47.17c44.3-8.64,91.46-21.31,125.77-53.01
		c39.62-36.59,35.38-75.59-17.35-93.53c-50.34-17.13-99.56-23.58-148.13-47.95c-44.92-22.53-86.95-59.29-136.92-69.45
		C36.14-4.2-26.56,146.8,44.26,237.08z"/>
            </g>
            <g id="OBJECTS">
                <g>
                    <g>
                        <g>
                            <polygon className="st1" points="30.49,316.25 8.56,316.25 5.55,283.91 33.5,283.91 " />
                            <g>
                                <path className="st2" d="M8.28,151.99c-1.28,1.27-0.11,4.23,2.43,6.79c2.54,2.56,5.48,3.76,6.76,2.49
						c1.28-1.27,0.11-4.23-2.43-6.79C12.51,151.92,9.57,150.72,8.28,151.99z"/>
                                <path className="st2" d="M21.5,161.29c1.27,1.28,4.23,0.11,6.79-2.43c2.56-2.54,3.76-5.48,2.49-6.76s-4.23-0.11-6.79,2.43
						C21.43,157.06,20.23,160.01,21.5,161.29z"/>
                                <path className="st2" d="M8.28,167.66c-1.28,1.27-0.11,4.23,2.43,6.79s5.48,3.76,6.76,2.49c1.28-1.27,0.11-4.23-2.43-6.79
						C12.51,167.59,9.57,166.39,8.28,167.66z"/>
                                <path className="st2" d="M23.99,170.19c-2.56,2.54-3.76,5.48-2.49,6.77c1.27,1.28,4.23,0.11,6.79-2.43
						c2.56-2.54,3.76-5.48,2.49-6.76C29.51,166.48,26.56,167.65,23.99,170.19z"/>
                                <path className="st2" d="M8.28,183.32c-1.28,1.27-0.11,4.23,2.43,6.79c2.54,2.56,5.48,3.76,6.76,2.49
						c1.28-1.27,0.11-4.23-2.43-6.79C12.51,183.25,9.57,182.06,8.28,183.32z"/>
                                <path className="st2" d="M23.99,185.86c-2.56,2.54-3.76,5.48-2.49,6.76c1.27,1.28,4.23,0.11,6.79-2.43
						c2.56-2.54,3.76-5.48,2.49-6.76C29.51,182.15,26.56,183.32,23.99,185.86z"/>
                                <path className="st2" d="M19.6,138.93c-1.8-0.01-3.07,2.91-3.08,6.52c-0.02,3.61,1.22,6.54,3.02,6.55c1.8,0.01,3.07-2.91,3.08-6.52
						C22.63,141.87,21.4,138.94,19.6,138.93z"/>
                            </g>
                            <g>
                                <rect x="19.07" y="153.51" className="st3" width="0.91" height="146.51" />
                            </g>
                        </g>
                        <g>
                            <path className="st3"
                                d="M35.27,287.8H3.78l-0.76-8.15h33.02L35.27,287.8z M4.6,286.9h29.85l0.59-6.34H4.01L4.6,286.9z" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st1"
                                    points="480.97,116.95 459.05,116.95 456.04,94.57 483.99,94.57 					" />
                                <g>
                                    <path className="st4" d="M458.77,58.13c-1.28,1.27-0.11,4.23,2.43,6.79c2.54,2.56,5.48,3.76,6.76,2.49
							c1.28-1.27,0.11-4.23-2.43-6.79C463,58.06,460.05,56.86,458.77,58.13z"/>
                                    <path className="st4" d="M471.99,67.43c1.27,1.28,4.23,0.11,6.79-2.43c2.56-2.54,3.76-5.48,2.49-6.76s-4.23-0.11-6.79,2.43
							C471.92,63.2,470.72,66.15,471.99,67.43z"/>
                                    <path className="st4" d="M458.77,73.79c-1.28,1.27-0.11,4.23,2.43,6.79c2.54,2.56,5.48,3.76,6.76,2.49
							c1.28-1.27,0.11-4.23-2.43-6.79C463,73.72,460.05,72.52,458.77,73.79z"/>
                                    <path className="st4" d="M474.48,76.33c-2.56,2.54-3.76,5.48-2.49,6.77c1.27,1.28,4.23,0.11,6.79-2.43
							c2.56-2.54,3.76-5.48,2.49-6.76C480,72.62,477.04,73.79,474.48,76.33z"/>
                                    <path className="st4" d="M470.08,45.06c-1.8-0.01-3.07,2.91-3.08,6.52c-0.02,3.61,1.22,6.54,3.02,6.55
							c1.8,0.01,3.07-2.91,3.08-6.52C473.12,48,471.89,45.07,470.08,45.06z"/>
                                </g>
                                <g>
                                    <rect x="469.56" y="59.64" className="st3" width="0.91" height="51.03" />
                                </g>
                                <g>
                                    <path className="st3"
                                        d="M485.76,99.36h-31.5l-0.76-8.15h33.01L485.76,99.36z M455.09,98.46h29.85l0.59-6.34H454.5L455.09,98.46z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <g>
                                <rect x="443.54" y="114.21" className="st1" width="52.94" height="3.33" />
                            </g>
                            <g>
                                <g>
                                    <g>

                                        <rect x="482.5" y="119.61"
                                            transform="matrix(0.5603 -0.8283 0.8283 0.5603 114.925 456.6237)"
                                            className="st1"
                                            width="10.1" height="0.9" />
                                    </g>
                                    <g>
                                        <rect x="484.04" y="116.19" className="st1" width="0.91" height="13.49" />
                                    </g>
                                </g>
                                <g>
                                    <g>

                                        <rect x="452.03" y="115.01"
                                            transform="matrix(0.8286 -0.5599 0.5599 0.8286 10.34 273.9044)"
                                            className="st1"
                                            width="0.9" height="10.1" />
                                    </g>
                                    <g>
                                        <rect x="455.08" y="116.19" className="st1" width="0.91" height="13.49" />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path className="st5" d="M353.11,188.95c-0.23-0.53,0.16-1.13,0.71-1.29c0,0-2.85,0.82-3.17-0.29c-0.26-0.89,0.79-1.43,0.79-1.43
					s-2.45,0.71-2.77-0.41c-0.32-1.11,2.13-1.82,2.13-1.82c-0.61,0.18-1.26-0.18-1.43-0.79c-0.45-1.56,5.22-2.71,5.22-2.71
					s-2.66-0.6-2.47-1.94c0.21-1.5,10.72-0.44,10.72-0.44c0.57,0.11,3.22,7.7,3.11,8.27l-6.95,2.25l-0.56,0.16
					C358.45,188.49,353.62,190.13,353.11,188.95z"/>
                            <g>
                                <line className="st6" x1="350.35" y1="183.84" x2="355.69" y2="182.29" />

                                <rect x="350.24" y="182.61"
                                    transform="matrix(0.9606 -0.278 0.278 0.9606 -36.98 105.3756)"
                                    className="st3" width="5.56" height="0.91" />
                            </g>
                            <g>
                                <line className="st6" x1="350.99" y1="186.06" x2="356.34" y2="184.52" />

                                <rect x="350.89" y="184.84"
                                    transform="matrix(0.961 -0.2766 0.2766 0.961 -37.4533 105.0537)"
                                    className="st3" width="5.56" height="0.9" />
                            </g>
                            <g>
                                <line className="st6" x1="353.42" y1="187.77" x2="356.98" y2="186.75" />

                                <rect x="353.35" y="186.81"
                                    transform="matrix(0.9606 -0.278 0.278 0.9606 -38.0536 106.1117)"
                                    className="st3" width="3.71" height="0.91" />
                            </g>
                        </g>
                        <path className="st4" d="M400.08,143.06c-5.46,21.67-18.34,27.69-38.21,33.01c-0.19,0.05,3.44,11.44,3.64,11.38
				c6.49-1.88,43.89-5.19,47.57-52.77C413.09,134.68,400.71,140.55,400.08,143.06z"/>
                    </g>
                    <g>
                        <g>
                            <g>
                                <path className="st5" d="M424.71,133.22L424.71,133.22c-2.15,0.05-3.93-1.65-3.99-3.8l-0.19-2.3l-0.14-11.78l7.79-0.18l0.33,14.08
						C428.57,131.39,426.87,133.17,424.71,133.22z"/>
                            </g>
                            <path className="st5" d="M419.86,125.4c-6.67-0.07-9.67-6.85-9.15-14.01c0.63-8.67,5.51-15.99,14.36-14.29
					C441.21,100.21,436.31,125.58,419.86,125.4z"/>
                            <g>
                                <g>
                                    <path className="st3" d="M424.17,111.25c-0.06,0.43-0.46,0.74-0.89,0.68c-0.43-0.06-0.74-0.46-0.68-0.89
							c0.06-0.43,0.46-0.74,0.89-0.68C423.93,110.42,424.23,110.82,424.17,111.25z"/>
                                </g>
                                <g>
                                    <path className="st3" d="M415.82,110.89c-0.06,0.43-0.46,0.74-0.89,0.68c-0.43-0.06-0.74-0.46-0.68-0.89
							c0.06-0.43,0.46-0.74,0.89-0.68C415.57,110.06,415.88,110.46,415.82,110.89z"/>
                                </g>
                                <g>
                                    <path className="st3" d="M425.47,110.17c-0.43-0.86-1.18-1.48-1.97-1.61c-0.69-0.11-1.37,0.14-1.97,0.73l-0.64-0.64
							c0.8-0.79,1.78-1.14,2.76-0.98c1.08,0.18,2.07,0.97,2.63,2.1L425.47,110.17z"/>
                                </g>
                                <g>
                                    <path className="st3" d="M419.39,114.8l-3.12-0.18l0.22-5.33c-0.34-0.65-0.8-1.03-1.34-1.09c-0.65-0.07-1.42,0.32-1.98,1.04
							l-0.71-0.56c0.77-0.97,1.81-1.48,2.79-1.38c0.88,0.1,1.63,0.7,2.1,1.69l0.05,0.1l-0.19,4.67l2.23,0.13L419.39,114.8z"/>
                                </g>
                            </g>
                        </g>
                        <path className="st3" d="M423.1,94.9c-13.64,0.6-13.95,7.9-6.91,10.05c2.49,0.76,13.1-1.84,14.12,2.28c0.68,2.78-0.19,7.76-3.35,7.21
				c-5.93-1.03-15.5-0.75-16.61,2.21c-1.68,4.5,2.28,9.61,8.23,10.65c6.02,1.05,14.06-2.09,16.16-11.66
				C436.1,109.37,437.23,94.28,423.1,94.9z"/>
                        <path className="st5" d="M433.64,109.19l-0.48,6.53c0.04,0,0.07,0,0.11-0.01c1.82-0.19,3.14-1.83,2.94-3.65
				C436.05,110.62,434.99,109.5,433.64,109.19z"/>
                        <g>
                            <path className="st5" d="M419.06,117.85c-0.9,0-1.76-0.29-2.53-0.86l0.54-0.73c0.99,0.74,1.98,0.75,2.64,0.62
					c1.05-0.2,2.02-0.87,2.59-1.8l0.77,0.48c-0.7,1.14-1.9,1.97-3.19,2.22C419.6,117.83,419.33,117.85,419.06,117.85z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <polygon className="st5"
                                points="476.52,306.95 484.12,300.07 470.78,266.52 455.27,271.11 				" />
                            <polygon className="st5"
                                points="467.74,316.74 488.06,308.11 484.12,300.07 466.66,315.96 				" />
                            <g>
                                <g>
                                    <polygon className="st4"
                                        points="475.99,307.47 485.94,302.76 488.55,308.36 467.74,316.74 466.66,315.96 						" />
                                </g>
                                <polygon className="st3"
                                    points="466.26,316.13 470.57,312 471.55,314.13 488.16,306.86 489.01,308.61 466.87,317.24 					" />
                                <path className="st4"
                                    d="M475.38,310.04l2.38-1.64c0,0-1.25-4.55-2.4-4.15C474.05,304.72,475.38,310.04,475.38,310.04z" />
                            </g>
                        </g>
                        <path className="st1" d="M467.24,298.7l15.87-6.61l-17.31-45.32l-3.56-62.23c0,0-11.05-16.7-31.55-15.92l0,0l3.97,35.39l7.73,42.8
				L467.24,298.7z"/>
                        <g>
                            <g>
                                <polygon className="st5"
                                    points="421.73,311.59 432.06,307.04 433.44,272.56 420.05,280.27 					" />
                                <polygon className="st5"
                                    points="410.75,316.62 432.81,315.96 432.06,307.04 410.02,315.5 					" />
                                <g>
                                    <g>
                                        <polygon className="st4"
                                            points="421.8,310.98 432.78,310.21 433.18,316.37 410.75,316.62 410.02,315.5 							" />
                                    </g>
                                    <polygon className="st3"
                                        points="409.59,315.51 415.11,313.23 415.24,315.57 433.36,314.83 433.51,316.77 409.75,316.77 						" />
                                    <path className="st4"
                                        d="M420.3,313.15l2.82-0.67c0,0,0.49-4.7-0.73-4.73C420.99,307.72,420.3,313.15,420.3,313.15z" />
                                </g>
                            </g>
                            <path className="st1"
                                d="M434.47,304.79l-15.64-0.18l-10.55-121.7c0,0,10.76-14.32,22.2-14.32l4.16,2.56L434.47,304.79z" />
                            <g>
                                <polygon className="st3" points="434.92,304.79 434.02,304.78 434.2,204.3 427.98,201.58 428.35,200.75 435.11,203.71 435.11,204
											"/>
                            </g>
                        </g>
                        <g>
                            <path className="st3" d="M458.58,202c-4.13,0-8.28-2.27-10.4-5.82l0.78-0.47c2.18,3.63,6.63,5.81,10.84,5.31l0.11,0.9
					C459.46,201.97,459.02,202,458.58,202z"/>
                        </g>
                    </g>
                    <g>
                        <polygon className="st5"
                            points="432.53,137.09 418.32,137.78 417.87,129.67 431.22,128.66 			" />
                        <path className="st4" d="M452.88,143.19c-2.28-9.78-12.02-16.31-21.85-15.09c-0.26,0.03-0.52,0.06-0.78,0.09
				c-0.31,2.73-2.46,4.89-5.27,5.19c-0.17,0.02-0.35,0.03-0.52,0.03v0c-2.7,0.06-5.14-1.58-6.2-3.93c-0.64,0.06-1.27,0.12-1.91,0.18
				c-9.88,0.88-17.24,9.22-16.37,19.16c1.54,16.12,3.09,32.23,4.63,48.35l58.99-6.28C460.02,174.99,456.45,159.09,452.88,143.19z"/>
                        <g>
                            <path className="st3" d="M404.15,197.21l-4.63-48.35c-0.88-10.11,6.49-18.74,16.78-19.65l0.08,0.9c-9.79,0.87-16.8,9.07-15.96,18.67
					l4.63,48.34L404.15,197.21z"/>
                        </g>
                        <g>
                            <path className="st3" d="M424.35,138.67c-3.44,0-6.61-1.68-8.44-4.61l0.77-0.48c2.04,3.27,5.76,4.8,9.69,3.98l0.18,0.89
					C425.81,138.59,425.08,138.67,424.35,138.67z"/>
                        </g>
                    </g>
                    <g>

                        <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -70.6586 330.4484)" className="st2"
                            cx="363.56"
                            cy="250.52" rx="67.53" ry="67.53" />

                        <ellipse transform="matrix(0.2382 -0.9712 0.9712 0.2382 33.6396 543.9222)" className="st1"
                            cx="363.56" cy="250.52" rx="60.45" ry="60.45" />
                        <path className="st7" d="M391.22,196.82l-81.36,81.36c5.7,11.12,14.74,20.24,25.8,26.04l81.6-81.6
				C411.46,211.56,402.34,202.51,391.22,196.82z"/>
                        <g>
                            <g>
                                <polygon className="st3"
                                    points="363.17,250.45 363.17,209.52 364.08,209.52 364.08,249.05 384.66,239.87 385.03,240.7 					" />
                            </g>
                        </g>
                        <g>
                            <path className="st3" d="M363.56,305.37c-30.24,0-54.85-24.61-54.85-54.85c0-30.24,24.61-54.85,54.85-54.85
					c30.24,0,54.85,24.61,54.85,54.85C418.41,280.76,393.8,305.37,363.56,305.37z M363.56,196.57c-29.75,0-53.94,24.2-53.94,53.94
					c0,29.75,24.2,53.94,53.94,53.94c29.74,0,53.94-24.2,53.94-53.94C417.5,220.77,393.3,196.57,363.56,196.57z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path className="st5" d="M403.14,200.9c-0.37-0.44-0.18-1.13,0.3-1.44c0,0-2.48,1.63-3.12,0.66c-0.51-0.77,0.33-1.6,0.33-1.6
					s-2.13,1.4-2.77,0.43c-0.64-0.97,1.5-2.37,1.5-2.37c-0.53,0.35-1.25,0.2-1.6-0.33c-0.89-1.36,4.19-4.13,4.19-4.13
					s-2.71,0.21-2.93-1.13c-0.24-1.5,10.12-3.59,10.12-3.59c0.57-0.07,5.34,6.41,5.41,6.98l-5.98,4.2l-0.48,0.32
					C408.11,198.88,403.97,201.87,403.14,200.9z"/>
                            <g>
                                <line className="st6" x1="399" y1="196.82" x2="403.65" y2="193.77" />

                                <rect x="398.54" y="194.85"
                                    transform="matrix(0.8362 -0.5485 0.5485 0.8362 -41.3653 252.124)"
                                    className="st3" width="5.56" height="0.9" />
                            </g>
                            <g>
                                <line className="st6" x1="400.27" y1="198.76" x2="404.92" y2="195.71" />

                                <rect x="399.81" y="196.78"
                                    transform="matrix(0.8361 -0.5485 0.5485 0.8361 -42.2174 253.1581)"
                                    className="st3" width="5.56" height="0.9" />
                            </g>
                            <g>
                                <line className="st6" x1="403.09" y1="199.68" x2="406.19" y2="197.65" />

                                <rect x="402.78" y="198.21"
                                    transform="matrix(0.8361 -0.5485 0.5485 0.8361 -42.6661 254.5143)"
                                    className="st3" width="3.71" height="0.9" />
                            </g>
                        </g>
                        <g>
                            <path className="st4" d="M434.29,140.47c2.2,24.29-8.47,34.15-26.58,45.53c-0.17,0.11,6.66,9.91,6.84,9.8
					c3.13-1.92,40.51-16.37,32.37-60.44"/>
                            <path className="st3" d="M414.54,196.29c-0.33,0-0.84-0.69-3.24-4.1c-1.32-1.87-3.04-4.38-3.71-5.46c-0.28-0.46-0.52-0.85-0.11-1.11
					c19.52-12.26,28.44-22.14,26.36-45.11l0.9-0.08c2.3,25.35-10.02,35.39-26.44,45.74c0.92,1.52,5.3,7.8,6.33,9.07
					c0.27-0.14,0.63-0.32,1.07-0.54c31.58-15.71,34.17-40.89,30.77-59.26l0.89-0.16c3.45,18.67,0.82,44.27-31.26,60.23
					c-0.63,0.31-1.08,0.54-1.32,0.68C414.7,196.25,414.62,196.29,414.54,196.29z"/>
                        </g>
                    </g>
                </g>
                <g>
                    <path className="st2" d="M343.77,93.57h39.22c13.4,0,24.26-10.86,24.26-24.26v0c0-13.4-10.86-24.26-24.26-24.26h-39.22
			c-13.4,0-24.26,10.86-24.26,24.26v0C319.51,82.71,330.37,93.57,343.77,93.57z"/>
                    <polygon className="st2" points="391.76,107.51 387.63,84.29 369.57,92.03 		" />
                    <g>
                        <g>
                            <rect x="375.56" y="58.14" className="st3" width="14.13" height="0.91" />
                        </g>
                        <g>
                            <rect x="336.7" y="65.21" className="st3" width="52.99" height="0.91" />
                        </g>
                        <g>
                            <rect x="336.7" y="72.27" className="st3" width="12.36" height="0.91" />
                        </g>
                        <g>
                            <rect x="354.37" y="72.27" className="st3" width="35.33" height="0.91" />
                        </g>
                        <g>
                            <rect x="354.37" y="79.34" className="st3" width="35.33" height="0.91" />
                        </g>
                    </g>
                </g>
                <g>
                    <path className="st3"
                        d="M189.01,182.69c-0.59-6.7-5.94-11.99-12.64-12.5l-53.15-4.03l-2.02,25.88l67.81,3.36L189.01,182.69z" />
                    <g>
                        <g>
                            <polygon className="st3"
                                points="52.48,193.83 69.89,317.7 235.86,317.7 222.17,220.34 218.45,193.83 				" />
                            <g>
                                <polygon className="st4"
                                    points="214.45,220.34 48.49,220.34 52.21,193.83 218.18,193.83 					" />
                                <g>
                                    <polygon className="st6"
                                        points="200.77,317.7 34.81,317.7 48.47,220.48 214.43,220.48 						" />
                                    <path className="st3" d="M201.16,318.15H34.28l13.79-98.13h166.88l-0.07,0.52L201.16,318.15z M35.33,317.24h165.05l13.54-96.32
							H48.86L35.33,317.24z"/>
                                </g>
                                <g>
                                    <polygon className="st2"
                                        points="179.27,269.12 157.38,269.12 160.38,247.74 182.28,247.74 						" />
                                    <polygon className="st2"
                                        points="132.54,290.28 110.65,290.28 113.65,268.9 135.55,268.9 						" />
                                    <polygon className="st4"
                                        points="88.9,290.28 67.01,290.28 70.01,268.9 91.91,268.9 						" />
                                    <g>
                                        <g>
                                            <path className="st3" d="M73.39,248.26H50.58l3.13-22.28h22.81L73.39,248.26z M51.62,247.35H72.6l2.88-20.47H54.5L51.62,247.35z
									"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M95.24,248.26H72.43l3.13-22.28h22.81L95.24,248.26z M73.47,247.35h20.98l2.88-20.47H76.35
									L73.47,247.35z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M117.1,248.26H94.29l3.13-22.28h22.81L117.1,248.26z M95.33,247.35h20.98l2.88-20.47H98.21
									L95.33,247.35z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M138.95,248.26h-22.81l3.13-22.28h22.81L138.95,248.26z M117.18,247.35h20.98l2.88-20.47h-20.98
									L117.18,247.35z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M160.81,248.26H138l3.13-22.28h22.81L160.81,248.26z M139.04,247.35h20.98l2.88-20.47h-20.98
									L139.04,247.35z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M182.66,248.26h-22.81l3.13-22.28h22.81L182.66,248.26z M160.89,247.35h20.98l2.88-20.47h-20.98
									L160.89,247.35z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M204.52,248.26h-22.81l3.13-22.28h22.81L204.52,248.26z M182.75,247.35h20.98l2.88-20.47h-20.98
									L182.75,247.35z"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st3" d="M70.39,269.57H47.58l3.13-22.28h22.81L70.39,269.57z M48.62,268.67H69.6l2.88-20.47H51.5L48.62,268.67z
									"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M92.25,269.57H69.44l3.13-22.28h22.81L92.25,269.57z M70.48,268.67h20.98l2.88-20.47H73.36
									L70.48,268.67z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M114.1,269.57H91.29l3.13-22.28h22.81L114.1,269.57z M92.33,268.67h20.98l2.88-20.47H95.21
									L92.33,268.67z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M135.96,269.57h-22.81l3.13-22.28h22.81L135.96,269.57z M114.19,268.67h20.98l2.88-20.47h-20.98
									L114.19,268.67z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M157.81,269.57H135l3.13-22.28h22.81L157.81,269.57z M136.04,268.67h20.98l2.88-20.47h-20.98
									L136.04,268.67z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M201.52,269.57h-22.81l3.13-22.28h22.81L201.52,269.57z M179.75,268.67h20.98l2.88-20.47h-20.98
									L179.75,268.67z"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st3" d="M67.4,290.88H44.59l3.13-22.28h22.81L67.4,290.88z M45.63,289.98h20.98l2.88-20.47H48.51L45.63,289.98z
									"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M89.25,290.88H66.44l3.13-22.28h22.81L89.25,290.88z M67.48,289.98h20.98l2.88-20.47H70.36
									L67.48,289.98z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M111.11,290.88H88.3l3.13-22.28h22.81L111.11,290.88z M89.34,289.98h20.98l2.88-20.47H92.22
									L89.34,289.98z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M132.96,290.88h-22.81l3.13-22.28h22.81L132.96,290.88z M111.19,289.98h20.98l2.88-20.47h-20.98
									L111.19,289.98z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M154.82,290.88h-22.81l3.13-22.28h22.81L154.82,290.88z M133.05,289.98h20.98l2.88-20.47h-20.98
									L133.05,289.98z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M176.67,290.88h-22.81l3.13-22.28h22.81L176.67,290.88z M154.9,289.98h20.98l2.88-20.47h-20.98
									L154.9,289.98z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M198.53,290.88h-22.81l3.13-22.28h22.81L198.53,290.88z M176.76,289.98h20.98l2.88-20.47h-20.98
									L176.76,289.98z"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st3"
                                                d="M64.4,312.2H41.59l3.13-22.28h22.81L64.4,312.2z M42.63,311.29h20.98l2.88-20.47H45.51L42.63,311.29z"
                                            />
                                        </g>
                                        <g>
                                            <path className="st3" d="M86.26,312.2H63.45l3.13-22.28h22.81L86.26,312.2z M64.49,311.29h20.98l2.88-20.47H67.37L64.49,311.29z
									"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M108.11,312.2H85.3l3.13-22.28h22.81L108.11,312.2z M86.34,311.29h20.98l2.88-20.47H89.22L86.34,311.29
									z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M129.97,312.2h-22.81l3.13-22.28h22.81L129.97,312.2z M108.2,311.29h20.98l2.88-20.47h-20.98
									L108.2,311.29z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M151.82,312.2h-22.81l3.13-22.28h22.81L151.82,312.2z M130.05,311.29h20.98l2.88-20.47h-20.98
									L130.05,311.29z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M173.67,312.2h-22.81l3.13-22.28h22.81L173.67,312.2z M151.91,311.29h20.98l2.88-20.47h-20.98
									L151.91,311.29z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M195.53,312.2h-22.81l3.13-22.28h22.81L195.53,312.2z M173.76,311.29h20.98l2.88-20.47h-20.98
									L173.76,311.29z"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g>
                            <path className="st3" d="M141.11,110.75l-15.52,3.69c5.79,16.21,21.25,30.58,43.14,33.19c4.69-5.18,8.31-10.88,10.82-16.71
					C160.6,132.61,146.65,122.41,141.11,110.75z"/>
                            <g>
                                <g>
                                    <path className="st6" d="M144.3,194.37h-39.75c-12.15,0-14.36-8.51-14.2-14.2c0.16-5.61,2.43-22.31,2.43-22.31l51.52,3.25V194.37z
							"/>
                                    <path className="st3" d="M144.75,194.83h-40.21c-4.8,0-8.51-1.31-11.02-3.9c-3.32-3.41-3.7-8.21-3.63-10.77
							c0.15-5.57,2.42-22.19,2.44-22.36l0.06-0.42l52.36,3.3V194.83z M93.17,158.34c-0.35,2.62-2.23,16.82-2.37,21.85
							c-0.07,2.41,0.28,6.94,3.37,10.11c2.34,2.4,5.83,3.62,10.38,3.62h39.3v-32.39L93.17,158.34z"/>
                                </g>
                                <g>
                                    <polygon className="st5"
                                        points="138.99,242.72 130.38,237.06 141.44,202.67 153.75,204 						" />
                                    <polygon className="st5"
                                        points="147.46,250.17 126.42,243.9 130.95,235.87 148.64,249.33 						" />
                                    <g>
                                        <g>
                                            <polygon className="st4"
                                                points="139.18,242.13 128.9,238.6 125.89,244.18 147.46,250.17 148.64,249.33 								" />
                                        </g>
                                        <polygon className="st3" points="149.05,249.45 144.69,245.94 143.56,248.05 126.37,242.72 125.39,244.47 148.36,250.57
								"/>
                                        <path className="st4"
                                            d="M139.71,244.53l-2.44-1.34c0,0,1.53-4.46,2.72-4.17C141.36,239.34,139.71,244.53,139.71,244.53z" />
                                    </g>
                                </g>
                                <g>
                                    <path className="st6" d="M104.54,194.37l33.67,0.81l-10.99,33.88l23.36,6.61l14.99-48.05c2.64-8.82-4.01-14.72-10.63-15.23
							l-40.66-4.03"/>
                                    <path className="st3" d="M150.88,236.23l-24.24-6.86l10.95-33.75l-33.06-0.8l0.02-0.91l34.28,0.83l-11.04,34.01l22.49,6.36
							l14.86-47.63c1.35-4.49,0.08-7.69-1.22-9.59c-1.95-2.84-5.4-4.77-9.01-5.05l-40.67-4.03l0.09-0.9l40.66,4.03
							c3.87,0.3,7.58,2.38,9.68,5.44c1.41,2.06,2.79,5.53,1.34,10.37L150.88,236.23z"/>
                                </g>
                                <g>
                                    <path className="st3" d="M137.17,231.84l-0.86-0.27l14.1-44.83l-40.97-0.84c-5.95,0-9.72-1.05-11.86-3.3
							c-2.56-2.7-2.35-6.8-2.12-11.14l0.01-0.23c0.29-5.55,2.3-22.17,2.32-22.34l0.9,0.11c-0.02,0.17-2.03,16.76-2.32,22.28
							l-0.01,0.23c-0.22,4.32-0.42,8.05,1.88,10.47c1.93,2.03,5.6,3.02,11.21,3.02l42.19,0.87L137.17,231.84z"/>
                                </g>
                            </g>
                            <g>
                                <rect x="109.58" y="101.66" className="st5" width="16.31" height="8.38" />
                                <path className="st2" d="M144.64,118.73c-0.19-9.97-8.39-17.49-18.31-17.32c-0.53,0.01-1.07,0.01-1.6,0.02
						c-0.19,3.2-2.8,5.93-6.07,6.2v0c-0.18,0.01-0.35,0.02-0.52,0.02c-3.22,0-5.84-2.48-5.94-5.75l-0.01-0.48
						c-0.21,0-0.42,0-0.63-0.01c-9.9-0.17-18.89,7.35-20.13,17.32c-1.87,16.19-3.89,48.61-3.89,48.61l57.61-0.05
						C144.98,151.11,144.81,134.92,144.64,118.73z"/>
                                <g>
                                    <g>
                                        <path className="st3" d="M144.7,151.48c-0.08-8.09-0.17-12.3-0.26-16.37c-0.08-4.07-0.17-8.28-0.26-16.38
								c-0.02-1.5-0.25-3.03-0.69-4.53c-0.41-1.42-1.01-2.78-1.78-4.04c-0.75-1.24-1.67-2.38-2.72-3.39
								c-1.04-1.01-2.22-1.88-3.49-2.6c-1.29-0.73-2.67-1.29-4.1-1.68c-1.49-0.4-3.01-0.61-4.53-0.62c-0.48,0-0.97,0-1.45,0.01
								l-0.3,0c-0.4,3.29-3.1,5.92-6.45,6.2c-3.73,0.31-6.84-2.51-6.95-6.17c0-0.16,0-0.35,0-0.5l0.9,0.03c0,0.14-0.01,0.31,0,0.45
								c0.1,3.14,2.76,5.55,5.97,5.29c2.99-0.25,5.48-2.78,5.66-5.77l0.03-0.42l1.12-0.02c0.49-0.01,0.98-0.02,1.47-0.01
								c1.59,0.01,3.19,0.23,4.76,0.66c1.51,0.41,2.96,1,4.31,1.77c1.34,0.76,2.57,1.68,3.67,2.74c1.11,1.07,2.07,2.27,2.86,3.57
								c0.81,1.33,1.44,2.76,1.88,4.26c0.46,1.58,0.7,3.19,0.72,4.78c0.08,8.09,0.17,12.3,0.26,16.37
								c0.08,4.07,0.17,8.28,0.26,16.38L144.7,151.48z"/>
                                    </g>
                                </g>
                                <g>

                                    <rect x="115.96" y="138.88"
                                        transform="matrix(0.056 -0.9984 0.9984 0.056 -52.3437 269.6334)"
                                        className="st3" width="0.91" height="47.24" />
                                </g>
                            </g>
                            <g>
                                <path className="st5" d="M139.18,142.99c-2.56-1.19-5.37-1.56-8.18-1.77v9.5c4.74,0.11,9.79,0.2,14.29,0.32
						C145.06,147.44,142.32,144.45,139.18,142.99z"/>
                                <path className="st2" d="M88.54,139.67c-5.69-11.39,0.21-29.04,13.99-36.25c0,0,5.76,7.28,9.05,10.12
						c-19.42,21.63-7.04,27.21,20.67,23.33c0,0,1.23,14.81,1.23,14.81c0,0-17.7,1.61-22.58,0.9
						C102.25,151.35,92.83,148.26,88.54,139.67z"/>
                                <g>
                                    <path className="st3" d="M118.72,153.57c-10.83,0-24.61-2.44-30.59-13.68c-4.67-8.77-2.48-23.88,9.25-33.98l0.59,0.69
							c-11.39,9.81-13.54,24.41-9.04,32.87c9.59,18.04,40.25,12.62,44.06,11.87c-0.17-2.08-0.96-11.62-1.15-13.92
							c-15.92,2.83-26.87,1.29-30.09-4.24c-2.3-3.95-0.52-9.47,5.28-16.41l0.69,0.58c-5.46,6.52-7.25,11.83-5.19,15.37
							c3,5.16,14.08,6.55,29.64,3.72l0.49-0.09l0.04,0.5c1.23,14.83,1.23,14.83,1.23,14.85v0.36l-0.35,0.08
							C133.43,152.17,126.92,153.57,118.72,153.57z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <polygon className="st1" points="149.68,119.72 142.4,150.45 122.52,151.62 122.08,154.82 185.95,154.82 193.98,119.72
								"/>
                                        <g>
                                            <path className="st3" d="M168.21,140.93c-1.12,0-2.11-0.45-2.81-1.27c-0.77-0.9-1.08-2.18-0.87-3.49
									c0.42-2.54,2.68-4.62,5.06-4.62c1.12,0,2.11,0.45,2.81,1.27c0.77,0.9,1.08,2.18,0.87,3.49
									C172.85,138.86,170.58,140.93,168.21,140.93z M169.59,132.46c-1.95,0-3.82,1.73-4.16,3.86c-0.17,1.06,0.07,2.06,0.67,2.76
									c0.52,0.61,1.27,0.95,2.12,0.95c1.95,0,3.82-1.73,4.16-3.86c0.17-1.06-0.07-2.06-0.66-2.76
									C171.18,132.8,170.43,132.46,169.59,132.46z"/>
                                        </g>
                                    </g>
                                </g>
                                <g>
                                    <polygon className="st3"
                                        points="122.54,152.07 122.49,151.17 142.03,150.02 149.24,119.62 150.12,119.83 142.76,150.88 						" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st5" d="M184.38,151.41c-0.02-0.58,0.56-1,1.13-0.94c0,0-2.95-0.27-2.85-1.42c0.08-0.92,1.26-1.05,1.26-1.05
							s-2.54-0.23-2.43-1.38c0.1-1.15,2.64-0.92,2.64-0.92c-0.64-0.06-1.11-0.62-1.05-1.26c0.15-1.62,5.27-0.91,6.72,0.13
							c2.21,1.58,0.46,7.34-1.62,8.52C187.28,153.59,184.43,152.69,184.38,151.41z"/>
                                    <g>
                                        <line className="st6" x1="183.67" y1="145.64" x2="189.21" y2="146.14" />

                                        <rect x="185.98" y="143.11"
                                            transform="matrix(0.0898 -0.996 0.996 0.0898 24.3857 318.4688)"
                                            className="st3" width="0.91" height="5.56" />
                                    </g>
                                    <g>
                                        <line className="st6" x1="183.46" y1="147.95" x2="189" y2="148.45" />

                                        <rect x="185.78" y="145.42"
                                            transform="matrix(0.0898 -0.996 0.996 0.0898 21.8977 320.3618)"
                                            className="st3" width="0.91" height="5.56" />
                                    </g>
                                    <g>
                                        <line className="st6" x1="185.1" y1="150.42" x2="188.79" y2="150.76" />

                                        <rect x="186.49" y="148.74"
                                            transform="matrix(0.0898 -0.996 0.996 0.0898 20.1664 323.2489)"
                                            className="st3" width="0.91" height="3.71" />
                                    </g>
                                </g>
                            </g>
                            <g className={'head'}>
                                <g>
                                    <g>
                                        <path className="st5" d="M118.18,106.44L118.18,106.44c2.18,0.02,4.17-1.74,4.19-3.89l-0.03-2.26l-0.01-11.78l-7.91-0.08
								l-0.15,14.08C114.24,104.65,115.99,106.41,118.18,106.44z"/>
                                        <polygon className="st3"
                                            points="122.37,101.22 112.17,94.02 123,87.53 							" />
                                    </g>
                                    <path className="st5" d="M122.87,98.55c6.67-0.16,9.58-6.98,8.96-14.13c-0.75-8.66-5.72-15.92-14.54-14.1
							C101.2,73.64,106.42,98.94,122.87,98.55z"/>
                                    <g>
                                        <g>
                                            <path className="st3" d="M118.37,84.46c0.07,0.43,0.47,0.73,0.9,0.66c0.43-0.07,0.73-0.47,0.66-0.9
									c-0.07-0.43-0.47-0.73-0.9-0.66C118.61,83.62,118.31,84.03,118.37,84.46z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M126.72,83.99c0.07,0.43,0.47,0.73,0.9,0.66c0.43-0.07,0.73-0.47,0.66-0.9
									c-0.07-0.43-0.47-0.73-0.9-0.66C126.95,83.16,126.66,83.56,126.72,83.99z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M117.06,83.4L116.24,83c0.55-1.14,1.52-1.94,2.6-2.13c0.97-0.18,1.95,0.16,2.77,0.94l-0.63,0.65
									c-0.61-0.58-1.29-0.83-1.98-0.7C118.22,81.9,117.48,82.53,117.06,83.4z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M123.52,90.99c-0.25,0-0.51-0.02-0.77-0.07c-1.3-0.23-2.51-1.04-3.22-2.17l0.76-0.49
									c0.58,0.92,1.56,1.58,2.62,1.77c0.66,0.12,1.65,0.1,2.63-0.65l0.55,0.72C125.32,90.69,124.44,90.99,123.52,90.99z"/>
                                        </g>
                                        <g>
                                            <path className="st3" d="M124.89,88.43l-0.14-0.89l1.28-0.2l-0.94-5.12l0.06-0.13c0.46-0.99,1.2-1.59,2.08-1.7
									c0.98-0.12,2.03,0.38,2.82,1.34l-0.7,0.57c-0.58-0.71-1.34-1.09-2-1.02c-0.53,0.07-0.98,0.43-1.31,1.06l1.05,5.74
									L124.89,88.43z"/>
                                        </g>
                                    </g>
                                </g>
                                <path className="st3" d="M108.73,87.43l6.15-8.69c0,0,15.63,1.84,18.51-2.2c2.2-3.09,0-8.38-8.38-8.38
						C92.93,68.16,108.73,87.43,108.73,87.43z"/>
                                <path className="st5" d="M112.1,86.38c-0.17,1.82-1.78,3.16-3.61,2.99c-1.82-0.17-3.16-1.78-2.99-3.61
						c0.17-1.82,1.78-3.16,3.61-2.99C110.93,82.95,112.27,84.56,112.1,86.38z"/>
                            </g>
                            <path className="st3" d="M111.79,86.23c-1.86,0.27-4.85-5.73-4.85-5.73c0-1.34,8.19-4.85,8.38-3.53
					C115.32,76.98,113.69,85.96,111.79,86.23z"/>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path className="st3" d="M194.66,210.12c-9.24,0-16.76-7.52-16.76-16.76s7.52-16.76,16.76-16.76s16.76,7.52,16.76,16.76h-0.91
							c0-8.74-7.11-15.86-15.86-15.86c-8.74,0-15.86,7.11-15.86,15.86c0,8.74,7.11,15.86,15.86,15.86V210.12z"/>
                                </g>
                                <circle className="st3" cx="194.44" cy="209.89" r="2.87" />
                            </g>
                            <g>
                                <g>
                                    <path className="st3" d="M63.74,210.12c-9.24,0-16.76-7.52-16.76-16.76s7.52-16.76,16.76-16.76s16.76,7.52,16.76,16.76H79.6
							c0-8.74-7.11-15.86-15.86-15.86c-8.74,0-15.86,7.11-15.86,15.86c0,8.74,7.11,15.86,15.86,15.86V210.12z"/>
                                </g>
                                <circle className="st3" cx="63.52" cy="209.89" r="2.87" />
                            </g>
                            <g>
                                <g>
                                    <path className="st3" d="M100.33,210.56c-9.24,0-16.76-7.52-16.76-16.76c0-5.22,2.37-10.05,6.5-13.25l0.56,0.72
							c-3.91,3.03-6.15,7.6-6.15,12.54c0,8.74,7.11,15.86,15.86,15.86V210.56z"/>
                                </g>
                                <circle className="st3" cx="100.11" cy="210.33" r="2.87" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <path className="st3" d="M155.41,95.73l1.62-9.13c-6.11-1.79-10.34-7.41-10.34-13.82c0-7.94,6.46-14.4,14.4-14.4h22.55
				c7.94,0,14.4,6.46,14.4,14.4c0,7.94-6.46,14.4-14.4,14.4h-15.97L155.41,95.73z M161.09,59.29c-7.44,0-13.49,6.05-13.49,13.49
				c0,6.13,4.14,11.5,10.07,13.05l0.4,0.11l-1.39,7.8l10.7-7.47h16.25c7.44,0,13.49-6.05,13.49-13.49c0-7.44-6.05-13.49-13.49-13.49
				H161.09z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path className="st4" d="M246.03,101.67c0.16-0.65,3.08-2.19,6.78-4.15c2.12-1.12,4.47-2.36,6.95-3.76H235.4
				c2.11,3.12,3.71,5.53,3.55,6.14c-3.86,15.46-14.5,20.36-23.05,24.29c-6.24,2.87-11.63,5.35-13.05,11.04l-1.84,7.35l58.42,14.59
				l1.84-7.35c1.42-5.69-2.17-10.41-6.33-15.88C249.25,126.46,242.16,117.13,246.03,101.67z"/>
                        <g>
                            <path className="st3" d="M259.66,157.57l-59.03-14.75l4.05-16.2c2.42-9.7,18.04-17.94,27.37-22.86c3.14-1.66,6.38-3.37,6.61-3.94
					c0.07-0.59-1.99-3.63-3.98-6.57c-5.92-8.73-15.82-23.35-13.4-33.05l4.05-16.2l59.03,14.75l-4.05,16.2
					c-2.42,9.7-18.04,17.94-27.37,22.86c-3.14,1.66-6.38,3.37-6.61,3.94c-0.07,0.59,1.99,3.63,3.98,6.57
					c5.92,8.73,15.82,23.35,13.4,33.05L259.66,157.57z M201.73,142.17l57.27,14.31l3.83-15.32c2.33-9.31-7.44-23.71-13.27-32.32
					c-3.08-4.55-4.33-6.43-4.11-7.3c0.22-0.87,2.2-1.95,7.06-4.51c9.2-4.85,24.59-12.97,26.91-22.28l3.83-15.32l-57.27-14.31
					l-3.83,15.32c-2.33,9.31,7.44,23.71,13.27,32.32c3.08,4.55,4.33,6.43,4.11,7.3c-0.22,0.87-2.2,1.95-7.06,4.51
					c-9.2,4.85-24.59,12.97-26.91,22.28L201.73,142.17z"/>
                        </g>
                        <g>
                            <path className="st3" d="M237.35,91.69c-4.84-7.16-14.9-22.06-12.49-31.7l0.88,0.22c-2.31,9.25,7.6,23.93,12.37,30.98L237.35,91.69z
					"/>
                        </g>

                        <rect x="222.55" y="47.79" transform="matrix(0.9702 0.2424 -0.2424 0.9702 20.1637 -60.1872)"
                            className="st2"
                            width="64.32" height="8.15" />

                        <rect x="198.18" y="145.35" transform="matrix(0.9702 0.2424 -0.2424 0.9702 43.0826 -51.3718)"
                            className="st2" width="64.32" height="8.15" />
                    </g>
                    <g>
                        <path className="st3" d="M273.78,154.21c-17.02,0-28.95-9.37-34.65-13.84c-0.9-0.71-1.62-1.27-2.14-1.62
				c-0.35-0.23-0.26-0.48,0.18-1.26l-5.52-3.48c-1.4-0.85-4.63-3-3.94-4.38c0.12-0.24,0.3-0.43,0.54-0.55
				c-0.71-0.67-1.41-1.61-0.88-2.4c0.19-0.29,0.44-0.46,0.71-0.55c-0.01-0.01-0.02-0.02-0.03-0.03c-0.95-1-0.81-1.7-0.54-2.11
				c0.28-0.41,0.87-0.8,2.16-0.3c0,0,0.01,0,0.01,0c0.01-0.29,0.1-0.59,0.27-0.85c0.18-0.26,0.45-0.43,0.79-0.48
				c1.06-0.17,2.87,0.91,4.08,1.74c-0.19-0.82-0.18-1.78,0.6-2.27c0.42-0.26,1.5-0.94,7.16,7.07c0.69-1.02,0.76-1.03,0.95-1.07
				c0.12-0.02,0.25,0,0.35,0.07c15.04,10.54,25.94,15.49,40.43,11.47c0.24-0.07,0.49,0.07,0.56,0.32c0.07,0.24-0.07,0.49-0.32,0.56
				c-14.72,4.08-25.76-0.83-40.85-11.36c-0.14,0.19-0.36,0.51-0.73,1.06c-0.08,0.12-0.22,0.2-0.37,0.2
				c-0.17-0.01-0.29-0.07-0.38-0.19c-3.37-4.83-5.74-7.34-6.35-7.34c0,0-0.01,0-0.01,0c-0.56,0.36-0.04,1.86,0.31,2.53
				c0.1,0.19,0.05,0.42-0.11,0.56c-0.16,0.14-0.4,0.14-0.57,0c-1.29-1.02-3.69-2.6-4.6-2.45c-0.13,0.02-0.16,0.07-0.18,0.09
				c-0.22,0.32-0.13,0.76,0.19,0.98c0,0,0,0,0,0c0.21,0.14,0.26,0.42,0.12,0.63c-0.14,0.21-0.42,0.26-0.63,0.12c0,0,0,0,0,0
				c-0.71-0.48-1.88-0.96-2.13-0.58c-0.18,0.27,0.25,0.78,0.45,0.99c0.41,0.43,0.9,0.76,0.9,0.77c0.19,0.13,0.26,0.38,0.15,0.59
				c-0.11,0.2-0.36,0.29-0.57,0.2c-0.01,0-0.76-0.3-1.06,0.15c-0.22,0.33,0.73,1.34,1.69,1.99c0,0,0,0,0,0
				c0.21,0.14,0.26,0.42,0.12,0.63c-0.14,0.21-0.42,0.26-0.63,0.12l0,0l0,0c-0.15-0.1-0.35-0.14-0.52-0.09
				c-0.09,0.02-0.2,0.08-0.27,0.2c-0.18,0.35,1.4,1.86,3.62,3.21l0.5,0.33l5.39,3.38c0.21,0.13,0.27,0.4,0.15,0.61
				c-0.18,0.31-0.29,0.51-0.37,0.65c0.5,0.36,1.13,0.85,1.88,1.44c6.87,5.39,22.95,18.02,46.07,12.1c0.25-0.06,0.49,0.08,0.55,0.33
				c0.06,0.24-0.08,0.49-0.33,0.55C281.67,153.74,277.6,154.21,273.78,154.21z"/>
                    </g>
                    <g>
                        <polygon className="st8"
                            points="309.56,312.65 318.85,307.91 317.04,273.9 304.82,273.95 			" />
                        <polygon className="st8"
                            points="298.15,316.99 320.75,316.99 318.85,307.91 297.35,315.86 			" />
                        <path className="st3"
                            d="M305.48,312.43c3.85,6.42,14.48,0.07,14.48,0.07l0.97,4.64h-23.77l-0.24-1.28L305.48,312.43z" />
                    </g>
                    <path className="st4" d="M297.26,290.17h28.82c0,0,1.23-124.12-43.8-122.41h0L297.26,290.17z" />
                    <g>
                        <path className="st3" d="M320.55,290.18l-0.9-0.01c0.01-0.89,0.57-89.02-26.01-114.86c-5.09-4.95-10.92-7.33-17.31-7.08l-0.03-0.9
				c6.66-0.25,12.71,2.22,17.98,7.34C321.12,200.77,320.56,289.28,320.55,290.18z"/>
                    </g>
                    <g>
                        <path className="st3" d="M318.44,119.35c-0.23,2.33-4.33,3.68-5.1,5.63c-0.85,2.16,1.21,5.79-0.18,7.45
				c-1.48,1.76-5.68,0.66-7.63,1.81c-1.97,1.16-2.75,5.17-5.06,5.64c-2.23,0.45-4.82-2.87-7.24-3.1c-2.41-0.24-5.6,2.51-7.7,1.63
				c-2.18-0.91-2.15-5-3.86-6.52c-1.69-1.5-6.03-1.24-7.13-3.26c-1.04-1.9,1.69-5.06,1.28-7.34c-0.37-2.07-4.13-4.19-3.9-6.52
				c0.23-2.34,4.34-3.71,5.11-5.69c0.85-2.17-1.22-5.81,0.16-7.5c1.47-1.8,5.71-0.77,7.65-1.96c1.97-1.21,2.69-5.25,5.01-5.76
				c2.25-0.49,4.89,2.8,7.32,3.04c2.43,0.24,5.66-2.47,7.77-1.55c2.17,0.95,2.09,5.05,3.79,6.62c1.67,1.54,6.03,1.36,7.12,3.41
				c1.03,1.93-1.71,5.09-1.31,7.39C314.91,114.87,318.67,117.02,318.44,119.35z"/>
                    </g>
                    <g>
                        <polygon className="st8"
                            points="275.74,312.65 285.03,307.91 283.22,273.9 271,273.95 			" />
                        <polygon className="st8"
                            points="264.33,316.99 286.93,316.99 285.03,307.91 263.53,315.86 			" />
                        <path className="st3"
                            d="M271.66,312.43c3.85,6.42,14.48,0.07,14.48,0.07l0.97,4.64h-23.77l-0.24-1.28L271.66,312.43z" />
                    </g>
                    <path className="st4"
                        d="M291.76,290.14h-28.82c0,0-19.52-122.41,22.41-122.41l0,0L291.76,290.14z" />
                    <g>
                        <polygon className="st3" points="291.18,290.07 286.34,195.41 280.48,193.03 280.82,192.19 287.21,194.79 287.23,195.08
				292.08,290.03 			"/>
                    </g>
                    <g>
                        <path className="st6"
                            d="M255.23,181.64c6.9-28.34,19.74-48.83,38.55-45.77c19.65,3.2,24.48,26.57,22.03,55.64L255.23,181.64z" />
                        <g>
                            <defs>
                                <path id="SVGID_00000137843948403962524810000010056341252669393819_" d="M312.33,151.95c0.01-0.01,0.03-0.01,0.04-0.02
						l-0.06-0.04c-3.49-8.72-9.42-14.54-18.53-16.03c-6.85-1.11-12.9,0.9-18.17,5.27c-8.13-3.61-14.67-10.38-21.9-19.19
						c-0.13-0.16-9,7.86-8.87,8.01c2.44,2.85,9.15,14.31,22.3,21.17c-5.03,7.99-9.01,18.55-11.92,30.51l60.58,9.87
						C317.12,175.95,316.35,162.02,312.33,151.95z"/>
                            </defs>
                            <clipPath id="SVGID_00000131328307824127642490000002705591456579150221_">
                                <use xlinkHref="#SVGID_00000137843948403962524810000010056341252669393819_" style={{ overflow: 'visible' }} />
                            </clipPath>
                            <g style={{ clipPath: `url(#SVGID_00000131328307824127642490000002705591456579150221_)` }}>
                                <g>
                                    <g>

                                        <rect x="239.74" y="129.21"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -22.9263 208.8553)"
                                            className="st2"
                                            width="1.81" height="5.79" />
                                    </g>
                                    <g>

                                        <rect x="257.76" y="129.21"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -17.6543 221.5803)"
                                            className="st2"
                                            width="1.81" height="5.79" />
                                    </g>
                                </g>
                                <g>
                                    <g>

                                        <rect x="248.88" y="121.5"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -12.8112 213.889)"
                                            className="st2"
                                            width="5.79" height="1.81" />
                                    </g>
                                </g>
                                <g>
                                    <g>

                                        <rect x="239.74" y="148.59"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -36.6345 214.5334)"
                                            className="st2"
                                            width="1.81" height="5.79" />
                                    </g>
                                    <g>

                                        <rect x="257.76" y="148.59"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -31.3619 227.2578)"
                                            className="st2"
                                            width="1.81" height="5.79" />
                                    </g>
                                    <g>

                                        <rect x="275.77" y="148.59"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -26.0865 239.9948)"
                                            className="st2"
                                            width="1.81" height="5.79" />
                                    </g>
                                    <g>

                                        <rect x="293.78" y="148.59"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.8019 252.76)"
                                            className="st2"
                                            width="1.81" height="5.79" />
                                    </g>
                                    <g>

                                        <rect x="311.8" y="148.59"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -15.5245 265.5005)"
                                            className="st2"
                                            width="1.81" height="5.79" />
                                    </g>
                                </g>
                                <g>
                                    <g>

                                        <rect x="302.92" y="140.89"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -10.6914 257.7792)"
                                            className="st2"
                                            width="5.79" height="1.81" />
                                    </g>
                                    <g>

                                        <rect x="284.91" y="140.89"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -15.9674 245.0419)"
                                            className="st2"
                                            width="5.79" height="1.81" />
                                    </g>
                                    <g>

                                        <rect x="266.9" y="140.89"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -21.2463 232.2952)"
                                            className="st2"
                                            width="5.79" height="1.81" />
                                    </g>
                                    <g>

                                        <rect x="248.88" y="140.89"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -26.5216 219.5593)"
                                            className="st2"
                                            width="5.79" height="1.81" />
                                    </g>
                                </g>
                                <g>
                                    <g>

                                        <rect x="261.06" y="170.47"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -45.8581 236.0266)"
                                            className="st2"
                                            width="1.81" height="5.79" />
                                    </g>
                                    <g>

                                        <rect x="275.77" y="167.98"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -39.7941 245.6724)"
                                            className="st2"
                                            width="1.81" height="5.79" />
                                    </g>
                                    <g>

                                        <rect x="293.78" y="167.98"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -34.5108 258.4388)"
                                            className="st2"
                                            width="1.81" height="5.79" />
                                    </g>
                                    <g>

                                        <rect x="311.8" y="167.98"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -29.2334 271.1794)"
                                            className="st2"
                                            width="1.81" height="5.79" />
                                    </g>
                                </g>
                                <g>
                                    <g>

                                        <rect x="320.94" y="160.28"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -19.1237 276.1947)"
                                            className="st2"
                                            width="5.79" height="1.81" />
                                    </g>
                                    <g>

                                        <rect x="302.92" y="160.28"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -24.3997 263.4574)"
                                            className="st2"
                                            width="5.79" height="1.81" />
                                    </g>
                                    <g>

                                        <rect x="284.91" y="160.28"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -29.6757 250.72)"
                                            className="st2"
                                            width="5.79" height="1.81" />
                                    </g>
                                    <g>

                                        <rect x="266.9" y="160.28"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -34.9541 237.9729)"
                                            className="st2"
                                            width="5.79" height="1.81" />
                                    </g>
                                </g>
                                <g>
                                    <g>

                                        <rect x="311.8" y="187.36"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -42.9461 276.8425)"
                                            className="st2"
                                            width="1.81" height="5.79" />
                                    </g>
                                </g>
                                <g>
                                    <g>

                                        <rect x="320.94" y="179.66"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -32.8292 281.883)"
                                            className="st2"
                                            width="5.79" height="1.81" />
                                    </g>
                                    <g>

                                        <rect x="302.92" y="179.66"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -38.1055 269.1453)"
                                            className="st2"
                                            width="5.79" height="1.81" />
                                    </g>
                                    <g>

                                        <rect x="284.91" y="179.66"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -43.3819 256.4076)"
                                            className="st2"
                                            width="5.79" height="1.81" />
                                    </g>
                                    <g>

                                        <rect x="266.9" y="179.66"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -48.6603 243.6597)"
                                            className="st2"
                                            width="5.79" height="1.81" />
                                    </g>
                                    <g>

                                        <rect x="248.88" y="179.66"
                                            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -53.936 230.9235)"
                                            className="st2"
                                            width="5.79" height="1.81" />
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g>
                            <path className="st3" d="M316.22,192.03l-61.54-10.02l0.12-0.48c7.92-32.55,21.78-48.93,39.06-46.11
					c9.41,1.53,16.02,7.73,19.66,18.42c3.15,9.27,4.08,21.96,2.75,37.7L316.22,192.03z M255.79,181.27l59.61,9.71
					c1.26-15.39,0.34-27.79-2.75-36.85c-3.52-10.35-9.89-16.34-18.95-17.82C271.32,132.67,260.37,162.68,255.79,181.27z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path className="st8" d="M291.13,140.45L291.13,140.45c-2.12-0.39-3.52-2.42-3.13-4.53l0.28-2.29l2.25-11.56l7.66,1.4l-2.53,13.85
					C295.27,139.44,293.24,140.84,291.13,140.45z"/>
                            <polygon className="st3" points="286.61,135.2 299.33,129.4 289.93,120.96 				" />
                        </g>
                        <path className="st8" d="M287.96,131.8c-6.52-1.43-8.08-8.67-6.12-15.58c2.38-8.36,8.64-14.54,16.96-11.08
				C313.97,111.47,304.04,135.32,287.96,131.8z"/>
                        <g>
                            <g>
                                <path className="st3" d="M295.05,118.83c-0.15,0.41-0.6,0.63-1.01,0.48c-0.41-0.15-0.63-0.6-0.48-1.01
						c0.15-0.41,0.6-0.63,1.01-0.48C294.98,117.96,295.2,118.42,295.05,118.83z"/>
                            </g>
                            <g>
                                <path className="st3" d="M286.95,116.78c-0.15,0.41-0.6,0.63-1.01,0.48c-0.41-0.15-0.63-0.6-0.48-1.01
						c0.15-0.41,0.6-0.63,1.01-0.48C286.88,115.92,287.09,116.37,286.95,116.78z"/>
                            </g>
                            <g>
                                <path className="st3" d="M296.54,118.03c-0.25-0.93-0.86-1.69-1.6-1.98c-0.65-0.25-1.37-0.14-2.08,0.31l-0.49-0.76
						c0.95-0.61,1.98-0.75,2.9-0.4c1.02,0.4,1.83,1.36,2.15,2.59L296.54,118.03z"/>
                            </g>
                            <g>
                                <path className="st3" d="M289.59,124.34c-0.02,0-0.04,0-0.07,0c-1.22-0.02-2.3-0.52-3.12-1.45l0.68-0.6
						c0.82,0.93,1.79,1.13,2.46,1.14c0.02,0,0.04,0,0.05,0c1.06,0,2.12-0.46,2.85-1.24l0.66,0.62
						C292.2,123.78,290.89,124.34,289.59,124.34z"/>
                            </g>
                            <g>
                                <path className="st3" d="M287.9,121.49l-2.1-0.75l2.13-5.44c-0.2-0.68-0.57-1.12-1.08-1.29c-0.63-0.21-1.46,0.03-2.16,0.62
						l-0.58-0.69c0.95-0.79,2.08-1.09,3.02-0.78c0.84,0.27,1.45,1,1.72,2.06l0.04,0.14l-1.89,4.85l1.22,0.44L287.9,121.49z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <path className="st3" d="M309.28,107.76c-2.32-5.73-17.27-8.01-22.41-5.89c-6.16,2.54-4.5,14.17,6.02,6.35
				c-0.17,5.9,5.67,9.86,10.28,8.1c-8.97,14.62,2.25,12.47,3.6,9.3C308.13,122.45,311.6,113.5,309.28,107.76z"/>
                    </g>
                    <g>

                        <rect x="279.74" y="165.13" transform="matrix(0.1558 -0.9878 0.9878 0.1558 57.4787 429.8152)"
                            className="st3" width="0.9" height="32.29" />
                    </g>
                    <g>
                        <g>
                            <g>
                                <path className="st8" d="M238.36,119.27c0.39-0.43,1.1-0.32,1.47,0.12c0,0-1.92-2.26-1.04-3.01c0.71-0.6,1.63,0.13,1.63,0.13
						s-1.65-1.94-0.77-2.69c0.88-0.75,2.54,1.19,2.54,1.19c-0.42-0.49-0.36-1.22,0.13-1.63c1.23-1.05,4.63,3.64,4.63,3.64
						s-0.55-2.67,0.75-3.05c1.46-0.43,4.83,9.59,4.83,9.59c0.14,0.56-5.69,6.11-6.25,6.24l-4.91-5.4l-0.38-0.44
						C240.98,123.95,237.5,120.22,238.36,119.27z"/>
                                <g>
                                    <line className="st6" x1="241.89" y1="114.65" x2="245.49" y2="118.88" />

                                    <rect x="243.24" y="113.98"
                                        transform="matrix(0.7608 -0.649 0.649 0.7608 -17.488 186.0801)"
                                        className="st3" width="0.91" height="5.56" />
                                </g>
                                <g>
                                    <line className="st6" x1="240.12" y1="116.15" x2="243.73" y2="120.38" />

                                    <rect x="241.47" y="115.49"
                                        transform="matrix(0.7609 -0.6489 0.6489 0.7609 -18.8922 185.2696)"
                                        className="st3" width="0.91" height="5.56" />
                                </g>
                                <g>
                                    <line className="st6" x1="239.56" y1="119.07" x2="241.97" y2="121.89" />

                                    <rect x="240.31" y="118.62"
                                        transform="matrix(0.7609 -0.6489 0.6489 0.7609 -20.6023 185.0475)"
                                        className="st3" width="0.91" height="3.71" />
                                </g>
                            </g>
                            <path className="st6" d="M297.55,143.46c-20.67,3.07-31.2-6.1-43.84-21.52c-0.13-0.16-9,7.86-8.87,8.01
					c4.39,5.14,21.44,37.92,66.24,22.53"/>
                            <g>
                                <defs>
                                    <path id="SVGID_00000106111663152060830210000005530419543467420294_" d="M311.09,152.04
							C311.1,152.03,311.09,152.04,311.09,152.04c-3.49-8.72-30.21-15.27-35.48-10.9c-8.13-3.61-14.67-10.38-21.9-19.19
							c-0.13-0.16-9,7.86-8.87,8.01c2.44,2.85,9.15,14.31,22.3,21.17C262.12,159.11,315.11,162.11,311.09,152.04z"/>
                                </defs>
                                <clipPath id="SVGID_00000034807684940849048750000000064667190954033832_">
                                    <use xlinkHref="#SVGID_00000106111663152060830210000005530419543467420294_" style={{ overflow: 'visible' }} />
                                </clipPath>
                                <g style={{ clipPath: `url(#SVGID_00000034807684940849048750000000064667190954033832_)` }}>
                                    <g>
                                        <g>

                                            <rect x="239.74" y="129.21"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -22.9263 208.8553)"
                                                className="st2" width="1.81" height="5.79" />
                                        </g>
                                        <g>

                                            <rect x="257.76" y="129.21"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -17.6543 221.5803)"
                                                className="st2" width="1.81" height="5.79" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>

                                            <rect x="248.88" y="121.5"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -12.8112 213.889)"
                                                className="st2" width="5.79" height="1.81" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>

                                            <rect x="239.74" y="148.59"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -36.6345 214.5334)"
                                                className="st2" width="1.81" height="5.79" />
                                        </g>
                                        <g>

                                            <rect x="257.76" y="148.59"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -31.3619 227.2578)"
                                                className="st2" width="1.81" height="5.79" />
                                        </g>
                                        <g>

                                            <rect x="275.77" y="148.59"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -26.0865 239.9948)"
                                                className="st2" width="1.81" height="5.79" />
                                        </g>
                                        <g>

                                            <rect x="293.78" y="148.59"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.8019 252.76)"
                                                className="st2" width="1.81" height="5.79" />
                                        </g>
                                        <g>

                                            <rect x="311.8" y="148.59"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -15.5245 265.5005)"
                                                className="st2" width="1.81" height="5.79" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>

                                            <rect x="302.92" y="140.89"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -10.6914 257.7792)"
                                                className="st2" width="5.79" height="1.81" />
                                        </g>
                                        <g>

                                            <rect x="284.91" y="140.89"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -15.9674 245.0419)"
                                                className="st2" width="5.79" height="1.81" />
                                        </g>
                                        <g>

                                            <rect x="266.9" y="140.89"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -21.2463 232.2952)"
                                                className="st2" width="5.79" height="1.81" />
                                        </g>
                                        <g>

                                            <rect x="248.88" y="140.89"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -26.5216 219.5593)"
                                                className="st2" width="5.79" height="1.81" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>

                                            <rect x="261.06" y="170.47"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -45.8581 236.0266)"
                                                className="st2" width="1.81" height="5.79" />
                                        </g>
                                        <g>

                                            <rect x="275.77" y="167.98"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -39.7941 245.6724)"
                                                className="st2" width="1.81" height="5.79" />
                                        </g>
                                        <g>

                                            <rect x="293.78" y="167.98"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -34.5108 258.4388)"
                                                className="st2" width="1.81" height="5.79" />
                                        </g>
                                        <g>

                                            <rect x="311.8" y="167.98"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -29.2334 271.1794)"
                                                className="st2" width="1.81" height="5.79" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>

                                            <rect x="320.94" y="160.28"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -19.1237 276.1947)"
                                                className="st2" width="5.79" height="1.81" />
                                        </g>
                                        <g>

                                            <rect x="302.92" y="160.28"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -24.3997 263.4574)"
                                                className="st2" width="5.79" height="1.81" />
                                        </g>
                                        <g>

                                            <rect x="284.91" y="160.28"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -29.6757 250.72)"
                                                className="st2" width="5.79" height="1.81" />
                                        </g>
                                        <g>

                                            <rect x="266.9" y="160.28"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -34.9541 237.9729)"
                                                className="st2" width="5.79" height="1.81" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>

                                            <rect x="311.8" y="187.36"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -42.9461 276.8425)"
                                                className="st2" width="1.81" height="5.79" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>

                                            <rect x="320.94" y="179.66"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -32.8292 281.883)"
                                                className="st2" width="5.79" height="1.81" />
                                        </g>
                                        <g>

                                            <rect x="302.92" y="179.66"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -38.1055 269.1453)"
                                                className="st2" width="5.79" height="1.81" />
                                        </g>
                                        <g>

                                            <rect x="284.91" y="179.66"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -43.3819 256.4076)"
                                                className="st2" width="5.79" height="1.81" />
                                        </g>
                                        <g>

                                            <rect x="266.9" y="179.66"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -48.6603 243.6597)"
                                                className="st2" width="5.79" height="1.81" />
                                        </g>
                                        <g>

                                            <rect x="248.88" y="179.66"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -53.936 230.9235)"
                                                className="st2" width="5.79" height="1.81" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g>
                            <path className="st3" d="M289.23,156.82c-25.21,0-38.15-17.66-43.11-24.44c-0.68-0.93-1.21-1.66-1.62-2.13l-0.17-0.2l0.09-0.26
					c0.19-0.53,8.71-8.21,9.21-8.29l0.26-0.04l0.18,0.21c13.48,16.44,23.89,24.26,43.42,21.36l0.13,0.9
					c-19.84,2.94-30.43-4.88-44-21.37c-1.38,1.1-7.03,6.2-8.19,7.41c0.39,0.48,0.86,1.12,1.42,1.9
					c6.21,8.47,25.09,34.26,65.36,19.66l0.31,0.85C303.8,155.52,296.07,156.82,289.23,156.82z"/>
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="0" y="316.9" className="st3" width="500" height="0.91" />
                    </g>
                    <g>
                        <rect x="42.31" y="328.21" className="st3" width="415.38" height="0.91" />
                    </g>
                </g>
            </g>
        </svg>

    )
}