import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
  alpha,
  createFilterOptions,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { CalendarIcon } from "../../../../components/icons";
import { useNavigate } from "react-router";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { ThemeTextField, themeTextFieldSx } from "../../../../components/inputs/theme-text-field";
import API from "../../../../api";
import toast from "react-hot-toast";
import { DeleteOutlineRounded, SearchRounded } from "@mui/icons-material";
import { FormBlockTitle } from "../../../../components/title/FormBlockTitle";
import TitleBar from "../../../../components/container/TitleBar";
import moment from "moment";
import FileUploadInput from "../../../../components/inputs/fileUploadInput";
import { useDispatch } from "react-redux";
import { updateProcessState } from "../../../../slices/process-slice";
import { useAllCrops } from "../../../../hooks/useAllCrops";
import { camelCaseWords } from "../../../../utils/app-helper";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

function CreateMeeting() {
  const theme = useTheme();
  const naviagate = useNavigate();
  const dispatch = useDispatch();

  const { allCrops, fetchAllCrops, fetchingAllCrops } = useAllCrops()

  const [searchedSubDistrict, setSearchedSubDistrict] = useState("");
  const [subDistricts, setSubDistricts] = useState<any[]>([]);
  const [fetchingSubDistricts, setFetchingSubDistricts] = useState(false);

  const [farmers, setFarmers] = useState<any[]>([])
  const [fetchingFarmers, setFetchingFarmers] = useState(false)

  const {
    control,
    watch,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      meeting_type: "",
      other_meeting_type: "",
      location: null,
      meeting_date: moment(),
      village: "",
      sub_district: "",
      district: "",
      state: "",
      pin: "",
      farmers: null,
      image1: null,
      image2: null,
    },
  });
  const inputData = watch();

  const fetchSubDistrict = useCallback(
    (subdistrict_name: string) => {
      setFetchingSubDistricts(true);
      API.get(`/fetch-villages`, { params: { subdistrict_name } })
        .then((res) => {
          if (res.data?.statuscode === "200") {
            setSubDistricts(res.data?.village_data);
          } else {
            setSubDistricts([]);
          }
        })
        .catch((err) => {
          toast.success("Oops! Something bad at our end");
        })
        .finally(() => {
          setFetchingSubDistricts(false);
        });
    },
    [searchedSubDistrict]
  );

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: any) =>
      `${option?.subdistrict_name}${option?.vil_town_name}`,
  });



  const fetchFarmers = useCallback(() => {
    setFetchingFarmers(true)
    API.get(`/get-farmers`)
      .then((res) => {
        if (res.data?.statuscode == "200") {
          setFarmers(res.data?.farmer_details)
        }
      })
      .catch((err) => {
        toast.success("Oops! Something bad at our end");
      })
      .finally(() => {
        setFetchingFarmers(false)
      });
  }, [])


  const onSubmit = (data: any) => {
    const newData = {
      meeting_type: data?.meeting_type == "Others" ? data?.other_meeting_type : data?.meeting_type,
      meeting_date: moment(data?.meeting_date).format('YYYY-MM-DD'),
      village: data?.village,
      sub_district: data?.sub_district,
      district: data?.district,
      state: data?.state,
      pin: data?.pin,
      farmers: data?.farmers?.map((farmer: any, i: number) => `${farmer?.id}`).join(','),
      images: [data?.image1[0], data?.image2 ? data?.image2[0] : null],
    };

    console.log(newData)

    const image1 = data?.image1[0]?.size / (1024 * 1024) > 2; // to compare size max is 2mb
    const image2 = data?.image2 ? data?.image2[0]?.size / (1024 * 1024) > 2 : false; // to compare size max is 2mb
    if (image1 || image2) {
      toast.error(`Error! Please change ${image1 ? "Image 1" : "Image 2"} \n Max 2mb allowed.`);
    } else {
      dispatch(updateProcessState(true));
      API.post("/add-meeting", { ...newData }, { headers: { "Content-Type": "multipart/form-data", } })
        .then((res) => {
          if (res.data?.status == "success") {
            toast.success(`Meeting added successfully \nfor date ${moment().format("DD MMM YYYY")}`);
            naviagate("/activity/meeting");
          } else {
            toast.error(`${res.data?.message}`);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => {
          dispatch(updateProcessState(false));
        });
    }
  };


  useEffect(() => {
    fetchFarmers();
    fetchAllCrops()
  }, []);

  return (
    <Box
      sx={{
        mb: 5,
      }}
    >
      <TitleBar
        title={"Add Meeting"}
        backIcon
        backPath={"/activity/meeting"}
      // secondChild={
      //   <Typography
      //     className="flexCenter"
      //     sx={{
      //       background: alpha(theme.palette.text.disabled, 0.1),
      //       borderRadius: "8px",
      //       p: "1px 8px",
      //       fontSize: "12px",
      //       gap: 0.5,
      //       "& svg": {
      //         height: "14px",
      //         width: "14px",
      //       },
      //     }}
      //   >
      //     <CalendarIcon /> {moment().format("DD MMM YYYY")}
      //   </Typography>
      // }
      />

      <>
        <Controller
          name={`meeting_type`}
          control={control}
          rules={{ required: { value: true, message: "Required" } }}
          defaultValue={""}
          render={({ field }) => (
            <ThemeTextField
              {...field}
              required
              className={"formInput"}
              error={Boolean(errors?.meeting_type)}
              select
              helperText={(errors?.meeting_type?.message ?? "").toString()}
              size={"small"}
              label={"Meeting Type"}
              placeholder={"select Type"}
            >
              <MenuItem value={""} disabled>
                -- select type --
              </MenuItem>
              <MenuItem value={"Large Farmer Meeting(LFM)"}>
                Large Farmer Meeting(LFM)
              </MenuItem>
              <MenuItem value={"Small Farmer Meeting(SFM)"}>
                Small Farmer Meeting(SFM)
              </MenuItem>
              <MenuItem value={"Individual Farmer Connect(IFC)"}>
                Individual Farmer Connect(IFC)
              </MenuItem>
              <MenuItem value={"Retailer Meeting"}>Retailer Meeting</MenuItem>
              <MenuItem value={"Others"}>Others</MenuItem>
            </ThemeTextField>
          )}
        />
        {inputData?.meeting_type == "Others" && (
          <Controller
            name={`other_meeting_type`}
            control={control}
            rules={{
              required: {
                value: inputData?.meeting_type == "Others",
                message: "Required",
              },
              maxLength: { value: 30, message: "Max 30 characters" },
            }}
            defaultValue={""}
            render={({ field }) => (
              <ThemeTextField
                {...field}
                required
                className={"formInput"}
                error={Boolean(errors?.other_meeting_type)}
                helperText={(
                  errors?.other_meeting_type?.message ?? ""
                ).toString()}
                size={"small"}
                label={"Define Meeting Type"}
                placeholder={"Enter meeting type"}
              />
            )}
          />
        )}

        <Controller
          name={`location`}
          control={control}
          rules={{ required: { value: true, message: "Required" } }}
          defaultValue={null}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              size={"small"}
              autoComplete
              className={"formItem"}
              onClose={() => {
                setSearchedSubDistrict("");
                setSubDistricts([]);
              }}
              onInputChange={(e, inputvalue) => {
                setSearchedSubDistrict(inputvalue);
                inputvalue?.length <= 3 && setSubDistricts([]);
              }}
              onChange={(e, data) => {
                onChange(data);
                setValue("village", `${data?.vil_town_name ?? ""}`, { shouldDirty: true, shouldValidate: true, });
                setValue("sub_district", `${data?.subdistrict_name ?? ""}`, { shouldDirty: true, shouldValidate: true, });
                setValue("district", `${data?.district_name ?? ""}`, { shouldDirty: true, shouldValidate: true, });
                setValue("state", `${data?.state_name ?? ""}`, { shouldDirty: true, shouldValidate: true, });
              }}
              filterOptions={filterOptions}
              noOptionsText={
                subDistricts.length > 0
                  ? "No Village Found"
                  : "Search with atleast 3 characters"
              }
              value={value || null}
              loading={fetchingSubDistricts}
              sx={{
                flex: "1 1 250px",
                "& .MuiOutlinedInput-root": {
                  paddingRight: "0.15rem !important",
                },
              }}
              options={subDistricts}
              isOptionEqualToValue={((option: any, value: any) => option.subdistrict_name == value.subdistrict_name)}
              renderOption={(props, option, { selected }) => (
                <Box
                  component={"li"}
                  {...props}
                  key={props.id}
                  sx={{
                    p: "2px 6px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexFlow: "column",
                    textAlign: "left",
                    gap: "2px",
                    borderRadius: "12px",
                    mx: "2px",
                    "& .village": {
                      width: "100%",
                      fontSize: "15px",
                      lineHeight: "15px",
                      fontWeight: 500,
                      color: theme.palette.text.secondary,
                    },
                    "& .state": {
                      width: "100%",
                      fontSize: "12px",
                      lineHeight: "12px",
                      fontWeight: 500,
                      color: theme.palette.text.disabled,
                    },
                  }}
                >
                  <Typography className={"village"}>
                    {/* {option?.vil_town_name}, */}
                    {option?.subdistrict_name}
                  </Typography>
                  <Typography className={"state"}>
                    {option?.district_name}, {option?.state_name}
                  </Typography>
                </Box>
              )}
              getOptionLabel={(option) => option.subdistrict_name ?? ""}
              renderInput={(params) => (
                <ThemeTextField
                  {...params}
                  required
                  error={Boolean(errors?.location)}
                  helperText={(errors?.location?.message ??
                    inputData?.sub_district
                    ? `${inputData?.village}, ${inputData?.sub_district}, ${inputData?.district}, ${inputData?.state}`
                    : ""
                  ).toString()}
                  size={"small"}
                  label={"Location"}
                  placeholder={"Sub district"}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          size="small"
                          sx={{
                            boxShadow: `0 0 12px -3px inset ${theme.palette.primary.main}`,
                            color: theme.palette.primary.main,
                          }}
                        >
                          <SearchRounded
                            onClick={() =>
                              searchedSubDistrict?.length >= 3 &&
                              fetchSubDistrict(searchedSubDistrict)
                            }
                          />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          )}
        />

        <Box className={'flexBetweenTop'} sx={{ columnGap: 1, }}>
          <Controller
            name={`pin`}
            control={control}
            rules={{
              required: { value: true, message: "Required" },
              pattern: { value: /^[1-9][0-9]{5}$/, message: "Invalid pincode" },
            }}
            defaultValue={""}
            render={({ field }) => (
              <ThemeTextField
                {...field}
                required
                error={Boolean(errors?.pin)}
                type="number"
                helperText={(errors?.pin?.message ?? "").toString()}
                size={"small"}
                label={"Pincode"}
                style={{ flex: 1, minWidth: '100px' }}
                placeholder={"XXXXXX"}
              />
            )}
          />

          <Controller
            name={`meeting_date`}
            control={control}
            rules={{ required: { value: true, message: 'Really! Without date?' } }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileDatePicker
                  {...field}
                  disableFuture
                  // defaultValue={moment()}
                  label="Meeting Date"
                  className={'formInput mb-3'}
                  format="DD-MM-YYYY"
                  value={field.value ? moment(field.value) : null}
                  onChange={(date) => {
                    setValue('meeting_date', moment(date), { shouldDirty: true, shouldValidate: true })
                    clearErrors('meeting_date')
                  }}
                  slotProps={{
                    textField: {
                      required: true,
                      size: 'small',
                      sx: themeTextFieldSx,
                      style: { flex: 1, minWidth: '100px' },
                      error: Boolean(errors?.meeting_date),
                      helperText: errors?.meeting_date?.message?.toString() ?? '',
                    }
                  }}
                />
              </LocalizationProvider>
            )} />
        </Box>
      </>

      <Controller name={`farmers`}
        control={control}
        rules={{ required: { value: true, message: 'Required' } }}
        render={({ field: { onChange, value } }) => (
          <Autocomplete size={'small'}
            autoComplete
            disableCloseOnSelect
            multiple limitTags={1}
            loading={fetchingFarmers}
            className={'formInput'}
            onChange={(e, data) => {
              onChange(data)
            }}
            noOptionsText={'Farmers Not Available'}
            sx={{ flex: '1 1 250px' }}
            options={farmers}
            getOptionLabel={(option) => `${option?.farmer_mobile_no} - ${camelCaseWords(option?.farmer_name)}` ?? ''}
            isOptionEqualToValue={((option: any, value: any) => option.id == value.id)}
            renderOption={(props, option, { selected }) => (
              <Box component={'li'} {...props} key={props.id}
                sx={{
                  p: '2px 6px',
                  textAlign: 'left',
                  borderRadius: '12px',
                  mx: '2px',
                  fontSize: '15px',
                  lineHeight: '15px',
                  fontWeight: 500,
                  color: theme.palette.text.secondary,
                }}>
                {option?.farmer_mobile_no} - {camelCaseWords(option?.farmer_name)}
              </Box>

            )}
            renderInput={(params) => (
              <ThemeTextField
                {...params}
                required
                error={Boolean(errors?.farmers)}
                helperText={(errors?.farmers?.message ?? '').toString()}
                size={'small'} label={'Farmers'}
                placeholder={'Please select farmers'}
              />
            )} />
        )} />

      <Box
        sx={{
          width: "100%",
          display: "grid",
          justifyItems: "stretch",
          gap: 2,
          mb: 4,
          mt: 3,
        }}
      >
        <FileUploadInput
          label={"Meeting Image 1"}
          accept={"image/png, image/gif, image/jpeg"}
          reactHookProps={{ ...register(`image1`, { required: "Required" }) }}
          onClear={() => setValue(`image1`, null)}
          errorMessage={errors?.image1?.message?.toString() ?? ""}
        />
        <FileUploadInput
          label={"Meeting Image 2"}
          accept={"image/png, image/gif, image/jpeg"}
          reactHookProps={{ ...register(`image2`) }}
          onClear={() => setValue(`image2`, null)}
          errorMessage={errors?.image2?.message?.toString() ?? ""}
        />
      </Box>
      <Button variant="contained" fullWidth onClick={handleSubmit(onSubmit)}>
        Submit
      </Button>
    </Box >
  );
}

export default CreateMeeting;
