import { useCallback, useEffect, useState } from "react";
import API from "../api";
import { useDispatch } from "react-redux";
import { updateProcessState } from "../slices/process-slice";

interface QueryParams {
  page?: number | string | null;
  per_page?: number | string | null;
  search?: string | null;
}

interface UsePaginatedServicesProps {
  query: QueryParams;
}

interface ApiResponse {
  statuscode: string;
  data: any[];
  message?: string;
}

export function usePaginatedServices({ query }: UsePaginatedServicesProps) {
  const { page = 1, per_page = 50, search = '' } = query;

  const dispatch = useDispatch();
  const [paginatedServices, setPaginatedServices] = useState<any | null>(null);
  const [fetchingPaginatedServices, setFetchingPaginatedServices] = useState(false);

  const fetchPaginatedServices = useCallback(async () => {
    setFetchingPaginatedServices(true);
    try {
      const response = await API.get<ApiResponse>(`/fetch_order_list_data`, {
        params: { page, per_page, search },
        headers: { Accept: "application/json" },
        timeout: 60000, // Add timeout if needed
      });

      if (response.data?.statuscode === "200") {
        setPaginatedServices(response.data?.data ?? null);
      } else {
        console.error(
          "Failed to fetch paginated services:",
          response.data?.message || "Unknown error"
        );
      }
    } catch (error: any) {
      if (error?.code === 'ECONNABORTED') {
        console.error('Request timed out');
      } else {
        console.error('Error fetching paginated services:', error);
      }
    } finally {
      setFetchingPaginatedServices(false);
    }
  }, [dispatch, page, per_page, search]);

  return {
    fetchPaginatedServices,
    paginatedServices,
    fetchingPaginatedServices,
  };
}
