import { useCallback, useEffect, useState } from "react";
import API from "../api";

export function useAvailableAssets() {
  const [availableAssets, setAvailableAssets] = useState<any[]>([]);
  const [fetchingAvailableAssets, setFetchingAvailableAssets] = useState(false);

  const fetchAvailableAssets = useCallback(async () => {
    try {
      setFetchingAvailableAssets(true);
      const response = await API.get("/available_asset_list", {
        headers: { Accept: "application/json" },
      });

      if (response.data?.statuscode === "200") {
        setAvailableAssets(response.data?.data || []);
      } else {
        console.error(
          "Failed to fetch available assets:",
          response.data?.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching available assets:", error);
    } finally {
      setFetchingAvailableAssets(false);
    }
  }, []);

  return {
    fetchAvailableAssets,
    availableAssets,
    fetchingAvailableAssets,
  };
}
