import React from "react"
import { Skeleton, Typography, useTheme } from "@mui/material"
import API from "../../../../api"
import { camelCaseWords } from "../../../../utils/app-helper"

const UserInfoBlock = ({ userId }: { userId: string }) => {

    const theme = useTheme()

    const [details, setDetails] = React.useState<null | any>(null)
    const [loading, setLoading] = React.useState(true)

    const fecthDetails = React.useCallback(() => {
        setLoading(true)
        API.post('/get-user-detail', { user_id: `${userId}` })
            .then((res) => {
                console.log(res.data)
                setDetails(res.data?.data)
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false))
    }, [userId])


    React.useEffect(() => {
        fecthDetails()
    }, [userId])

    return (
        <Typography
            sx={{
                fontSize: '13px',
                maxWidth: '180px',
                cursor: ' pointer',
                '& span': {
                    '&.highlighted': {
                        fontSize: '11px',
                        color: theme.palette.info.main,
                        boxShadow: `0 0 6px -2px ${theme.palette.info.main} inset`,
                        borderRadius: '40px',
                        p: '1px 6px',
                        ml: 1,
                    },
                    '& svg': {
                        color: theme.palette.text.disabled,
                    },
                    '& .limitedText': {
                        maxWidth: '150px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    },
                },
            }}>

            {loading ? <Skeleton /> : <><strong>{(details?.emp_id ?? '')?.toUpperCase()}</strong><span className='highlighted'>{(details?.role ?? '')?.toUpperCase()}</span></>}
            {loading ? <Skeleton /> : <span className='line-clamp clamp-1'>{camelCaseWords(details?.name ?? '')}</span>}
        </Typography>
    )
}

export default UserInfoBlock