import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { User } from "../models/user";

export interface AuthInterface {
  isLoggedIn?: boolean;
  currentUser: User;
}

const initialState: AuthInterface = {
  isLoggedIn: false,
  currentUser: {} as User,
};

export const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    updateAuth: (state, action: PayloadAction<AuthInterface>) => {
      state.isLoggedIn = action.payload?.isLoggedIn;
      state.currentUser = action.payload?.currentUser;
    },
  },
});

export const { updateAuth } = authSlice.actions;

export default authSlice.reducer;
