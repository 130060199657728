import { Box } from '@mui/material'
import React from 'react'
import ill from '../../../assets/images/illustartions/support.png'


function Training() {
    return (
        <Box sx={{
            display: 'grid',
            placeItems: 'center',
            alignContent: 'center',
            gap: 1,
            minHeight: '60vh',
            '& img': {
                width: '200px',
                objectFit: 'contain',
            },

        }}>
            <img src={ill} alt={'coming soon'} />
            Coming soon.
        </Box>
    )
}

export default Training