import { useCallback } from "react";
import { useDispatch } from "react-redux";
import API from "../api";
import { updateProcessState } from "../slices/process-slice";
import { AttendanceInterface, updateAttendance } from "../slices/attendance-slice";

export function useAttendance() {
  const dispatch = useDispatch();

  const fetchAttendance = useCallback(async () => {
    try {
      dispatch(updateProcessState(true));

      const response = await API.get("/fetch_operator_attendance", { headers: { Accept: "application/json" } });

      if (response.data?.status === "success") {
        const fetchedAttendance = response.data?.data?.length > 0 ? response.data?.data[0] : {};
        dispatch(
          updateAttendance(
            {
              user_id: fetchedAttendance.user_id,
              in: fetchedAttendance.in,
              out: fetchedAttendance.out,
              user_mobile_no: fetchedAttendance.user_mobile_no,
              working_hours: fetchedAttendance.working_hours,
              date: fetchedAttendance.date,

              attendance_status: fetchedAttendance.attendance_status,
              emp_id: fetchedAttendance.emp_id,
              field_activity: fetchedAttendance.field_activity,
              geo_location: fetchedAttendance.geo_location,
              on_leave: fetchedAttendance.on_leave,
              out_of_station: fetchedAttendance.out_of_station,
              remarks: fetchedAttendance.remarks,
              user_remarks: fetchedAttendance.user_remarks,
            }
          )
        );
      } else {
        console.error("Failed to fetch operatorAttendance:", response.data?.message || "Unknown error")
        dispatch(updateAttendance({} as AttendanceInterface))
      }
    } catch (error) {
      console.error("Error fetching operatorAttendance:", error);
    } finally {
      dispatch(updateProcessState(false));
    }
  }, []);

  return {
    fetchAttendance,
  };
}
