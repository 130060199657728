import { alpha, Autocomplete, Box, Button, InputAdornment, MenuItem, Stack, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ThemeTextField, themeTextFieldSx } from '../../../components/inputs/theme-text-field'
import { camelCaseWords, currencyFormatter } from '../../../utils/app-helper'
import { useRegionalClients } from '../../../hooks/useRegionalClinets'
import { User } from '../../../models/user'
import { RootState } from '../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { useAvailableCrops } from '../../../hooks/useAvailableCrops'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import API from '../../../api'
import { updateProcessState } from '../../../slices/process-slice'
import { FetchedScheme } from '../create-service'
import { CurrencyRupeeRounded } from '@mui/icons-material'
import SchemeStack from '../../../components/schemeStack'
import { FormBlockTitle } from '../../../components/title/FormBlockTitle'
import operatorIllustration from '../../../assets/images/illustartions/support.png'
import toast from 'react-hot-toast'
import FloatingAlert from '../../../components/floating-alert/floating-alert'


function UpdateForm(props: any) {

    const { selectedRequest, onClose, fetchServices } = props

    const theme = useTheme()
    const dispatch = useDispatch()
    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser


    const [step, setStep] = useState<'1' | '2' | '3'>('1')


    const [client, setClient] = useState({} as any)
    const [invalidDetails, setInvalidDetails] = useState(false)
    const [fetchedSchemes, setFetchedSchemes] = useState<FetchedScheme[]>([])
    const [selectedCropPrice, setSelectedCropPrice] = useState<null | number>(null)

    const { fetchRegionalClients, fetchingRegionalClients, regionalClients } = useRegionalClients()
    const { availableCrops, fetchAvailableCrops, fetchingAvailableCrops } = useAvailableCrops()

    const { handleSubmit, control, reset, watch, setValue, setError, clearErrors, formState: { errors } } = useForm()
    const inputData = watch()


    const applicableSchemes = () => {
        dispatch(updateProcessState(true))
        API.post('/update_apply_order_scheme', {
            id: `${selectedRequest?.id}`,
            client_id: `${inputData?.client?.id}`,
            crop_id: `${inputData?.crop?.id}`,
            requested_acreage: inputData?.acreage
        })
            .then((res) => {
                console.log('amit', res.data)
                setFetchedSchemes(res.data?.data?.schemes ?? [])
                setSelectedCropPrice(res.data?.data?.crop_price)
                setStep('2')
            })
            .catch((err) => console.log(err))
            .finally(() => {
                dispatch(updateProcessState(false))
            })
    }

    const offeredDiscount = (fetchedSchemes?.length > 0 ? fetchedSchemes?.reduce((total, scheme) => total + (+scheme?.discount_price * (+inputData?.acreage ?? 0)), 0) : 0) + (+inputData?.extra_discount ?? 0)
    const totalCharges = selectedRequest?.order_type == '1' ? +(+inputData?.acreage * (selectedCropPrice ?? 0)) : offeredDiscount
    const totalPayable = totalCharges - offeredDiscount
    const agriwingsDiscount = fetchedSchemes?.filter((el) => el.type == '1')?.reduce((total, scheme) => total + (+scheme.discount_price * +inputData?.acreage), 0) + (+inputData?.extra_discount)
    const clientDiscount = offeredDiscount - agriwingsDiscount


    const applyDiscount = () => {
        if (totalPayable < 0) setInvalidDetails(true)
        else {
            setStep('3')
            setInvalidDetails(false)
        }
    }

    const updateOrder = (data: any) => {

        const newData = {
            id: `${selectedRequest?.id}`,
            spray_date: moment(inputData?.date_of_spray).format('YYYY-MM-DD'),
            crop_name: inputData?.crop?.crop_name,
            crop_id: `${inputData?.crop?.id}`,
            client_id: `${inputData?.client?.id}`,
            requested_acreage: inputData?.acreage,
            scheme_ids: fetchedSchemes.map(scheme => scheme.id).join(',') ?? '',
            total_amount: `${+totalCharges.toFixed(3)}`,
            total_discount: `${+offeredDiscount.toFixed(3)}`,
            total_payable_amount: `${+totalPayable.toFixed(3)}`,

            extra_discount: `${inputData?.extra_discount}`,
            remarks: inputData?.extra_discount ? inputData.extra_discount_remarks : '',
            amount_received: `[]`,
            agriwings_discount: `${+agriwingsDiscount.toFixed(3)}`,
            client_discount: `${+clientDiscount.toFixed(3)}`,
            client_order_id: inputData?.client.id == '1' ? `` : inputData.client_order_id,

            amended_remarks: `Spray date: ${moment(selectedRequest?.spray_date, 'YYYY-MM-DD').isSame(moment(inputData?.date_of_spray)) ? `${moment(inputData?.date_of_spray).format('DD-MM-YYYY')}` : `${moment(selectedRequest?.spray_date, 'YYYY-MM-DD').format('DD-MM-YYYY')} changed to ${moment(inputData?.date_of_spray).format('DD-MM-YYYY')}`},Acreage: ${+selectedRequest?.requested_acreage == +inputData?.acreage ? inputData?.acreage : `${selectedRequest?.requested_acreage} changed to ${inputData?.acreage}`},Crop: ${selectedRequest?.crop_name == inputData?.crop?.crop_name ? inputData?.crop?.crop_name : `${selectedRequest?.crop_name} changed to ${inputData?.crop?.crop_name}`},Client: ${selectedRequest?.client_details?.regional_client_name == inputData?.client?.regional_client_name ? inputData?.client?.regional_client_name : `${selectedRequest?.client_details?.regional_client_name} changed to ${inputData?.client?.regional_client_name}`},Client Order ID: ${selectedRequest?.client_order_id == inputData?.client_order_id ? inputData?.client_order_id : `${selectedRequest?.client_order_id ?? 'n/a'} changed to ${inputData?.client_order_id ?? 'n/a'}`},${inputData?.amended_remarks}`

        }

        if (totalPayable < 0) setInvalidDetails(true)
        else {
            setInvalidDetails(false)
            dispatch(updateProcessState(true))
            API.post('/update_order_details', newData)
                .then((res) => {
                    if (res.data?.status == 'success') {
                        toast.success('Request updated successfully!')
                        fetchServices()
                        onClose()
                    } else {
                        toast.error(`${res.data?.msg}`)
                    }
                })
                .catch((err) => console.error(err))
                .finally(() => {
                    dispatch(updateProcessState(false))
                })
        }
    }


    useEffect(() => {
        fetchRegionalClients()
        fetchAvailableCrops()
    }, [])

    useEffect(() => {
        setValue('client', regionalClients?.filter((el: any) => el.id == selectedRequest?.client_id)[0])
    }, [regionalClients?.length > 0])

    useEffect(() => {
        setValue('crop', availableCrops?.filter((crop) => crop?.id == selectedRequest?.crop_id)[0])
    }, [availableCrops?.length > 0])

    useEffect(() => {
        console.log('selectedRequest', selectedRequest)
    }, [selectedRequest])


    return (
        <Box sx={{
            width: `min(90vw, 767px)`,
            minHeight: 'min(90vh, 600px)',
            display: 'flex',
            flexFlow: 'column',
        }}>
            <Box
                sx={{
                    pt: 3,
                    '& .flexCenterTop': {
                        columnGap: 3,
                    },
                    '& .formInput': {
                        flex: '1 1 160px',
                        '&.mb-3': {
                            mb: '24px !important',
                        },
                    },
                }}>

                <FormBlockTitle title={selectedRequest?.order_id} />

                <Box className={'flexCenterTop'} sx={{
                    mt: 4,
                }}>
                    <Controller name={`client`}
                        control={control}
                        rules={{ required: { value: true, message: 'Required' } }}
                        defaultValue={''}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete size={'small'}
                                autoComplete
                                loading={fetchingRegionalClients}
                                className={'formInput'}
                                onChange={(e, data) => {
                                    onChange(data)
                                    setValue('client', data)
                                }}
                                disabled={step != '1'}
                                noOptionsText={'Clients Not Available'}
                                value={value || null}
                                sx={{ flex: '1 1 250px' }}
                                options={regionalClients}
                                getOptionDisabled={(option: any) => option?.status == '0'}
                                getOptionLabel={(option) => camelCaseWords(option?.regional_client_name) ?? ''}
                                isOptionEqualToValue={((option: any, value: any) => option.id == value.id)}
                                renderOption={(props, option, { selected }) => (
                                    <Box component={'li'} {...props} key={props.id}
                                        sx={{
                                            p: '2px 6px',
                                            textAlign: 'left',
                                            borderRadius: '12px',
                                            mx: '2px',
                                            fontSize: '15px',
                                            lineHeight: '15px',
                                            fontWeight: 500,
                                            color: theme.palette.text.secondary,
                                            textTransform: 'uppercase',
                                        }}>
                                        {option?.regional_client_name}
                                    </Box>

                                )}
                                renderInput={(params) => (
                                    <ThemeTextField
                                        {...params}
                                        required={inputData?.order_type != 'general_order'}
                                        error={Boolean(errors?.client)}
                                        helperText={(errors?.client?.message ?? '').toString()}
                                        size={'small'} label={'Client'}
                                        placeholder={'Please select client'}
                                    />
                                )} />
                        )} />

                    <Controller name={`client_order_id`}
                        control={control}
                        rules={{
                            required: { value: false, message: '' },
                            // pattern: { value: /^[a-zA-Z0-9 ]*$/, message: 'Invalid input' },
                        }}
                        defaultValue={selectedRequest?.client_order_id ?? ''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} className={'formInput'}
                                disabled={client ? (client.id == '1' || step != '1') : step != '1'}
                                error={Boolean(errors?.client_order_id)}
                                inputProps={{ maxLength: 15 }}
                                helperText={(errors?.client_order_id?.message ?? '').toString()}
                                size={'small'} label={'Client Order Id'}
                                onBlur={(e) => setValue('client_order_id', e.target.value?.toUpperCase())}
                                placeholder={'client_order_id'}
                            />
                        )} />
                </Box>

                <Box className={'flexCenterTop'}>
                    <Controller
                        name={`date_of_spray`}
                        control={control}
                        rules={{ required: { value: true, message: 'Really! Without date?' } }}
                        defaultValue={selectedRequest?.spray_date ?? null}
                        render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <MobileDatePicker
                                    {...field}
                                    disablePast
                                    disabled={step != '1'}
                                    label="Date of Spray"
                                    className={'formInput mb-3'}
                                    format='DD/MM/YYYY'
                                    value={field.value ? moment(field.value) : null}
                                    onChange={(date) => {
                                        setValue('date_of_spray', date);
                                        clearErrors('date_of_spray')
                                    }}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                            size: 'small',
                                            sx: themeTextFieldSx,
                                            style: { width: '100%' },
                                            error: Boolean(errors?.date_of_spray),
                                            helperText: errors?.date_of_spray?.message?.toString() ?? '',
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        )} />

                    <Controller name={`crop`}
                        control={control}
                        rules={{ required: { value: true, message: 'Required' } }}
                        defaultValue={''}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete size={'small'}
                                autoComplete
                                loading={fetchingAvailableCrops}
                                className={'formInput'}
                                noOptionsText={'No Crops Available'}
                                value={value || null}
                                onChange={(e, data) => {
                                    onChange(data)
                                    setValue('crop', data)
                                }}
                                options={availableCrops}
                                disabled={step != '1'}
                                getOptionLabel={(option) => camelCaseWords(option?.crop_name) ?? ''}
                                isOptionEqualToValue={((option: any, value: any) => option?.id == value?.id)}
                                renderOption={(props, option, { selected }) => (
                                    <Box component={'li'} {...props} key={props.id}
                                        sx={{
                                            p: '2px 6px',
                                            textAlign: 'left',
                                            borderRadius: '12px',
                                            mx: '2px',
                                            fontSize: '15px',
                                            lineHeight: '15px',
                                            fontWeight: 500,
                                            color: theme.palette.text.secondary,
                                        }}>
                                        {camelCaseWords(option?.crop_name)}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <ThemeTextField
                                        {...params} required
                                        error={Boolean(errors?.crop)}
                                        helperText={(errors?.crop?.message ?? '').toString()}
                                        size={'small'} label={'Crop'}
                                        placeholder={'Please select crop'}
                                    />
                                )} />
                        )} />

                    <Controller name={`acreage`}
                        control={control}
                        rules={{
                            required: { value: true, message: 'Acreage value is required' },
                            // max: {
                            //     value: +selectedFarmer?.farm_info?.filter((el: any) => el.id == inputData?.farm_location)[0]?.acerage ?? 30,
                            //     message: 'Acreage exceeds'
                            // },
                        }}
                        defaultValue={selectedRequest?.requested_acreage ?? ''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} required className={'formInput'}
                                onWheel={(e) => e.currentTarget.blur()}
                                onBlur={(e) => setValue('acreage', ((+e.target.value < 0.1) ? '' : (+e.target.value).toFixed(2)), { shouldValidate: true })}
                                error={Boolean(errors?.acreage)} type={'number'}
                                helperText={(errors?.acreage?.message ?? '').toString()}
                                size={'small'} label={'Acreage'}
                                placeholder={'acreage'}
                                disabled={step != '1'}
                            />
                        )} />
                </Box>
            </Box>

            <Box
                sx={{
                    flex: 1,
                    width: '100%',
                    display: 'grid',
                    justifyItems: 'center',
                    alignItems: step != '1' ? 'start' : 'center',
                    '& img': {
                        objectFit: 'contain',
                        maxHeight: '120px',
                        maxWidth: '90%',
                    },
                    '& .priceDetail': {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontFamily: theme.typography.h1.fontFamily,
                        fontWeight: 500,
                        '&.inner': {
                            fontSize: '12px',
                            color: theme.palette.text.secondary,

                        },
                        '&.total': {
                            fontSize: '16px',
                            fontFamily: theme.typography.h1.fontFamily,
                            fontWeight: 500,
                            color: theme.palette.success.main,
                        },
                        '& span': {
                            display: 'flex',
                            alignItems: 'center',
                            padding: '2px 8px',
                            borderRadius: '50vh',
                            '&.discount': {
                                color: theme.palette.error.main,
                            },
                            '&:has(svg)': {
                                cursor: 'pointer',
                                '& svg': {
                                    height: '14px',
                                    width: '14px',
                                    borderRadius: '50vh',
                                    '&:hover': {
                                        boxShadow: `0 0 12px -4px ${theme.palette.text.secondary} inset`
                                    },
                                },
                                '&:first-of-type': {
                                    justifyContent: 'flex-start',

                                },
                                '&:last-of-type': {
                                    justifyContent: 'flex-end',
                                    width: '30%',
                                },

                            },
                        },
                    },
                }}>
                {step == '1'
                    ? <img src={operatorIllustration} alt={'a'} />
                    : selectedRequest?.order_type != '1' && fetchedSchemes?.length < 1
                        ? <Typography sx={{ color: theme.palette.error.main, minHeight: '100px', textAlign: 'center' }}>
                            No schemes available,
                            <br />
                            Please change details
                        </Typography>
                        : <Box
                            className={'animate__animated animate__fadeIn'}
                            sx={{
                                width: '100%',
                            }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    columnGap: 3,
                                    alignItems: 'flex-start',
                                    alignContent: 'flex-start',
                                    background: theme.palette.mode == 'dark'
                                        ? '#0e0e0e'
                                        : `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.07)}, ${theme.palette.background.paper})`,
                                    boxShadow: theme.palette.mode == 'dark' ? 'none' : `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                                    border: `2px solid ${theme.palette.background.paper}`,
                                    borderRadius: '2rem',
                                    p: 3,
                                    mb: 2,
                                }}>
                                <Box sx={{ minWidth: '100%', }}>
                                    <FormBlockTitle title='Applied Schemes' />
                                </Box>
                                <Box sx={{
                                    py: 1,
                                }}>
                                    {fetchedSchemes?.map((fetchedScheme: any, index: number) =>
                                        <SchemeStack key={index} name={fetchedScheme?.scheme_name} amount={fetchedScheme?.discount_price} />
                                    )}
                                </Box>
                                <Box sx={{ flex: 1, }}>
                                    <Controller name={`extra_discount`}
                                        control={control}
                                        rules={{ required: { value: false, message: '' } }}
                                        defaultValue={''}
                                        render={({ field }) => (
                                            <ThemeTextField
                                                {...field} className={'formInput'}
                                                sx={{
                                                    mt: '1rem !important',
                                                    display: selectedRequest?.order_type != '1' ? 'none' : 'flex'
                                                }}
                                                autoFocus
                                                disabled={selectedRequest?.order_type != '1'}
                                                onBlur={(e) => {
                                                    if (e.target.value)
                                                        setValue('extra_discount', (+e.target.value).toFixed(3), { shouldValidate: true, shouldDirty: true })
                                                }}
                                                error={Boolean(errors?.extra_discount)} type={'number'}
                                                helperText={(errors?.extra_discount?.message ?? '').toString()}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"><CurrencyRupeeRounded sx={{ fontSize: '16px' }} /></InputAdornment>,
                                                }}
                                                size={'small'} label={'Extra Discount'}
                                                placeholder={'eg. 21322'}
                                            />
                                        )} />
                                    <Controller name={`extra_discount_remarks`}
                                        control={control}
                                        rules={{ required: { value: (inputData?.extra_discount != '' && inputData?.extra_discount != undefined), message: 'Remarks required for extra discount' } }}
                                        render={({ field }) => (
                                            <ThemeTextField
                                                {...field} required={inputData?.extra_discount != '' && inputData?.extra_discount != undefined}
                                                disabled={selectedRequest?.order_type != '1'}
                                                sx={{
                                                    display: selectedRequest?.order_type != '1' ? 'none' : 'flex'
                                                }}
                                                className={'formInput'}
                                                error={Boolean(errors?.extra_discount_remarks)}
                                                helperText={(errors?.extra_discount_remarks?.message ?? '').toString()}
                                                size={'small'} label={'Extra Discount Remarks'}
                                                placeholder={'eg. for goodwill'}
                                            />
                                        )} />
                                </Box>
                                <Box className={'flexCenter'} sx={{
                                    position: 'relative',
                                    minWidth: '100%'
                                }}>
                                    <FloatingAlert message='Applied discount not valid' isVisible={invalidDetails} severity='error' hideArrow animate />
                                </Box>

                                {step == '3' &&
                                    <Box
                                        className={'animate__animated animate__fadeIn'}
                                        sx={{
                                            minWidth: '100%',
                                            border: `2px solid ${theme.palette.primary.main}`,
                                            p: 1, pt: 2,
                                            borderRadius: '18px',
                                            display: 'flex',
                                            flexFlow: 'column',
                                            alignItems: 'stretch',
                                            justifyContent: 'flex-end',
                                        }}>
                                        <Typography className={'priceDetail'}><span>Total Amount</span><span>{currencyFormatter(totalCharges)}</span></Typography>
                                        <Typography className={'priceDetail'}><span>Total Discount</span><span>{currencyFormatter(+offeredDiscount ?? 0)}</span></Typography>
                                        <Typography className={'priceDetail'} sx={{ mb: 3, }}><span>Total Payable</span><span>{currencyFormatter(totalPayable)}</span></Typography>


                                        <Controller name={`amended_remarks`}
                                            control={control}
                                            rules={{ required: { value: true, message: 'Remarks required' } }}
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <ThemeTextField
                                                    {...field}
                                                    required={inputData.extra_discount != ''}
                                                    className={'formInput'}
                                                    error={Boolean(errors?.amended_remarks)}
                                                    helperText={(errors?.amended_remarks?.message ?? '').toString()}
                                                    size={'small'} label={'Remarks'}
                                                    placeholder={'eg. for goodwill'}
                                                />
                                            )} />
                                    </Box>
                                }

                            </Box>
                        </Box>

                }
            </Box>



            <Box className={'flexCenterBottom'}
                sx={{
                    flexWrap: 'wrap',
                    gap: 3,
                }}>
                <Button
                    className={'nextButton'}
                    variant={'text'}
                    onClick={() => onClose()}
                >
                    Discard & Close
                </Button>
                <Button
                    className={'nextButton'}
                    variant={'contained'}
                    disabled={!inputData?.client || !inputData?.crop || !inputData?.acreage || !inputData?.date_of_spray}
                    onClick={
                        step == '1' ? handleSubmit(applicableSchemes)
                            : step == '2' ? handleSubmit(applyDiscount)
                                : step == '3' ? handleSubmit(updateOrder)
                                    : () => { }
                    }
                >
                    {step == '3' ? 'Update' : 'Continue'}
                </Button>
            </Box>
        </Box >
    )
}

export default UpdateForm