import React, { useEffect, useState } from 'react'
import { Box, InputAdornment, MenuItem, Rating, Slider, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import { ThemeTextField, themeTextFieldSx } from '../../components/inputs/theme-text-field'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { FormBlockTitle } from '../../components/title/FormBlockTitle'
import { EmailRounded, FacebookRounded, Instagram, InstallMobileRounded, WhatsApp, YouTube } from '@mui/icons-material'
import moment, { Moment } from 'moment'
import { farmerPersonalInfo } from '../../models/farmer'


const socialPlateforms = [
    { name: 'Facebook', icon: FacebookRounded },
    { name: 'Instagram', icon: InstallMobileRounded },
    { name: 'Youtube', icon: YouTube },
]

function FarmerPersonalInfo(
    {
        control,
        errors,
        register,
        channel,
        setChannel,
        rating,
        setRating,
        influence,
        setInfluence,
        farmer,
        setValue
    }: any) {

    const [farmerPersonalData, setFarmerPersonalData] = useState<farmerPersonalInfo[]>([])

    const [dob, setDob] = useState<Moment | null>(null)
    const [wedAnniversary, setWedAnniversary] = useState<Moment | null>(null)

    useEffect(() => {
        if (farmer?.farmer_profile_info != undefined) {
            setFarmerPersonalData(farmer?.farmer_profile_info)

            if (farmer?.farmer_profile_info[0]?.date_of_birth != null) {
                setDob(moment(farmer?.farmer_profile_info[0]?.date_of_birth, 'YYYY-MM-DD'))
                setValue('date_of_birth', moment(farmer?.farmer_profile_info[0]?.date_of_birth).format('YYYY-MM-DD'))
            }
            if (farmer?.farmer_profile_info[0]?.wedding_anniversary != null) {
                setWedAnniversary(moment(farmer?.farmer_profile_info[0]?.wedding_anniversary, 'YYYY-MM-DD'))
                setValue('wedding_anniversary', moment(farmer?.farmer_profile_info[0]?.wedding_anniversary).format('YYYY-MM-DD'))
            }

        }

    }, [farmer?.farmer_profile_info])


    return (
        <Box className={'animate__animated animate__fadeIn formbox'}>

            <Box className={'formInputGroup'}>
                <Box className={'groupTop'}>
                    <Box className={'titleBlock'}>
                        <FormBlockTitle title={`Demographic Info`} icon={false} />
                    </Box>
                </Box>
                <Box className={'farmsInputArea'} >
                    <Box sx={{
                        width: '100%',
                        display: 'grid !important',
                        gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' },
                        columnGap: 2,
                    }}>
                        <Controller name={`gender`}
                            control={control}
                            rules={{ required: { value: false, message: '' } }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} className={'formInput'}
                                    error={Boolean(errors?.gender)} select
                                    helperText={(errors?.gender?.message ?? '').toString()}
                                    size={'small'} label={'Gender'}
                                    placeholder={'gender'}
                                >
                                    <MenuItem value={'male'}>Male</MenuItem>
                                    <MenuItem value={'female'}>Female</MenuItem>
                                </ThemeTextField>
                            )} />

                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <MobileDatePicker
                                disableFuture
                                label="Date of Birth"
                                className={'formInput'}
                                value={dob}
                                format={'DD-MM-YYYY'}
                                onChange={(newDate: moment.Moment | null) => {
                                    setDob(newDate)
                                    setValue('date_of_birth', moment(newDate).format('YYYY-MM-DD'))
                                }}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        sx: themeTextFieldSx,
                                        style: { width: '100%', minHeight: '64px' },
                                        error: Boolean(errors?.date_of_birth),
                                        helperText: errors?.date_of_birth?.message?.toString() ?? '',
                                    }
                                }}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <MobileDatePicker
                                disableFuture
                                label="Wedding Anniversary"
                                className={'formInput'}
                                format={'DD-MM-YYYY'}
                                value={wedAnniversary}
                                onChange={(newDate: moment.Moment | null) => {
                                    setWedAnniversary(newDate)
                                    setValue('wedding_anniversary', moment(newDate).format('YYYY-MM-DD'))
                                }}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        sx: themeTextFieldSx,
                                        style: { width: '100%', minHeight: '64px' },
                                        error: Boolean(errors?.wedding_anniversary),
                                        helperText: errors?.wedding_anniversary?.message?.toString() ?? '',
                                    }
                                }}
                            />
                        </LocalizationProvider>


                        <Controller name={`education_level`}
                            control={control}
                            rules={{ required: { value: false, message: '' } }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} className={'formInput'}
                                    error={Boolean(errors?.education_level)} select
                                    helperText={(errors?.education_level?.message ?? '').toString()}
                                    size={'small'} label={'Education Level'}
                                    placeholder={'education_level'}
                                >
                                    <MenuItem value={'no-school'}>School Not Attended</MenuItem>
                                    <MenuItem value={'middle'}>8th Class</MenuItem>
                                    <MenuItem value={'matric'}>10th Class</MenuItem>
                                    <MenuItem value={'secondary'}>12th Class</MenuItem>
                                    <MenuItem value={'graduate'}>Graduate/ Post Graduate</MenuItem>
                                    <MenuItem value={'doctorate'}>PhD/ Doctorate</MenuItem>
                                </ThemeTextField>
                            )} />

                        <Controller name={`professional_info`}
                            control={control}
                            rules={{ required: { value: false, message: '' } }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} className={'formInput'}
                                    error={Boolean(errors?.professional_info)} select
                                    helperText={(errors?.professional_info?.message ?? '').toString()}
                                    size={'small'} label={'Profession'}
                                    placeholder={'professional_info'}
                                >
                                    <MenuItem value={'agriculture'}>Agriculture Only</MenuItem>
                                    <MenuItem value={'job+agriculture'}>Agriculture + Job</MenuItem>
                                </ThemeTextField>
                            )} />

                        <Controller name={`income`}
                            control={control}
                            rules={{ required: { value: false, message: '' } }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} className={'formInput'}
                                    error={Boolean(errors?.income)} select
                                    helperText={(errors?.income?.message ?? '').toString()}
                                    size={'small'} label={'Income Level'}
                                    placeholder={'income'}
                                >
                                    <MenuItem value={'- 1 lac'}>Below ₹1,00,000</MenuItem>
                                    <MenuItem value={'1-2 lacs'}>₹1,00,000 - ₹2,00,000</MenuItem>
                                    <MenuItem value={'3-5 lacs'}>₹2,00,000 - ₹5,00,000</MenuItem>
                                    <MenuItem value={'5-7 lacs'}>₹5,00,000 - ₹7,00,000</MenuItem>
                                    <MenuItem value={'+ 7 lacs'}>Above ₹7,00,000</MenuItem>
                                </ThemeTextField>
                            )} />
                    </Box>


                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                    }}>
                        <Typography variant='body2'
                            className={'animate__animated animate__fadeIn'}
                            sx={{ px: 1, fontWeight: 500 }} >
                            Influence Level
                        </Typography>
                        <Slider step={20}
                            onChange={(e, newValue) => setInfluence(newValue)}
                            value={influence}
                            aria-label="influence"
                            valueLabelDisplay="auto"
                            sx={{
                                maxWidth: '400px'
                            }} />
                    </Box>

                </Box>
            </Box>

            <Box className={'formInputGroup'} sx={{ pb: '1rem !important', }}>
                <Box className={'groupTop'}>
                    <Box className={'titleBlock'}>
                        <FormBlockTitle title={`Interest & Hobbbies`} icon={false} />
                    </Box>
                </Box>
                <Box className={'farmsInputArea'} sx={{
                    display: 'grid !important',
                    gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' },
                    gap: 2,
                }}>

                    <Controller name={`attitude`}
                        control={control}
                        rules={{ required: { value: false, message: '' } }}
                        defaultValue={''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} className={'formInput'}
                                error={Boolean(errors?.attitude)} multiline rows={3}
                                helperText={(errors?.attitude?.message ?? '').toString()}
                                size={'small'} label={'Attitude'}
                                placeholder={'attitude'}
                            />
                        )} />
                    <Controller name={`lifestyle`}
                        control={control}
                        rules={{ required: { value: false, message: '' } }}
                        defaultValue={''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} className={'formInput'}
                                error={Boolean(errors?.lifestyle)} multiline rows={3}
                                helperText={(errors?.lifestyle?.message ?? '').toString()}
                                size={'small'} label={'Life Style'}
                                placeholder={'lifestyle'}
                            />
                        )} />
                    <Controller name={`hobbies`}
                        control={control}
                        rules={{ required: { value: false, message: '' } }}
                        defaultValue={''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} className={'formInput'}
                                error={Boolean(errors?.hobbies)} multiline rows={3}
                                helperText={(errors?.hobbies?.message ?? '').toString()}
                                size={'small'} label={'Hobbies'}
                                placeholder={'hobbies'}
                            />
                        )} />
                    <Controller name={`intrests`}
                        control={control}
                        rules={{ required: { value: false, message: '' } }}
                        defaultValue={''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} className={'formInput'}
                                error={Boolean(errors?.intrests)} multiline rows={3}
                                helperText={(errors?.intrests?.message ?? '').toString()}
                                size={'small'} label={'Interest'}
                                placeholder={'Interest'}
                            />
                        )} />
                    <Controller name={`favourite_activities`}
                        control={control}
                        rules={{ required: { value: false, message: '' } }}
                        defaultValue={''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} className={'formInput'}
                                error={Boolean(errors?.favourite_activities)} multiline rows={3}
                                helperText={(errors?.favourite_activities?.message ?? '').toString()}
                                size={'small'} label={'Favourite Activity'}
                                placeholder={'favourite_activities'}
                            />
                        )} />

                </Box>
            </Box>

            <Box className={'formInputGroup'}>
                <Box className={'groupTop'}>
                    <Box className={'titleBlock'}>
                        <FormBlockTitle title={`Technographic Info`} icon={false} />
                    </Box>
                </Box>
                <Box className={'farmsInputArea'} sx={{ pt: 2, }}>


                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 3,
                        flex: '1 1 300px',
                        minWidth: '100%',
                        alignItems: 'flex-start',
                        mb: 3,

                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            gap: 2,
                        }}>
                            <Typography variant='body2'
                                className={'animate__animated animate__fadeIn'}
                                sx={{ px: 1, minWidth: { xs: '100%', sm: 'auto' } }} >
                                Communicate on
                            </Typography>

                            <>
                                <input type="radio" name={'communicateOn'} checked={channel == 'whatsapp'} onChange={() => setChannel('whatsapp')} id={`byWhatsapp`} style={{ display: 'none' }} />
                                <Box
                                    component={'label'}
                                    htmlFor={`byWhatsapp`}
                                    className={`labelName`}>
                                    <WhatsApp />
                                    <Typography className='labelText'>WhatsApp</Typography>
                                </Box>
                            </>
                            <>
                                <input type="radio" name={'communicateOn'} checked={channel == 'email'} id={`byEmail`} onChange={() => setChannel('email')} style={{ display: 'none' }} />
                                <Box
                                    component={'label'}
                                    htmlFor={`byEmail`}
                                    className={`labelName`}>
                                    <EmailRounded />
                                    <Typography className='labelText'>Email</Typography>
                                </Box>
                            </>
                        </Box>


                    </Box>

                    <Box sx={{
                        flex: '1 1 450px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        gap: 1,
                        borderRadius: '18px',
                        userSelect: 'none',
                        minWidth: '100%',
                    }}>
                        <Typography variant='body2'
                            sx={{ px: 1, minWidth: '100%' }} >
                            Social Media
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            columnGap: 3,
                            flex: 1,
                            '& .formInput': {
                                flex: '1 1 200px',
                            },
                        }}>
                            <Controller name={`fb_link`}
                                control={control}
                                rules={{ required: { value: false, message: '' } }}
                                defaultValue={''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} className={'formInput'}
                                        error={Boolean(errors?.fb_link)}
                                        helperText={(errors?.fb_link?.message ?? '').toString()}
                                        size={'small'} placeholder={'@username'}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><FacebookRounded /></InputAdornment>,
                                        }}
                                    />
                                )} />
                            <Controller name={`insta_link`}
                                control={control}
                                rules={{ required: { value: false, message: '' } }}
                                defaultValue={''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} className={'formInput'}
                                        error={Boolean(errors?.insta_link)}
                                        helperText={(errors?.insta_link?.message ?? '').toString()}
                                        size={'small'} placeholder={'@username'}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><Instagram /></InputAdornment>,
                                        }}
                                    />
                                )} />
                            <Controller name={`yt_link`}
                                control={control}
                                rules={{ required: { value: false, message: '' } }}
                                defaultValue={''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} className={'formInput'}
                                        error={Boolean(errors?.yt_link)}
                                        helperText={(errors?.yt_link?.message ?? '').toString()}
                                        size={'small'} placeholder={'@username'}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><YouTube /></InputAdornment>,
                                        }}
                                    />
                                )} />
                            {false &&
                                socialPlateforms.map((plateform, index) => {
                                    const LabelIcon = plateform.icon
                                    return (
                                        <React.Fragment key={index}>
                                            <input {...register(plateform.name)}
                                                type="checkbox"
                                                defaultChecked={farmerPersonalData
                                                    ? farmerPersonalData[0]?.social_media_platform?.includes(`${plateform.name}`)
                                                    : false
                                                }
                                                id={`plateform-${index + 1}`}
                                                style={{ display: 'none' }} />
                                            <Box
                                                component={'label'}
                                                htmlFor={`plateform-${index + 1}`}
                                                key={index}
                                                className={`labelName`}>
                                                <LabelIcon />
                                                <Typography className='labelText'>{plateform.name}</Typography>
                                            </Box>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </Box>
                    </Box>


                    <Controller name={`tech_proficiency`}
                        control={control}
                        rules={{ required: { value: false, message: '' } }}
                        defaultValue={''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} className={'formInput'}
                                error={Boolean(errors?.tech_proficiency)} select
                                helperText={(errors?.tech_proficiency?.message ?? '').toString()}
                                size={'small'} label={'Tech Profiency'}
                                placeholder={'tech_proficiency'}
                            >
                                <MenuItem value={'low'}>Low</MenuItem>
                                <MenuItem value={'average'}>Average</MenuItem>
                                <MenuItem value={'high'}>High</MenuItem>
                            </ThemeTextField>
                        )} />

                    <Controller name={`mobile_phone_used`}
                        control={control}
                        rules={{ required: { value: false, message: '' } }}
                        defaultValue={''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} className={'formInput'}
                                error={Boolean(errors?.mobile_phone_used)} select
                                helperText={(errors?.mobile_phone_used?.message ?? '').toString()}
                                size={'small'} label={'Mobile Using'}
                                placeholder={'mobile_phone_used'}
                            >
                                <MenuItem value={'android'}>Android Phone</MenuItem>
                                <MenuItem value={'iphone'}>IPhone</MenuItem>
                                <MenuItem value={'keypad'}>Keypad Phone</MenuItem>
                                <MenuItem value={'windows'}>Windows</MenuItem>
                            </ThemeTextField>
                        )} />

                    {channel == 'email' &&
                        <Controller name={`email_id`}
                            control={control}
                            rules={{ required: { value: channel == 'email', message: '' } }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required
                                    sx={{
                                        flex: '1 1 200px'
                                    }}
                                    error={Boolean(errors?.email_id)}
                                    helperText={(errors?.email_id?.message ?? '').toString()}
                                    size={'small'} label={'Email'}
                                    placeholder={'email@your.domain'}
                                />
                            )} />
                    }
                </Box>
            </Box>

            <Box className={'formInputGroup'}>
                <Box className={'groupTop'}>
                    <Box className={'titleBlock'}>
                        <FormBlockTitle title={`Rating & Feedback`} icon={false} />
                    </Box>
                </Box>
                <Box className={'farmsInputArea'} sx={{ pb: 3, }}>

                    <Rating
                        size="large"
                        name="rating"
                        value={rating}
                        onChange={(event, newValue) => setRating(newValue)}
                        sx={{
                            mx: 'auto',
                            mb: 3,
                        }}
                    />

                    <Controller name={`suggestion_for_improvement`}
                        control={control}
                        rules={{ required: { value: false, message: '' } }}
                        defaultValue={''}
                        // defaultValue={''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} sx={{ width: '100%', }}
                                error={Boolean(errors?.suggestion_for_improvement)}
                                multiline rows={3}
                                helperText={(errors?.suggestion_for_improvement?.message ?? '').toString()}
                                size={'small'} label={'Feedback'}
                                placeholder={'suggestion_for_improvement'}
                            />
                        )} />
                </Box>
            </Box>


        </Box>
    )
}

export default FarmerPersonalInfo