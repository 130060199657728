import React from 'react'
import { Backdrop, useTheme } from "@mui/material"
import ThemeLoader from './theme-loader'

export default function LoadingView() {

    return (
        <Backdrop
            open
            className={'animate__animated animate__fadeIn'}
            sx={{
                position: 'fixed',
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.drawer,
                textAlign: 'center',
            }}>
            <ThemeLoader />
        </Backdrop >
    )
}
