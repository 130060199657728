import React, { useCallback, useEffect, useState } from 'react'
import { Box, Typography, alpha, useTheme } from "@mui/material"
import API from '../../api'
import Chart from 'react-google-charts'
import { FormBlockTitle } from '../../components/title/FormBlockTitle'
import { PhoneRounded } from '@mui/icons-material'
import { titleCaseWords } from '../../utils/app-helper'
import LoadingView from '../../components/loading-view'
import noDataIllustration from '../../assets/images/illustartions/no-data.svg'
import moment from 'moment'

export default function RmDashboard() {

    const theme = useTheme()
    const [statData, setStatData] = useState<any | null>(null)
    const [fetchingData, setFetchingData] = useState(true)

    const monthWiseData = statData?.month_wise_acreage?.map(({ month, total_requested_acreage, total_sprayed_acreage }: any) => [month?.slice(0, 3), +total_requested_acreage, +total_sprayed_acreage])

    const clientWiseData = statData?.client_acerage?.length > 0
        ? (() => {
            const sortedData = statData.client_acerage.sort((a: any, b: any) => +b[1] - +a[1])
            const slicedData = sortedData.slice(0, 9);
            const others = [
                'Others',
                sortedData.slice(10).reduce((total: number, item: any) => total + +item[1], 0),
                sortedData.slice(10).reduce((total: number, item: any) => total + +item[2], 0),
            ]
            return sortedData.length > 10 ? [...slicedData, others] : sortedData;
        })()?.map(({ client_details, total_requested_acreage, total_sprayed_acreage }: any) =>
            [client_details?.regional_client_name, +total_requested_acreage, +total_sprayed_acreage])
        : [['All Clients', 0, 0]]

    const cropWiseData = statData?.crop_wise_acerage?.length > 0
        ? (() => {
            const sortedData = statData.crop_wise_acerage?.sort((a: any, b: any) => +b[1] - +a[1])
            const slicedData = sortedData?.slice(0, 9);
            const others = [
                'Others',
                sortedData.slice(10).reduce((total: number, item: any) => total + +item[1], 0),
            ]
            return sortedData.length > 10 ? [...slicedData, others] : sortedData;
        })()?.map(({ crop, total_sprayed_acreage }: any) =>
            [crop?.crop_name, +total_sprayed_acreage])
        : [['All Crops', 0.00001]]

    const stateWiseData = statData?.state_wise_sprayed_acreage?.length > 0
        ? (() => {
            const sortedData = statData.state_wise_sprayed_acreage?.sort((a: any, b: any) => +b[1] - +a[1])
            const slicedData = sortedData?.slice(0, 9);
            const others = [
                'Others',
                sortedData.slice(10).reduce((total: number, item: any) => total + +item[1], 0),
            ]
            return sortedData.length > 10 ? [...slicedData, others] : sortedData;
        })()?.map(({ client_details, total_sprayed_acreage }: any) =>
            [client_details?.state, +total_sprayed_acreage])
        : [['All States', 0.00001]]

    monthWiseData?.unshift(["Month", "Requested", "Sprayed"])
    clientWiseData?.unshift(["Company", "Requested", "Sprayed"])
    cropWiseData?.unshift(["Crop", "Acreage"])
    stateWiseData?.unshift(["State", "Acreage"])

    const fetchDashboardDetails = useCallback(() => {
        setFetchingData(true)
        API.get('/get_fleet_management_details')
            .then((res) => {
                setStatData(res.data?.data)
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setFetchingData(false)
            })
    }, [])

    useEffect(() => {
        fetchDashboardDetails()
    }, [])

    return (
        fetchingData
            ? <LoadingView />
            : <>
                <Box className={'animate__animated animate__fadeIn'} sx={{
                    minWidth: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignContent: 'flex-start',
                    alignItems: 'stretch',
                    gap: 3,
                }}>
                    {/* static data */}
                    <Box sx={{
                        flex: { xs: '1 0 100%', sm: '1 1 240px' },
                        pt: 3,
                    }}>
                        <FormBlockTitle title='At a glance' icon={false} />
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: 1,
                        }}>
                            {statData &&
                                Object.entries(statData?.static_report)?.filter(([key, value]: [string, any]) => key != 'total_crops')?.map(([key, value]: [string, any], i: number) => (
                                    <Box key={i}
                                        sx={{
                                            borderRadius: '18px',
                                            background: theme.palette.mode == 'dark' ? alpha(theme.palette.text.disabled, 0.1) : alpha(theme.palette.primary.main, 0.06),
                                            p: '4px',
                                            fontSize: '0.7rem',
                                            fontWeight: 500,
                                            display: 'flex',
                                            flexFlow: 'column',
                                            alignItems: 'stretch',
                                            justifyContent: 'center',
                                            fontFamily: theme.typography.h1.fontFamily,
                                            color: theme.palette.primary.main,
                                            textAlign: 'center',
                                            '& span': {
                                                flex: 1,
                                                py: 0.5,
                                                fontSize: '0.8rem',
                                                background: theme.palette.background.default,
                                                borderRadius: '16px',
                                                color: theme.palette.mode == 'dark' ? theme.palette.text.primary : theme.palette.text.secondary,
                                                fontWeight: 600,
                                                fontFamily: theme.typography.body1.fontFamily,
                                            },
                                        }}>
                                        {titleCaseWords(key)} <span>{value}</span>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>

                    {/* best operator data */}
                    <Box className={'chartBox'} sx={{ flex: '1 1 280px' }}>
                        <Typography className={'chartTitle'}>Best Performing Operators</Typography>
                        <LeadingChart data={statData ? statData?.top_five_operators : []} />
                    </Box>

                    {/* best asssts data */}
                    <Box className={'chartBox'} sx={{ flex: '1 1 280px' }}>
                        <Typography className={'chartTitle'}>Best performing Assets</Typography>
                        <LeadingChart data={statData ? statData?.top_five_assets : []} />
                    </Box>

                </Box>

                <Box className={'animate__animated animate__fadeIn'} sx={{
                    minWidth: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 3,
                }}>
                    {/* client data */}
                    <Box className={'chartBox'} sx={{ flex: '1 1 300px', }}>
                        <Typography className={'chartTitle'}>Client Wise</Typography>
                        <Chart
                            chartType="ColumnChart"
                            width="100%"
                            data={clientWiseData}
                            options={{
                                height: (window.innerHeight * 0.3 < 200) ? 200 : window.innerHeight * 0.3,
                                legend: { position: "top" },
                                colors: ["#BFDB38", "#379237"],
                                hAxis: { title: "." },
                                vAxis: { title: "Acreage", minValue: 0, viewWindow: { min: 0 } },
                            }} />
                    </Box>

                    {/* monthly data */}
                    <Box className={'chartBox'} sx={{ flex: '1 1 300px', }}>
                        <Typography className={'chartTitle'}>Financial Year {moment().month() > 3 ? `${moment().format('YYYY')} - ${moment().add(1, 'year').format('YY')}` : `${moment().subtract(2, 'year').format('YYYY')} - ${moment().subtract(1, 'year').format('YY')}`}</Typography>
                        <Chart
                            chartType="LineChart"
                            width="100%"
                            data={monthWiseData}
                            options={{
                                curveType: "function",
                                height: (window.innerHeight * 0.3 < 200) ? 200 : window.innerHeight * 0.3,
                                legend: { position: "top" },
                                colors: ["#BFDB38", "#379237"],
                                hAxis: { title: "." },
                                vAxis: { title: "Acreage", minValue: 0, viewWindow: { min: 0 } },
                            }} />
                    </Box>
                </Box>

                <Box className={'animate__animated animate__fadeIn'} sx={{
                    minWidth: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignContent: 'flex-start',
                    alignItems: 'flex-start',
                    justifyContent: 'stretch',
                    gap: 3,
                }}>

                    {/* crop waise data */}
                    <Box className={'chartBox'} >
                        <Typography className={'chartTitle'}>Crop Wise</Typography>
                        <Chart
                            chartType="PieChart"
                            width={window?.innerWidth * 0.3 > 200 ? window?.innerWidth * 0.3 : 220}
                            data={cropWiseData}
                            options={{
                                height: 200,
                                legend: { position: "bottom" },
                                colors: ["#235d23", '#318231', '#3fa73f', '#58c058', '#7dce7d', "#6b7d17", '#7f941b', '#92aa1f', '#a6c123', '#a6c123'],
                            }}
                        />
                    </Box>

                    {/* state wise data */}
                    <Box className={'chartBox'}>
                        <Typography className={'chartTitle'}>State Wise</Typography>
                        <Chart
                            chartType="PieChart"
                            width={window?.innerWidth * 0.3 > 200 ? window?.innerWidth * 0.3 : 220}
                            data={stateWiseData}
                            options={{
                                height: 200,
                                legend: { position: "bottom" },
                                colors: ["#235d23", '#318231', '#3fa73f', '#58c058', '#7dce7d', "#6b7d17", '#7f941b', '#92aa1f', '#a6c123', '#a6c123'],
                            }}
                        />
                    </Box>

                </Box>
            </>
    )
}

const LeadingChart = (props: any) => {

    const { data } = props

    const theme = useTheme()

    return (
        <Box sx={{
            background: theme.palette.background.default,
            width: '100%',
            borderRadius: '24px',
            height: '-webkit-fill-available',
        }}>
            <Box
                sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    p: 2,
                    gap: 1,
                    height: '100%',
                }}>
                {(data?.length > 0)
                    ? data?.map((item: any, i: number) => (
                        <Box key={i} className={'leadingItem'}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                                background: theme.palette.mode == 'dark' ? alpha('#c5ff9c', 0.2) : alpha('#c5ff9c', 0.3),
                                alignSelf: 'stretch',
                                borderRadius: '50vh',
                                p: '3px',
                                position: 'relative',
                                '& .rank': {
                                    alignSelf: 'stretch',
                                    width: '32px',
                                    background: alpha(theme.palette.text.secondary, 0.1),
                                    fontSize: '12px',
                                    borderRadius: '50vh',
                                    display: 'grid',
                                    placeItems: 'center',
                                },
                                '& .label': {
                                    zIndex: 1,
                                    fontSize: '12px',
                                    lineHeight: '14px',
                                    fontWeight: 600,
                                    flex: 1,
                                    color: theme.palette.mode == 'dark' ? theme.palette.text.primary : '#456d00',
                                    '& a': {
                                        textDecoration: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        maxWidth: 'max-content',
                                        color: 'inherit',
                                        '& svg': {
                                            fontSize: 'inherit'
                                        },
                                        '&:hover': {
                                            color: theme.palette.primary.main,
                                        },
                                    },
                                },
                                '& .value': {
                                    alignSelf: 'stretch',
                                    minWidth: '60px',
                                    background: '#379237',
                                    color: theme.palette.primary.contrastText,
                                    borderRadius: '1.2rem',
                                    display: 'grid',
                                    placeItems: 'center',
                                    justifyItems: 'center',
                                    fontSize: '0.85rem',
                                },
                            }}>
                            <Box className={'rank'} >{i + 1}</Box>
                            <Typography className={'label'} >
                                {item?.asset_id ? item?.asset?.asset_id : item?.asset_operator?.name} {item?.asset_operator && `- ${item?.asset_operator?.code}`}<br />

                                {item?.asset_id
                                    ? item?.asset?.uin
                                    : <a href={`tel:+91${item?.asset_operator?.phone}`}><PhoneRounded /> +91 {item?.asset_operator?.phone}</a>
                                }

                            </Typography>
                            <Box className={'value'}>
                                {+(item?.total_sprayed_acreage).toFixed(1)}
                            </Box>
                        </Box>
                    ))
                    : <Box sx={{
                        display: 'grid',
                        placeItems: 'center',
                        height: '100%',
                        width: '100%',
                        '& img': {
                            width: '50%',
                            objectFit: 'contain',
                            filter: 'grayscale(1) brightness(1.3)',
                            opacity: 0.7,
                        },
                    }}>
                        <img src={noDataIllustration} alt={'no data'} />
                    </Box>
                }
            </Box>
        </Box>
    )
}
