import React, { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import API from '../../api';
import { updateProcessState } from '../../slices/process-slice';
import { useParams } from 'react-router';
import SingleFarmer from './singleFarmer';
import LoadingView from '../../components/loading-view';
import { Farmer } from '../../models/farmer';

function ViewFarmer() {

    const params = useParams()
    const { farmerId } = params
    const dispatch = useDispatch()
    const [fetchingFarmer, setFetchingFarmer] = useState(false)
    const [farmer, setFarmer] = useState<Farmer>({} as Farmer)


    const fetchFarmer = useCallback(() => {
        setFetchingFarmer(true)

        dispatch(updateProcessState(true));

        API.get(`/get-farmer-info?id=${atob(`${farmerId}`)}`)
            .then((res) => {
                if (res.data?.statuscode == "200") {
                    setFarmer(res.data?.farmer_data[0])
                }
            })
            .catch((err) => {
                toast.success("Oops! Something bad at our end");
            })
            .finally(() => {
                setFetchingFarmer(false)
                dispatch(updateProcessState(false))
            });
    }, [])

    useEffect(() => {
        fetchFarmer()
    }, [])
    return (
        fetchingFarmer
            ? <LoadingView />
            : <SingleFarmer farmerData={farmer} />
    )
}

export default ViewFarmer