import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Typography, alpha, useTheme } from "@mui/material"
import API from '../../api'
import Chart from 'react-google-charts'
import LoadingView from '../../components/loading-view'
import welcomeImage from '../../assets/images/illustartions/support.png'
import moment from 'moment'
import { Diversity2Rounded, FlareRounded, GroupRounded, KeyboardCommandKeyRounded, TodayRounded, TrendingDownRounded, TrendingUpRounded, TwoWheelerRounded } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { User } from '../../models/user'
import { camelCaseWords, titleCaseWords } from '../../utils/app-helper'
import { useNavigate } from 'react-router'

export default function ManagementDashboard() {


    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser


    const theme = useTheme()
    const navigate = useNavigate()
    const [statData, setStatData] = useState<any | null>(null)
    const [fetchingData, setFetchingData] = useState(true)

    const monthWiseData = statData?.month_wise_acreage?.map(({ month, total_no_of_orders, total_requested_acreage, total_sprayed_acreage }: any) => [month?.slice(0, 3), +total_requested_acreage, +total_sprayed_acreage, +total_no_of_orders])


    const months = ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'];
    const states = statData?.state_wise_sprayed_acreage?.map((stateData: any) => stateData.state) ?? [];
    const stateWiseData = [['Month', ...states]]
    months?.map((month: any) => {
        const rowData = [month]
        states?.forEach((state: any) => {
            const monthData = statData?.state_wise_sprayed_acreage
                ?.find((stateData: any) => stateData.state == state)?.month_data
                ?.find((data: any) => data.month?.slice(0, 3) == month);
            rowData.push(monthData ? +monthData.sprayed : 0)
        });
        stateWiseData?.push(rowData)
    });

    const clientWiseData = statData?.client_wise_bifurcation?.length > 0
        ? (() => {
            const sortedData = statData.client_wise_bifurcation.sort((a: any, b: any) => +b[1] - +a[1]);
            const slicedData = sortedData.slice(0, 9);

            const others = [
                'Others',
                sortedData.slice(10).reduce((total: number, item: any) => total + +item[1], 0),
                sortedData.slice(10).reduce((total: number, item: any) => total + +item[2], 0),
                sortedData.slice(10).reduce((total: number, item: any) => total + +item[3], 0)
            ];

            return sortedData.length > 10 ? [...slicedData, others] : sortedData;
        })().map(({ client_details, requested_acerage, sprayed_acerage, total_no_of_orders }: any) =>
            [client_details?.regional_client_name, +requested_acerage, +sprayed_acerage, +total_no_of_orders]
        )
        : [['All Clients', 0, 0, 0]];


    const staticData = {
        ...statData?.our_fleet,
        total_farmers_served: statData?.total_farmers_served
    }


    clientWiseData?.unshift(["Client", "Requested", "Sprayed", "No of Orders"])
    monthWiseData?.unshift(["Month", "Requested", "Sprayed", "No of Orders"])

    const fetchCsoDashboardDetails = useCallback(() => {
        setFetchingData(true)
        API.get('/get_management_dashboard_details')
            .then((res) => {
                setStatData(res.data?.data)
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setFetchingData(false)
            })
    }, [])

    useEffect(() => {
        fetchCsoDashboardDetails()
    }, [])

    return (
        fetchingData
            ? <LoadingView />
            : <>
                <Box className={'animate__animated animate__fadeIn'} sx={{
                    minWidth: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 3,
                }}>
                    <Box
                        sx={{
                            flex: 1,
                            borderRadius: '27px',
                            background: theme.palette.mode == 'dark' ? alpha(theme.palette.text.disabled, 0.1) : alpha(theme.palette.primary.main, 0.06),
                            p: 2,
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 2,
                            '& .welcomeBlock': {
                                minWidth: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 1,
                                flexWrap: 'wrap-reverse',
                                '& .MuiTypography-root': {
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    gap: '4px',
                                    '&.name': {
                                        fontSize: theme.typography.pxToRem(18),
                                        lineHeight: theme.typography.pxToRem(18),
                                        fontWeight: 500,
                                        fontFamily: theme.typography.h1.fontFamily,
                                        '& span': {
                                            fontSize: theme.typography.pxToRem(12),
                                            lineHeight: theme.typography.pxToRem(12),
                                            fontWeight: 400,
                                            fontStyle: 'italic',
                                            width: '100%',
                                        },
                                    },
                                    '&.date': {
                                        fontSize: theme.typography.pxToRem(12),
                                        lineHeight: theme.typography.pxToRem(12),
                                        color: theme.palette.text.secondary,
                                        pr: 2,
                                        '& svg': {
                                            fontSize: theme.typography.pxToRem(14),
                                        },
                                    },
                                },
                            },
                            '& .detailBlock': {
                                justifySelf: 'flex-end',
                                background: theme.palette.background.default,
                                flex: '1 1 125px',
                                borderRadius: '19px',
                                display: 'flex',
                                flexFlow: 'column',
                                gap: 1,
                                p: 2,
                                minHeight: { xs: '100px', sm: '150px' },
                                '&.action': {
                                    flex: '2 1 260px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    gap: 2,
                                    '& .detailItem': {
                                        flex: '1 1 140px',
                                        minWidth: '40%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        gap: 1,
                                        outline: `1px solid ${alpha(theme.palette.text.disabled, 0.03)}`,
                                        borderRadius: '3px',
                                        transition: 'all 180ms ease-in-out',
                                        p: 1,
                                        '& .identity': {
                                            flex: 1,
                                            display: 'grid',
                                            alignItems: 'center',
                                            '& svg': {
                                                color: alpha(theme.palette.primary.main, 0.4),
                                            },
                                            '& .des': {
                                                flex: 1,
                                                fontSize: theme.typography.pxToRem(12),
                                                lineHeight: theme.typography.pxToRem(12),
                                                color: theme.palette.primary.main,
                                                fontWeight: 500,
                                            },
                                        },
                                        '& .count': {
                                            fontSize: theme.typography.pxToRem(18),
                                            lineHeight: theme.typography.pxToRem(18),
                                            color: theme.palette.text.secondary,
                                            fontWeight: 500,
                                            boxShadow: `0 0 14px -3px ${alpha(theme.palette.text.primary, 0.1)} inset`,
                                            minWidth: '70px',
                                            alignSelf: 'stretch',
                                            borderRadius: '8px',
                                            display: 'grid',
                                            placeItems: 'center',
                                        },
                                        '&:hover': {
                                            outline: `1px solid ${alpha(theme.palette.text.disabled, 0.3)}`,
                                            borderRadius: '16px',
                                            "& svg": {
                                                color: alpha(theme.palette.primary.main, 0.9),
                                            },
                                        },
                                    },


                                },
                                '& .key': {
                                    fontSize: theme.typography.pxToRem(13),
                                    fontWeight: 500,
                                    textAlign: 'center',
                                    '&.order': {
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 0.4,
                                        '& svg': {
                                            color: theme.palette.success.main,
                                            fontSize: theme.typography.pxToRem(15),
                                            '&.down': {
                                                color: theme.palette.error.main,
                                            },
                                        },
                                    },
                                },
                                '& .value': {
                                    flex: 1,
                                    fontSize: 'clamp(1.1rem, 5vw, 1.6rem)',
                                    lineHeight: 'clamp(1.1rem, 5vw, 1.6rem)',
                                    fontWeight: 500,
                                    display: 'grid',
                                    placeItems: 'center',
                                    alignContent: 'center',
                                    color: theme.palette.text.secondary,
                                    '& span': {
                                        fontSize: theme.typography.pxToRem(12),
                                        lineHeight: theme.typography.pxToRem(12),
                                        color: theme.palette.text.disabled,
                                    },
                                },
                            },
                        }}>
                        <Box className={'welcomeBlock'}>
                            <Typography className={'name'}><span>👋 Hey!</span> &nbsp;&nbsp;&nbsp;{camelCaseWords(currentUser?.name ?? 'Buddy')}</Typography>
                            <Typography className={'date'}><TodayRounded />{moment().format('DD MMM, YYYY')}</Typography>
                        </Box>
                        <Box className={'detailBlock'}>
                            <span className={'key'}>Last 7 Days</span>
                            <span className={'value'}>
                                {+(statData?.last_seven_days[0]?.requested_acerage ?? 0).toFixed(1)} / {+(statData?.last_seven_days[0]?.sprayed_acerage ?? 0).toFixed(1)}
                                <span>Booked / Sprayed</span><span>Acreage</span>
                            </span>
                            <span className={'key order'}>
                                Total Orders - {+(statData?.last_seven_days[0]?.total_no_of_orders ?? 0).toFixed(1)}
                                {+(statData?.last_thirty_days[0]?.requested_acerage ?? 0).toFixed(1) / 4 > (statData?.last_seven_days[0]?.requested_acerage ?? 0) ? <TrendingDownRounded className='down' /> : <TrendingUpRounded />}
                            </span>
                        </Box>
                        <Box className={'detailBlock'}>
                            <span className={'key'}>Last 30 Days</span>
                            <span className={'value'}>
                                {+(statData?.last_thirty_days[0]?.requested_acerage ?? 0).toFixed(1)} / {+(statData?.last_thirty_days[0]?.sprayed_acerage ?? 0).toFixed(1)}
                                <span>Booked / Sprayed</span><span>Acreage</span>
                            </span>
                            <span className={'key order'}>
                                Total Orders - {statData?.last_thirty_days[0]?.total_no_of_orders ?? 0} <TrendingUpRounded />
                            </span>
                        </Box>
                        <Box className={'detailBlock'}>
                            <span className={'key'}>All Time</span>
                            <span className={'value'}>
                                {+(statData?.all_time[0]?.requested_acerage ?? 0).toFixed(1)} / {+(statData?.all_time[0]?.sprayed_acerage ?? 0).toFixed(1)}
                                <span>Booked / Sprayed</span><span>Acreage</span>
                            </span>
                            <span className={'key order'}>
                                Total Orders - {statData?.all_time[0]?.total_no_of_orders ?? 0} <TrendingUpRounded />
                            </span>
                        </Box>
                        <Box className={'detailBlock action'}>
                            {Object.entries(staticData)?.map(([key, value]: [string, any], i: number) => (
                                <Box key={i} className={'detailItem'}>
                                    <Box className={'identity'}>
                                        {key == 'total_assets' ? <KeyboardCommandKeyRounded />
                                            : key == 'total_farmers_served' ? <Diversity2Rounded />
                                                : key == 'total_operators' ? <GroupRounded />
                                                    : key == 'total_vehicles_owned' ? <TwoWheelerRounded />
                                                        : ''
                                        }
                                        <Typography className={'des'}>
                                            {key == 'total_assets' ? 'Total Assets'
                                                : key == 'total_farmers_served' ? 'Farmers Served'
                                                    : key == 'total_operators' ? 'Active Operator'
                                                        : key == 'total_vehicles_owned' ? 'Vehicle Owned'
                                                            : ''
                                            }
                                        </Typography>
                                    </Box>
                                    <Box className={'count'}>
                                        {value}
                                    </Box>
                                </Box>
                            ))}

                            <Typography sx={{
                                fontSize: '12px',
                                textAlign: 'center',
                                width: '100%',
                            }}>Average acreage sprayed by a drone per day - <strong>{+(statData?.asset_information?.average_per_drone_per_day ?? 0).toFixed(1)}</strong></Typography>
                        </Box>
                    </Box>
                </Box>

                <Box className={'animate__animated animate__fadeIn'} sx={{
                    minWidth: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 3,
                }}>
                    {/* state wise data */}
                    <Box className={'chartBox'} sx={{ flex: '1 1 300px', }}>
                        <Typography className={'chartTitle'}>State Wise Sprayed {moment().subtract(1, 'year').format('YYYY')}-{moment().format('YY')}</Typography>
                        <Chart
                            chartType="LineChart"
                            width="100%"
                            data={stateWiseData}
                            options={{
                                curveType: "function",
                                height: (window.innerHeight * 0.3 < 200) ? 200 : window.innerHeight * 0.3,
                                legend: { position: "top" },
                                colors: ["#235d23", '#318231', '#3fa73f', '#58c058', '#7dce7d', "#6b7d17", '#7f941b', '#92aa1f', '#a6c123', '#a6c123'],
                                hAxis: { title: "." },
                                vAxis: { title: "Acreage", minValue: 0, viewWindow: { min: 0 } },
                            }} />
                    </Box>
                    {/* monthly data */}
                    {/* <Box className={'chartBox'} sx={{ flex: '1 1 300px', }}>
                        <Typography className={'chartTitle'}>Financial Year {moment().month() > 3 ? `${moment().format('YYYY')} - ${moment().add(1, 'year').format('YY')}` : `${moment().subtract(2, 'year').format('YYYY')} - ${moment().subtract(1, 'year').format('YY')}`}</Typography>
                        <Chart
                            chartType="LineChart"
                            width="100%"
                            data={monthWiseData}
                            options={{
                                curveType: "function",
                                height: (window.innerHeight * 0.3 < 200) ? 200 : window.innerHeight * 0.3,
                                legend: { position: "top" },
                                colors: ["#BFDB38", "#379237", '#3bc19a'],
                                hAxis: { title: "." },
                                vAxis: { title: "Acreage", minValue: 0, viewWindow: { min: 0 } },
                            }} />
                    </Box> */}

                    {/* client data */}
                    <Box className={'chartBox'} sx={{ flex: '1 1 300px', }}>
                        <Typography className={'chartTitle'}>Client Wise</Typography>
                        <Chart
                            chartType="ComboChart"
                            width="100%"
                            data={clientWiseData}
                            options={{
                                vAxis: { title: "Acreage" },
                                hAxis: { title: "Regional Clients" },
                                seriesType: "bars",
                                series: { 2: { type: "line" } },
                                height: (window.innerHeight * 0.3 < 200) ? 200 : window.innerHeight * 0.3,
                                legend: { position: "top" },
                                colors: ["#BFDB38", "#379237", '#3bc19a'],
                            }}
                        />
                    </Box>
                </Box >

                <Box className={'animate__animated animate__fadeIn'} sx={{
                    minWidth: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 3,
                }}>
                    .
                </Box>
            </>
    )
}



const dd = [
    { state: "Punjab", month_data: [{ month: "Apr", sprayed: '5' }, { month: "May", sprayed: '15' }] },
    { state: "Haryana", month_data: [{ month: "Mar", sprayed: '2' }] },
]

const ss = [
    ['Month', 'Punjab', 'Haryana'],
    ['Apr', 5, 2],
    ['May', 15, 0],
    ['Jun', 0, 0],
    ['Jul', 0, 0],
    ['Aug', 0, 0],
    ['Sep', 0, 0],
    ['Oct', 0, 0],
    ['Nov', 0, 0],
    ['Dec', 0, 0],
    ['Jan', 0, 0],
    ['Feb', 0, 0],
    ['Mar', 0, 0],
]
