import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import noConnection from '../../assets/images/illustartions/no-connection.svg'
import { colors } from '../../utils/colors'

function NoInternetConnection() {
  return (
    <Box sx={{
      display: 'grid',
      placeItems: 'center',
      alignContent: 'center',
      height: '100%',
      minHeight: '70vh',
      width: '100%',
      '& img': {
        maxWidth: '160px',
        objectFit: 'contain',
      },
    }}>
      <img src={noConnection} alt={'no internet connection'} />
      <Typography>
        You're <span>offline</span> !!
      </Typography>
      <Typography>
        Please check your connection and try again.
      </Typography>

      <Button variant='contained'
        sx={{
          mt: 2,
          textTransform: 'none',
          background: colors.primary,
          color: '#fff',
          borderRadius: '50vh',
          '&:hover': {
            background: colors.primary,
          },
        }} onClick={() => window?.location?.reload()}>Refresh</Button>
    </Box>
  )
}

export default NoInternetConnection