import React from 'react'
import { useParams } from 'react-router'
import ViewUserAttendance from './viewUserAttendance'

export default function SingleUserAttendance() {

    const { userId, month } = useParams<{ userId?: string; month?: string }>()

    return (
        <ViewUserAttendance userId={`${userId}`} multiView />

    )
}