import { lighten, styled } from "@mui/material"
import { DataGrid, } from "@mui/x-data-grid"


export const ThemeDataTable = styled(DataGrid)(({ theme }) => ({
    minHeight: '420px',
    width: '-webkit-fill-available',
    overflowY: 'clip',
    margin: '0.5rem 1rem',
    border: 'none',
    overflowX: 'auto',
    position: 'relative',
    '& .MuiDataGrid-virtualScroller': {
        flex: 1,
    },
    '& .MuiDataGrid-columnHeaders': {
        backgroundColor: theme.palette.mode == 'dark'
            ? '#3f3f3f'
            : lighten(theme.palette.text.secondary, 0.9),
        borderBottom: '1px solid',
        '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600,
        },
        '&:focus': {
            outline: 'none !important',
        },
        '&:has(.stickyLeft)': {
            position: 'relative',
            '&:after': {
                position: 'absolute',
                right: 0,
                width: '106px',
                transition: 'box-shadow 0.3s',
                content: '"Action"',
                pointerEvents: 'none',
                fontWeight: 500,
                zIndex: 99,
                background: theme.palette.mode == 'dark' ? '#3f3f3f' : '#efefef',
                textAlign: 'center',
                top: '50%',
                boxShadow: '0px 0 12px 0px #83838380',
                transform: 'translateY(-50%)',
            },
        },
    },
    '& .MuiDataGrid-row': {
        '&:last-child td, &:last-child th': {
            border: 0,
        },
        '& .MuiDataGrid-cell': {
            backgroundColor: theme.palette.background.default
        },
        '& .MuiIconButton-root': {
            height: '1.5rem',
            width: '2rem',
            color: theme.palette.primary.main,
            transition: 'all 300ms ease-in-out',
            borderRadius: '50vh',
            '&:hover': {
                boxShadow: '0 0 12px -4px inset',
            },
            '& svg': {
                height: '16px',
                width: '16px',
            },
            '&:has(svg[data-testid="DeleteOutlinedIcon"]):not(:disabled)': {
                color: theme.palette.error.dark
            },
            '&:not(:last-of-type)': {
                // marginRight: '8px',
            },
            '&.Mui-disabled': {
                color: theme.palette.text.disabled,
            },
        },
    },
    '& .stickyRight, & .stickyLeft': {
        position: 'sticky',
        // position: 'relative',
        overflow: 'visible !important',
        backgroundColor: theme.palette.mode == 'dark'
            ? '#3f3f3f'
            : '#ededed',
        '&:after': {
            position: 'absolute',
            top: 0,
            bottom: '-1px',
            left: 0,
            width: '30px',
            transform: 'translateX(-100%)',
            transition: 'box-shadow 0.3s',
            content: '""',
            pointerEvents: 'none',
        },
    },
    '& .stickyRight': {
        right: 0,
        textAlign: 'center !important',
        '&:after': {
            left: 0,
            transform: 'translateX(-100%)',
            boxShadow: 'inset -10px 0 8px -8px rgb(5 5 5 / 20%)'
        },
    },
    '& .stickyLeft': {
        left: 0,
        '&:after': {
            left: 'calc(100% + 30px)',
            transform: 'translateX(-100% )',
            boxShadow: 'inset 10px 0 8px -8px rgb(5 5 5 / 20%)'
        },
    },
    '& .MuiIconButton-root': {
        height: '1.5rem',
        width: '2rem',
        color: theme.palette.primary.main,
        transition: 'all 300ms ease-in-out',
        borderRadius: '50vh',
        '&:hover': {
            // boxShadow: '0 0 12px -4px inset',
        },
        '& svg': {
            height: '16px',
            width: '16px',
        },
        '&:has(svg[data-testid="DeleteOutlinedIcon"]):not(:disabled)': {
            color: theme.palette.error.dark
        },
        '&:not(:last-of-type)': {
            // marginRight: '8px',
        },
        '&.Mui-disabled': {
            color: theme.palette.text.disabled,
        },
    },
    '& .flex': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 0.5,
        overflow: 'auto !important',
        py: '8px !important'
    },
    '& .MuiTextField-root': {
        flex: '1 1 300px',
        maxWidth: '300px',
    },

    ['@media (max-width:600px)']: {
        margin: '0.5rem 0',
        '& .MuiTextField-root': {
            flex: '1 1 230px',
            maxWidth: '500px',
        },
        '& .MuiTable-root': {
            '& .stickyRight': {
                position: 'relative',
                '&:after': {
                    display: 'none',
                },
            },
            // '& .stickyLeft': {
            //     position: 'relative',
            //     '&:after': {
            //         display: 'none',
            //     },
            // },
        },
    },
}))