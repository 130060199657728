import { Box } from '@mui/material'
import React, { Suspense } from 'react'
import MyAssociates from '../../my-associates'
import TitleBar from '../../../components/container/TitleBar'
import PageContainer from '../../../components/container/page-container'

function MyTeam() {
    return (

        <PageContainer>

            <TitleBar title='My Team' />
            <Box sx={{
                flex: 1,
                height: '100vh',
                maxHeight: 'calc(100vh - 189px)',
                transition: 'all 350ms ease-in-out',
            }}> <Suspense fallback={<span>fetching, please wait</span>}>
                    <MyAssociates />
                </Suspense>
            </Box>
        </PageContainer>
    )
}

export default MyTeam