import React, { useCallback, useEffect, useState } from 'react'
import {
    Box,
    Button,
    Chip,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material"
import { CalendarMonthRounded, CalendarViewMonthRounded, RemoveRedEyeRounded } from "@mui/icons-material"
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams, GridToolbar } from "@mui/x-data-grid"

import { ThemeDataTable } from "../../components/theme-data-table-container"
import API from "../../api"
import PageContainer from '../../components/container/page-container'
import TitleBar from '../../components/container/TitleBar'
import TableLoading from '../../components/theme-table-container/TableLoading'
import NoDataView from '../../components/theme-table-container/NoData-'
import { useNavigate } from 'react-router'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { User } from '../../models/user'
import { RootState } from '../../store/store'
import toast from 'react-hot-toast'
import { DGE } from '../../models/dge'
import { updateDgeDetail } from '../../slices/dge-detail-slice'


export default function Dges() {

    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [dge, setDge] = useState<DGE[]>([])
    const [fetchingDge, setFetchingDge] = useState(false)


    const fetchDge = useCallback(() => {
        setFetchingDge(true)
        API.get('/fetch_dge_details', { headers: { "Accept": "application/json" } })
            .then((res) => {
                res.data.status == 'success' &&
                    setDge(res.data?.data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => setFetchingDge(false))
    }, [])

    useEffect(() => {
        fetchDge()
        dispatch(updateDgeDetail({ selectedDge: {} as DGE }))
    }, [])

    return (
        <PageContainer>
            <TitleBar title={`DGE's`}
                secondChild={
                    <Button
                        variant='contained'
                        className={'buttonGradient animate__animated animate__fadeInUp'}
                        onClick={() => navigate('create')}
                    >
                        Add New
                    </Button>
                } />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    '& .buttonGradient': {
                        backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        outline: `0px solid ${theme.palette.primary.main}`,
                        outlineOffset: 0,
                        boxShadow: 0,
                        textTransform: 'none',
                        transition: 'all 100ms ease',
                        '&:hover': {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: '2px',
                            '&:active': {
                                transform: 'scale(0.98) !important'
                            }
                        },
                    },

                }}>

                <DgesTable data={dge} fetchingDge={fetchingDge} />
            </Box>
        </PageContainer>
    )
}


const DgesTable = (props: any) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { data, fetchingDge } = props
    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser

    const columns: GridColDef[] = [
        {
            field: 'code', headerName: 'Code', width: 120,
            renderCell: (params: GridRenderCellParams) =>
                <Chip
                    size={'small'}
                    variant='outlined'
                    label={params.row?.code}
                    color={params.row?.status == '1' ? 'success' : 'error'}
                    sx={{
                        fontSize: '12px',
                        lineHeight: '12px',
                        minWidth: '60px',
                        p: '2px',
                        height: '18px',
                        textTransform: 'capitalize',
                        boxShadow: `0 0 12px -4px currentcolor inset`,
                    }} />
        },
        { field: 'emp_id', headerName: 'Employee ID', flex: 1, minWidth: 160 },
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 160 },
        {
            field: 'mobile_no', headerName: 'Mobile', width: 160, flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams<String>) => (
                <Tooltip title={params.value != '' ? params.value : ''} arrow>
                    <Typography
                        sx={{ fontSize: '13px' }}>{params.value != '' ? `+91 ${params.value}` : '-na-'}</Typography>
                </Tooltip>
            )
        },
        { field: 'reporting_manager', headerName: 'Reporting Manager', flex: 1, minWidth: 160 },
        {
            field: 'sub_district', headerName: 'Location', flex: 1, minWidth: 220,
            renderCell: (params: GridRenderCellParams<any>) => (
                <Typography sx={{ fontSize: '13px' }}>
                    {params.row?.sub_district}, {params.row?.district}
                </Typography>
            )
        },

        {
            field: 'date_of_joining', headerName: 'Joining Date', width: 160, flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams<Date>) => (
                <Typography sx={{ fontSize: '13px' }}>
                    <CalendarMonthRounded sx={{ fontSize: '12px' }} /> {moment(params.value).format('DD MMM YYYY')}
                </Typography>
            )
        },
        { field: 'dl_no', headerName: 'DL Number', flex: 1, minWidth: 160 },
        {
            field: 'date_of_leaving', headerName: 'Last Working Date', width: 160, flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams<Date>) => (
                <Typography sx={{ fontSize: '13px' }}>
                    {params.value && <CalendarMonthRounded sx={{ fontSize: '12px' }} />} {params.value ?? '-'}
                </Typography>
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            type: 'actions',
            cellClassName: 'stickyRight',
            headerClassName: 'stickyLeft',
            getActions: (params: GridRowParams<any>) => [
                <Tooltip title={`View ${params.row?.code}`} arrow>
                    <GridActionsCellItem
                        icon={<RemoveRedEyeRounded />}
                        label="View DGE"
                        onClick={() => {
                            navigate(`${btoa(params.row?.id)}`)
                            dispatch(updateDgeDetail({ selectedDge: { ...params.row } as DGE }))
                        }}
                    />
                </Tooltip>,
            ],
        },

    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetchingDge}
            disableColumnMenu
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            columnVisibilityModel={{
            }}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}

