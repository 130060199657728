import { alpha, Box, Button, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material"
import { AlertIcon, CalendarIcon } from "./icons"
import moment from "moment"
import AttendanceDialog from "./attendanceDialog"
import { RootState } from "../store/store"
import React from "react"
import { useSelector } from "react-redux"

export default function UserAttendance() {

    const theme = useTheme()
    const attendance = useSelector((state: RootState) => state.attendance)
    const clockedIn = attendance?.in ? attendance.in : null
    const clockedOut = attendance?.out ? attendance.out : null

    const isOnLeave = attendance ? attendance.on_leave == '1' : false
    const smallScreen = useMediaQuery('(max-width:900px)')


    const [open, setOpen] = React.useState<boolean>(false)
    const openAttendanceDialog = () => setOpen(true)
    const closeAttendanceDialog = () => setOpen(false)

    const attendanceStatus: 'Done' | 'Working' | 'Pending' | 'Leave' = isOnLeave ? 'Leave' : clockedOut ? 'Done' : clockedIn ? 'Working' : 'Pending'

    const color = isOnLeave ? theme.palette.error.main : clockedOut ? theme.palette.info.main : clockedIn ? theme.palette.success.main : theme.palette.warning.main

    return (
        <>
            <Box
                className={'flexCenter'}
                sx={{
                    position: 'relative',
                    borderRadius: '50vh',
                    minWidth: smallScreen ? '80px' : '230px',
                    alignSelf: 'center',
                    p: 0.5, pl: smallScreen ? 0.5 : 1,
                    gap: 1,
                    background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                    boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                    border: `1px solid ${theme.palette.background.paper}`,
                }}>

                {!smallScreen &&
                    <Box sx={{
                        flex: 1,
                        '& .date': {
                            fontSize: theme.typography.pxToRem(14),
                            fontWeight: 500,
                            fontFamily: theme.typography.h1.fontFamily,
                            whiteSpace: 'nowrap',
                            '& svg': {
                                height: '16px',
                                width: '16px',
                                mx: 1,
                            }
                        }
                    }}>
                        <Typography className={'flexStartCenter date'}>
                            <CalendarIcon /> {moment().format('DD MMM YYYY')}
                        </Typography>
                    </Box>
                }
                <Button variant='contained' size="small" title='Click to take action on attendance'
                    onClick={openAttendanceDialog}
                    color={isOnLeave ? 'error' : clockedOut ? 'info' : clockedIn ? 'success' : 'warning'}
                    sx={{
                        background: color,
                        flex: smallScreen ? 1 : 0.75,
                    }}>
                    {attendanceStatus}
                </Button>

                {!clockedIn &&
                    <Tooltip title={'Mark your attendance'}>
                        <Box
                            className={'flexCenter'}
                            sx={{
                                position: 'absolute',
                                color: theme.palette.error.main,
                                left: -32,
                            }}>
                            <AlertIcon className={'animate__animated animate__heartBeat animate__infinite infinite'} />
                        </Box>
                    </Tooltip>
                }

            </Box>

            <AttendanceDialog open={open} onCloseDialog={closeAttendanceDialog} />

        </>

    )
}