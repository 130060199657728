import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ClusterDetailsInterface {
  selectedCluster: any;
}

const initialState: ClusterDetailsInterface = {
  selectedCluster: {} as any,
};

export const clusterDetailSlice = createSlice({
  name: "clusterDetail",
  initialState,
  reducers: {
    updateClusterDetail: (
      state,
      action: PayloadAction<ClusterDetailsInterface>
    ) => {
      state.selectedCluster = action.payload?.selectedCluster;
    },
  },
});

export const { updateClusterDetail } = clusterDetailSlice.actions;

export default clusterDetailSlice.reducer;
