import React from 'react'
import { Box, ButtonBase, Typography, alpha, useTheme } from '@mui/material'
import illustration from '../../../assets/images/bg/activty-illustration.png'
import circles from '../../../assets/images/bg/card-bg.png'
import { FormBlockTitle } from '../../../components/title/FormBlockTitle'
import { ChevronRightRounded } from '@mui/icons-material'
import { useNavigate } from 'react-router'


function Activity() {

    const theme = useTheme()

    return (
        <Box
            sx={{
                minHeight: '75vh',
                display: 'flex',
                flexFlow: 'column',
                gap: 3,
                maxWidth: '550px',
                mx: 'auto',
                // background: `url(${mainBg})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom center',

            }}>
            <Box
                className={'animate__animated animate__fadeIn'}
                sx={{
                    borderRadius: '32px',
                    position: 'relative',
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    boxShadow: `0 0 9px -3px ${alpha(theme.palette.primary.main, 0.6)}, 0 0 69px -3px ${alpha(theme.palette.primary.main, 0.5)} inset`,
                    p: 2,
                    minHeight: '140px',
                    border: `3px solid ${theme.palette.background.default}`,
                    background: `url(${illustration})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center right',
                    '& .MuiTypography-root': {
                        color: theme.palette.secondary.main,
                        fontFamily: theme.typography.h1.fontFamily,
                        fontSize: theme.typography.pxToRem(16),
                        lineHeight: theme.typography.pxToRem(16),
                        fontWeight: 600,
                        '&.title': {
                            color: theme.palette.secondary.main,
                            fontSize: theme.typography.pxToRem(24),
                            lineHeight: theme.typography.pxToRem(24),
                        },
                    },
                }}>
                <Typography className={'animate__animated animate__fadeInUp'}>Your</Typography>
                <Typography className={'title animate__animated animate__fadeInUp'}>Activities</Typography>
            </Box>

            <Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    columnGap: 3,
                    mb: 2,
                    '& .MuiTypography-root': {
                        fontSize: '16px',
                        fontWeight: 500,
                    },

                }}>
                    <FormBlockTitle title='Categories' icon={false} />

                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        // justifyContent: 'center',
                        gap: 2,
                        overflowX: 'auto',
                        overflowY: 'hidden',
                        whiteSpace: 'nowrap',
                        scrollSnapType: 'x mandatory',
                        scrollSnapAlign: 'center',
                    }}>
                    {activityCards.map((card, i) => <ActivityTypeCard key={i} detail={card} color={
                        i % 2 == 0 ? theme.palette.secondary.main : theme.palette.primary.main
                    } />)}
                </Box>
            </Box>
        </Box >

    )
}

const activityCards = [
    { label: 'Meeting', link: 'meeting', color: '#54B435' },
]

export default Activity


const ActivityTypeCard = (props: any) => {

    const { detail } = props

    const theme = useTheme()
    const navigate = useNavigate()

    const cardColor = detail?.color ?? theme.palette.primary.main

    return (
        <ButtonBase
            className={'animate__animated animate__fadeIn'}
            onClick={() => navigate(detail?.link)}
            sx={{
                minHeight: '120px',
                borderRadius: '28px',
                p: 2,
                minWidth: '80%',
                backgroundImage: `url(${circles}), linear-gradient(135deg, ${alpha(cardColor, 0.8)}, ${cardColor})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center left',
                color: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                mx: 'auto',
                transition: 'transform 260ms ease-in-out',
                '&:only-child': {
                    minWidth: '100%',
                },
                '&:active': {
                    transform: 'scale(0.97)',
                },
                '& svg': {
                    height: '32px',
                    width: '32px',
                    borderRadius: '50vh',
                    p: '4px',
                    color: '#fff',
                    boxShadow: '0 0 12px -2px inset #ffffff60',
                },
                '& .MuiTypography-root': {
                    fontSize: theme.typography.pxToRem(24),
                    fontWeight: 600,
                    fontFamily: theme.typography.h1.fontFamily,
                },
            }}>
            <ChevronRightRounded />
            <Typography className={'animate__animated animate__fadeInUp'}>{detail?.label}</Typography>
        </ButtonBase>
    )
}
