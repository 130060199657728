import React, { useEffect, useState } from 'react'
import { lighten, SxProps, Typography, useTheme } from "@mui/material"
import { ErrorOutlineOutlined, InfoOutlined, ThumbUpAltOutlined, WarningAmberRounded } from "@mui/icons-material"


interface FloatingAlertProps {
    key?: string
    isVisible: boolean
    animate?: boolean
    severity?: 'info' | 'success' | 'warning' | 'error'
    message: string
    hideArrow?: boolean
    sx?: SxProps
}

export default function FloatingAlert(props: FloatingAlertProps) {

    const key = `abc${Math.random()}`
    const isVisible = props.isVisible
    const severity = props.severity ?? 'error'
    const message = props.message
    const hideArrow = props.hideArrow
    const sx = props.sx

    const theme = useTheme()

    let alertConfig = {
        color: theme.palette.error.main,
        bgColor: lighten(theme.palette.error.main, 0.8),
        AlertIcon: ErrorOutlineOutlined
    }

    switch (severity) {

        case "info":
            alertConfig = {
                color: theme.palette.info.main,
                bgColor: lighten(theme.palette.info.light, 0.8),
                AlertIcon: InfoOutlined
            }
            break
        case "success":
            alertConfig = {
                color: theme.palette.success.light,
                bgColor: lighten(theme.palette.success.light, 0.8),
                AlertIcon: ThumbUpAltOutlined
            }
            break
        case "warning":
            alertConfig = {
                color: theme.palette.warning.main,
                bgColor: lighten(theme.palette.warning.light, 0.8),
                AlertIcon: WarningAmberRounded
            }
    }

    const [animated, setAnimated] = useState(false)

    useEffect(() => {
        setAnimated(true)
        const timeout = setTimeout(() => setAnimated(false), 900)
        return () => clearTimeout(timeout)
    }, [isVisible, key])


    return (!isVisible
        ? null
        : <Typography variant={'caption'} className={animated ? 'animated' : ''}
            sx={{
                boxShadow: 3,
                zIndex: 1299,
                width: 'max-content',
                color: alertConfig.color,
                fontSize: '0.8rem',
                lineHeight: '0.8rem',
                fontWeight: 500,
                background: alertConfig.bgColor,
                backdropFilter: 'blur(5px)',
                borderRadius: '7px',
                padding: '3px 8px',
                display: 'flex',
                alignItems: 'center',
                position: 'absolute',
                bottom: '-2rem',
                gap: '5px',
                '& svg': {
                    fontSize: '1.1rem',
                },
                '&:before': {
                    display: { xs: 'none', sm: 'block' },
                    bottom: '100%',
                    left: '20%',
                    border: 'solid transparent',
                    content: hideArrow ? null : '""',
                    height: 0,
                    width: 0,
                    position: 'absolute',
                    pointerEvents: 'none',
                    borderColor: 'rgba(136, 183, 213, 0)',
                    borderBottomColor: alertConfig.bgColor,
                    borderWidth: '8px',
                    marginLeft: '-24px'
                },
                '&.animated': {
                    animation: `${(severity === 'error') ? 'ziggle' : 'bounce'} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both`,
                    ['@keyframes bounce']: {
                        '10%': { transform: 'translate3d(0, 3px, 0)' },
                        '60%': { transform: 'translate3d(0, -1px, 0)' },
                        '90%': { transform: 'translate3d(0, 1px, 0)' },
                    },
                    ['@keyframes ziggle']: {
                        '20%, 80%': { transform: 'translate3d(-1px, 0, 0)' },
                        '30%, 50%, 70%': { transform: 'translate3d(-4px, 0, 0)' },
                        '40%, 60%': { transform: 'translate3d(4px, 0, 0)' },
                        '90%, 10%': { transform: 'translate3d(-1px, 0, 0)' },
                    },
                },
                ...sx
            }}>
            <alertConfig.AlertIcon />
            {message}
        </Typography>
    )
}