import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Ter } from "../models/ter";

export interface TerDetailsInterface {
  selectedTer: Ter;
}

const initialState: TerDetailsInterface = {
  selectedTer: {} as Ter,
};

export const terDetailSlice = createSlice({
  name: "terDetail",
  initialState,
  reducers: {
    updateTerDetail: (state, action: PayloadAction<TerDetailsInterface>) => {
      state.selectedTer = action.payload?.selectedTer;
    },
  },
});

export const { updateTerDetail } = terDetailSlice.actions;

export default terDetailSlice.reducer;
