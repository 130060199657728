import { Box, Button, Step, StepLabel, Stepper, alpha, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import TitleBar from '../../components/container/TitleBar'
import farmBg from '../../assets/images/bg/bg-4.png'
import PageContainer from '../../components/container/page-container'
import { useDispatch, useSelector } from 'react-redux'
import { updateProcessState } from '../../slices/process-slice'
import FarmerPersonalInfo from './farmerPersonalInfo'
import API from '../../api'
import toast from 'react-hot-toast'
import FarmerDetails from './farmer-details'
import { scrollToTop } from '../../utils/app-helper'
import { useNavigate } from 'react-router'
import { RootState } from '../../store/store'
import { User } from '../../models/user'



function CreateFarmer() {

    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser

    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [submitting, setSubmitting] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const [activeStep, setActiveStep] = React.useState(0);
    const [channel, setChannel] = React.useState('whatsapp');
    const [rating, setRating] = React.useState<number | null>(4)
    const [influence, setInfluence] = React.useState<number | null>(80)

    const handleBack = () => setActiveStep(0)
    const handleReset = () => setActiveStep(0)


    const [sameAddress, setSameAddress] = useState(false)

    const farmAddress = {
        field_area: null,
        pin_code: '',
        district: '',
        state: '',
        address: '',
        sub_district: '',
        village: null,
        acerage: '',
    }

    const { handleSubmit, control, setError, clearErrors, reset, setValue, register, watch, formState: { errors } } = useForm({
        defaultValues: {
            farmer_name: '',
            farmer_mobile_no: '',
            farmer_pincode: '',
            farmer_district: '',
            farmer_state: '',
            farmer_address: '',
            farmer_sub_district: '',
            farmer_village: null,
            companyName: '',
            farms: [{ ...farmAddress }]
        }
    })

    const inputData = watch()

    const setAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSameAddress(event.target.checked);
        if (event.target.checked) {
            const sd = inputData.farmer_sub_district
            const town = inputData.farmer_village
            setValue('farms.0.address', `${inputData.farmer_address}`, { shouldDirty: true, shouldValidate: true });
            setValue('farms.0.sub_district', `${inputData.farmer_sub_district}`, { shouldDirty: true, shouldValidate: true });
            setValue('farms.0.village', town, { shouldDirty: true, shouldValidate: true });
            setValue('farms.0.pin_code', `${inputData.farmer_pincode}`, { shouldDirty: true, shouldValidate: true });
            setValue('farms.0.district', `${inputData.farmer_district}`, { shouldDirty: true, shouldValidate: true });
            setValue('farms.0.state', `${inputData.farmer_state}`, { shouldDirty: true, shouldValidate: true });
        }
    };

    const resetForm = () => {
        reset()
        setActiveStep(0)
    }

    const onHandleNext = (data: any) => {
        setActiveStep(1)
    }
    const onSubmit = (data: any) => {
        setSubmitting(true)
        dispatch(updateProcessState(true))

        const socialPlateformsSelected = [data.Facebook ? 'Facebook' : '', data.Instagram ? 'Instagram' : '', data.Youtube ? 'Youtube' : '',].toString()
        const farmAddresses = data.farms.map((farm: any, index: number) => {
            const farmAcreage = parseFloat((+(farm.acreage)).toFixed(2))
            return (
                {
                    ...farm,
                    field_area: `Farms ${index + 1}`,
                    village: farm.village?.vil_town_name,
                    acreage: `${farmAcreage}`
                }
            )
        })

        const newData = {
            farmer_details: {
                farmer_name: data.farmer_name,
                farmer_mobile_no: data.farmer_mobile_no,
                farmer_address: data.farmer_address,
                farmer_sub_district: data.farmer_sub_district,
                farmer_village: data.farmer_village?.vil_town_name,
                farmer_district: data.farmer_district,
                farmer_state: data.farmer_state,
                farmer_pincode: data.farmer_pincode,
                farm_addresses: farmAddresses,
                profile: [
                    {
                        gender: data.gender,
                        income: data.income,
                        education_level: data.education_level,
                        date_of_birth: data.date_of_birth ?? '',
                        wedding_anniversary: data.wedding_anniversary ?? '',
                        attitude: data.attitude,
                        lifestyle: data.lifestyle,
                        professional_info: data.professional_info,
                        influence: `${influence}`,
                        hobbies: data.hobbies,
                        favourite_activities: data.favourite_activities,
                        intrests: data.intrests,
                        mobile_phone_used: data.mobile_phone_used,
                        social_media_platform: socialPlateformsSelected,
                        tech_proficiency: data.tech_proficiency,
                        email_id: data?.email_id,
                        ratings: `${rating}`,
                        suggestion_for_improvement: data.suggestion_for_improvement,
                        prferred_communication: channel == 'whatsapp' ? 'whatsapp' : 'email',
                        social_profile: JSON.stringify([
                            { name: 'facebook', link: data?.fb_link ?? '' },
                            { name: 'instagram', link: data?.insta_link ?? '' },
                            { name: 'youtube', link: data?.yt_link ?? '' }
                        ])
                    }
                ]
            },

        }
        API.post('/submit_farmer_details', { ...newData })
            .then((res) => {
                if (res.data?.status == 'success') {
                    toast.success('Farmer created successfully!')
                    resetForm()
                    navigate((currentUser?.role?.name == 'cso' || currentUser?.role?.name == 'client') ? '/' : '/farmers')
                }
                if (res.data?.status == 'error') {
                    toast.error(`Error! \n ${res.data?.msg}`)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setSubmitting(false)
                setSubmitted(true)
                dispatch(updateProcessState(false))
            })
    }

    useEffect(() => {
        scrollToTop()
    }, [activeStep])

    return (
        <Box className={'animate__animated animate__fadeIn'}
            sx={{
                flex: 1,
                position: 'relative',
                // background: `url(${farmBg}) no-repeat center bottom / contain`,
                // pb: 18,
                backgroundAttachment: 'fixed',
                '& input[type="checkbox"]:checked + label.labelName, & input[type="radio"]:checked + label.labelName': {
                    boxShadow: `0 0 28px -8px ${theme.palette.primary.light} inset`,
                    '& .labelText': {
                        fontSize: '14px',
                        fontWeight: 500,
                        color: theme.palette.primary.main,
                    },
                    '& svg': {
                        color: theme.palette.primary.main,
                    },
                },
                '& .labelName:hover': {
                    transform: 'scale(0.98)',
                },
                '& label.labelName': {
                    cursor: 'pointer',
                    borderRadius: '16px',
                    py: 0.7,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    pr: 2, pl: 1.2,
                    transition: 'all 300ms ease-in-out',
                    boxShadow: `0 0 28px -8px ${alpha(theme.palette.text.secondary, 0.2)} inset`,
                    '& .labelText': {
                        color: theme.palette.text.secondary,
                        fontSize: '14px',
                        fontWeight: 500,
                    },
                    '& svg': {
                        height: '18px',
                        width: '18px',
                        color: theme.palette.text.secondary,
                    },
                },
            }}>
            <TitleBar title={'Create Farmer'}
                backIcon
                // backPath={'/farmers'}
                secondChild={
                    <Stepper activeStep={activeStep} sx={{
                        '& .MuiStepLabel-root': {
                            cursor: 'pointer'
                        },
                    }}>
                        <Step key={'basic_info'}> <StepLabel error={Object.keys(errors).length > 0} onClick={() => setActiveStep(0)}>Basic Info</StepLabel> </Step>
                        <Step key={'personal_info'}> <StepLabel onClick={() => setActiveStep(1)}> Personal Info</StepLabel> </Step>
                    </Stepper>
                }
            />
            <PageContainer>
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        gap: 2,
                        px: { xs: 0, sm: '3%' },
                        '& .formInputGroup': {
                            padding: '0.5rem 1rem 0.2rem',
                            borderRadius: '18px',
                            mb: 2,
                            background: theme.palette.mode == 'dark'
                                ? '#0e0e0e'
                                : `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.07)}, ${theme.palette.background.paper})`,
                            boxShadow: theme.palette.mode == 'dark' ? 'none' : `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                            border: `2px solid ${theme.palette.background.paper}`,
                            '& .farmsInputArea': {
                                display: 'flex',
                                flexWrap: 'wrap',
                                columnGap: 3,
                                '& .formInput': {
                                    flex: '1 1 210px',
                                },

                            },
                            '& .groupTop': {
                                display: 'flex',
                                width: '100%',
                                alignItems: 'flex-end',
                                justifyContent: 'space-between',
                                mb: 0.5,
                                minHeight: '32px',
                                '& .titleBlock': {
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                },
                                '& .actionBlock': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    '& .MuiFormControlLabel-root': {
                                        '& .MuiCheckbox-root': {
                                            p: '8px',
                                            '& svg': {
                                                height: '1rem',
                                                width: '1rem',
                                            },
                                        },
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '14px'
                                        },
                                    },
                                    '& .MuiIconButton-root': {
                                        '& svg': {
                                            height: '22px',
                                            fontSize: '20px'
                                        },
                                    }
                                },
                            },
                        },
                        '& .farmAddresses': {
                            width: '100%',
                        },

                    }}>
                    <Box
                        sx={{
                            width: '100%',
                            minHeight: '45vh',
                            '& .formbox': {
                                width: '100%',
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                gap: 2,
                                mx: 'auto',
                                // px: '3%',
                                '& .formInputGroup': {
                                    width: '100%',
                                    padding: '0.5rem 1rem 0.2rem',
                                    borderRadius: '18px',
                                    mb: 2,
                                    background: theme.palette.mode == 'dark'
                                        ? '#0e0e0e'
                                        : `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.07)}, ${theme.palette.background.paper})`,
                                    boxShadow: theme.palette.mode == 'dark' ? 'none' : `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                                    border: `2px solid ${theme.palette.background.paper}`,
                                    '& .farmsInputArea': {
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        columnGap: 3,
                                        '& .formInput': {
                                            flex: '1 1 220px',
                                        },

                                    },
                                    '& .groupTop': {
                                        display: 'flex',
                                        width: '100%',
                                        alignItems: 'flex-end',
                                        justifyContent: 'space-between',
                                        mb: 0.5,
                                        minHeight: '32px',
                                        '& .titleBlock': {
                                            flex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        },
                                        '& .actionBlock': {
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                            '& .MuiFormControlLabel-root': {
                                                '& .MuiCheckbox-root': {
                                                    p: '8px',
                                                    '& svg': {
                                                        height: '1rem',
                                                        width: '1rem',
                                                    },
                                                },
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: '14px'
                                                },
                                            },
                                            '& .MuiIconButton-root': {
                                                '& svg': {
                                                    height: '22px',
                                                    fontSize: '20px'
                                                },
                                            }
                                        },
                                    },
                                },

                                '& .formInput': {
                                    flex: '1 1 220px',
                                    minWidth: { xs: '100%', sm: 'auto' },
                                },

                                '& .farmAddresses': {
                                    width: '100%',
                                    '& .farms': {
                                        padding: '0.5rem 1rem 0.2rem',
                                        borderRadius: '18px',
                                        mb: 2,
                                        background: theme.palette.mode == 'dark'
                                            ? '#0e0e0e'
                                            : `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.07)}, ${theme.palette.background.paper})`,
                                        boxShadow: theme.palette.mode == 'dark' ? 'none' : `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                                        border: `2px solid ${theme.palette.background.paper}`,
                                        '& .farmsInputArea': {
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            columnGap: 3,
                                            '& .formInput': {
                                                flex: '1 1 220px',
                                            },

                                        },

                                    },
                                },
                            },
                        }}>
                        <Box className="basicInfo" sx={{
                            display: activeStep === 0 ? 'flex' : 'none'
                        }}>
                            <FarmerDetails
                                register={register}
                                sameAddress={sameAddress}
                                setSameAddress={setSameAddress}
                                setAddress={setAddress}
                                farmAddress={farmAddress}
                                control={control}
                                watch={watch}
                                setValue={setValue}
                                errors={errors}
                                setError={setError}
                                clearErrors={clearErrors}
                                type={'create'}
                                inputData={inputData}
                            />
                        </Box>
                        <Box className="personalInfo"
                            sx={{
                                display: activeStep === 0 ? 'none' : 'flex'
                            }}>
                            <FarmerPersonalInfo
                                control={control}
                                errors={errors}
                                register={register}
                                channel={channel}
                                setChannel={setChannel}
                                rating={rating}
                                setValue={setValue}
                                setRating={setRating}
                                influence={influence}
                                setInfluence={setInfluence}
                            />
                        </Box>
                    </Box>
                </Box>

                {/* fixed buttons */}
                <Box
                    sx={{
                        width: '94%',
                        // position: 'absolute',
                        // bottom: 0,
                        display: 'flex',
                        flexWrap: 'wrap-reverse',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        py: { xs: '2.5rem', sm: 7 },
                        mx: 'auto',
                        background: alpha(theme.palette.background.default, 0.3),
                        backdropFilter: 'blur(4px)',
                        '& .MuiButton-root': {
                            flex: '1 0 160px',
                            maxWidth: { xs: '100%', sm: '190px' },
                            textWrap: 'nowrap',
                            textTransform: 'capitalize'
                        },
                    }}>

                    <Button
                        size={'large'}
                        variant={'outlined'}
                        sx={{ mr: 1 }}
                        onClick={activeStep === 0 ? resetForm : handleBack}>
                        {activeStep === 0 ? 'Discard Changes' : 'Back'}
                    </Button>
                    <LoadingButton
                        variant={'contained'}
                        size={'large'}
                        disabled={Object.keys(errors)?.length > 0 && activeStep !== 0}
                        loading={submitting}
                        endIcon={<></>}
                        loadingPosition={'end'}
                        onClick={activeStep === 0 ? onHandleNext : handleSubmit(onSubmit)}>
                        {activeStep === 0 ? 'Continue' : 'Create'}
                    </LoadingButton>

                </Box>
            </PageContainer >
        </Box >
    )
}

export default CreateFarmer