import { IconButton, Stack, Typography, alpha, useTheme } from '@mui/material'
import { useNavigate } from 'react-router'
import Title from '../title/Title'
import { ChevronLeftRounded } from '@mui/icons-material'
import { ReactElement } from 'react'

interface TitleBarProps {
    title: string
    backIcon?: boolean
    backPath?: string
    secondChild?: ReactElement
}

function TitleBar({ title, backIcon, backPath, secondChild }: TitleBarProps) {

    const theme = useTheme()
    const navigate = useNavigate()

    return (
        <Stack direction={'row'} className={'animate__animated animate__fadeIn'}
            sx={{
                flex: 1,
                alignItems: 'center',
                position: 'sticky',
                top: 0,
                background: alpha(theme.palette.background.default, 1),
                zIndex: theme.zIndex.drawer - 1,
                mb: 3,
                mt: 1,
                flexWrap: 'wrap',
                gap: 1,
                backdropFilter: 'blur(9px)',
                pb: '4px',
                userSelect: 'none',
            }}>
            <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                {backIcon && <IconButton onClick={() => backPath ? navigate(`${backPath}`) : navigate(-1)}><ChevronLeftRounded /></IconButton>}
                <Typography variant={'h2'} className={'pageTitle animate__animated animate__fadeIn'}>
                    {title}
                </Typography>
            </Stack>
            <Stack direction={'row'} sx={{ flex: 1, alignItems: 'center', justifyContent: 'flex-end' }}>
                {secondChild}
            </Stack>
        </Stack>
    )
}

export default TitleBar