import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Box, CssBaseline, useMediaQuery, useTheme } from "@mui/material"

import TopBar from "./topbar/topbar"
import Sidebar from "./sidebar/sidebar"
import ContentArea from "./content-area/content-area"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { updateDrawerState } from "../slices/drawer-state-slice";
import { useLocation } from "react-router";
import LgDrawer from "./sidebar/lg-drawer";
import LoadingView from '../components/loading-view'
import NoInternetConnection from '../components/no-internet-connection'
import { useAttendance } from '../hooks/useAttendance'


export default function Layout() {

    const theme = useTheme()
    const location = useLocation()

    const dispatch = useDispatch()
    const smallScreen = useMediaQuery('(max-width:900px)')

    const [hovered, setHovered] = useState(false)


    const drawerState = useSelector((state: RootState) => state.drawerState.open)
    const processState = useSelector((state: RootState) => state.processState.working)
    const drawerWidth = smallScreen ? 300 : drawerState || hovered ? 240 : 60

    const toggleDrawer = () => dispatch(updateDrawerState(!drawerState))
    const handleDrawerClose = () => dispatch(updateDrawerState(false))

    const { fetchAttendance } = useAttendance()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])

    useEffect(() => {
        fetchAttendance()
    }, [])



    window.onbeforeunload = function () {
        if (processState) return "Changes made may not reflect"
    }

    const [isOnline, setIsOnline] = useState(true)

    useLayoutEffect(() => {
        setIsOnline(window?.navigator?.onLine)
    }, [])

    return (
        isOnline
            ? <Box sx={{
                minHeight: { xs: 'calc(100vh - 80px)', md: '100vh' },
                display: 'flex',
                justifyContent: 'space-between',
                '& .pageTitle': {
                    fontSize: '1.2rem',
                    fontWeight: 600,
                    position: 'relative',
                    background: theme.palette.mode == 'dark' ? theme.palette.primary.main : `-webkit-linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    width: 'max-content',
                },
                '&::-webkit-scrollbar-track': {
                    background: theme.palette.action.hover,
                },
                '& *::-webkit-scrollbar-track': {
                    background: theme.palette.action.hover,
                    borderRadius: '50vh',
                },
                '& *::-webkit-scrollbar-thumb': {
                    background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    borderRadius: '50vh',
                    transition: 'all 300ms ease-in-out',
                    '&:hover': {
                        background: `linear-gradient(135deg, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
                    },
                },
                '& .buttonGradient': {
                    backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    outline: `0px solid ${theme.palette.primary.main}`,
                    outlineOffset: 0,
                    boxShadow: 0,
                    textTransform: 'none',
                    transition: 'all 100ms ease',
                    '&:hover': {
                        outline: `2px solid ${theme.palette.primary.main}`,
                        outlineOffset: '2px',
                        '&:active': {
                            transform: 'scale(0.98) !important'
                        }
                    },
                },
            }}>
                <CssBaseline />

                <TopBar handleDrawerOpen={toggleDrawer} open={drawerState} smallScreen={smallScreen} />

                {smallScreen
                    ? <Sidebar handleDrawerOpen={toggleDrawer} smallScreen={smallScreen} open={drawerState} handleDrawerClose={handleDrawerClose}
                        drawerWidth={drawerWidth} hovered={hovered} setHovered={setHovered} />
                    : <LgDrawer handleDrawerOpen={toggleDrawer} smallScreen={smallScreen} open={drawerState} handleDrawerClose={handleDrawerClose}
                        drawerWidth={drawerWidth} hovered={hovered} setHovered={setHovered} />
                }


                <ContentArea smallScreen={smallScreen} open={drawerState} hovered={hovered} drawerWidth={drawerWidth} />
                {processState && <LoadingView />}
            </Box>
            : <NoInternetConnection />
    )
}
