import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface processState {
  working: boolean;
}

const initialState: processState = { working: false };

export const processStateSlice = createSlice({
  name: "processState",
  initialState,
  reducers: {
    updateProcessState: (state, action: PayloadAction<boolean>) => {
      state.working = action.payload;
    },
  },
});

export const { updateProcessState } = processStateSlice.actions;

export default processStateSlice.reducer;
