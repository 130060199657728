import { AccountCircleRounded, AssignmentRounded, HomeRounded, ReceiptLongRounded } from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction, Slide, Toolbar, alpha, useScrollTrigger, useTheme } from '@mui/material';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { RootState } from '../../store/store';
import { User } from '../../models/user';

function OperatorNavigation() {

    const location = useLocation();
    const { pathname } = location;
    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser

    const [value, setValue] = React.useState<'' | 'services' | 'ter' | 'account' | null>(null);

    const theme = useTheme()
    const navigate = useNavigate()

    useEffect(() => {
        const s = pathname.includes('services') ? 'services' :
            pathname.includes('ter') ? 'ter' :
                pathname.includes('account') ? 'account' :
                    ''

        setValue(s)
    }, [pathname])

    return (
        <Toolbar
            className={'animate__animated  animate__fadeIn'}
            sx={{
                zIndex: theme.zIndex.drawer,
                position: 'fixed',
                bottom: '12px',
                left: '50%', transform: 'translateX(-50%)',
                borderRadius: '20px',
                background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                boxShadow: `0 0 12px -3px ${theme.palette.mode == 'dark' ? '#83838380' : '#69696980'}`,
                width: '96vw',
                minHeight: '64px',
            }}>
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    navigate(`${newValue}`)
                }}
                sx={{
                    width: '100%',
                    background: `transparent`,
                    '& .MuiBottomNavigationAction-root': {
                        color: alpha(theme.palette.primary.contrastText, 0.8),
                        '&.Mui-selected': {
                            color: theme.palette.primary.contrastText,
                        },
                    },



                }}
            >
                <BottomNavigationAction label="Dashboard" value={''} icon={<HomeRounded />} />
                {currentUser?.role?.name == 'operator' &&
                    <BottomNavigationAction label="Services" value={'services'} icon={<AssignmentRounded />} />
                }
                {/* {currentUser?.provider_id == '1' &&
                    <BottomNavigationAction label="TER" value={'ter'} icon={<ReceiptLongRounded />} />
                } */}
                <BottomNavigationAction label="Profile" value={'account'} icon={<AccountCircleRounded />} />
            </BottomNavigation>
        </Toolbar>
    )
}

export default OperatorNavigation