import { Box, IconButton, alpha, useMediaQuery, useTheme } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react'
import { ThemeDataTable } from '../../components/theme-data-table-container';
import TableLoading from '../../components/theme-table-container/TableLoading';
import NoDataView from '../../components/noDataView';
import TitleBar from '../../components/container/TitleBar';
import PageContainer from '../../components/container/page-container';
import API from '../../api';
import { capitalizeWords, currencyFormatter } from '../../utils/app-helper';
import { Sync } from '@mui/icons-material';
import { useDocumentTitle } from '../../hooks/useDocumnetTitle';

export default function ClientServiceReport() {

    useDocumentTitle('Services')

    const theme = useTheme()
    const [services, setServices] = useState<any[]>([])
    const [fetching, setFetching] = useState<boolean>(true)

    const fetchServices = useCallback(() => {
        setFetching(true)
        API.get('/download_client_service_report')
            .then((res) => {
                setServices(res.data?.data?.length > 0 ? res.data?.data.reverse() : res.data?.data)
            })
            .catch((err) => console.error(err))
            .finally(() => setFetching(false))
    }, [])

    useEffect(() => {
        fetchServices()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Services Report'} secondChild={
                <IconButton
                    onClick={fetchServices}
                    size='small'
                    sx={{
                        mr: 2,
                        background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                        boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                        border: `1px solid ${theme.palette.background.paper}`,
                    }}>
                    <Sync />
                </IconButton>
            } />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                }}>

                <ServicesTable data={services} fetching={fetching} />
            </Box>
        </PageContainer>
    )
}

const ServicesTable = (props: any) => {

    const { data, fetching } = props
    const theme = useTheme()

    const columns: GridColDef[] = [
        { field: 's_no', headerName: 'SR No', flex: 1, minWidth: 160, },
        { field: 'client_name', headerName: 'Client Name', flex: 1, minWidth: 160 },
        { field: 'client_order_id', headerName: 'Client Ordr Id', flex: 1, minWidth: 100 },
        { field: 'sr_booking', headerName: 'SR Booking Date', flex: 1, minWidth: 100 },
        {
            field: 'sr_type', headerName: 'SR Type', flex: 1, minWidth: 100,
            valueFormatter: ({ value }) => `${capitalizeWords(value ?? '')}`
        },
        { field: 'sr_date', headerName: 'Service Start Date', flex: 1, minWidth: 100 },
        { field: 'actual_service_date', headerName: 'Service End Date', flex: 1, minWidth: 100 },
        { field: 'payment_date', headerName: 'Payment  Date', flex: 1, minWidth: 100 },
        { field: 'farmer_name', headerName: 'Farmer Name', flex: 1, minWidth: 100 },
        { field: 'farmer_sub_district', headerName: 'Site Sub.Dist', flex: 1, minWidth: 100 },
        { field: 'farmer_district', headerName: 'Site District', flex: 1, minWidth: 100 },
        { field: 'farmer_state', headerName: 'Site State', flex: 1, minWidth: 100 },
        { field: 'crop_name', headerName: 'Crop Name', flex: 1, minWidth: 100 },
        { field: 'farm_location', headerName: 'Site Address', flex: 1, minWidth: 100 },
        { field: 'requested_acreage', headerName: 'Requested  Acreage', flex: 1, minWidth: 100 },
        { field: 'actual_acreage', headerName: 'Actual Acreage', flex: 1, minWidth: 100 },
        { field: 'asset_operator_id', headerName: 'Operator ID', flex: 1, minWidth: 160 },
        { field: 'asset_operator', headerName: 'Operator Name', flex: 1, minWidth: 160 },
        { field: 'chemicals', headerName: 'Chemical Used', flex: 1, minWidth: 160 },
        {
            field: 'base_price', headerName: 'Base Price', flex: 1, minWidth: 100,
            renderCell: (params: GridRenderCellParams) => <span>{currencyFormatter(params?.value ?? 0)}</span>
        },
        {
            field: 'total_service_value', headerName: 'Total Service Value', flex: 1, minWidth: 100,
            renderCell: (params: GridRenderCellParams) => <span>{currencyFormatter(params?.value ?? 0)}</span>
        },
        {
            field: 'service_status', headerName: 'Service Status', flex: 1, minWidth: 100,
            valueFormatter: ({ value }) => `${capitalizeWords(value ?? '')}`
        },
        { field: 'payment_status', headerName: 'Payment Status', flex: 1, minWidth: 100 },
        { field: 'payment_type', headerName: 'Payment Type', flex: 1, minWidth: 100 },
        { field: 'service_request_by', headerName: 'Service Created By', flex: 1, minWidth: 100 },
        { field: 'noc', headerName: 'NOC', flex: 1, minWidth: 100 },
        { field: 'invoice_link', headerName: 'Invoice', flex: 1, minWidth: 100 },
        { field: 'cancel_remarks', headerName: 'Remarks', flex: 1, minWidth: 100 },
    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetching}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}


