import React, { useCallback, useEffect, useState } from 'react'
import { Box, Divider, Paper, Typography, alpha, useTheme } from '@mui/material'
import { AccessTimeRounded, AddTaskRounded, AlarmOnRounded, AssignmentIndRounded, EditRounded, ErrorOutlineRounded, NoteAddRounded, Person2Rounded, PublishedWithChangesRounded, TaskAltRounded } from '@mui/icons-material'
import moment from 'moment'
import API from '../../api'
import ThemeLoader from '../../components/theme-loader'
import { capitalizeWords, currencyFormatter, s3BucketUrl } from '../../utils/app-helper'

const ServiceTimeline = (props: any) => {

    const { requestDetails } = props
    const theme = useTheme()

    const [timelineDetails, setTimelineDetails] = useState<null | any>(null)
    const [fetching, setFetching] = useState(true)

    const fetchDetails = useCallback((requestId: string) => {
        setFetching(true)
        API.get(`/get_order_timeline/${requestId}`)
            .then((res) => {
                setTimelineDetails(res.data?.data[0] ?? null)
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setFetching(false)
            })
    }, [])

    const timelineData = {
        delivered: {
            available: !!timelineDetails?.delivered_date,
            at: timelineDetails?.delivered_date,
            by: timelineDetails?.delivered_created_by,
            status: 'Service Request Delivered',
            refundSignature: timelineDetails?.farmer_refund_signature,
            amountReceived: timelineDetails?.amount_received,
            accessAmount: timelineDetails?.added_amount,
            refundAmount: timelineDetails?.refund_amount,
            color: theme.palette.success.main,
            icon: TaskAltRounded,
        },
        completed: {
            available: !!timelineDetails?.completed_date,
            at: timelineDetails?.completed_date,
            by: timelineDetails?.updated_by,
            status: 'Spray Completed',
            requestedAcreage: timelineDetails?.requested_acreage,
            sprayedAcreage: timelineDetails?.sprayed_acreage,
            droneAcreage: requestDetails?.droneAcreage,
            effectiveAmount: timelineDetails?.total_amount,
            effectiveDiscount: timelineDetails?.total_discount,
            effectivePayable: timelineDetails?.total_payable_amount,
            accessAmount: timelineDetails?.added_amount,
            refundAmount: timelineDetails?.refund_amount,
            farmerSign: timelineDetails?.farmer_signature,
            farmerImage: timelineDetails?.farmer_image,
            farmImage: timelineDetails?.refund_image,
            color: theme.palette.info.main,
            icon: AddTaskRounded,
        },
        started: {
            available: !!timelineDetails?.spray_started_created_by,
            at: timelineDetails?.spray_started_date,
            by: timelineDetails?.spray_started_created_by,
            availablePerson: timelineDetails?.farmer_available == '1' ? 'Farmer(Self)' : `Other Person (${capitalizeWords(timelineDetails?.available_person_name ?? '')} - ${timelineDetails?.available_person_phone})`,
            freshWater: timelineDetails?.fresh_water,
            chemicals: timelineDetails?.chemical_used_ids,
            noc: timelineDetails?.noc_image,
            status: 'Spray Started',
            color: theme.palette.secondary.main,
            icon: AlarmOnRounded,
        },
        cancel: {
            available: !!timelineDetails?.cancel_date,
            at: timelineDetails?.cancel_date,
            by: timelineDetails?.cancel_created_by,
            status: 'Service Request Cancelled',
            color: theme.palette.error.main,
            icon: ErrorOutlineRounded,
        },
        acknowledged: {
            available: !!timelineDetails?.aknowledged_date,
            at: timelineDetails?.aknowledged_date,
            by: timelineDetails?.aknowledged_created_by,
            status: 'Acknowledged by Operator',
            color: theme.palette.info.main,
            icon: PublishedWithChangesRounded,
        },
        assigned: {
            available: !!timelineDetails?.assign_date,
            at: timelineDetails?.assign_date,
            by: timelineDetails?.assign_created_by,
            name: requestDetails?.name ?? '',
            phone: requestDetails?.phone ?? '',
            asset: requestDetails?.asset ?? '',
            status: 'Operator Assigned',
            color: theme.palette.warning.main,
            icon: AssignmentIndRounded,
        },
        created: {
            available: !!timelineDetails?.order_date,
            at: timelineDetails?.order_date,
            by: timelineDetails?.created_by,
            status: 'Service Request Created',
            color: theme.palette.primary.main,
            icon: NoteAddRounded,
            amountReceived: timelineDetails?.amount_received,
        },
        updated: {
            available: !!timelineDetails?.amended_date,
            at: timelineDetails?.amended_date,
            by: timelineDetails?.amended_by_name,
            status: 'Service Request Updated',
            color: theme.palette.warning.main,
            icon: EditRounded,
            remarks: timelineDetails?.amended_remarks,
        }
    }

    const [sortedTimeline, setSortedTimeline] = useState<any[]>([])

    useEffect(() => {
        if (timelineDetails) {
            const sorted = Object.entries(timelineData).sort(([keyA, valueA], [keyB, valueB]) => {
                const dateA = valueA.at ? moment(valueA?.at, 'YYYY-MM-DD HH:mm:ss') : moment();
                const dateB = valueB.at ? moment(valueB?.at, 'YYYY-MM-DD HH:mm:ss') : moment();
                return dateB.diff(dateA)

            });
            setSortedTimeline(sorted)
        }
    }, [timelineDetails])


    useEffect(() => {
        fetchDetails(`${requestDetails?.id}`)
    }, [requestDetails?.id])

    return (
        <Box sx={{
            width: { xs: 'auto', sm: 'min(80vw, 700px)' },
            py: 4,
            overflowY: 'auto',
        }}>

            {fetching
                ? <Box className={'animate__animated animate__fadeIn'}
                    sx={{
                        display: 'grid',
                        placeItems: 'center',
                        minHeight: '200px',

                    }}>
                    <ThemeLoader />
                </Box>
                : Object.entries(Object.fromEntries(sortedTimeline))?.map(([key, value]: [string, any], index: number) => {
                    const Icon = value?.icon
                    return (
                        value?.available
                            ? <Box key={index} className={'animate__animated animate__fadeIn'}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'stretch',
                                    alignContent: 'flex-start',
                                    justifyContent: 'space-between',
                                    gap: 2,
                                    p: '0 0.5rem 0 0',
                                    '&:last-of-type': {
                                        '& .tailBlock': {
                                            '& .MuiDivider-root': {
                                                display: 'none'
                                            },
                                        },
                                    },
                                    '& .tailBlock': {
                                        display: 'flex',
                                        flexFlow: 'column',
                                        alignItems: 'center',
                                        color: value?.color ?? theme.palette.error.main,
                                        '& .MuiDivider-root': {
                                            flex: 1,
                                            borderWidth: '1px',
                                            borderStyle: 'dashed'
                                        },
                                    },
                                    '& .detailBlock': {
                                        flex: 1,
                                        borderTopLeftRadius: 0,
                                        mt: 0, mb: 3, mx: 0,
                                        p: 1,
                                        boxShadow: '0 2px 12px -3px #83838380',
                                        color: value?.color ?? theme.palette.error.main,
                                        '& .details': {
                                            flex: 1,
                                            minWidth: '100%',
                                            m: 0, mt: 1,
                                            px: 2, py: 1,
                                            borderRadius: '12px',
                                            borderTopRightRadius: 0,
                                            borderTopLeftRadius: 0,
                                            boxShadow: 0,
                                            background: alpha(theme.palette.text.disabled, 0.07),
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            color: 'inherit',
                                            gap: 2,
                                            '& .statusTitle': {
                                                width: '100%',
                                                fontSize: 'clamp(0.85rem, 3vw, 1rem)',
                                                fontWeight: 500,
                                                fontFamily: theme.typography.h1.fontFamily,
                                                color: 'inherit',
                                                '& span': {
                                                    marginLeft: '16px',
                                                    fontSize: '12px',
                                                    outline: '1px solid',
                                                    padding: '2px 12px',
                                                    borderRadius: '6px',
                                                    color: timelineDetails?.payment_type == '1' ? theme.palette.primary.main : theme.palette.warning.main,
                                                },
                                            },
                                        },
                                        '& .statusTimeStamp': {
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            fontSize: '10px',
                                            lineHeight: '12px',
                                            fontWeight: 600,
                                            width: '100%',
                                            gap: 0.5,
                                            px: 1,
                                            color: theme.palette.text.secondary,
                                            '& span': {
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: 0.5,
                                                '& svg': {
                                                    fontSize: '12px',
                                                    color: theme.palette.text.disabled,
                                                },
                                            },
                                        },
                                        '& .imageContainer': {
                                            width: '100%',
                                            background: theme.palette.background.default,
                                            borderRadius: '18px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexWrap: 'wrap',
                                            boxShadow: '0 0 12px -3px #83838370',
                                            '& .imageTitle': {
                                                fontSize: '12px',
                                                color: theme.palette.text.secondary,
                                            },
                                            '& .images': {
                                                width: '100%',
                                                display: 'grid',
                                                gap: 1,
                                                justifyContent: 'center',
                                                background: alpha(theme.palette.primary.main, 0.07),
                                                p: 1, mx: 0.5, mb: 0.5,
                                                borderRadius: '12px',
                                                '& a': {
                                                    height: '60px',
                                                    '& img': {
                                                        objectFit: 'contain',
                                                        borderRadius: '8px',
                                                        height: '60px',
                                                        width: '100%',
                                                        '&:hover': {
                                                            background: alpha(theme.palette.text.secondary, 0.07),
                                                        },
                                                    },
                                                },
                                                '& .inlineDetail': {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    gap: 1,
                                                    width: 'min(100%, 320px)',
                                                    mx: 'auto',
                                                    fontSize: '13px',
                                                    lineHeight: '14px',
                                                    mb: 0,
                                                    '& span': {
                                                        '&:first-of-type': {
                                                            color: theme.palette.text.secondary,
                                                            minWidth: '140px'
                                                        },
                                                        '&:last-of-type': {
                                                            color: theme.palette.text.primary,
                                                            flex: 1,
                                                            textAlign: 'right',
                                                        },
                                                    },
                                                },
                                            },



                                        },
                                    },

                                }}>
                                <Box className={'tailBlock animate__animated animate__fadeInUp'}>
                                    <Icon className={'statusIcon'} />
                                    <Divider orientation='vertical' />
                                </Box>

                                <Paper className={'detailBlock animate__animated animate__fadeInUp'}>
                                    <Typography className={'statusTimeStamp'}>
                                        <span><AccessTimeRounded /> {moment(value?.at).format('DD MMM YYYY HH:mm:ss')}</span>
                                        <span><Person2Rounded /> {value?.by}</span>
                                    </Typography>
                                    <Paper className={'details'}>
                                        <Typography className={'statusTitle'}>
                                            {value?.status}
                                            {key == 'created' &&
                                                <span>{timelineDetails?.payment_type == '1' ? 'Prepaid' : 'Postpaid'}</span>
                                            }
                                        </Typography>
                                        {key == 'updated' &&
                                            <>
                                                <Box className={'imageContainer'} sx={{ width: '100%' }}>
                                                    <Typography className='imageTitle'>Update Deatils</Typography>
                                                    <Box className={'images'} sx={{ gridTemplateColumns: '1fr', }}>
                                                        {value?.remarks ? (
                                                            value.remarks.split(',').slice(0, -1)
                                                                .map((text: string, index: number) => (
                                                                    <Typography key={index} className='inlineDetail' sx={{
                                                                        color: theme.palette.text.primary,
                                                                        width: '90% !important',
                                                                        alignItems: 'flex-start'
                                                                    }}>
                                                                        {text.split(':').map((d: string) => <span key={d}>{d}</span>)}
                                                                    </Typography>
                                                                ))
                                                        ) : null}
                                                    </Box>
                                                </Box>

                                                <Box className={'imageContainer'} sx={{ width: '100%' }}>
                                                    <Typography className='imageTitle'>Update Remarks</Typography>
                                                    <Box className={'images'} sx={{ gridTemplateColumns: '1fr', }}>
                                                        {value?.remarks ? (
                                                            value.remarks.split(',').slice(-1)
                                                                .map((text: string, index: number) => (
                                                                    <Typography key={index} className='inlineDetail' sx={{
                                                                        color: theme.palette.text.primary,
                                                                        width: '90% !important',
                                                                        alignItems: 'flex-start'
                                                                    }}>
                                                                        {text}
                                                                    </Typography>
                                                                ))


                                                        ) : null}
                                                    </Box>
                                                </Box>
                                            </>
                                        }
                                        {key == 'created' && JSON.parse(value?.amountReceived)?.length > 0 && !(!!timelineDetails?.delivered_date) &&
                                            <Box className={'imageContainer'} sx={{ width: '100%' }}>
                                                <Typography className='imageTitle'>Payment Details</Typography>
                                                <Box className={'images'} sx={{ gridTemplateColumns: '1fr', }}>

                                                    <Typography className='inlineDetail'
                                                        sx={{
                                                            color: theme.palette.text.primary,
                                                        }}>
                                                        {currencyFormatter(+JSON.parse(value?.amountReceived)[0]?.amount ?? 0)} received {
                                                            JSON.parse(value?.amountReceived)[0]?.mode == '1' ? 'offline' : 'online'
                                                        } with reference {JSON.parse(value?.amountReceived)[0]?.reference_no}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }
                                        {(key == 'created' && requestDetails?.payment_required == 0) &&
                                            <Box className={'imageContainer'} sx={{ width: '100%' }}>
                                                <Typography className='imageTitle'>Payment Details</Typography>
                                                <Box className={'images'} sx={{ gridTemplateColumns: '1fr', }}>

                                                    <Typography className='inlineDetail'
                                                        sx={{
                                                            color: theme.palette.text.primary,
                                                        }}>
                                                        Payment handled by client.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }

                                        {key == 'assigned' &&
                                            <>
                                                <Box className={'imageContainer'} sx={{ width: '100%' }}>
                                                    <Typography className='imageTitle'>
                                                        &nbsp;
                                                    </Typography>
                                                    <Box className={'images'} sx={{ gridTemplateColumns: '1fr', }}>
                                                        <Typography className='inlineDetail'><span>Operator</span>: <span>{value?.name}</span></Typography>
                                                        <Typography className='inlineDetail'><span>Phone</span>: <span>{value?.phone}</span></Typography>
                                                        <Typography className='inlineDetail'><span>Asset</span>: <span>{value?.asset}</span></Typography>
                                                    </Box>
                                                </Box>
                                            </>
                                        }
                                        {key == 'started' &&
                                            <>
                                                <Box className={'imageContainer'} sx={{ width: '100%' }}>
                                                    <Typography className='imageTitle'>
                                                        &nbsp;
                                                    </Typography>
                                                    <Box className={'images'} sx={{ gridTemplateColumns: '1fr', }}>
                                                        <Typography className='inlineDetail' sx={{ '& span': { textAlign: 'left' } }}>
                                                            <span>
                                                                Spray started by {value?.by} in the presence of {value?.availablePerson} {value?.freshWater == '1' ? 'with' : 'without'} fresh water.
                                                            </span>
                                                            <span></span>
                                                        </Typography>
                                                        <Typography className='inlineDetail'><span>Chemical Used</span>: <span>{value?.chemicals}</span></Typography>
                                                    </Box>
                                                </Box>
                                                <Box className={'imageContainer'}>
                                                    <Typography className='imageTitle'>NOC Image {!value?.noc && 'not uploaded'}</Typography>
                                                    {value?.noc &&
                                                        <Box className={'images'} sx={{ gridTemplateColumns: '1fr', }}>
                                                            <a target='_blank' href={`${s3BucketUrl}/noc_image/${value?.noc}`}>
                                                                <img src={`${s3BucketUrl}/noc_image/${value?.noc}`} alt={'noc image'} key={index} />
                                                            </a>
                                                        </Box>
                                                    }
                                                </Box>
                                            </>
                                        }

                                        {key == 'completed' &&
                                            <Box className={'imageContainer'} sx={{ width: '100%' }}>
                                                <Typography className='imageTitle'>Affected Changes</Typography>
                                                <Box className={'images'} sx={{ gridTemplateColumns: '1fr', }}>

                                                    <Typography className='inlineDetail'><span>Requested Acreage</span>: <span>{value.requestedAcreage}</span></Typography>
                                                    <Typography className='inlineDetail'><span>Sprayed Acreage</span>: <span>{value.sprayedAcreage}</span></Typography>
                                                    <Typography className='inlineDetail'><span>Drone Acreage</span>: <span>{value.droneAcreage}</span></Typography>
                                                    <Typography className='inlineDetail'><span>Effective Amount</span>: <span>{currencyFormatter(+value?.effectiveAmount ?? 0)}</span></Typography>
                                                    <Typography className='inlineDetail'><span>Effective Discount</span>: <span>{currencyFormatter(+value?.effectiveDiscount ?? 0)}</span></Typography>
                                                    <Typography className='inlineDetail'><span>Effective Payable</span>: <span>{currencyFormatter(+value?.effectivePayable ?? 0)}</span></Typography>
                                                    <Typography className='inlineDetail'><span>Access Amount</span>: <span>{currencyFormatter(+value?.accessAmount ?? 0)}</span></Typography>
                                                    <Typography className='inlineDetail'><span>Refund Amount</span>: <span>{currencyFormatter(+value?.refundAmount ?? 0)}</span></Typography>

                                                </Box>
                                            </Box>
                                        }

                                        {(key == 'delivered' && (value?.refundAmount != '0' || value?.accessAmount != '0' || JSON.parse(value?.amountReceived)?.length > 0)) &&
                                            <Box className={'imageContainer'} sx={{ width: '100%' }}>
                                                <Typography className='imageTitle'>Payment Details</Typography>
                                                <Box className={'images'} sx={{ gridTemplateColumns: '1fr', }}>
                                                    {+value?.refundAmount > 0 &&
                                                        <a target='_blank' href={`${value?.refundSignature}`}>
                                                            <img src={`${value?.refundSignature}`} alt={'farmer sign'} key={index} />
                                                        </a>
                                                    }
                                                    {JSON.parse(value?.amountReceived)?.length > 0 &&
                                                        <Typography className='inlineDetail'
                                                            sx={{ color: theme.palette.text.primary }}>
                                                            {currencyFormatter(+JSON.parse(value?.amountReceived)[0]?.amount ?? 0)} received {
                                                                JSON.parse(value?.amountReceived)[0]?.mode == '1' ? 'offline' : 'online'
                                                            } with reference {JSON.parse(value?.amountReceived)[0]?.reference_no}
                                                        </Typography>
                                                    }

                                                    {(+value?.refundAmount > 0 || +value?.accessAmount > 0) &&
                                                        <Typography className='inlineDetail'
                                                            sx={{
                                                                color: +value?.refundAmount > 0 ? theme.palette.warning.main : theme.palette.text.primary,
                                                            }}>
                                                            {JSON.parse(value?.amountReceived)?.length > 1
                                                                ? `${currencyFormatter(+JSON.parse(value?.amountReceived)[1]?.amount ?? 0)} ${+value?.refundAmount > 0 ? 'refunded' : 'excess amount received'} ${JSON.parse(value?.amountReceived)[1]?.mode == '1' ? 'offline' : 'online'
                                                                } with reference ${JSON.parse(value?.amountReceived)[1]?.reference_no}`
                                                                : +value?.refundAmount > 0 ? `Refund of ${currencyFormatter(+value?.refundAmount)} initiated` : ``
                                                            }
                                                        </Typography>
                                                    }
                                                </Box>
                                            </Box>
                                        }

                                        {key == 'completed' &&
                                            <>
                                                <Box className={'imageContainer'}>
                                                    <Typography className='imageTitle'>Delivery Proof</Typography>
                                                    <Box className={'images'} sx={{ gridTemplateColumns: '1fr 1fr', }}>
                                                        <a target='_blank' href={`${s3BucketUrl}/farmer_img_/${value?.farmerImage}`}>
                                                            <img src={`${s3BucketUrl}/farmer_img_/${value?.farmerImage}`} alt={'ss'} key={index} />
                                                        </a>
                                                        <a target='_blank' href={value?.farmerSign}>
                                                            <img src={value?.farmerSign} alt={'ss'} key={index} />
                                                        </a>
                                                    </Box>
                                                </Box>
                                                {value?.farmImage &&
                                                    <Box className={'imageContainer'}>
                                                        <Typography className='imageTitle'>Farm Image</Typography>
                                                        <Box className={'images'} sx={{ gridTemplateColumns: '1fr 1fr', }}>
                                                            <a target='_blank' href={`${s3BucketUrl}/refund_img_/${value?.farmImage}`}>
                                                                <img src={`${s3BucketUrl}/refund_img_/${value?.farmImage}`} alt={'refund image'} />
                                                            </a>
                                                            {/* <a target='_blank' href={value?.refundSignature}>
                                                                <img src={value?.refundSignature} alt={'Refund Signature'} />
                                                            </a> */}
                                                        </Box>
                                                    </Box>
                                                }
                                            </>
                                        }

                                    </Paper>
                                </Paper>
                            </Box>
                            : null
                    )
                })}
        </Box>
    )
}

export default ServiceTimeline
