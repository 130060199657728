import { useCallback, useEffect, useState } from "react";
import API from "../api";
import { useDispatch } from "react-redux";
import { updateProcessState } from "../slices/process-slice";

export function useUsers() {
  const dispatch = useDispatch();

  const [users, setUsers] = useState<any[]>([]);
  const [fetchingUsers, setFetchingUsers] = useState(false);

  const fetchUsers = useCallback(async () => {
    try {
      dispatch(updateProcessState(true));
      setFetchingUsers(true);
      const response = await API.get("/get_all_users", {
        headers: { Accept: "application/json" },
      });

      if (response.data?.status === "success") {
        setUsers(response.data?.data || []);
      } else {
        console.error(
          "Failed to fetch users:",
          response.data?.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      dispatch(updateProcessState(false));
      setFetchingUsers(false);
    }
  }, []);

  return {
    fetchUsers,
    users,
    fetchingUsers,
  };
}
