import React from 'react'

function SuccessSvg() {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 1080 1080" className={'animate__animated animate__heartBeat'}>
            <g>
                <g className={'animate__animated animate__fadeInUp'}>
                    <path className="st0" d="M419,172.8c-11.6,3-25.9,7.3-34.5,16c-10.5,10.6-14.3,26.7-17.8,42.8c-0.3,1.6-2.5,1.6-2.8,0
                                c-2.8-12.9-6.3-28.4-13.8-38c-9.5-12.2-24.2-17.2-38.5-20.9c-1.5-0.4-1.5-2.4,0-2.8c12.6-3.2,27-7.5,36-17.4
                                c9.4-10.3,13.1-26.5,16.3-41.4c0.3-1.6,2.5-1.6,2.8,0c3,14.1,8.1,30,16.6,40c9.2,10.9,22.3,15.4,35.6,18.8
                                C420.5,170.4,420.5,172.4,419,172.8z"/>
                </g>
                <g className={'animate__animated animate__fadeInUp'}>
                    <path className="st0" d="M193.2,461c-16.5,4.2-37,10.4-49.3,22.8c-15,15.1-20.5,38.2-25.4,61.2c-0.5,2.2-3.5,2.2-4,0
                                c-3.9-18.4-9-40.6-19.7-54.3c-13.6-17.4-34.5-24.5-55.1-29.8c-2.1-0.6-2.1-3.5,0-4c18-4.6,38.6-10.7,51.4-24.8
                                c13.4-14.7,18.8-37.8,23.4-59.2c0.5-2.2,3.5-2.2,4,0c4.3,20.1,11.6,42.9,23.7,57.2c13.2,15.5,31.8,21.9,50.9,26.8
                                C195.4,457.6,195.4,460.5,193.2,461z"/>
                </g>
                <g className={'animate__animated animate__fadeInUp'}>
                    <path className="st0" d="M1041.3,294.5c-5.6,1.4-12.6,3.5-16.7,7.8c-5.1,5.1-7,13-8.6,20.8c-0.2,0.8-1.2,0.8-1.4,0
                                c-1.3-6.3-3.1-13.8-6.7-18.4c-4.6-5.9-11.7-8.3-18.7-10.1c-0.7-0.2-0.7-1.2,0-1.4c6.1-1.6,13.1-3.6,17.5-8.4
                                c4.5-5,6.4-12.8,7.9-20.1c0.2-0.8,1.2-0.8,1.4,0c1.4,6.8,3.9,14.6,8,19.4c4.5,5.3,10.8,7.4,17.3,9.1
                                C1042,293.3,1042,294.3,1041.3,294.5z"/>
                </g>
                <g className={'animate__animated animate__fadeInUp'}>
                    <path className="st0" d="M418.4,786.2c-5.6,1.4-12.6,3.5-16.7,7.8c-5.1,5.1-7,13-8.6,20.8c-0.2,0.8-1.2,0.8-1.4,0
                                c-1.3-6.3-3.1-13.8-6.7-18.4c-4.6-5.9-11.7-8.3-18.7-10.1c-0.7-0.2-0.7-1.2,0-1.4c6.1-1.6,13.1-3.6,17.5-8.4
                                c4.5-5,6.4-12.8,7.9-20.1c0.2-0.8,1.2-0.8,1.4,0c1.4,6.8,3.9,14.6,8,19.4c4.5,5.3,10.8,7.4,17.3,9.1
                                C419.1,785,419.1,786,418.4,786.2z"/>
                </g>
                <g className={'animate__animated animate__fadeInUp'}>
                    <path className="st0" d="M543.6,979.9c-5.6,1.4-12.6,3.5-16.7,7.8c-5.1,5.1-7,13-8.6,20.8c-0.2,0.8-1.2,0.8-1.4,0
                                c-1.3-6.3-3.1-13.8-6.7-18.4c-4.6-5.9-11.7-8.3-18.7-10.1c-0.7-0.2-0.7-1.2,0-1.4c6.1-1.6,13.1-3.6,17.5-8.4
                                c4.5-5,6.4-12.8,7.9-20.1c0.2-0.8,1.2-0.8,1.4,0c1.4,6.8,3.9,14.6,8,19.4c4.5,5.3,10.8,7.4,17.3,9.1
                                C544.4,978.7,544.4,979.7,543.6,979.9z"/>
                </g>
                <g>
                    <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="523.7787" y1="396.2407"
                        x2="839.0307"
                        y2="722.6192">
                        <stop offset="0" style={{ stopColor: '#84D619' }} />
                        <stop offset="1" style={{ stopColor: '#21A80A' }} />
                    </linearGradient>
                    <path className="st1" d="M617.3,817.2c-86.6,0-168-33.7-229.2-94.9c-61.2-61.2-94.9-142.6-94.9-229.2s33.7-168,94.9-229.2
                                c61.2-61.2,142.6-94.9,229.2-94.9s168,33.7,229.2,94.9c61.2,61.2,94.9,142.6,94.9,229.2s-33.7,168-94.9,229.2
                                C785.3,783.5,703.9,817.2,617.3,817.2z"/>
                    <path className="st2" d="M617.3,172.4c177.1,0,320.7,143.6,320.7,320.7S794.4,813.7,617.3,813.7S296.6,670.2,296.6,493.1
                                S440.2,172.4,617.3,172.4 M617.3,165.4c-44.2,0-87.1,8.7-127.5,25.8c-39,16.5-74.1,40.1-104.1,70.2
                                c-30.1,30.1-53.7,65.1-70.2,104.1c-17.1,40.4-25.8,83.3-25.8,127.5c0,44.2,8.7,87.1,25.8,127.5c16.5,39,40.1,74.1,70.2,104.1
                                c30.1,30.1,65.1,53.7,104.1,70.2c40.4,17.1,83.3,25.8,127.5,25.8s87.1-8.7,127.5-25.8c39-16.5,74.1-40.1,104.1-70.2
                                s53.7-65.1,70.2-104.1c17.1-40.4,25.8-83.3,25.8-127.5c0-44.2-8.7-87.1-25.8-127.5c-16.5-39-40.1-74.1-70.2-104.1
                                s-65.1-53.7-104.1-70.2C704.4,174.1,661.5,165.4,617.3,165.4L617.3,165.4z"/>
                </g>
                <g>
                    <path className="st3" d="M820.5,368.4c-14.9-22.9-53.6-34.2-76.4-15.2c-39.2,32.8-78.4,65.5-117.6,98.3c-18.7,15.6-37.3,31.2-56,46.8
                                c-6.6,5.5-14.9,14.2-23.6,22c-1.1-1.7-2.1-3.3-3.2-5c-8.1-12.5-16.2-24.9-24.2-37.4c-38.7-59.6-130.3,1.6-91.6,61.2
                                c19.9,30.8,38.4,71.9,69.7,92.2c34.3,22.3,70.9,11,100.7-13.3c69.7-56.9,138.2-115.5,207.2-173.2
                                C828.3,425.6,838,395.2,820.5,368.4z"/>
                </g>
                <g>
                    <path className="st4"
                        d="M709.4,188.7c-72.5-31.1-272-38.6-373.4,159.2C431.4,233.4,534.7,175.6,709.4,188.7z" />
                </g>
                <g>
                    <path className="st5" d="M555.3,805.6C723.8,793.5,840.4,694.9,921,576c-13,74.1-63.1,142.2-123.8,184
                                c-60.7,41.9-154.9,67.4-242.7,45.6"/>
                </g>
            </g>
        </svg>
    )
}

export default SuccessSvg