import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Typography, alpha, useTheme } from "@mui/material"
import API from '../../api'
import Chart from 'react-google-charts'
import LoadingView from '../../components/loading-view'
import welcomeImage from '../../assets/images/illustartions/support.png'
import moment from 'moment'
import { FlareRounded, TodayRounded } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { User } from '../../models/user'
import { camelCaseWords } from '../../utils/app-helper'
import { useNavigate } from 'react-router'

export default function CsoDashboard() {


    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser


    const theme = useTheme()
    const navigate = useNavigate()
    const [statData, setStatData] = useState<any | null>(null)
    const [fetchingData, setFetchingData] = useState(true)

    const monthWiseData = statData?.month_wise_acreage?.map(({ month, total_requested_acreage, total_sprayed_acreage }: any) => [month?.slice(0, 3), +total_requested_acreage, +total_sprayed_acreage])

    const clientWiseData = statData?.client_wise_sprayed_acerage?.length > 0
        ? (() => {
            const sortedData = statData.client_wise_sprayed_acerage.sort((a: any, b: any) => +b[1] - +a[1])
            const slicedData = sortedData.slice(0, 9);
            const others = [
                'Others',
                sortedData.slice(10).reduce((total: number, item: any) => total + +item[1], 0),
            ]
            return sortedData.length > 10 ? [...slicedData, others] : sortedData;
        })()?.map(({ client_details, total_sprayed_acreage }: any) =>
            [client_details?.regional_client_name, +total_sprayed_acreage])
        : [['All Clients', 0.0001]]


    clientWiseData?.unshift(["Company", "Sprayed"])
    monthWiseData?.unshift(["Month", "Requested", "Sprayed"])

    const fetchCsoDashboardDetails = useCallback(() => {
        setFetchingData(true)
        API.get(currentUser?.role?.name == 'client' ? '/get_client_dashboard_details' : '/get_cso_dashboard_details')
            .then((res) => {
                setStatData(res.data?.data)
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setFetchingData(false)
            })
    }, [])

    useEffect(() => {
        fetchCsoDashboardDetails()
    }, [])

    return (
        fetchingData
            ? <LoadingView />
            : <>
                <Box className={'animate__animated animate__fadeIn'} sx={{
                    minWidth: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 3,
                }}>
                    <Box sx={{
                        flex: '1 1 300px',
                        display: { xs: 'none', sm: 'grid' },
                        placeItems: 'center',
                        '& img': {
                            width: '80%',
                            maxHeight: '200px',
                            objectFit: 'contain',
                        },
                    }}>
                        <img src={welcomeImage} alt={'hello world'} />
                    </Box>

                    <Box
                        sx={{
                            flex: '2 1 300px',
                            borderRadius: '27px',
                            background: theme.palette.mode == 'dark' ? alpha(theme.palette.text.disabled, 0.1) : alpha(theme.palette.primary.main, 0.06),
                            p: 2,
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 2,
                            '& .welcomeBlock': {
                                minWidth: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 1,
                                flexWrap: 'wrap-reverse',
                                '& .MuiTypography-root': {
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    gap: '4px',
                                    '&.name': {
                                        fontSize: theme.typography.pxToRem(18),
                                        lineHeight: theme.typography.pxToRem(18),
                                        fontWeight: 500,
                                        fontFamily: theme.typography.h1.fontFamily,
                                        '& span': {
                                            fontSize: theme.typography.pxToRem(12),
                                            lineHeight: theme.typography.pxToRem(12),
                                            fontWeight: 400,
                                            fontStyle: 'italic',
                                            width: '100%',
                                        },
                                    },
                                    '&.date': {
                                        fontSize: theme.typography.pxToRem(12),
                                        lineHeight: theme.typography.pxToRem(12),
                                        color: theme.palette.text.secondary,
                                        pr: 2,
                                        '& svg': {
                                            fontSize: theme.typography.pxToRem(14),
                                        },
                                    },
                                },
                            },
                            '& .detailBlock': {
                                justifySelf: 'flex-end',
                                background: theme.palette.background.default,
                                flex: '1 1 125px',
                                borderRadius: '19px',
                                display: 'flex',
                                flexFlow: 'column',
                                p: 2,
                                minHeight: { xs: '100px', sm: '150px' },
                                '&.action': {
                                    flex: '2 1 150px',
                                    '& svg': {
                                        color: theme.palette.primary.main,
                                    },
                                    '& .des': {
                                        flex: 1,
                                        my: 2,
                                        fontSize: theme.typography.pxToRem(12),
                                        lineHeight: theme.typography.pxToRem(12),
                                        color: theme.palette.text.disabled,
                                    },
                                },
                                '& .key': {
                                    fontSize: theme.typography.pxToRem(13),
                                    fontWeight: 500,
                                    textAlign: 'center',
                                },
                                '& .value': {
                                    flex: 1,
                                    fontSize: 'clamp(1.2rem, 3vw, 1.6rem)',
                                    lineHeight: 'clamp(1.2rem, 3vw, 1.6rem)',
                                    fontWeight: 500,
                                    display: 'grid',
                                    placeItems: 'center',
                                    alignContent: 'center',
                                    '& span': {
                                        fontSize: theme.typography.pxToRem(12),
                                        lineHeight: theme.typography.pxToRem(12),
                                        color: theme.palette.text.disabled,
                                    },
                                },
                            },
                        }}>
                        <Box className={'welcomeBlock'}>
                            <Typography className={'name'}><span>👋 Hey!</span> &nbsp;&nbsp;&nbsp;{camelCaseWords(currentUser?.name ?? 'Buddy')}</Typography>
                            <Typography className={'date'}><TodayRounded />{moment().format('DD MMM, YYYY')}</Typography>
                        </Box>
                        <Box className={'detailBlock'}>
                            <span className={'key'}>Today's</span>
                            <span className={'value'}>
                                {+(statData?.todays_acreage_details[0]?.total_requested_acreage ?? 0).toFixed(1)} / {+(statData?.todays_acreage_details[0]?.total_sprayed_acreage ?? 0).toFixed(1)}
                                <span>Booked / Sprayed</span><span>Acreage</span>
                            </span>
                        </Box>
                        <Box className={'detailBlock'}>
                            <span className={'key'}>All Time</span>
                            <span className={'value'}>
                                {+(statData?.cso_total_acreage[0]?.total_requested_acreage ?? 0).toFixed(1)} / {+(statData?.cso_total_acreage[0]?.total_sprayed_acreage ?? 0).toFixed(1)}
                                <span>Booked / Sprayed</span><span>Acreage</span>
                            </span>
                        </Box>
                        <Box className={'detailBlock action'}>
                            <FlareRounded />
                            <Typography className={'des'}>
                                Reap Rewards with AgriWings
                            </Typography>
                            <Button fullWidth variant='contained' sx={{ boxShadow: 0, textTransform: 'none' }} onClick={() => navigate('/services/create')}>Book Service</Button>
                        </Box>
                    </Box>
                </Box>

                <Box className={'animate__animated animate__fadeIn'} sx={{
                    minWidth: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 3,
                }}>
                    {/* monthly data */}
                    <Box className={'chartBox'} sx={{ flex: '1 1 300px', }}>
                        <Typography className={'chartTitle'}>Financial Year {moment().month() > 3 ? `${moment().format('YYYY')} - ${moment().add(1, 'year').format('YY')}` : `${moment().subtract(2, 'year').format('YYYY')} - ${moment().subtract(1, 'year').format('YY')}`}</Typography>
                        <Chart
                            chartType="LineChart"
                            width="100%"
                            data={monthWiseData}
                            options={{
                                curveType: "function",
                                height: (window.innerHeight * 0.3 < 200) ? 200 : window.innerHeight * 0.3,
                                legend: { position: "top" },
                                colors: ["#BFDB38", "#379237"],
                                hAxis: { title: "." },
                                vAxis: { title: "Acreage", minValue: 0, viewWindow: { min: 0 } },
                            }} />
                    </Box>

                    {/* client data */}
                    {currentUser?.role?.name == 'cso' &&
                        <Box className={'chartBox'}>
                            <Typography className={'chartTitle'}>Client Wise</Typography>
                            <Chart
                                chartType="PieChart"
                                width={window?.innerWidth * 0.3 > 200 ? window?.innerWidth * 0.3 : 220}
                                data={clientWiseData}
                                options={{
                                    height: 200,
                                    legend: { position: "bottom" },
                                    colors: ["#235d23", '#318231', '#3fa73f', '#58c058', '#7dce7d', "#6b7d17", '#7f941b', '#92aa1f', '#a6c123', '#a6c123'],
                                }}
                            />
                        </Box>
                    }
                </Box >

                <Box className={'animate__animated animate__fadeIn'} sx={{
                    minWidth: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 3,
                }}>
                    .
                </Box>
            </>
    )
}
