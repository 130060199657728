import React, { useEffect, useMemo } from 'react'
import { createTheme, CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material"
import { Toaster } from 'react-hot-toast';

import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Route, Routes, useLocation, useNavigation } from "react-router";
import Layout from "../layout/layout";
import Dashboard from "../pages/dashboard/dashboard";
import ThemeSnackbar from "../components/theme-snackbar";
import ProtectedRouteAuthCheck from "./protected-route-auth-check";
import AuthLayout from "../auth/auth-layout/auth-layout";
import { User } from "../models/user";
import NoPageFound from "../pages/no-page-found";
import TestPage from "../pages/test-page";
import MyAccount from '../pages/my-account';
import Operators from '../pages/fleet-managment/operators';
import Assets from '../pages/fleet-managment/assets';
import Batteries from '../pages/fleet-managment/battery';
import Vehicles from '../pages/fleet-managment/vehicles';
import SingleOperator from '../pages/fleet-managment/operators/SingleOperator';
import Farmers from '../pages/farmers';
import SingleFarmer from '../pages/farmers/singleFarmer';
import CreateFarmer from '../pages/farmers/create-farmer';
import CreateOperator from '../pages/fleet-managment/operators/createOperator';
import { colors } from '../utils/colors';
import CreateService from '../pages/services/create-service';
import ServiceList from '../pages/services/service-list';
import Users from '../pages/users';
import ViewFarmer from '../pages/farmers/viewFarmer';
import { scrollToTop } from '../utils/app-helper';
import Clients from '../pages/clients';
import CreateClient from '../pages/clients/create-client';
import UpdateClient from '../pages/clients/update-client';
import BaseClientList from '../pages/clients/base-client/base-client-list';
import CropManagement from '../pages/crop-management';
import Schemes from '../pages/scheme/schemes';
import CreateScheme from '../pages/scheme/create-scheme';
import OperatorDashboard from '../pages/asset-operator/operator-dashboard';
import OperatorLayout from '../operator-layout';
import Ter from '../pages/asset-operator/ter/ter';
import OperatorServiceList from '../pages/asset-operator/service-list/operator-service-list';
import OperatorAccount from '../pages/asset-operator/operator-account/operator-account';
import ServiceDetail from '../pages/asset-operator/service-list/service-detail';
import TerDashboard from '../pages/asset-operator/ter/ter-dashboard';
import CreateTer from '../pages/asset-operator/ter/create-ter';
import TerList from '../pages/hr/ter/ter-list';
import UserAttendance from '../pages/hr/attendance';
import ClaimTer from '../pages/asset-operator/ter/claim-ter';
import UpdateTer from '../pages/asset-operator/ter/update-ter';
import Training from '../pages/asset-operator/training';
import ServiceReport from '../pages/reports/service-report';
import FarmerReport from '../pages/reports/farmer-report';
import FarmReport from '../pages/reports/farm-report';
import AssetReport from '../pages/reports/asset-report';
import CropReport from '../pages/reports/crop-report';
import OperatorReport from '../pages/reports/operators-report';
import BatteryReport from '../pages/reports/battery-report';
import VehicleReport from '../pages/reports/vehicle-report';
import RegionalClientsReport from '../pages/reports/regional-clients-report';
import SchemeReport from '../pages/reports/scheme-report';
import OperatorUpdateFarmer from '../pages/asset-operator/update-farmer';
import Providers from '../pages/providers';
import Cluster from '../pages/cluster/cluster';
import UpdateService from '../pages/services/update-service';
import Transactions from '../pages/transactions';
import ClientServiceReport from '../pages/reports/client-service-report';
import Activity from '../pages/asset-operator/activity';
import CreateMeeting from '../pages/asset-operator/activity/meetings/create';
import Meeting from '../pages/asset-operator/activity/meetings';
import Meetings from '../pages/asset-operator/activity/meetings/list';
import MeetingReport from '../pages/reports/meeting-report';
import VisitedFramersReport from '../pages/reports/visited-farmers-report';
import FarmerRegistration from '../pages/campaign/farmer-registration';
import AllTransactionsReport from '../pages/reports/transaction-report';
import AssetSprayReport from '../pages/reports/asset-spray-report';
import Dges from '../pages/dge';
import CreateDge from '../pages/dge/createDge';
import UpdateDge from '../pages/dge/updateDge';
import MyAssociates from '../pages/my-associates';
import SingleUserAttendance from '../pages/hr/attendance/singleUserAttendance';
import MyTeam from '../pages/asset-operator/my-team';


export default function WebRoutes() {

    const location = useLocation()
    const currentLocation = location.pathname

    const initialMode = useSelector((state: RootState) => state.colorMode.mode)
    const errorMessage = useSelector((state: RootState) => state.snackbarMessage.message)
    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser

    const superAdmin = currentUser?.role?.name == 'super admin'
    const admin = currentUser?.role?.name == 'admin'
    const rm = currentUser?.role?.name == 'rm'
    const rtl = currentUser?.role?.name == 'rtl'

    const isMobile = useMediaQuery('(max-width:767px)')

    const theme = useMemo(() =>
        createTheme({
            palette: {
                primary: {
                    main: colors.primary, //'#0C9463',
                },
                secondary: {
                    main: colors.secondary, // '#007944',
                },
                success: {
                    main: colors.success,
                },
                warning: {
                    main: colors.warning,
                },
                error: {
                    main: colors.error,
                },

                mode: initialMode,
                ...(initialMode != 'light'
                    && {
                    background: {
                        default: '#000',
                        paper: '#000'
                    }
                }),
            },
            typography: {
                h1: { fontFamily: "Montserrat, sans-serif" },
                h2: { fontFamily: "Montserrat, sans-serif" },
                h3: { fontFamily: "Montserrat, sans-serif" },
                h4: { fontFamily: "Montserrat, sans-serif" },
                h5: { fontFamily: "Montserrat, sans-serif" },
                h6: { fontFamily: "Montserrat, sans-serif" },
                button: {
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: 500,
                },
                fontFamily: "Nunito, sans-serif",
                // fontFamily: "Roboto, sans-serif"
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            borderRadius: '50vh'
                        }
                    }
                },
                MuiMenu: {
                    styleOverrides: {
                        paper: {
                            borderRadius: '16px !important'
                        },
                    },
                },
                MuiTooltip: {
                    styleOverrides: {
                        tooltip: {
                            padding: '6px 14px',
                            borderRadius: '10px',
                        }
                    }
                }
            },
        }),
        [initialMode],
    )

    useEffect(() => {
        scrollToTop()
    }, [currentLocation])


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
                <Route path="auth" element={<AuthLayout />} />
                <Route path="*" element={<NoPageFound />} />
                <Route element={<ProtectedRouteAuthCheck />}>
                    {currentUser?.role?.name == 'operator' || currentUser?.role?.name == 'dge'
                        ? <Route path='/' element={<OperatorLayout />} >
                            <Route path='' element={<OperatorDashboard />} />
                            {/* <Route path='ter' element={<TerDashboard />} />
                            <Route path='ter/claim' element={<ClaimTer />} />
                            <Route path='ter/list/:categoryId' element={<Ter />} /> */}
                            {/* <Route path='ter/detail' element={<UpdateTer />} />
                            <Route path='ter/create' element={<CreateTer />} /> */}
                            <Route path='account' element={<OperatorAccount />} />

                            <Route path='activity' element={<Activity />} />
                            <Route path='activity/meeting' element={<Meeting />} />
                            <Route path='activity/meeting/list' element={<Meetings />} />
                            <Route path='activity/meeting/create' element={<CreateMeeting />} />

                            {(currentUser?.is_supervisor_head == '1') &&
                                <>
                                    <Route path="attendance" element={<UserAttendance />} />
                                    <Route path="my-team" element={<MyTeam />} />
                                    <Route path="attendance/:userId?/:month?" element={<SingleUserAttendance />} />
                                </>
                            }

                            <Route path='coming-soon' element={<Training />} />
                            {currentUser?.role?.name == 'operator' &&
                                <>
                                    <Route path='services' element={<OperatorServiceList />} />
                                    <Route path="services/:serviceId" element={<ServiceDetail />} />
                                    <Route path='operator-update-farmer/:farmerId' element={<OperatorUpdateFarmer />} />
                                </>
                            }
                        </Route>
                        : <Route path="/" element={<Layout />}>
                            <>
                                <Route path="" element={<Dashboard />} />
                                <Route path="my-account" element={<MyAccount />} />

                                {currentUser.role?.name != 'management' &&
                                    <>
                                        <Route path="transactions/bank" element={<Transactions />} />
                                        <Route path="transactions/all" element={<AllTransactionsReport />} />
                                        {(superAdmin || admin || rm || rtl || currentUser.role?.name == 'hr' || currentUser?.is_supervisor_head == '1') &&
                                            <>
                                                <Route path="attendance" element={<UserAttendance />} />
                                                <Route path="attendance/:userId?/:month?" element={<SingleUserAttendance />} />
                                                <Route path="my-associates" element={<MyAssociates />} />
                                            </>
                                        }
                                        {!(currentUser.role?.name == 'hr' || currentUser.role?.name == 'accounts')
                                            ? <>
                                                {(superAdmin || admin) && <Route path='users' element={<Users />} />}

                                                {currentUser?.provider_id == '1' &&
                                                    <>
                                                        {(currentUser.role?.name != 'cso' && currentUser.role?.name != 'client') &&
                                                            <Route path="farmers">
                                                                <Route path="" element={<Farmers />} />
                                                                <Route path=":farmerId" element={<ViewFarmer />} />
                                                            </Route>
                                                        }
                                                        <Route path="farmers/create" element={<CreateFarmer />} />
                                                    </>
                                                }

                                                <Route path="services">
                                                    <Route path="" element={<ServiceList />} />
                                                    <Route path=":serviceId" element={<UpdateService />} />
                                                    {currentUser?.provider_id == '1' &&
                                                        <Route path="create" element={<CreateService />} />
                                                    }
                                                </Route>


                                                {((currentUser.role?.name == 'rm') && currentUser?.provider_id != '1') &&
                                                    <Route path="reports">
                                                        <Route path="operator-report" element={<OperatorReport />} />
                                                        <Route path="service-report" element={<ServiceReport />} />
                                                        <Route path="asset-report" element={<AssetReport />} />
                                                        <Route path="battery-report" element={<BatteryReport />} />
                                                        <Route path="vehicle-report" element={<VehicleReport />} />
                                                    </Route>
                                                }


                                                {(superAdmin || admin || rm || rtl) &&
                                                    <>
                                                        <Route path="fleet-management">
                                                            <Route path="operators">
                                                                <Route path="" element={<Operators />} />
                                                                <Route path="create" element={<CreateOperator />} />
                                                                <Route path=":operatorId" element={<SingleOperator />} />
                                                            </Route>
                                                            <Route path="dge">
                                                                <Route path="" element={<Dges />} />
                                                                <Route path="create" element={<CreateDge />} />
                                                                <Route path=":dgeId" element={<UpdateDge />} />
                                                            </Route>
                                                            <Route path="assets" element={<Assets />} />
                                                            <Route path="batteries" element={<Batteries />} />

                                                            <Route path="vehicles" element={<Vehicles />} />

                                                        </Route>

                                                        {currentUser?.provider_id == '1' &&
                                                            <>
                                                                {(!rtl || !rm) &&
                                                                    <>
                                                                        <Route path='providers' element={<Providers />} />
                                                                        <Route path='clusters' element={<Cluster />} />
                                                                    </>
                                                                }
                                                                <Route path="crop-management" element={<CropManagement />} />
                                                                <Route path="clients">
                                                                    <Route path="regional" element={<Clients />} />
                                                                    <Route path="base">
                                                                        <Route path="" element={<BaseClientList />} />
                                                                        <Route path="create" element={<CreateClient />} />
                                                                        <Route path=":clientId" element={<UpdateClient />} />
                                                                    </Route>
                                                                </Route>
                                                                <Route path="schemes">
                                                                    <Route path="" element={<Schemes />} />
                                                                    <Route path="create" element={<CreateScheme />} />
                                                                </Route>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                            : <>
                                                <Route path="transactions/bank" element={<Transactions />} />
                                                <Route path="transactions/all" element={<AllTransactionsReport />} />

                                                <Route path="reports">
                                                    <Route path="service-report" element={<ServiceReport />} />
                                                </Route>
                                                {(currentUser.role?.name == 'hr') &&
                                                    <Route path="ter-list" element={<TerList />} />
                                                }
                                            </>
                                        }
                                    </>
                                }
                                {(((currentUser.role?.name != 'accounts' && currentUser.role?.name != 'cso') && currentUser?.provider_id == '1')) &&
                                    <>
                                        <Route path="reports">
                                            <Route path="operator-report" element={<OperatorReport />} />
                                            {(currentUser.role?.name == 'client') &&
                                                <Route path="client-service-report" element={<ClientServiceReport />} />
                                            }
                                            {(currentUser.role?.name != 'hr') &&
                                                <>
                                                    <Route path="service-report" element={<ServiceReport />} />
                                                    <Route path="farmer-report" element={<FarmerReport />} />
                                                    <Route path="farm-report" element={<FarmReport />} />
                                                    <Route path="asset-report" element={<AssetReport />} />
                                                    <Route path="crop-report" element={<CropReport />} />
                                                    <Route path="battery-report" element={<BatteryReport />} />
                                                    <Route path="vehicle-report" element={<VehicleReport />} />
                                                    <Route path="regional-clients-report" element={<RegionalClientsReport />} />
                                                    <Route path="scheme-report" element={<SchemeReport />} />
                                                    <Route path="asset-spray-report" element={<AssetSprayReport />} />
                                                </>
                                            }
                                        </Route>
                                        {(currentUser.role?.name == 'management' || currentUser.role?.name == 'mis') &&
                                            <Route path='clusters' element={<Cluster />} />
                                        }
                                    </>
                                }
                                {(
                                    (superAdmin
                                        || currentUser.role?.name == 'admin'
                                        || currentUser.role?.name == 'management'
                                        || currentUser.role?.name == 'mis'
                                        || currentUser.role?.name == 'rm'
                                        || currentUser.role?.name == 'hr'
                                        || currentUser.role?.name == 'rtl'
                                    )
                                ) &&
                                    <Route path="reports">
                                        <Route path="meeting-report" element={<MeetingReport />} />
                                        {/* <Route path="visited-farmers-report" element={<VisitedFramersReport />} /> */}
                                    </Route>
                                }

                                {
                                    currentUser.role?.name == 'rtl' &&
                                    <>

                                        <Route path='activity/meeting' element={<Meeting />} />
                                        <Route path='activity/meeting/list' element={<Meetings />} />
                                        <Route path='activity/meeting/create' element={<CreateMeeting />} />
                                    </>
                                }
                            </>
                        </Route>
                    }
                </Route>

                <Route path="farmer-registration" element={<FarmerRegistration />} />
            </Routes>

            <ThemeSnackbar message={errorMessage} />
            <Toaster />

        </ThemeProvider >
    )
}
