import React, { useCallback, useEffect, useState } from 'react'
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
    alpha,
    useMediaQuery,
    useTheme,
} from "@mui/material"
import { RemoveRedEyeRounded, Sync } from "@mui/icons-material"
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams, GridToolbar } from "@mui/x-data-grid"
import { useDispatch } from 'react-redux'
import { LoadingButton } from '@mui/lab'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { Controller, useForm } from 'react-hook-form'

import API from "../../api"
import { ThemeDataTable } from "../../components/theme-data-table-container"
import PageContainer from '../../components/container/page-container'
import TitleBar from '../../components/container/TitleBar'
import TableLoading from '../../components/theme-table-container/TableLoading'
import NoDataView from '../../components/theme-table-container/NoData-'
import ThemeDialog from '../../components/dialog-box/theme-dialog'
import { ThemeTextField } from '../../components/inputs/theme-text-field'
import operatorIllustration from '../../assets/images/illustartions/support.png'
import { updateProcessState } from '../../slices/process-slice'
import { useUsers } from '../../hooks/useUsers'
import { camelCaseWords, capitalizeWords } from '../../utils/app-helper'
import { useRegionalClients } from '../../hooks/useRegionalClinets'
import { useProviders } from '../../hooks/useProviders'


export default function Users() {

    const theme = useTheme()

    const { fetchUsers, fetchingUsers, users } = useUsers()

    const [roles, setRoles] = useState([])

    const [user, setUser] = useState({})
    const [userDetailType, setUserDetailType] = useState<'create' | 'edit'>('create')
    const [userDetailDialogOpen, setUserDetailDialogOpen] = useState(false)
    const closeUserDetailDialog = () => {
        setUserDetailDialogOpen(false)
        setUserDetailType('create')
        setUser({})
    }

    const fetchRoles = () => {
        API.get('/roles_list')
            .then((res) => {
                setRoles(res.data?.data?.filter(
                    (el: string) => el != 'super admin' && el != 'dge' && el != 'operator'
                )
                )
            })
    }

    useEffect(() => {
        fetchUsers()
        fetchRoles()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Users'}
                secondChild={
                    <Box className={'flexCenter'} sx={{ gap: 1 }}>

                        <IconButton
                            onClick={fetchUsers}
                            size='small'
                            sx={{
                                mr: 2,
                                background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                                boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                                border: `1px solid ${theme.palette.background.paper}`,
                            }}>
                            <Sync />
                        </IconButton>
                        <Button
                            variant='contained'
                            className={'buttonGradient animate__animated animate__fadeInUp'}
                            onClick={() => setUserDetailDialogOpen(true)}>
                            Add New
                        </Button>
                    </Box>
                }
            />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    '& .buttonGradient': {
                        backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        outline: `0px solid ${theme.palette.primary.main}`,
                        outlineOffset: 0,
                        boxShadow: 0,
                        textTransform: 'none',
                        transition: 'all 100ms ease',
                        '&:hover': {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: '2px',
                            '&:active': {
                                transform: 'scale(0.98) !important'
                            }
                        },
                    },

                }}>

                <UsersTable
                    data={users}
                    fetchingUsers={fetchingUsers}
                    setUser={setUser}
                    setUserDetailDialogOpen={setUserDetailDialogOpen}
                    setUserDetailType={setUserDetailType}
                />
            </Box>

            {userDetailDialogOpen &&
                <ThemeDialog
                    open={userDetailDialogOpen}
                    dialogBody={
                        <UserDetailForm
                            closeDialog={closeUserDetailDialog}
                            type={userDetailType}
                            user={user}
                            fetchUsers={fetchUsers}
                            roles={roles}
                        />
                    } />
            }
        </PageContainer>
    )
}


const UsersTable = (props: any) => {

    const navigate = useNavigate()
    const { data, fetchingUsers, setUser, setUserDetailDialogOpen, setUserDetailType } = props

    const columns: GridColDef[] = [
        {
            field: 'role', headerName: 'Role', flex: 1, minWidth: 120,
            cellClassName: 'textCenter',
            headerClassName: 'textCenter',
            renderCell: (params: GridRenderCellParams) =>
                <Chip
                    size={'small'}
                    variant='outlined'
                    label={params.row?.role ?? 'NA'}
                    color={params.row?.status == '1' ? 'success' : 'error'}
                    sx={{
                        fontSize: '12px',
                        lineHeight: '12px',
                        minWidth: '100px',
                        p: '2px',
                        height: '18px',
                        textTransform: 'capitalize',
                        boxShadow: `0 0 12px -4px currentcolor inset`,
                    }} />
        },
        {
            field: 'emp_id', headerName: 'Employee ID', flex: 1, minWidth: 100,
            valueFormatter: ({ value }) => `${value ?? '-'}`
        },
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 120 },
        { field: 'email', headerName: 'Email', flex: 1, minWidth: 160 },
        {
            field: 'is_supervisor_head', headerName: 'Is Supervisor', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => `${value == 0 ? 'No' : 'Yes'}`

        },
        {
            field: 'supervisor_name', headerName: 'Supervisor', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => `${value ?? '-'}`

        },
        {
            field: 'client_id', headerName: 'Clients', flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams) => (
                <span style={{ textTransform: 'capitalize' }}>{params.value ?? '-'}</span>
            )
        },
        {
            field: 'provider_name', headerName: 'Provider', flex: 1, minWidth: 220,
            renderCell: (params: GridRenderCellParams) => (
                <span style={{ textTransform: 'capitalize' }}>{params.value ?? '-'}</span>
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            type: 'actions',
            cellClassName: 'stickyRight',
            headerClassName: 'stickyLeft',
            getActions: (params: GridRowParams<any>) => [
                <Tooltip title={`View${params.row?.id}`} arrow>
                    <GridActionsCellItem
                        icon={<RemoveRedEyeRounded />}
                        label="View Farmer"
                        onClick={() => {
                            if (params.row?.status == '1') {
                                setUser(params.row)
                                setUserDetailDialogOpen(true)
                                setUserDetailType('edit')
                            } else {
                                toast.error('Can not view inactive user.')
                            }
                        }}
                    />
                </Tooltip>,
            ],
        },
    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetchingUsers}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}


const UserDetailForm = (props: any) => {

    const { closeDialog, type, user, fetchUsers, roles } = props

    const theme = useTheme()
    const dispatch = useDispatch()
    const [userStatus, setUserStatus] = useState<string>('active')
    const [selectedClients, setSelectedClients] = useState<any[]>([])

    const { fetchRegionalClients, fetchingRegionalClients, regionalClients } = useRegionalClients()
    const { fetchProviders, fetchingProviders, providers } = useProviders()

    const [supervisors, setSupervisors] = useState<any[]>([])
    const [fetchingSupervisors, setFetchingSupervisors] = useState(false)

    const [provider, setProvider] = useState<any>({} as any)

    const availableClients = regionalClients?.length > 0 ?
        regionalClients.filter((client: any, index: number, self: any) => index === self.findIndex((c: any) => c.regional_client_name === client.regional_client_name))
            .map(({ id, regional_client_name, status }: any) => ({ id, name: regional_client_name, status })) : []

    const fetchSupervisors = useCallback(() => {
        setFetchingSupervisors(true)
        API.post('fetch_users_details', { roles: 'admin,rm,hr' })
            .then((res) => {
                setSupervisors(res.data.data ?? [])
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setFetchingSupervisors(false)
            })
    }, [])




    const { control, handleSubmit, watch, setValue, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: user?.name ?? '',
            email: user?.email ?? '',
            password: user?.text_password ?? '',
            role: user?.role ?? '',
            client_id: user?.client_id ?? '',
            provider: user?.provider_id ? providers?.filter((el: any) => el?.id == user?.provider_id)[0] : null,
            // provider: user?.provider_id ? providers?.filter((el: any) => el?.id == user?.provider_id)[0] : null,
            provider_id: user?.provider_id ?? '',
            provider_name: user?.provider_name ?? '',
            emp_id: user?.emp_id ?? '',
            is_supervisor_head: user?.is_supervisor_head ?? '0',
            supervisor: user?.supervisor_id ? supervisors?.filter((el: any) => el?.id == user?.supervisor_id)[0] : null,
            supervisor_id: user?.supervisor_id ?? '',
        }
    })

    const inputData = watch()
    const rtlSelected = inputData?.role == 'rtl' ?? false

    const filterSupervisors = inputData.role == 'rm'
        ? [...supervisors].filter((el: any) => el.role != 'rm')
        : supervisors



    const [submitting, setSubmitting] = useState<boolean>(false)

    const onSubmit = (data: any) => {
        const {
            provider,
            supervisor,
            ...restData
        } = data
        dispatch(updateProcessState(true))
        setSubmitting(true)
        if (type == 'create') {
            API.post('/create_user', {
                ...restData,
                client_id: selectedClients
                    ? selectedClients?.map((el, i) => (el.id)).toString()
                    : '',
                provider_id: `${data?.provider?.id}`,
                provider_name: data?.provider?.name,
                is_supervisor_head: (data?.provider?.id == '1' && (data?.role == 'rm' || data?.role == 'rtl' || data?.role == 'admin' || data?.role == 'hr'))
                    ? '1'
                    : (data?.provider?.id != '1' && data?.role == 'rm') ? '1' : '0',
                supervisor_id: `${data?.supervisor?.id ?? ''}`,
            })
                .then((res) => {
                    if (res.data?.status == 'success') {
                        toast.success('User created successfully!')
                        reset()
                        closeDialog()
                        fetchUsers()
                    }
                    if (res.data?.status == 'error') {
                        toast.error(`Error! \n ${res.data?.msg}`)
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    setSubmitting(false)
                    dispatch(updateProcessState(false))
                })
        } else if (type == 'edit') {
            const newData = {
                ...restData,
                id: user?.id,
                status: (userStatus == 'active') ? '1' : '0',
                email: user?.email ?? '',
                client_id: selectedClients
                    ? selectedClients?.map((el, i) => (el.id)).toString()
                    : '',
                supervisor_id: `${data?.supervisor?.id ?? ''}`,
            }
            API.post('/edit_user', { ...newData })
                .then((res) => {
                    if (res.data?.status == 'success') {
                        toast.success('User updated successfully!')
                        reset()
                        closeDialog()
                        fetchUsers()
                    }
                    if (res.data?.status == 'error') {
                        toast.error(`Error! \n ${res.data?.msg}`)
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    setSubmitting(false)
                    dispatch(updateProcessState(false))
                })
        }
    }


    useEffect(() => {
        fetchSupervisors()
        fetchRegionalClients()
        fetchProviders()
    }, [])


    useEffect(() => {
        if (providers) {
            setProvider(providers.filter((el: any) => el?.id == user?.provider_id)[0])
            setValue('provider', providers.filter((el: any) => el?.id == user?.provider_id)[0], { shouldValidate: true, shouldDirty: true })
        }
    }, [type == 'edit' && providers])

    useEffect(() => {
        setValue('supervisor', supervisors?.filter((el: any) => el?.id == user?.supervisor_id)[0], { shouldDirty: true })
    }, [type == 'edit' && supervisors])

    useEffect(() => {
        const alreadyAssigned = `${user?.client_id}`.split(',').map((filterItem: any) => filterItem.trim());
        setSelectedClients(availableClients?.length > 0
            ? availableClients?.filter((item: any) => alreadyAssigned?.includes(item.id.toString()))
            : [])
    }, [type == 'edit' && availableClients?.length > 0])

    return (
        <Box
            sx={{
                minWidth: '280px',
                width: { xs: '100%', md: '70vw' },
                maxWidth: '650px',
                minHeight: '200px',
                '& .boxTitle': {
                    fontWeight: 500,
                    fontSize: '18px',
                    mb: 3,
                },
                '& .formInput': {
                    flex: '1 1 260px',
                },
            }}>
            <Typography className={'boxTitle'}>
                {type == 'edit'
                    ? 'Update User'
                    : 'Create User'
                }
            </Typography>

            <Box component={'form'}
                sx={{
                    '& .actionBox': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        mt: 3,
                        '& .MuiButton-root': {
                            textTransform: 'none',
                            minWidth: { xs: 'auto', md: '150px' },
                            flex: { xs: 1, md: 0 },
                            '&.buttonGradient': {
                                backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                outline: `0px solid ${theme.palette.primary.main}`,
                                outlineOffset: 0,
                                boxShadow: 0,
                                transition: 'all 100ms ease',
                                '&:hover': {
                                    outline: `2px solid ${theme.palette.primary.main}`,
                                    outlineOffset: '2px',
                                    '&:active': {
                                        transform: 'scale(0.98) !important'
                                    }
                                },
                            },
                        },
                    },
                }}>

                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 3,
                        mx: { xs: 0, sm: 3, md: 5 },
                    }}>
                    <Box
                        sx={{
                            flex: '1 1 300px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                            '& img': {
                                objectFit: 'contain',
                                maxHeight: '120px',
                                maxWidth: '90%',
                            },
                            '& .assetDetail': {
                                flex: '1 1 200px',
                                display: 'flex',
                                flexFlow: 'column',
                                alignItems: 'flex-end',
                                justifyContent: 'center',
                                '& .detail': {
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: theme.typography.h1.fontFamily,
                                    color: theme.palette.text.secondary,
                                },

                            },
                        }}>
                        <img src={operatorIllustration} alt={'user Illustration'} />
                        {type == 'edit' &&
                            <Box className={'assetDetail'}>
                                <Box
                                    sx={{
                                        boxShadow: `0 0 12px -3px #83838390`,
                                        borderRadius: '16px',
                                        p: '4px',
                                        textAlign: 'center',
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        width: '100px',
                                        mb: 1,
                                        '& .MuiInput-root': {
                                            color: theme.palette.primary.contrastText,
                                            background: userStatus == 'active'
                                                ? `${alpha(theme.palette.success.main, 1)} !important`
                                                : `${alpha(theme.palette.error.main, 1)} !important`,
                                            borderRadius: '12px',
                                            '&:before, &:after': {
                                                display: 'none',
                                            },
                                            '& .MuiSelect-select': {
                                                borderRadius: '12px',
                                                padding: '2px 16px 4px 12px',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                fontWeight: 500,
                                            },
                                        },

                                    }}>
                                    <TextField
                                        required select variant="standard" value={userStatus}
                                        onChange={(e) => setUserStatus(e.target.value)}
                                        size={'small'} placeholder={'Choose option'}
                                        sx={{ flex: 1 }}
                                    >
                                        <MenuItem key={'active'} value={'active'}>Active</MenuItem>
                                        <MenuItem key={'inactive'} value={'inactive'}>In-active</MenuItem>
                                    </TextField>
                                </Box>

                            </Box>
                        }
                    </Box>
                    <Box
                        sx={{
                            flex: '1 1 300px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            columnGap: 3,
                            minHeight: 'min(40vh, 300px)',
                            alignContent: 'flex-start',
                        }}>

                        <Controller name={`provider`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            render={({ field: { value, onChange } }) => (
                                <ThemeTextField
                                    disabled={type == 'edit'}
                                    value={provider}
                                    onChange={(e) => {
                                        setProvider(e.target.value)
                                        setValue('provider', e.target.value, { shouldValidate: true })
                                        setValue(`supervisor`, null, { shouldDirty: true })
                                    }} required className={'formInput'}
                                    error={Boolean(errors?.provider)}
                                    helperText={(errors?.provider?.message ?? '').toString()}
                                    size={'small'} label={'Provider'} select
                                    placeholder={'select provider'}
                                >
                                    {providers?.map((item: any, index: number) =>
                                        <MenuItem key={index} value={item} disabled={item?.status == '0'}>{camelCaseWords(item?.name ?? '')}</MenuItem>
                                    )}
                                </ThemeTextField>
                            )} />

                        <Controller name={`role`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            defaultValue={''}
                            render={({ field: { value, onChange } }) => (
                                <ThemeTextField
                                    value={value}
                                    onChange={(e) => {
                                        setValue('role', e.target.value, { shouldValidate: true })
                                        setValue(`supervisor`, null, { shouldDirty: true })
                                    }}
                                    disabled={type == 'edit'}
                                    required className={'formInput'}
                                    error={Boolean(errors?.role)}
                                    helperText={(errors?.role?.message ?? '').toString()}
                                    size={'small'} label={'Role'}
                                    placeholder={'select role'} select
                                >
                                    {roles?.map((role: string, index: number) =>
                                        <MenuItem
                                            key={index} value={role}
                                            disabled={(role != 'rm' && provider?.id != '1')}>
                                            {capitalizeWords(role)}
                                        </MenuItem>
                                    )}
                                </ThemeTextField>
                            )} />

                        <Controller name={`supervisor`}
                            control={control}
                            rules={{
                                required: {
                                    value: (inputData.provider?.id == '1' && (inputData.role == 'rm' || inputData.role == 'rtl')),
                                    message: "Required"
                                }
                            }}
                            defaultValue={null}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    size={"small"}
                                    autoComplete
                                    onChange={(e, data) => {
                                        onChange(data);
                                        setValue(`supervisor`, data, { shouldDirty: true, shouldValidate: true });
                                    }}
                                    noOptionsText={supervisors.length < 1 ? "No supervisor Found" : ""}
                                    value={value || null}
                                    loading={fetchingSupervisors}
                                    disabled={inputData.role != 'rm' && inputData.role != 'rtl'}
                                    sx={{
                                        display: (inputData.provider?.id == '1' && (inputData.role == 'rm' || inputData.role == 'rtl')) ? 'flex' : 'none',
                                        flex: "1 1 250px",
                                        "& .MuiOutlinedInput-root": {
                                            paddingRight: "0.15rem !important",
                                        },
                                    }}
                                    options={filterSupervisors?.filter((el: any) => el?.status == '1')}
                                    isOptionEqualToValue={((option: any, value: any) => option?.id == value?.id)}
                                    renderOption={(props, option, { selected }) => (
                                        <Box
                                            component={"li"}
                                            {...props}
                                            key={props.id}
                                            sx={{
                                                p: "2px 24px 2px 6px",
                                                display: "flex",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                flexFlow: "column",
                                                textAlign: "left",
                                                gap: "2px",
                                                borderRadius: "12px",
                                                mx: "2px",
                                                position: 'relative',

                                                "& .village": {
                                                    width: "100%",
                                                    fontSize: "15px",
                                                    lineHeight: "15px",
                                                    fontWeight: 500,
                                                    color: theme.palette.text.secondary,
                                                },
                                                "& .state": {
                                                    width: "100%",
                                                    fontSize: "12px",
                                                    lineHeight: "12px",
                                                    fontWeight: 500,
                                                    color: theme.palette.text.disabled,
                                                },
                                            }}
                                        >
                                            <Typography className={"village"}>{option?.name}</Typography>
                                            <Typography className={"state"}>{option?.email}</Typography>
                                            <Typography sx={{
                                                position: 'absolute',
                                                right: '4px',
                                                textTransform: 'uppercase',
                                                fontSize: '10px',
                                                fontWeight: 600,
                                                background: alpha(theme.palette.warning.main, 0.15),
                                                color: theme.palette.warning.main,
                                                borderRadius: '50vh',
                                                padding: '2px 8px',
                                            }}>{option?.role}</Typography>
                                        </Box>
                                    )}
                                    getOptionLabel={(option) => option.name ?? ""}
                                    renderInput={(params) => (
                                        <ThemeTextField
                                            {...params}
                                            required
                                            error={Boolean(errors?.supervisor)}
                                            helperText={(errors?.supervisor?.message ?? "").toString()}
                                            size={"small"}
                                            label={"Supervisor"}
                                            placeholder={"Select Supervisor"}

                                        />
                                    )}
                                />
                            )} />

                        <Controller name={`emp_id`}
                            control={control}
                            rules={{
                                required: { value: (inputData.provider?.id == '1' && (inputData.role == 'rm' || inputData.role == 'rtl')), message: 'Required' },
                                pattern: { value: /^[a-zA-Z0-9 ]*$/, message: 'Special characters not allowed' },
                            }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    sx={{
                                        display: (inputData.provider?.id == '1' && (inputData.role == 'rm' || inputData.role == 'rtl')) ? 'flex' : 'none',
                                    }}
                                    disabled={!(inputData.provider?.id == '1' && (inputData.role == 'rm' || inputData.role == 'rtl'))}
                                    error={Boolean(errors?.emp_id)}
                                    helperText={(errors?.emp_id?.message ?? '').toString()}
                                    onBlur={(e) => setValue(`emp_id`, `${e.target?.value?.toUpperCase()}`)}
                                    size={'small'} label={'Employee ID'}
                                    placeholder={'eg XXXXXXX'}
                                    onChange={(e) => setValue('emp_id', e.target.value?.toUpperCase(), { shouldDirty: true, shouldValidate: true })}
                                />
                            )} />

                        <Controller name={`name`}
                            control={control}
                            rules={{
                                required: { value: true, message: 'Required' },
                                pattern: { value: /^[a-zA-Z0-9 ]*$/, message: 'Special characters not allowed' },
                            }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.name)}
                                    helperText={(errors?.name?.message ?? '').toString()}
                                    size={'small'} label={'Name'}
                                    placeholder={'eg User Name'}
                                />
                            )} />

                        <Controller name={`email`}
                            control={control}
                            rules={{
                                required: { value: true, message: 'Required' },
                                pattern: { value: /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/, message: 'Invalid email address' }
                            }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.email)}
                                    disabled={type == 'edit'}
                                    helperText={(errors?.email?.message ?? '').toString()}
                                    size={'small'} label={'Email'}
                                    placeholder={'email@your.domain'}
                                />
                            )} />

                        <Controller name={`password`}
                            control={control}
                            defaultValue={''}
                            rules={{
                                required: { value: true, message: 'Required' },
                                minLength: { value: 8, message: 'Minimum 8 characters' }
                            }}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.password)}
                                    helperText={(errors?.password?.message ?? '').toString()}
                                    size={'small'} label={'Password'}
                                    placeholder={'XXXXXXXX'}
                                />
                            )} />

                        <Autocomplete
                            loading={fetchingRegionalClients}
                            disabled={!(inputData?.role == 'rtl' || inputData?.role == 'cso' || inputData?.role == 'client')}
                            multiple
                            sx={{
                                display: (inputData?.role == 'rtl' || inputData?.role == 'cso' || inputData?.role == 'client') ? 'flex' : 'none'
                            }}
                            size="small"
                            limitTags={2}
                            className={'formInput'}
                            options={availableClients ?? []}
                            filterSelectedOptions
                            value={selectedClients}
                            onChange={(e, data) => setSelectedClients(data)}
                            isOptionEqualToValue={(option, value) => option?.id == value?.id}
                            getOptionLabel={(option) => camelCaseWords(option?.name) ?? ''}
                            getOptionDisabled={option => option?.status == '0'}
                            renderInput={(params) => (
                                <ThemeTextField
                                    {...params}
                                    size={'small'} label={'Clients'}
                                    placeholder={'Please select clients'}
                                />
                            )} />

                    </Box>
                </Box>



                <Box className={'actionBox'}>
                    <Button className={''} variant={'outlined'} onClick={closeDialog}>Discard & Close</Button>
                    <LoadingButton loading={submitting} endIcon={<></>} loadingPosition={'end'}
                        className={'buttonGradient'} variant={'contained'}
                        onClick={handleSubmit(onSubmit)}>
                        {type.type == 'create' ? 'Save & Create' : 'Save & Update'}
                    </LoadingButton>
                </Box>
            </Box>
        </Box>
    )
}

