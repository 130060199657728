import React, { useCallback, useEffect, useState } from 'react'
import PageContainer from '../../../components/container/page-container'
import TitleBar from '../../../components/container/TitleBar'
import { Box, Button, Typography, InputAdornment, InputLabel, MenuItem, TextField, alpha, useTheme, Autocomplete, FormControlLabel } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { updateProcessState } from '../../../slices/process-slice'
import { CreditCardOutlined } from '@mui/icons-material'
import { ThemeTextField, themeTextFieldSx } from '../../../components/inputs/theme-text-field'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import FileUploadInput from '../../../components/inputs/fileUploadInput'
import operatorIllustration from '../../../assets/images/illustartions/support.png'
import { LoadingButton } from '@mui/lab'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router'
import moment, { isMoment } from 'moment'
import { RootState } from '../../../store/store'
import { Operator } from '../../../models/operator'
import { camelCaseWords, s3BucketUrl } from '../../../utils/app-helper'
import API from '../../../api'
import { Vehicle } from '../../../models/vehicle'
import { useAssets } from '../../../hooks/useAssets'
import { useVehicles } from '../../../hooks/useVehicles'
import { useAvailableAssets } from '../../../hooks/useAvailableAssets'
import { User } from '../../../models/user'
import { ThemeSwitchInput } from '../../../components/inputs/theme-switch'
import { useSupervisors } from '../../../hooks/useSupervisors'


function SingleOperator() {

    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser

    const selectedOperator: Operator = useSelector((state: RootState) => state.operatorDetails.selectedOperator)
    const [operatorStatus, setOperatorStatus] = useState<string>(selectedOperator.status == '1' ? 'active' : 'inactive')

    const { availableAssets, fetchAvailableAssets, fetchingAvailableAssets } = useAvailableAssets()
    const { fetchSupervisors, fetchingSupervisors, supervisors } = useSupervisors()

    const [selectedAsset, setSelectedAsset] = useState<any | null>(null)

    const { fetchVehicles, fetchingVehicles, vehicles } = useVehicles()
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null)

    const [submitting, setSubmitting] = useState(false)
    const [isCaptain, setIsCaptain] = useState(selectedOperator?.is_captain == '1')


    const { handleSubmit, register, control, reset, setValue, watch, formState: { errors } } = useForm()
    const data = watch()

    const [searchedSubDistrict, setSearchedSubDistrict] = useState('')

    const [subDistricts, setSubDistricts] = useState<any[]>([])
    const [fetchingSubDistricts, setFetchingSubDistricts] = useState(false)

    const fetchSubDistrict = useCallback((village_name: string) => {
        setFetchingSubDistricts(true)
        API.get(`/fetch-villages`, {
            params: {
                state_name: '',
                district_name: '',
                subdistrict_name: village_name,
                village_name: '',
            }
        })
            .then((res) => {
                if (res.data?.statuscode == '200') {
                    setSubDistricts(res.data?.village_data?.map((item: any) => ({
                        district_name: item?.district_name,
                        state_name: item?.state_name,
                        subdistrict_name: item?.subdistrict_name,
                    }))
                        .filter((el: any, index: number, array: any) =>
                            index ===
                            array.findIndex(
                                (item: any) =>
                                    item.district_name === el.district_name &&
                                    item.state_name === el.state_name &&
                                    item.subdistrict_name === el.subdistrict_name
                            )
                        ) ?? [])
                } else {
                    setSubDistricts([])
                }
            })
            .catch((err) => { toast.success("Oops! Something bad at our end") })
            .finally(() => {
                setFetchingSubDistricts(false)
            });
    }, [searchedSubDistrict])


    useEffect(() => {
        searchedSubDistrict.length == 3 &&
            fetchSubDistrict(searchedSubDistrict)
    }, [searchedSubDistrict])




    const resetForm = () => {
        reset()
        const initialvehcile = vehicles.filter((v: Vehicle) => v.id == selectedOperator?.vehicle_id)[0]
        setSelectedVehicle(initialvehcile)
        setValue('district', selectedOperator?.district, { shouldValidate: true })
        setValue('sub_district', selectedOperator?.sub_district, { shouldValidate: true })
        setValue('state', selectedOperator?.state, { shouldValidate: true })
    }

    const onSubmit = (data: any) => {

        const { subDistrict, end_date, supervisor, ...restData } = data
        if (operatorStatus == 'inactive') {
            if (data?.vehicle_id != null) {
                if (moment(data?.end_date).format('YYYY-MM-DD') != 'Invalid date') {
                    setSubmitting(true)
                    dispatch(updateProcessState(true))
                    const newData = {
                        id: selectedOperator?.id,
                        end_date: data?.end_date ? `${moment(data?.end_date).format('YYYY-MM-DD')}` : '',
                    }
                    API.post('/delete_operator', { ...newData }, { headers: { "Content-Type": "multipart/form-data" } })
                        .then((res) => {
                            if (res.data?.status == 'success') {
                                toast.success('Operator removed successfully!')
                                reset()
                                navigate('/fleet-management/operators')
                            }
                            if (res.data?.status == 'error') {
                                toast.error(`Error! \n ${res.data?.msg}`)
                            }
                        })
                        .catch((err) => console.log(err))
                        .finally(() => {
                            setSubmitting(false)
                            dispatch(updateProcessState(false))
                        })
                } else { toast.error('Inactive date is required.') }
            } else { toast.error('Vehicle assigned, please remove it.') }
        } else {
            setSubmitting(true)
            dispatch(updateProcessState(true))
            const newData = {
                ...restData,
                id: selectedOperator?.id,
                user_id: selectedOperator?.user_id,
                vehicle_id: data.vehicle_id?.value ?? selectedVehicle?.id ?? '',
                asset_id: data.asset_id?.value ?? selectedAsset?.id ?? '',
                start_date: data?.start_date ? `${moment(data?.start_date).format('YYYY-MM-DD')}` : '',
                end_date: operatorStatus == 'inactive' ? `${moment(data?.end_date).format('YYYY-MM-DD')}` : '',
                district: data?.district ?? selectedOperator?.district,
                sub_district: data?.sub_district ?? selectedOperator?.sub_district,
                state: data?.state ?? selectedOperator?.state,
                emp_id: data?.emp_id ?? selectedOperator?.emp_id,
                supervisor_id: currentUser?.provider_id != '1' ? `${currentUser?.id}` : `${supervisor?.id}`,
                is_captain: currentUser?.provider_id == '1' ? isCaptain ? '1' : '0' : '0',

                ...(data?.aadhaar_img?.length > 0 ? { aadhaar_img: data?.aadhaar_img[0] } : {}),
                ...(data?.dl_img?.length > 0 ? { dl_img: data?.dl_img[0] } : {}),
                ...(data?.rpc_img?.length > 0 ? { rpc_img: data?.rpc_img[0] } : {}),
            }

            const dlSize = data?.dl_img?.length > 0 ? (data?.dl_img[0]?.size / (1024 * 1024)) > 2 : false // to compare size max is 2mb
            const adSize = data?.aadhaar_img?.length > 0 ? (data?.aadhaar_img[0]?.size / (1024 * 1024)) > 2 : false
            const rpcSize = data?.rpc_img?.length > 0 ? (data?.rpc_img[0]?.size / (1024 * 1024)) > 2 : false
            if (dlSize || adSize || rpcSize) {
                toast.error(`Error! Please change ${dlSize ? 'DL Image' : adSize ? 'Aadhar Image' : 'RPC Image'} \n Max 2mb allowed.`)
                setSubmitting(false)
                dispatch(updateProcessState(false))
            } else {
                API.post('/edit_operator_details', { ...newData }, { headers: { "Content-Type": "multipart/form-data" } })
                    .then((res) => {
                        if (res.data?.status == 'success') {
                            toast.success('Operator updated successfully!')
                            reset()
                            navigate('/fleet-management/operators')
                        }
                        if (res.data?.status == 'error') {
                            toast.error(`Error! \n ${res.data?.msg}`)
                        }
                    })
                    .catch((err) => console.log(err))
                    .finally(() => {
                        setSubmitting(false)
                        dispatch(updateProcessState(false))
                    })
            }
        }
    }


    useEffect(() => {
        fetchVehicles()
        fetchAvailableAssets()
        if (currentUser?.provider_id == '1') {
            fetchSupervisors('admin,rm,hr,rtl,operator')
        }
    }, [])

    useEffect(() => {
        if (vehicles.length > 0) {
            setValue('vehicle_id', `${selectedOperator.vehicle_id}`, { shouldValidate: true })
            const initialvehicle = vehicles.filter((vehicle: any) => vehicle.id == selectedOperator?.vehicle_id)[0]
            setSelectedVehicle(initialvehicle)
        }
    }, [vehicles])

    useEffect(() => {
        if (availableAssets.length > 0) {
            setValue('asset_id', `${selectedOperator.asset_id}`, { shouldValidate: true })
            const initialAsset = availableAssets.filter((asset: any) => asset.id == selectedOperator?.asset_id)[0]
            setSelectedAsset(initialAsset)
        }
    }, [availableAssets])

    useEffect(() => {
        !selectedOperator?.id &&
            navigate('/fleet-management/operators')

        if (selectedOperator?.id) {
            setValue('district', selectedOperator?.district, { shouldValidate: true })
            setValue('sub_district', selectedOperator?.sub_district, { shouldValidate: true })
            setValue('state', selectedOperator?.state, { shouldValidate: true })
            setIsCaptain(selectedOperator?.is_captain == '1' ? true : false)
        }
    }, [selectedOperator])

    useEffect(() => {
        if (currentUser?.provider_id == '1') {
            if (supervisors.length > 0) {
                const initialAsset = supervisors.filter((supervisor: any) => supervisor.id == selectedOperator?.supervisor_id)[0]
                setValue('supervisor', initialAsset, { shouldValidate: true, shouldDirty: true })
            }
        }
    }, [supervisors])




    return (
        <PageContainer>
            <TitleBar title={'Update Operator'} backIcon
                secondChild={<>
                    {currentUser?.provider_id == '1' &&
                        <FormControlLabel
                            value="isCaptain"
                            control={<ThemeSwitchInput value={isCaptain} checked={isCaptain} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIsCaptain(event.target.checked)} />}
                            label="Is Captain"
                            labelPlacement="start"
                            className={'animate__animated animate__fadeIn'}
                            sx={{ gap: 1, }}
                        />
                    }
                </>} />

            {(currentUser?.provider_id == selectedOperator?.owned_by_id)
                ? <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 3,
                    mx: { xs: 0, sm: 3, md: 5 },
                }}>
                    <Box sx={{
                        flex: '1 1 300px',
                        order: 0,
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        alignContent: 'flex-start',
                        columnGap: 2,
                    }}>
                        {currentUser?.provider_id == '1' &&
                            <Controller name={`emp_id`}
                                control={control}
                                rules={{ required: { value: true, message: '' } }}
                                defaultValue={selectedOperator.emp_id ?? ''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} required className={'formInput'}
                                        sx={{ flex: '1 1 200px' }}
                                        error={Boolean(errors?.emp_id)}
                                        helperText={(errors?.emp_id?.message ?? '').toString()}
                                        size={'small'} label={'Employee ID'}
                                        onBlur={(e) => setValue(`emp_id`, `${e.target?.value?.toUpperCase()}`)}
                                        placeholder={'XXXXXXXXX'}
                                    />
                                )} />
                        }
                        <Controller name={`name`}
                            control={control}
                            rules={{ required: { value: true, message: '' } }}
                            defaultValue={selectedOperator.name ?? ''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    sx={{ flex: '1 1 300px' }}
                                    error={Boolean(errors?.name)}
                                    helperText={(errors?.name?.message ?? '').toString()}
                                    size={'small'} label={'Name'}
                                    placeholder={'Enter name'}
                                />
                            )} />
                        <Controller name={`phone`}
                            control={control}
                            rules={{
                                required: { value: true, message: '' },
                                pattern: { value: /^[6789]\d{9}$/, message: 'Invalid mobile number' },
                            }}
                            defaultValue={selectedOperator.phone ?? ''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    sx={{ flex: '1 1 200px' }}
                                    error={Boolean(errors?.phone)} disabled
                                    helperText={(errors?.phone?.message ?? '').toString()}
                                    size={'small'} label={'Mobile'}
                                    placeholder={'XXXX XXX XXX'}
                                />
                            )} />

                        {currentUser?.provider_id == '1' &&
                            <Controller name={`supervisor`}
                                control={control}
                                rules={{
                                    required: {
                                        value: currentUser?.provider_id == '1',
                                        message: "Required"
                                    }
                                }}
                                defaultValue={null}
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        size={"small"}
                                        autoComplete
                                        onChange={(e, data) => {
                                            onChange(data);
                                            setValue(`supervisor`, data, { shouldDirty: true, shouldValidate: true });
                                        }}
                                        noOptionsText={supervisors.length < 1 ? "No supervisor Found" : ""}
                                        value={value || null}
                                        loading={fetchingSupervisors}
                                        sx={{
                                            flex: "1 1 280px",
                                            "& .MuiOutlinedInput-root": {
                                                paddingRight: "0.15rem !important",
                                            },
                                        }}
                                        options={supervisors?.filter((el: any) => ((el?.emp_id != selectedOperator?.emp_id) && el?.status == '1'))}
                                        getOptionDisabled={(option) => option.emp_id == selectedOperator?.emp_id}
                                        isOptionEqualToValue={((option: any, value: any) => option?.id == value?.id)}
                                        renderOption={(props, option, { selected }) => (
                                            <Box
                                                component={"li"}
                                                {...props}
                                                key={props.id}
                                                sx={{
                                                    p: "2px 24px 2px 6px",
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                    justifyContent: "flex-start",
                                                    flexFlow: "column",
                                                    textAlign: "left",
                                                    gap: "2px",
                                                    borderRadius: "12px",
                                                    mx: "2px",
                                                    position: 'relative',

                                                    "& .village": {
                                                        width: "100%",
                                                        fontSize: "15px",
                                                        lineHeight: "15px",
                                                        fontWeight: 500,
                                                        color: theme.palette.text.secondary,
                                                    },
                                                    "& .state": {
                                                        width: "100%",
                                                        fontSize: "12px",
                                                        lineHeight: "12px",
                                                        fontWeight: 500,
                                                        color: theme.palette.text.disabled,
                                                    },
                                                }}
                                            >
                                                <Typography className={"village"}>{option?.name}</Typography>
                                                <Typography className={"state"}>{option?.email}</Typography>
                                                <Typography sx={{
                                                    position: 'absolute',
                                                    right: '4px',
                                                    textTransform: 'uppercase',
                                                    fontSize: '10px',
                                                    fontWeight: 600,
                                                    background: alpha(theme.palette.warning.main, 0.15),
                                                    color: theme.palette.warning.main,
                                                    borderRadius: '50vh',
                                                    padding: '2px 8px',
                                                }}>{option?.role == 'operator' ? 'Captain' : option?.role}</Typography>
                                            </Box>
                                        )}
                                        getOptionLabel={(option) => option.name ?? ""}
                                        renderInput={(params) => (
                                            <ThemeTextField
                                                {...params}
                                                required
                                                error={Boolean(errors?.supervisor)}
                                                helperText={(errors?.supervisor?.message ?? "").toString()}
                                                size={"small"}
                                                label={"Supervisor"}
                                                placeholder={"Select Supervisor"}

                                            />
                                        )}
                                    />
                                )} />
                        }

                        <Controller
                            name={`start_date`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            defaultValue={moment(selectedOperator?.start_date) ?? ''}
                            render={({ field: { onChange, value } }) => (
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        disableFuture
                                        label="Joining Date"
                                        format={'DD-MM-Y'}
                                        value={value}
                                        onChange={onChange}
                                        sx={{ width: '100%' }}
                                        slotProps={{
                                            textField: {
                                                required: true,
                                                size: 'small',
                                                sx: themeTextFieldSx,
                                                style: { width: '100%', flex: '1 1 200px', marginBottom: '24px !important' },
                                                error: Boolean(errors?.start_date),
                                                helperText: errors?.start_date?.message?.toString() ?? '',
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            )} />
                    </Box>

                    <Box sx={{
                        flex: '1 1 300px',
                        display: 'grid',
                        placeItems: 'center',
                        order: { xs: -1, sm: 1 },
                        '& img': {
                            objectFit: 'contain',
                            maxHeight: 'min(30vh, 300px)',
                            maxWidth: '90%',

                        },
                    }}>
                        <img src={operatorIllustration} alt={'a'} />
                    </Box>

                    <Box sx={{
                        order: 2,
                        width: '100%',
                        display: 'grid',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        justifyItems: 'stretch',
                        gridTemplateColumns: `repeat( auto-fit, minmax(250px, 1fr) )`,
                        gap: 2,
                        flexWrap: 'wrap',
                    }}>

                        <FileUploadInput
                            label={'Driving License'}
                            inputBlock={
                                <Controller name={`dl_no`}
                                    control={control}
                                    rules={{ required: { value: data.aadhaar_no == '', message: 'Required' } }}
                                    defaultValue={selectedOperator.dl_no ?? ''}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            required
                                            className={'formInput'}
                                            error={Boolean(errors?.dl_no)}
                                            size={'small'}
                                            id="standard-basic"
                                            onBlur={(e) => setValue(`dl_no`, `${e.target?.value?.toUpperCase()}`)}
                                            variant="standard"
                                            placeholder='License Number'
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">
                                                    <CreditCardOutlined sx={{ fontSize: '16px' }} />
                                                </InputAdornment>,
                                            }}
                                            sx={{
                                                mx: 2, mt: 1,
                                                '& input': {
                                                    fontSize: '14px'
                                                },
                                            }} />
                                    )} />
                            }
                            accept={'image/png, image/gif, image/jpeg'}
                            defaultImage={`${s3BucketUrl}/dl/${selectedOperator?.dl_img}`}
                            reactHookProps={{ ...register('dl_img') }}
                            onClear={() => setValue('dl_img', '')}
                            errorMessage={errors.dl_img?.message?.toString() ?? errors.dl_no?.message?.toString()}
                        />

                        <FileUploadInput
                            label={'Aadhar Card'}
                            inputBlock={
                                <Controller name={`aadhaar_no`}
                                    control={control}
                                    rules={{ required: { value: data.dl_no == '', message: 'Required' } }}
                                    defaultValue={selectedOperator.aadhaar_no ?? ''}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            required
                                            className={'formInput'}
                                            error={Boolean(errors?.aadhaar_no)}
                                            size={'small'}
                                            id="standard-basic"
                                            variant="standard"
                                            placeholder='XXXX XXXX XXXX'
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">
                                                    <CreditCardOutlined sx={{ fontSize: '16px' }} />
                                                </InputAdornment>,
                                            }}
                                            sx={{
                                                mx: 2, mt: 1,
                                                '& input': {
                                                    fontSize: '14px'
                                                },
                                            }} />
                                    )} />
                            }
                            accept={'image/png, image/gif, image/jpeg'}
                            defaultImage={`${s3BucketUrl}/aadhar/${selectedOperator?.aadhaar_img}`}
                            reactHookProps={{ ...register('aadhaar_img') }}
                            onClear={() => setValue('aadhaar_img', '')}
                            errorMessage={errors.aadhaar_img?.message?.toString() ?? errors.aadhaar_no?.message?.toString()}
                        />

                        <FileUploadInput
                            label={'Remote Pilot Certificate'}
                            inputBlock={
                                <Controller name={`rpc_no`}
                                    control={control}
                                    rules={{ required: { value: true, message: 'Required' } }}
                                    defaultValue={selectedOperator.rpc_no ?? ''}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            required
                                            className={'formInput'}
                                            error={Boolean(errors?.rpc_no)}
                                            size={'small'}
                                            id="standard-basic"
                                            onBlur={(e) => setValue(`rpc_no`, `${e.target?.value?.toUpperCase()}`)}
                                            variant="standard"
                                            placeholder='XXXX XXXX XXXX'
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">
                                                    <CreditCardOutlined sx={{ fontSize: '16px' }} />
                                                </InputAdornment>,
                                            }}
                                            sx={{
                                                mx: 2, mt: 1,
                                                '& input': {
                                                    fontSize: '14px'
                                                },
                                            }} />
                                    )} />
                            }
                            accept={'image/png, image/gif, image/jpeg'}
                            defaultImage={`${s3BucketUrl}/rpc/${selectedOperator?.rpc_img}`}
                            reactHookProps={{ ...register('rpc_img') }}
                            onClear={() => setValue('rpc_img', '')}
                            errorMessage={errors.rpc_img?.message?.toString() ?? errors.rpc_no?.message?.toString()}
                        />
                    </Box>

                    <Box
                        sx={{
                            order: 3,
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            columnGap: 2,
                            width: '100%',
                            padding: '0.5rem ',
                            borderRadius: '18px',
                            background: theme.palette.mode == 'dark'
                                ? '#0e0e0e'
                                : `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.07)}, ${theme.palette.background.paper})`,
                            boxShadow: theme.palette.mode == 'dark' ? 'none' : `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                            border: `2px solid ${theme.palette.background.paper}`,
                            '& .formInput': {
                                flex: '1 1 200px',
                            },
                        }}>

                        <Typography sx={{
                            width: '100%',
                            fontFamily: theme.typography.h1.fontFamily,
                            fontSize: '14px',
                            fontWeight: 600,
                            color: theme.palette.primary.main,
                            pl: 2,
                            mb: 1,

                        }}>Credentials</Typography>

                        <Controller name={`user_id`}
                            control={control}
                            rules={{
                                required: { value: true, message: '' },
                                pattern: { value: /^[^\s\W]+$/, message: 'No space or special character allowed' }
                            }}
                            defaultValue={selectedOperator?.user_id ?? ''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'} disabled
                                    error={Boolean(errors?.user_id)}
                                    helperText={(errors?.user_id?.message ?? '').toString()}
                                    onBlur={(e) => setValue(`user_id`, `${e.target?.value?.toLowerCase()}`)}
                                    size={'small'} label={'Username'}
                                    placeholder={'eg. swanstore'}
                                />
                            )} />

                        <Controller name={`user_password`}
                            control={control}
                            rules={{ required: { value: true, message: '' } }}
                            defaultValue={selectedOperator?.user_password ?? ''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.user_password)}
                                    helperText={(errors?.user_password?.message ?? '').toString()}
                                    size={'small'} label={'Password'}
                                    placeholder={'********'}
                                />
                            )} />

                        <Controller name={`subDistrict`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            defaultValue={{
                                subdistrict_name: selectedOperator?.sub_district,
                                district_name: selectedOperator?.district,
                                state_name: selectedOperator?.state,
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete size={'small'}
                                    autoComplete
                                    className={'formItem'}
                                    onClose={() => {
                                        setSearchedSubDistrict('')
                                        setSubDistricts([])
                                    }}
                                    onInputChange={(e, inputvalue) => {
                                        setSearchedSubDistrict(inputvalue)
                                        inputvalue?.length <= 3 && setSubDistricts([])
                                    }}
                                    onChange={(e, data) => {
                                        onChange(data)
                                        setValue(`district`, `${data?.district_name ?? ''}`, { shouldDirty: true, shouldValidate: true });
                                        setValue(`sub_district`, `${data?.subdistrict_name ?? ''}`, { shouldDirty: true, shouldValidate: true });
                                        setValue(`state`, `${data?.state_name ?? ''}`, { shouldDirty: true, shouldValidate: true });
                                    }}
                                    noOptionsText={searchedSubDistrict.length < 3 ? 'Min 3 characters required to search' : 'No district Found'}
                                    value={value || null}
                                    loading={fetchingSubDistricts}
                                    sx={{ flex: '1 1 250px' }}
                                    options={subDistricts}
                                    renderOption={(props, option, { selected }) => (
                                        <Box component={'li'} {...props} key={props.id}
                                            sx={{
                                                p: '2px 6px',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                justifyContent: 'flex-start',
                                                flexFlow: 'column',
                                                textAlign: 'left',
                                                gap: '2px',
                                                borderRadius: '12px',
                                                mx: '2px',
                                                '& .village': {
                                                    width: '100%',
                                                    fontSize: '15px',
                                                    lineHeight: '15px',
                                                    fontWeight: 500,
                                                    color: theme.palette.text.secondary,
                                                },
                                                '& .state': {
                                                    width: '100%',
                                                    fontSize: '12px',
                                                    lineHeight: '12px',
                                                    fontWeight: 500,
                                                    color: theme.palette.text.disabled,
                                                },
                                            }}>
                                            <Typography className={'village'}>
                                                {option?.subdistrict_name}
                                            </Typography>
                                            <Typography className={'state'}>
                                                {option?.district_name}, {option?.state_name}
                                            </Typography>
                                        </Box>

                                    )}
                                    getOptionLabel={(option) => option.subdistrict_name ?? ''}
                                    renderInput={(params) => (
                                        <ThemeTextField
                                            {...params} required
                                            error={Boolean(errors?.sub_district)}
                                            helperText={(errors?.sub_district?.message ?? '').toString()}
                                            size={'small'} label={'Sub District'}
                                            placeholder={'Sub District'}
                                        />
                                    )} />
                            )} />

                        <input type="hidden" {...register("state", { required: true })} />
                        <input type="hidden" {...register("district", { required: true })} />
                        <input type="hidden" {...register("sub_district", { required: 'Required' })} />


                        <Controller name={`vehicle_id`}
                            control={control}
                            rules={{ required: { value: false, message: 'Required' } }}
                            defaultValue={selectedOperator?.vehicle_id ?? ''}
                            render={({ field }) => (
                                <Autocomplete
                                    options={vehicles}
                                    autoHighlight
                                    className={'formInput'}
                                    value={selectedVehicle}
                                    loading={fetchingVehicles}
                                    getOptionLabel={(option) => `${option.registration_no}`}
                                    getOptionDisabled={(option) => option?.status == "0" || (option?.operator_id != null && option?.operator_id?.length > 0 && option?.id != selectedOperator?.vehicle_id)}

                                    onChange={(e, data) => {
                                        setValue('vehicle_id', data?.id)
                                        setSelectedVehicle(data)
                                    }}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            {option.registration_no} - {option?.status == '1' ? 'Active' : 'Inactive'}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <ThemeTextField
                                            {...params} required
                                            error={Boolean(errors?.vehicle_id)}
                                            helperText={(errors?.vehicle_id?.message ?? '').toString()}
                                            size={'small'} label={'Assigned Vehicle'}
                                            placeholder={'eg. HR20AJ7830'}
                                        />
                                    )}
                                />
                            )} />

                        <Controller name={`asset_id`}
                            control={control}
                            rules={{ required: { value: false, message: 'Required' } }}
                            defaultValue={selectedOperator?.asset_id ?? ''}
                            render={({ field }) => (
                                <Autocomplete
                                    options={availableAssets}
                                    autoHighlight
                                    className={'formInput'}
                                    loading={fetchingAvailableAssets}
                                    value={selectedAsset}
                                    filterSelectedOptions
                                    getOptionLabel={(option) => `${option.asset_id}`}
                                    getOptionDisabled={(option) => (option?.status == "0") || (`${option.id}` != `${selectedOperator?.asset_id}` && option.assigned_status == '1')}
                                    onChange={(e, data) => {
                                        setValue('asset_id', data?.id)
                                        setSelectedAsset(data)
                                    }}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                            {option.asset_id} - {option?.status == '1' ? 'Active' : 'Inactive'}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <ThemeTextField
                                            {...params}
                                            error={Boolean(errors?.asset_id)}
                                            helperText={(errors?.asset_id?.message ?? '').toString()}
                                            size={'small'} label={'Assigned Asset'}
                                            placeholder={'eg. Agribot 1'}
                                        />
                                    )}
                                />
                            )} />
                    </Box>

                    <Box sx={{
                        order: 4,
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                        gap: 2,
                    }}>
                        <Box sx={{
                            boxShadow: `0 0 12px -3px #83838390`,
                            borderRadius: '16px',
                            p: '4px 4px 4px 12px',
                            textAlign: 'center',
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            width: '240px',
                            '& .tabLabel': {
                                fontSize: '14px',
                                fontWeight: 600,
                                flex: 1,
                            },
                            '& .MuiInput-root': {
                                color: theme.palette.primary.contrastText,
                                background: operatorStatus == 'active'
                                    ? `${alpha(theme.palette.success.main, 1)} !important`
                                    : `${alpha(theme.palette.error.main, 1)} !important`,
                                borderRadius: '12px',
                                '&:before, &:after': {
                                    display: 'none',
                                },
                                '& .MuiSelect-select': {
                                    borderRadius: '12px',
                                    padding: '4px 24px 4px 18px',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    fontWeight: 500,
                                },
                            },

                        }}>
                            <InputLabel className={'tabLabel'}>Status</InputLabel>
                            <TextField
                                required select variant="standard" value={operatorStatus}
                                onChange={() => setOperatorStatus(prev => prev == 'inactive' ? 'active' : 'inactive')}
                                size={'small'} placeholder={'Choose option'}
                                sx={{ flex: 1 }}
                            >
                                <MenuItem key={'active'} value={'active'}>Active</MenuItem>
                                <MenuItem key={'inactive'} value={'inactive'}>In-active</MenuItem>
                            </TextField>
                        </Box>
                        {operatorStatus == 'inactive' &&
                            <Controller
                                name={`end_date`}
                                control={control}
                                rules={{ required: { value: true, message: 'Required' } }}
                                defaultValue={moment(selectedOperator?.end_date) ?? ''}
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <MobileDatePicker
                                            {...field}
                                            disableFuture
                                            label="From Date"
                                            format={'DD-MM-Y'}
                                            className={'formInput animate__animated animate__fadeIn'}
                                            sx={{
                                                '& .MuiFormControl-root.flex1': {
                                                    '& .MuiTextField-root': {
                                                        marginBottom: '24px !important',
                                                    },
                                                },

                                                '& .MuiOutlinedInput-root': {
                                                    mb: '24px !important',
                                                },
                                            }}
                                            slotProps={{
                                                textField: {
                                                    required: true,
                                                    size: 'small',
                                                    sx: themeTextFieldSx,
                                                    style: { width: '200px', marginBottom: '24px' },
                                                    error: Boolean(errors?.end_date),
                                                    helperText: errors?.end_date?.message?.toString() ?? '',
                                                }
                                            }}
                                        />
                                        {/*                                     
                                    <DatePicker
                                        disableFuture
                                        minDate={moment(selectedOperator?.start_date)}
                                        label="End Date"
                                        format={'DD-MM-Y'}
                                        value={value}
                                        onChange={onChange}
                                        className={'formInput animate__animated animate__fadeIn'}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                sx: themeTextFieldSx,
                                                style: { width: '200px' },
                                                // className: 'formItem',
                                                error: Boolean(errors?.end_date),
                                                helperText: errors?.end_date?.message?.toString() ?? '',
                                            }
                                        }}
                                    /> */}
                                    </LocalizationProvider>
                                )}
                            />
                        }
                    </Box>


                    {selectedOperator?.status == '1' &&
                        <Box sx={{
                            order: 4,
                            width: '100%',
                            display: 'flex',
                            flexWrap: 'wrap-reverse',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                            my: 4,
                            '& .MuiButton-root': {
                                flex: '1 0 160px',
                                maxWidth: { xs: '100%', sm: '190px' },
                                textWrap: 'nowrap',
                                textTransform: 'capitalize'
                            },
                        }}>

                            <Button
                                size={'large'}
                                variant={'outlined'}
                                onClick={resetForm}>
                                Discard
                            </Button>
                            <LoadingButton
                                variant={'contained'}
                                size={'large'}
                                loading={submitting}
                                endIcon={<></>}
                                loadingPosition={'end'}
                                onClick={handleSubmit(onSubmit)}>
                                Update
                            </LoadingButton>

                        </Box>
                    }

                </Box>
                : 'Not authorized for actions'
            }

        </PageContainer >
    )
}

export default SingleOperator