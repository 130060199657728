import { useCallback, useState } from "react";
import API from "../api";
import { Vehicle } from "../models/vehicle";

export function useVehicles() {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [fetchingVehicles, setFetchingVehicles] = useState(false);

  const fetchVehicles = useCallback(async () => {
    try {
      setFetchingVehicles(true);
      const response = await API.get("/fetch_vehicle_list", {
        headers: { Accept: "application/json" },
      });

      if (response.data?.status === "success") {
        setVehicles(response.data?.data || []);
      } else {
        console.error(
          "Failed to fetch vehicles:",
          response.data?.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    } finally {
      setFetchingVehicles(false);
    }
  }, []);

  return {
    fetchVehicles,
    vehicles,
    fetchingVehicles,
  };
}
