import { Box, Typography } from "@mui/material"
import noData from '../../assets/images/illustartions/no-data.svg'

const NoDataView = () => {

    return (
        <Box sx={{
            minHeight: '280px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column',
            textAlign: 'center',
            '& img': {
                maxHeight: '120px',
                objectFit: 'contain'
            },
        }}>
            <img src={noData} className={'noDataImage'} alt={'no data'} />
            <Typography className={'des'}>
                No data to show.
            </Typography>
        </Box>)
}

export default NoDataView