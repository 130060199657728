import React, { useCallback, useEffect, useState } from 'react'
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
    alpha,
    useMediaQuery,
    useTheme
} from "@mui/material"
import { RemoveRedEyeRounded } from "@mui/icons-material"
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams, GridToolbar } from "@mui/x-data-grid"

import { ThemeDataTable } from "../../components/theme-data-table-container"
import API from "../../api"
import PageContainer from '../../components/container/page-container'
import TitleBar from '../../components/container/TitleBar'
import TableLoading from '../../components/theme-table-container/TableLoading'
import { updateProcessState } from '../../slices/process-slice'
import { useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { camelCaseWords } from '../../utils/app-helper'
import operatorIllustration from '../../assets/images/illustartions/support.png'
import { Controller, useForm } from 'react-hook-form'
import { ThemeTextField } from '../../components/inputs/theme-text-field'
import { LoadingButton } from '@mui/lab'
import ThemeDialog from '../../components/dialog-box/theme-dialog'
import { stateList } from '../../utils/sample-data'
import NoDataView from '../../components/noDataView'
import LoadingView from '../../components/loading-view'


export default function Clients() {

    const theme = useTheme()
    const dispatch = useDispatch()
    const [clients, setClients] = useState<any[]>([])
    const [fetchingClients, setFetchingClients] = useState(false)
    const [baseClients, setBaseClients] = useState<any[]>([])

    const [regClientDetailType, setRegClientDetailType] = useState<'create' | 'edit'>('create')
    const [regClientDetailDialogOpen, setRegClientDialogOpen] = useState(false)
    const [regClient, setRegionalClient] = useState({} as any)


    const closeRegClientDetailDialog = () => {
        setRegClientDialogOpen(false)
        setRegClientDetailType('create')
        setRegionalClient({})
    }

    const fetchRegionalClients = useCallback(() => {
        setFetchingClients(true)
        dispatch(updateProcessState(true));

        API.get(`/get_all_clients_list`)
            .then((res) => {
                if (res.data?.statuscode == "200") {
                    setClients(res.data?.data)
                }
            })
            .catch((err) => {
                toast.success("Oops! Something bad at our end");
            })
            .finally(() => {
                setFetchingClients(false)
                dispatch(updateProcessState(false))
            });
    }, [])

    const fetchBaseClients = useCallback(() => {
        dispatch(updateProcessState(true));
        API.get(`/get_all_base_clients`)
            .then((res) => {
                if (res.data?.statuscode == "200") {
                    setBaseClients(res.data?.data)
                }
            })
            .catch((err) => {
                console.log("Oops! Something bad at our end");
            })
            .finally(() => {
                dispatch(updateProcessState(false))
            });
    }, [])


    useEffect(() => {
        fetchBaseClients()
        fetchRegionalClients()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Regional Clients'}
                secondChild={
                    <Button variant='contained'
                        className={'buttonGradient animate__animated animate__fadeInUp'}
                        onClick={() => {
                            setRegClientDialogOpen(true)
                            setRegClientDetailType('create')
                        }}
                    >
                        Add New
                    </Button>
                } />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    '& .buttonGradient': {
                        backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        outline: `0px solid ${theme.palette.primary.main}`,
                        outlineOffset: 0,
                        boxShadow: 0,
                        textTransform: 'none',
                        transition: 'all 100ms ease',
                        '&:hover': {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: '2px',
                            '&:active': {
                                transform: 'scale(0.98) !important'
                            }
                        },
                    },

                }}>

                {!fetchingClients &&
                    <ClientsTable
                        data={clients}
                        fetchingClients={fetchingClients}
                        setRegionalClient={setRegionalClient}
                        setRegClientDialogOpen={setRegClientDialogOpen}
                        setRegClientDetailType={setRegClientDetailType}
                    />
                }

                {regClientDetailDialogOpen &&
                    <ThemeDialog
                        open={regClientDetailDialogOpen}
                        dialogBody={
                            <RegClientDetailForm
                                closeDialog={closeRegClientDetailDialog}
                                type={regClientDetailType}
                                regClient={regClient}
                                fetchAssets={fetchRegionalClients}
                                baseClients={baseClients}
                            />
                        } />
                }
            </Box>
        </PageContainer>
    )
}


const ClientsTable = (props: any) => {

    const { data, fetchingClients, setRegionalClient, setRegClientDetailType, setRegClientDialogOpen } = props

    const columns: GridColDef[] = [
        {
            field: 'regional_client_name', headerName: 'Name', flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams<Date>) => (
                <Typography sx={{ fontSize: '14px', textTransform: 'capitalize' }}>{params.value}</Typography>
            )
        },
        {
            field: 'state', headerName: 'State Code', flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams<Date>) => (
                <Typography sx={{ fontSize: '14px', textTransform: 'capitalize' }}>{params.value}</Typography>
            )
        },
        {
            field: 'gst_no', headerName: 'GSTIN', flex: 1, minWidth: 120,
            renderCell: (params: GridRenderCellParams<Date>) => (
                <Typography sx={{ fontSize: '14px', textTransform: 'uppercase' }}>{camelCaseWords(params.value)}</Typography>
            )
        },
        {
            field: 'status', headerName: 'Status', flex: 1, minWidth: 130,
            renderCell: (params: GridRenderCellParams) =>
                <Chip
                    size={'small'}
                    variant='outlined'
                    label={params.row?.status == '1' ? 'Active' : 'Inactive'}
                    color={params.row?.status == '1' ? 'success' : 'error'}
                    sx={{
                        fontSize: '12px',
                        lineHeight: '12px',
                        minWidth: '60px',
                        p: '2px',
                        height: '18px',
                        textTransform: 'capitalize',
                        boxShadow: `0 0 12px -4px currentcolor inset`,
                    }} />
        },

        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            type: 'actions',
            cellClassName: 'stickyRight',
            headerClassName: 'stickyLeft',
            getActions: (params: GridRowParams<any>) => [
                <Tooltip title={`View ${params.row?.id}`} arrow>
                    <GridActionsCellItem
                        icon={<RemoveRedEyeRounded />}
                        label="View Farmer"
                        onClick={() => {
                            // navigate(`${btoa(params.row?.id)}`)
                            setRegionalClient(params.row)
                            setRegClientDialogOpen(true)
                            setRegClientDetailType('edit')
                        }}
                    />
                </Tooltip>,
            ],
        },
    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetchingClients}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}

const RegClientDetailForm = (props: any) => {

    const { baseClients, closeDialog, type, regClient, fetchAssets } = props

    const theme = useTheme()
    const dispatch = useDispatch()

    const [clientStatus, setClientStatus] = useState(regClient?.status == '0' ? 'inactive' : 'active')

    const { control, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        defaultValues: {
            base_client_id: regClient?.base_client_id ?? '',
            gst_no: regClient?.gst_no ?? '',
            state: regClient?.state ? { label: '', id: `${regClient?.state}` } : null,
            address: regClient?.address ?? '',
        }
    })

    const [submitting, setSubmitting] = useState<boolean>(false)

    const onSubmit = (data: any) => {
        dispatch(updateProcessState(true))
        setSubmitting(true)
        const newData = {
            regional_client: {
                ...data,
                gst_no: `${data.gst_no?.toUpperCase()}`,
                base_client_id: `${data.base_client_id}`,
                state: data.state?.id,
                ...(type == 'edit' ? {
                    id: `${regClient?.id}`,
                    status: clientStatus != 'active' ? '0' : '1'
                } : {})
            }
        }

        const url = type == 'create' ? '/create_regional_client' : '/update_regional_client'

        API.post(url, { ...newData })
            .then((res) => {
                if (res.data?.statuscode == '200') {
                    toast.success(`Regional Client ${type == 'edit' ? 'updated' : 'created'} successfully!`)
                    reset()
                    closeDialog()
                    fetchAssets()
                }
                if (res.data?.status == 'error') {
                    toast.error(`Error! \n ${res.data?.msg}`)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setSubmitting(false)
                dispatch(updateProcessState(false))
            })
    }

    useEffect(() => {
        if (type == 'edit') {
            setValue('base_client_id', regClient?.base_client_id, { shouldValidate: true })
            setValue('gst_no', regClient?.gst_no, { shouldValidate: true })
            setValue('state', stateList.filter((s) => s.id == `${regClient?.state}`)[0], { shouldValidate: true })
            setValue('address', regClient?.address, { shouldValidate: true })
        }
    }, [type])

    return (
        <Box
            sx={{
                minWidth: '280px',
                width: { xs: '100%', md: '70vw' },
                maxWidth: '650px',
                minHeight: '200px',
                '& .boxTitle': {
                    fontWeight: 500,
                    fontSize: '18px',
                    mb: 3,
                },
                '& .formInput': {
                    flex: '1 1 260px',
                },
            }}>

            <Typography className={'boxTitle'}>
                {type == 'edit'
                    ? 'Update Regional Client'
                    : 'Create Regional Client'
                }
            </Typography>

            <Box component={'form'}
                sx={{
                    '& .actionBox': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        mt: 3,
                        '& .MuiButton-root': {
                            textTransform: 'none',
                            minWidth: { xs: 'auto', md: '150px' },
                            flex: { xs: 1, md: 0 },
                            '&.buttonGradient': {
                                backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                outline: `0px solid ${theme.palette.primary.main}`,
                                outlineOffset: 0,
                                boxShadow: 0,
                                transition: 'all 100ms ease',
                                '&:hover': {
                                    outline: `2px solid ${theme.palette.primary.main}`,
                                    outlineOffset: '2px',
                                    '&:active': {
                                        transform: 'scale(0.98) !important'
                                    }
                                },
                            },
                        },
                    },
                }}>

                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 3,
                        mx: { xs: 0, sm: 3, md: 5 },
                    }}>

                    <Box
                        sx={{
                            flex: '1 1 300px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                            '& img': {
                                objectFit: 'contain',
                                maxHeight: '120px',
                                maxWidth: '90%',
                            },
                            '& .regClientDetail': {
                                flex: '1 1 200px',
                                display: 'flex',
                                flexFlow: 'column',
                                alignItems: 'flex-end',
                                justifyContent: 'center',
                                '& .detail': {
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: theme.typography.h1.fontFamily,
                                    color: theme.palette.text.secondary,
                                },

                            },
                        }}>
                        <img src={operatorIllustration} alt={'a'} />
                        {type == 'edit' &&
                            <Box className={'regClientDetail'}>
                                <Box
                                    sx={{
                                        boxShadow: `0 0 12px -3px #83838390`,
                                        borderRadius: '16px',
                                        p: '4px',
                                        textAlign: 'center',
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        width: '100px',
                                        mb: 1,
                                        '& .MuiInput-root': {
                                            color: theme.palette.primary.contrastText,
                                            background: clientStatus == 'active'
                                                ? `${alpha(theme.palette.success.main, 1)} !important`
                                                : `${alpha(theme.palette.error.main, 1)} !important`,
                                            borderRadius: '12px',
                                            '&:before, &:after': {
                                                display: 'none',
                                            },
                                            '& .MuiSelect-select': {
                                                borderRadius: '12px',
                                                padding: '2px 16px 4px 12px',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                fontWeight: 500,
                                            },
                                        },

                                    }}>
                                    <TextField
                                        required select variant="standard" value={clientStatus}
                                        onChange={(e) => setClientStatus(e.target.value)}
                                        size={'small'} placeholder={'Choose option'}
                                        sx={{ flex: 1 }}
                                    >
                                        {regClient?.status != '0' && <MenuItem key={'active'} value={'active'}>Active</MenuItem>}
                                        <MenuItem key={'inactive'} value={'inactive'}>In-active</MenuItem>
                                    </TextField>
                                </Box>
                            </Box>
                        }
                    </Box>
                    <Box sx={{
                        flex: '1 1 300px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        columnGap: 3,
                    }}>
                        <Controller name={`base_client_id`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.base_client_id)}
                                    disabled={type == 'edit'} select
                                    helperText={(errors?.base_client_id?.message ?? '').toString()}
                                    size={'small'} label={'Base Client'}
                                    placeholder={'eg. fmc'}
                                >
                                    {baseClients.map((client: any, index: number) => <MenuItem key={index} value={client.id} sx={{ textTransform: 'uppercase' }}>{client.client_name}</MenuItem>)}
                                </ThemeTextField>
                            )} />
                        <Controller name={`state`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete size={'small'}
                                    autoComplete
                                    disabled={type == 'edit'}
                                    className={'formItem'}
                                    onChange={(e, data) => onChange(data)}
                                    noOptionsText={'No States Found'}
                                    value={value || null}
                                    sx={{ flex: '1 1 250px' }}
                                    options={stateList}
                                    getOptionLabel={(option) => option.label ?? ''}
                                    isOptionEqualToValue={((option: any, value: any) => option.id == value.id)}
                                    renderOption={(props, option, { selected }) => (
                                        <Box component={'li'} {...props} key={props.id}
                                            sx={{
                                                p: '2px 6px',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                justifyContent: 'flex-start',
                                                flexFlow: 'column',
                                                textAlign: 'left',
                                                gap: '2px',
                                                borderRadius: '12px',
                                                mx: '2px',
                                                '& .village': {
                                                    width: '100%',
                                                    fontSize: '15px',
                                                    lineHeight: '15px',
                                                    fontWeight: 500,
                                                    color: theme.palette.text.secondary,
                                                },
                                            }}>
                                            <Typography className={'village'}>
                                                {option?.label}
                                            </Typography>
                                        </Box>

                                    )}
                                    renderInput={(params) => (
                                        <ThemeTextField
                                            {...params} required
                                            error={Boolean(errors?.state)}
                                            helperText={(errors?.state?.message ?? '').toString()}
                                            size={'small'} label={'State'}
                                            placeholder={'Please select state'}
                                        />
                                    )} />
                            )} />
                        <Controller name={`gst_no`}
                            control={control}
                            rules={{
                                required: { value: true, message: 'Required' },
                                pattern: {
                                    value: /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ][0-9a-zA-Z]{1}$/,
                                    message: 'Invalid GSTIN'
                                }
                            }}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.gst_no)}
                                    helperText={(errors?.gst_no?.message ?? '').toString()}
                                    size={'small'} label={'GSTIN'}
                                    placeholder={'****************'}
                                />
                            )} />
                        <Controller name={`address`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.address)}
                                    helperText={(errors?.address?.message ?? '').toString()}
                                    size={'small'} label={'Address'}
                                    placeholder={'eg. address here'}
                                />
                            )} />
                    </Box>
                </Box>



                <Box className={'actionBox'}>
                    <Button className={''} variant={'outlined'} onClick={closeDialog}>Discard & Close</Button>
                    {!(type == 'edit' && regClient?.status == '0') &&
                        <LoadingButton loading={submitting} endIcon={<></>} loadingPosition={'end'}
                            className={'buttonGradient'} variant={'contained'}
                            onClick={handleSubmit(onSubmit)}>
                            {type.type == 'create' ? 'Save & Create' : 'Save & Update'}
                        </LoadingButton>
                    }
                </Box>
            </Box>
        </Box >
    )
}