export const stateList = [
  { label: "Andaman and Nicobar Islands", id: "AN" },
  { label: "Andhra Pradesh", id: "AP" },
  { label: "Arunachal Pradesh", id: "AR" },
  { label: "Assam", id: "AS" },
  { label: "Bihar", id: "BR" },
  { label: "Chandigarh", id: "CH" },
  { label: "Chhattisgarh", id: "CG" },
  { label: "Daman & Diu", id: "DN" },
  { label: "Delhi", id: "DL" },
  { label: "Goa", id: "GA" },
  { label: "Gujarat", id: "GJ" },
  { label: "Haryana", id: "HR" },
  { label: "Himachal Pradesh", id: "HP" },
  { label: "Jharkhand", id: "JH" },
  { label: "Karnataka", id: "KA" },
  { label: "Kerala", id: "KL" },
  { label: "Ladakh", id: "LA" },
  { label: "Lakshadweep", id: "LD" },
  { label: "Madhya Pradesh", id: "MP" },
  { label: "Maharashtra", id: "MH" },
  { label: "Manipur", id: "MN" },
  { label: "Meghalaya", id: "ML" },
  { label: "Mizoram", id: "MZ" },
  { label: "Nagaland", id: "NL" },
  { label: "Odisha", id: "OR" },
  { label: "Puducherry", id: "PY" },
  { label: "Punjab", id: "PB" },
  { label: "Rajasthan", id: "RJ" },
  { label: "Sikkim", id: "SK" },
  { label: "Tamil Nadu", id: "TN" },
  { label: "Telangana", id: "TG" },
  { label: "Tripura", id: "TR" },
  { label: "Uttar Pradesh", id: "UP" },
  { label: "Uttarakhand", id: "UK" },
  { label: "West Bengal", id: "WB" },
];
