import { Box, CircularProgress, Typography } from "@mui/material"

const TableLoading = () => {

    return (
        <Box sx={{
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            transition: 'all 350ms ease-in-out',
            minHeight: '280px',
            '& .noDataImage': {
                maxHeight: '160px',
                objectFit: 'contain',
            },
            '& .des': {
                fontSize: '15px',
                lineHeight: '28px',

            },
        }}>

            <CircularProgress />

            <Typography className={'des'}>
                Loading please wait
            </Typography>
        </Box>
    )
}

export default TableLoading