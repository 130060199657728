import { useCallback, useEffect, useState } from "react";
import API from "../api";
import { useDispatch } from "react-redux";
import { updateProcessState } from "../slices/process-slice";

export function useProviders() {
  const dispatch = useDispatch();

  const [providers, setProviders] = useState<any[]>([]);
  const [fetchingProviders, setFetchingProviders] = useState(false);

  const fetchProviders = useCallback(async () => {
    try {
      setFetchingProviders(true);
      const response = await API.get("/get_provider_list", {
        headers: { Accept: "application/json" },
      });

      if (response.data?.status === "success") {
        setProviders(response.data?.data || []);
      } else {
        console.error(
          "Failed to fetch providers:",
          response.data?.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching providers:", error);
    } finally {
      setFetchingProviders(false);
    }
  }, []);

  return {
    fetchProviders,
    providers,
    fetchingProviders,
  };
}
