import React, { ReactNode } from 'react'
import { Box } from "@mui/material";


interface PageContainerProps {
    children: ReactNode
}

export default function PageContainer(props: PageContainerProps) {

    return (
        <Box sx={{
            px: { xs: 1, md: 3 },
            pb: 3,
            width: '100%',
            minHeight: '100%',
            overflow: 'auto',
        }}>
            {props.children}
        </Box>
    )

}