import React from 'react'
import {alpha, Box, IconButton, Tooltip, Typography, useTheme} from "@mui/material";
import {useDispatch} from "react-redux";
import {updateColorMode} from "../../slices/color-mode-slice";
import {Brightness3, Brightness7} from "@mui/icons-material";

interface ThemeColorToggleProps {
    size?: 'small' | 'large'
}

const ThemeColorToggle = (props: ThemeColorToggleProps) => {

    const {size = 'small'} = props
    const theme = useTheme()
    const dispatch = useDispatch()
    const isLight = theme.palette.mode == 'light'

    const longButtonSx = {
        width: '140px',
        height: '42px',
        borderRadius: '50vh',
        position: 'relative',
        cursor: 'pointer',
        background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
        boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
        border: `1px solid ${theme.palette.background.paper}`,
        '& p': {
            fontSize: '12px',
            lineHeight: '14px',
            textAlign: 'center',
            fontWeight: isLight ? 500 : 400,
        },
        '& .indicator': {
            position: 'absolute',
            zIndex: 1,
            height: '36px',
            width: '36px',
            borderRadius: '50vh',
            backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            left: '3px',
            transform: isLight ? 'translateX(100px) rotate(180deg)' : 'none',
            transition: 'all 400ms ease-in-out',
        },
        '& .darkMode': {
            color: `#ffde5f`,
            position: 'absolute',
            height: '30px',
            width: '30px',
            right: '5px',
            transform: isLight ? 'rotate(-135deg)' : 'rotate(135deg)',
            transition: 'all 400ms ease-in-out',
        },
        '& .lightMode': {
            color: theme.palette.primary.main,
            position: 'absolute',
            height: '30px',
            width: '30px',
            left: '5px',
            transform: isLight ? 'rotate(180deg)' : 'none',
            transition: 'all 400ms ease-in-out',
        },
    }

    const SmallButtonSx = {
        width: '40px',
        height: '40px',
        borderRadius: '50vh',
        position: 'relative',
        background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
        boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
        border: `1px solid ${theme.palette.background.paper}`,
        '& .darkMode': {
            color: `#ffde5f`,
            position: 'absolute',
            height: '22px',
            width: '22px',
            transform: isLight ? 'scale(0) rotate(300deg)' : 'scale(1) rotate(135deg)',
            transition: 'all 400ms ease-in-out',
        },
        '& .lightMode': {
            color: theme.palette.text.secondary,
            position: 'absolute',
            height: '22px',
            width: '22px',
            transform: isLight ? 'none' : 'scale(0) rotate(-180deg)',
            transition: 'all 400ms ease-in-out',
        },
    }

    const toggleTheme = () => {
        if (theme.palette.mode == 'dark')
            dispatch(updateColorMode('light'))
        else dispatch(updateColorMode('dark'))
    }

    return (
        <Tooltip title={size == 'large' ? '' : isLight ? 'Dark Mode' : 'Light Mode'} arrow placement={'bottom'}>
            <IconButton onClick={toggleTheme} sx={size == 'small' ? SmallButtonSx : longButtonSx}>
                {size == 'large' &&
                <>
                    <Box className={'indicator'}/>
                    <Typography>{isLight ? 'Light' : 'Dark'}<br/>Mode</Typography>
                </>
                }
                <Brightness3 className={'darkMode'}/>
                <Brightness7 className={'lightMode'}/>
            </IconButton>
        </Tooltip>
    )
}

export default ThemeColorToggle