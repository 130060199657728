import React from 'react'
import './App.css'
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import store from "./store/store"
import 'animate.css';
import LoadingView from "./components/loading-view"
import WebRoutes from "./web-routes/web-routes";
import NoInternetConnection from './components/no-internet-connection'


function App() {

  let persistor = persistStore(store)

  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingView />} persistor={persistor}>
        <BrowserRouter>
          <WebRoutes />
        </BrowserRouter>
      </PersistGate >
    </Provider >
  )
}

export default App
