import React from 'react'
import { Avatar, Box, Button, IconButton, lighten, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Circle, LogoutRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import useLogout from '../../hooks/useLogout';

export default function UserMenu() {

    const theme = useTheme()
    const navigate = useNavigate()
    const currentUser = useSelector((state: RootState) => state.userAuth.currentUser)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)
    const logout = useLogout()

    return (
        <>
            <IconButton onClick={handleClick}>
                <Avatar />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            background: theme.palette.mode == 'dark' ? '#222327' : lighten(theme.palette.primary.main, 0.96),
                            boxShadow: 10,
                            borderRadius: '30px !important',
                            mt: 1.5,
                            overflow: 'hidden',
                            zIndex: 991,
                            width: 'min(100%, 400px)',
                            maxHeight: 'calc(-64.9963px + 100vh)',
                            padding: 1.2,
                            '& ul': {
                                padding: 0
                            },
                        },
                    }
                }}>
                <Box sx={{
                    background: theme.palette.mode == 'dark' ? '#171717' : theme.palette.background.paper,
                    borderRadius: '20px',
                    padding: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: 2,
                    '& .MuiAvatar-root': {
                        height: '64px',
                        width: '64px',
                    },
                    '& .info': {
                        flex: 1,
                        maxWidth: '-webkit-fill-available',
                        '& .name': {
                            fontWeight: 600,
                        },
                        '& .email': {
                            fontSize: '13px'
                        },
                    },
                    '& .buttonBlock': {
                        width: '100%',
                        textAlign: 'center',
                        '& .MuiButton-root': {
                            textTransform: 'none',
                            color: theme.palette.text.primary,
                            borderColor: theme.palette.text.primary,
                        },
                    },
                }}>
                    <Avatar />
                    <Box className={'info'}>
                        <Typography
                            className={'name noTextWrap'}>{currentUser ? currentUser?.name : 'Current User'}</Typography>
                        <Typography
                            className={'email noTextWrap'}>{currentUser ? currentUser?.email : 'no email added'}</Typography>
                    </Box>

                    <Box className={'buttonBlock'} onClick={() => navigate('/my-account')}>
                        <Button variant={'outlined'}>Manage your Account</Button>
                    </Box>

                </Box>

                <MenuItem onClick={logout} sx={{
                    mt: 1,
                    borderRadius: '8px',
                }}>
                    <Box sx={{
                        display: 'flex',
                        width: '42px',
                        justifyContent: 'space-around',
                        position: 'relative',
                        '& .arrowIcon': {
                            position: 'absolute',
                            right: 0,
                            opacity: 0,
                            transition: 'all 200ms ease-in-out',
                        }
                    }}>
                        <LogoutRounded fontSize="small" />
                        <Divider orientation={'vertical'} sx={{ height: 'auto' }} />
                    </Box>
                    Logout
                </MenuItem>

                <Divider />

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 0.5,
                    '& .MuiButton-root': {
                        fontSize: '12px',
                        textTransform: 'none',
                        padding: '0 12px',
                        color: theme.palette.text.secondary,
                        '&:hover': {
                            color: theme.palette.text.primary,
                        },
                    },
                    '& svg': {
                        fontSize: '7px',
                        color: theme.palette.text.secondary,
                    },
                }}>
                    <Button size={'small'}>Privacy Policy</Button>
                    <Circle />
                    <Button size={'small'}>Terms of Service</Button>
                </Box>

            </Menu>
        </>
    )
}