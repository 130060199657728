import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { DGE } from "../models/dge";


export interface DgeDetailsInterface {
  selectedDge: DGE
}

const initialState: DgeDetailsInterface = {
  selectedDge: {} as DGE,
};

export const dgeDetailSlice = createSlice({
  name: "dgeDetail",
  initialState,
  reducers: {
    updateDgeDetail: (
      state,
      action: PayloadAction<DgeDetailsInterface>
    ) => {
      state.selectedDge = action.payload?.selectedDge;
    },
  },
});

export const { updateDgeDetail } = dgeDetailSlice.actions;

export default dgeDetailSlice.reducer;
