import Axios from "axios";
import { serverRoute } from "../utils/app-helper";

const source = Axios.CancelToken.source();

const API = Axios.create({
  baseURL: serverRoute, //"https://new.agriwings.in/api",
  timeout: 30000,
  cancelToken: source.token,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": btoa(`secretlesson`),
    Accept: "application/json",
  },
});

export default API;
