import React, { useEffect, useState } from 'react'
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
    alpha,
    useMediaQuery,
    useTheme,
} from "@mui/material"
import { RemoveRedEyeRounded } from "@mui/icons-material"
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams, GridToolbar } from "@mui/x-data-grid"
import { useDispatch } from 'react-redux'
import { LoadingButton } from '@mui/lab'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { Controller, useForm } from 'react-hook-form'

import API from "../../api"
import { ThemeDataTable } from "../../components/theme-data-table-container"
import PageContainer from '../../components/container/page-container'
import TitleBar from '../../components/container/TitleBar'
import TableLoading from '../../components/theme-table-container/TableLoading'
import NoDataView from '../../components/theme-table-container/NoData-'
import ThemeDialog from '../../components/dialog-box/theme-dialog'
import { ThemeTextField } from '../../components/inputs/theme-text-field'
import operatorIllustration from '../../assets/images/illustartions/support.png'
import { updateProcessState } from '../../slices/process-slice'
import { useRegionalClients } from '../../hooks/useRegionalClinets'
import { useProviders } from '../../hooks/useProviders'


export default function Providers() {

    const theme = useTheme()

    const { fetchProviders, fetchingProviders, providers } = useProviders()
    const { fetchRegionalClients, fetchingRegionalClients, regionalClients } = useRegionalClients()

    const [provider, setProvider] = useState({})
    const [providerDetailType, setProviderDetailType] = useState<'create' | 'edit'>('create')
    const [providerDetailDialogOpen, setProviderDetailDialogOpen] = useState(false)
    const closeProviderDetailDialog = () => {
        setProviderDetailDialogOpen(false)
        setProviderDetailType('create')
        setProvider({})
    }

    useEffect(() => {
        fetchProviders()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Providers'}
                secondChild={
                    <Button
                        variant='contained'
                        className={'buttonGradient animate__animated animate__fadeInUp'}
                        onClick={() => setProviderDetailDialogOpen(true)}>
                        Add New
                    </Button>}
            />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    '& .buttonGradient': {
                        backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        outline: `0px solid ${theme.palette.primary.main}`,
                        outlineOffset: 0,
                        boxShadow: 0,
                        textTransform: 'none',
                        transition: 'all 100ms ease',
                        '&:hover': {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: '2px',
                            '&:active': {
                                transform: 'scale(0.98) !important'
                            }
                        },
                    },

                }}>

                <ProvidersTable
                    data={providers?.length > 0 ? providers.filter((el: any) => el?.id != '1') : providers}
                    fetchingProviders={fetchingProviders}
                    setProvider={setProvider}
                    setProviderDetailDialogOpen={setProviderDetailDialogOpen}
                    setProviderDetailType={setProviderDetailType}
                    regionalClients={regionalClients}
                />
            </Box>

            {providerDetailDialogOpen &&
                <ThemeDialog
                    open={providerDetailDialogOpen}
                    dialogBody={
                        <ProviderDetailForm
                            closeDialog={closeProviderDetailDialog}
                            type={providerDetailType}
                            provider={provider}
                            fetchProviders={fetchProviders}
                            regionalClients={regionalClients}
                        />
                    } />
            }
        </PageContainer>
    )
}


const ProvidersTable = (props: any) => {

    const { data, fetchingProviders, setProvider, setProviderDetailDialogOpen, setProviderDetailType } = props

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'UNID', width: 70 },
        {
            field: 'status', headerName: 'Status', flex: 1, minWidth: 80,
            cellClassName: 'textCenter',
            headerClassName: 'textCenter',
            renderCell: (params: GridRenderCellParams) =>
                <Chip
                    size={'small'}
                    variant='outlined'
                    label={params.row?.status == '1' ? 'Active' : 'Inactive'}
                    color={params.row?.status == '1' ? 'success' : 'error'}
                    sx={{
                        fontSize: '12px',
                        lineHeight: '12px',
                        minWidth: '60px',
                        p: '2px',
                        height: '18px',
                        textTransform: 'capitalize',
                        boxShadow: `0 0 12px -4px currentcolor inset`,
                    }} />
        },
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 120 },
        { field: 'company_type', headerName: 'Type', flex: 1, minWidth: 100 },
        { field: 'contact_number', headerName: 'Mobile', flex: 1, minWidth: 160 },
        { field: 'address', headerName: 'Address', flex: 1, minWidth: 180 },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            type: 'actions',
            cellClassName: 'stickyRight',
            headerClassName: 'stickyLeft',
            getActions: (params: GridRowParams<any>) => [
                <Tooltip title={`View${params.row?.id}`} arrow>
                    <GridActionsCellItem
                        icon={<RemoveRedEyeRounded />}
                        label="View Farmer"
                        onClick={() => {
                            if (params.row?.status == '1') {
                                setProvider(params.row)
                                setProviderDetailDialogOpen(true)
                                setProviderDetailType('edit')
                            } else {
                                toast.error('Can not view inactive provider.')
                            }
                        }}
                    />
                </Tooltip>,
            ],
        },
    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetchingProviders}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            columnVisibilityModel={{
            }} slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}


const ProviderDetailForm = (props: any) => {

    const { closeDialog, type, provider, fetchProviders } = props

    const theme = useTheme()
    const dispatch = useDispatch()
    const [providerStatus, setProviderStatus] = useState<string>('active')

    const { control, handleSubmit, watch, setValue, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: provider?.name ?? '',
            company_type: provider?.company_type ?? '',
            contact_number: provider?.contact_number ?? '',
            address: provider?.address ?? '',
        }
    })

    const [submitting, setSubmitting] = useState<boolean>(false)

    const onSubmit = (data: any) => {
        dispatch(updateProcessState(true))
        setSubmitting(true)
        if (type == 'create') {
            API.post('/submit_providers', {
                ...data,
            })
                .then((res) => {
                    if (res.data?.status == 'success') {
                        toast.success('Provider created successfully!')
                        reset()
                        closeDialog()
                        fetchProviders()
                    }
                    if (res.data?.status == 'error') {
                        if ("email" in res.data?.msg) {
                            toast.error(`Error! \n Email already exists.`)
                        } else {
                            toast.error(`Error! \n Something went wrong.`)
                        }
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    setSubmitting(false)
                    dispatch(updateProcessState(false))
                })
        } else if (type == 'edit') {
            const newData = {
                id: provider?.id,
                contact_number: data?.contact_number,
                address: data?.address,
                status: (providerStatus == 'active') ? '1' : '0',
            }
            API.post('/update_provider', { ...newData })
                .then((res) => {
                    if (res.data?.status == 'success') {
                        toast.success('Provider updated successfully!')
                        reset()
                        closeDialog()
                        fetchProviders()
                    }
                    if (res.data?.status == 'error') {
                        toast.error(`Error! \n ${res.data?.msg}`)
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    setSubmitting(false)
                    dispatch(updateProcessState(false))
                })
        }
    }

    useEffect(() => {
        provider &&
            setProviderStatus(provider?.status == '1' ? 'active' : 'inactive')
    }, [provider])

    return (
        <Box
            sx={{
                minWidth: '280px',
                width: { xs: '100%', md: '70vw' },
                maxWidth: '650px',
                minHeight: '200px',
                '& .boxTitle': {
                    fontWeight: 500,
                    fontSize: '18px',
                    mb: 3,
                },
                '& .formInput': {
                    flex: '1 1 260px',
                    '&.sm': {
                        flex: '1 1 140px',
                    },
                },
            }}>
            <Typography className={'boxTitle'}>
                {type == 'edit'
                    ? 'Update Provider'
                    : 'Create Provider'
                }
            </Typography>

            <Box component={'form'}
                sx={{
                    '& .actionBox': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        mt: 3,
                        '& .MuiButton-root': {
                            textTransform: 'none',
                            minWidth: { xs: 'auto', md: '150px' },
                            flex: { xs: 1, md: 0 },
                            '&.buttonGradient': {
                                backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                outline: `0px solid ${theme.palette.primary.main}`,
                                outlineOffset: 0,
                                boxShadow: 0,
                                transition: 'all 100ms ease',
                                '&:hover': {
                                    outline: `2px solid ${theme.palette.primary.main}`,
                                    outlineOffset: '2px',
                                    '&:active': {
                                        transform: 'scale(0.98) !important'
                                    }
                                },
                            },
                        },
                    },
                }}>

                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 3,
                        mx: { xs: 0, sm: 3, md: 5 },
                    }}>
                    <Box
                        sx={{
                            flex: '1 1 300px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                            '& img': {
                                objectFit: 'contain',
                                maxHeight: '120px',
                                maxWidth: '90%',
                            },
                            '& .assetDetail': {
                                flex: '1 1 200px',
                                display: 'flex',
                                flexFlow: 'column',
                                alignItems: 'flex-end',
                                justifyContent: 'center',
                                '& .detail': {
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: theme.typography.h1.fontFamily,
                                    color: theme.palette.text.secondary,
                                },

                            },
                        }}>
                        <img src={operatorIllustration} alt={'provider Illustration'} />
                        {type == 'edit' &&
                            <Box className={'assetDetail'}>
                                <Box
                                    sx={{
                                        boxShadow: `0 0 12px -3px #83838390`,
                                        borderRadius: '16px',
                                        p: '4px',
                                        textAlign: 'center',
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        width: '100px',
                                        mb: 1,
                                        '& .MuiInput-root': {
                                            color: theme.palette.primary.contrastText,
                                            background: providerStatus == 'active'
                                                ? `${alpha(theme.palette.success.main, 1)} !important`
                                                : `${alpha(theme.palette.error.main, 1)} !important`,
                                            borderRadius: '12px',
                                            '&:before, &:after': {
                                                display: 'none',
                                            },
                                            '& .MuiSelect-select': {
                                                borderRadius: '12px',
                                                padding: '2px 16px 4px 12px',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                fontWeight: 500,
                                            },
                                        },

                                    }}>
                                    <TextField
                                        required select variant="standard" value={providerStatus}
                                        onChange={(e) => setProviderStatus(e.target.value)}
                                        size={'small'} placeholder={'Choose option'}
                                        sx={{ flex: 1 }}
                                    >
                                        <MenuItem key={'active'} value={'active'}>Active</MenuItem>
                                        <MenuItem key={'inactive'} value={'inactive'}>In-active</MenuItem>
                                    </TextField>
                                </Box>

                            </Box>
                        }
                    </Box>
                    <Box
                        sx={{
                            flex: '1 1 300px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            columnGap: 3,
                        }}>
                        <Controller name={`name`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field}
                                    disabled={type == 'edit'}
                                    required sx={{ width: '100%' }}
                                    error={Boolean(errors?.name)}
                                    helperText={(errors?.name?.message ?? '').toString()}
                                    size={'small'} label={'Name'}
                                    placeholder={'eg Provider Name'}
                                />
                            )} />
                        <Controller name={`company_type`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            defaultValue={''}
                            render={({ field: { value, onChange } }) => (
                                <ThemeTextField
                                    value={value}
                                    onChange={(e) => {
                                        setValue('company_type', e.target.value, { shouldValidate: true })
                                    }}
                                    disabled={type == 'edit'}
                                    required className={'formInput'}
                                    error={Boolean(errors?.company_type)}
                                    helperText={(errors?.company_type?.message ?? '').toString()}
                                    size={'small'} label={'Company Type'}
                                    placeholder={'select type'} select
                                >
                                    <MenuItem value={'AgriWings'}>AgriWings</MenuItem>
                                    <MenuItem value={'Network Partner'}>Network Partner</MenuItem>
                                    <MenuItem value={'VLE'}>VLE</MenuItem>
                                </ThemeTextField>
                            )} />

                        <Controller name={`contact_number`}
                            control={control}
                            rules={{
                                required: { value: true, message: 'Required' },
                                pattern: { value: /^[789]\d{9}$/, message: 'Invalid mobile number' },
                            }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.contact_number)}
                                    helperText={(errors?.contact_number?.message ?? '').toString()}
                                    size={'small'} label={'Contact Number'}
                                    placeholder={'XXXX XXX XXX'}
                                />
                            )} />
                        <Controller name={`address`}
                            control={control}
                            rules={{
                                required: { value: true, message: 'Required' },
                            }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field}
                                    required sx={{ width: '100%' }}
                                    error={Boolean(errors?.address)} multiline rows={3}
                                    helperText={(errors?.address?.message ?? '').toString()}
                                    size={'small'} label={'Address'}
                                    placeholder={'address here'}
                                />
                            )} />

                    </Box>
                </Box>



                <Box className={'actionBox'}>
                    <Button className={''} variant={'outlined'} onClick={closeDialog}>Discard & Close</Button>
                    <LoadingButton loading={submitting} endIcon={<></>} loadingPosition={'end'}
                        className={'buttonGradient'} variant={'contained'}
                        onClick={handleSubmit(onSubmit)}>
                        {type.type == 'create' ? 'Save & Create' : 'Save & Update'}
                    </LoadingButton>
                </Box>
            </Box>
        </Box>
    )
}