import React, { useCallback, useEffect, useState } from 'react'
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    FormControlLabel,
    InputAdornment,
    InputLabel,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
    alpha,
    useMediaQuery,
    useTheme,
} from "@mui/material"
import { RemoveRedEyeRounded } from "@mui/icons-material"
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams, GridToolbar } from "@mui/x-data-grid"

import { ThemeDataTable } from "../../../components/theme-data-table-container"
import API from "../../../api"
import PageContainer from '../../../components/container/page-container'
import TitleBar from '../../../components/container/TitleBar'
import TableLoading from '../../../components/theme-table-container/TableLoading'
import NoDataView from '../../../components/theme-table-container/NoData-'
import { useNavigate } from 'react-router'
import ThemeDialog from '../../../components/dialog-box/theme-dialog'
import { Controller, useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { ThemeTextField, themeTextFieldSx } from '../../../components/inputs/theme-text-field'
import operatorIllustration from '../../../assets/images/illustartions/support.png'
import { useDispatch, useSelector } from 'react-redux'
import { updateProcessState } from '../../../slices/process-slice'
import toast from 'react-hot-toast'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { User } from '../../../models/user'
import { RootState } from '../../../store/store'


export default function Vehicles() {

    const theme = useTheme()
    const [vehicles, setVehicles] = useState<any[]>([])
    const [fetchingVehicles, setFetchingVehicles] = useState(false)

    const [vehicle, setVehicle] = useState({})
    const [vehicleDetailType, setVehicleDetailType] = useState<'create' | 'edit'>('create')
    const [vehicleDetailDialogOpen, setVehicleDetailDialogOpen] = useState(false)
    const closeVehicleDetailDialog = () => {
        setVehicleDetailDialogOpen(false)
        setVehicleDetailType('create')
        setVehicle({})
    }


    const fetchVehicles = useCallback(() => {
        setFetchingVehicles(true)
        API.get('/fetch_vehicle_list', { headers: { "Accept": "application/json" } })
            .then((res) => {
                res.data.status == 'success' &&
                    res.data?.data && setVehicles(res.data?.data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => setFetchingVehicles(false))
    }, [])

    useEffect(() => {
        fetchVehicles()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Vehicles'} secondChild={<CreateOptions
                closeDialog={closeVehicleDetailDialog}
                fetchVehicles={fetchVehicles}
                setVehicleDetailDialogOpen={setVehicleDetailDialogOpen} />} />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    '& .buttonGradient': {
                        backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        outline: `0px solid ${theme.palette.primary.main}`,
                        outlineOffset: 0,
                        boxShadow: 0,
                        textTransform: 'none',
                        transition: 'all 100ms ease',
                        '&:hover': {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: '2px',
                            '&:active': {
                                transform: 'scale(0.98) !important'
                            }
                        },
                    },

                }}>

                <VehiclesTable
                    data={vehicles}
                    fetchingVehicles={fetchingVehicles}
                    setVehicle={setVehicle}
                    setVehicleDetailDialogOpen={setVehicleDetailDialogOpen}
                    setVehicleDetailType={setVehicleDetailType}
                />

            </Box>

            {vehicleDetailDialogOpen &&
                <ThemeDialog
                    title={vehicleDetailType == 'edit' ? 'Update Vehicle' : 'Create Vehcile'}
                    open={vehicleDetailDialogOpen}
                    dialogBody={
                        <AssetDetailForm
                            closeDialog={closeVehicleDetailDialog}
                            type={vehicleDetailType}
                            vehicle={vehicle}
                            fetchVehicles={fetchVehicles}
                        />
                    } />
            }
        </PageContainer>
    )
}


const VehiclesTable = (props: any) => {

    const navigate = useNavigate()
    const { data, fetchingVehicles, setVehicle, setVehicleDetailDialogOpen, setVehicleDetailType } = props
    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser

    const columns: GridColDef[] = [
        {
            field: 'registration_no', headerName: 'Registration No', width: 150,
            renderCell: (params: GridRenderCellParams) =>
                <Chip
                    size={'small'}
                    variant='outlined'
                    label={params.row?.registration_no}
                    color={params.row?.status == '1' ? 'success' : 'error'}
                    sx={{
                        fontSize: '12px',
                        lineHeight: '12px',
                        minWidth: '90px',
                        p: '2px',
                        height: '18px',
                        textTransform: 'uppercase',
                        boxShadow: `0 0 12px -4px currentcolor inset`,
                    }} />
        },
        { field: 'type', headerName: 'Type', flex: 1, minWidth: 120 },
        { field: 'manufacturer', headerName: 'Manufacturer', flex: 1, minWidth: 160 },
        { field: 'year_of_make', headerName: 'Make Year', flex: 1, minWidth: 100 },
        { field: 'chassis_no', headerName: 'Chassis No', flex: 1, minWidth: 200 },
        { field: 'engine_no', headerName: 'Engine No', flex: 1, minWidth: 140 },
        { field: 'owned_by', headerName: 'Owned By', flex: 1, minWidth: 140 },
        { field: 'owned_by_name', headerName: 'Provider Name', flex: 1, minWidth: 140 },
        {
            field: 'assigned_operator', headerName: 'Operator', flex: 1, minWidth: 140,
            renderCell: (params: GridRenderCellParams) => (
                params.row?.asset_operator_detail?.name ?? '-'
            )

        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            type: 'actions',
            cellClassName: 'stickyRight',
            headerClassName: 'stickyLeft',
            getActions: (params: GridRowParams<any>) => [
                <Tooltip title={`View${params?.row}`} arrow>
                    <GridActionsCellItem
                        icon={<RemoveRedEyeRounded />}
                        label="View Farmer"
                        onClick={() => {
                            if (currentUser?.provider_id != params.row?.owned_by_id) {
                                toast.error(`Sorry, Not owned by you.`)
                            } else {
                                setVehicle(params?.row)
                                setVehicleDetailDialogOpen(true)
                                setVehicleDetailType('edit')
                            }
                        }}
                    />
                </Tooltip>,
            ],
        },
    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetchingVehicles}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}

const CreateOptions = (props: any) => {

    const { closeDialog, fetchVehicles, setVehicleDetailDialogOpen } = props

    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <Button
                id="basic-button"
                variant='contained'
                className={'buttonGradient animate__animated animate__fadeInUp'}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                Add New
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    setVehicleDetailDialogOpen(true)
                    handleClose()
                }}>Create</MenuItem>
                <MenuItem onClick={handleClose}>Import</MenuItem>
            </Menu>
        </div >
    )
}


const ImportFormDialog = () => {

    return (
        <Box>
        </Box>
    )
}


const AssetDetailForm = (props: any) => {

    const { closeDialog, type, vehicle, fetchVehicles } = props

    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [vehicleStatus, setVehicleStatus] = useState<string>('active')


    const { control, handleSubmit, setError, setValue, clearErrors, register, reset, formState: { errors } } = useForm({
        defaultValues: {
            owned_by: vehicle?.owned_by ?? 'D2F Services Pvt Ltd',
            type: vehicle?.type ?? '',
            year_of_make: vehicle?.year_of_make ?? '',
            manufacturer: vehicle?.manufacturer ?? '',
            engine_no: vehicle?.engine_no ?? '',
            chassis_no: vehicle?.chassis_no ?? '',
            registration_no: vehicle?.registration_no ?? '',
        }
    })

    const [submitting, setSubmitting] = useState<boolean>(false)


    const removeVehicle = () => {
        API.post('/delete_vehicle', { id: vehicle?.id })
            .then((res) => {
                if (res.data?.statuscode == '200') {
                    toast.success('Vehicle removed successfully!')
                    reset()
                    closeDialog()
                    fetchVehicles()
                }
                if (res.data?.status == 'error') {
                    toast.error(`Error! \n ${res.data?.msg}`)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setSubmitting(false)
                dispatch(updateProcessState(false))
            })
    }

    const onSubmit = (data: any) => {
        dispatch(updateProcessState(true))
        setSubmitting(true)
        if (vehicleStatus == 'inactive') {
            removeVehicle()
        } else {
            if (type == 'create') {
                API.post('/submit_vehicle_details', { ...data, year_of_make: moment(data?.year_of_make).format('YYYY') })
                    .then((res) => {
                        if (res.data?.status == 'success') {
                            reset()
                            toast.success('Vehicle created successfully!')
                            closeDialog()
                            fetchVehicles()
                        }
                        if (res.data?.status == 'error') {
                            toast.error(`Error! \n ${res.data?.msg}`)
                        }
                    })
                    .catch((err) => console.log(err))
                    .finally(() => {
                        setSubmitting(false)
                        dispatch(updateProcessState(false))
                    })
            } else if (type == 'edit') {
                API.post('/edit_vehicle_details', { ...data, year_of_make: moment(data?.year_of_make).format('YYYY'), id: vehicle?.id })
                    .then((res) => {
                        if (res.data?.status == 'success') {
                            reset()
                            toast.success('Vehicle updated successfully!')
                            closeDialog()
                            fetchVehicles()
                        }
                        if (res.data?.status == 'error') {
                            toast.error(`Error! \n ${res.data?.msg}`)
                        }
                    })
                    .catch((err) => console.log(err))
                    .finally(() => {
                        setSubmitting(false)
                        dispatch(updateProcessState(false))
                    })
            }
        }


    }


    useEffect(() => {
        console.log('ss', vehicle)
    }, [])

    return (
        <Box
            sx={{
                minWidth: '280px',
                width: { xs: '100%', md: '70vw' },
                maxWidth: '650px',
                minHeight: '200px',
                '& .boxTitle': {
                    fontWeight: 500,
                    fontSize: '18px',
                    mb: 3,
                },
                '& .formInput': {
                    flex: '1 1 260px',
                },
            }}>

            <Box component={'form'}
                sx={{
                    '& .actionBox': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        mt: 3,
                        '& .MuiButton-root': {
                            textTransform: 'none',
                            minWidth: { xs: 'auto', md: '150px' },
                            flex: { xs: 1, md: 0 },
                            '&.buttonGradient': {
                                backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                outline: `0px solid ${theme.palette.primary.main}`,
                                outlineOffset: 0,
                                boxShadow: 0,
                                transition: 'all 100ms ease',
                                '&:hover': {
                                    outline: `2px solid ${theme.palette.primary.main}`,
                                    outlineOffset: '2px',
                                    '&:active': {
                                        transform: 'scale(0.98) !important'
                                    }
                                },
                            },
                        },
                    },
                }}>



                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 3,
                    mx: { xs: 0, sm: 3, md: 5 },
                }}>

                    <Box sx={{
                        flex: '1 1 300px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        '& img': {
                            objectFit: 'contain',
                            maxHeight: '120px',
                            maxWidth: '90%',
                        },
                        '& .vehicleDetail': {
                            flex: '1 1 200px',
                            display: 'flex',
                            flexFlow: 'column',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            '& .detail': {
                                fontSize: "14px",
                                fontWeight: 500,
                                fontFamily: theme.typography.h1.fontFamily,
                                color: theme.palette.text.secondary,
                            },

                        },
                    }}>
                        <img src={operatorIllustration} alt={'a'} />
                        {type == 'edit' &&
                            <Box className={'vehicleDetail'}>
                                <Box sx={{
                                    boxShadow: `0 0 12px -3px #83838390`,
                                    borderRadius: '16px',
                                    p: '4px',
                                    textAlign: 'center',
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    width: '100px',
                                    mb: 1,
                                    '& .MuiInput-root': {
                                        color: theme.palette.primary.contrastText,
                                        background: vehicleStatus == 'active'
                                            ? `${alpha(theme.palette.success.main, 1)} !important`
                                            : `${alpha(theme.palette.error.main, 1)} !important`,
                                        borderRadius: '12px',
                                        '&:before, &:after': {
                                            display: 'none',
                                        },
                                        '& .MuiSelect-select': {
                                            borderRadius: '12px',
                                            padding: '2px 16px 4px 12px',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            fontWeight: 500,
                                        },
                                    },

                                }}>
                                    <TextField
                                        required select variant="standard" value={vehicleStatus}
                                        onChange={(e) => setVehicleStatus(e.target.value)}
                                        size={'small'} placeholder={'Choose option'}
                                        sx={{ flex: 1 }}
                                    >
                                        <MenuItem key={'active'} value={'active'}>Active</MenuItem>
                                        <MenuItem key={'inactive'} value={'inactive'}>In-active</MenuItem>
                                    </TextField>
                                </Box>
                                {/* <Typography className={'detail'}>Code : <span>gfsh21323</span></Typography>
                                <Typography className={'detail'}>UIN : <span>123467856</span></Typography> */}
                            </Box>
                        }
                    </Box>
                    <Box sx={{
                        flex: '1 1 300px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        columnGap: 3,
                    }}>
                        <Controller name={`owned_by`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}

                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required
                                    sx={{ width: '100%' }}
                                    error={Boolean(errors?.owned_by)}
                                    helperText={(errors?.owned_by?.message ?? '').toString()}
                                    size={'small'} label={'Owned By'}
                                    placeholder={'eg. Forntier Agrotech'}
                                />
                            )} />

                        <Controller name={`type`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.type)} select
                                    helperText={(errors?.type?.message ?? '').toString()}
                                    size={'small'} label={'Type'}
                                    placeholder={'Enter type'}
                                >
                                    <MenuItem value={'2-wheeler'}>2-Wheeler</MenuItem>
                                    <MenuItem value={'3-wheeler'}>3-Wheeler</MenuItem>
                                    <MenuItem value={'4-wheeler'}>4-Wheeler</MenuItem>
                                </ThemeTextField>
                            )} />
                        <Controller
                            name="year_of_make"
                            control={control}
                            defaultValue={null}
                            rules={{
                                required: { value: true, message: 'Required' },
                            }}
                            render={({ field }) => (
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <MobileDatePicker
                                        {...field}
                                        disableFuture
                                        label="Make Year"
                                        className={'formInput'}
                                        openTo="year"
                                        views={['year']}
                                        format="YYYY"
                                        value={field.value ? moment(field.value) : null}
                                        onChange={(date) => {
                                            setValue('year_of_make', date);
                                            clearErrors('year_of_make')
                                        }}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                sx: themeTextFieldSx,
                                                style: { width: '100%', marginBottom: '24px !important' },
                                                error: Boolean(errors?.year_of_make),
                                                helperText: errors?.year_of_make?.message?.toString() ?? '',
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                        <Controller name={`manufacturer`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.manufacturer)}
                                    helperText={(errors?.manufacturer?.message ?? '').toString()}
                                    size={'small'} label={'Manufacturer'}
                                    placeholder={'eg. 12'}
                                />
                            )} />
                        <Controller name={`engine_no`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.engine_no)}
                                    helperText={(errors?.engine_no?.message ?? '').toString()}
                                    size={'small'} label={'Engine Number'}
                                    placeholder={'eg. 124324SDFDFD'}
                                />
                            )} />
                        <Controller name={`chassis_no`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.chassis_no)}
                                    helperText={(errors?.chassis_no?.message ?? '').toString()}
                                    size={'small'} label={'Chassis Number'}
                                    placeholder={'eg. 1FDFD234567GGHJKJ2'}
                                />
                            )} />
                        <Controller name={`registration_no`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    disabled={type == 'edit'}
                                    error={Boolean(errors?.registration_no)}
                                    helperText={(errors?.registration_no?.message ?? '').toString()}
                                    size={'small'} label={'Registration Number'}
                                    placeholder={'eg. HR20AJ7830'}
                                />
                            )} />

                        {type == 'edit' &&
                            <Typography sx={{
                                width: '100%',
                                fontSize: '14px',
                                fontWeight: 500,
                            }}>
                                Assigned to: {vehicle?.asset_operator_detail ? vehicle?.asset_operator_detail?.name : 'Not Assigned'}
                            </Typography>
                        }
                    </Box>
                </Box>



                <Box className={'actionBox'}>
                    <Button className={''} variant={'outlined'} onClick={closeDialog}>Discard & Close</Button>
                    <LoadingButton loading={submitting} endIcon={<></>} loadingPosition={'end'}
                        className={'buttonGradient'} variant={'contained'}
                        onClick={handleSubmit(onSubmit)}>
                        {type.type == 'create' ? 'Save & Create' : 'Save & Update'}
                    </LoadingButton>
                </Box>
            </Box>
        </Box>
    )
}