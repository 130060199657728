import {
    AppBar,
    Autocomplete,
    Box,
    Button,
    InputAdornment,
    Paper,
    Toolbar,
    Typography,
    alpha,
    createFilterOptions,
    useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Controller, useForm } from "react-hook-form";
import { ThemeTextField } from "../../components/inputs/theme-text-field";
import API from "../../api";
import toast from "react-hot-toast";
import { SearchRounded } from "@mui/icons-material";
import TitleBar from "../../components/container/TitleBar";
import { useDispatch, useSelector } from "react-redux";
import { updateProcessState } from "../../slices/process-slice";
import { useAllCrops } from "../../hooks/useAllCrops";
import { camelCaseWords } from "../../utils/app-helper";
import { RootState } from "../../store/store";
import LoadingView from "../../components/loading-view";
import logo from "../../assets/images/app-icons/agri-wing-logo.svg";
import framerIllusion from '../../assets/images/illustartions/farmer.png'
import ThemeColorToggle from "../../components/theme-color-toggle";


function FarmerRegistration() {

    const theme = useTheme()
    const location = useLocation()

    const processState = useSelector((state: RootState) => state.processState.working)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])


    window.onbeforeunload = function () {
        if (processState) return "Changes made may not reflect"
    }

    return (
        <Box data-owner={'Amit Thakur'}
            component={'main'}
            className={'thakuramit962'} sx={{
                flexGrow: 1,
                display: 'flex',
                flexFlow: 'column',
                // height: '100vh',
                px: { xs: 0, md: '0.5rem' },
                transition: 'all 300ms ease-in-out',
                '& .contentArea': {
                    flex: 1,
                    maxWidth: '100%',
                    overflow: 'auto',
                    borderRadius: { xs: '12px 12px 24px 24px', md: `20px` },
                    background: theme.palette.background.paper,
                    boxShadow: 0,
                    // boxShadow: `0 0 12px -3px ${theme.palette.mode == 'dark' ? '#83838380' : '#69696980'} inset`,
                    p: { xs: 0, md: 1 },
                    display: 'flex',
                    flexFlow: 'column',
                    alignItems: 'stretch',
                },
            }}>
            <Topbar />
            <Toolbar />
            <Paper className={'contentArea'}>
                <Box id={'scrollContainer'}
                    sx={{
                        overflow: 'auto',
                    }}>
                    <Box
                        component={'section'}
                        sx={{
                            flex: 1,
                            px: { xs: 2, md: 3 },
                            pb: 3, pt: 1,
                            '& .pageTitle': {
                                fontSize: '1.2rem',
                                fontWeight: 600,
                                position: 'relative',
                                background: theme.palette.mode == 'dark' ? theme.palette.primary.main : `-webkit-linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                width: 'max-content',
                            },
                        }}>
                        <FarmerRegistrationForm />
                    </Box>
                </Box>
            </Paper>
            <Toolbar />
            {processState && <LoadingView />}
        </Box>
    )
}

export const Topbar = () => {
    const theme = useTheme()
    return (
        <AppBar
            position="fixed"
            sx={{
                zIndex: theme.zIndex.drawer + 1,
                color: theme.palette.text.primary,
                background: theme.palette.background.paper,
                boxShadow: 0,
            }}>
            <Toolbar>
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& .logoImage': {
                        maxHeight: '40px',
                        objectFit: 'contain',
                    },
                }}>
                    <img className={'logoImage'} src={theme.palette.mode == 'dark' ? logo : logo} alt={'hey-there-logo'} />
                </Box>
            </Toolbar>
        </AppBar>
    )
}


function FarmerRegistrationForm() {
    const theme = useTheme();
    const naviagate = useNavigate();
    const dispatch = useDispatch();

    const { allCrops, fetchAllCrops, fetchingAllCrops } = useAllCrops()

    const [searchedSubDistrict, setSearchedSubDistrict] = useState("");
    const [subDistricts, setSubDistricts] = useState<any[]>([]);
    const [fetchingSubDistricts, setFetchingSubDistricts] = useState(false);

    const [submitted, setSubmitted] = useState(false);

    const { control, watch, setValue, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            phone: '',
            acreage: '',
            crop: '',
            address: "",
            location: null,
            village: "",
            sub_district: "",
            district: "",
            state: "",
            pin: "",
        },
    });
    const inputData = watch();


    const fetchSubDistrict = useCallback(
        (subdistrict_name: string) => {
            setFetchingSubDistricts(true);
            API.get(`/fetch-villages`, { params: { village_name: subdistrict_name } })
                .then((res) => {
                    if (res.data?.statuscode === "200") {
                        setSubDistricts(res.data?.village_data);
                    } else {
                        setSubDistricts([]);
                    }
                })
                .catch((err) => {
                    toast.success("Oops! Something bad at our end");
                })
                .finally(() => {
                    setFetchingSubDistricts(false);
                });
        },
        [searchedSubDistrict]
    );

    const filterOptions = createFilterOptions({
        matchFrom: "any",
        stringify: (option: any) =>
            `${option?.subdistrict_name}${option?.vil_town_name}`,
    });

    const onSubmit = (data: any) => {
        const newData = {
            name: data?.name,
            phone: data?.phone,
            acreage: data?.acreage,
            crop: data?.crop?.map((cropItem: { crop_name: string }, index: number) => `${cropItem?.crop_name}`).join(', '),
            address: data?.address,
            village: data?.village,
            sub_district: data?.sub_district,
            district: data?.district,
            state: data?.state,
            pin: data?.pin,
        };

        dispatch(updateProcessState(true));
        API.post("/create-farmer", { ...newData })
            .then((res) => {
                if (res.data?.status == "success") {
                    // toast.success(`Registration Successfull`);
                    setSubmitted(true)
                } else {
                    toast.error(`${res.data?.message}`);
                }
            })
            .catch((err) => console.error(err))
            .finally(() => {
                dispatch(updateProcessState(false));
            });
    };

    const resetPage = () => {
        reset()
        setSubmitted(false)
    }

    useEffect(() => {
        fetchAllCrops()
    }, []);

    return (
        <Box
            sx={{
                mb: 5,
                mx: 'auto',
                maxWidth: '400px',
            }}
        >
            <TitleBar title={submitted ? '' : "Visiters Form"} secondChild={<ThemeColorToggle />} />
            {!submitted
                ? <>
                    <Box sx={{
                        flex: { xs: '1 1 100%', sm: '1 1 40%' },
                        display: 'grid',
                        placeItems: 'center',
                        order: { xs: -1, sm: 2 },
                        '& img': {
                            maxWidth: '240px',
                            aspectRatio: '1/1',
                            objectFit: 'contain',
                        }
                    }}>
                        <img src={submitted ? framerIllusion : framerIllusion} alt={'framer illustion'} className={'animate__animated animate__fadeIn'} />
                    </Box>

                    <Controller
                        name={`name`}
                        control={control}
                        rules={{ required: { value: true, message: "" } }}
                        defaultValue={""}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field}
                                required
                                className={'animate__animated animate__fadeInUp'}
                                error={Boolean(errors?.name)}
                                helperText={(
                                    errors?.name?.message ?? ""
                                ).toString()}
                                size={"small"}
                                label={"Name"}
                                sx={{ flex: 1, minWidth: "200px" }}
                                placeholder={"farmer name"}
                            />
                        )}
                    />

                    <Controller
                        name={`phone`}
                        control={control}
                        rules={{
                            required: { value: true, message: "" },
                            pattern: {
                                value: /^[6789]\d{9}$/,
                                message: "Invalid mobile number",
                            },
                        }}
                        defaultValue={""}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field}
                                required
                                className={'animate__animated animate__fadeInUp'}
                                type={"tel"}
                                error={Boolean(errors?.phone)}
                                helperText={(
                                    errors?.phone?.message ?? ""
                                ).toString()}
                                size={"small"}
                                label={"Phone"}
                                sx={{ flex: 1, minWidth: "100px" }}
                                placeholder={"XXXXXXXXXX"}
                            />
                        )}
                    />

                    <Controller
                        name={`address`}
                        control={control}
                        rules={{ required: { value: true, message: "" } }}
                        defaultValue={""}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field}
                                required
                                className={'animate__animated animate__fadeInUp'}
                                error={Boolean(errors?.address)}
                                helperText={(
                                    errors?.address?.message ?? ""
                                ).toString()}
                                size={"small"}
                                label={"Address"}
                                sx={{ flex: 1, minWidth: "200px" }}
                                placeholder={"Address line"}
                            />
                        )}
                    />

                    <Controller
                        name={`location`}
                        control={control}
                        rules={{ required: { value: true, message: "Required" } }}
                        defaultValue={null}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                size={"small"}
                                autoComplete
                                className={'animate__animated animate__fadeInUp'}
                                onClose={() => {
                                    setSearchedSubDistrict("");
                                    setSubDistricts([]);
                                }}
                                onInputChange={(e, inputvalue) => {
                                    setSearchedSubDistrict(inputvalue);
                                    inputvalue?.length <= 3 && setSubDistricts([]);
                                }}
                                onChange={(e, data) => {
                                    onChange(data);
                                    setValue("village", `${data?.vil_town_name ?? ""}`, { shouldDirty: true, shouldValidate: true, });
                                    setValue("sub_district", `${data?.subdistrict_name ?? ""}`, { shouldDirty: true, shouldValidate: true, });
                                    setValue("district", `${data?.district_name ?? ""}`, { shouldDirty: true, shouldValidate: true, });
                                    setValue("state", `${data?.state_name ?? ""}`, { shouldDirty: true, shouldValidate: true, });
                                }}
                                filterOptions={filterOptions}
                                noOptionsText={
                                    subDistricts.length > 0
                                        ? "No Village Found"
                                        : "Search with atleast 3 characters of sub district"
                                }
                                value={value || null}
                                loading={fetchingSubDistricts}
                                sx={{
                                    flex: "1 1 250px",
                                    "& .MuiOutlinedInput-root": {
                                        paddingRight: "0.15rem !important",
                                    },
                                }}
                                options={subDistricts}
                                isOptionEqualToValue={((option: any, value: any) => option.subdistrict_name == value.subdistrict_name)}
                                renderOption={(props, option, { selected }) => (
                                    <Box
                                        component={"li"}
                                        {...props}
                                        key={props.id}
                                        sx={{
                                            p: "2px 6px",
                                            display: "flex",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            flexFlow: "column",
                                            textAlign: "left",
                                            gap: "2px",
                                            borderRadius: "12px",
                                            mx: "2px",
                                            "& .village": {
                                                width: "100%",
                                                fontSize: "15px",
                                                lineHeight: "15px",
                                                fontWeight: 500,
                                                color: theme.palette.text.secondary,
                                            },
                                            "& .state": {
                                                width: "100%",
                                                fontSize: "12px",
                                                lineHeight: "12px",
                                                fontWeight: 500,
                                                color: theme.palette.text.disabled,
                                            },
                                        }}
                                    >
                                        <Typography className={"village"}>
                                            {option?.vil_town_name}, {option?.subdistrict_name}
                                        </Typography>
                                        <Typography className={"state"}>
                                            {option?.district_name}, {option?.state_name}
                                        </Typography>
                                    </Box>
                                )}
                                getOptionLabel={(option) => option.subdistrict_name ?? ""}
                                renderInput={(params) => (
                                    <ThemeTextField
                                        {...params}
                                        required
                                        error={Boolean(errors?.location)}
                                        helperText={(errors?.location?.message ??
                                            inputData?.sub_district
                                            ? `${inputData?.village}, ${inputData?.sub_district}, ${inputData?.district}, ${inputData?.state}`
                                            : ""
                                        ).toString()}
                                        size={"small"}
                                        label={"Location"}
                                        placeholder={"Sub-district name"}
                                        InputProps={{
                                            ...params.InputProps,
                                            type: "search",
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button
                                                        size="small"
                                                        sx={{
                                                            boxShadow: `0 0 12px -3px inset ${theme.palette.primary.main}`,
                                                            color: theme.palette.primary.main,
                                                        }}
                                                    >
                                                        <SearchRounded
                                                            onClick={() =>
                                                                searchedSubDistrict?.length >= 3 &&
                                                                fetchSubDistrict(searchedSubDistrict)
                                                            }
                                                        />
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        )}
                    />

                    <Box className={'flexBetweenTop'}
                        sx={{
                            columnGap: 1,
                            flexWrap: 'wrap',
                        }}>
                        <Controller
                            name={`pin`}
                            control={control}
                            rules={{
                                required: { value: true, message: "" },
                                pattern: {
                                    value: /^[1-9][0-9]{5}$/,
                                    message: "Invalid pincode",
                                },
                            }}
                            defaultValue={""}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field}
                                    required
                                    className={'animate__animated animate__fadeInUp'}
                                    type={"number"}
                                    error={Boolean(errors?.pin)}
                                    helperText={(
                                        errors?.pin?.message ?? ""
                                    ).toString()}
                                    size={"small"}
                                    label={"Pincode"}
                                    placeholder={"XXXXXX"}
                                    sx={{ flex: 1, minWidth: "100px" }}
                                />
                            )}
                        />

                        <Controller
                            name={`acreage`}
                            control={control}
                            rules={{
                                required: { value: true, message: "required" },
                                min: { value: 0, message: "Add at least 0 acreage" },
                                max: { value: 1000, message: "max 1000 acreages" },
                            }}
                            defaultValue={""}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field}
                                    required
                                    onBlur={(e) => {
                                        setValue(
                                            `acreage`,
                                            `${parseFloat((+e.target.value).toFixed(2))}`
                                        );
                                    }}
                                    className={'animate__animated animate__fadeInUp'}
                                    type={"number"}
                                    sx={{ flex: 1, minWidth: "100px" }}
                                    error={Boolean(errors?.acreage)}
                                    helperText={(
                                        errors?.acreage?.message ?? ""
                                    ).toString()}
                                    size={"small"}
                                    label={"Acerage"}
                                    placeholder={"eg. 12.00"}
                                />
                            )}
                        />
                        <Controller
                            name={`crop`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete size={'small'}
                                    autoComplete
                                    multiple
                                    disableCloseOnSelect

                                    limitTags={1}
                                    disabled={fetchingAllCrops}
                                    loading={fetchingAllCrops}
                                    className={'animate__animated animate__fadeInUp'}
                                    onChange={(e, data) => onChange(data)}
                                    noOptionsText={'No Crops Available'}
                                    // value={value || null}
                                    sx={{ flex: 1, minWidth: "200px" }}
                                    options={allCrops}
                                    getOptionLabel={(option) => camelCaseWords(option?.crop_name) ?? ''}
                                    isOptionEqualToValue={((option: any, value: any) => option.id == value.id)}
                                    renderOption={(props, option, { selected }) => (
                                        <Box component={'li'} {...props} key={props.id}
                                            sx={{
                                                p: '2px 6px',
                                                textAlign: 'left',
                                                borderRadius: '12px',
                                                mx: '2px',
                                                fontSize: '15px',
                                                lineHeight: '15px',
                                                fontWeight: 500,
                                                color: theme.palette.text.secondary,
                                            }}>
                                            {camelCaseWords(option?.crop_name)}
                                        </Box>

                                    )}
                                    renderInput={(params) => (
                                        <ThemeTextField
                                            {...params} required
                                            error={Boolean(errors?.crop)}
                                            helperText={(errors?.crop?.message ?? "").toString()}
                                            size={'small'} label={'Crop'}
                                            placeholder={'Please select crop'}
                                        />
                                    )} />
                            )} />
                    </Box>

                    <Button
                        className={'animate__animated animate__fadeInUp'}
                        variant="contained" fullWidth
                        onClick={handleSubmit(onSubmit)}>
                        Submit
                    </Button>
                </>
                : <SuccessfullBlock resetPage={resetPage} />
            }
        </Box >
    );
}


const SuccessfullBlock = (props: any) => {
    const theme = useTheme()
    return (
        <Box
            className={'flexCenter'}
            sx={{
                flexFlow: 'column',
                minHeight: '70vh',
                '& svg': {
                    maxWidth: 'min(70vw, 180px)',
                    mb: 3,
                    '& .cls-1': {
                        fill: `url(#linear-gradient)`,
                        animation: 'rotate 15000ms linear infinite',
                        transformOrigin: 'center'
                    },
                    '& .cls-2': {
                        fill: '#39b54a',
                        opacity: '0.21',
                    },
                    '& .cls-3': {
                        fill: `#fff`,
                        filter: `url(#drop-shadow-1)`,
                    },
                    '@keyframes rotate': {
                        '0%': {
                            transform: 'rotate(0deg)',
                        },
                        '100%': {
                            transform: 'rotate(360deg)',
                        }
                    },
                },
            }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080" className={'animate__animated animate__fadeInUp'}>
                <defs>
                    <linearGradient id="linear-gradient" x1="389.14" y1="191.87" x2="640.85" y2="772.73" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="#39b54a" />
                        <stop offset="1" stopColor="#006837" />
                    </linearGradient>
                    <filter id="drop-shadow-1" filterUnits="userSpaceOnUse">
                        <feOffset dx="5" dy="24" />
                        <feGaussianBlur result="blur" stdDeviation="5" />
                        <feFlood floodColor="#000" floodOpacity=".33" />
                        <feComposite in2="blur" operator="in" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                </defs>
                <rect className="cls-2" x="31" y="31" width="1018" height="1018" rx="294.76" ry="294.76" />
                <path className="cls-1 " d="m627.19,199.06h0c23.61,25.72,57.36,39.7,92.24,38.21h0c69.29-2.96,126.26,54.01,123.3,123.3h0c-1.49,34.88,12.49,68.63,38.21,92.24h0c51.09,46.9,51.09,127.48,0,174.37h0c-25.72,23.61-39.7,57.36-38.21,92.24h0c2.96,69.29-54.01,126.26-123.3,123.3h0c-34.88-1.49-68.63,12.49-92.24,38.21h0c-46.9,51.09-127.48,51.09-174.37,0h0c-23.61-25.72-57.36-39.7-92.24-38.21h0c-69.29,2.96-126.26-54.01-123.3-123.3h0c1.49-34.88-12.49-68.63-38.21-92.24h0c-51.09-46.9-51.09-127.48,0-174.37h0c25.72-23.61,39.7-57.36,38.21-92.24h0c-2.96-69.29,54.01-126.26,123.3-123.3h0c34.88,1.49,68.63-12.49,92.24-38.21h0c46.9-51.09,127.48-51.09,174.37,0Z" />
                <path className="cls-3" d="m344.39,510.08h0c20.44-20.44,53.59-20.44,74.03,0l49.58,49.58c10.75,10.75,28.07,11.11,39.25.81,51.27-47.21,102.55-94.42,153.82-141.63,21.43-19.73,54.84-18.17,74.34,3.46.83.92,1.66,1.84,2.48,2.75,19.38,21.5,17.63,54.64-3.9,73.98-64.63,58.07-129.25,116.14-193.88,174.21-32.79,29.46-83.01,27.67-113.62-4.04l-82.77-85.76c-19.82-20.54-19.53-53.18.65-73.37Z" />
            </svg>
            <Typography variant="h3" className={'animate__animated animate__fadeInUp pageTitle'}>Thank You!</Typography>
            <Typography variant="body2" className={'animate__animated animate__fadeInUp'} color={theme.palette.text.secondary} sx={{
                textAlign: 'center',
            }}>
                Entered details registered successfully<br />with AgriWings.
            </Typography>

            <Button
                variant="outlined"
                className="animate__animated animate__fadeInUp"
                onClick={props.resetPage}
                sx={{
                    mt: 6,
                    width: '140px'
                }}>Okay</Button>
        </Box>
    )
}

export default FarmerRegistration;
