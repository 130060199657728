import React, { useCallback, useEffect, useState } from 'react'
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
    alpha,
    useMediaQuery,
    useTheme,
} from "@mui/material"
import { CurrencyRupeeRounded, DeleteOutlineRounded } from "@mui/icons-material"
import { GridColDef, GridRenderCellParams, GridRowParams, GridToolbar } from "@mui/x-data-grid"

import { ThemeDataTable } from "../../components/theme-data-table-container"
import API from "../../api"
import PageContainer from '../../components/container/page-container'
import TitleBar from '../../components/container/TitleBar'
import TableLoading from '../../components/theme-table-container/TableLoading'
import NoDataView from '../../components/theme-table-container/NoData-'
import { stateList } from '../../utils/sample-data'
import ThemeDialog from '../../components/dialog-box/theme-dialog'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { ThemeTextField } from '../../components/inputs/theme-text-field'
import { useDispatch } from 'react-redux'
import { updateProcessState } from '../../slices/process-slice'
import toast from 'react-hot-toast'
import { FormBlockTitle } from '../../components/title/FormBlockTitle'
import { camelCaseWords } from '../../utils/app-helper'
import { useAvailableCrops } from '../../hooks/useAvailableCrops'
import { useAllCrops } from '../../hooks/useAllCrops'
import CropManagementForm from './crop-management-form'


export default function CropManagement() {

    const theme = useTheme()
    const dispatch = useDispatch()

    const { allCrops, fetchAllCrops, fetchingAllCrops } = useAllCrops()
    const { availableCrops, fetchAvailableCrops, fetchingAvailableCrops } = useAvailableCrops()



    const [cropId, setCropId] = useState<string | null>(null)
    const [cropDetailDialogOpen, setCropDetailDialogOpen] = useState(false)
    const closeDialog = () => {
        setCropDetailDialogOpen(false)
        setCropId(null)
    }

    useEffect(() => {
        fetchAllCrops()
        fetchAvailableCrops()
    }, [])


    return (
        <PageContainer>
            <TitleBar title={'Crops'} secondChild={
                <Button
                    variant='contained'
                    className={'buttonGradient animate__animated animate__fadeInUp'}
                    onClick={() => setCropDetailDialogOpen(true)}>
                    Add New
                </Button>} />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    '& .buttonGradient': {
                        backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        outline: `0px solid ${theme.palette.primary.main}`,
                        outlineOffset: 0,
                        boxShadow: 0,
                        textTransform: 'none',
                        transition: 'all 100ms ease',
                        '&:hover': {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: '2px',
                            '&:active': {
                                transform: 'scale(0.98) !important'
                            }
                        },
                    },

                }}>
                <CropsTable
                    data={availableCrops}
                    fetchingCrops={fetchingAvailableCrops}
                    setCropId={setCropId}
                    setCropDetailDialogOpen={setCropDetailDialogOpen}
                />

            </Box>

            {cropDetailDialogOpen &&
                <ThemeDialog
                    open={cropDetailDialogOpen}
                    title={'Manage Crop'}
                    dialogBody={
                        <CropManagementForm
                            cropId={cropId}
                            allCrops={allCrops}
                            fetchingAllCrops={fetchingAllCrops}
                            closeDialog={closeDialog}
                            fetchAvailableCrops={fetchAvailableCrops}
                        />
                    }
                />
            }
        </PageContainer >
    )
}


const CropsTable = (props: any) => {

    const theme = useTheme()
    const { data, fetchingCrops, setCropId, setCropDetailDialogOpen, setSubmissionType } = props

    const columns: GridColDef[] = [

        {
            field: 'crop_name', headerName: 'Name', flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams) => (
                <Typography sx={{ fontSize: '14px', textTransform: 'capitalize' }}>{camelCaseWords(params.value)}</Typography>
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            type: 'actions',
            cellClassName: 'stickyRight',
            headerClassName: 'stickyLeft',
            getActions: (params: GridRowParams<any>) => [
                <Tooltip placement={'top'} title={`Manage ${params.row?.crop_name}`} arrow>
                    <Chip
                        size={'small'}
                        label={'Manage'}
                        sx={{
                            fontSize: '12px',
                            minWidth: '80px',
                            boxShadow: `0 0 12px -4px ${theme.palette.primary.main} inset`,
                            background: theme.palette.background.paper,
                            '&:hover': {
                                background: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                            },
                        }}
                        onClick={() => {
                            setCropId(params.row?.id)
                            setCropDetailDialogOpen(true)
                        }} />
                </Tooltip>,
            ],
        },
    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetchingCrops}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}


