import { Box, Button, Chip, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tab, Tabs, Tooltip, Typography, alpha, useTheme } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router"
import { LoadingButton } from "@mui/lab"
import moment from "moment"
import toast from "react-hot-toast"
import { Controller, useForm } from "react-hook-form"
import { AccountBalanceWalletTwoTone, AgricultureRounded, AssistantRounded, CloseRounded, DeleteOutlineRounded, EditRounded, EventRounded, LocationOnRounded, MoreVertRounded, PaymentTwoTone, PhoneRounded, PieChartRounded, SensorOccupiedRounded, SyncAltRounded, SyncRounded } from "@mui/icons-material"
import ServiceTimeline from "../service-timeline"
import RmActionForm from "../rm-action-form"
import API from "../../../api"
import LoadingView from "../../../components/loading-view"
import ThemeDialog from "../../../components/dialog-box/theme-dialog"
import { Operator } from "../../../models/operator"
import { User } from "../../../models/user"
import { useProviders } from "../../../hooks/useProviders"
import { useDocumentTitle } from "../../../hooks/useDocumnetTitle"
import { updateProcessState } from "../../../slices/process-slice"
import { RootState } from "../../../store/store"
import { camelCaseWords, currencyFormatter, pluralSuffix, serverRouteWithoutApi } from "../../../utils/app-helper"
import { ThemeTextField } from "../../../components/inputs/theme-text-field"
import UpdateForm from "./update-form"
import { CancelForm } from "../../asset-operator/service-list/service-detail"
import SchemeStack from "../../../components/schemeStack"


export default function UpdateService() {

    useDocumentTitle('Service Details')

    let paymentPage: Window | null

    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser
    const isAdmin = (currentUser?.role?.name == 'super admin' || currentUser?.role?.name == 'admin')
    const isRm = (currentUser?.role?.name == 'rm' || isAdmin)

    const params = useParams()
    const { serviceId } = params
    const { fetchProviders, providers } = useProviders()

    const [serviceDetail, setServiceDetail] = useState<any>({} as any)
    const [paymentType, setPaymentType] = useState(serviceDetail?.payment_type ?? '1') // 1: prepaid, 2: postpaid
    const [paymentMode, setPaymentMode] = useState<'1' | '2'>('1') // 1: offline, 2: online
    const [selectedRequest, setSelectedRequest] = useState<null | {}>(null)
    const [dialogFor, setDialogFor] = useState<null | 'timeline' | 'rmAction' | 'adminAction' | 'update' | 'cancel'>(null)


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)


    const alreadyReceivedAmount = serviceDetail?.amount_received?.length > 3 ? JSON.parse(serviceDetail.amount_received) : []
    const receivedAmount = alreadyReceivedAmount.length > 0 ? alreadyReceivedAmount.filter((el: any) => el.mode == '1' || el.mode == '2').reduce((total: number, current: any) => total + +current.amount, 0) : 0


    const [bankPage, setBankPage] = useState<null | string>(null)

    const [fetching, setFetching] = useState(true)

    const { handleSubmit, register, formState: { errors } } = useForm()

    const fetchServiceDetail = () => {
        if (bankPage) {
            setFetching(true)
            dispatch(updateProcessState(true))
        }
        setFetching(true)
        API.get(`/fetch_single_order/${serviceId}`)
            .then((res) => {
                setServiceDetail(res.data?.data)
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setFetching(false)
                dispatch(updateProcessState(false))
            })
    }
    const [operators, setOperators] = useState<Operator[]>([])

    const fetchOperators = useCallback(() => {
        API.get('/fetch_operators_to_assign', { headers: { "Accept": "application/json" } })
            .then((res) => {
                res.data.status == 'success' &&
                    setOperators(res.data?.data)
            })
            .catch((err) => console.log(err))
            .finally(() => { })
    }, [])

    const onUpdatePayment = (data: any) => {
        const newData = {
            id: `${serviceDetail?.id}`,
            payment_type: `${paymentType}`,
            amount_received: paymentType == '1'
                ? JSON.stringify([{
                    mode: paymentMode,
                    reference_no: data?.reference_no,
                    amount: serviceDetail?.total_payable_amount,
                }])
                : '[]',

        }
        if (paymentType == '1' && paymentMode == '2') {
            API.post('/initiate_payment_request', { id: `${serviceDetail?.id}` })
                .then((res) => setBankPage(res.data))
                .catch((err) => console.error(err))
                .finally(() => dispatch(updateProcessState(false)))
        } else {
            API.post('/update_payment_details', newData)
                .then((res) => {
                    if (res.data?.status == 'success') {
                        toast.success('Payment updated for this request')
                        fetchServiceDetail()
                    } else {
                        toast.error(`${res.data?.msg}`)
                    }
                })
                .catch((err) => console.error(err))
                .finally(() => {
                    dispatch(updateProcessState(false))
                })

        }
    }


    const amountReceived = serviceDetail?.amount_received?.length > 0 ? JSON.parse(serviceDetail?.amount_received) : []

    useEffect(() => {
        fetchServiceDetail()
    }, [serviceId])

    useEffect(() => {
        setPaymentType(serviceDetail?.payment_type)
        if (serviceDetail?.order_status == '1') {
            fetchOperators()
            fetchProviders()
        }
    }, [serviceDetail])

    var intervalId: string | number | NodeJS.Timeout | undefined
    function isPaymentWIndowOpen() {
        if (paymentPage && !paymentPage?.closed) {
            console.log('Processing Payment')
        } else {
            setBankPage(null)
            fetchServiceDetail()
            dispatch(updateProcessState(false))
            clearInterval(intervalId)
        }
    }


    useEffect(() => {
        if (bankPage) {
            paymentPage = window.open(bankPage, 'paymentWindow');
            dispatch(updateProcessState(true))
            intervalId = setInterval(isPaymentWIndowOpen, 500);

            window.onbeforeunload = function (e) {
                return 'Changes made will not be saved';
            }
        }
    }, [bankPage])


    return (
        fetching && bankPage == null
            ? <LoadingView />
            : <Box className={'animate__animated animate__fadeIn'}
                sx={{
                    minHeight: 'min(80vh, 650px)',
                    p: 2,
                    '& .actionButton': {
                        flex: '1 1 140px',
                        borderRadius: '18px',
                        transition: 'all 0.6s ease',
                        px: 2,
                        display: 'flex',
                        alignnItems: 'center',
                        justifyContent: 'space-between',
                        gap: 1.5,
                        fontSize: '14px',
                        textTransform: 'none',
                        '& svg': {
                            width: '1.4em',
                            height: '1.4em',
                            transition: 'all 0.3s ease',
                            transform: 'rotate(90deg)',
                            '&.normal': {
                                transform: 'rotate(0deg)',
                            },
                        },
                        '& .label': {
                            flex: 1,
                            textAlign: 'center',
                        },
                        '&:hover': {
                            '& svg': {
                                transform: 'translateX(5px) rotate(0deg)',
                            }
                        }
                    }
                }}>

                <Box
                    sx={{
                        outline: `1px solid ${alpha(theme.palette.text.disabled, 0.1)}`,
                        borderRadius: '32px',
                        p: 2,
                        display: 'flex',
                        flexWrap: 'wrap',
                        columnGap: 1,
                        rowGap: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Typography className={'animate__animated animate__fadeInUp'}
                        sx={{
                            flex: 1,
                            fontWeight: 600,
                            fontSize: '14px',
                            fontFamily: theme.typography.h1.fontFamily,
                            color: theme.palette.primary.main,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                        # {serviceDetail?.order_id}
                        <IconButton onClick={fetchServiceDetail}>
                            <SyncRounded fontSize="small" />
                        </IconButton>
                    </Typography>

                    <Stack direction={'row'} alignItems={'center'}>
                        <Chip
                            className={'animate__animated animate__fadeInUp'}
                            size={'small'}
                            sx={{ minWidth: '100px' }}
                            label={serviceDetail?.order_status == '0' ? 'Cancelled'
                                : serviceDetail?.order_status == '1' ? 'Created' :
                                    serviceDetail?.order_status == '2' ? 'Assigned' :
                                        serviceDetail?.order_status == '3' ? 'Accepted' :
                                            serviceDetail?.order_status == '4' ? 'Started' :
                                                serviceDetail?.order_status == '5' ? 'Complete' :
                                                    serviceDetail?.order_status == '6' ? 'Delivered' :
                                                        'Canclled'
                            }
                            color={serviceDetail?.order_status == '0' ? 'error'
                                : serviceDetail?.order_status == '1' ? 'warning' :
                                    serviceDetail?.order_status == '2' ? 'secondary' :
                                        serviceDetail?.order_status == '3' ? 'info' :
                                            serviceDetail?.order_status == '4' ? 'default' :
                                                serviceDetail?.order_status == '5' ? 'primary' :
                                                    serviceDetail?.order_status == '6' ? 'success' :
                                                        'error'
                            }
                        />

                        {
                            (currentUser?.role?.name == 'super admin' || (serviceDetail?.order_status != '0' && +serviceDetail?.order_status < 6) && isRm) &&
                            <>
                                <IconButton onClick={handleClick}>
                                    <MoreVertRounded />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={!!anchorEl}
                                    onClose={handleClose}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    sx={{
                                        '& .MuiListItemIcon-root': {
                                            height: '20px',
                                            width: '20px',
                                        },
                                    }}

                                >
                                    <MenuItem
                                        disabled={
                                            serviceDetail?.order_timeline?.amended_date || (currentUser.role?.name == 'rm' ? +serviceDetail?.order_status > 3 : (isAdmin ? +serviceDetail?.order_status > 4 : false))
                                        }
                                        onClick={() => {
                                            if (serviceDetail?.order_timeline?.amended_date) {
                                                toast.error(`Order Already Updated`)
                                            } else {
                                                if (currentUser.role?.name == 'rm' ? +serviceDetail?.order_status < 4 : (isAdmin ? +serviceDetail?.order_status < 5 : false)) {
                                                    setDialogFor('update')
                                                    setSelectedRequest({
                                                        id: `${serviceDetail?.id}`,
                                                        orderId: `${serviceDetail?.order_id}`,
                                                        orderStatus: serviceDetail?.order_status,
                                                        ...serviceDetail,
                                                    })
                                                }
                                            }

                                            handleClose()
                                        }}>
                                        <ListItemIcon>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={'normal'}>
                                                <path d="M15.2141 5.98239L16.6158 4.58063C17.39 3.80646 18.6452 3.80646 19.4194 4.58063C20.1935 5.3548 20.1935 6.60998 19.4194 7.38415L18.0176 8.78591M15.2141 5.98239L6.98023 14.2163C5.93493 15.2616 5.41226 15.7842 5.05637 16.4211C4.70047 17.058 4.3424 18.5619 4 20C5.43809 19.6576 6.94199 19.2995 7.57889 18.9436C8.21579 18.5877 8.73844 18.0651 9.78375 17.0198L18.0176 8.78591M15.2141 5.98239L18.0176 8.78591" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11 20H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                        </ListItemIcon>
                                        <ListItemText>Edit</ListItemText>
                                    </MenuItem>

                                    {isAdmin &&
                                        <MenuItem
                                            disabled={+serviceDetail?.order_status == 6 || +serviceDetail?.order_status == 0}
                                            onClick={() => {
                                                if (+serviceDetail?.order_status != 6 && +serviceDetail?.order_status != 0) {
                                                    setDialogFor('cancel')
                                                    setSelectedRequest({ id: `${serviceDetail?.id}` })
                                                }
                                                handleClose()
                                            }}
                                        >
                                            <ListItemIcon>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={'normal'}>
                                                    <path d="M14.9994 15L9 9M9.00064 15L15 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" stroke-width="1.5" />
                                                </svg>
                                            </ListItemIcon>
                                            <ListItemText>Cancel</ListItemText>
                                        </MenuItem>
                                    }

                                    {currentUser?.role?.name == 'super admin' &&
                                        <MenuItem
                                            onClick={() => {
                                                if (currentUser?.role?.name == 'super admin') {
                                                    setDialogFor('adminAction')
                                                    setSelectedRequest({
                                                        id: `${serviceDetail?.id}`,
                                                        orderId: `${serviceDetail?.order_id}`,
                                                        orderStatus: serviceDetail?.order_status,
                                                    })
                                                }
                                                handleClose()
                                            }}
                                            sx={{ color: theme.palette.error.main }}>
                                            <ListItemIcon sx={{ color: theme.palette.error.main }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={'normal'}>
                                                    <path d="M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                                    <path d="M9 11.7349H15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                                    <path d="M10.5 15.6543H13.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                                    <path d="M3 5.5H21M16.0555 5.5L15.3729 4.09173C14.9194 3.15626 14.6926 2.68852 14.3015 2.39681C14.2148 2.3321 14.1229 2.27454 14.0268 2.2247C13.5937 2 13.0739 2 12.0343 2C10.9686 2 10.4358 2 9.99549 2.23412C9.89791 2.28601 9.80479 2.3459 9.7171 2.41317C9.32145 2.7167 9.10044 3.20155 8.65842 4.17126L8.05273 5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                                </svg>
                                            </ListItemIcon>
                                            <ListItemText>Remove</ListItemText>
                                        </MenuItem>
                                    }
                                </Menu>
                            </>
                        }


                    </Stack>

                    <Box
                        sx={{
                            position: 'relative',
                            width: '100%',
                            background: alpha(theme.palette.text.disabled, 0.1),
                            borderRadius: '12px',
                            px: 2, pb: 1, pt: 3,
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'flex-start',
                            columnGap: 1,
                            '& .summaryLine': {
                                fontSize: '13px',
                                display: 'grid',
                                '& span': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                    mx: 1,
                                    '& span': {
                                        mx: 0,
                                    },
                                    '& svg': {
                                        color: theme.palette.text.disabled,
                                    },
                                    '& a': {
                                        textDecoration: 'none',
                                        color: theme.palette.primary.main,
                                    },
                                },
                            },
                        }}>
                        <Box sx={{
                            color: theme.palette.primary.contrastText,
                            borderTopLeftRadius: '12px',
                            borderBottomRightRadius: '16px',
                            paddingInline: '16px',
                            background: theme.palette.primary.main,
                            fontSize: '12px',
                            position: 'absolute',
                            top: -5,
                            left: 0,
                            py: 0.3,
                            boxShadow: `5px 1px 5px -2px #838383`

                        }}>
                            Created on <strong>{moment(serviceDetail?.spray_date, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY')}</strong>
                        </Box>
                        <Box className={'summaryLine animate__animated animate__fadeInUp'}
                            sx={{
                                flex: '2 1 400px',
                                '& .link': {
                                    color: theme.palette.primary.main,
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    maxWidth: 'max-content',
                                    '&:hover': {
                                        textDecoration: 'none',

                                    },
                                },
                            }}>
                            <span><strong>{camelCaseWords(serviceDetail?.client_details?.regional_client_name)} ({serviceDetail?.client_order_id})</strong></span>
                            <span><AgricultureRounded sx={{ fontSize: '12px' }} /> <strong>{camelCaseWords(serviceDetail?.farmer_details?.farmer_name)}</strong></span>
                            <span><PhoneRounded sx={{ fontSize: '12px' }} /><strong>{serviceDetail?.farmer_details?.farmer_mobile_no}</strong></span>
                            <span><PieChartRounded sx={{ fontSize: '12px' }} /><strong>{serviceDetail?.sprayed_acreage ?? serviceDetail?.requested_acreage}</strong> acres of <strong>{serviceDetail?.crop_name}</strong></span>
                            <span> <LocationOnRounded sx={{ fontSize: '12px' }} /> {serviceDetail?.farm_location?.village}, {serviceDetail?.farm_location?.sub_district}, {serviceDetail?.farm_location?.district} - {serviceDetail?.farm_location?.pin_code}</span>

                            <span><Tooltip title={'Spray Request Date'}><EventRounded sx={{ fontSize: '12px' }} /></Tooltip> {moment(serviceDetail?.spray_date, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY')}</span>

                            {serviceDetail?.asset_operator_id
                                ? <span><Tooltip title={'Asset Operator'}><SensorOccupiedRounded sx={{ fontSize: '12px' }} /></Tooltip> {serviceDetail?.asset_operator?.name ?? '-'}</span>
                                : <Tooltip arrow title={'Click to assign Asset Operator'}><span className="link"
                                    onClick={() => {
                                        if (serviceDetail?.order_status == '1' && isRm) {
                                            if (((serviceDetail?.payment_status != '1' && serviceDetail?.payment_status != '0') || serviceDetail?.payment_type == '2') || serviceDetail?.order_type == '4' || serviceDetail?.order_type == '5') {
                                                setDialogFor('rmAction')
                                                setSelectedRequest({ id: `${serviceDetail?.id}`, orderId: `${serviceDetail?.order_id}`, provider_id: `${serviceDetail?.provider_id}`, provider_name: `${serviceDetail?.provider_name}` })
                                            } else {
                                                toast.error('Please update payment before assigning AO.')
                                            }
                                        } else {
                                            toast.error(`You're not allowed to do so.`)
                                        }
                                    }}
                                >
                                    <SensorOccupiedRounded sx={{ fontSize: '12px' }} /> Assigne Operator
                                </span>
                                </Tooltip>
                            }

                            {serviceDetail?.provider_name &&
                                <span><Tooltip title={'Provider'}><AssistantRounded sx={{ fontSize: '12px' }} /></Tooltip> {serviceDetail?.provider_name ?? '-'}</span>
                            }

                            {(serviceDetail?.scheme_ids) &&
                                <Box sx={{ maxWidth: '450px', mt: 1, }}>

                                    <ShowSchemes id={`${serviceDetail?.scheme_ids}`} />

                                </Box>
                            }

                        </Box>


                        <Box className={'summaryLine animate__animated animate__fadeInUp'} sx={{
                            flex: '1 1 240px',
                            '& span': {
                                justifyContent: 'space-between',
                            },
                        }}>
                            {(serviceDetail?.extra_discount || serviceDetail?.scheme_ids) &&
                                <Box sx={{
                                    mt: 1,
                                    mb: 2,
                                    p: 1,
                                    color: theme.palette.info.main,
                                    border: `1px solid ${theme.palette.info.main}`,
                                    borderRadius: '16px',
                                    background: alpha(theme.palette.info.main, 0.1),
                                    textAlign: 'center',
                                }}>
                                    {serviceDetail?.scheme_ids &&
                                        serviceDetail?.scheme_ids?.split(',')?.length > 0 ? `${serviceDetail?.scheme_ids?.split(',')?.length} scheme${pluralSuffix(serviceDetail?.scheme_ids?.split(',')?.length)}` : ''}
                                    {(serviceDetail?.extra_discount && serviceDetail?.scheme_ids) && ` &`}
                                    {serviceDetail?.extra_discount && ` ${currencyFormatter(serviceDetail?.extra_discount)} extra discount `}
                                    {(serviceDetail?.extra_discount || serviceDetail?.scheme_ids) && ` applied.`}
                                </Box>
                            }

                            <span><strong>Payment:</strong></span>
                            <Divider />
                            <span>Total: <span style={{ color: theme.palette.text.secondary }}><strong>{currencyFormatter(+serviceDetail?.total_amount ?? 0)}</strong></span></span>
                            <span>Discount: <span style={{ color: theme.palette.text.secondary }}><strong>-{currencyFormatter(+serviceDetail?.total_discount ?? 0)}</strong></span></span>
                            <span>Payable: <span style={{ color: theme.palette.text.primary }}><strong>{currencyFormatter(+serviceDetail?.total_payable_amount ?? 0)}</strong></span></span>

                            {(serviceDetail?.order_status == '3' || serviceDetail?.order_status == '4' || serviceDetail?.order_status == '5' || serviceDetail?.order_status == '6') &&
                                <span>Received: <span style={{ color: theme.palette.success.main }}><strong>{currencyFormatter(+receivedAmount)}</strong></span></span>
                            }
                            {(serviceDetail?.order_status == '5' || serviceDetail?.order_status == '6') &&
                                <>
                                    {serviceDetail?.added_amount != '0' && <span>Excess: <span style={{ color: theme.palette.warning.main }}><strong>{currencyFormatter(+serviceDetail?.added_amount)}</strong></span></span>}
                                    {serviceDetail?.refund_amount != '0' && <span>Refund: <span style={{ color: theme.palette.error.main }}><strong>{currencyFormatter(+serviceDetail?.refund_amount)}</strong></span></span>}
                                </>
                            }
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        mt: 3,
                        borderRadius: '28px',
                        width: '100%',
                        maxWidth: { xs: '100%', sm: '600px' },
                        mx: 'auto',
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& .MuiTabs-root': {
                            minWidth: '100%',
                            '& .MuiTabs-scroller': {
                                overflow: 'visible !important',
                                flex: 1,
                                '& .MuiTabs-flexContainer': {
                                    // height: '100%',
                                },
                            },
                            '& .MuiTab-root': {
                                p: '8px 12px',
                                borderRadius: '32px',
                                // minHeight: '28px',
                                zIndex: 1,
                                textTransform: 'none',
                                color: theme.palette.text.secondary,
                                minWidth: '120px',
                                transition: 'all 300ms ease-in-out',
                                flex: 1,
                                '&:hover': {
                                    color: theme.palette.text.primary,
                                },
                                '&.Mui-selected': {
                                    color: '#f2f2f2', // theme.palette.text.primary,
                                    fontWeight: 600,
                                },
                            },
                            '& .MuiTabs-indicator': {
                                zIndex: 0,
                                borderTopRightRadius: '32px',
                                borderTopLeftRadius: '32px',
                                height: '100%',
                                width: '100%',
                                boxShadow: `0 2px 11px -2px #83838370`,
                                background: paymentType == '2' ? `#c77b00` : `#1a940e`,
                                // background: theme.palette.background.paper
                            },
                            '& .curve': {
                                position: 'absolute',
                                height: '48px',
                                width: '48px',
                                left: '50%',
                                top: '12px',
                                fill: paymentType == '2' ? `#c77b00` : `#1a940e`,
                                zIndex: 1,
                                transform: 'translateX(-50%)',
                            },
                        },

                    }}>
                    <Tabs
                        value={paymentType}
                        onChange={(event: React.SyntheticEvent, newValue: string) => {
                            if ((serviceDetail?.payment_status == '0' || serviceDetail?.payment_status == '1') && serviceDetail?.order_status == '1' && (serviceDetail?.order_type != '4' && serviceDetail?.order_type != '5')) {
                                setPaymentType(newValue)
                            }
                        }}
                        aria-label="Payment Type Tabs"
                    >
                        <Tab disableRipple value="1" label="Prepaid" />
                        <Tab disableRipple value="2" label="Postpaid" />
                        <svg className={'curve'} x="0px" y="0px" viewBox="0 0 512 256">
                            <path d="M512,256H0c141.4,0,256-114.6,256-256C256,141.4,370.6,256,512,256z" />
                        </svg>
                    </Tabs>

                    <Box
                        sx={{
                            background: paymentType == '2' ? `linear-gradient(180deg, #c77b00, #684a1a)` : `linear-gradient(353deg, #032400 0%, #2a9607 75%, #1a940e 100%)`,
                            color: '#fff',
                            fontSize: '14px',
                            flex: 1,
                            borderBottomLeftRadius: '32px',
                            borderBottomRightRadius: '32px',
                            borderTopLeftRadius: paymentType == '2' ? '32px' : '0',
                            borderTopRightRadius: paymentType == '1' ? '32px' : '0',
                            transition: 'all 300ms ease-in-out',
                            p: 2,
                            minHeight: '170px',
                            display: 'flex',
                            flexFlow: 'column',

                        }}>
                        {(serviceDetail?.order_type == '4' || serviceDetail?.order_type == '5' || serviceDetail?.payment_required == 0)
                            ? <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minHeight: '120px',
                                    '& .des': {
                                        mx: 2,
                                        fontSize: 'clamp(0.85rem, 3.4783vw + 0.3043rem, 0.8rem)',
                                        textAlign: 'center',
                                    },
                                }}>
                                <Typography className={'des animate__animated animate__fadeInUp'} >
                                    🔔  Payment receiving is not applicable for this service request.<br />
                                    {serviceDetail?.payment_required == 0 && 'Payment handled by client'}
                                </Typography>
                            </Box>
                            : ((serviceDetail?.payment_status == '0' || serviceDetail?.payment_status == '1') && serviceDetail?.order_status == '1')
                                ? <>
                                    <Box sx={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        minHeight: '120px',
                                        '& .des': {
                                            maxWidth: '28ch',
                                            fontSize: 'clamp(0.85rem, 3.4783vw + 0.3043rem, 0.8rem)',
                                            textAlign: 'center',
                                        },


                                    }}>
                                        {paymentType == '2' &&
                                            <Typography className={'des animate__animated animate__fadeInUp'}>
                                                👉 Payable amount will be received at the time of spray completion by AO.
                                            </Typography>
                                        }
                                        {paymentType == '1' &&
                                            <Box
                                                sx={{
                                                    flex: 1,
                                                    minWidth: '100%',
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    gap: 2,
                                                    px: 2,
                                                    mb: 2,
                                                    '& .paymentOption': {
                                                        cursor: 'pointer',
                                                        borderRadius: '16px',
                                                        py: 1, px: 2,
                                                        color: theme.palette.text.secondary,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        flexFlow: 'row wrap',
                                                        transition: 'all 350ms ease-in-out',
                                                        position: 'relative',
                                                        outline: `2px solid ${alpha(theme.palette.background.paper, 0.2)}`,
                                                        minWidth: '90px',
                                                        height: '72px',
                                                        overflow: 'hidden',
                                                        '& .selectContentBox': {
                                                            flex: 1,
                                                            display: 'flex',
                                                            gap: 1.5,
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            '& .optionIcon': {
                                                                color: alpha(theme.palette.background.paper, 0.2),
                                                                fontSize: '2.5rem',
                                                            },
                                                            '& .optionTitle': {
                                                                fontSize: '0.8rem',
                                                                lineHeight: '0.8rem',
                                                                fontWeight: 600,
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                background: alpha(theme.palette.background.paper, 0.2),
                                                                color: theme.palette.primary.main,
                                                                p: '2px 12px',
                                                                borderRadius: '18px 0 18px 0',
                                                                minWidth: '60px',
                                                            },
                                                        },
                                                        '& .content': {
                                                            flex: 1,
                                                            '& img': {
                                                                maxHeight: '22px',
                                                                width: '100%'
                                                            },
                                                        },
                                                        '&.active': {
                                                            minWidth: '260px',
                                                            background: alpha(theme.palette.background.paper, 0.1),
                                                            outline: `2px solid ${alpha(theme.palette.background.paper, 1)}`,
                                                            boxShadow: 7,
                                                            transition: 'all 350ms ease-in-out',
                                                            '& .selectContentBox': {
                                                                '& .optionTitle': {
                                                                    background: alpha(theme.palette.background.paper, 1),
                                                                    color: theme.palette.primary.main,
                                                                },
                                                            },
                                                            '& .optionIcon': {
                                                                color: theme.palette.background.paper,
                                                                fontSize: '2rem',
                                                                transition: 'all 300ms',
                                                            },
                                                        },
                                                    },
                                                }}>
                                                <Box className={`animate__animated animate__fadeInUp paymentOption ${paymentMode == '1' && ' active'}`}
                                                    sx={{ flex: paymentMode == '1' ? `3 1 160px` : `1 1 60px` }}
                                                    onClick={() => setPaymentMode('1')}>
                                                    <Box className={'selectContentBox'}>
                                                        <AccountBalanceWalletTwoTone className={'optionIcon'} />
                                                        <Typography variant={'caption'} className={'optionTitle'}>
                                                            Offline
                                                        </Typography>
                                                        {paymentMode == '1' &&
                                                            <Box className={'content'}
                                                                sx={{
                                                                    '& .refInput': {
                                                                        background: alpha(theme.palette.background.paper, 0.1),
                                                                        color: theme.palette.text.primary,
                                                                        p: '12px',
                                                                        borderWidth: '0 0 0 0',
                                                                        width: '100%',
                                                                        outline: 'none',
                                                                        borderRadius: '12px',
                                                                        borderColor: errors?.trxn_no ? theme.palette.error.main : theme.palette.background.default,
                                                                        '&::placeholder': {
                                                                            color: theme.palette.text.secondary,
                                                                        },
                                                                        '&:focus': {
                                                                            borderWidth: '0 0 2px 0',
                                                                            borderStyle: 'solid',
                                                                            borderColor: errors?.reference_no ? theme.palette.error.main : theme.palette.background.default,
                                                                            borderRadius: '0',
                                                                        },

                                                                    },
                                                                }}>
                                                                <input placeholder="Reference Remarks" className="refInput" {...register('reference_no', { required: true, pattern: /^[a-zA-Z0-9 ]*$/ })} />
                                                            </Box>
                                                        }
                                                    </Box>
                                                </Box>
                                                <Box className={`animate__animated animate__fadeInUp paymentOption ${paymentMode == '2' && ' active'}`}
                                                    sx={{ flex: paymentMode == '2' ? `3 1 160px` : `1 1 60px` }}
                                                    onClick={() => setPaymentMode('2')}>
                                                    <Box className={'selectContentBox'}>
                                                        <PaymentTwoTone className={'optionIcon'} />
                                                        <Typography variant={'caption'} className={'optionTitle'}>
                                                            Online
                                                        </Typography>
                                                        {paymentMode == '2' &&
                                                            <Box className={'content'}>
                                                                <Typography sx={{
                                                                    color: theme.palette.background.paper,
                                                                    fontSize: '13px',
                                                                }}>
                                                                    ⚠️ Payment QR will be shown here to pay the payable amount.
                                                                </Typography>
                                                            </Box>
                                                        }
                                                    </Box>
                                                </Box>
                                            </Box>
                                        }
                                    </Box>
                                    <LoadingButton
                                        onClick={handleSubmit(onUpdatePayment)}
                                        variant="contained"
                                        fullWidth
                                        className="animate__animated animate__fadeInUp"
                                        sx={{
                                            background: '#fff !important',
                                            minHeight: '48px',
                                            borderRadius: '18px',
                                            color: paymentType == '2' ? `#c77b00` : `#1a940e`,
                                            textTransform: 'none',
                                            mx: 'auto',

                                        }}
                                    >
                                        Update Payment
                                    </LoadingButton>
                                </>
                                : <>
                                    <Box
                                        sx={{
                                            flex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            minHeight: '120px',
                                            '& .des': {
                                                mx: 2,
                                                fontSize: 'clamp(0.85rem, 3.4783vw + 0.3043rem, 0.8rem)',
                                                textAlign: 'center',
                                            },


                                        }}>
                                        {
                                            serviceDetail?.payment_type == '1'
                                                ? <Typography className={'des animate__animated animate__fadeInUp'} >
                                                    {serviceDetail?.order_status != '6' && <>🔔 Initial payment received for this service request.<br /></>}
                                                    {currencyFormatter(amountReceived?.length > 0 ? +amountReceived[0]?.amount : 0)} received {
                                                        amountReceived?.length > 0 ? (amountReceived[0]?.mode == '1' ? 'offline' : 'online') : ''
                                                    } with reference {amountReceived?.length > 0 ? amountReceived[0]?.reference_no : ''}
                                                    <br />
                                                    {(+serviceDetail?.refund_amount > 0 || +serviceDetail?.added_amount > 0) && amountReceived?.length > 1
                                                        ? `${currencyFormatter(amountReceived?.length > 1 ? +amountReceived[1]?.amount : 0)} ${+serviceDetail?.refund_amount > 0 ? 'refunded' : 'excess amount received'} ${amountReceived?.length > 1 ? amountReceived[1]?.mode == '1' ? 'offline' : 'online' : ''
                                                        } with reference ${amountReceived?.length > 1 ? amountReceived[1]?.reference_no : ''}`
                                                        : +serviceDetail?.refund_amount > 0 ? `Refund Initiated` : ``
                                                    }

                                                </Typography>
                                                : <Typography className={'des animate__animated animate__fadeInUp'}>
                                                    {serviceDetail?.order_status == '6'
                                                        ? `${currencyFormatter(amountReceived?.length > 0 ? +amountReceived[0]?.amount : 0)} received ${amountReceived?.length > 0 ? (amountReceived[0]?.mode == '1' ? 'offline' : 'online') : ''
                                                        } with reference ${amountReceived?.length > 0 ? amountReceived[0]?.reference_no : ''}`
                                                        : `👉 Payable amount will be received at the time of spray completion by AO.`
                                                    }
                                                </Typography>
                                        }
                                    </Box>
                                </>
                        }
                    </Box>
                </Box>

                <Box
                    sx={{
                        mt: 3,
                        borderRadius: '28px',
                        width: '100%',
                        maxWidth: { xs: '100%', sm: '600px' },
                        mx: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        flexWrap: 'wrap',
                    }} >
                    <Button className="actionButton" variant="contained"
                        onClick={() => {
                            setDialogFor('timeline')
                            setSelectedRequest({
                                id: `${serviceDetail?.id}`,
                                orderId: `${serviceDetail?.order_id}`,
                                name: `${serviceDetail?.asset_operator?.name}`,
                                phone: serviceDetail?.asset_operator?.phone,
                                asset: serviceDetail?.asset?.asset_id,
                                droneAcreage: serviceDetail?.drone_estimated_acreage,
                                payment_required: `${serviceDetail?.payment_required}`,
                            })
                        }}>
                        <span className="label">Service Timeline</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                        </svg>
                    </Button>
                    {serviceDetail?.order_status == '6' &&
                        <Button className="actionButton" variant="outlined"
                            disabled={serviceDetail?.order_status != '6'}
                            onClick={() => {
                                if (serviceDetail?.order_status == '6') {
                                    const link = document.createElement("a");
                                    link.href = `${serverRouteWithoutApi}/zp/${btoa(serviceDetail?.id)}`;
                                    link.setAttribute("target", `_blank`);
                                    link.setAttribute("download", `invoice-${serviceDetail?.id}.pdf`);
                                    document.body.appendChild(link);
                                    link.click();
                                    link.parentNode?.removeChild(link); // Clean up and remove the link
                                    window.close()
                                }
                            }}>
                            <span className="label">Payment Invoice</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                            </svg>
                        </Button>
                    }
                </Box>

                {
                    !!selectedRequest && dialogFor == 'rmAction' &&
                    <ThemeDialog
                        open={!!selectedRequest}
                        title={'Update Request'}
                        onClickClose={() => setSelectedRequest(null)}
                        dialogBody={
                            <RmActionForm
                                fetchServices={fetchServiceDetail}
                                setSelectedRequest={setSelectedRequest}
                                selectedRequest={selectedRequest}
                                operators={operators}
                                providers={providers}
                            />
                        } />
                }
                {
                    !!selectedRequest && dialogFor == 'adminAction' &&
                    <ThemeDialog
                        open={!!selectedRequest}
                        title={'Remove Request'}
                        onClickClose={() => setSelectedRequest(null)}
                        dialogBody={
                            <RemoveDialog
                                fetchServices={fetchServiceDetail}
                                setSelectedRequest={setSelectedRequest}
                                selectedRequest={selectedRequest}
                            />
                        } />
                }

                {
                    !!selectedRequest && dialogFor == 'timeline' &&
                    <ThemeDialog
                        open={!!selectedRequest && dialogFor == 'timeline'}
                        title={'Request Timeline'}
                        onClickClose={() => setSelectedRequest(null)}
                        dialogBody={
                            <ServiceTimeline requestDetails={selectedRequest} />
                        } />
                }

                {
                    !!selectedRequest && dialogFor == 'update' &&
                    <ThemeDialog
                        open={!!selectedRequest}
                        title={`Update Request`}
                        dialogBody={<UpdateForm selectedRequest={selectedRequest} onClose={() => setSelectedRequest(null)} fetchServices={fetchServiceDetail} />}
                    />
                }

                {
                    !!selectedRequest && dialogFor == 'cancel' &&
                    <ThemeDialog open={!!selectedRequest} title={'Cancel Request'}
                        dialogBody={<CancelForm serviceId={serviceDetail?.id} fetchServiceDetail={fetchServiceDetail} closeDialog={() => setSelectedRequest(null)} />}
                    />
                }
            </Box >
    )
}


const RemoveDialog = (props: any) => {

    const { selectedRequest } = props

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser
    const { handleSubmit, control, formState: { errors } } = useForm()

    const onSubmit = (data: any) => {
        if (currentUser?.role?.name == 'super admin') {
            API.post('/delete_order', {
                id: `${selectedRequest?.id}`,
                delete_remarks: data?.delete_remarks
            })
                .then((res) => {
                    toast.success('Service removed successfully.')
                    navigate('/services')
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    dispatch(updateProcessState(false))
                })
        } else {
            toast.error('Action not allowed.')
        }
    }

    return (
        <Box
            sx={{
                width: { xs: 'min(80vw, 350px)', sm: 'min(90vw, 350px)' },
                pt: 3,
            }}>

            <Box sx={{
                minHeight: '90px',
                mb: 2,
            }}>
                <Controller name={`delete_remarks`}
                    control={control}
                    rules={{ required: { value: true, message: 'Required' } }}
                    defaultValue={''}
                    render={({ field }) => (
                        <ThemeTextField
                            {...field} required multiline rows={3}
                            error={Boolean(errors?.delete_remarks)}
                            helperText={(errors?.delete_remarks?.message ?? '').toString()}
                            size={'small'} label={'Remarks'}
                            placeholder={'eg. reason for order removing'}
                        />
                    )} />
            </Box>


            <LoadingButton
                variant='contained'
                fullWidth
                color='error'
                onClick={handleSubmit(onSubmit)}
            >
                Remove
            </LoadingButton>
        </Box>
    )
}




interface ShowSchemesProps { id: string }

const ShowSchemes = ({ id }: ShowSchemesProps) => {


    const dispatch = useDispatch()

    const [schemes, setSchemes] = useState<any[]>([])


    const checkSchemes = () => {
        dispatch(updateProcessState(true))
        API.get(`/get_scheme_details?scheme_id=${id}`)
            .then((res) => {
                setSchemes(res.data?.data)
            })
            .catch((err) => console.log(err))
            .finally(() => {
                dispatch(updateProcessState(false))
            })
    }

    useEffect(() => {
        checkSchemes()
    }, [])


    return (
        <>
            {schemes?.map((scheme: any, index: number) =>
                <SchemeStack key={index} name={scheme?.scheme_name} amount={scheme?.discount_price} />
            )}
        </>
    )
}