import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface AttendanceInterface {
  user_id: string | null,
  emp_id: string | null;
  user_mobile_no: string | null,
  in: string | null,
  out: string | null,
  out_of_station: string | null,
  field_activity: string | null,
  on_leave: string | null,
  user_remarks: string | null,
  attendance_status: string | null,
  geo_location: string | null,
  remarks: string | null,
  date: string | null,
  working_hours: string | null,
}

export const initialStateAttendance: AttendanceInterface = {
  user_id: '',
  emp_id: null,
  user_mobile_no: '',
  working_hours: '',
  in: '',
  out: '',
  date: '',
  out_of_station: '',
  field_activity: '',
  on_leave: '',
  user_remarks: '',
  attendance_status: '',  // 0: rejected, 1: pending, 2: approved
  geo_location: '',
  remarks: '',
};

export const AttendanceSlice = createSlice({
  name: "Attendance",
  initialState: initialStateAttendance,
  reducers: {
    updateAttendance: (state, action: PayloadAction<Partial<AttendanceInterface>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateAttendance } = AttendanceSlice.actions;

export default AttendanceSlice.reducer;