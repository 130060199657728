import { Autocomplete, Box, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, InputAdornment, Typography, createFilterOptions, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useFieldArray } from 'react-hook-form'
import { ThemeTextField } from '../../components/inputs/theme-text-field'
import { DeleteOutlineRounded } from '@mui/icons-material'
import framerIllusion from '../../assets/images/illustartions/farmer.png'
import { FormBlockTitle, FormBlockTitle2 } from '../../components/title/FormBlockTitle'
import API from '../../api'
import toast from 'react-hot-toast'

function FarmerDetails({ farmerData, inputData, control, setValue, setError, clearErrors, errors, farmAddress, setAddress, sameAddress, setSameAddress, type }: any) {

    const theme = useTheme()

    const [searchedVillage, setSearchedVillage] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [checkingMobileNumber, setCheckingMobileNumber] = useState(false)

    const [subDistricts, setSubDistricts] = useState<any[]>([])
    const [fetchingSubDistricts, setFetchingSubDistricts] = useState(false)

    const { fields, remove, append } = useFieldArray({ name: "farms", control })
    const appendFarmRows = () => append(farmAddress)

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option: any) => `${option?.vil_town_name}${option?.subdistrict_name}`,
    });

    const fetchVillages = useCallback((village_name: string) => {
        setFetchingSubDistricts(true)
        API.get(`/fetch-villages`, { params: { village_name: village_name, } })
            .then((res) => {
                if (res.data?.statuscode === "200") {
                    setSubDistricts(res.data?.village_data)
                } else {
                    setSubDistricts([])
                }
            })
            .catch((err) => { toast.success("Oops! Something bad at our end") })
            .finally(() => {
                setFetchingSubDistricts(false)
            });
    }, [searchedVillage])

    const checkMobileNumber = useCallback((mobileNo: string) => {
        setCheckingMobileNumber(true)
        API.get(`/check-mobile`, { params: { mobile_no: mobileNo, } })
            .then((res) => {
                if (res.data?.statuscode !== "200") {
                    console.log('2')
                    setError('farmer_mobile_no', { type: 'custom', message: 'Number already in use' })
                } else {
                    console.log('1')
                    clearErrors('farmer_mobile_no')
                }
            })
            .catch((err) => { console.log("Oops! Something bad at our end") })
            .finally(() => {
                setCheckingMobileNumber(false)
            });
    }, [searchedVillage])

    useEffect(() => {
        searchedVillage.length == 3 &&
            fetchVillages(searchedVillage)
    }, [searchedVillage])

    useEffect(() => {
        mobileNumber.length == 10 &&
            checkMobileNumber(mobileNumber)
    }, [mobileNumber])



    return (
        <Box className={'animate__animated animate__fadeIn formbox'}>
            <Box
                sx={{
                    flex: { xs: '1 1 100%', sm: '1 1 40%' },
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: 2,
                    flexWrap: 'wrap',
                    order: { xs: 1, sm: 1 },

                    '& .formInput': {
                        flex: '0 1 100%',
                        minWidth: '100% !important',
                    }
                }}>
                <FormBlockTitle2 title='Basic Info' />


                {/* <Controller name={`companyName`}
                    control={control}
                    rules={{ required: { value: false, message: '' } }}
                    defaultValue={''}
                    render={({ field }) => (
                        <ThemeTextField
                            {...field} className={'formInput'}
                            error={Boolean(errors?.companyName)}
                            helperText={(errors?.companyName?.message ?? '').toString()}
                            size={'small'} label={'Company Name'}
                            placeholder={'compnay name'}
                        />
                    )} /> */}
                <Controller name={`farmer_name`}
                    control={control}
                    rules={{ required: { value: true, message: '' } }}
                    defaultValue={''}
                    render={({ field }) => (
                        <ThemeTextField
                            {...field} required className={'formInput'}
                            error={Boolean(errors?.farmer_name)}
                            helperText={(errors?.farmer_name?.message ?? '').toString()}
                            size={'small'} label={'Name'}
                            placeholder={'farmer_name'}
                        />
                    )} />
                <Controller name={`farmer_mobile_no`}
                    control={control}
                    rules={{
                        required: { value: true, message: '' },
                        pattern: { value: /^[6789]\d{9}$/, message: 'Invalid mobile number' },
                    }}
                    defaultValue={''}
                    render={({ field }) => (
                        <ThemeTextField
                            {...field} required className={'formInput'}
                            error={Boolean(errors?.farmer_mobile_no)}
                            helperText={(errors?.farmer_mobile_no?.message ?? '').toString()}
                            disabled={!!farmerData}
                            size={'small'} label={'Mobile'}
                            placeholder={'XXXX XXX XXX'}
                            onBlur={(e) => setMobileNumber(e.target.value)}
                            inputProps={{
                                maxLength: 10,
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{checkingMobileNumber && <CircularProgress size={'16px'} />}</InputAdornment>,
                            }}
                        />
                    )} />

            </Box>

            <Box sx={{
                flex: { xs: '1 1 100%', sm: '1 1 40%' },
                display: 'grid',
                placeItems: 'center',
                order: { xs: -1, sm: 2 },
                '& img': {
                    maxWidth: '240px',
                    aspectRatio: '1/1',
                    objectFit: 'contain',
                }
            }}>
                <img src={framerIllusion} alt={'framer illustion'} />
            </Box>

            <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                columnGap: 2,
                flexWrap: 'wrap',
                order: { xs: 3, sm: 3 },

            }}>
                <Box className={'formInputGroup'}>
                    <Box className={'groupTop'}>
                        <Box className={'titleBlock'}>
                            <FormBlockTitle title={`Residential Address`} icon={false} />
                        </Box>
                    </Box>
                    <Box className={'farmsInputArea'}>
                        <Controller name={`farmer_address`}
                            control={control}
                            rules={{ required: { value: true, message: '' } }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={''}
                                    error={Boolean(errors?.farmer_address)}
                                    helperText={(errors?.farmer_address?.message ?? '').toString()}
                                    size={'small'} label={'Address Line'}
                                    sx={{ flex: '3 1 400px' }}
                                    placeholder={'address line 1'}
                                />
                            )} />
                        <Controller name={`farmer_village`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            defaultValue={''}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete size={'small'}
                                    autoComplete
                                    className={'formItem'}
                                    onClose={() => {
                                        setSearchedVillage('')
                                        setSubDistricts([])
                                    }}
                                    onInputChange={(e, inputvalue) => {
                                        setSearchedVillage(inputvalue)
                                        inputvalue?.length <= 3 && setSubDistricts([])
                                    }}
                                    onChange={(e, data) => {
                                        onChange(data)
                                        setValue('farmer_district', `${data?.district_name ?? ''}`, { shouldDirty: true, shouldValidate: true });
                                        setValue('farmer_sub_district', `${data?.subdistrict_name ?? ''}`, { shouldDirty: true, shouldValidate: true });
                                        setValue('farmer_state', `${data?.state_name ?? ''}`, { shouldDirty: true, shouldValidate: true });
                                    }}
                                    filterOptions={filterOptions}
                                    noOptionsText={searchedVillage.length < 3 ? 'Min 3 characters required to search' : 'No Village Found'}
                                    value={value || null}
                                    loading={fetchingSubDistricts}
                                    sx={{ flex: '1 1 250px' }}
                                    options={subDistricts}
                                    renderOption={(props, option, { selected }) => (
                                        <Box component={'li'} {...props} key={props.id}
                                            sx={{
                                                p: '2px 6px',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                justifyContent: 'flex-start',
                                                flexFlow: 'column',
                                                textAlign: 'left',
                                                gap: '2px',
                                                borderRadius: '12px',
                                                mx: '2px',
                                                '& .village': {
                                                    width: '100%',
                                                    fontSize: '15px',
                                                    lineHeight: '15px',
                                                    fontWeight: 500,
                                                    color: theme.palette.text.secondary,
                                                },
                                                '& .state': {
                                                    width: '100%',
                                                    fontSize: '12px',
                                                    lineHeight: '12px',
                                                    fontWeight: 500,
                                                    color: theme.palette.text.disabled,
                                                },
                                            }}>
                                            <Typography className={'village'}>
                                                {option?.vil_town_name}, {option?.subdistrict_name}
                                            </Typography>
                                            <Typography className={'state'}>
                                                {option?.district_name}, {option?.state_name}
                                            </Typography>
                                        </Box>

                                    )}
                                    getOptionLabel={(option) => option.vil_town_name ?? ''}
                                    renderInput={(params) => (
                                        <ThemeTextField
                                            {...params} required
                                            error={Boolean(errors?.farmer_village)}
                                            helperText={(errors?.farmer_village?.message ?? '').toString()}
                                            size={'small'} label={'Village/ Town'}
                                            placeholder={'Village/ Town'}
                                        />
                                    )} />
                            )} />
                        <Controller name={`farmer_sub_district`}
                            control={control}
                            rules={{ required: { value: true, message: '' } }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'} disabled
                                    error={Boolean(errors?.farmer_sub_district)}
                                    helperText={(errors?.farmer_sub_district?.message ?? '').toString()}
                                    size={'small'} label={'Sub District'}
                                    placeholder={'sub District'}
                                />
                            )} />
                        <Controller name={`farmer_district`}
                            control={control}
                            rules={{ required: { value: true, message: '' } }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.farmer_district)} disabled
                                    helperText={(errors?.farmer_district?.message ?? '').toString()}
                                    size={'small'} label={'District'}
                                    placeholder={'District'}
                                />
                            )} />
                        <Controller name={`farmer_state`}
                            control={control}
                            rules={{ required: { value: true, message: '' } }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.farmer_state)} disabled
                                    helperText={(errors?.farmer_state?.message ?? '').toString()}
                                    size={'small'} label={'State'}
                                    placeholder={'State name'}
                                />
                            )} />
                        <Controller name={`farmer_pincode`}
                            control={control}
                            rules={{
                                required: { value: true, message: '' },
                                pattern: { value: /^[1-9][0-9]{5}$/, message: 'Invalid pincode' },
                            }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    type={'number'}
                                    error={Boolean(errors?.farmer_pincode)}
                                    helperText={(errors?.farmer_pincode?.message ?? '').toString()}
                                    size={'small'} label={'Pincode'}
                                    placeholder={'XXXXXX'}
                                />
                            )} />
                    </Box>

                </Box>
            </Box>

            <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                columnGap: 2,
                flexWrap: 'wrap',
                order: { xs: 4, sm: 4 },
            }}>
                <Box className={'farmAddresses'}>
                    {fields.map((field, index) => {
                        return (
                            <Box className={'formInputGroup'} key={field.id}>
                                <Box className={'groupTop'}>
                                    <Box className={'titleBlock'}>
                                        <FormBlockTitle title={`Farm ${index + 1}`} icon={false} />
                                        {/* <input type='hidden' {...register(`farms.${index}.field_area`)} value={`Farm ${index + 1}`} /> */}
                                    </Box>
                                    <Box className={'actionBlock'}>
                                        {index == 0 && type != 'edit' &&
                                            <FormControlLabel disabled={!inputData?.farmer_address || !inputData?.farmer_village || !inputData?.farmer_sub_district || !inputData?.farmer_district || !inputData?.farmer_state || !inputData?.farmer_pincode}
                                                control={<Checkbox size='small' checked={sameAddress} onChange={setAddress} />}
                                                label="Same as residential" />
                                        }
                                        {(fields.length > 1 && (farmerData ? index >= farmerData?.farm_info?.length : true)) &&
                                            <IconButton
                                                size={'small'}
                                                color={'error'}
                                                onClick={() => {
                                                    remove(index)
                                                    if (index == 0) setSameAddress(false)
                                                }}
                                            >
                                                <DeleteOutlineRounded />
                                            </IconButton>
                                        }
                                    </Box>
                                </Box>
                                <Box className={'farmsInputArea'}>
                                    <Controller name={`farms.${index}.address`}
                                        control={control}
                                        rules={{ required: { value: true, message: '' } }}
                                        defaultValue={''}
                                        render={({ field }) => (
                                            <ThemeTextField
                                                {...field} required className={''}
                                                error={Boolean(errors?.farms?.[index]?.address)}
                                                helperText={(errors?.farms?.[index]?.address?.message ?? '').toString()}
                                                size={'small'} label={'Address Line'}
                                                sx={{ flex: '3 1 400px' }}
                                                placeholder={'address line 1'}
                                            />
                                        )} />
                                    <Controller name={`farms.${index}.village`}
                                        control={control}
                                        rules={{ required: { value: true, message: 'Required' } }}
                                        defaultValue={''}
                                        render={({ field: { onChange, value } }) => (
                                            <Autocomplete size={'small'}
                                                autoComplete
                                                disabled={sameAddress && index == 0}
                                                className={'formItem'}
                                                onClose={() => {
                                                    setSearchedVillage('')
                                                    setSubDistricts([])
                                                }}
                                                onInputChange={(e, inputvalue) => {
                                                    setSearchedVillage(inputvalue)
                                                    inputvalue?.length <= 3 && setSubDistricts([])
                                                }}
                                                onChange={(e, data) => {
                                                    onChange(data)
                                                    setValue(`farms.${index}.district`, `${data?.district_name ?? ''}`, { shouldDirty: true, shouldValidate: true });
                                                    setValue(`farms.${index}.sub_district`, `${data?.subdistrict_name ?? ''}`, { shouldDirty: true, shouldValidate: true });
                                                    setValue(`farms.${index}.state`, `${data?.state_name ?? ''}`, { shouldDirty: true, shouldValidate: true });
                                                }}
                                                noOptionsText={searchedVillage.length < 3 ? 'Min 3 characters required to search' : 'No Village Found'}
                                                value={value || null}
                                                loading={fetchingSubDistricts}
                                                sx={{ flex: '1 1 250px' }}
                                                options={subDistricts}
                                                renderOption={(props, option, { selected }) => (
                                                    <Box component={'li'} {...props} key={props.id}
                                                        sx={{
                                                            p: '2px 6px',
                                                            display: 'flex',
                                                            alignItems: 'flex-start',
                                                            justifyContent: 'flex-start',
                                                            flexFlow: 'column',
                                                            textAlign: 'left',
                                                            gap: '2px',
                                                            borderRadius: '12px',
                                                            mx: '2px',
                                                            '& .village': {
                                                                width: '100%',
                                                                fontSize: '15px',
                                                                lineHeight: '15px',
                                                                fontWeight: 500,
                                                                color: theme.palette.text.secondary,
                                                            },
                                                            '& .state': {
                                                                width: '100%',
                                                                fontSize: '12px',
                                                                lineHeight: '12px',
                                                                fontWeight: 500,
                                                                color: theme.palette.text.disabled,
                                                            },
                                                        }}>
                                                        <Typography className={'village'}>
                                                            {option?.vil_town_name}, {option?.subdistrict_name}
                                                        </Typography>
                                                        <Typography className={'state'}>
                                                            {option?.district_name}, {option?.state_name}
                                                        </Typography>
                                                    </Box>

                                                )}
                                                getOptionLabel={(option) => option.vil_town_name ?? ''}
                                                renderInput={(params) => (
                                                    <ThemeTextField
                                                        {...params} required
                                                        error={Boolean(errors?.farms?.[index]?.village)}
                                                        helperText={(errors?.farms?.[index]?.village?.message ?? '').toString()}
                                                        size={'small'} label={'Village/ Town'}
                                                        placeholder={'Village/ Town'}
                                                    />
                                                )} />
                                        )} />
                                    <Controller name={`farms.${index}.sub_district`}
                                        control={control}
                                        rules={{ required: { value: true, message: '' } }}
                                        defaultValue={''}
                                        render={({ field }) => (
                                            <ThemeTextField
                                                {...field} required className={'formInput'} disabled
                                                error={Boolean(errors?.farms?.[index]?.sub_district)}
                                                helperText={(errors?.farms?.[index]?.sub_district?.message ?? '').toString()}
                                                size={'small'} label={'Sub District'}
                                                placeholder={'Sub District'}
                                            />
                                        )} />
                                    <Controller name={`farms.${index}.district`}
                                        control={control}
                                        rules={{ required: { value: true, message: '' } }}
                                        defaultValue={''}
                                        render={({ field }) => (
                                            <ThemeTextField
                                                {...field} required className={'formInput'} disabled
                                                error={Boolean(errors?.farms?.[index]?.district)}
                                                helperText={(errors?.farms?.[index]?.district?.message ?? '').toString()}
                                                size={'small'} label={'District'}
                                                placeholder={'District'}
                                            />
                                        )} />
                                    <Controller name={`farms.${index}.state`}
                                        control={control}
                                        rules={{ required: { value: true, message: '' } }}
                                        defaultValue={''}
                                        render={({ field }) => (
                                            <ThemeTextField
                                                {...field} required className={'formInput'} disabled
                                                error={Boolean(errors?.farms?.[index]?.state)}
                                                helperText={(errors?.farms?.[index]?.state?.message ?? '').toString()}
                                                size={'small'} label={'State'}
                                                placeholder={'State name'}
                                            />
                                        )} />
                                    <Controller name={`farms.${index}.pin_code`}
                                        control={control}
                                        rules={{
                                            required: { value: true, message: '' },
                                            pattern: { value: /^[1-9][0-9]{5}$/, message: 'Invalid pincode' },
                                        }}
                                        defaultValue={''}
                                        render={({ field }) => (
                                            <ThemeTextField
                                                {...field} required className={'formInput'}
                                                type={'number'}
                                                disabled={sameAddress && index == 0}
                                                error={Boolean(errors?.farms?.[index]?.pin_code)}
                                                helperText={(errors?.farms?.[index]?.pin_code?.message ?? '').toString()}
                                                size={'small'} label={'Pincode'}
                                                placeholder={'XXXXXX'}
                                            />
                                        )} />
                                    <Controller name={`farms.${index}.acerage`}
                                        control={control}
                                        rules={{
                                            required: { value: true, message: '' },
                                            min: { value: 0.1, message: 'Add at least 1 acreage' },
                                            max: { value: 1000, message: 'max 1000 acreages' },
                                        }}
                                        defaultValue={''}
                                        render={({ field }) => (
                                            <ThemeTextField
                                                {...field}
                                                required
                                                onBlur={(e) => {
                                                    setValue(`farms.${index}.acerage`, `${parseFloat((+e.target.value).toFixed(2))}`)
                                                }}
                                                className={'formInput'} type={'number'}
                                                error={Boolean(errors?.farms?.[index]?.acerage)}
                                                helperText={(errors?.farms?.[index]?.acerage?.message ?? '').toString()}
                                                size={'small'} label={'Acerage'}
                                                placeholder={'eg. 12.00'}
                                            />
                                        )} />
                                </Box>

                            </Box>
                        )
                    })}
                </Box>
            </Box>

            <Button
                size={'small'}
                onClick={appendFarmRows}
                sx={{
                    mx: 'auto',
                    order: { xs: 5, sm: 5 },

                }}>
                Add Farm
            </Button>

        </Box>
    )
}

export default FarmerDetails
