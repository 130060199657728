import React, { useState } from 'react'
import { Box, IconButton, InputAdornment } from "@mui/material"
import { EmailRounded, KeyRounded, Visibility, VisibilityOff } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { useDispatch } from "react-redux"
import { Controller, useForm } from "react-hook-form"

import LoadingView from '../../components/loading-view'
import { ThemeTextField } from "../../components/inputs/theme-text-field";
import API from "../../api";
import { updateAuth } from "../../slices/auth-slice";
import toast from 'react-hot-toast'
import { updateProcessState } from '../../slices/process-slice'
import { User } from '../../models/user'
import { useNavigate } from 'react-router'


export default function Login() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { handleSubmit, control, formState: { errors } } = useForm()
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState<boolean>(false)

    const togglePassword = () => setShowPassword((prevState => !prevState))

    const onSubmit = (data: any) => {
        setSubmitting(true)
        dispatch(updateProcessState(true))

        API.post(`/login`, { ...data })
            .then((res) => {
                if (res.data?.accessToken) {
                    const userInfo = res.data?.user
                    document.cookie = `accessToken=${res.data?.accessToken}`
                    dispatch(updateAuth({ isLoggedIn: true, currentUser: res.data?.user as User }))
                    toast.success(`Welcome ${res.data?.user ? res.data?.user?.name : 'Buddy!!'}`,)
                } else if (res.data?.statuscode == 403) {
                    toast.error(`Errro! ${res.data?.msg}`,)
                } else {
                    toast.error(`Errro! Invalid Credentials`)
                    // const errors = res?.data?.errors
                    // Object.keys(errors).forEach(key => {
                    //     if (['email', 'password'].includes(key)) {
                    //         setError(key, { message: errors[key]?.[0] })
                    //         delete errors[key]
                    //     }
                    // })
                }
            })
            .catch((err) => {
                toast.error(`Something bad at our end.\n Please try again`,)
            }).finally(() => {
                setSubmitting(false)
                dispatch(updateProcessState(false))
            })
    }


    return (
        <Box className={'formBlock animate__animated animate__fadeIn'}>
            <Box className={'formContainer'}>
                <Controller
                    name={'email'}
                    control={control}
                    rules={{
                        required: { value: true, message: 'Required' },
                        // pattern: { value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, message: 'Invalid email' },
                    }}
                    defaultValue={''}
                    render={({ field }) => (
                        <ThemeTextField
                            {...field} autoFocus required
                            error={Boolean(errors?.email)}
                            helperText={(errors?.email?.message ?? '').toString()}
                            className={'animate__animated animate__fadeInUp'}
                            size={'small'}
                            placeholder={'email@your.domain'}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailRounded />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )} />
                <Controller
                    name={'password'}
                    control={control}
                    rules={{
                        required: { value: true, message: 'Required' },
                    }}
                    defaultValue={''}
                    render={({ field }) => (
                        <ThemeTextField
                            type={showPassword ? 'text' : 'password'}
                            {...field} required size={'small'}
                            error={Boolean(errors?.password)}
                            helperText={(errors?.password?.message ?? '').toString()}
                            placeholder={'********'}
                            className={'animate__animated animate__fadeInUp'}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start"><KeyRounded /></InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={togglePassword}
                                            onMouseDown={togglePassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )} />
                <LoadingButton
                    className={'buttonGradient animate__animated animate__fadeInUp'}
                    variant={'contained'}
                    size="small"
                    onClick={handleSubmit(onSubmit)}
                    type={'submit'}
                    loading={submitting}
                    endIcon={<></>}
                    loadingPosition="end">
                    Login
                </LoadingButton>

                {/*<Typography variant={'caption'} sx={{mt: 1}}>Forgot my password</Typography>*/}

            </Box>
            {submitting && <LoadingView />}
        </Box>
    )
}