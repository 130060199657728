import { AppBar, Box, Paper, Toolbar, useTheme } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router'
import logo from "../assets/images/app-icons/agri-wing-logo.svg";
import OperatorNavigation from './operator-navigation/operator-navigation';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import LoadingView from '../components/loading-view';
import { useAttendance } from '../hooks/useAttendance';



function OperatorLayout() {

    const theme = useTheme()
    const location = useLocation()

    const processState = useSelector((state: RootState) => state.processState.working)
    const { fetchAttendance } = useAttendance()

    useEffect(() => {
        fetchAttendance()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])


    window.onbeforeunload = function () {
        if (processState) return "Changes made may not reflect"
    }

    return (
        <Box data-owner={'Amit Thakur'}
            component={'main'}
            className={'thakuramit962'} sx={{
                flexGrow: 1,
                display: 'flex',
                flexFlow: 'column',
                // height: '100vh',
                px: { xs: 0, md: '0.5rem' },
                transition: 'all 300ms ease-in-out',
                '& .contentArea': {
                    flex: 1,
                    maxWidth: '100%',
                    overflow: 'auto',
                    borderRadius: { xs: '12px 12px 24px 24px', md: `20px` },
                    background: theme.palette.background.paper,
                    boxShadow: 0,
                    // boxShadow: `0 0 12px -3px ${theme.palette.mode == 'dark' ? '#83838380' : '#69696980'} inset`,
                    p: { xs: 0, md: 1 },
                    display: 'flex',
                    flexFlow: 'column',
                    alignItems: 'stretch',
                },
            }}>
            <OperatorTopbar />
            <Toolbar />
            <Paper className={'contentArea'}>
                <Box id={'scrollContainer'}
                    sx={{
                        overflow: 'auto',
                    }}>
                    <Box
                        component={'section'}
                        sx={{
                            flex: 1,
                            px: { xs: 2, md: 3 },
                            pb: 3, pt: 1,
                            '& .pageTitle': {
                                fontSize: '1.2rem',
                                fontWeight: 600,
                                position: 'relative',
                                background: theme.palette.mode == 'dark' ? theme.palette.primary.main : `-webkit-linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                width: 'max-content',
                            },
                        }}>
                        <Outlet />
                    </Box>
                </Box>
            </Paper>
            <Toolbar />
            <OperatorNavigation />
            {processState && <LoadingView />}
        </Box>
    )
}

const OperatorTopbar = () => {
    const theme = useTheme()
    const navigate = useNavigate()
    return (
        <AppBar
            position="fixed"
            sx={{
                zIndex: theme.zIndex.drawer + 1,
                color: theme.palette.text.primary,
                background: theme.palette.background.paper,
                boxShadow: 0,
            }}>
            <Toolbar>
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& .logoImage': {
                        maxHeight: '40px',
                        objectFit: 'contain',
                    },
                }}>
                    <img onClick={() => navigate(('/'))} className={'logoImage'} src={theme.palette.mode == 'dark' ? logo : logo} alt={'hey-there-logo'} />
                </Box>

                {/* <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                }}>  <ThemeColorToggle />

                </Box> */}
            </Toolbar>
        </AppBar>
    )
}

export default OperatorLayout