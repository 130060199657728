import React, { useCallback, useEffect, useState } from 'react'
import {
    Box,
    Button,
    Fab,
    IconButton,
    Paper,
    Tooltip,
    Typography,
    alpha,
    useTheme,
    useThemeProps,
} from "@mui/material"
import { AttachmentRounded, CloudDownloadRounded, EventRounded, NewReleasesRounded, PhoneOutlined, SyncRounded, TaskAltRounded, TodayOutlined, TodayRounded } from "@mui/icons-material"
import API from "../../../api"
import PageContainer from '../../../components/container/page-container'
import TitleBar from '../../../components/container/TitleBar'
import NoDataView from '../../../components/theme-table-container/NoData-'
import ThemeDialog from '../../../components/dialog-box/theme-dialog'
import { ThemeTextField, themeTextFieldSx } from '../../../components/inputs/theme-text-field'
import { useDispatch } from 'react-redux'
import { updateProcessState } from '../../../slices/process-slice'
import moment, { Moment } from 'moment'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import LoadingView from '../../../components/loading-view'
import { currencyFormatter, downloadFile, s3BucketUrl, serverRoute, terCategories } from '../../../utils/app-helper'
import { useNavigate } from 'react-router'
import toast from 'react-hot-toast'
import ThemeFab from '../../../components/buttons/theme-fab'
import removeTerIllustration from '../../../assets/images/illustartions/remove_ter.svg'
import approveTerIllustration from '../../../assets/images/illustartions/approve-ter.svg'
import FloatingAlert from '../../../components/floating-alert/floating-alert'
import { FormBlockTitle } from '../../../components/title/FormBlockTitle'


export default function TerList() {

    const theme = useTheme()
    const dispatch = useDispatch()

    const [forMonth, setForMonth] = useState<Moment | null>(moment())

    const [terList, setTerList] = useState<any[]>([])
    const [fetchingTerList, setFetchingTerList] = useState(false)

    const [selectedTer, setSelectedTer] = useState<any | null>(null)
    const [dialogType, setDialogType] = useState<'accept' | 'reject' | 'view'>('view')

    const closeDialog = () => {
        setDialogType('view')
        setSelectedTer(null)
    }


    const fetchTerList = useCallback(() => {
        dispatch(updateProcessState(true))
        setFetchingTerList(true)
        API.post('/get_ter_list', {
            from_date: `${moment(forMonth).startOf('month').format('YYYY-MM-DD')}`,
            to_date: `${moment(forMonth).endOf('month').format('YYYY-MM-DD')}`,
            user_id: ''
        }).then((res) => {
            if (res.data?.statuscode === "200") {
                setTerList(res.data?.data || []);
            } else {
                console.error("Failed to fetch ter :", res.data?.message || "Unknown error")
            }
        }).catch(err => console.log(err))
            .finally(() => {
                dispatch(updateProcessState(false))
                setFetchingTerList(false)

            })
    }, [forMonth])

    const downloadTerList = () => {
        const link = document.createElement("a");
        link.href = `${serverRoute}/download_ter_list?from_date=${moment(forMonth).startOf('month').format('YYYY-MM-DD')}&to_date=${moment(forMonth).endOf('month').format('YYYY-MM-DD')}`
        link.target = "_blank"
        link.setAttribute("download", 'ter.csv')
        document.body.appendChild(link)
        link.click()
        link.parentNode?.removeChild(link) // Clean up and remove the link
        window.close()
    }

    return (
        <PageContainer>
            <TitleBar title={'TER List'}
                secondChild={
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,

                    }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <MobileDatePicker
                                views={['month', 'year']}
                                value={forMonth}
                                onChange={(newValue) => setForMonth(newValue)}
                                format='MMM, YYYY'
                                disableFuture
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        required: true,
                                        sx: themeTextFieldSx,
                                        style: { width: 'min(100%, 160px)' },
                                    }
                                }}
                            />
                        </LocalizationProvider>
                        <IconButton
                            sx={{ color: theme.palette.primary.contrastText }}
                            className={'buttonGradient animate__animated animate__fadeInUp'}
                            onClick={() => {
                                fetchTerList()
                            }}
                        >
                            <SyncRounded />
                        </IconButton>
                    </Box>}
            />
            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    position: 'relative',
                    '& .buttonGradient': {
                        backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        outline: `0px solid ${theme.palette.primary.main}`,
                        outlineOffset: 0,
                        boxShadow: 0,
                        textTransform: 'none',
                        transition: 'all 100ms ease',
                        '&:hover': {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: '2px',
                            '&:active': {
                                transform: 'scale(0.98) !important'
                            }
                        },
                    },

                }}>
                {fetchingTerList
                    ? <LoadingView />
                    : terList?.length > 0
                        ? <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: { xs: '1fr', sm: 'repeat(auto-fit, minmax(320px, 1fr))' },
                            gap: 2,
                            pb: '72px',
                        }}>
                            {terList?.map((ter, index) => <TerListItem
                                key={index}
                                ter={ter}
                                setSelectedTer={setSelectedTer}
                                setDialogType={setDialogType}
                            />)}
                        </Box>
                        : <NoDataView />
                }

                <Tooltip title={'Download TER List'} arrow>
                    <Fab
                        variant="extended"
                        onClick={downloadTerList}
                        sx={{
                            position: 'fixed',
                            bottom: 32,
                            right: 32,
                            cursor: 'pointer',
                            background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                            boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                            border: `1px solid ${theme.palette.background.paper}`,
                            color: theme.palette.primary.main,
                            gap: 1,
                            textTransform: 'none',
                        }}>
                        <CloudDownloadRounded />
                    </Fab>
                </Tooltip>
            </Box>

            {(!!selectedTer && dialogType == 'view') &&
                <ThemeDialog
                    open={(!!selectedTer && dialogType == 'view')}
                    onClickClose={closeDialog}
                    title={`${selectedTer?.asset_operator?.name} - ${moment(selectedTer?.from_date, 'YYYY-MM-DD').format('MMMM, YYYY')}`}
                    dialogBody={<ReimbursementItems selectedTer={selectedTer} />}
                />
            }
            {(!!selectedTer && dialogType != 'view') &&
                <ThemeDialog
                    open={dialogType == 'accept' || dialogType == 'reject'}
                    dialogBody={<UpdateDialog
                        closeDialog={closeDialog}
                        dialogType={dialogType}
                        selectedTer={selectedTer}
                        fetchTerList={fetchTerList}
                    />}
                />
            }

        </PageContainer >
    )
}


const TerListItem = (props: any) => {

    const { ter, setSelectedTer, setDialogType } = props

    const theme = useTheme()

    return (
        <Paper
            onClick={() => {
                setDialogType('view')
                setSelectedTer({ ...ter })
            }}
            sx={{
                p: 2,
                borderRadius: '28px',
                boxShadow: 0,
                outline: `1px solid ${alpha(theme.palette.text.disabled, 0.05)}`,
                flex: '1',
                minHeight: '100px',
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                transition: 'all 0.2s cubic-bezier(.58,.23,.33,1.49)',
                cursor: 'pointer',
                '&:hover': {
                    outline: `2px solid ${alpha(theme.palette.primary.main, 1)}`,
                    boxShadow: `0 0 17px -3px inset ${alpha(theme.palette.primary.main, 0.8)}`,
                    outlineOffset: '4px',
                },
            }}>
            <Box sx={{
                flex: 1,
                '& .chip': {
                    background: alpha(theme.palette.text.secondary, 0.05),
                    borderRadius: '50vh',
                    p: '0 8px 0 6px',
                    fontSize: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.3,
                    color: theme.palette.text.secondary,
                    width: 'max-content',
                    mb: 1,
                    '& svg': {
                        fontSize: '12px',
                    },
                },
                '& .name': {
                    fontSize: '16px',
                    fontWeight: 500,
                },
                '& .phone': {
                    display: 'flex',
                    alignItems: 'center',
                    color: theme.palette.text.secondary,
                    width: 'max-content',
                    fontSize: '12px',
                    fontWeight: 500,
                    textDecoration: 'none',
                    '& svg': {
                        fontSize: '14px',
                    },
                    '&:hover': {
                        color: theme.palette.primary.main,

                    },
                },
            }}>
                <Typography className='chip'>{ter?.asset_operator?.code}</Typography>
                <Typography className='name'>{ter?.asset_operator?.name ?? '-'}</Typography>
                <Typography className='phone' component={'a'} href={`tel:+91${ter?.asset_operator?.phone}`} onClick={(e) => e.stopPropagation()}>
                    <PhoneOutlined /> +91 {ter?.asset_operator?.phone ?? '-'}
                </Typography>
                <Typography className='phone'>
                    <TodayOutlined /> &nbsp;&nbsp; {moment(ter.from_date, 'YYYY-MM-DD').format('MMMM, YYYY')}
                </Typography>

            </Box>
            <Box
                sx={{
                    display: 'grid',
                    justifyContent: 'center',
                    justifyItems: 'center',
                    gap: 1,
                    '& .amount': {
                        fontWeight: 500,
                        color: ter?.status == '3' ? theme.palette.error.main
                            : ter?.status == '4' ? theme.palette.success.main
                                : theme.palette.warning.main,

                    },
                    '& .MuiButton-root': {
                        textTransform: 'none',
                        boxShadow: `0 0 22px -3px ${alpha(theme.palette.success.main, 0.5)} inset`,
                        outline: `2px solid ${alpha(theme.palette.success.main, 0.5)}`,
                        p: '0 12px',
                        minWidth: '100px',
                        color: theme.palette.success.main,
                        '&.reject': {
                            boxShadow: `0 0 22px -3px ${alpha(theme.palette.error.main, 0.5)} inset`,
                            outline: `2px solid ${alpha(theme.palette.error.main, 0.5)}`,
                            color: theme.palette.error.main
                        },
                    },
                    '& .statusBlock': {
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                        color: ter?.status == '3' ? theme.palette.error.main
                            : ter?.status == '4' ? theme.palette.success.main
                                : theme.palette.warning.main,
                        fontSize: '14px',
                        fontWeight: 500,
                        '& svg': {
                            fontSize: '16px',

                        },
                    },
                }}>
                <Typography className='amount'>{currencyFormatter(+ter?.total_claimed_amount ?? 0)}</Typography>

                {ter?.status == '3'
                    ? <Box className={'statusBlock'}>
                        Rejected <Tooltip title={ter?.remarks ?? ''}><NewReleasesRounded /></Tooltip>
                    </Box>
                    : ter?.status == '2'
                        ? <Box className={'statusBlock'}>
                            <TaskAltRounded /> Approved
                        </Box>
                        : <>
                            <Button size='small' className='accept' fullWidth
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setDialogType('accept')
                                    setSelectedTer({ ...ter })
                                }}>
                                Approve
                            </Button>
                            <Button size='small' className='reject' fullWidth
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setDialogType('reject')
                                    setSelectedTer({ ...ter })
                                }}>
                                Reject
                            </Button>
                        </>
                }
            </Box>
        </Paper >
    )
}


const ReimbursementItems = (props: any) => {

    const { selectedTer } = props

    const theme = useTheme()
    const navigate = useNavigate()

    return (
        <Box sx={{
            pt: 3,
            width: 'min(75vw, 800px)',
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', sm: 'repeat(auto-fit, minmax(300px, 1fr))' },
            gap: 2,
        }}>
            {selectedTer?.operator_reimbursement?.map((item: any, index: number) => (
                <Box key={index} className={'animate__animated animate__fadeIn'}
                    sx={{
                        textAlign: 'left',
                        width: '100%',
                        p: 1,
                        display: 'flex',
                        flexFlow: 'column',
                        alignItems: 'flex-start',
                        gap: 0.5,
                        position: 'relative',
                        borderRadius: '18px',
                        outline: `1px solid ${alpha(theme.palette.text.disabled, 0.05)}`,
                        boxShadow: 0,
                        transition: 'all 0.2s cubic-bezier(.58,.23,.33,1.49)',
                        '&:hover': {
                            outline: `2px solid ${alpha(theme.palette.primary.main, 1)}`,
                            boxShadow: `0 0 17px -10px inset ${alpha(theme.palette.primary.main, 0.8)}`,
                            outlineOffset: '4px',
                        },
                        '& *': {
                            fontSize: '0.8rem',
                        },
                        '& .category': {
                            borderRadius: '8px',
                            background: item?.status == '4' ? theme.palette.error.main : theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            fontSize: '0.7rem',
                            p: '0 8px',
                            mb: 0.5,
                        },
                        '& .billNo': {
                            color: theme.palette.primary.main,
                            fontSize: '16px',
                            fontWeight: 600,
                            mb: 0.5,
                        },
                        '& .date': {
                            color: theme.palette.text.secondary,
                            fontSize: '0.8rem',
                            lineHeight: '0.9rem',
                            fontWeight: 500,
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            '& svg': {
                                fontSize: '0.85rem',
                                mb: '-2px',
                                mr: '4px',
                            },
                        },
                        '& .remarks': {
                            background: alpha(theme.palette.text.disabled, 0.03),
                            color: theme.palette.text.secondary,
                            px: 1, py: 0.5,
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            maxWidth: '90%',
                            fontSize: '0.7rem',
                            borderRadius: '12px',
                        },
                    }}>
                    <Typography className={'category'}>{terCategories.filter(el => el.id == item?.category)[0].name}</Typography>
                    <Typography className={'date'}>
                        <span><TodayRounded />{moment(item?.from_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}</span>
                        -
                        <span><TodayRounded />{moment(item?.to_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}</span>
                    </Typography>
                    <Typography className={'date'}>
                        Bill Amount: {currencyFormatter(+item?.bill_amount)}
                    </Typography>

                    <Tooltip arrow title={item?.remarks} placement='top'>
                        <Typography className={'remarks'}>
                            Remarks: {item?.remarks}
                        </Typography>
                    </Tooltip>

                    <Typography sx={{
                        position: 'absolute',
                        top: '0.5rem',
                        right: '0.5rem',
                        color: theme.palette.warning.main,
                        fontSize: '1.1rem',
                        lineHeight: '1.1rem',

                    }}>{currencyFormatter(+item?.claimed_amount)}</Typography>

                    <Tooltip arrow placement='top' title={'View Attachment'}>
                        <IconButton href={`${s3BucketUrl}/reimburse/${item?.attachment}`} target='_blank'
                            // onClick={(e: any) => {
                            //     e.stopPropagation()
                            //     navigate(`${s3BucketUrl}/reimburse/${item?.attachment}`)
                            // }}
                            sx={{
                                zIndex: 0,
                                position: 'absolute',
                                bottom: '8px',
                                right: '8px',
                                height: '24px',
                                width: '24px',
                                color: theme.palette.info.main,
                                '& svg': {
                                    height: '16px',
                                    width: '16px',
                                },
                            }}>
                            <AttachmentRounded />
                        </IconButton>
                    </Tooltip>
                </Box>
            ))}
        </Box>
    )
}


const UpdateDialog = (props: any) => {

    const { closeDialog, dialogType, selectedTer, fetchTerList } = props

    const theme = useTheme()
    const dispatch = useDispatch()

    const [remarks, setRemarks] = useState('')

    const onsubmit = () => {

        const data = {
            id: `${selectedTer?.id}`,
            status: dialogType == 'reject' ? '3' : '2',
            remarks,
        }

        console.log(data)
        dispatch(updateProcessState(true))
        API.post('/update_ter_details', data)
            .then((res) => {
                if (res.data?.status == 'success') {
                    toast.success(`Success! TER request ${dialogType == 'accept' ? 'approved' : 'rejected'}.`)
                    fetchTerList()
                    closeDialog()
                } else if (res.data?.status == 'error') {
                    toast.error(`${res.data?.msg}`)
                } else {
                    toast.error('Oops! Something bad happened.')
                }
            })
            .catch((err) => {
                console.error(err)
            })
            .finally(() => {
                dispatch(updateProcessState(false))
            })
    }

    return (
        <Box
            sx={{
                width: `min(70vw, 350px)`,
                textAlign: 'center',

                '& img': {
                    width: 'min(65%, 160px)',
                    objectFit: 'contain',
                    mx: 'auto',
                    mb: 1,
                },
                '& .removeButton': {
                    textTransform: 'none',

                },
            }}
        >
            <Typography sx={{
                fontSize: 'clamp(1rem, 0.875rem + 1vw, 1.25rem)',
                fontWeight: 500,
                fontFamily: theme.typography.h1.fontFamily,
                mb: 4,
            }}>
                {dialogType == 'accept' ? 'Approve TER' : 'Reject TER'}
            </Typography>
            <img className={'animate__animated animate__fadeInUp'} src={dialogType == 'accept' ? approveTerIllustration : removeTerIllustration} alt={'s'} />
            <Typography className={'animate__animated animate__fadeInUp'} sx={{
                fontSize: 'clamp(0.6875rem, 0.5938rem + 0.75vw, 0.875rem)',
                mb: 2,
            }}>
                {dialogType == 'accept'
                    ? `You are approving a amount of ${currencyFormatter(+selectedTer?.total_claimed_amount)} to ${selectedTer?.asset_operator?.name}. Have you checked all reimbursement items?`
                    : `Are you sure you to reject this reimbursemnet request of amount ${currencyFormatter(+selectedTer?.total_claimed_amount)} from ${selectedTer?.asset_operator?.name}?`
                }

            </Typography>

            {dialogType == 'reject' &&
                <Box sx={{ mb: 3, }}
                    className={'animate__animated animate__fadeInUp'}>
                    <ThemeTextField
                        size={'small'}
                        label={'Rejection Remarks'}
                        placeholder='Rejection remarks ...'
                        multiline rows={2}
                        value={remarks}
                        onChange={(e) => setRemarks(e.target?.value)}
                    />
                </Box>
            }

            <Button className={'removeButton'} variant='contained' fullWidth
                color={dialogType == 'accept' ? 'warning' : 'error'}
                disabled={dialogType == 'reject' && remarks?.length < 1}
                onClick={onsubmit}>
                {dialogType == 'accept' ? 'Yeah, Approve it' : 'Sure, Reject it '}
            </Button>
            <Button className={'removeButton'} fullWidth color={'info'} onClick={closeDialog}>
                Close
            </Button>
        </Box >
    )
}