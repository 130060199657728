import { Box, Button, Chip, ClickAwayListener, Dialog, Grow, IconButton, ListItem, MenuItem, MenuList, Paper, Popper, Tab, Tabs, Typography, alpha, useTheme } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { LoadingButton } from '@mui/lab'
import { useDispatch, useSelector } from 'react-redux'
import { updateProcessState } from '../../../slices/process-slice'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import API from '../../../api'
import NoDataView from '../../../components/noDataView'
import { ThemeTextField } from '../../../components/inputs/theme-text-field'
import { FilterList, FilterListOff } from '@mui/icons-material'
import { FormBlockTitle } from '../../../components/title/FormBlockTitle'
import { RootState } from '../../../store/store'

function OperatorServiceList() {

    const theme = useTheme()
    const dispatch = useDispatch()
    const [serviceType, setServiceType] = useState('0')
    const [fetching, setFecthing] = useState(true)
    const [allotedServices, setAllotedServices] = useState<any[]>([])
    const [acceptedServices, setAcceptedServices] = useState<any[]>([])

    const [showStatus, setShowStatus] = useState<null | '3' | '4' | '5' | '6' | '0'>(null)
    const [searchString, setSearchString] = useState<string>('')

    const changeServiceType = (event: React.SyntheticEvent, newValue: string) => setServiceType(newValue)

    const fetchServices = () => {
        setFecthing(true)
        dispatch(updateProcessState(true))
        const url = serviceType == '0' ? 'get_operator_assigned_services' : 'get_operator_accepted_services'
        API.get(url)
            .then((res) => {
                if (res.data?.status == 'success') {
                    const fetchedData = res.data?.data?.sort((a: any, b: any) => b.id - a.id)
                    serviceType == '0'
                        ? setAllotedServices(fetchedData)
                        : setAcceptedServices(fetchedData)
                } else {
                    toast.error('Error while fetching service, \nPlease try agagin.')
                }
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setFecthing(false)
                dispatch(updateProcessState(false))
            })
    }

    const filteredAcceptedServices = showStatus
        ? acceptedServices?.filter((el: any) => el.order_status == showStatus)?.filter((el: any) => `${el.order_id}`?.includes(searchString))
        : acceptedServices?.filter((el: any) => `${el.order_id}`?.includes(searchString))

    useEffect(() => {
        fetchServices()
    }, [serviceType])


    return (
        <Box sx={{
            '& .MuiTabs-root': {
                p: '2px',
                borderRadius: '18px',
                boxShadow: `0 0 16px ${alpha(theme.palette.text.disabled, 0.1)} inset, 0 0 19px ${alpha(theme.palette.text.disabled, 0.1)}`,
                border: `2px solid ${theme.palette.primary.main}`,
                minHeight: 'auto',
                mb: 2,
                '& .MuiTab-root': {
                    p: '8px 12px',
                    borderRadius: '16px',
                    minHeight: '28px',
                    zIndex: 1,
                    textTransform: 'none',
                    color: theme.palette.primary.main,
                    '&.Mui-selected': {
                        color: theme.palette.primary.main
                    },
                },
                '& .MuiTabs-indicator': {
                    zIndex: 0,
                    borderRadius: '16px',
                    height: '100%',
                    boxShadow: `0 0 14px ${theme.palette.primary.main} inset`,
                    background: theme.palette.background.paper
                },
            },
        }}>
            <Tabs value={serviceType} onChange={changeServiceType} variant='fullWidth'>
                <Tab value={'0'} label="Assigned" />
                <Tab value={'1'} label="Acknowledged" />
            </Tabs>

            {serviceType != '0' &&
                <FilterBlock
                    showStatus={showStatus}
                    setShowStatus={setShowStatus}
                    searchString={searchString}
                    setSearchString={setSearchString}
                />
            }

            {!fetching &&
                serviceType == '0'
                ? <Services data={allotedServices} fetchServices={fetchServices} />
                : <Services data={filteredAcceptedServices} fetchServices={fetchServices} allotedList />
            }

        </Box>
    )
}


const Services = (props: any) => {

    const { data, fetchServices, allotedList = false } = props
    const attendance = useSelector((state: RootState) => state.attendance)

    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [active, setActive] = useState<number | null>(null)
    const [submitting, setSubmitting] = useState(false)

    const allocationFeedback = (id: string, order_accepted: string) => {
        setSubmitting(true)
        dispatch(updateProcessState(true))
        API.post('/submit_operator_order_request', { id: id, order_accepted: order_accepted })
            .then((res) => {
                toast.success(`Success! Request ${order_accepted == '0' ? 'declined' : 'acknowledged'}.`)
                fetchServices()
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setSubmitting(false)
                dispatch(updateProcessState(false))
            })
    }

    return (
        <Box>
            {data?.length > 0
                ? data.sort((a: any, b: any) => +a.order_status > +b.order_status)?.map((service: any, index: number) => (
                    <ListItem
                        key={index}
                        onClick={() => {
                            if (service?.order_status == '6' || service?.order_status == '0') {
                                !allotedList ? ((active == index) ? setActive(null) : setActive(index)) : navigate(`${service?.id}`)
                            } else if ((attendance.in && !attendance.out) && attendance?.on_leave == '0') {
                                !allotedList ? ((active == index) ? setActive(null) : setActive(index)) : navigate(`${service?.id}`)
                            } else {
                                toast.error(attendance?.on_leave == '1' ? 'You are not working today.' : (attendance.out ? 'Clocked Out for today.' : 'Clock-in first to take actions.'))
                            }
                        }}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            columnGap: 2,
                            rowGap: 1,
                            mb: 1,
                            borderRadius: '12px',
                            boxShadow: active == index ? '0 0 12px -3px #83838380' : 0,
                            transition: 'all 240ms ease-in-out',
                            '& .actionButtons': {
                                minWidth: '100%',
                                flex: 1,
                                borderRadius: '12px',
                                background: alpha(theme.palette.background.paper, 0.6),
                                display: 'flex',
                                alignItems: 'flex-end',
                                justifyContent: 'center',
                                gap: 1,
                                pb: 1,
                                '& .MuiButton-root': {
                                    flex: '0 1 120px'
                                },
                            },
                        }}>
                        <Box sx={{
                            flex: 1,
                            '& .inline': {
                                fontSize: '13px',
                                fontWeight: 600,
                                pl: 1.5,
                                color: theme.palette.text.secondary,
                                maxWidth: '30ch',
                                '& svg': {
                                    fontSize: '16px',
                                },
                            },
                        }}>
                            <Typography
                                sx={{
                                    width: 'max-content',
                                    minWidth: '140px',
                                    px: 1,
                                    ml: 1,
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    borderRadius: '12px',
                                    mb: 0.4,
                                    fontFamily: theme.typography.h1.fontFamily,
                                    color: allotedList ? theme.palette.primary.contrastText : theme.palette.primary.main,
                                    background: !allotedList ? theme.palette.primary.contrastText
                                        : (service?.order_status == '2' ? theme.palette.secondary.main :
                                            service?.order_status == '3' ? theme.palette.info.main :
                                                service?.order_status == '4' ? theme.palette.text.secondary :
                                                    service?.order_status == '5' ? theme.palette.primary.main :
                                                        service?.order_status == '6' ? theme.palette.success.main :
                                                            theme.palette.error.main),

                                }}>
                                {allotedList
                                    ? service?.order_status == '2' ? `Assigned - ${service?.order_id}` :
                                        service?.order_status == '3' ? `Acknowledged - ${service?.order_id}` :
                                            service?.order_status == '4' ? `Started - ${service?.order_id}` :
                                                service?.order_status == '5' ? `Completed - ${service?.order_id}` :
                                                    service?.order_status == '6' ? `Successfull - ${service?.order_id}` :
                                                        `Cancelled - ${service?.order_id}`
                                    : `Service ID - ${service?.order_id}`}
                            </Typography>

                            <Box sx={{
                                width: '100%',
                                mb: 0.5,
                                background: alpha(theme.palette.action.active, 0.05),
                                borderRadius: '12px',
                                py: 0.25,

                            }}>
                                <Typography className={'inline'}><span>Crop: <strong>{service?.crop_name}</strong></span> &nbsp;&nbsp; | &nbsp;&nbsp; <span>Acreage: <strong>{service?.requested_acreage}</strong></span></Typography>
                                <Typography className='inline line-clamp clamp-2'>Location: <strong>{service?.farm_location?.village}, {service?.farm_location?.sub_district}, {service?.farm_location?.district} - {service?.farm_location?.pin_code}</strong></Typography>
                            </Box>
                        </Box>

                        <Box sx={{
                            width: '54px',
                            height: '54px',
                            borderRadius: '8px',
                            outline: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                            display: 'flex',
                            flexFlow: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 0.25,
                            color: theme.palette.text.secondary,
                            '& .date': {
                                fontSize: '24px',
                                lineHeight: '24px',
                                fontWeight: 600,
                            },
                            '& .year': {
                                fontSize: '12px',
                                lineHeight: '12px',
                                fontWeight: 500,
                            },
                        }}>
                            <span className='date'>{moment(service?.spray_date, 'YYYY-MM-DD').format('DD')}</span>
                            <span className='year'>{moment(service?.spray_date, 'YYYY-MM-DD').format('MMM, YY')}</span>
                        </Box>

                        {(!allotedList && active == index && attendance.in) &&
                            <Box className={'actionButtons animate__animated animate__fadeIn'}>
                                <LoadingButton
                                    size={'small'}
                                    variant='contained'
                                    color='success'
                                    loading={submitting}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        allocationFeedback(service?.id, '1')
                                    }}
                                >
                                    Accept
                                </LoadingButton>
                                <LoadingButton
                                    size={'small'}
                                    variant='contained'
                                    loading={submitting}
                                    color='error'
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        allocationFeedback(service?.id, '0')
                                    }}
                                >
                                    Decline
                                </LoadingButton>
                            </Box>
                        }
                    </ListItem >
                ))
                : <NoDataView />
            }
        </Box >
    )
}


const FilterBlock = (props: any) => {

    const { setShowStatus, showStatus, searchString, setSearchString } = props

    const theme = useTheme()

    const [filteredStatus, setFilteredStatus] = useState<null | '3' | '4' | '5' | '6' | '0'>(showStatus)

    const [filterDialog, setFilterDialog] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const handleToggle = () => setFilterDialog((prevOpen) => !prevOpen)

    const handleClose = () => {
        setFilterDialog(false);
    };

    const onApplyFilter = () => {
        setShowStatus(filteredStatus)
        setSearchString('')
        handleClose()
    }

    return (
        <Box
            ref={anchorRef}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                mb: 2,
            }}>
            <ThemeTextField
                size='small'
                placeholder='search...'
                type='serach'
                value={searchString}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchString(e.target.value)}
                sx={{
                    minHeight: 'max-content',
                }}
            />

            <IconButton
                sx={{
                    height: '37px',
                    width: '37px',
                    borderRadius: '14px',
                    background: alpha(theme.palette.primary.main, 0.1),
                    color: theme.palette.primary.main,

                }}
                onClick={handleToggle}>
                {showStatus ? <FilterList /> : <FilterListOff />}
            </IconButton>

            <Popper
                sx={{ zIndex: 1, }}
                open={filterDialog}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper
                            sx={{
                                width: '90vw',
                                boxShadow: 5,
                                borderRadius: '26px',
                                p: 2,
                            }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <Box
                                    sx={{
                                        width: '100%',
                                        minHeight: '300px',
                                        display: 'flex',
                                        flexFlow: 'column',
                                        gap: 1,
                                        alignItems: 'stretch',
                                    }}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            flex: 1,
                                        }}>
                                        <FormBlockTitle title='Show' icon={false} />
                                        <Box
                                            className={'animate__animated animate__fadeIn'}
                                            sx={{
                                                mt: 0.5,
                                                display: 'flex',
                                                gap: 1,
                                                flexWrap: 'wrap',
                                                p: 1,
                                                boxShadow: '0 0 12px -3px #83838360 inset',
                                                borderRadius: '12px',
                                                '& .MuiChip-root': {
                                                    minWidth: '60px',
                                                },
                                            }}>
                                            {[
                                                { label: 'All', value: null },
                                                { label: 'Acknowledged', value: '3' },
                                                { label: 'Started', value: '4' },
                                                { label: 'Completed', value: '5' },
                                                { label: 'Successfull', value: '6' },
                                                { label: 'Cancelled', value: '0' },
                                            ]
                                                .map((item: any, index: number) => (
                                                    <Chip
                                                        key={index}
                                                        size={'small'}
                                                        label={item.label}
                                                        className={'animate__animated animate__fadeInUp'}
                                                        variant='filled'
                                                        color={filteredStatus == item.value ? 'primary' : 'default'}
                                                        onClick={() => setFilteredStatus(item.value)}
                                                    />
                                                ))
                                            }
                                        </Box>
                                    </Box>

                                    <Button
                                        variant={'contained'}
                                        fullWidth
                                        onClick={onApplyFilter}
                                    >
                                        Apply Filter
                                    </Button>
                                </Box>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

        </Box >
    )
}


export default OperatorServiceList