import { Box, IconButton, alpha, useMediaQuery, useTheme } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react'
import { ThemeDataTable } from '../../components/theme-data-table-container';
import TableLoading from '../../components/theme-table-container/TableLoading';
import NoDataView from '../../components/noDataView';
import TitleBar from '../../components/container/TitleBar';
import PageContainer from '../../components/container/page-container';
import API from '../../api';
import { camelCaseWords, capitalizeWords, currencyFormatter } from '../../utils/app-helper';
import { Sync } from '@mui/icons-material';
import { useAllBatteries } from '../../hooks/useAllBatteries';
import { useDocumentTitle } from '../../hooks/useDocumnetTitle';

export default function BatteryReport() {

    useDocumentTitle('Batteries')

    const theme = useTheme()
    const { fetchAllBatteries, fetchingAllBatteries, allBatteries } = useAllBatteries()

    useEffect(() => {
        fetchAllBatteries()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Batteries Report'} secondChild={
                <IconButton
                    onClick={fetchAllBatteries}
                    size='small'
                    sx={{
                        mr: 2,
                        background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                        boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                        border: `1px solid ${theme.palette.background.paper}`,
                    }}>
                    <Sync />
                </IconButton>
            } />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                }}>

                <BatteriesTable data={allBatteries} fetching={fetchingAllBatteries} />
            </Box>
        </PageContainer>
    )
}

const BatteriesTable = (props: any) => {

    const { data, fetching } = props
    const theme = useTheme()

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', flex: 1, minWidth: 80 },
        {
            field: 'battery_id', headerName: 'Battery Id', flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams) =>
                <span style={{ textTransform: 'uppercase' }}>{params.value}</span>
        },
        { field: 'battery_code', headerName: 'Battery Code', flex: 1, minWidth: 160 },
        { field: 'battery_type', headerName: 'battery Type', flex: 1, minWidth: 160 },
        { field: 'owned_by_name', headerName: 'Provider Name', flex: 1, minWidth: 160 },
        {
            field: 'status', headerName: 'Status', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => `${value == '1' ? 'Active' : 'Inactive'}`
        },
    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetching}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}


