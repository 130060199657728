import React from "react"
import { alpha, Box, Button, FormControlLabel, MenuItem, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import API from "../api"
import ThemeDialog from "./dialog-box/theme-dialog"
import { ThemeSwitchInput } from "./inputs/theme-switch"
import { ThemeTextField } from "./inputs/theme-text-field"

import { RootState } from "../store/store"
import { useAttendance } from "../hooks/useAttendance"
import { updateProcessState } from "../slices/process-slice"
import { camelCaseWords } from "../utils/app-helper"
import { User } from "../models/user"
import leaveIllustration from '../assets/images/illustartions/d-1.png'


interface AttendanceDialogProps {
    open: boolean
    onCloseDialog: () => void
}

const AttendanceDialog = (props: AttendanceDialogProps) => {

    const { open, onCloseDialog } = props

    const theme = useTheme()
    const dispatch = useDispatch()

    const smallScreen = useMediaQuery('(max-width:767px)')

    const user = useSelector((state: RootState) => state.userAuth)
    const attendance = useSelector((state: RootState) => state.attendance)
    const currentUser: User = user?.currentUser

    const clockedIn = attendance?.in ? attendance?.in : null
    const clockedOut = attendance?.out ? attendance?.out : null

    const isOnLeave = attendance ? attendance.on_leave == '1' : false

    const [currentTime, setCurrentTime] = React.useState(moment().format('hh:mm:ss A'))
    const [actionType, setActionType] = React.useState<'present' | 'leave'>('present')

    const [inHq, setInHq] = React.useState(false)
    const [isOnField, setIsOnField] = React.useState(false)

    const [totalDuration, setTotalDuration] = React.useState(moment.duration(
        clockedOut ? moment(clockedOut, 'hh:mm:ss').diff(moment(clockedIn, 'hh:mm:ss')) : moment().diff(moment(clockedIn, 'hh:mm:ss'))
    ))
    const formattedDuration = totalDuration?.isValid() ? moment?.utc(totalDuration?.asMilliseconds())?.format('HH:mm') : '-'

    const [remark, setRemark] = React.useState('')
    const [otherRemark, setOtherRemark] = React.useState('')
    const [leaveReamrks, setLeaveReamrks] = React.useState('')

    const statusColor = actionType == 'present' ? theme.palette.primary.main : theme.palette.error.main
    const isEnough = totalDuration.asHours() >= 8

    const { fetchAttendance } = useAttendance()

    const changeActionType = (event: React.SyntheticEvent, newValue: 'present' | 'leave') => setActionType(newValue)

    const getGeolocation = async () => {
        if (!navigator.geolocation) {
            toast.error('Geolocation not enabled or supported');
            dispatch(updateProcessState(false))
            return null;
        }

        try {
            const position = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    resolve,
                    () => {
                        toast.error('Please enebale geolocation.')
                        dispatch(updateProcessState(false))
                    });
            });
            return position;
        } catch (error) {
            console.error('Error getting geolocation', error);
            dispatch(updateProcessState(false))
            return null;
        }
    }

    const clockIn = async () => {
        if (!clockedIn) {
            dispatch(updateProcessState(true))
            const position: any = await getGeolocation();
            const geoLocation = `latitude: ${position?.coords?.latitude}, longitude: ${position?.coords?.longitude}`;
            const data = {
                user_id: `${currentUser?.id}`,
                date: `${moment().format('YYYY-MM-DD')}`,
                in: `${moment().format('HH:mm:ss')}`,
                on_leave: actionType == 'present' ? '0' : '1',
                user_remarks: leaveReamrks?.replaceAll('\n', ' '),
                field_activity: isOnField ? '1' : '0',
                out_of_station: inHq ? '1' : '0',
                geo_location: geoLocation
            }
            if (position) {
                API.post('/clockIn', data)
                    .then((res) => {
                        if (res.data.status == 'success') {
                            onCloseDialog()
                            fetchAttendance()
                        }
                    })
                    .catch((err) => {
                        console.error(err)
                    })
                    .finally(() => {
                        dispatch(updateProcessState(false))
                    })
            } else {
                toast.error('Please enebale geolocation. 1234')
                dispatch(updateProcessState(false))
            }
        }
    }

    const clockout = (remark: string) => {
        if (clockedIn) {
            dispatch(updateProcessState(true))
            const data = {
                user_id: `${currentUser?.id}`,
                out: `${moment().format('HH:mm:ss')}`,
                remarks: remark
            }
            API.post('/clockOut', data)
                .then((res) => {
                    if (res.data.status == 'success') {
                        fetchAttendance()
                        onCloseDialog()
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
                .finally(() => {
                    dispatch(updateProcessState(false))
                })
        }
    }


    React.useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(moment().format('hh:mm:ss A'));
        }, 1000);
        return () => clearInterval(intervalId)
    }, [])


    React.useEffect(() => {
        if (!clockedOut) {
            const intervalId = setInterval(() => {
                setTotalDuration(moment.duration(moment().diff(moment(clockedIn, 'HH:mm:ss'))))
            }, 1000);
            return () => clearInterval(intervalId);
        } else {
            setTotalDuration(moment.duration(moment(clockedOut, 'hh:mm:ss').diff(moment(clockedIn, 'hh:mm:ss'))))
        }
    }, [clockedIn, clockedOut])



    return (
        <ThemeDialog
            size={smallScreen ? "large" : 'small'}
            open={open}
            dialogBody={
                <Box
                    className={'flexBetweenTop'}
                    sx={{
                        flexFlow: 'column',
                        alignItems: 'stretch',
                        width: 'auto',
                        minWidth: { xs: '100%', sm: '550px' },
                        minHeight: '100%',
                        '& .MuiTabs-root': {
                            p: '2px',
                            borderRadius: '18px',
                            boxShadow: `0 0 16px ${alpha(theme.palette.text.disabled, 0.1)} inset, 0 0 19px ${alpha(theme.palette.text.disabled, 0.1)}`,
                            border: `2px solid ${statusColor}`,
                            minHeight: 'auto',
                            mb: 2,
                            '& .MuiTab-root': {
                                p: '8px 12px',
                                borderRadius: '16px',
                                minHeight: '28px',
                                zIndex: 1,
                                textTransform: 'none',
                                color: statusColor,
                                '&.Mui-selected': {
                                    color: statusColor
                                },
                            },
                            '& .MuiTabs-indicator': {
                                zIndex: 0,
                                borderRadius: '16px',
                                height: '100%',
                                boxShadow: `0 0 14px ${statusColor} inset`,
                                background: theme.palette.background.paper
                            },
                        },
                        '& .MuiButton-root': {
                            textTransform: 'none',
                            '&.buttonGradient': {
                                color: theme.palette.error.contrastText,
                                backgroundImage: `linear-gradient(135deg, ${theme.palette.error.main}, ${(clockedIn || actionType == 'present') ? theme.palette.warning.main : theme.palette.error.main})`,
                            },
                        },

                        '& .infoText': {
                            color: theme.palette.success.main,
                            mb: 1,
                            '& span': {
                                color: theme.palette.text.secondary,

                            },
                        },

                        '& .infoBlock': {
                            p: 1,
                            color: isEnough ? theme.palette.success.main : theme.palette.error.main,
                            // border: `2px solid ${isEnough ? theme.palette.success.main : theme.palette.error.main}`,
                            borderRadius: '16px',
                            boxShadow: `0 0 12px -3px ${alpha(isEnough ? theme.palette.success.main : theme.palette.error.main, 1)} inset`

                        },
                    }}>

                    <Box className={'flexBetweenCenter'} sx={{
                        flexWrap: 'wrap',
                        mb: 2,
                        gap: 2,
                    }}>
                        <Typography
                            sx={{
                                flex: 1,
                                fontSize: '1rem',
                                fontFamily: theme.typography.h1.fontFamily,
                                fontStyle: 'italic',
                                display: 'grid',
                                color: theme.palette.text.disabled,
                                fontWeight: 500,
                                '& span': {
                                    color: theme.palette.text.secondary,
                                    fontWeight: 600,
                                    fontSize: '16px',
                                    mb: 1,
                                    fontStyle: 'normal',
                                    '&.name': {
                                        fontSize: 'clamp(1rem, 7vw, 1.2rem)',
                                    },
                                    '&.timing': {
                                        fontWeight: 500,
                                        fontSize: '12px',
                                        mb: 0,
                                        letterSpacing: '1px',
                                    },
                                },
                            }}>
                            Good day,
                            <span className='name'>{camelCaseWords(currentUser?.name ?? 'Buddy')}</span>
                            {isOnLeave
                                ? ''
                                : clockedIn ?
                                    <>
                                        <span className={'timing'}>In &nbsp;&nbsp;&nbsp;: <strong>{clockedIn ? `${moment(clockedIn, 'hh:mm:ss').format('hh:mm A')}` : '-'}</strong></span>
                                        <span className={'timing'}>Out : <strong>{clockedOut ? `${moment(clockedOut, 'hh:mm:ss').format('hh:mm A')}` : '-'}</strong></span>
                                    </>
                                    : <span className={'infoText'}>
                                        <span>👉 Press Clock In to Start the day</span>
                                    </span>}
                        </Typography>

                        <Box className={'flexCenter'}
                            sx={{
                                flexFlow: 'column',
                                alignItems: 'stretch',
                                gap: 1,
                                boxShadow: `0 0 17px -3px ${theme.palette.text.disabled} inset`,
                                borderRadius: '1.5rem',
                                p: 1,
                            }}>
                            <Typography sx={{
                                textAlign: 'center',
                                fontSize: 'clamp(0.8rem, 2vw, 0.75rem)',
                                fontWeight: 600,
                                display: 'flex',
                                color: theme.palette.text.disabled,
                                flexFlow: 'column',
                                '& span': {
                                    color: theme.palette.primary.main,
                                    fontFamily: theme.typography.h1.fontFamily,
                                    fontSize: 'clamp(1.1rem, 3vw, 0.9rem)',
                                    mx: 0.5,
                                }

                            }}>
                                Attendance for
                                <span>{attendance?.date
                                    ? moment(attendance?.date, 'YYYY-MM-DD').format('DD MMM, YYYY')
                                    : moment().format('DD MMM, YYYY')
                                }</span>
                            </Typography>
                            <Typography sx={{
                                textAlign: 'center',
                                color: theme.palette.primary.main,
                                background: alpha(theme.palette.primary.main, 0.1),
                                borderRadius: '20vh',
                                fontSize: '14px',
                                fontWeight: 600,
                                p: '2px 12px',
                            }}>
                                {currentTime}
                            </Typography>
                        </Box>
                    </Box>

                    {isOnLeave
                        ? <LeaveInfoBlock />
                        : <>
                            {clockedIn &&
                                <Typography className={'infoText'}>
                                    {clockedOut ? 'Worked' : 'Working'} - <span> Operating {attendance?.out_of_station === '0' ? 'from Headquarters' : 'remotely'} {attendance?.field_activity === '1' ? 'with active field involvement' : 'without field engagement'}.</span>
                                </Typography>
                            }

                            {!clockedIn &&
                                <>
                                    <Tabs value={actionType} onChange={changeActionType} variant='fullWidth'>
                                        <Tab sx={{ color: theme.palette.primary.main }} value={'present'} label="Working" />
                                        <Tab sx={{ color: theme.palette.error.main }} value={'leave'} label="Leave" />
                                    </Tabs>

                                    <Box sx={{
                                        display: 'flex',
                                        flexFlow: 'column',
                                        gap: 1,
                                        mr: 1, px: 2,
                                        minHeight: '120px',
                                        '& .MuiFormControlLabel-root': {
                                            justifyContent: 'space-between',
                                            ml: 0,
                                        }
                                    }}>
                                        {actionType == 'present'
                                            ? <>
                                                <FormControlLabel
                                                    value="headQuarter"
                                                    control={<ThemeSwitchInput value={inHq} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInHq(event.target.checked)} />}
                                                    label="Out of Station"
                                                    labelPlacement="start"
                                                    className={'animate__animated animate__fadeIn'}

                                                />
                                                <FormControlLabel
                                                    value="fieldActivity"
                                                    control={<ThemeSwitchInput value={isOnField} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIsOnField(event.target.checked)} />}
                                                    label="Field Activity"
                                                    labelPlacement="start"
                                                    className={'animate__animated animate__fadeIn'}

                                                />
                                            </>
                                            :
                                            <ThemeTextField
                                                required
                                                className={'animate__animated animate__fadeIn'}
                                                type={"tel"}
                                                size={"small"}
                                                label={"Leave Reason"}
                                                multiline rows={3}
                                                helperText={'Maximum 140 characters'}
                                                value={leaveReamrks}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => event.target.value?.length <= 140 && setLeaveReamrks(event.target.value)}
                                                sx={{ flex: 1, minWidth: "100px" }}
                                                placeholder={"Reason for leave"}
                                            />
                                        }

                                    </Box>
                                </>
                            }


                            {clockedIn &&
                                <Box
                                    className={'infoBlock'}
                                    sx={{
                                        borderRadius: '1.5rem',
                                        p: 1.5,
                                        textAlign: 'center',
                                        mb: 3, mt: 2,
                                    }}>

                                    <Box className={`flexCenter`} sx={{
                                        mb: clockedOut ? 0 : 2,
                                        flexWrap: 'wrap',
                                        gap: 1.5,
                                    }}>
                                        <Box sx={{
                                            '& .msg': {
                                                fontSize: '12px'
                                            },
                                            '& .time': {
                                                textAlign: 'center',
                                                color: 'inherit',
                                                background: alpha(theme.palette.primary.main, 0.1),
                                                borderRadius: '20vh',
                                                fontSize: '14px',
                                                fontWeight: 600,
                                                p: '2px 12px',
                                            },
                                        }}>
                                            <Typography className={'msg'}>Today's hours</Typography>
                                            <Typography className={'time'}>{formattedDuration} hrs</Typography>
                                        </Box>
                                        <Typography sx={{
                                            flex: '1 1 270px',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            color: 'inherit',
                                        }}>
                                            {isEnough ? '👍 Enough for the day' : `⚠️ ${clockedOut ? 'Clocked' : 'Clocking'} out before average working hours.`}
                                        </Typography>
                                    </Box>


                                    {!clockedOut &&
                                        <>
                                            <ThemeTextField
                                                required className={'formInput'}
                                                size={'small'} label={'Activity'}
                                                value={remark}
                                                placeholder={'activity remarks'}
                                                sx={{ textAlign: 'left' }}
                                                onChange={(e: any) => setRemark(`${e.target?.value}`)}
                                                select >
                                                <MenuItem value={''}>--Select activity--</MenuItem>
                                                <MenuItem value={'Service Request fulfillment'}>Service Request fulfillment</MenuItem>
                                                <MenuItem value={'Farmer Meeting'}>Farmer Meeting</MenuItem>
                                                <MenuItem value={'Retailer Meeting'}>Retailer Meeting</MenuItem>
                                                <MenuItem value={'Marketing'}>Marketing</MenuItem>
                                                <MenuItem value={'Hub Transition'}>Hub Transition</MenuItem>
                                                <MenuItem value={'Business Meeting'}>Business Meeting</MenuItem>
                                                <MenuItem value={'Imparting Training'}>Imparting Training</MenuItem>
                                                <MenuItem value={'No Activity due to weather Conditions'}>No Activity due to weather Conditions</MenuItem>
                                                <MenuItem value={'Training'}>Training</MenuItem>
                                                <MenuItem value={'Others'}>Others</MenuItem>
                                            </ThemeTextField>

                                            {remark == 'Others' &&
                                                <ThemeTextField
                                                    required className={'formInput'}
                                                    size={'small'} label={'Other Activity'}
                                                    value={otherRemark} multiline rows={2}
                                                    onChange={(e) => e.target.value?.length <= 140 && setOtherRemark(e.target.value)}
                                                    placeholder={'other activity'} helperText={'Max 140 characters.'}
                                                />
                                            }
                                        </>}
                                </Box>
                            }
                        </>
                    }

                    <Box className={'flexBottomCenter'}
                        sx={{
                            flex: 1,
                            flexFlow: 'column',
                            gap: 1,
                        }}>
                        {!clockedOut && !isOnLeave &&
                            <Button
                                variant='contained'
                                color={clockedIn ? 'warning' : actionType == 'present' ? 'primary' : 'error'}
                                fullWidth
                                className={'buttonGradient'}
                                onClick={() => {
                                    if (clockedIn) {
                                        if (remark === '') {
                                            toast.error('Please choose the activity you did today.')
                                            return
                                        }
                                        if (remark === 'Other' && otherRemark === '') {
                                            toast.error('Please describe the activity you did today.')
                                            return
                                        }
                                        clockout(remark === 'Others' ? otherRemark : remark)
                                    } else {
                                        if (leaveReamrks === '' && actionType == 'leave') toast.error('Please enter a  reason for leave.')
                                        else clockIn()
                                    }
                                }}>

                                {clockedIn ? 'Clock Out' : actionType == 'present' ? 'Clock In' : 'Mark Leave for Today'}
                            </Button>
                        }

                        <Button
                            fullWidth
                            color={'inherit'}
                            onClick={onCloseDialog}>
                            {clockedOut || isOnLeave ? 'Close' : clockedIn ? 'Continue to work' : 'Discard & Close'}
                        </Button>
                    </Box>

                </Box>
            }
        />

    )
}

export default AttendanceDialog


const LeaveInfoBlock = () => {

    const attendance = useSelector((state: RootState) => state.attendance)

    const theme = useTheme()

    return (
        <Box
            className={'flexCenter'}
            sx={{
                flex: 1,
                py: 5,
                flexFlow: 'column',
                '& img': {
                    maxHeight: '120px',
                    objectFit: 'contain',
                    filter: 'sepia(1)',
                }
            }}>
            <img src={leaveIllustration} alt="on leave" />
            <Typography sx={{
                fontSize: '1.5rem',
                fontWeight: 600,
                fontFamily: theme.typography.h1.fontFamily,
                color: theme.palette.warning.main,
            }}>On Leave</Typography>
            <Typography>You have marked leave for {moment(attendance.date).format('DD MMM YYYY')}</Typography>
        </Box>
    )
}