import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, IconButton, MenuItem, Typography, alpha, useTheme } from '@mui/material'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { DeleteOutlineRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router'
import { updateProcessState } from '../../slices/process-slice'
import { stateList } from '../../utils/sample-data'
import PageContainer from '../../components/container/page-container'
import TitleBar from '../../components/container/TitleBar'
import { ThemeTextField } from '../../components/inputs/theme-text-field'
import FileUploadInput from '../../components/inputs/fileUploadInput'
import API from '../../api'
import { FormBlockTitle } from '../../components/title/FormBlockTitle'
import { s3BucketUrl } from '../../utils/app-helper'
import LoadingView from '../../components/loading-view'

function UpdateClient() {

    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const { clientId } = params

    const [baseClient, setBaseClient] = useState({} as any)

    const { handleSubmit, register, control, reset, setValue, watch, formState: { errors } } = useForm()

    const [fetchingClient, setFetchingClient] = useState(true)
    const [submitting, setSubmitting] = useState(false)

    const data = watch()

    const resetForm = () => {
        reset()
        setValue('logo', null)
        setValue('qr_code', null)
        setValue('sign_img', null)
    }

    const onSubmit = (data: any) => {
        setSubmitting(true)
        dispatch(updateProcessState(true))
        const { regional_clients, logo_img, qr_code, sign_img, ...restData } = data
        const newData = {
            base_client: {
                ...restData,
                id: `${baseClient?.id}`,
                ...(data?.logo_img?.length > 0 ? { logo_img: data?.logo_img[0] } : {}),
                ...(data?.qr_code?.length > 0 ? { qr_code: data?.qr_code[0] } : {}),
                ...(data?.sign_img?.length > 0 ? { sign_img: data?.sign_img[0] } : {}),
            },
        }
        console.log(newData)
        API.post('/update_base_client', { ...newData }, { headers: { "Content-Type": "multipart/form-data" } })
            .then((res) => {
                if (res.data?.status == 'success') {
                    toast.success('Client updated successfully!')
                    reset()
                    navigate('/clients/base')
                }
                if (res.data?.status == 'error') {
                    toast.error(`Error! \n ${res.data?.msg}`)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setSubmitting(false)
                dispatch(updateProcessState(false))
            })
    }


    const fectchBaseClientDetails = (clientId: string) => {
        setFetchingClient(true)
        API.post('/get_base_client_details', { id: `${atob(clientId)}` })
            .then((res) => {
                if (res.data?.status == 'success') {
                    setBaseClient(res.data?.data[0])
                }
                if (res.data?.status == 'error') {
                    toast.error(`Error! \n ${res.data?.msg}`)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setFetchingClient(false)
                dispatch(updateProcessState(false))
            })
    }

    useEffect(() => {
        fectchBaseClientDetails(`${clientId}`)
    }, [])


    useEffect(() => {
        setValue('client_name', baseClient?.client_name, { shouldValidate: true })
        setValue('pan_no', baseClient?.pan_no, { shouldValidate: true })
        setValue('cin', baseClient?.cin, { shouldValidate: true })
        setValue('phone_number', baseClient?.phone_number, { shouldValidate: true })
        setValue('signature_name', baseClient?.signature_name, { shouldValidate: true })
        setValue('gst_nature', baseClient?.gst_nature, { shouldValidate: true })
        setValue('registration_address', baseClient?.registration_address, { shouldValidate: true })
        setValue('account_no', baseClient?.account_no, { shouldValidate: true })
        setValue('ifsc', baseClient?.ifsc, { shouldValidate: true })
        setValue('branch_name', baseClient?.branch_name, { shouldValidate: true })
        setValue('bank_name', baseClient?.bank_name, { shouldValidate: true })
        setValue('upi_id', baseClient?.upi_id, { shouldValidate: true })
        // setValue('logo_img', baseClient?.logo_img, { shouldValidate: true })
        // setValue('qr_code', baseClient?.qr_code, { shouldValidate: true })
        // setValue('sign_img', baseClient?.sign_img, { shouldValidate: true })

    }, [baseClient])


    return (
        <PageContainer>
            <TitleBar title={'Update Client'} backIcon />

            {fetchingClient
                ? <LoadingView />
                : <Box className={'animate__animated animate__fadeIn'}
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 3,
                        mx: { xs: 0, sm: 3, md: 5 },
                        '& .formInputsBlock': {
                            padding: '0.5rem 1rem',
                            borderRadius: '18px',
                            background: theme.palette.mode == 'dark'
                                ? '#0e0e0e'
                                : `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.07)}, ${theme.palette.background.paper})`,
                            boxShadow: theme.palette.mode == 'dark' ? 'none' : `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                            border: `2px solid ${theme.palette.background.paper}`,
                            '& .blockHeading': {
                                width: '100%',
                                fontFamily: theme.typography.h1.fontFamily,
                                fontSize: '14px',
                                fontWeight: 600,
                                color: theme.palette.primary.main,
                                pl: 2,
                                mb: 1,
                            },
                            '& .groupTop': {
                                display: 'flex',
                                width: '100%',
                                alignItems: 'flex-end',
                                justifyContent: 'space-between',
                                mb: 0.5,
                                minHeight: '32px',
                                '& .titleBlock': {
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                },
                                '& .actionBlock': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    '& .MuiFormControlLabel-root': {
                                        '& .MuiCheckbox-root': {
                                            p: '8px',
                                            '& svg': {
                                                height: '1rem',
                                                width: '1rem',
                                            },
                                        },
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '14px'
                                        },
                                    },
                                    '& .MuiIconButton-root': {
                                        '& svg': {
                                            height: '22px',
                                            fontSize: '20px'
                                        },
                                    }
                                },
                            },
                        },

                        '& .regionalClients': {
                            width: '100%',
                            display: 'flex',
                            flexFlow: 'column',
                            alignItems: 'center',
                            columnGap: 2,
                            justifyContent: 'stretch',
                            px: { xs: 0, sm: 3, md: 5 },
                            pb: 4,
                            '& .regionalClient': {
                                background: { xs: theme.palette.background.paper, sm: 'transparent' },
                                width: { xs: '100%', sm: '90%' },
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                columnGap: 3,
                                '& .formInput': {
                                    flex: '1 1 140px',
                                    '&.grow-1': {
                                        flex: '2 1 200px'
                                    },
                                },
                            },
                        },
                    }}>
                    <Box className={'formInputsBlock'}
                        sx={{
                            flex: '1 1 300px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            columnGap: 3,
                            '& .formInput': {
                                flex: '1 1 250px',
                            },
                        }}>
                        <Typography className={'blockHeading'}>Basic Info</Typography>

                        <>
                            <Controller name={`client_name`}
                                control={control}
                                rules={{ required: { value: true, message: 'Required' } }}
                                defaultValue={baseClient?.client_name ?? ''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} required className={'formInput'}
                                        error={Boolean(errors?.client_name)}
                                        helperText={(errors?.client_name?.message ?? '').toString()}
                                        size={'small'} label={'Client Legal Name'}
                                        placeholder={'Enter name'}
                                    />
                                )} />
                            <Controller name={`pan_no`}
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Required' },
                                    pattern: { value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, message: 'invalid pan' },
                                }}
                                defaultValue={baseClient?.pan_no ?? ''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} required className={'formInput'}
                                        error={Boolean(errors?.pan_no)}
                                        helperText={(errors?.pan_no?.message ?? '').toString()}
                                        size={'small'} label={'PAN Number'}
                                        placeholder={'XXXXXXXXXX'}
                                    />
                                )} />
                            <Controller name={`cin`}
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Required' },
                                }}
                                defaultValue={baseClient?.cin ?? ''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} required className={'formInput'}
                                        error={Boolean(errors?.cin)}
                                        helperText={(errors?.cin?.message ?? '').toString()}
                                        size={'small'} label={'CIN'}
                                        placeholder={'XXXXXXXXXX'}
                                    />
                                )} />
                            <Controller name={`phone_number`}
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Required' },
                                    pattern: { value: /^[789]\d{9}$/, message: 'Invalid mobile number' },
                                }}
                                defaultValue={baseClient?.phone_number ?? ''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} required className={'formInput'}
                                        error={Boolean(errors?.phone_number)} type={'number'}
                                        helperText={(errors?.phone_number?.message ?? '').toString()}
                                        size={'small'} label={'Mobile'}
                                        placeholder={'XXXX XXX XXX'}
                                    />
                                )} />
                            <Controller name={`signature_name`}
                                control={control}
                                rules={{ required: { value: true, message: 'Required' } }}
                                defaultValue={baseClient?.signature_name ?? ''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} required className={'formInput'}
                                        error={Boolean(errors?.signature_name)}
                                        helperText={(errors?.signature_name?.message ?? '').toString()}
                                        size={'small'} label={'Signatory Name'}
                                        placeholder={'Enter name'}
                                    />
                                )} />
                            <Controller name={`gst_nature`}
                                control={control}
                                rules={{ required: { value: true, message: 'Required' } }}
                                defaultValue={baseClient?.gst_nature ?? ''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} required className={'formInput'}
                                        error={Boolean(errors?.gst_nature)} select
                                        helperText={(errors?.gst_nature?.message ?? '').toString()}
                                        size={'small'} label={'GST Nature'}
                                        placeholder={'Please slect nature'}
                                    >
                                        <MenuItem value={'exempt'}>Exempt</MenuItem>
                                        <MenuItem value={'applicable'}>Applicable</MenuItem>
                                    </ThemeTextField>
                                )} />
                            <Controller name={`registration_address`}
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Required' },
                                }}
                                defaultValue={baseClient?.registration_address ?? ''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} required sx={{ width: '100%', marginBottom: '1rem !important', }} multiline rows={3}
                                        error={Boolean(errors?.registration_address)}
                                        helperText={(errors?.registration_address?.message ?? '').toString()}
                                        size={'small'} label={'Registered Address'}
                                        placeholder={'XXXXXXXXXX'}
                                    />
                                )} />
                        </>

                    </Box>

                    <Box className="formInputsBlock"
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            columnGap: 3,
                            '& .formInput': {
                                flex: '1 1 250px',
                            },

                        }}>
                        <Typography className={'blockHeading'}>Bank Info</Typography>

                        <Controller name={`account_no`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            defaultValue={baseClient?.account_no ?? ''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.account_no)}
                                    helperText={(errors?.account_no?.message ?? '').toString()}
                                    size={'small'} label={'Account Number'}
                                    placeholder={'***************'}
                                />
                            )} />
                        <Controller name={`ifsc`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            defaultValue={baseClient?.ifsc ?? ''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.ifsc)}
                                    helperText={(errors?.ifsc?.message ?? '').toString()}
                                    size={'small'} label={'IFSC'}
                                    placeholder={'***********'}
                                />
                            )} />
                        <Controller name={`branch_name`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            defaultValue={baseClient?.branch_name ?? ''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.branch_name)}
                                    helperText={(errors?.branch_name?.message ?? '').toString()}
                                    size={'small'} label={'Barnch'}
                                    placeholder={'eg. Azad Nagar, Hisar'}
                                />
                            )} />
                        <Controller name={`bank_name`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            defaultValue={baseClient?.bank_name ?? ''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.bank_name)}
                                    helperText={(errors?.bank_name?.message ?? '').toString()}
                                    size={'small'} label={'Bank'}
                                    placeholder={'eg. State bank of India'}
                                />
                            )} />
                        <Controller name={`upi_id`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            defaultValue={baseClient?.upi_id ?? ''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.upi_id)}
                                    helperText={(errors?.upi_id?.message ?? '').toString()}
                                    size={'small'} label={'UPI Address'}
                                    placeholder={'***************'}
                                />
                            )} />
                    </Box>

                    <Box sx={{
                        width: '100%',
                        display: 'grid',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        justifyItems: 'stretch',
                        gridTemplateColumns: `repeat( auto-fit, minmax(250px, 1fr) )`,
                        gap: 2,
                        flexWrap: 'wrap',
                    }}>

                        <FileUploadInput
                            label={'QR Code'}
                            accept={'image/png, image/gif, image/jpeg'}
                            defaultImage={`${s3BucketUrl}/qr/${baseClient?.qr_code}`}
                            reactHookProps={{ ...register('qr_code') }}
                            onClear={() => setValue('qr_code', null)}
                            errorMessage={errors.qr_code?.message?.toString() ?? ''}
                        />
                        <FileUploadInput
                            label={'Company Logo'}
                            accept={'image/png, image/gif, image/jpeg'}
                            defaultImage={`${s3BucketUrl}/logo_img/${baseClient?.logo_img}`}
                            reactHookProps={{ ...register('logo_img') }}
                            onClear={() => setValue('logo_img', null)}
                            errorMessage={errors.logo_img?.message?.toString() ?? ''}
                        />
                        <FileUploadInput
                            label={'Signature'}
                            accept={'image/png, image/gif, image/jpeg'}
                            defaultImage={`${s3BucketUrl}/sign_img/${baseClient?.sign_img}`}
                            reactHookProps={{ ...register('sign_img') }}
                            onClear={() => setValue('sign_img', null)}
                            errorMessage={errors.sign_img?.message?.toString() ?? ''}
                        />

                    </Box>

                    <Box sx={{
                        order: 4,
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap-reverse',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        my: 4,
                        '& .MuiButton-root': {
                            flex: '1 0 160px',
                            maxWidth: { xs: '100%', sm: '190px' },
                            textWrap: 'nowrap',
                            textTransform: 'capitalize'
                        },
                    }}>

                        <Button
                            size={'large'}
                            variant={'outlined'}
                            onClick={resetForm}>
                            Discard Changes
                        </Button>
                        <LoadingButton
                            variant={'contained'}
                            size={'large'}
                            loading={submitting}
                            endIcon={<></>}
                            loadingPosition={'end'}
                            onClick={handleSubmit(onSubmit)}>
                            Create
                        </LoadingButton>

                    </Box>

                </Box>
            }

        </PageContainer >
    )
}

export default UpdateClient


const DocumentsToUpload = (props: any) => {

    const { baseClient, register, setValue, errors } = props

    return (
        <>
            <FileUploadInput
                label={'QR Code'}
                accept={'image/png, image/gif, image/jpeg'}
                defaultImage={baseClient ? `${s3BucketUrl}/qr/${baseClient.qr_code}` : ''}
                reactHookProps={{ ...register('qr_code') }}
                onClear={() => setValue('qr_code', null)}
                errorMessage={errors.qr_code?.message?.toString() ?? ''}
            />
            <FileUploadInput
                label={'Company Logo'}
                accept={'image/png, image/gif, image/jpeg'}
                defaultImage={`${s3BucketUrl}/logo_img/${baseClient?.logo_img}`}
                reactHookProps={{ ...register('logo_img') }}
                onClear={() => setValue('logo_img', null)}
                errorMessage={errors.logo_img?.message?.toString() ?? ''}
            />
            <FileUploadInput
                label={'Signature'}
                accept={'image/png, image/gif, image/jpeg'}
                defaultImage={`${s3BucketUrl}/sign_img/${baseClient?.sign_img}`}
                reactHookProps={{ ...register('sign_img') }}
                onClear={() => setValue('sign_img', null)}
                errorMessage={errors.sign_img?.message?.toString() ?? ''}
            />
        </>
    )
}