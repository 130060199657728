import {
    Autocomplete,
    Box,
    Button,
    InputAdornment,
    MenuItem,
    Tab,
    Tabs,
    alpha,
    useTheme,
    CircularProgress
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ThemeTextField, themeTextFieldSx } from '../../../components/inputs/theme-text-field'
import API from '../../../api'
import { CurrencyRupeeRounded } from '@mui/icons-material'
import { camelCaseWords } from '../../../utils/app-helper'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { LoadingButton } from '@mui/lab'
import { useDispatch } from 'react-redux'
import { updateProcessState } from '../../../slices/process-slice'
import toast from 'react-hot-toast'
import { useAvailableCrops } from '../../../hooks/useAvailableCrops'
import { useSchemesData } from '../../../hooks/useSchemesData'
import { useNavigate } from 'react-router'
import { useRegionalClients } from '../../../hooks/useRegionalClinets'

function CreateSchemeForm() {

    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { fetchRegionalClients, fetchingRegionalClients, regionalClients } = useRegionalClients()
    const { availableCrops, fetchAvailableCrops, fetchingAvailableCrops } = useAvailableCrops()

    const [orderType, setorderType] = useState('0')
    const [submitting, setSubmitting] = useState(false)



    const { handleSubmit, control, setError, clearErrors, reset, setValue, register, watch, formState: { errors } } = useForm()
    const inputData = watch()

    const statusColor = orderType == '4' ? theme.palette.warning.main : orderType == '5' ? theme.palette.info.main : theme.palette.secondary.main

    const resetForm = () => {
        reset()
        setorderType('general')
    }

    const changeOrderType = (event: React.SyntheticEvent, newValue: string) => {
        setorderType(newValue)
        reset()
    }

    const [fetchingCropPrice, setFetchingCropPrice] = useState(false)
    const fetchCropPrice = (cropId: string, state: string) => {
        setFetchingCropPrice(true)
        API.post('get_state_crop_details', { crop_id: cropId, state: state })
            .then((res) => {
                setValue('crop_base_price', `${res.data?.data}`, { shouldValidate: true })
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setFetchingCropPrice(false)
            })
    }



    const onSubmit = (data: any) => {
        setSubmitting(true)
        dispatch(updateProcessState(true))
        const { client, crop, period_from, period_to, order_type, discount_price, ...restData } = data
        const newData = {
            ...restData,
            type: orderType == '0' ? data.order_type : orderType,
            crop_id: `${data.crop.id}`,
            crop_name: data.crop.crop_name,
            period_from: moment(data.period_from).format('YYYY-MM-DD'),
            period_to: moment(data.period_to).format('YYYY-MM-DD'),
            discount_price: orderType != '0' ? data.crop_base_price : data.discount_price,
            ...(data.client ? { client_id: `${data?.client.id}` } : {}),

            //to be deleted
            scheme_code: '5',
            scheme_name: 'dsfds'
        }
        API.post('submit_scheme_details', { ...newData })
            .then((res) => {
                if (res.data?.status == 'success') {
                    toast.success('Success! Scheme created successfully.')
                    navigate('/schemes')
                }
                if (res.data?.status == 'error') {
                    toast.error(`Error! \n ${res.data?.msg}`)
                }
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setSubmitting(false)
                dispatch(updateProcessState(false))
            })
    }

    useEffect(() => {
        fetchRegionalClients()
        fetchAvailableCrops()
    }, [])

    return (
        <Box
            sx={{
                mx: 'auto',
                maxWidth: '860px',
                '& .formInputGroup': {
                    padding: '1rem 1rem 0.2rem',
                    borderRadius: '18px',
                    mb: 2,
                    background: theme.palette.mode == 'dark'
                        ? '#0e0e0e'
                        : `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.07)}, ${theme.palette.background.paper})`,
                    boxShadow: theme.palette.mode == 'dark' ? 'none' : `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                    border: `2px solid ${theme.palette.background.paper}`,
                    '& .farmsInputArea': {
                        display: 'flex',
                        flexWrap: 'wrap',
                        columnGap: 3,
                        '& .formInput': {
                            flex: '1 1 210px',
                        },

                    },
                    '& .groupTop': {
                        display: 'flex',
                        width: '100%',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                        mb: 0.5,
                        minHeight: '32px',
                        '& .titleBlock': {
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                        '& .actionBlock': {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            '& .MuiFormControlLabel-root': {
                                '& .MuiCheckbox-root': {
                                    p: '8px',
                                    '& svg': {
                                        height: '1rem',
                                        width: '1rem',
                                    },
                                },
                                '& .MuiFormControlLabel-label': {
                                    fontSize: '14px'
                                },
                            },
                            '& .MuiIconButton-root': {
                                '& svg': {
                                    height: '22px',
                                    fontSize: '20px'
                                },
                            }
                        },
                    },
                },

                '& .MuiTabs-root': {
                    p: '2px',
                    borderRadius: '18px',
                    boxShadow: `0 0 16px ${alpha(theme.palette.text.disabled, 0.1)} inset, 0 0 19px ${alpha(theme.palette.text.disabled, 0.1)}`,
                    border: `2px solid ${statusColor}`,
                    // border: `2px solid ${theme.palette.background.default}`,
                    minHeight: 'auto',
                    mb: 2,
                    '& .MuiTab-root': {
                        p: '8px 12px',
                        borderRadius: '16px',
                        minHeight: '28px',
                        zIndex: 1,
                        textTransform: 'none',
                        color: statusColor,
                        '&.Mui-selected': {
                            color: statusColor
                        },
                    },
                    '& .MuiTabs-indicator': {
                        zIndex: 0,
                        borderRadius: '16px',
                        height: '100%',
                        boxShadow: `0 0 14px ${statusColor} inset`,
                        background: theme.palette.background.paper
                    },
                },
            }}>

            <Tabs value={orderType} onChange={changeOrderType} variant='fullWidth'>
                <Tab value={'4'} label="R&D Order" />
                <Tab value={'0'} label="General Order" />
                <Tab value={'5'} label="Demo Order" />
            </Tabs>

            <Box className={'formInputGroup'}
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    columnGap: 3,
                    '& .innerInputs': {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        columnGap: 3,
                    },
                    '& .formInput': {
                        flex: '1 1 200px'
                    },
                    '& .MuiTextField-root': {
                        '&.date': {
                            marginBottom: '24px !important'
                        },
                    },
                }}>
                <>
                    <Box className={'innerInputs'}>
                        {orderType == '0' &&
                            <Controller name={`order_type`}
                                control={control}
                                rules={{ required: { value: orderType === '0', message: 'Required' } }}
                                defaultValue={''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} required className={'formInput'}
                                        type={'number'}
                                        disabled={orderType !== '0'}
                                        error={Boolean(errors?.order_type)} select
                                        helperText={(errors?.order_type?.message ?? '').toString()}
                                        size={'small'} label={'Scheme Type'}
                                        placeholder={'select Type'}
                                    >
                                        <MenuItem value={'1'}>General Scheme</MenuItem>
                                        <MenuItem value={'2'}>Client Scheme</MenuItem>
                                        <MenuItem value={'3'}>Subvention Scheme</MenuItem>
                                    </ThemeTextField>
                                )} />
                        }

                        {inputData?.order_type !== '1' &&
                            <Controller name={`client`}
                                control={control}
                                rules={{ required: { value: inputData?.order_type != 'general_order', message: 'Required' } }}
                                defaultValue={''}
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete size={'small'}
                                        autoComplete
                                        className={'formInput'}
                                        disabled={inputData?.order_type == ''}
                                        onChange={(e, data) => {
                                            onChange(data)
                                            inputData?.crop && setValue('crop_base_price', ``, { shouldValidate: true })
                                        }}
                                        noOptionsText={'Clients Not Available'}
                                        value={value || null}
                                        sx={{ flex: '1 1 250px' }}
                                        options={regionalClients}
                                        getOptionLabel={(option) => camelCaseWords(option?.regional_client_name) ?? ''}
                                        isOptionEqualToValue={((option: any, value: any) => option.id == value.id)}
                                        renderOption={(props, option, { selected }) => (
                                            <Box component={'li'} {...props} key={props.id}
                                                sx={{
                                                    p: '2px 6px',
                                                    textAlign: 'left',
                                                    borderRadius: '12px',
                                                    mx: '2px',
                                                    fontSize: '15px',
                                                    lineHeight: '15px',
                                                    fontWeight: 500,
                                                    color: theme.palette.text.secondary,
                                                    textTransform: 'uppercase',
                                                }}>
                                                {option?.regional_client_name}
                                            </Box>

                                        )}
                                        renderInput={(params) => (
                                            <ThemeTextField
                                                {...params}
                                                required={inputData?.order_type != 'general_order'}
                                                error={Boolean(errors?.client)}
                                                helperText={(errors?.client?.message ?? '').toString()}
                                                size={'small'} label={'Client'}
                                                placeholder={'Please select client'}
                                            />
                                        )} />
                                )} />
                        }
                    </Box>

                    <Controller name={`crop`}
                        control={control}
                        rules={{ required: { value: true, message: 'Required' } }}
                        defaultValue={''}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete size={'small'}
                                autoComplete
                                disabled={inputData?.order_type != '1' && !inputData?.client?.state}
                                className={'formInput'}
                                onChange={(e, data) => {
                                    onChange(data)
                                    data
                                        ? fetchCropPrice(`${data?.id}`, `${inputData?.client?.state}`)
                                        : setValue('crop_base_price', ``, { shouldValidate: true })
                                }}
                                noOptionsText={'No Crops Available'}
                                value={value || null}
                                sx={{ flex: '1 1 250px' }}
                                options={availableCrops}
                                getOptionLabel={(option) => camelCaseWords(option?.crop_name) ?? ''}
                                isOptionEqualToValue={((option: any, value: any) => option.id == value.id)}
                                renderOption={(props, option, { selected }) => (
                                    <Box component={'li'} {...props} key={props.id}
                                        sx={{
                                            p: '2px 6px',
                                            textAlign: 'left',
                                            borderRadius: '12px',
                                            mx: '2px',
                                            fontSize: '15px',
                                            lineHeight: '15px',
                                            fontWeight: 500,
                                            color: theme.palette.text.secondary,
                                        }}>
                                        {camelCaseWords(option?.crop_name)}
                                    </Box>

                                )}
                                renderInput={(params) => (
                                    <ThemeTextField
                                        {...params} required
                                        error={Boolean(errors?.crop)}
                                        helperText={(errors?.crop?.message ?? '').toString()}
                                        size={'small'} label={'Crop'}
                                        placeholder={'Please select crop'}
                                    />
                                )} />
                        )} />

                    <Controller name={`crop_base_price`}
                        control={control}
                        rules={{ required: { value: true, message: 'Required' } }}
                        defaultValue={''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} required className={'formInput'}
                                error={Boolean(errors?.crop_base_price)} type={'number'}
                                disabled={orderType == '0'}
                                helperText={(errors?.crop_base_price?.message ?? '').toString()}
                                size={'small'} label={'Base Price'}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><CurrencyRupeeRounded sx={{ fontSize: '16px' }} /></InputAdornment>,
                                    endAdornment: <InputAdornment position="end">{fetchingCropPrice && <CircularProgress size={14} />}</InputAdornment>,
                                }}
                                placeholder={'eg. 12'}
                            />
                        )} />

                    <Controller name={`min_acreage`}
                        control={control}
                        rules={{ required: { value: true, message: 'Required' } }}
                        defaultValue={''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} required className={'formInput'}
                                onBlur={(e) => setValue('min_acreage', (+e.target.value).toFixed(2), { shouldValidate: true })}
                                error={Boolean(errors?.min_acreage)} type={'number'}
                                helperText={(errors?.min_acreage?.message ?? '').toString()}
                                size={'small'} label={'Min Acreage'}
                                placeholder={'eg. 12'}
                            />
                        )} />

                    <Controller name={`max_acreage`}
                        control={control}
                        rules={{ required: { value: true, message: 'Required' } }}
                        defaultValue={''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} required className={'formInput'}
                                onBlur={(e) => setValue('max_acreage', (+e.target.value).toFixed(2), { shouldValidate: true })}
                                error={Boolean(errors?.max_acreage)} type={'number'}
                                helperText={(errors?.max_acreage?.message ?? '').toString()}
                                size={'small'} label={'Max Acreage'}
                                placeholder={'eg. 12'}
                            />
                        )} />

                    <Controller name="period_from"
                        control={control}
                        defaultValue={null}
                        rules={{ required: { value: true, message: 'Required' } }}
                        render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <MobileDatePicker
                                    {...field}
                                    disablePast
                                    minDate={moment().add(1, 'days')}
                                    label="Start From"
                                    className={'formInput date'}
                                    value={field.value ? moment(field.value) : null}
                                    onChange={(date) => {
                                        setValue('period_from', date);
                                        clearErrors('period_from')
                                    }}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            required: true,
                                            sx: themeTextFieldSx,
                                            style: { width: '100%' },
                                            error: Boolean(errors?.period_from),
                                            helperText: errors?.period_from?.message?.toString() ?? '',
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        )}
                    />
                    <Controller name="period_to"
                        control={control}
                        defaultValue={null}
                        rules={{ required: { value: true, message: 'Required' } }}
                        render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <MobileDatePicker
                                    {...field}
                                    disablePast
                                    minDate={moment().add(1, 'days')}
                                    label="Valid Upto"
                                    className={'formInput date'}
                                    value={field.value ? moment(field.value) : null}
                                    onChange={(date) => {
                                        setValue('period_to', date);
                                        clearErrors('period_to')
                                    }}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            required: true,
                                            sx: themeTextFieldSx,
                                            style: { width: '100%' },
                                            error: Boolean(errors?.period_to),
                                            helperText: errors?.period_to?.message?.toString() ?? '',
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        )}
                    />

                    <Box className={'innerInputs'}>
                        <Controller name={`discount_price`}
                            control={control}
                            rules={{
                                required: { value: orderType == '0', message: 'Required' },
                                min: { value: 1, message: 'At least add some discount.' },
                            }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    onBlur={(e) => setValue('discount_price', (+e.target.value).toFixed(3), { shouldValidate: true })}
                                    error={Boolean(errors?.discount_price)} type={'number'}
                                    disabled={orderType != '0'}
                                    helperText={(errors?.discount_price?.message ?? '').toString()}
                                    size={'small'} label={'Discount Amount'}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><CurrencyRupeeRounded sx={{ fontSize: '16px' }} /></InputAdornment>,
                                    }}
                                    placeholder={'eg. 12'}
                                />
                            )} />
                    </Box>
                </>
            </Box>

            <Box sx={{
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap-reverse',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
                my: 4,
                '& .MuiButton-root': {
                    flex: '1 0 160px',
                    maxWidth: { xs: '100%', sm: '190px' },
                    textWrap: 'nowrap',
                    textTransform: 'capitalize'
                },
            }}>

                <Button
                    size={'large'}
                    variant={'outlined'}
                    onClick={resetForm}>
                    Discard Changes
                </Button>
                <LoadingButton
                    variant={'contained'}
                    size={'large'}
                    loading={submitting}
                    endIcon={<></>}
                    loadingPosition={'end'}
                    onClick={handleSubmit(onSubmit)}>
                    Create
                </LoadingButton>

            </Box>
        </Box>
    )
}

export default CreateSchemeForm