import React from 'react'
import {Box, Button, Stack, useTheme} from "@mui/material"
import error404 from '../../assets/images/illustartions/no-data.svg'
import {useNavigate} from "react-router-dom";


export default function NoPageFound() {

    const theme = useTheme()
    const navigate = useNavigate()

    return (
        <Box sx={{
            display: 'flex',
            flexFlow: 'column',
            mx: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            height: '100vh',
            '& img': {
                maxHeight: '200px',
                objectFit: 'contain'
            },
            '& .buttonGradient': {
                backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                outline: `0px solid ${theme.palette.primary.main}`,
                outlineOffset: 0,
                boxShadow: 0,
                textTransform: 'none',
                transition: 'all 100ms ease',
                '&:hover': {
                    outline: `2px solid ${theme.palette.primary.main}`,
                    outlineOffset: '2px',
                    '&:active': {
                        transform: 'scale(0.98) !important'
                    }
                },
            },


        }}>
            <img src={error404} alt={'error 404'}/>
            No Page Found
            <Button
                variant={'contained'}
                className={'buttonGradient animate__animated animate__fadeInUp'}
                onClick={() => navigate('/')}>
                Go to Home
            </Button>
        </Box>
    )
}