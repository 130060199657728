import React, { useEffect, useState } from 'react'
import { Box, Button, Skeleton, Typography, alpha, useTheme } from '@mui/material'
import moment from 'moment';
import { ApiRounded, TodayRounded } from '@mui/icons-material';
import { FormBlockTitle2 } from '../../../components/title/FormBlockTitle';

import ill from '../../../assets/images/illustartions/support.png'
import API from '../../../api';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { User } from '../../../models/user';
import { camelCaseWords } from '../../../utils/app-helper';
import { useNavigate } from 'react-router';
import AttendanceDialog from '../../../components/attendanceDialog';

function OperatorDashboard() {

    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser

    const navigate = useNavigate()

    return (
        <Box sx={{
            minHeight: '75vh',
            display: 'flex',
            flexFlow: 'column',
            pt: 3,
            gap: 3,
            maxWidth: '550px',
            mx: 'auto',
        }}>
            <Box sx={{ flex: 1 }}><TopBlock /></Box>
            {currentUser?.role?.name == 'operator' && <StatsBlock />}
            <ActionWidgets />
        </Box >
    )
}

export default OperatorDashboard


const ActionWidgets = () => {

    const theme = useTheme()
    const navigate = useNavigate()

    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser

    const actions = [

        ...(currentUser?.role?.name == 'operator' ? [{ label: 'Services', link: 'services' },] : []),
        // ...(currentUser?.provider_id == '1' ? [{ label: 'TER', link: 'ter' }] : []),
        { label: 'Training', link: 'coming-soon' },
        // { label: 'Activity', link: 'coming-soon' },
        ...(currentUser?.is_supervisor_head == '1' ? [
            { label: 'Approve Attendance', link: 'attendance' },
            { label: 'My Team', link: 'my-team' },
        ] : []),
        { label: 'Activity', link: 'activity' },
        { label: 'Reports', link: 'coming-soon' },
        { label: 'Knowledge Check', link: 'coming-soon' },
    ]


    return (
        <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(90px, 1fr))',
            alignItems: 'flex-end',
            gap: 2,
            outline: `1px solid ${alpha(theme.palette.text.disabled, 0.1)}`,
            borderRadius: '32px',
            p: 2,
        }}>
            {actions.map((action, index) => (
                <Button key={index}
                    onClick={() => navigate(`/${action.link}`)}
                    sx={{
                        boxShadow: 0,
                        display: 'flex',
                        flexFlow: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 0.5,
                        background: alpha(theme.palette.primary.main, 0.07),
                        borderRadius: '18px',
                        '& .iconBox': {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '50px',
                            '& svg': {
                                fontSize: '44px',
                                color: theme.palette.text.secondary,
                            },
                        },
                        '& .MuiTypography-root': {
                            maxWidth: '10ch',
                            textAlign: 'center',
                            height: '30px',
                            fontSize: '14px',
                            lineHeight: '16px',
                            fontWeight: 500,
                            textTransform: 'none'
                        },
                    }}>
                    <Box className={'iconBox'}>
                        <ApiRounded />
                    </Box>
                    <Typography>{action.label}</Typography>
                </Button>
            ))}
        </Box>


    )
}

const StatsBlock = () => {

    const theme = useTheme()

    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser

    const [stats, setStats] = useState<any | null>(null)
    const [statsLoading, setStatsLoading] = useState(true)

    const fetchStats = () => {
        setStatsLoading(true)
        API.post('/fetch_operator_dashboard', { id: `${currentUser?.id}` })
            .then((res) => {
                setStats(res.data?.data)
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setStatsLoading(false)
            })

    }

    useEffect(() => {
        fetchStats()
    }, [])

    return (
        <Box
            sx={{
                outline: `1px solid ${alpha(theme.palette.text.disabled, 0.1)}`,
                borderRadius: '32px',
                p: 2, pb: 0.5,
                display: 'flex',
                flexWrap: 'wrap',
                columnGap: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
                '& .demoImage': {
                    '& img': {
                        height: '100px',
                        width: '100px',
                        objectFit: 'contain',

                    },
                },
            }}>
            <Box className={'demoImage'}>
                <img src={ill} alt={'welcome'} />
            </Box>
            <Box
                sx={{
                    flex: 1,
                    '& .MuiTypography-root': {
                        fontSize: '14px',
                        fontWeight: 500,
                        px: 1,
                        ml: 1,
                        display: 'flex',
                        // gridTemplateColumns: '1fr 1fr 1fr',
                        columnGap: 1,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: `1px solid ${alpha(theme.palette.text.disabled, 0.1)}`,
                        '& span': {
                            textAlign: 'left',
                            flex: 1,
                            maxWidth: '110px',
                            '&.val': {
                                textAlign: 'center',
                                flex: 0,
                                minWidth: '50px',
                            },
                        },
                        '&:last-of-type': {
                            borderBottom: 'none',
                        },

                    },
                }}>
                <FormBlockTitle2 icon={false} title='STATS - YTD' />
                <Typography>
                    <span>&nbsp;</span>
                    <span className={'val'}>Acres</span>
                    <span className={'val'}>Tasks</span>
                </Typography>
                <Typography color={theme.palette.success.main}>
                    <span>Successful:</span>
                    <span className={'val'}>{statsLoading ? <Skeleton sx={{ minWidth: '40px' }} /> : +(stats?.successfully_sprayed ?? 0).toFixed(2)}</span>
                    <span className={'val'}>{statsLoading ? <Skeleton sx={{ minWidth: '40px' }} /> : +(stats?.sprayed_order_count ?? 0).toFixed(2)}</span>
                </Typography>
                <Typography color={theme.palette.warning.main}>
                    <span>Pending:</span>
                    <span className={'val'}>{statsLoading ? <Skeleton sx={{ minWidth: '40px' }} /> : +(stats?.total_acerage ?? 0).toFixed(2)}</span>
                    <span className={'val'}>{statsLoading ? <Skeleton sx={{ minWidth: '40px' }} /> : +(stats?.requested_order_count ?? 0).toFixed(2)}</span>
                </Typography>
            </Box>
        </Box>
    )
}

const TopBlock = () => {

    const theme = useTheme()
    const user = useSelector((state: RootState) => state.userAuth)
    const attendance = useSelector((state: RootState) => state.attendance)
    const currentUser: User = user?.currentUser

    const clockedIn = attendance?.in ? attendance.in : null
    const clockedOut = attendance?.out ? attendance.out : null

    const isOnLeave = attendance ? attendance.on_leave == '1' : false

    const [totalDuration, setTotalDuration] = React.useState(moment.duration(
        clockedOut ? moment(clockedOut, 'hh:mm:ss').diff(moment(clockedIn, 'hh:mm:ss')) : moment().diff(moment(clockedIn, 'hh:mm:ss'))
    ))
    const formattedDuration = totalDuration?.isValid() ? moment?.utc(totalDuration?.asMilliseconds())?.format('HH:mm') : '-'


    const [open, setOpen] = useState(false)
    const openAttendanceDialog = () => setOpen(true)
    const closeAttendanceDialog = () => setOpen(false)


    React.useEffect(() => {
        if (!clockedOut) {
            const intervalId = setInterval(() => {
                setTotalDuration(moment.duration(moment().diff(moment(clockedIn, 'HH:mm:ss'))))
            }, 1000);
            return () => clearInterval(intervalId);
        } else {
            setTotalDuration(moment.duration(moment(clockedOut, 'hh:mm:ss').diff(moment(clockedIn, 'hh:mm:ss'))))
        }
    }, [clockedIn, clockedOut])

    return (
        <>
            <Box
                sx={{
                    outline: `1px solid ${alpha(theme.palette.text.disabled, 0.1)}`,
                    borderRadius: '44px',
                    p: 2, pb: 0.5, pl: 3,
                    display: 'flex',
                    flexWrap: 'wrap',
                    columnGap: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                }}>
                <Typography
                    sx={{
                        flex: 1,
                        fontSize: 'clamp(1rem, 7vw, 1.5rem)',
                        fontFamily: theme.typography.h1.fontFamily,
                        color: alpha(theme.palette.primary.contrastText, 0.3),
                        display: 'grid',
                        '& span': {
                            color: theme.palette.primary.contrastText,
                            // fontStyle: 'italic',
                            fontSize: '16px',
                            fontWeight: 600,
                            mb: 1,
                            '&.timing': {
                                fontWeight: 500,
                                fontSize: '12px',
                                fontStyle: 'normal',
                                mb: 0,
                                '&:has(svg)': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                },
                                '& svg': {
                                    height: '14px',
                                    width: '14px'
                                },
                            },
                        },
                    }}>
                    Good day,
                    <span>{camelCaseWords(currentUser?.name ?? 'Buddy')}</span>
                    <span className={'timing'}> <TodayRounded /> {attendance?.date
                        ? moment(attendance?.date, 'YYYY-MM-DD').format('DD MMMM, YYYY')
                        : moment().format('DD MMMM, YYYY')
                    }</span>
                    {isOnLeave
                        ? <>
                        </>
                        : <>
                            <span className={'timing'}>In &nbsp;&nbsp;&nbsp; : {clockedIn ? `${moment(clockedIn, 'hh:mm:ss').format('hh:mm:ss A')}` : '-'}</span>
                            <span className={'timing'}>Out : {clockedOut ? `${moment(clockedOut, 'hh:mm:ss').format('hh:mm:ss A')}` : '-'}</span>
                        </>}
                </Typography>

                <Box
                    onClick={openAttendanceDialog}
                    sx={{
                        height: '90px',
                        width: '140px',
                        borderRadius: '28px',
                        boxShadow: `0 0 17px -4px ${theme.palette.background.paper} inset`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: isOnLeave ? theme.palette.error.main : theme.palette.primary.main,
                        '& .MuiButton-root': {
                            borderRadius: '18px',
                            minHeight: '70px',
                            width: '120px',
                            backgroundImage: `linear-gradient(135deg, ${!clockedIn ? theme.palette.primary.main : theme.palette.error.main}, ${!clockedIn ? theme.palette.secondary.main : theme.palette.warning.main})`,
                            textTransform: 'none',
                            fontSize: '18px',
                        },
                    }}>
                    {isOnLeave
                        ? <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexFlow: 'column',
                            }}>
                            <Typography sx={{
                                fontWeight: 600,
                                fontSize: 'clamp(1.3rem, 3vw, 1.5rem)',
                                lineHeight: 'clamp(1.3rem, 3vw, 1.5rem)',
                                color: theme.palette.error.contrastText,
                            }}>Leave</Typography>
                        </Box>
                        : (!clockedIn || !clockedOut) ?
                            <Button variant='contained'
                            >
                                {!clockedIn ? `Clock in` : (clockedIn && !clockedOut) ? `Clock out` : 'Completed'}
                            </Button>
                            : <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexFlow: 'column',
                                    color: theme.palette.primary.contrastText,
                                }}>
                                <Typography sx={{
                                    fontWeight: 500,
                                    fontSize: 'clamp(0.75rem, 3vw, 0.8rem)',
                                    lineHeight: 'clamp(0.75rem, 3vw, 0.8rem)',
                                    mb: 1,
                                }}>
                                    You worked today
                                </Typography>
                                <Typography sx={{
                                    fontWeight: 600,
                                    fontSize: 'clamp(1.3rem, 3vw, 1.5rem)',
                                    lineHeight: 'clamp(1.3rem, 3vw, 1.5rem)',
                                }}>{formattedDuration}</Typography>
                                <Typography sx={{
                                    fontWeight: 500,
                                    fontSize: 'clamp(0.75rem, 3vw, 0.8rem)',
                                    lineHeight: 'clamp(0.75rem, 3vw, 0.8rem)',
                                }}>
                                    hours
                                </Typography>
                            </Box>
                    }
                </Box>


                <Typography
                    sx={{
                        fontSize: '12px',
                        width: '100%',
                        textAlign: 'center',
                        mt: 2,
                        color: isOnLeave ? theme.palette.primary.contrastText : theme.palette.text.disabled,
                    }}>
                    {isOnLeave
                        ? `🔔 Marked leave for today`
                        : (!clockedIn || !clockedOut)
                            ? !clockedIn ? `Press Clock In to Start the day` : `Press Clock Out to Close for the day`
                            : `Buddy! Done for the day.`
                    }
                </Typography>
            </Box>

            <AttendanceDialog open={open} onCloseDialog={closeAttendanceDialog} />

        </>
    )
}