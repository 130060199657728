import React from 'react'
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { AppBar, Box, useTheme } from "@mui/material";
import { MenuOpenRounded, MenuRounded } from "@mui/icons-material";
import UserMenu from "./user-menu";
import { useNavigate } from "react-router";
import ThemeColorToggle from "../../components/theme-color-toggle";
import logo from "../../assets/images/app-icons/agri-wing-logo.svg";
import UserAttendance from '../../components/UserAttendance';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';



export default function TopBar(props: any) {


    const { handleDrawerOpen, smallScreen, open } = props
    const theme = useTheme()
    const navigate = useNavigate()
    const currentUser = useSelector((state: RootState) => state.userAuth?.currentUser)
    const attendance = useSelector((state: RootState) => state.attendance)

    return (
        <AppBar position="fixed"

            sx={{
                zIndex: theme.zIndex.drawer + 1,
                color: theme.palette.text.primary,
                background: theme.palette.background.paper,
                boxShadow: 0,
                '& .MuiToolbar-root': {
                    pr: 1
                },
            }}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{ mr: 2 }}
                >
                    {open ? <MenuOpenRounded /> : <MenuRounded />}
                </IconButton>
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: (currentUser?.provider_id == '1' && (currentUser?.role?.name == 'rm' || currentUser?.role?.name == 'rtl')) ? (smallScreen ? 'flex-start' : 'flex-start') : 'center',
                    '& .logoImage': {
                        maxHeight: '40px',
                        objectFit: 'contain',
                    },
                }}>
                    <img onClick={() => navigate(('/'))} className={'logoImage'} src={theme.palette.mode == 'dark' ? logo : logo} alt={'hey-there-logo'} />
                </Box>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                }}>
                    {(currentUser?.provider_id == '1' && (currentUser?.role?.name == 'rm' || currentUser?.role?.name == 'rtl')) && <UserAttendance />}
                    {smallScreen ? <UserMenu /> : <ThemeColorToggle />}
                </Box>
            </Toolbar>
        </AppBar>
    )
}