import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Avatar, Box, Button, Chip, Divider, FormControlLabel, IconButton, InputAdornment, MenuItem, Paper, Radio, RadioGroup, Stack, Tooltip, Typography, alpha, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import toast from 'react-hot-toast'
import { DatePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { ChevronRightRounded, CloseOutlined, CurrencyRupeeRounded, EditRounded, InfoOutlined, PhoneRounded, TaskAltRounded } from '@mui/icons-material'

import API from '../../../api'
import PageContainer from '../../../components/container/page-container'
import TitleBar from '../../../components/container/TitleBar'
import SuccessSvg from '../../../components/svgs/successSvg'
import { ThemeTextField, themeTextFieldSx } from '../../../components/inputs/theme-text-field'
import farmerImage from '../../../assets/images/illustartions/farmer.png'
import { User } from '../../../models/user'
import { RootState } from '../../../store/store'
import { updateProcessState } from '../../../slices/process-slice'
import { useFarmers } from '../../../hooks/useFarmers'
import { useDocumentTitle } from '../../../hooks/useDocumnetTitle'
import { useAvailableCrops } from '../../../hooks/useAvailableCrops'
import { useRegionalClients } from '../../../hooks/useRegionalClinets'
import { camelCaseWords, currencyFormatter, scrollToTop } from '../../../utils/app-helper'

const orderTypes = [
    { name: 'General Order', value: '1' },
    { name: 'R&D Order', value: '4' },
    { name: 'Demo Order', value: '5' },
]

export interface FetchedScheme {
    id: string
    scheme_name: string
    discount_price: string
    type: string
}


function CreateService() {

    useDocumentTitle('Create Service Request')

    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser

    const [activeStep, setActiveStep] = useState<number>(1)
    const [fetchedSchemes, setFetchedSchemes] = useState<FetchedScheme[]>([])

    const [orderType, setOrderType] = useState({ name: 'General Order', value: '1' })

    const { fetchRegionalClients, fetchingRegionalClients, regionalClients } = useRegionalClients()
    const [client, setClient] = useState({} as any)

    const { farmers, fetchFarmers, fetchingFarmers } = useFarmers()
    const [selectedFarmer, setSelectedFarmer] = useState({} as any)

    const [searchedFarmers, setSearchedFarmers] = useState<any[]>([])

    const [selectedCropPrice, setSelectedCropPrice] = useState<null | number>(null)

    const { availableCrops, fetchAvailableCrops, fetchingAvailableCrops } = useAvailableCrops()

    const [submitting, setSubmitting] = useState(false)
    const [discountShown, setDiscountShown] = useState(false)
    const [paymentType, setPaymentType] = useState<null | 'prepaid' | 'postpaid'>(null)

    const { handleSubmit, control, reset, watch, setValue, clearErrors, formState: { errors } } = useForm()

    const inputData = watch()
    const resetPage = () => {
        setSelectedFarmer({} as any)
        activeStepOne()
    }

    // const filteredFarmers = farmers?.filter((el: any) => el?.farmer_mobile_no == searchedFarmers)

    const applicableSchemes = () => {
        if ((+inputData.acreage <= (+selectedFarmer?.farm_info?.filter((el: any) => el.id == inputData?.farm_location)[0]?.acerage ?? 30) && +inputData.acreage <= 30)) {
            dispatch(updateProcessState(true))
            API.post('/apply_order_scheme', {
                order_type: orderType.value,
                client_id: `${client?.id}`,
                crop_id: `${inputData.crop?.id}`,
                requested_acreage: inputData.acreage
            })
                .then((res) => {
                    console.log('amit', res)
                    setFetchedSchemes(res.data?.data?.schemes)
                    setSelectedCropPrice(res.data?.data?.crop_price)
                    setActiveStep(4)
                    scrollToTop()
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    dispatch(updateProcessState(false))
                })
        } else {
            toast.error('Error! Acreage exceeds.')
        }
    }

    const offeredDiscount = fetchedSchemes?.reduce((total, scheme) => total + (+scheme.discount_price * +inputData?.acreage), 0) + (+inputData?.extra_discount)
    const totalCharges = orderType.value == '1' ? +(+inputData?.acreage * (selectedCropPrice ?? 0)) : offeredDiscount
    const totalPayable = totalCharges - offeredDiscount
    const agriwingsDiscount = fetchedSchemes?.filter((el) => el.type == '1')?.reduce((total, scheme) => total + (+scheme.discount_price * +inputData?.acreage), 0) + (+inputData?.extra_discount)
    const clientDiscount = offeredDiscount - agriwingsDiscount


    const activeStepOne = () => {
        setActiveStep(1)
        setOrderType({ name: 'General Order', value: '1' })
        setClient({})
        setSelectedFarmer({})
        setSelectedCropPrice(null)
        setPaymentType(null)
        reset()
        document.getElementById('step-1')?.scrollIntoView({ behavior: 'smooth' })
    }

    const activeStepTwo = () => {
        setActiveStep(2)
        setSelectedFarmer({})
        setSelectedCropPrice(null)
        setPaymentType(null)
        document.getElementById('step-2')?.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

    const activeStepThree = () => {
        setActiveStep(3)
        setSelectedCropPrice(null)
        setPaymentType(null)
        document.getElementById('step-3')?.scrollIntoView({ behavior: 'smooth' })
    }

    const activeStepFour = () => {
        setActiveStep(4)
        document.getElementById('step-4')?.scrollIntoView({ behavior: 'smooth' })
    }
    const activeStepFive = () => {
        (offeredDiscount > totalCharges) ? toast.error('Invalid discount aaplied.') : setActiveStep(5)
        document.getElementById('step-5')?.scrollIntoView({ behavior: 'smooth' })
    }

    const createRequest = (data: any) => {

        console.log('data', data)
        const { date_of_spray, crop, client, farmer, client_order_id, ...restData } = data

        const newData = {
            ...restData,
            order_type: orderType?.value,
            client_id: `${client?.id}`,
            farmer_name: farmer?.farmer_name,
            farmer_id: `${farmer?.id}`,
            spray_date: moment(date_of_spray).format('YYYY-MM-DD'),
            crop_name: crop?.crop_name,
            crop_id: `${crop?.id}`,
            requested_acreage: data?.acreage,
            farm_location: `${data.farm_location}`,
            scheme_ids: fetchedSchemes.map(scheme => scheme.id).join(',') ?? '',
            total_amount: `${+totalCharges.toFixed(3)}`,
            total_discount: `${+offeredDiscount.toFixed(3)}`,
            total_payable_amount: `${+totalPayable.toFixed(3)}`,
            remarks: data.extra_discount_remarks,
            payment_type: paymentType == 'prepaid' ? '1' : '2',
            amount_received: '[]',
            agriwings_discount: `${+agriwingsDiscount.toFixed(3)}`,
            client_discount: `${+clientDiscount.toFixed(3)}`,
            client_order_id: data.client_order_id,
            // client_order_id: client.id == '1' ? `` : data.client_order_id,
        }

        setSubmitting(true)
        dispatch(updateProcessState(true))

        API.post('/submit_order_details', newData)
            .then((res) => {
                if (res.data.status == 'success') {
                    toast.success('Service request created successfully!')
                    navigate('/services')
                }
                if (res.data.status == 'error') {
                    toast.error(`Failed, ${res.data?.msg}`)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setSubmitting(false)
                dispatch(updateProcessState(false))
            })

    }

    useEffect(() => {
        fetchRegionalClients()
        fetchFarmers()
        fetchAvailableCrops()
    }, [])



    return (
        <Box className={'animate__animated animate__fadeIn'}
            sx={{
                flex: 1,
                position: 'relative',
                pb: 10,
            }}>
            <PageContainer>
                <TitleBar title={'Create Service'} backIcon backPath={'/services'} />

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexFlow: 'column',
                        gap: 2,
                        px: { xs: 0, sm: '3%' },
                        minHeight: '45vh',
                        '& .MuiAccordion-root': {
                            background: theme.palette.mode == 'dark'
                                ? '#0e0e0e'
                                : `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.07)}, ${theme.palette.background.paper})`,
                            boxShadow: theme.palette.mode == 'dark' ? 'none' : `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                            border: `2px solid ${theme.palette.background.paper}`,
                            width: '100%',
                            maxWidth: { xs: '100%', sm: 'min(90vw, 767px)' },
                            borderRadius: '2rem !important',
                            position: 'relative',
                            '&:before': {
                                display: 'none',
                            },
                            '& .MuiIconButton-root': {
                                '&.actionIcon': {
                                    position: 'absolute',
                                    right: { xs: '8px', sm: '14px' },
                                    top: { xs: '8px', sm: '16px' },
                                    height: '24px',
                                    width: '24px',
                                    boxShadow: '0 0 12px -3px #83838390',
                                    border: `1px solid ${theme.palette.primary.main}`,
                                    color: theme.palette.primary.main,
                                    zIndex: 1,
                                    '& svg': {
                                        height: '16px',
                                        width: '16px'
                                    },
                                },
                            },
                            '& .MuiAccordionDetails-root': {
                                pb: 0
                            },
                            '& .MuiAccordionSummary-root': {
                                p: { xs: '8px', sm: '0 16px' },
                            },
                            '& .MuiAccordionSummary-content': {
                                alignSelf: 'stretch',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 2,
                                margin: 0,
                                padding: '4px 12px 4px 4px',
                                '&.Mui-expanded': {
                                    my: 1.5,
                                    '& .accordianTitle': {
                                        '& span': {
                                            background: theme.palette.primary.main,
                                            color: theme.palette.primary.contrastText,
                                        },
                                    },
                                },
                            },
                            '& .accordianTitle': {
                                fontFamily: theme.typography.h1.fontFamily,
                                color: theme.palette.text.secondary,
                                fontWeight: 500,
                                fontSize: '14px',
                                display: 'flex',
                                gap: 1,
                                minWidth: '25%',
                                '& span': {
                                    height: '20px',
                                    minWidth: '20px',
                                    borderRadius: '50vh',
                                    background: theme.palette.text.secondary,
                                    color: theme.palette.background.default,
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    display: 'grid',
                                    placeItems: 'center',
                                },
                            },
                            '& .accordionDetail': {
                                alignSelf: 'stretch',
                                flex: '1 1 300px',
                                background: theme.palette.background.paper,
                                padding: '4px',
                                borderRadius: '24px',
                                position: 'relative',
                                display: 'flex',
                                // flexWrap: 'wrap',
                                alignItems: 'center',
                                '& .detail': {
                                    flex: '1 1 220px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    gap: '4px',
                                    '& .MuiTypography-root': {
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        color: theme.palette.primary.main,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                        '&.multiDetail': {
                                            display: 'flex',
                                            flexFlow: 'column',
                                            // flexFlow: { xs: 'row', sm: 'column' },
                                            // justifyContent: { xs: 'space-around', sm: 'flext-start' },
                                            alignItems: 'flex-start',
                                            fontSize: '15px',
                                            lineHeight: '15px',
                                            gap: 0.5,
                                            pl: 1.5,
                                            // minWidth: { xs: '100%', sm: 'auto' },
                                            '& span': {
                                                fontSize: '12px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: theme.palette.text.secondary,
                                                '& svg': {
                                                    fontSize: '12px',
                                                },
                                            },
                                        },
                                    },
                                },

                                '& .MuiChip-root': {
                                    textTransform: 'capitalize',
                                },
                                '& .MuiDivider-root': {
                                    display: { xs: 'none', sm: 'flex' }
                                },
                            },
                            '& .nextButton': {
                                mb: 1.5,
                                flex: { xs: '1 1 240px', sm: '0 1 140px' },
                                ml: 'auto'
                            },
                        },
                        '& .formBlock': {
                            background: theme.palette.mode == 'dark'
                                ? '#0e0e0e'
                                : `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.07)}, ${theme.palette.background.paper})`,
                            boxShadow: theme.palette.mode == 'dark' ? 'none' : `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                            border: `2px solid ${theme.palette.background.paper}`,
                            flex: 1,
                            maxWidth: 'min(90vw, 500px)',
                            borderRadius: '2rem',
                            p: 3,
                            minHeight: '370px',
                            position: 'relative',
                            '& .actionBar': {
                                position: 'absolute',
                                bottom: '-2rem',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                width: 'min(90%, 300px)',
                                display: 'flex',
                                flexFlow: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                '& .submitButton': {
                                    width: '100%',
                                    textTransform: 'capitalize',
                                },
                                '& .changeButton': {
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                    borderRadius: 0,
                                    '&:hover': {
                                        background: alpha(theme.palette.primary.main, 0.05),
                                        borderRadius: '50vh',
                                    }
                                }
                            },
                        },
                    }}>

                    {activeStep == 6
                        ? <SuccessBlock resetPage={resetPage} />
                        : <>
                            <Accordion id={'step-1'} expanded={activeStep === 1}>
                                <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header" >
                                    <Typography className={'accordianTitle'}>
                                        <span>1</span> Order Type & Client
                                    </Typography>
                                    {activeStep > 1 && activeStep !== 1 &&
                                        <>
                                            <IconButton className={'actionIcon'} onClick={activeStepOne}><EditRounded /></IconButton>
                                            <Box className={'accordionDetail animate__animated animate__fadeIn'}>
                                                <Stack className={'detail'}>
                                                    {orderType && <Chip sx={{ minHeight: '40px', borderRadius: '50vh' }} label={`${orderType.name}`} />}
                                                    {client.regional_client_name} ({inputData?.client_order_id})
                                                </Stack>
                                            </Box>
                                        </>
                                    }
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Divider />
                                    {activeStep === 1 &&
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                alignItems: 'flex-start',
                                                columnGap: 3,
                                                pt: 3,
                                                '& .formInput': {
                                                    flex: '1 1 300px'
                                                },
                                            }}>
                                            <ThemeTextField
                                                size={'small'} label={'Order Type'}
                                                placeholder={'select type'} select
                                                className='formInput'
                                                defaultValue={'1'}
                                                onChange={(e) => setOrderType({ name: e.target.value == '1' ? 'General Order' : e.target.value == '4' ? 'R&D Order' : 'Demo Order', value: e.target.value })}
                                            >
                                                {orderTypes.map((type, index) => <MenuItem key={index} value={type.value}>{type.name}</MenuItem>)}
                                            </ThemeTextField>
                                            <Controller name={`client`}
                                                control={control}
                                                rules={{ required: { value: inputData?.order_type != 'general_order', message: 'Required' } }}
                                                defaultValue={''}
                                                render={({ field: { onChange, value } }) => (
                                                    <Autocomplete size={'small'}
                                                        autoComplete
                                                        loading={fetchingRegionalClients}
                                                        className={'formInput'}
                                                        onChange={(e, data) => {
                                                            onChange(data)
                                                            setClient(data)
                                                        }}
                                                        noOptionsText={'Clients Not Available'}
                                                        value={value || null}
                                                        sx={{ flex: '1 1 250px' }}
                                                        options={
                                                            currentUser?.client_id
                                                                ? regionalClients.filter(item => currentUser?.client_id?.split(',').map(Number).includes(item.id))
                                                                : regionalClients
                                                        }
                                                        getOptionDisabled={(option: any) => option?.status == '0'}
                                                        getOptionLabel={(option) => camelCaseWords(option?.regional_client_name) ?? ''}
                                                        isOptionEqualToValue={((option: any, value: any) => option.id == value.id)}
                                                        renderOption={(props, option, { selected }) => (
                                                            <Box component={'li'} {...props} key={props.id}
                                                                sx={{
                                                                    p: '2px 6px',
                                                                    textAlign: 'left',
                                                                    borderRadius: '12px',
                                                                    mx: '2px',
                                                                    fontSize: '15px',
                                                                    lineHeight: '15px',
                                                                    fontWeight: 500,
                                                                    color: theme.palette.text.secondary,
                                                                    textTransform: 'uppercase',
                                                                }}>
                                                                {option?.regional_client_name}
                                                            </Box>

                                                        )}
                                                        renderInput={(params) => (
                                                            <ThemeTextField
                                                                {...params}
                                                                required={inputData?.order_type != 'general_order'}
                                                                error={Boolean(errors?.client)}
                                                                helperText={(errors?.client?.message ?? '').toString()}
                                                                size={'small'} label={'Client'}
                                                                placeholder={'Please select client'}
                                                            />
                                                        )} />
                                                )} />

                                            <Controller name={`client_order_id`}
                                                control={control}
                                                rules={{
                                                    required: { value: false, message: '' },
                                                    // pattern: { value: /^[a-zA-Z0-9 ]*$/, message: 'Invalid input' },
                                                }}
                                                defaultValue={''}
                                                render={({ field }) => (
                                                    <ThemeTextField
                                                        {...field} className={'formInput'}
                                                        // disabled={client ? client.id == '1' : false}
                                                        error={Boolean(errors?.client_order_id)}
                                                        inputProps={{ maxLength: 15 }}
                                                        helperText={(errors?.client_order_id?.message ?? '').toString()}
                                                        size={'small'} label={'Client Order Id'}
                                                        onBlur={(e) => setValue('client_order_id', e.target.value?.toUpperCase())}
                                                        placeholder={'client_order_id'}
                                                    />
                                                )} />

                                            <Button
                                                className={'nextButton'}
                                                variant={'contained'}
                                                disabled={!client?.id}
                                                onClick={() => {
                                                    setActiveStep(2)
                                                    scrollToTop()
                                                }}>Continue</Button>
                                        </Box>
                                    }
                                </AccordionDetails>
                            </Accordion>

                            <Accordion id={'step-2'} expanded={activeStep === 2}>
                                <AccordionSummary aria-controls="panel2bh-content" id="panel2bh-header" >
                                    <Typography className={'accordianTitle'}>
                                        <span>2</span> Farmer Info
                                    </Typography>
                                    {activeStep > 2 && activeStep != 2 &&
                                        <>
                                            <IconButton className={'actionIcon'} onClick={activeStepTwo}><EditRounded /></IconButton>
                                            <Box className={'accordionDetail animate__animated animate__fadeIn'}>
                                                <Avatar src={farmerImage} />
                                                <Stack className={'detail'}>
                                                    <Typography className={'multiDetail'}>
                                                        {camelCaseWords(selectedFarmer?.farmer_name)}
                                                        <span><PhoneRounded /> +91 {selectedFarmer?.farmer_mobile_no}</span>
                                                    </Typography>
                                                    <Divider orientation='vertical' sx={{ mx: 2, }} />
                                                    <Typography className={'multiDetail'}>
                                                        <span>Total Farms: {selectedFarmer?.farm_info?.length}</span>
                                                        <span>Total Acreage: {selectedFarmer?.farm_info?.reduce((total: number, farm: any) => total + (+farm.acerage), 0)}</span>
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        </>
                                    }
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Divider />
                                    {activeStep === 2 &&
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                alignItems: 'flex-start',
                                                columnGap: 3,
                                                pt: 3,
                                                '& .formInput': {
                                                    flex: '1 1 300px'
                                                },
                                            }}>

                                            <Controller name={`farmer`}
                                                control={control}
                                                rules={{ required: { value: true, message: 'Required' } }}
                                                defaultValue={''}
                                                render={({ field: { onChange, value } }) => (
                                                    <Autocomplete size={'small'}
                                                        autoComplete
                                                        loading={fetchingFarmers}
                                                        className={'formInput'}
                                                        onChange={(e, data) => {
                                                            onChange(data)
                                                            setSelectedFarmer(data)
                                                        }}
                                                        onInputChange={(e, val) => {
                                                            if (!(currentUser?.role?.name != 'client' && currentUser?.role?.name != 'cso' && currentUser?.provider_id == '1')) {
                                                                if (val?.length == 10) {
                                                                    const filtered = farmers?.filter((el: any) => el?.farmer_mobile_no == val)
                                                                    setSearchedFarmers(filtered)
                                                                }
                                                            }
                                                        }}
                                                        noOptionsText={
                                                            (currentUser?.role?.name != 'client' && currentUser?.role?.name != 'cso' && currentUser?.provider_id == '1')
                                                                ? 'Farmers Not Available'
                                                                : 'Please enter the mobile number to search farmer'
                                                        }
                                                        onClose={() => setSearchedFarmers([])}
                                                        value={value || null}
                                                        sx={{ flex: '1 1 250px' }}
                                                        options={(currentUser?.role?.name != 'client' && currentUser?.role?.name != 'cso' && currentUser?.provider_id == '1') ? farmers : searchedFarmers}
                                                        getOptionLabel={(option) => `${camelCaseWords(option?.farmer_name)} - ${option?.farmer_mobile_no}` ?? ''}
                                                        isOptionEqualToValue={((option: any, value: any) => option.id == value.id)}
                                                        renderOption={(props, option, { selected }) => (
                                                            <Box component={'li'} {...props} key={props.id}
                                                                sx={{
                                                                    p: '2px 6px',
                                                                    textAlign: 'left',
                                                                    borderRadius: '12px',
                                                                    mx: '2px',
                                                                    fontSize: '15px',
                                                                    lineHeight: '15px',
                                                                    fontWeight: 500,
                                                                    color: theme.palette.text.secondary,
                                                                }}>
                                                                {/* {camelCaseWords(option?.farmer_name)} - {option?.farmer_mobile_no} */}
                                                                {camelCaseWords(option?.farmer_name)} {(currentUser?.role?.name != 'client' && currentUser?.role?.name != 'cso' && currentUser?.provider_id == '1') && `- ${option?.farmer_mobile_no}`}
                                                            </Box>

                                                        )}
                                                        renderInput={(params) => (
                                                            <ThemeTextField
                                                                {...params}
                                                                required={inputData?.order_type != 'general_order'}
                                                                error={Boolean(errors?.farmer)}
                                                                helperText={(errors?.farmer?.message ?? '').toString()}
                                                                size={'small'} label={'Farmers'}
                                                                placeholder={'Please select farmer'}
                                                            />
                                                        )} />
                                                )} />


                                            <Button
                                                className={'nextButton'}
                                                variant={'contained'}
                                                disabled={!selectedFarmer?.id}
                                                onClick={() => {
                                                    setActiveStep(3)
                                                    scrollToTop()
                                                }}>Continue</Button>
                                        </Box>
                                    }
                                </AccordionDetails>
                            </Accordion>

                            <Accordion id={'step-3'} expanded={activeStep === 3}>
                                <AccordionSummary aria-controls="panel3bh-content" id="panel3bh-header" >
                                    <Typography className={'accordianTitle'}>
                                        <span>3</span> Spray Info
                                    </Typography>
                                    {activeStep > 3 && activeStep != 3 &&
                                        <>
                                            <IconButton className={'actionIcon'} onClick={activeStepThree}><EditRounded /></IconButton>
                                            <Box className={'accordionDetail animate__animated animate__fadeIn'}>
                                                <Avatar src={farmerImage} />
                                                <Stack className={'detail'}>
                                                    <Typography className={'multiDetail'}>
                                                        <span>Acreage: {inputData?.acreage}</span>
                                                        <span>Crop: {camelCaseWords(inputData?.crop?.crop_name)} ({currencyFormatter(selectedCropPrice ? +selectedCropPrice : 0)})</span>
                                                    </Typography>
                                                    <Divider orientation='vertical' sx={{ mx: 2, }} />
                                                    <Typography className={'multiDetail'}>
                                                        <span>Location: {camelCaseWords(selectedFarmer?.farm_info?.filter((el: any) => el.id == inputData?.farm_location)[0]?.address)}</span>
                                                        <span>Date: {moment(inputData?.date_of_spray).format('DD MMM YYYY')}</span>
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        </>
                                    }
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Divider />
                                    {activeStep === 3 &&
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                alignItems: 'flex-start',
                                                columnGap: 3,
                                                pt: 3,
                                                '& .formInput': {
                                                    flex: '1 1 300px',
                                                    '&.mb-3': {
                                                        mb: '24px !important',
                                                    },
                                                },
                                            }}>

                                            <Controller
                                                name={`date_of_spray`}
                                                control={control}
                                                rules={{ required: { value: true, message: 'Really! Without date?' } }}
                                                render={({ field }) => (
                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                        <MobileDatePicker
                                                            {...field}
                                                            disablePast
                                                            label="Date of Spray"
                                                            className={'formInput mb-3'}
                                                            value={field.value ? moment(field.value) : null}
                                                            onChange={(date) => {
                                                                setValue('date_of_spray', date);
                                                                clearErrors('date_of_spray')
                                                            }}
                                                            slotProps={{
                                                                textField: {
                                                                    required: true,
                                                                    size: 'small',
                                                                    sx: themeTextFieldSx,
                                                                    style: { width: '100%' },
                                                                    error: Boolean(errors?.date_of_spray),
                                                                    helperText: errors?.date_of_spray?.message?.toString() ?? '',
                                                                }
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                )} />
                                            <Controller name={`crop`}
                                                control={control}
                                                rules={{ required: { value: true, message: 'Required' } }}
                                                defaultValue={''}
                                                render={({ field: { onChange, value } }) => (
                                                    <Autocomplete size={'small'}
                                                        autoComplete
                                                        loading={fetchingAvailableCrops}
                                                        className={'formInput'}
                                                        onChange={(e, data) => onChange(data)}
                                                        noOptionsText={'No Crops Available'}
                                                        value={value || null}
                                                        sx={{ flex: '1 1 250px' }}
                                                        options={availableCrops}
                                                        getOptionLabel={(option) => camelCaseWords(option?.crop_name) ?? ''}
                                                        isOptionEqualToValue={((option: any, value: any) => option.id == value.id)}
                                                        renderOption={(props, option, { selected }) => (
                                                            <Box component={'li'} {...props} key={props.id}
                                                                sx={{
                                                                    p: '2px 6px',
                                                                    textAlign: 'left',
                                                                    borderRadius: '12px',
                                                                    mx: '2px',
                                                                    fontSize: '15px',
                                                                    lineHeight: '15px',
                                                                    fontWeight: 500,
                                                                    color: theme.palette.text.secondary,
                                                                }}>
                                                                {camelCaseWords(option?.crop_name)}
                                                            </Box>

                                                        )}
                                                        renderInput={(params) => (
                                                            <ThemeTextField
                                                                {...params} required
                                                                error={Boolean(errors?.crop)}
                                                                helperText={(errors?.crop?.message ?? '').toString()}
                                                                size={'small'} label={'Crop'}
                                                                placeholder={'Please select crop'}
                                                            />
                                                        )} />
                                                )} />
                                            <Controller name={`acreage`}
                                                control={control}
                                                rules={{
                                                    required: { value: true, message: 'Acreage value is required' },
                                                    max: {
                                                        value: +selectedFarmer?.farm_info?.filter((el: any) => el.id == inputData?.farm_location)[0]?.acerage ?? 30,
                                                        message: 'Acreage exceeds'
                                                    },
                                                }}
                                                defaultValue={''}
                                                render={({ field }) => (
                                                    <ThemeTextField
                                                        {...field} required className={'formInput'}
                                                        onWheel={(e) => e.currentTarget.blur()}
                                                        onBlur={(e) => setValue('acreage', ((+e.target.value < 0.1) ? '' : (+e.target.value).toFixed(2)), { shouldValidate: true })}
                                                        error={Boolean(errors?.acreage)} type={'number'}
                                                        helperText={(errors?.acreage?.message ?? '').toString()}
                                                        size={'small'} label={'Acreage'}
                                                        placeholder={'acreage'}
                                                    />
                                                )} />
                                            <Controller name={`farm_location`}
                                                control={control}
                                                rules={{ required: { value: true, message: 'Where to spray?' } }}
                                                defaultValue={''}
                                                render={({ field }) => (
                                                    <ThemeTextField
                                                        {...field} required className={'formInput'}
                                                        error={Boolean(errors?.farm_location)} select
                                                        helperText={(errors?.farm_location?.message ?? '').toString()}
                                                        size={'small'} label={'Farm Location'}
                                                        placeholder={'farm_location'}
                                                    >
                                                        {selectedFarmer?.farm_info?.length > 0 ?
                                                            selectedFarmer?.farm_info.map((farm: any, index: number) => (
                                                                <MenuItem key={index} value={farm.id}>{camelCaseWords(farm?.address)}</MenuItem>
                                                            ))
                                                            : []
                                                        }
                                                    </ThemeTextField>
                                                )} />
                                            <Button
                                                className={'nextButton'}
                                                variant={'contained'}
                                                disabled={!inputData.date_of_spray || !inputData.crop || !inputData.acreage || !inputData.farm_location}
                                                onClick={() => {
                                                    applicableSchemes()
                                                }}>Continue</Button>
                                        </Box>
                                    }
                                </AccordionDetails>
                            </Accordion>

                            <Accordion id={'step-4'} expanded={activeStep === 4}>
                                <AccordionSummary aria-controls="panel4bh-content" id="panel4bh-header" >
                                    <Typography className={'accordianTitle'}>
                                        <span>4</span> Scheme Info
                                    </Typography>
                                    {activeStep > 4 && activeStep != 4 &&
                                        <>
                                            <IconButton className={'actionIcon'} onClick={activeStepFour}><EditRounded /></IconButton>
                                            <Box className={'accordionDetail animate__animated animate__fadeIn'}>
                                                <Box sx={{
                                                    borderRadius: '50vh',
                                                    boxShadow: `0 0 12px -3px ${theme.palette.success.main} inset`,
                                                    height: '40px',
                                                    display: 'grid',
                                                    placeItems: 'center',
                                                    px: 2,
                                                    mr: 2,
                                                    fontSize: '13px',
                                                    minWidth: '100px',
                                                    color: theme.palette.success.main,
                                                }}>
                                                    {currencyFormatter(offeredDiscount)}
                                                </Box>
                                                <Stack className={'detail'} direction={'row'}>
                                                    <Typography sx={{ color: `${theme.palette.text.secondary} !important` }}>
                                                        <span>{fetchedSchemes?.length} Schemes & Extra</span>
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        </>
                                    }
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Divider />
                                    {activeStep === 4 &&
                                        <Box
                                            sx={{
                                                pt: 3,
                                                '& .formInput': {
                                                    flex: '1 1 200px',
                                                    width: '100%',
                                                    maxWidth: '340px',
                                                },
                                            }}>

                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    px: { xs: 0, ms: 4, md: 7 },
                                                    display: 'grid',
                                                    placeItems: 'center',
                                                    '& .detailContainer': {
                                                        background: 'linear-gradient(45deg, lime, yellow, red, magenta, blue, aqua)',
                                                        p: '0 1px 2px 0',
                                                        borderRadius: '12px',
                                                        width: '100%',
                                                        maxWidth: '340px',
                                                        flex: 1,
                                                        mb: 1,
                                                        animation: 'borderAnimationKeyframes 3000ms infinite linear',
                                                        '& .detailStack': {
                                                            flex: 1,
                                                            boxShadow: '0 0 12px -3px #83838370',
                                                            p: '3px 3px 3px 6px',
                                                            background: theme.palette.background.paper,
                                                            borderRadius: '11px',
                                                            '& .detail': {
                                                                fontWeight: 500,
                                                                flex: 1,
                                                                fontSize: '12px',
                                                                px: 1,
                                                                textTransform: 'capitalize',
                                                                '&:last-of-type': {
                                                                    textAlign: 'center',
                                                                    maxWidth: '25%',
                                                                    background: alpha(theme.palette.success.main, 0.1),
                                                                    color: theme.palette.success.main,
                                                                    borderRadius: '8px',
                                                                },
                                                            },
                                                        },
                                                        '&:last-of-type': {
                                                            mb: 2,
                                                        },
                                                        '@keyframes borderAnimationKeyframes': {
                                                            '0%': {
                                                                background: 'linear-gradient(90deg,lime, yellow, red, magenta, blue, aqua)',
                                                            },
                                                            '50%': {
                                                                background: 'linear-gradient(-270deg, aqua, blue, magenta, red, yellow, lime )',
                                                            },
                                                            '100%': {
                                                                background: 'linear-gradient(90deg,lime, yellow, red, magenta, blue, aqua)',
                                                            },
                                                        },
                                                    },
                                                }}>

                                                {orderType.value != '1' && fetchedSchemes?.length < 1
                                                    ? <Typography sx={{ color: theme.palette.error.main, minHeight: '100px', textAlign: 'center' }}>No schemes available, <br />Please change order details</Typography>
                                                    : <>
                                                        <Typography>Applied Schemes</Typography>
                                                        {fetchedSchemes?.map((fetchedScheme: any, index: number) => (
                                                            <Stack key={index} direction={'row'} className={'detailContainer'}>
                                                                <Stack direction={'row'} className={'detailStack'}>
                                                                    <Typography className='detail'>{camelCaseWords(fetchedScheme.scheme_name)}</Typography>
                                                                    <Typography className='detail'>{currencyFormatter(+fetchedScheme.discount_price)}</Typography>
                                                                </Stack>
                                                            </Stack>
                                                        ))}

                                                        <Controller name={`extra_discount`}
                                                            control={control}
                                                            rules={{ required: { value: false, message: '' } }}
                                                            defaultValue={''}
                                                            render={({ field }) => (
                                                                <ThemeTextField
                                                                    {...field} className={'formInput'}
                                                                    sx={{
                                                                        mt: '1rem !important',
                                                                        display: orderType.value != '1' ? 'none' : 'flex'
                                                                    }}
                                                                    disabled={orderType.value != '1'}
                                                                    onBlur={(e) => {
                                                                        if (e.target.value)
                                                                            setValue('extra_discount', (+e.target.value).toFixed(3), { shouldValidate: true })
                                                                    }}
                                                                    error={Boolean(errors?.extra_discount)} type={'number'}
                                                                    helperText={(errors?.extra_discount?.message ?? '').toString()}
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start"><CurrencyRupeeRounded sx={{ fontSize: '16px' }} /></InputAdornment>,
                                                                    }}
                                                                    size={'small'} label={'Extra Discount'}
                                                                    placeholder={'eg. 21322'}
                                                                />
                                                            )} />
                                                        <Controller name={`extra_discount_remarks`}
                                                            control={control}
                                                            rules={{ required: { value: inputData?.extra_discount != '', message: 'Remarks required for extra discount' } }}
                                                            defaultValue={''}
                                                            render={({ field }) => (
                                                                <ThemeTextField
                                                                    {...field} required={inputData.extra_discount != ''}
                                                                    disabled={orderType.value != '1'}
                                                                    sx={{
                                                                        display: orderType.value != '1' ? 'none' : 'flex'
                                                                    }}
                                                                    className={'formInput'}
                                                                    error={Boolean(errors?.extra_discount_remarks)}
                                                                    helperText={(errors?.extra_discount_remarks?.message ?? '').toString()}
                                                                    size={'small'} label={'Remarks'}
                                                                    placeholder={'eg. for goodwill'}
                                                                />
                                                            )} />

                                                        <Button
                                                            className={'nextButton'}
                                                            variant={'contained'}
                                                            disabled={(inputData?.extra_discount != '' && inputData?.extra_discount_remarks == '') || (orderType.value != '1' && fetchedSchemes?.length < 1)}
                                                            onClick={activeStepFive}
                                                            sx={{
                                                                width: 'min(100%, 340px)', mx: 'auto'
                                                            }}>
                                                            Apply
                                                        </Button>
                                                    </>
                                                }
                                            </Box>

                                        </Box>
                                    }
                                </AccordionDetails>
                            </Accordion>

                            <Accordion id={'step-5'} expanded={activeStep === 5}>
                                <AccordionSummary aria-controls="panel5bh-content" id="panel5bh-header" >
                                    <Typography className={'accordianTitle'}>
                                        <span>5</span> Payment Info
                                    </Typography>
                                    {activeStep > 5 && activeStep != 5 &&
                                        <>
                                            <IconButton className={'actionIcon'} onClick={() => setActiveStep(5)}><EditRounded /></IconButton>
                                        </>
                                    }
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Divider />
                                    {activeStep === 5 &&
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                alignItems: 'flex-end',
                                                justifyContent: 'center',
                                                pt: 3,
                                            }}>

                                            <Box
                                                sx={{
                                                    flex: '1 1 300px',
                                                    alignSelf: "flex-start",
                                                    pr: 3,
                                                    '& .paymentType': {
                                                        borderRadius: '18px',
                                                        boxShadow: `0 0 12px -3px ${theme.palette.text.disabled} inset`,
                                                        cursor: 'pointer',
                                                        mb: 2,
                                                        p: 1,
                                                        fontSize: '13px',
                                                        textAlign: 'center',
                                                        '& .title': {
                                                            fontSize: '14px',
                                                            fontWeight: 500,
                                                            color: theme.palette.text.secondary,
                                                            fontFamily: theme.typography.h1.fontFamily,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            gap: 2,
                                                        },
                                                        '&.active': {
                                                            fontSize: '15px',
                                                            p: 2,
                                                            boxShadow: `0 0 12px -3px ${theme.palette.success.main} inset`,
                                                            textAlign: 'left',
                                                            '& .title': {
                                                                fontSize: '16px',
                                                                color: theme.palette.success.main,
                                                                justifyContent: 'flex-start',
                                                            },
                                                        },
                                                    },
                                                }}>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                    color: theme.palette.text.secondary,
                                                    fontFamily: theme.typography.h1.fontFamily,
                                                    mb: 1, ml: 1,
                                                }}>Payment Type</Typography>

                                                {orderType.value == '1' &&
                                                    <Box
                                                        className={`paymentType ${paymentType == 'postpaid' ? 'active' : ''}`}
                                                        onClick={() => {
                                                            setPaymentType('postpaid')
                                                            console.log(paymentType)
                                                        }}>
                                                        <Typography className={'title'}>{paymentType == 'postpaid' && <TaskAltRounded />}Postpaid</Typography>
                                                    </Box>
                                                }
                                                <Box
                                                    className={`paymentType ${paymentType == 'prepaid' ? 'active' : ''}`}
                                                    onClick={() => {
                                                        setPaymentType('prepaid')
                                                    }}
                                                    sx={{
                                                        position: 'relative',
                                                    }}>
                                                    <Typography className={'title'}>{paymentType == 'prepaid' && <TaskAltRounded />}Prepaid</Typography>

                                                </Box>

                                            </Box>

                                            <Box
                                                sx={{
                                                    mb: 2,
                                                    flex: '0 1 300px',
                                                    border: `2px solid ${theme.palette.primary.main}`,
                                                    p: 1, pt: 2,
                                                    borderRadius: '18px',
                                                    display: 'flex',
                                                    flexFlow: 'column',
                                                    alignItems: 'stretch',
                                                    justifyContent: 'flex-end',
                                                    '& .priceDetail': {
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        fontSize: '14px',
                                                        fontFamily: theme.typography.h1.fontFamily,
                                                        fontWeight: 500,
                                                        '&.inner': {
                                                            fontSize: '12px',
                                                            color: theme.palette.text.secondary,

                                                        },
                                                        '&.total': {
                                                            fontSize: '16px',
                                                            fontFamily: theme.typography.h1.fontFamily,
                                                            fontWeight: 500,
                                                            color: theme.palette.success.main,
                                                        },
                                                        '& span': {
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            padding: '2px 8px',
                                                            borderRadius: '50vh',
                                                            '&.discount': {
                                                                color: theme.palette.error.main,
                                                            },
                                                            '&:has(svg)': {
                                                                cursor: 'pointer',
                                                                '& svg': {
                                                                    height: '14px',
                                                                    width: '14px',
                                                                    borderRadius: '50vh',
                                                                    '&:hover': {
                                                                        boxShadow: `0 0 12px -4px ${theme.palette.text.secondary} inset`
                                                                    },
                                                                },
                                                                '&:first-of-type': {
                                                                    justifyContent: 'flex-start',

                                                                },
                                                                '&:last-of-type': {
                                                                    justifyContent: 'flex-end',
                                                                    width: '30%',
                                                                },

                                                            },
                                                        },
                                                    },
                                                }}>
                                                <Typography className={'priceDetail'}>
                                                    <span>Total</span>
                                                    <span>{currencyFormatter(totalCharges)}</span>
                                                </Typography>

                                                <Box sx={{
                                                    borderRadius: '12px',
                                                    p: discountShown ? 0.5 : 0,
                                                    mb: 1,
                                                    background: alpha(theme.palette.primary.main, discountShown ? 0.05 : 0),
                                                    transition: 'all 300ms ease-in-out',
                                                }}>
                                                    {discountShown &&
                                                        <>
                                                            {fetchedSchemes.map((el, index) => (
                                                                <Typography key={index} className={'priceDetail inner'}>
                                                                    <span className='line-clamp clamp-1' style={{ maxWidth: '20ch', whiteSpace: 'nowrap' }}>{el.scheme_name}</span>
                                                                    <span className={'discount'}>-{currencyFormatter(+el.discount_price * +inputData.acreage)}</span>
                                                                </Typography>
                                                            ))}
                                                            <Typography className={'priceDetail inner'}>
                                                                <span>Extra</span>
                                                                <span className={'discount'}>-{currencyFormatter(+inputData?.extra_discount)}</span>
                                                            </Typography>
                                                        </>
                                                    }
                                                    <Typography className={'priceDetail'}>
                                                        <span>
                                                            Discount
                                                            <ChevronRightRounded
                                                                onClick={() => setDiscountShown(prev => !prev)}
                                                                sx={{
                                                                    transform: discountShown ? 'rotate(-90deg)' : 'rotate(0deg)',
                                                                    transition: 'all 180ms ease-in-out',

                                                                }} />
                                                        </span>
                                                        <span className={'discount'}>-{currencyFormatter(offeredDiscount)}</span>
                                                    </Typography>
                                                </Box>

                                                <Divider />
                                                <Typography className={'priceDetail total'}>
                                                    <span>Payable</span>
                                                    <span>{currencyFormatter(totalPayable)}</span>
                                                </Typography>

                                                <Button
                                                    fullWidth
                                                    variant={'contained'}
                                                    sx={{ mt: 3 }}
                                                    disabled={paymentType == null || (paymentType != 'postpaid' && inputData.trxn_no?.length < 8)}
                                                    onClick={handleSubmit(createRequest)}>
                                                    Create Request
                                                </Button>

                                            </Box>

                                        </Box>
                                    }
                                </AccordionDetails>
                            </Accordion>
                        </>
                    }
                </Box>
            </PageContainer>


        </Box >
    )
}

export default CreateService

const SuccessBlock = ({ resetPage }: any) => {
    const theme = useTheme()
    const navigate = useNavigate()
    return (
        <Box className={'formBlock'} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            flexFlow: 'column',
            '& .svgBox': {
                height: '110px',
                width: '110px',
                '& .st0': { fill: '#89D81A' },
                '& .st1': { fill: 'url(#SVGID_1_)' },
                '& .st2': { fill: '#47AA00' },
                '& .st3': { fill: '#FFFFFF' },
                '& .st4': { fill: '#BDDE23' },
                '& .st5': { fill: '#15A208' },
            },
            '& .message': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexFlow: 'column',
                fontSize: '16px',
                fontWeight: 500,
                fontfamily: theme.typography.h1.fontFamily,
                color: '#40b70f',
                '& span': {
                    fontSize: '12px',
                    color: theme.palette.text.secondary,
                },

            },
            '& .actions': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 3,
                mt: 3,
                '& .MuiButton-root': {
                    fontSize: '13px',
                    textTransform: 'capitalize',
                    minWidth: '120px',
                },
            },

        }}>
            <Box className={'svgBox'}>
                <SuccessSvg />
            </Box>

            <Typography className={'message'}>
                Request created!
                <span>
                    Your spray request created successfully!
                </span>
            </Typography>

            <Box className={'actions'}>
                <Button size={'small'} variant='outlined' onClick={() => navigate('/services/list')}>View Request</Button>
                <Button size={'small'} variant='outlined' onClick={resetPage}> Create New</Button>
            </Box>
        </Box >
    )
}


