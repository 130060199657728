import { Autocomplete, Box, Button, Checkbox, Chip, Divider, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Stack, Typography, alpha, useTheme, useThemeProps } from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { camelCaseWords, currencyFormatter, s3BucketUrl } from '../../../utils/app-helper'
import { AccountBalanceWalletTwoTone, AgricultureRounded, Attachment, LocationOnRounded, PaymentTwoTone, PhoneRounded, PieChartRounded } from '@mui/icons-material'
import { Controller, useForm } from 'react-hook-form'
import { ThemeTextField } from '../../../components/inputs/theme-text-field'
import { ThemeSwitchInput } from '../../../components/inputs/theme-switch'
import { LoadingButton } from '@mui/lab'
import { useDispatch, useSelector } from 'react-redux'
import { updateProcessState } from '../../../slices/process-slice'
import FileUploadInput from '../../../components/inputs/fileUploadInput'
import SignaturePad from '../../../components/signature-pad/signature-pad'
import ThemeDialog from '../../../components/dialog-box/theme-dialog'
import { useNavigate, useParams } from 'react-router'
import API from '../../../api'
import toast from 'react-hot-toast'
import LoadingView from '../../../components/loading-view'
import { useChemicals } from '../../../hooks/useChemicals'
import { useDocumentTitle } from '../../../hooks/useDocumnetTitle'
import { CheckmarkIcon, CircleIcon } from '../../../components/icons'
import { RootState } from '../../../store/store'


function ServiceDetail() {

    useDocumentTitle('Service Details')

    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const params = useParams()
    const { serviceId } = params

    const attendance = useSelector((state: RootState) => state.attendance)

    const [stage, setStage] = useState(0)
    const [serviceDetail, setServiceDetail] = useState<any>({} as any)
    const [fetching, setFetching] = useState(true)

    const fetchServiceDetail = () => {
        setFetching(true)
        dispatch(updateProcessState(true))
        API.get(`/fetch_single_order/${serviceId}`)
            .then((res) => {
                setServiceDetail(res.data?.data)
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setFetching(false)
                dispatch(updateProcessState(false))
            })
    }

    useEffect(() => {
        fetchServiceDetail()
    }, [serviceId])


    useEffect(() => {
        if (!fetching && (serviceDetail?.order_status != '0' && serviceDetail?.order_status != '6')) {
            if ((!attendance.in || attendance.out || attendance?.on_leave == '1')) {
                toast.error('Please clock in first to take actions')
                navigate('/services')
            }
        }
    }, [serviceDetail, attendance])



    return (
        fetching
            ? <LoadingView />
            : <Box className={'animate__animated animate__fadeIn'}
                sx={{
                    minHeight: 'min(80vh, 650px)',
                    display: 'flex',
                    flexFlow: 'column',
                }}>
                <ServiceSummary serviceDetail={serviceDetail} stage={stage} />
                {serviceDetail?.order_status == '3'
                    ? <StartForm serviceDetail={serviceDetail} fetchServiceDetail={fetchServiceDetail} setStage={setStage} />
                    : serviceDetail?.order_status == '4'
                        ? <CompleteForm serviceDetail={serviceDetail} fetchServiceDetail={fetchServiceDetail} setStage={setStage} />
                        : serviceDetail?.order_status == '5'
                            ? <SuccessFullForm serviceDetail={serviceDetail} fetchServiceDetail={fetchServiceDetail} setStage={setStage} />
                            : <></>
                }
            </Box>
    )
}

const ServiceSummary = (props: any) => {

    const { serviceDetail } = props
    const theme = useTheme()

    const navigate = useNavigate()

    const alreadyReceivedAmount = serviceDetail?.amount_received?.length > 3 ? JSON.parse(serviceDetail.amount_received) : []
    // const receivedAmount = alreadyReceivedAmount.length > 0 ? alreadyReceivedAmount[0]?.amount : 0
    const receivedAmount = alreadyReceivedAmount.length > 0 ? alreadyReceivedAmount.filter((el: any) => el.mode == '1' || el.mode == '2').reduce((total: number, current: any) => total + +current.amount, 0) : 0

    return (
        <Box
            sx={{
                outline: serviceDetail?.order_type == '4' ? `2px solid ${theme.palette.info.main}` : (serviceDetail?.order_type == '5' ? `2px solid ${theme.palette.warning.main}` : `1px solid ${alpha(theme.palette.text.disabled, 0.1)}`),
                borderRadius: '32px',
                p: 2,
                display: 'flex',
                flexWrap: 'wrap',
                columnGap: 1,
                rowGap: 1,
                justifyContent: 'center',
                alignItems: 'center',

            }}>
            <Typography className={'animate__animated animate__fadeInUp'} sx={{
                flex: 1,
                fontWeight: 600,
                fontSize: '14px',
                fontFamily: theme.typography.h1.fontFamily,
                color: serviceDetail?.order_type == '4' ? theme.palette.info.main : (serviceDetail?.order_type == '5' ? theme.palette.warning.main : theme.palette.primary.main),
            }}>
                # {serviceDetail?.order_id}
            </Typography>
            <Chip
                className={'animate__animated animate__fadeInUp'}
                size={'small'}
                label={serviceDetail?.order_status == '3' ? 'Acknowledged' :
                    serviceDetail?.order_status == '4' ? 'Started' :
                        serviceDetail?.order_status == '5' ? 'Completed' :
                            serviceDetail?.order_status == '6' ? 'Successfull' :
                                'Cancelled'}
                color={serviceDetail?.order_status == '3' ? 'info' :
                    serviceDetail?.order_status == '4' ? 'default' :
                        serviceDetail?.order_status == '5' ? 'primary' :
                            serviceDetail?.order_status == '6' ? 'success' :
                                'error'}
            />

            <Box
                sx={{
                    width: '100%',
                    background: alpha(theme.palette.text.disabled, 0.1),
                    borderRadius: '12px',
                    py: 1, px: 2,
                    display: 'flex',
                    flexFlow: 'column',
                    columnGap: 1,
                    rowGap: 2,
                    '& .summaryLine': {
                        fontSize: '13px',
                        display: 'grid',
                        '& span': {
                            mx: 1,
                            '&:has(svg), &.payment': {
                                display: 'flex',
                                gap: '4px',
                                alignItems: 'center',
                            },
                            '& span': {
                                mx: 0,
                            },
                            '& svg': {
                                color: theme.palette.text.disabled,
                            },
                            '& a': {
                                textDecoration: 'none',
                                color: theme.palette.primary.main,
                            },
                        },
                    },
                }}>
                <Box className={'summaryLine animate__animated animate__fadeInUp'}>
                    <span><strong>{camelCaseWords(serviceDetail?.client_details?.regional_client_name)}</strong></span>
                    <span onClick={() => navigate(`/operator-update-farmer/${btoa(serviceDetail?.farmer_details?.id)}`)}><AgricultureRounded sx={{ fontSize: '12px' }} /> <strong>{camelCaseWords(serviceDetail?.farmer_details?.farmer_name)}</strong></span>
                    <span><PhoneRounded sx={{ fontSize: '12px' }} /><strong>{serviceDetail?.farmer_details?.farmer_mobile_no}</strong></span>
                    <span><PieChartRounded sx={{ fontSize: '12px' }} /><strong>{serviceDetail?.requested_acreage}</strong> acres of <strong>{serviceDetail?.crop_name}</strong></span>
                    <span> <LocationOnRounded sx={{ fontSize: '12px' }} /> {serviceDetail?.farm_location?.village}, {serviceDetail?.farm_location?.sub_district}, {serviceDetail?.farm_location?.district} - {serviceDetail?.farm_location?.pin_code}</span>
                    <span><a href='https://digitalsky.dgca.gov.in/airspace-map/#/app' target='_blank'>Check Zone</a></span>
                </Box>


                {(serviceDetail?.order_status == '4' || serviceDetail?.order_status == '5' || serviceDetail?.order_status == '6') &&
                    <Box className={'summaryLine animate__animated animate__fadeInUp'}>
                        <Divider />
                        {serviceDetail?.order_timeline?.request_chemicals && <span> Requested Chemical: <strong>{serviceDetail?.order_timeline?.request_chemicals}</strong></span>}
                        <span> Sprayed Chemical: <strong>{serviceDetail?.order_timeline?.chemical_used_ids}</strong></span>
                        <span>
                            <span> Fresh Water: <strong>{serviceDetail?.order_timeline?.fresh_water == '1' ? 'Yes' : 'No'} </strong></span>
                            |
                            <span> Farmer Present: <strong>{serviceDetail?.order_timeline?.farmer_available == '1' ? 'Yes' : 'No'}</strong></span>
                        </span>
                        <span> Noc Image:
                            {serviceDetail?.order_timeline?.noc_image
                                ? <strong><a target={'_blank'} href={`${s3BucketUrl}/noc_image/${serviceDetail?.order_timeline?.noc_image}`}> View</a></strong>
                                : <strong> Not Uploaded</strong>
                            }
                        </span>
                        {(serviceDetail?.order_status == '5' || serviceDetail?.order_status == '6') &&
                            <>
                                <span> Drone Estimated Acreage: <strong>{serviceDetail?.drone_estimated_acreage ?? '0.00'}</strong></span>
                                <span> Sprayed Acreage: <strong>{serviceDetail?.sprayed_acreage}</strong></span>
                            </>
                        }
                    </Box>
                }

                <Box className={'summaryLine animate__animated animate__fadeInUp'}
                    sx={{
                        '& span': {
                            justifyContent: 'space-between',
                            '&.paymentStatus': {
                                color: serviceDetail?.payment_status == '0' ? theme.palette.error.main :
                                    serviceDetail?.payment_status == '1' ? theme.palette.info.main :
                                        serviceDetail?.payment_status == '2' ? theme.palette.success.light :
                                            serviceDetail?.payment_status == '3' ? theme.palette.success.light :
                                                serviceDetail?.payment_status == '4' ? theme.palette.warning.main :
                                                    serviceDetail?.payment_status == '5' ? theme.palette.success.main :
                                                        theme.palette.text.secondary,
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 0.5,
                                outline: '1px dashed currentColor',
                                borderRadius: '11px',
                                maxWidth: 'max-content',
                                margin: '1rem auto !important',
                                padding: '4px 16px',
                            },
                        },

                    }}>
                    <span><strong>Payment:</strong></span>
                    <Divider />
                    <span className='payment'>Total: <span style={{ color: theme.palette.text.secondary }}><strong>{currencyFormatter(+serviceDetail?.total_amount ?? 0)}</strong></span></span>
                    <span className='payment'>Discount: <span style={{ color: theme.palette.text.secondary }}><strong>-{currencyFormatter(+serviceDetail?.total_discount ?? 0)}</strong></span></span>
                    <span className='payment'>Payable: <span style={{ color: theme.palette.text.primary }}><strong>{currencyFormatter(+serviceDetail?.total_payable_amount ?? 0)}</strong></span></span>
                    {(serviceDetail?.order_status == '3' || serviceDetail?.order_status == '4' || serviceDetail?.order_status == '5' || serviceDetail?.order_status == '6') &&
                        <span className='payment'>Received: <span style={{ color: theme.palette.success.main }}><strong>{currencyFormatter(+receivedAmount)}</strong></span></span>
                    }
                    {(serviceDetail?.order_status == '5' || serviceDetail?.order_status == '6') &&
                        <>
                            {serviceDetail?.added_amount != '0' && <span className='payment'>Excess: <span style={{ color: theme.palette.warning.main }}><strong>{currencyFormatter(+serviceDetail?.added_amount)}</strong></span></span>}
                            {serviceDetail?.refund_amount != '0' && <span className='payment'>Refund: <span style={{ color: theme.palette.error.main }}><strong>{currencyFormatter(+serviceDetail?.refund_amount)}</strong></span></span>}
                        </>
                    }

                    <span className="paymentStatus">
                        <span>{serviceDetail?.payment_type == '2' ? 'Postpaid :' : 'Prepaid :'}</span>
                        <span>{serviceDetail?.payment_status == '2' ? 'Offline' :
                            serviceDetail?.payment_status == '3' ? 'Online' :
                                ''
                        }</span>
                        <span>{
                            serviceDetail?.payment_status == '0' ? 'Failed' :
                                serviceDetail?.payment_status == '1' ? 'Initiated' :
                                    serviceDetail?.payment_status == '2' ? 'partially paid' :
                                        serviceDetail?.payment_status == '3' ? 'partially paid' :
                                            serviceDetail?.payment_status == '4' ? 'Refund Initiated' :
                                                serviceDetail?.payment_status == '5' ? 'Successful' :
                                                    ''
                        }</span>
                    </span>
                </Box>
            </Box>
        </Box>
    )
}

const StartForm = (props: any) => {

    const { serviceDetail, fetchServiceDetail } = props
    const { chemicals, fetchChemicals } = useChemicals()
    const theme = useTheme()
    const dispatch = useDispatch()

    const [noc, setNoc] = useState<File[]>([])
    const [freshWater, setFreshWater] = useState(true)
    const [farmerPresence, setFarmerPresence] = useState(true)
    const [submitting, setSubmitting] = useState(false)

    const [cancelDialog, setCancelDialog] = useState(false)

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (files) {
            const fileArray = Array.from(files)
            setNoc([...fileArray])
        }
    }

    const handleRemoveFile = (index: number) => {
        const updatedAttachments = noc.filter((_, i) => i !== index)
        setNoc(updatedAttachments)
    }

    const { control, handleSubmit, setValue, watch, formState: { errors } } = useForm()

    const otherChemical = watch('chemical_used_ids')?.includes('Others')

    const initiatePayment = () => {
        dispatch(updateProcessState(true))

        API.post('/payment-initiated', { id: serviceDetail?.id })
            .then((res) => {
                if (res.data?.status == 'success') {
                    setContent('2')
                    toast.success('Payment initiated successfully')
                }

            })
            .catch((err) => console.error(err))
            .finally(() => {
                dispatch(updateProcessState(false))

            })
    }
    const fetchPayment = () => {
        dispatch(updateProcessState(true))

        API.post('/payment-fetched', { id: serviceDetail?.id })
            .then((res) => {
                if (res.data?.status == 'success')
                    if (res.data?.data?.payment_status == '5') {
                        setContent(null)
                        toast.success('Payment paid successfully')
                    }
            })
            .catch((err) => console.error(err))
            .finally(() => {
                dispatch(updateProcessState(false))

            })
    }

    const [fmcChemicals, setFmcChemicals] = useState<any[]>([])
    const fetchFmcChemicals = () => {
        API.get('/fmc-chemicals')
            .then((res) => {
                if (res.data?.status == 'success') {
                    setFmcChemicals([
                        ...res.data?.data,
                        ...[{ id: "other", chemical_name: "Others" }],
                    ] || []
                    )
                }
            })
            .catch((err) => console.error(err))
            .finally(() => {
                dispatch(updateProcessState(false))

            })
    }


    const onSubmit = async (data: any) => {
        setSubmitting(true);
        dispatch(updateProcessState(true));
        try {
            if (!navigator.geolocation) {
                toast.error('Geolocation not enabled or supported')
                return
            }

            const position = await new Promise<GeolocationPosition>((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    (pos) => resolve(pos),
                    (error) => {
                        toast.error('Please enable geolocation.')
                        reject(error)
                    }
                );
            });

            const coordinates = `latitude: ${position.coords.latitude}, longitude: ${position.coords.longitude}`;

            const newData = {
                id: serviceDetail?.id,
                farmer_available: farmerPresence ? '1' : '0',
                fresh_water: freshWater ? '1' : '0',
                noc_image: noc[0] || '',
                chemical_used_ids: `${data?.chemical_used_ids
                    ?.split(', ')
                    ?.filter((el: string) => el != 'Others')
                    ?.join(', ')}${otherChemical ? `, ${data?.other_chemical?.toUpperCase()}` : ''}`,
                location_coordinates: coordinates,
                available_person_name: farmerPresence ? '' : data?.available_person_name,
                available_person_phone: farmerPresence ? '' : data?.available_person_phone,
            };

            const nocSize =
                data?.noc_image?.length > 0 ? data?.noc_image[0]?.size / (1024 * 1024) > 2 : false;
            if (nocSize) {
                toast.error('Error! Please change NOC Image. Max 2MB allowed.');
                return
            }

            await API.post('/start_spray', newData, { headers: { "Content-Type": "multipart/form-data" } })
                .then((res) => {
                    toast.success('Request started!')
                    fetchServiceDetail()
                })
                .catch((err) => console.log(err))

        } catch (error: any) {
            console.error('Error in onSubmit:', error);
            toast.error(error?.message || 'An unexpected error occurred.');
        } finally {
            setSubmitting(false);
            dispatch(updateProcessState(false));
        }
    }

    const [content, setContent] = useState<'1' | '2' | null>(serviceDetail?.order_timeline?.created_by == 'FMC' ? '1' : null)


    useEffect(() => {
        if (serviceDetail?.order_timeline?.created_by == 'FMC') {
            fetchPayment()
            fetchFmcChemicals()
        } else {
            fetchChemicals()
        }
    }, [])

    const icon = <CircleIcon fontSize="small" />;
    const checkedIcon = <CheckmarkIcon fontSize="small" />;

    return (
        <>
            {/* {serviceDetail?.order_timeline?.created_by == 'FMC' && serviceDetail?.payment_status == '1'
                ? */}
            <Box className={'animate__animated animate__fadeIn'}
                sx={{
                    flex: 1,
                    p: 2, pt: 4,
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'stretch',
                }}>
                {content == '1' &&
                    <>
                        <Button
                            size={'large'}
                            color='warning'
                            variant='contained'
                            sx={{ mr: 1 }}
                            onClick={initiatePayment}>
                            Initiate Payment
                        </Button>
                        <Button
                            size={'large'}
                            color='error'
                            sx={{ mr: 1 }}
                            onClick={() => setCancelDialog(true)}>
                            Cancel Instead
                        </Button>
                    </>
                }
                {content == '2' &&
                    <Button
                        size={'large'}
                        color='warning'
                        variant='contained'
                        sx={{ mr: 1 }}
                        onClick={fetchPayment}>
                        Fetch Payment
                    </Button>
                }
            </Box>
            {/* : */}
            {content == null &&
                <Box className={'animate__animated animate__fadeIn'}
                    sx={{
                        flex: 1,
                        p: 2, pt: 4,
                        display: 'flex',
                        flexFlow: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'stretch',
                    }}>

                    <Controller name={`chemical_used_ids`}
                        control={control}
                        rules={{
                            required: { value: true, message: 'Chemical is required' },
                            validate: { maxLength: (value) => (value?.split(', ')?.length ?? 0) <= 5 || 'Maximum 5 chemicals allowed' },
                        }}
                        render={({ field }) => (
                            <Autocomplete
                                options={serviceDetail?.order_timeline?.created_by == 'FMC' ? fmcChemicals : chemicals}
                                autoHighlight
                                multiple limitTags={1}
                                className={'formInput'}
                                onChange={(e, data) => {
                                    setValue('chemical_used_ids', data?.map((item: any) => item?.chemical_name).join(', '))
                                }}
                                disableCloseOnSelect
                                getOptionLabel={(option: { id: number, chemical_name: string }) => `${option.chemical_name}`}
                                renderOption={(props, option: { id: number, chemical_name: string }, { selected }) => {
                                    const { ...optionProps } = props;
                                    return (
                                        <Box component="li" key={option?.id} {...optionProps} >
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option?.chemical_name}
                                        </Box>
                                    );
                                }}
                                renderInput={(params) => (
                                    <ThemeTextField
                                        {...params} required
                                        error={Boolean(errors?.chemical_used_ids)}
                                        helperText={(errors?.chemical_used_ids?.message ?? '').toString()}
                                        size={'small'} label={'Chemical Used'}
                                        placeholder={'chemical_used_ids'}
                                    />
                                )}
                            />
                        )} />
                    {otherChemical &&
                        <Controller name={`other_chemical`}
                            control={control}
                            rules={{ required: { value: otherChemical, message: 'Chemical is required' } }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required
                                    error={Boolean(errors?.other_chemical)}
                                    onBlur={(e) => e.target.value.toUpperCase()}
                                    helperText={(errors?.other_chemical?.message ?? '').toString()}
                                    size={'small'} label={'Chemical Name'}
                                    placeholder={'name of chemical'}
                                />
                            )} />
                    }

                    <Stack sx={{ width: '100%', mb: 2, }} alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
                        <FormLabel>Fresh Water</FormLabel>
                        <ThemeSwitchInput checked={freshWater} onChange={(e, checked) => setFreshWater(checked)} />
                    </Stack>
                    <Stack sx={{ width: '100%', mb: 2, }} alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
                        <FormLabel>Farmer Available</FormLabel>
                        <ThemeSwitchInput checked={farmerPresence} onChange={(e, checked) => setFarmerPresence(checked)} />
                    </Stack>


                    {!farmerPresence &&
                        <>
                            <Controller name={`available_person_name`}
                                control={control}
                                rules={{
                                    required: { value: !farmerPresence, message: 'Required' },
                                    pattern: { value: /^[a-zA-Z0-9 ]*$/, message: 'Special characters not allowed' },
                                }}
                                defaultValue={''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} required
                                        error={Boolean(errors?.available_person_name)}
                                        helperText={(errors?.available_person_name?.message ?? '').toString()}
                                        size={'small'} label={'Available Person Name'}
                                        placeholder={'name'}
                                    />
                                )} />
                            <Controller name={`available_person_phone`}
                                control={control}
                                rules={{
                                    required: { value: !farmerPresence, message: 'Required' },
                                    pattern: { value: /^[789]\d{9}$/, message: 'Invalid mobile number' },
                                }}
                                defaultValue={''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} required
                                        error={Boolean(errors?.available_person_phone)}
                                        helperText={(errors?.available_person_phone?.message ?? '').toString()}
                                        size={'small'} label={'Available Person Mobile'}
                                        placeholder={'XXXX XXX XXX'}
                                    />
                                )} />
                        </>
                    }
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            gap: 1,
                            mb: 3,
                            '& .attachments': {
                                flex: 1,
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                gap: 1,
                                '& .attachmentLabel': {
                                    fontSize: '14px',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    color: theme.palette.primary.main,
                                }
                            },
                        }}>

                        <Box className={'attachments'}>
                            <Typography className={'attachmentLabel'}><Attachment /> NOC</Typography>
                            {
                                noc.map((file, index) => (
                                    <Chip key={index} label={file.name} variant="outlined" color="primary" size="small"
                                        onDelete={() => handleRemoveFile(index)} />
                                ))
                            }

                            {noc.length < 1 &&
                                <>
                                    <label htmlFor={'attachmentInput'}>
                                        <Chip label={'Add'} color={'secondary'} size="small" sx={{ cursor: 'pointer' }} />
                                    </label>
                                    <input
                                        type="file"
                                        id="attachmentInput"
                                        name="attachmentInput"
                                        accept="image/jpeg, image/png, image/jpg"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                    />
                                </>}
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'flex-end',
                            alignContent: 'flex-end',
                            mx: 'auto',
                            background: alpha(theme.palette.background.default, 0.3),
                            backdropFilter: 'blur(4px)',
                            '& .MuiButton-root': {
                                width: '100%',
                                textWrap: 'nowrap',
                                textTransform: 'capitalize'
                            },
                        }}>

                        <LoadingButton
                            variant={'contained'}
                            size={'large'}
                            loading={submitting}
                            endIcon={<></>}
                            loadingPosition={'end'}
                            onClick={handleSubmit(onSubmit)}>
                            Start Spray
                        </LoadingButton>
                        <Button
                            size={'large'}
                            color='error'
                            sx={{ mr: 1 }}
                            onClick={() => setCancelDialog(true)}>
                            Cancel Instead
                        </Button>

                    </Box>
                </Box >
            }

            {
                cancelDialog &&
                <ThemeDialog open={cancelDialog} title={'Cancel Request'}
                    dialogBody={<CancelForm serviceId={serviceDetail?.id} fetchServiceDetail={fetchServiceDetail} closeDialog={() => setCancelDialog(false)} />}
                />
            }
        </>
    )
}

const CompleteForm = (props: any) => {
    const { setStage, serviceDetail, fetchServiceDetail } = props
    const theme = useTheme()
    const dispatch = useDispatch()

    const [submitting, setSubmitting] = useState(false)
    const [signature, setSignature] = useState<string | null>(null);

    const { control, watch, handleSubmit, register, setValue, formState: { errors } } = useForm()

    const inputData = watch()

    const [remarksRequired, setRemarksRequired] = useState(false)

    const onSubmit = (data: any) => {
        if (signature) {
            const newData = {
                id: serviceDetail?.id,
                sprayed_acreage: data?.sprayed_acreage,
                farmer_image: data?.farmer_image[0],
                refund_image: remarksRequired ? data?.refund_image[0] : '',
                farmer_signature: signature,
                spray_remarks: data?.spray_remarks == 'Others' ? data?.other_remarks : data?.spray_remarks
            }
            const farmerImageSize = data?.farmer_image?.length > 0 ? (data?.farmer_image[0]?.size / (1024 * 1024)) > 2 : false // to compare size max is 2mb
            const farmImageSize = data?.refund_image?.length > 0 ? (data?.refund_image[0]?.size / (1024 * 1024)) > 2 : false // to compare size max is 2mb
            if (farmerImageSize || farmImageSize) {
                toast.error(`Error! Please change Farmer Image \n Max 2mb allowed.`)
            }
            else {
                setSubmitting(true)
                dispatch(updateProcessState(true))
                API.post('/complete_spray', newData, { headers: { "Content-Type": "multipart/form-data" } })
                    .then((res) => {
                        if (res.data?.status == 'success') {
                            toast.success('Request updated successfully!')
                            fetchServiceDetail()
                        }
                        if (res.data?.status == 'error') {
                            toast.error(`Error! ${res.data?.msg}`)
                        }
                    })
                    .catch((err) => console.log(err))
                    .finally(() => {
                        setSubmitting(false)
                        dispatch(updateProcessState(false))
                    })
            }
        } else {
            toast.error('Signtaure is required.')
        }
    }

    return (
        <Box className={'animate__animated animate__fadeIn'}
            sx={{
                flex: 1,
                p: 2, pt: 4,
                display: 'flex',
                flexFlow: 'column',
                justifyContentContent: 'flex-start',
                alignItems: 'stretch',
            }}>

            <Box
                sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    gap: 2,
                    justifyContentContent: 'flex-start',
                    alignItems: 'stretch',
                    '& .infoBlock': {
                        mt: 1,
                        mb: 2,
                        p: 1,
                        color: theme.palette.text.secondary,
                        border: `1px solid ${theme.palette.warning.main}`,
                        borderRadius: '16px',
                        background: alpha(theme.palette.warning.main, 0.1),
                        textAlign: 'center',
                        fontSize: '13px',
                    },
                }}>

                <Controller name={`sprayed_acreage`}
                    control={control}
                    rules={{
                        required: { value: true, message: 'required' },
                        max: { value: +serviceDetail?.farm_location?.acerage ?? 30, message: 'Acreage exceeds' }
                    }}
                    defaultValue={''}
                    render={({ field }) => (
                        <ThemeTextField
                            {...field} required className={'formInput'}
                            error={Boolean(errors?.sprayed_acreage)} type={'number'}
                            onWheel={(e) => e.currentTarget.blur()}
                            onBlur={(e) => {
                                setValue('sprayed_acreage', ((+e.target.value < 0.1) ? '' : (+e.target.value).toFixed(2)), { shouldValidate: true })
                                setRemarksRequired((+e.target.value < +serviceDetail?.requested_acreage))
                            }}
                            helperText={(errors?.sprayed_acreage?.message ?? '').toString()}
                            size={'small'} label={'Sprayed Acreage'}
                            placeholder={'sprayed_acreage'}
                        />
                    )} />

                {remarksRequired &&
                    <>
                        <Controller name={`spray_remarks`}
                            control={control}
                            rules={{ required: { value: true, message: 'Spray remark is required' } }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.spray_remarks)} select
                                    helperText={(errors?.spray_remarks?.message ?? '').toString()}
                                    size={'small'} label={'Remarks'}
                                    placeholder={'spray_remarks'}
                                >
                                    <MenuItem value="Obstacles">Obstacles</MenuItem>
                                    <MenuItem value="Wires">Wires</MenuItem>
                                    <MenuItem value="Chemical Shortage">Chemical Shortage</MenuItem>
                                    <MenuItem value="Misrepresentation">Misrepresentation</MenuItem>
                                    <MenuItem value="Farmer Refusal">Farmer Refusal</MenuItem>

                                    <MenuItem value="Others">Others</MenuItem>
                                </ThemeTextField>
                            )}
                        />

                        {inputData?.spray_remarks == 'Others' &&
                            <Controller name={`other_remarks`}
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Required' },
                                    pattern: { value: /^[a-zA-Z0-9 ]*$/, message: 'Special characters not allowed' },
                                }}
                                defaultValue={''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} required className={'formInput'} multiline rows={3}
                                        error={Boolean(errors?.other_remarks)}
                                        helperText={(errors?.other_remarks?.message ?? '').toString()}
                                        size={'small'} label={'Others Remarks'}
                                        placeholder={'other_remarks'}
                                    />
                                )}
                            />
                        }

                        <FileUploadInput
                            label={'Farm Image'}
                            accept={'image/png, image/gif, image/jpeg'}
                            reactHookProps={{ ...register('refund_image', { required: 'Image required', }) }}
                            onClear={() => setValue('refund_image', null)}
                            errorMessage={errors.refund_image?.message?.toString()}
                        />
                    </>

                }

                <FileUploadInput
                    label={'Farmer Image'}
                    accept={'image/png, image/gif, image/jpeg'}
                    reactHookProps={{ ...register('farmer_image', { required: 'Image required', }) }}
                    onClear={() => setValue('farmer_image', null)}
                    errorMessage={errors.farmer_image?.message?.toString()}
                />
                <SignaturePad label={'Farmer Signature'} imageData={signature} setImageData={setSignature} />

                <Box className={'infoBlock'}>Please Note: You may receive a feedback call from AgriWings regarding our services.</Box>

            </Box>

            <Box sx={{
                mt: 3,
                flex: 1,
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'flex-end',
                alignContent: 'flex-end',
                background: alpha(theme.palette.background.default, 0.3),
                backdropFilter: 'blur(4px)',
                '& .MuiButton-root': {
                    width: '100%',
                    textWrap: 'nowrap',
                    textTransform: 'capitalize'
                },
            }}>

                <LoadingButton
                    variant={'contained'}
                    size={'large'}
                    loading={submitting}
                    endIcon={<></>}
                    loadingPosition={'end'}
                    onClick={handleSubmit(onSubmit)}>
                    Complete Spray
                </LoadingButton>

            </Box>
        </Box>
    )
}

const SuccessFullForm = (props: any) => {
    const { serviceDetail, fetchServiceDetail } = props
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let paymentPage: Window | null

    const [submitting, setSubmitting] = useState(false)
    const [signature, setSignature] = useState<string | null>(null);
    const [paymentMode, setPaymentMode] = useState<'1' | '2'>('1');
    const [bankPage, setBankPage] = useState<null | string>(null)

    const { control, handleSubmit, register, setValue, formState: { errors } } = useForm()

    const alreadyReceivedAmount = JSON.parse(serviceDetail?.amount_received) ?? []

    const onSubmit = (data: any) => {

        const amountAdded = {
            // 1: offline, 2: online, 3: online refund, 4: offline refund
            mode: serviceDetail?.refund_amount != '0' ? (paymentMode == '1' ? '4' : '3') : paymentMode,
            reference_no: data?.trxn_no,
            ...(serviceDetail?.added_amount != '0'
                ? { amount: `${(+serviceDetail?.added_amount ?? 0)?.toFixed(3)}` }
                : serviceDetail?.refund_amount != '0'
                    ? { amount: `${(+serviceDetail?.refund_amount ?? 0)?.toFixed(3)}` }
                    : alreadyReceivedAmount?.length < 1
                        ? { amount: `${(+serviceDetail?.total_payable_amount ?? 0)?.toFixed(3)}` }
                        : { amount: '' }),
        }

        const newData = {
            ...data,
            id: serviceDetail?.id,
            mode: serviceDetail?.refund_amount != '0' ? (paymentMode == '1' ? '4' : '3') : paymentMode,
            refund_image: '',
            // refund_image: data?.farmer_image?.length > 0 ? data?.farmer_image[0] : '',
            farmer_refund_signature: `${signature}` ?? '',
            ...((alreadyReceivedAmount?.length > 0 && (+alreadyReceivedAmount[0]?.amount == +serviceDetail?.total_payable_amount))
                ? { amount_received: JSON.stringify([...alreadyReceivedAmount]) }
                : { amount_received: JSON.stringify([...alreadyReceivedAmount, amountAdded]) }
            )
        }
        // const refundImageSize = data?.refund_image?.length > 0 ? (data?.refund_image[0]?.size / (1024 * 1024)) > 2 : false // to compare size max is 2mb
        // if (refundImageSize) {
        //     toast.error(`Error! Please change Refund Image \n Max 2mb allowed.`)
        // } else {

        if (paymentMode == '2') {
            API.post('/initiate_payment_request', { id: `${serviceDetail?.id}` })
                .then((res) => { setBankPage(res.data) })
                .catch((err) => console.error(err))
                .finally(() => { dispatch(updateProcessState(false)) })
        } else {
            setSubmitting(true)
            dispatch(updateProcessState(true))
            API.post('/mark_spray_successful', newData, { headers: { "Content-Type": "multipart/form-data" } })
                .then((res) => {
                    res.data.status == 'success' && toast.success('Request updated successfully!')
                    fetchServiceDetail()
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    setSubmitting(false)
                    dispatch(updateProcessState(false))
                })
        }
    }


    var intervalId: string | number | NodeJS.Timeout | undefined
    function isPaymentWIndowOpen() {
        if (paymentPage && !paymentPage?.closed) {
            console.log('Processing Payment')
        } else {
            setBankPage(null)
            fetchServiceDetail()
            clearInterval(intervalId)
        }
    }


    useEffect(() => {
        if (bankPage) {
            paymentPage = window.open(bankPage, 'paymentWindow');
            dispatch(updateProcessState(true))
            intervalId = setInterval(isPaymentWIndowOpen, 500);

            window.onbeforeunload = function (e) {
                return 'Changes made will not be saved';
            }
        }
    }, [bankPage])


    return (
        <Box
            sx={{
                flex: 1,
                p: 2, pt: 4,
                display: 'flex',
                flexFlow: 'column',
                justifyContentContent: 'flex-start',
                alignItems: 'stretch',
                gap: 3,
            }}>
            {serviceDetail?.refund_amount != '0' &&
                <Typography className={'animate__animated animate__fadeInUp'} sx={{
                    color: theme.palette.error.main,
                    textAlign: 'center',
                    '& span': {
                        fontWeight: 600,
                        fontSize: '20px'
                    },
                }}>
                    Refund Amount < br /> <span>{currencyFormatter(+serviceDetail?.refund_amount)}</span>
                </Typography>
            }

            {(serviceDetail?.added_amount != '0' && !(alreadyReceivedAmount?.length < 1 && serviceDetail?.total_payable_amount != '0')) &&
                <Typography className={'animate__animated animate__fadeInUp'} sx={{
                    color: theme.palette.warning.main,
                    textAlign: 'center',
                    '& span': {
                        fontWeight: 600,
                        fontSize: '20px'
                    },
                }}>
                    Payable Excess Amount <br /><span>{currencyFormatter(+serviceDetail?.added_amount)}</span>
                </Typography>
            }
            {(alreadyReceivedAmount?.length < 1 && serviceDetail?.total_payable_amount != '0') &&
                <Typography className={'animate__animated animate__fadeInUp'} sx={{
                    color: theme.palette.warning.main,
                    textAlign: 'center',
                    '& span': {
                        fontWeight: 600,
                        fontSize: '20px'
                    },
                }}>
                    Payable Amount <br /><span>{currencyFormatter(+serviceDetail?.total_payable_amount)}</span>
                </Typography>
            }

            {serviceDetail?.refund_amount != '0' &&
                <Box sx={{
                    display: 'grid',
                    gap: 3,
                }}>
                    {/* <FileUploadInput
                        label={'Farmer Image'}
                        cameraOnly={false}
                        accept={'image/png, image/gif, image/jpeg'}
                        aspectRatio={'auto'}
                        // reactHookProps={{ ...register('farmer_image', { required: 'Image required', }) }}
                        reactHookProps={{ ...register('farmer_image') }}
                        onClear={() => setValue('farmer_image', null)}
                        errorMessage={errors.farmer_image?.message?.toString()}
                    /> */}

                    <SignaturePad imageData={signature} setImageData={setSignature} />

                </Box>
            }

            {(
                (alreadyReceivedAmount?.length < 1 && serviceDetail?.total_payable_amount != '0')
                || serviceDetail?.added_amount != '0'
                // || serviceDetail?.refund_amount != '0'
            )
                &&
                <Box
                    sx={{
                        background: `linear-gradient(353deg, #032400 0%, #2a9607 75%, #1a940e 100%)`,
                        flex: 1,
                        minWidth: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                        p: 2,
                        mb: 2,
                        borderRadius: '32px',
                        '& .paymentOption': {
                            cursor: 'pointer',
                            borderRadius: '16px',
                            py: 1, px: 2,
                            color: theme.palette.text.secondary,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexFlow: 'row wrap',
                            transition: 'all 350ms ease-in-out',
                            position: 'relative',
                            outline: `2px solid ${alpha(theme.palette.background.paper, 0.2)}`,
                            minWidth: '90px',
                            height: '72px',
                            overflow: 'hidden',
                            '& .selectContentBox': {
                                flex: 1,
                                display: 'flex',
                                gap: 1.5,
                                alignItems: 'center',
                                justifyContent: 'center',
                                '& .optionIcon': {
                                    color: alpha(theme.palette.background.paper, 0.2),
                                    fontSize: '2.5rem',
                                },
                                '& .optionTitle': {
                                    fontSize: '0.8rem',
                                    lineHeight: '0.8rem',
                                    fontWeight: 600,
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    background: alpha(theme.palette.background.paper, 0.2),
                                    color: theme.palette.primary.main,
                                    p: '2px 12px',
                                    borderRadius: '18px 0 18px 0',
                                    minWidth: '60px',
                                },
                            },
                            '& .content': {
                                flex: 1,
                                '& img': {
                                    maxHeight: '22px',
                                    width: '100%'
                                },
                            },
                            '&.active': {
                                minWidth: '260px',
                                background: alpha(theme.palette.background.paper, 0.1),
                                outline: `2px solid ${alpha(theme.palette.background.paper, 1)}`,
                                boxShadow: 7,
                                transition: 'all 350ms ease-in-out',
                                '& .selectContentBox': {
                                    '& .optionTitle': {
                                        background: alpha(theme.palette.background.paper, 1),
                                        color: theme.palette.primary.main,
                                    },
                                },
                                '& .optionIcon': {
                                    color: theme.palette.background.paper,
                                    fontSize: '2rem',
                                    transition: 'all 300ms',
                                },
                            },
                        },
                    }}>
                    <Box className={`animate__animated animate__fadeInUp paymentOption ${paymentMode == '1' && ' active'}`}
                        sx={{ flex: paymentMode == '1' ? `3 1 160px` : `1 1 60px` }}
                        onClick={() => setPaymentMode('1')}>
                        <Box className={'selectContentBox'}>
                            <AccountBalanceWalletTwoTone className={'optionIcon'} />
                            <Typography variant={'caption'} className={'optionTitle'}>
                                Offline
                            </Typography>
                            {paymentMode == '1' &&
                                <Box className={'content'}
                                    sx={{
                                        '& .refInput': {
                                            background: alpha(theme.palette.background.paper, 0.1),
                                            color: theme.palette.text.primary,
                                            p: '12px',
                                            borderWidth: '0 0 0 0',
                                            width: '100%',
                                            outline: 'none',
                                            borderRadius: '12px',
                                            borderColor: errors?.trxn_no ? theme.palette.error.main : theme.palette.background.default,
                                            '&::placeholder': {
                                                color: theme.palette.text.secondary,
                                            },
                                            '&:focus': {
                                                borderWidth: '0 0 2px 0',
                                                borderStyle: 'solid',
                                                borderColor: errors?.reference_no ? theme.palette.error.main : theme.palette.background.default,
                                                borderRadius: '0',
                                            },

                                        },
                                    }}>
                                    <input placeholder="Reference Remarks" className="refInput" {...register('trxn_no', { required: true })} />
                                </Box>
                            }
                        </Box>
                    </Box>
                    <Box className={`animate__animated animate__fadeInUp paymentOption ${paymentMode == '2' && ' active'}`}
                        sx={{ flex: paymentMode == '2' ? `3 1 160px` : `1 1 60px` }}
                        onClick={() => setPaymentMode('2')}>
                        <Box className={'selectContentBox'}>
                            <PaymentTwoTone className={'optionIcon'} />
                            <Typography variant={'caption'} className={'optionTitle'}>
                                Online
                            </Typography>
                            {paymentMode == '2' &&
                                <Box className={'content'}>
                                    <Typography sx={{
                                        color: theme.palette.background.paper,
                                        fontSize: '13px',
                                    }}>
                                        ⚠️ Payment QR will be shown here to pay the payable amount.
                                    </Typography>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>
            }


            <Box sx={{
                mt: 3,
                flex: 1,
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'flex-end',
                alignContent: 'flex-end',
                background: alpha(theme.palette.background.default, 0.3),
                backdropFilter: 'blur(4px)',
                '& .MuiButton-root': {
                    width: '100%',
                    textWrap: 'nowrap',
                },
            }}>

                <LoadingButton
                    variant={'contained'}
                    size={'large'}
                    loading={submitting}
                    endIcon={<></>}
                    loadingPosition={'end'}
                    onClick={handleSubmit(onSubmit)}>
                    Mark as Successful
                </LoadingButton>

            </Box>
        </Box >
    )
}

export const CancelForm = (props: any) => {

    const { closeDialog, serviceId, fetchServiceDetail } = props

    const theme = useTheme()
    const dispatch = useDispatch()

    const [cancelling, setCancelling] = useState(false)
    const { control, handleSubmit, watch, register, formState: { errors } } = useForm()
    const inputData = watch()

    const onSubmit = (data: any) => {
        const newData = {
            id: serviceId,
            cancel_remarks:
                data?.cancel_remarks == 'Others'
                    ? data?.other_remarks
                    : data?.cancel_remarks
        }
        setCancelling(true)
        dispatch(updateProcessState(true))
        API.post('/cancel_order', newData)
            .then((res) => {
                fetchServiceDetail()
                toast.success('Request cancelled successfully!')
            })
            .catch((err) => console.log(err))
            .finally(() => {
                closeDialog()
                setCancelling(false)
                dispatch(updateProcessState(false))
            })
    }

    return (
        <Box sx={{
            width: 'min(80vw, 350px)',
            pt: 5,
        }}>

            <Controller name={`cancel_remarks`}
                control={control}
                rules={{ required: { value: true, message: 'Cancellation remark is required' } }}
                defaultValue={''}
                render={({ field }) => (
                    <ThemeTextField
                        {...field} required className={'formInput'}
                        error={Boolean(errors?.cancel_remarks)} select
                        helperText={(errors?.cancel_remarks?.message ?? '').toString()}
                        size={'small'} label={'Remarks'}
                        placeholder={'cancel_remarks'}
                    >
                        <MenuItem value="Regulatory compliance">Regulatory compliance</MenuItem>
                        <MenuItem value="Unforeseen obstacles">Unforeseen obstacles</MenuItem>
                        <MenuItem value="Safety concerns">Safety concerns</MenuItem>
                        <MenuItem value="Crop conditions">Crop conditions</MenuItem>
                        <MenuItem value="Pest population dynamics">Pest population dynamics</MenuItem>
                        <MenuItem value="Chemical supply issues">Chemical supply issues</MenuItem>
                        <MenuItem value="Operational constraints">Operational constraints</MenuItem>
                        <MenuItem value="Customer request">Customer request</MenuItem>

                        <MenuItem value="Unsupportive weather conditions">Unsupportive weather conditions</MenuItem>
                        <MenuItem value="Technical issue with Drone">Technical issue with Drone</MenuItem>
                        <MenuItem value="Drone not available">Drone not available</MenuItem>
                        <MenuItem value="Spray Service with another provider undertaken by Farmer">Spray Service with another provider undertaken by Farmer</MenuItem>
                        <MenuItem value="Farmer rescheduled the request date">Farmer rescheduled the request date</MenuItem>
                        <MenuItem value="Unable to contact the Farmer">Unable to contact the Farmer</MenuItem>
                        <MenuItem value="No slot available for service delivery">No slot available for service delivery</MenuItem>
                        <MenuItem value="Service representative not available">Service representative not available</MenuItem>

                        <MenuItem value="Others">Others</MenuItem>
                    </ThemeTextField>
                )}
            />

            {inputData?.cancel_remarks == 'Others' &&
                <Controller name={`other_remarks`}
                    control={control}
                    rules={{
                        required: { value: true, message: 'Required' },
                        pattern: { value: /^[a-zA-Z0-9 ]*$/, message: 'Special characters not allowed' },
                    }}
                    defaultValue={''}
                    render={({ field }) => (
                        <ThemeTextField
                            {...field} required className={'formInput'} multiline rows={3}
                            error={Boolean(errors?.other_remarks)}
                            helperText={(errors?.other_remarks?.message ?? '').toString()}
                            size={'small'} label={'Others Remarks'}
                            placeholder={'other_remarks'}
                        />
                    )}
                />
            }


            <Box
                sx={{
                    mt: 3,
                    flex: 1,
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'flex-end',
                    alignContent: 'flex-end',
                    mx: 'auto',
                    background: alpha(theme.palette.background.default, 0.3),
                    backdropFilter: 'blur(4px)',
                    '& .MuiButton-root': {
                        width: '100%',
                        textWrap: 'nowrap',
                        textTransform: 'capitalize'
                    },
                }}>

                <LoadingButton
                    variant={'contained'}
                    size={'large'}
                    color='error'
                    loading={cancelling}
                    endIcon={<></>}
                    loadingPosition={'end'}
                    onClick={handleSubmit(onSubmit)}>
                    Confirm
                </LoadingButton>
                <Button
                    size={'large'}
                    sx={{ mr: 1 }}
                    onClick={closeDialog}>
                    Discard & Back
                </Button>

            </Box>
        </Box>
    )
}

export default ServiceDetail