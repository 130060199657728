import React, { Suspense } from 'react'
import { Box, Divider, Typography, alpha, useTheme } from '@mui/material'
import { IdIcon } from '../../components/icons'
import { RootState } from '../../store/store'
import LoadingView from '../../components/loading-view'
import { useSelector } from 'react-redux'
import { useDocumentTitle } from '../../hooks/useDocumnetTitle'
import dp from '../../assets/images/app-icons/agri-wing-logo.svg'
import PageContainer from '../../components/container/page-container'
import TitleBar from '../../components/container/TitleBar'
import { getRoleName } from '../../utils/app-helper'

const MyAssociates = React.lazy(() => import('../my-associates'))
const MyAttendance = React.lazy(() => import('../../components/MyAttenance'))


export default function MyAccount() {

    useDocumentTitle('My Account')

    return (
        <ProfilePage />
    )
}


const ProfilePage = () => {

    const theme = useTheme()
    const user = useSelector((state: RootState) => state?.userAuth?.currentUser)

    return (
        <PageContainer>
            <TitleBar title={'My Account'} />
            <Box
                className={'flexTopTop wrap'}
                sx={{
                    mt: 3,
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    alignContent: 'flex-start',
                    gap: 2,
                    '& .buttonGradient': {
                        backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        outline: `0px solid ${theme.palette.primary.main}`,
                        outlineOffset: 0,
                        boxShadow: 0,
                        textTransform: 'none',
                        transition: 'all 100ms ease',
                        '&:hover': {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: '2px',
                            '&:active': {
                                transform: 'scale(0.98) !important'
                            }
                        },
                    },
                }}>

                <Box
                    className={'flexTopTop wrap'}
                    sx={{
                        minWidth: '100%',
                        background: alpha(theme.palette.primary.main, 0.1),
                        p: 1.5,
                        borderRadius: '26px',
                        gap: 2,
                        order: -1,
                    }}>
                    <Box
                        className={'wrap'}
                        sx={{
                            display: 'flex',
                            alignItems: { xs: 'flex-end', md: 'flex-start' },
                            gap: 2,
                            flexDirection: { xs: 'row', md: 'column' },
                            flex: '2 1 500px',
                            alignSelf: 'center',
                            pl: { sm: 0, md: 1 },
                            pt: { sm: 0, md: 1 },
                            '& img': {
                                height: '8rem',
                                width: '8rem',
                                objectFit: 'contain',
                                outline: `2px solid ${theme.palette.primary.main}`,
                                borderRadius: '18px',
                                background: theme.palette.background.default,
                            },
                        }}>
                        <img src={dp} alt="profile pic" />

                        <Box sx={{
                            '& .MuiTypography-root': {
                                fontWeight: 600,
                                fontFamily: theme.typography.h1.fontFamily,
                                fontSize: theme.typography.pxToRem(22),
                                color: theme.palette.text.primary,
                                '&.code': {
                                    fontWeight: 600,
                                    color: theme.palette.text.secondary,
                                    fontSize: theme.typography.pxToRem(14),
                                    gap: 0.5,
                                },
                                '& svg': {
                                    height: 16,
                                    width: 16,
                                },
                            }
                        }}>
                            <Typography className={'name'}>{user?.name ?? ''}</Typography>
                            {user?.emp_id && <Typography className={'flexTopTop code'} ><IdIcon /> {user?.emp_id?.toUpperCase()}</Typography>}
                        </Box>

                    </Box>
                    <Box sx={{
                        flex: '1 1 500px',
                        background: alpha(theme.palette.background.default, 1),
                        p: 1.5, px: 2.5,
                        borderRadius: '18px',
                        alignSelf: 'stretch',
                    }}>
                        <DetailItem label='Role' content={getRoleName(user?.role?.name ?? '')} />
                        {(user?.role?.name == 'rm' || user?.role?.name == 'rtl') && <DetailItem label='Supervisor' content={user?.supervisor_name ?? ''} />}
                        <DetailItem label='Provider' content={user?.provider_name ?? ''} />
                        <DetailItem label='Contact' content={`Email: ${user?.email}`} />
                    </Box>
                </Box>


                <Box sx={{
                    flex: '2 1 500px',
                }}>
                    {(user?.role?.name != 'super admin' || user?.is_supervisor_head != '0')
                        &&
                        <>
                            <Typography variant='h6' sx={{
                                fontSize: theme.typography.pxToRem(18),
                                fontWeight: 600,
                                color: theme.palette.primary.main,
                                mx: 2,
                            }}>
                                My Team
                            </Typography>
                            <Divider sx={{ mx: 2, mb: 3, }} />

                            <Suspense fallback={<span>fetching, please wait</span>}>
                                <MyAssociates />
                            </Suspense>
                        </>
                    }
                </Box>
                <Box sx={{
                    flex: '1 1 500px',
                    borderRadius: '18px',
                    display: 'grid',
                    gap: 3,
                    py: 2,
                }}>
                    {(user?.provider_id == '1' && (user?.role?.name == 'rm' || user?.role?.name == 'rtl')) && <AttendanceBlock />}
                </Box>

            </Box>

        </PageContainer >
    )
}

export const DetailItem = ({ label, content }: { label?: string, content?: string }) => {

    const theme = useTheme()

    return (
        <Typography
            className='detailItem'
            sx={{
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'flex-start',
                '&:has(+ .detailItem)': {
                    mb: 1.5,
                },
                '& .label': {
                    fontSize: theme.typography.pxToRem(12),
                    lineHeight: theme.typography.pxToRem(14),
                    color: theme.palette.text.secondary,
                    mb: 0.5,
                },
                '& .content': {
                    fontSize: theme.typography.pxToRem(14),
                    lineHeight: theme.typography.pxToRem(18),
                    color: theme.palette.text.primary,
                    fontWeight: 600,
                },
            }}>
            {label && <span className={'label'}>{label}</span>}
            {content?.split(',').map((item: string, i: number) => <span className={'content'} key={item}>{item}</span>)}
        </Typography>
    )
}

const AttendanceBlock = () => {

    const theme = useTheme()

    return (
        <Box component={'aside'} sx={{
            py: 2,
            borderRadius: '18px',
            boxShadow: `0 0 17px -3px #83838380 inset`,
            minHeight: '260px'
        }}>
            <Typography variant='h6' sx={{
                fontSize: theme.typography.pxToRem(14),
                mx: 2,
            }}>
                My Attendance
            </Typography>

            <Divider sx={{ mx: 2 }} />

            <Suspense fallback={<LoadingView />}>
                <MyAttendance />
            </Suspense>

        </Box>
    )
}
