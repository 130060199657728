import { Box, IconButton, alpha, useMediaQuery, useTheme } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react'
import { ThemeDataTable } from '../../components/theme-data-table-container';
import TableLoading from '../../components/theme-table-container/TableLoading';
import NoDataView from '../../components/noDataView';
import TitleBar from '../../components/container/TitleBar';
import PageContainer from '../../components/container/page-container';
import API from '../../api';
import { camelCaseWords, capitalizeWords, currencyFormatter } from '../../utils/app-helper';
import { Sync } from '@mui/icons-material';
import moment from 'moment';
import { useDocumentTitle } from '../../hooks/useDocumnetTitle';

export default function RegionalClientsReport() {

    useDocumentTitle('Regional Clients')

    const theme = useTheme()
    const [clients, setClients] = useState<any[]>([])
    const [fetching, setFetching] = useState<boolean>(true)

    const fetchClients = useCallback(() => {
        setFetching(true)
        API.get('/get_all_clients_list')
            .then((res) => {
                res.data?.statuscode == "200" &&
                    res.data?.data && setClients(res.data?.data)
            })
            .catch((err) => console.error(err))
            .finally(() => setFetching(false))
    }, [])

    useEffect(() => {
        fetchClients()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Regional Clients Report'} secondChild={
                <IconButton
                    onClick={fetchClients}
                    size='small'
                    sx={{
                        mr: 2,
                        background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                        boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                        border: `1px solid ${theme.palette.background.paper}`,
                    }}>
                    <Sync />
                </IconButton>
            } />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                }}>

                <ClientsTable data={clients} fetching={fetching} />
            </Box>
        </PageContainer>
    )
}

const ClientsTable = (props: any) => {

    const { data, fetching } = props
    const theme = useTheme()

    const columns: GridColDef[] = [
        {
            field: 'base_client', headerName: 'Base Client', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => `${value?.client_name ?? ''}`
        },
        { field: 'regional_client_name', headerName: 'Reginaol client', flex: 1, minWidth: 160 },
        { field: 'state', headerName: 'State', flex: 1, minWidth: 70 },
        {
            field: 'gst_no', headerName: 'GSTIN', flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams) =>
                <span style={{ textTransform: 'uppercase' }}>{params.value}</span>
        },
        { field: 'address', headerName: 'Address', flex: 1, minWidth: 160 },
        {
            field: 'status', headerName: 'Status', flex: 1, minWidth: 100,
            valueFormatter: ({ value }) => `${value == '1' ? 'Active' : 'Inactive'}`
        },
    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetching}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}


