import { Box, Typography } from "@mui/material"
import noData from '../../assets/images/illustartions/no-data.svg'


interface noDataViewInterface {
    message?: string
    image?: boolean
}

const NoDataView = (props: noDataViewInterface) => {

    const { message, image = true } = props
    return (
        <Box sx={{
            minHeight: '280px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column',
            textAlign: 'center',
            '& img': {
                maxHeight: '120px',
                objectFit: 'contain'
            },
        }}>
            {image &&
                <img src={noData} className={'noDataImage'} alt={'no data'} />
            }
            <Typography className={'des'}>
                {message ? message : 'No data to show'}
            </Typography>
        </Box>
    )
}

export default NoDataView