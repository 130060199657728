import { useCallback, useEffect, useState } from "react";
import API from "../api";

export function useAssets() {
  const [assets, setAssets] = useState<any[]>([]);
  const [fetchingAssets, setFetchingAssets] = useState(false);

  const fetchAssets = useCallback(async () => {
    try {
      setFetchingAssets(true);
      const response = await API.get("/show_asset_list", {
        headers: { Accept: "application/json" },
      });

      if (response.data?.statuscode === "200") {
        setAssets(response.data?.data || []);
      } else {
        console.error(
          "Failed to fetch assets:",
          response.data?.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching assets:", error);
    } finally {
      setFetchingAssets(false);
    }
  }, []);

  return {
    fetchAssets,
    assets,
    fetchingAssets,
  };
}
