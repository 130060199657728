import { useCallback, useEffect, useState } from "react";
import API from "../api";

export function useSchemesData() {
  const [schemes, setSchemes] = useState<any[]>([]);
  const [fetchingSchemes, setFetchingSchemes] = useState(true);

  const fetchSchemes = useCallback(async () => {
    try {
      setFetchingSchemes(true);
      const response = await API.get("/get_scheme_list", {
        headers: { Accept: "application/json" },
      });

      if (response.data) {
        // if (response.data?.statuscode === "200") {
        setSchemes(response.data?.data || []);
      } else {
        console.error(
          "Failed to fetch Schemes:",
          response.data?.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching Schemes:", error);
    } finally {
      setFetchingSchemes(false);
    }
  }, []);

  return {
    schemes,
    fetchSchemes,
    fetchingSchemes,
  };
}
