import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Box, Toolbar, Paper, useTheme } from "@mui/material"
import { Outlet } from "react-router"
import NoInternetConnection from '../../components/no-internet-connection'

export default function ContentArea(props: any) {

    const { smallScreen, drawerWidth, hovered, open } = props
    const pr = smallScreen ? 0 : 60

    const theme = useTheme()



    return (
        <Box data-owner={'Amit Thakur'} className={'thakuramit962'} sx={{
            flexGrow: 1,
            display: 'flex',
            flexFlow: 'column',
            height: '100vh',
            pr: { sm: 0, md: '0.5rem' }, pb: '0.5rem',
            maxWidth: smallScreen ? '98vw' : !open && hovered ? `calc(100vw - ${pr + 8}px)` : `calc(100vw - ${drawerWidth + 8}px)`,
            ml: 'auto',
            mr: smallScreen ? 'auto' : 0,
            transition: 'all 300ms ease-in-out',
            '& .contentArea': {
                flex: 1,
                maxWidth: '100%',
                overflow: 'auto',
                borderRadius: { xs: '0px', md: `20px` },
                background: theme.palette.background.paper,
                boxShadow: `0 0 12px -3px ${theme.palette.mode == 'dark' ? '#83838380' : '#69696980'} inset`,
                px: { xs: 0, sm: 1 }, pt: 2,
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'stretch',
            },
        }}>
            <Toolbar />
            <Paper className={'contentArea'}>
                <Box id={'scrollContainer'} sx={{
                    height: 'calc(100vh - 72px)',
                    overflow: 'auto',
                }}>
                    {window?.navigator?.onLine
                        ? <Outlet />
                        : <NoInternetConnection />
                    }
                </Box>
            </Paper>
        </Box>
    )
}