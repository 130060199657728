import { useCallback, useState } from "react";
import API from "../api";

export function useFarmers() {
  const [farmers, setFarmers] = useState<any[]>([]);
  const [fetchingFarmers, setFetchingFarmers] = useState(false);

  const fetchFarmers = useCallback(async () => {
    try {
      setFetchingFarmers(true);
      const response = await API.get("/get-farmers", {
        headers: { Accept: "application/json" },
      });

      if (response.data?.statuscode === "200") {
        setFarmers(response.data?.farmer_details || []);
      } else {
        console.error(
          "Failed to fetch crops:",
          response.data?.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching crops:", error);
    } finally {
      setFetchingFarmers(false);
    }
  }, []);

  return {
    fetchFarmers,
    fetchingFarmers,
    farmers,
  };
}
