import React, { ReactNode } from 'react';
import { alpha, Box, BoxProps, SxProps, Typography, useTheme } from '@mui/material';

interface ElevatedContainerProps extends BoxProps {
    // You can add any additional props here if needed
    label?: string;
    children: ReactNode;
    sx?: SxProps;

}
const ElevatedContainer: React.FC<ElevatedContainerProps> = ({ children, label, sx, ...boxProps }) => {
    const theme = useTheme();
    const containerSx = {
        padding: '0.5rem',
        borderRadius: '18px',
        background: theme.palette.mode === 'dark'
            ? '#0e0e0e'
            : `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.07)}, ${theme.palette.background.paper})`,
        boxShadow: theme.palette.mode === 'dark' ? 'none' : `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
        border: `2px solid ${theme.palette.background.paper}`,
        ...sx,
    };

    return (
        <Box sx={containerSx} {...boxProps}>
            {label && (
                <Typography
                    variant="h4"
                    sx={{
                        width: '100%',
                        fontFamily: theme.typography.h1.fontFamily,
                        fontSize: '14px',
                        fontWeight: 600,
                        color: theme.palette.primary.main,
                        pl: 2,
                        mb: 1,
                    }}
                >
                    {label}
                </Typography>
            )}
            {children}
        </Box>
    );
}
export default ElevatedContainer