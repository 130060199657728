import React, { useCallback, useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import API from '../../../../api'
import { alpha, Badge, Box, IconButton, Skeleton, Tab, Tabs, useTheme } from '@mui/material'
import { ArrowLeftIcon, ArrowRightIcon, CalendarIcon, GridIcon, ListIcon } from '../../../../components/icons'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { getAllDatesUpToToday } from '../../../../utils/app-helper'
import UserInfoBlock from './userInfoBlock'
import SingleAttendance from './attendnaceListItem'


interface ViewUserAttendanceProps {
    userId: string
    date?: string
    showUser?: boolean
    multiView?: boolean
}

const ViewUserAttendance = ({ userId, date = `${moment().startOf('month').format('YYYY-MM-DD')}`, showUser = false, multiView = false }: ViewUserAttendanceProps) => {

    const theme = useTheme()

    const [month, setMonth] = useState(date)
    const [selectedMonthAttendance, setSelectedMonthAttendance] = useState<any[]>([])
    const [loading, setLoading] = useState(true)

    const [viewType, setViewType] = React.useState<'list' | 'calender'>('calender')

    const [dates, setDates] = useState<any[]>([])
    const startOfMonth = moment(month, 'YYYY-MM-DD').startOf('month')
    const startDay = startOfMonth.day()
    const emptyDates = Array.from(Array(startDay)).map((_) => null)

    const calenderDates = viewType == 'list' ? dates : [...emptyDates, ...dates]

    const changeMonth = (type: 'prev' | 'next') => setMonth(prev => moment(prev, 'YYYY-MM-DD').add(type == 'next' ? 1 : -1, 'month').format('YYYY-MM-DD'))
    const toggleViewType = (event: React.SyntheticEvent, newValue: 'list' | 'calender') => setViewType(newValue)

    const mapAttendanceForMonth = useCallback((month: Moment) => {

        const allDates = getAllDatesUpToToday(`${moment(month).startOf('month').format('YYYY-MM-DD')}`, viewType == 'list')

        const generatedDates = allDates?.map((date: any, i: number) => (
            {
                date: date,
                data: selectedMonthAttendance?.filter((att: any) => att?.date == moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD'))[0] || null
            }
        ))
        setDates(generatedDates)
    }, [month, viewType])


    const fetchAttendance = useCallback(() => {
        setLoading(true)
        API.post('/get_employee_attendace', {
            from: moment(month, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD'),
            to: moment(month, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD'),
            user_id: userId ? atob(userId) : null,
        }).then((res) => {
            if (res.data?.statuscode == "200") {
                console.log(res.data?.data)
                setSelectedMonthAttendance(res.data?.data)

                const allDates = getAllDatesUpToToday(`${moment(month).startOf('month').format('YYYY-MM-DD')}`, viewType == 'list')
                const generatedDates = allDates?.map((date: any, i: number) => (
                    {
                        date: date,
                        data: res.data?.data?.filter((att: any) => att?.date == moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD'))[0] || null
                    }
                ))
                setDates(generatedDates)

            } else {
                console.error(
                    "Failed to fetch attendance:",
                    res.data?.message || "Unknown error"
                );
            }
        }).catch(err => console.log(err))
            .finally(() => {
                setLoading(false)
            })
    }, [month, userId])


    useEffect(() => {
        fetchAttendance()
    }, [month, userId])

    useEffect(() => {
        mapAttendanceForMonth(moment(month, 'YYYY-MM-DD'))
    }, [month, viewType])

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    maxHeight: { xs: '85vh', sm: '65vh' },
                    overflow: 'auto',
                    width: '100%',
                    userSelect: 'none',
                    minWidth: '240px',
                }}>
                <Box
                    className={'flexBetweenCenter bn'}
                    sx={{
                        position: 'sticky',
                        top: 0,
                        backdropFilter: 'blur(16px)',
                        gap: 1.5,
                        flexWrap: 'wrap',
                        p: 1.5,
                        zIndex: 9,
                        width: 'calc(100% - 1rem)',
                        left: '0.5rem',
                        '& .datePicker': {
                            flex: '0 0 90px',
                            '& .MuiInputBase-root': {
                                pr: 0,
                            },
                            '& input': {
                                padding: '4px',
                                textAlign: 'center',
                                color: theme.palette.info.main,
                                fontWeight: 600,
                            },
                            '& fieldset': {
                                border: 'none',
                            }
                        }
                    }}>

                    {showUser &&
                        <Box sx={{
                            minWidth: '100%',
                        }}>
                            <UserInfoBlock userId={atob(userId)} />
                        </Box>
                    }

                    {multiView &&
                        <Box sx={{
                            flex: 1,
                            '& .MuiTabs-root': {
                                maxWidth: 'max-content',
                                p: '2px',
                                borderRadius: '12px',
                                background: alpha(theme.palette.primary.main, 0.1),
                                minHeight: 'auto',
                                '& .MuiTab-root': {
                                    p: '4px 8px',
                                    minHeight: '24px',
                                    zIndex: 1,
                                    textTransform: 'none',
                                    minWidth: '3rem',
                                    '& svg': {
                                        height: '18px',
                                        width: '18px',
                                    },
                                    '&.Mui-selected': {
                                        color: theme.palette.primary.main
                                    },
                                },
                                '& .MuiTabs-indicator': {
                                    zIndex: 0,
                                    borderRadius: '12px',
                                    height: '100%',
                                    boxShadow: `0 0 14px ${theme.palette.primary.main} inset`,
                                    background: theme.palette.background.paper
                                },
                            },
                        }}>
                            <Tabs value={viewType} onChange={toggleViewType} aria-label="basic tabs example">
                                <Tab icon={<ListIcon />} value="list" aria-label="list" />
                                <Tab icon={<GridIcon />} value="calender" aria-label="calender" />
                            </Tabs>
                        </Box>
                    }

                    <Box className={'flexBottomCenter'} sx={{ flex: 1 }}>
                        <IconButton onClick={() => changeMonth('prev')}><ArrowLeftIcon /></IconButton>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <MobileDatePicker
                                openTo="month"
                                views={['year', 'month']}
                                disableFuture
                                value={moment(month, 'YYYY-MM-DD')}
                                onAccept={(newValue: any) => setMonth(newValue)}
                                closeOnSelect
                                className='datePicker'
                                format='MMM YYYY'
                                slotProps={{
                                    textField: {
                                        sx: {
                                            color: '#bbdefb',
                                            borderRadius: '16px',
                                            border: 'none',
                                            padding: 0,
                                            height: 'auto'
                                        },
                                    },
                                    actionBar: {
                                        sx: { display: 'none' }
                                    },
                                }}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        padding: 0,
                                    },
                                    borderRadius: '18px',
                                    '&.MuiInputBase-roott': {
                                        borderRadius: '18px',
                                    },
                                    '& fieldset': {
                                        borderWidth: 0,
                                        borderColor: theme.palette.action.hover,
                                        borderRadius: '16px',
                                        '&:hover': {
                                            borderWidth: '1px',
                                            borderRadius: '16px',
                                        }
                                    },
                                }}
                            />
                        </LocalizationProvider>
                        <IconButton disabled={moment(month).endOf('month').isSameOrAfter(moment())} onClick={() => changeMonth('next')}><ArrowRightIcon /></IconButton>
                    </Box>
                </Box>

                {viewType == 'calender' && <CalenderView loading={loading} calenderDates={calenderDates} />}

                {viewType == 'list' &&
                    <Box
                        className={'animate__animated animate__fadeIn'}
                        sx={{
                            p: 1,
                            '& .MuiSkeleton-root': {
                                transform: 'none',
                                mb: 1,
                                borderRadius: '12px',
                                background: alpha(theme.palette.text.disabled, 0.05)
                            }
                        }}>
                        {loading
                            ? Array.from(Array(7)).map((_, i) => <Skeleton animation={'wave'} key={i} height={48} />)
                            : calenderDates?.map((attendnace: any, index: number) => <SingleAttendance key={index} data={attendnace} />)
                        }
                    </Box>
                }
            </Box>
        </>
    )
}

const CalenderView = ({ calenderDates, loading = false }: { calenderDates: any[], loading: boolean }) => {

    const theme = useTheme()

    return (
        <Box
            className={'animate__animated animate__fadeIn'}
            sx={{
                '& .weekRow': {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignContent: 'center',
                    marginBottom: '8px',
                    flexWrap: 'wrap',
                    width: '100%',
                },
                '& .weekDay': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    borderRadius: 20,
                    width: `calc(100% / 7)`,
                    height: '38px',
                    cursor: 'pointer',
                },
                '& .MuiSkeleton-root': {
                    transform: 'scale(0.95)',
                    borderRadius: '12px',
                    background: alpha(theme.palette.text.disabled, 0.05)
                }
            }}>
            <Box className={'weekRow'}>
                {
                    ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']?.map((day: any, i: number) => (
                        <Box key={`${i}`} className={'weekDay animate__animated animate__fadeIn'} sx={{ fontWeight: 600 }}>{day}</Box>
                    ))
                }
            </Box>
            <Box className={'weekRow'}>
                {loading
                    ? Array.from(Array(35)).map((_, i) => <Skeleton key={i} className='weekDay' />)
                    : calenderDates?.map((day: any, i: number) => (
                        <Box key={`${i}`} className={'weekDay animate__animated animate__fadeIn'}>
                            <Badge
                                color={
                                    day?.date && moment(day?.date, 'DD-MM-YYYY').isSameOrBefore(moment()) ?
                                        (moment(day?.date, 'DD-MM-YYYY').get('day') == 0 && !day?.data ? `default`
                                            : (day?.data?.on_leave == '0' && day?.data?.attendance_status != '0') ? `success`
                                                : (day?.data?.on_leave == '1' && day?.data?.attendance_status != '0') ? `warning` :
                                                    `error`)
                                        : 'default'
                                }
                                variant="dot">
                                <span style={{
                                    color: moment(day?.date, 'DD-MM-YYYY').get('day') == 0 ? theme.palette.text.disabled : theme.palette.text.primary
                                }}>
                                    {day?.date ? moment(day?.date, 'DD-MM-YYYY').format('D') : null}
                                </span>
                            </Badge>
                        </Box>
                    ))
                }
            </Box>
        </Box>
    )
}

export default ViewUserAttendance