import { Box, ButtonBase, Typography, alpha, useScrollTrigger, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TitleBar from '../../../../components/container/TitleBar'
import { useNavigate } from 'react-router'
import MeetingItem from './meeting-item'
import API from '../../../../api'
import moment from 'moment'
import toast from 'react-hot-toast'
import { MeetingDetailsDrawer } from './meeting-details-drawer'
import NoDataView from '../../../../components/theme-table-container/NoData-'

function Meetings() {

    const theme = useTheme()
    const navigate = useNavigate()

    const [active, setActive] = useState(`Today's`)

    const [selectedMeeting, setSelectedMeeting] = useState({} as any);

    const [meetings, setMeetings] = useState<any[]>([]);
    const [fetchingMeetings, setFetchingMeetings] = useState(false);

    const fetchTodaysMeetings = () => {
        setFetchingMeetings(true)
        const url = active == `Today's`
            ? `/meeting-details-list?from_date=${moment().format("YYYY-MM-DD")}&to_date=${moment().format("YYYY-MM-DD")}`
            : active == `Yesterday's` ? `/meeting-details-list?from_date=${moment().clone().subtract(1, 'days').format("YYYY-MM-DD")}&to_date=${moment().clone().subtract(1, 'days').format("YYYY-MM-DD")}`
                : active == `Week's` ? `/meeting-details-list?from_date=${moment().clone().startOf('week').format("YYYY-MM-DD")}&to_date=${moment().clone().endOf('week').format("YYYY-MM-DD")}`
                    : active == `Month's` ? `/meeting-details-list?from_date=${moment().clone().startOf('month').format("YYYY-MM-DD")}&to_date=${moment().clone().endOf('month').format("YYYY-MM-DD")}`
                        : `/meeting-details-list`
        API.get(url)
            .then((res) => {
                if (res.data?.status == "success") {
                    setMeetings(res.data?.data)
                } else {
                    toast.error(res.data?.message);
                }
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setFetchingMeetings(false)
            })
    };

    const scrolled = useScrollTrigger({ disableHysteresis: true, threshold: 0, });

    useEffect(() => {
        fetchTodaysMeetings();
    }, [active]);

    return (
        <>
            <Box
                sx={{
                    mb: 5,
                    position: 'relative',
                    pt: '60px',
                }}
            >
                <Box sx={{
                    position: 'fixed',
                    top: '56px',
                    zIndex: 1,
                    background: theme.palette.background.paper,
                    pb: 1.5,
                    borderBottom: scrolled ? `1px solid #83838380` : 'none',
                    mt: { xs: 1, sm: 2 },
                    mx: { xs: 2, sm: 2 },
                    width: '-webkit-fill-available',
                }}>
                    <TitleBar
                        title={"All Meetings"}
                        secondChild={
                            <Typography
                                className="flexCenter"
                                onClick={() => navigate('/activity/meeting/create')}
                                sx={{
                                    background: alpha(theme.palette.text.disabled, 0.1),
                                    borderRadius: "8px",
                                    p: "1px 8px",
                                    fontSize: "12px",
                                    gap: 0.5,
                                    "& svg": {
                                        height: "14px",
                                        width: "14px",
                                    },
                                }}
                            >
                                Add New
                            </Typography>
                        }
                    />

                    <Box
                        className={'flexStartCenter'}
                        sx={{
                            gap: 1,
                            overflowX: 'auto',
                            overflowY: 'hidden',
                            whiteSpace: 'nowrap',
                            scrollSnapType: 'x mandatory',
                            scrollSnapAlign: 'center',
                        }}>
                        {[`Today's`, `Yesterday's`, `Week's`, `Month's`, `All`].map((item: string) => (
                            <ButtonBase
                                key={item}
                                onClick={() => setActive(item)}
                                sx={{
                                    background: active == item ? alpha(theme.palette.primary.main, 1) : alpha(theme.palette.text.disabled, 0.1),
                                    px: 1.5,
                                    py: 0.5,
                                    borderRadius: '50vh',
                                    color: active == item ? theme.palette.primary.contrastText : theme.palette.text.secondary,
                                }}>
                                {item}
                            </ButtonBase>
                        ))}
                    </Box>
                </Box>

                <Box
                    sx={{
                        pt: 6,
                        width: "100%",
                        display: 'grid',
                        justifyContent: 'stretch',
                        gap: 1,
                    }}
                >

                    {fetchingMeetings
                        ?
                        <>
                            {
                                Array.from(Array(4)).map((_, i) => (
                                    <Box
                                        key={i}
                                        className={'animate__animated animate__fadeIn'}
                                        sx={{
                                            minHeight: '80px',
                                            background: alpha(theme.palette.text.disabled, 0.1),
                                            borderRadius: '18px',
                                        }} />
                                ))
                            }

                        </>
                        : meetings?.length > 0
                            ? meetings?.map((meeting: any, i: number) => (
                                <MeetingItem
                                    key={i}
                                    data={meeting}
                                    handleClick={() => {
                                        setSelectedMeeting(meeting);
                                    }}
                                />
                            ))
                            : <NoDataView />
                    }
                </Box>

            </Box >

            <MeetingDetailsDrawer
                open={!!selectedMeeting?.meeting_details?.id}
                data={selectedMeeting}
                handleClose={() => setSelectedMeeting({} as any)}
            />
        </>

    )
}

export default Meetings
