import React, { useCallback, useEffect, useState } from 'react'
import {
    Box,
    Button,
    Chip,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    alpha,
    debounce,
    useTheme,
} from "@mui/material"
import {
    AgricultureRounded,
    CalendarMonthRounded,
    DescriptionRounded,
    GrassRounded,
    PhoneRounded,
    SearchRounded,
    SensorOccupiedRounded,
    Sync,
    Timeline
} from "@mui/icons-material"
import { useNavigate } from 'react-router'
import moment from 'moment'
import toast from 'react-hot-toast'
import ServiceTimeline from '../service-timeline'
import RmActionForm from '../rm-action-form'
import API from "../../../api"
import PageContainer from '../../../components/container/page-container'
import TitleBar from '../../../components/container/TitleBar'
import TableLoading from '../../../components/theme-table-container/TableLoading'
import NoDataView from '../../../components/theme-table-container/NoData-'
import ThemeDialog from '../../../components/dialog-box/theme-dialog'
import { User } from '../../../models/user'
import { Operator } from '../../../models/operator'
import { RootState } from '../../../store/store'
import { useProviders } from '../../../hooks/useProviders'
import { useDocumentTitle } from '../../../hooks/useDocumnetTitle'
import { camelCaseWords, capitalizeWords, currencyFormatter, serverRouteWithoutApi } from '../../../utils/app-helper'
import { ThemeTableContainer } from '../../../components/theme-table-container'
import { usePaginatedServices } from '../../../hooks/usePaginatedServices'
import { useSelector } from 'react-redux'


export default function ServiceList() {

    useDocumentTitle('Service Requests')

    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser

    const theme = useTheme()
    const navigate = useNavigate()

    return (
        <PageContainer>
            <TitleBar title={'Service Requests'}
                secondChild={
                    <>
                        {currentUser?.provider_id == '1' &&
                            <Button
                                variant='contained'
                                className={'buttonGradient animate__animated animate__fadeInUp'}
                                onClick={() => navigate('/services/create')}
                            >
                                Create
                            </Button>
                        }
                    </>
                } />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    '& .buttonGradient': {
                        backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        outline: `0px solid ${theme.palette.primary.main}`,
                        outlineOffset: 0,
                        boxShadow: 0,
                        textTransform: 'none',
                        transition: 'all 100ms ease',
                        '&:hover': {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: '2px',
                            '&:active': {
                                transform: 'scale(0.98) !important'
                            }
                        },
                    },

                }}>
                <ServiceTable />
            </Box>

        </PageContainer>
    )
}


const ServiceTable = () => {

    const theme = useTheme()
    const navigate = useNavigate()

    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser
    const isAdmin = (currentUser?.role?.name == 'super admin' || currentUser?.role?.name == 'admin')
    const isRm = (currentUser?.role?.name == 'rm' || isAdmin)

    const [selectedRequest, setSelectedRequest] = useState<null | {}>(null)
    const [dialogFor, setDialogFor] = useState<null | 'timeline' | 'rmAction'>(null)

    const [operators, setOperators] = useState<Operator[]>([])
    const { fetchProviders, fetchingProviders, providers } = useProviders()

    const fetchOperators = useCallback(() => {
        API.get('/fetch_operators_to_assign', { headers: { "Accept": "application/json" } })
            .then((res) => {
                res.data.status == 'success' &&
                    setOperators(res.data?.data)
            })
            .catch((err) => console.log(err))
            .finally(() => { })
    }, [])

    const [query, setQuery] = useState({
        page: 1,
        per_page: 50,
        search: ''
    })
    const { paginatedServices, fetchingPaginatedServices, fetchPaginatedServices } = usePaginatedServices({
        query: { page: query.page, per_page: query?.per_page, search: query?.search }
    });


    const keywordSearch = useCallback(
        debounce((value: string) => {
            setQuery({ ...query, page: 1, search: value })
        }, 500),
        []
    )


    useEffect(() => {
        fetchOperators()
        fetchProviders()
    }, [])

    useEffect(() => { fetchPaginatedServices() }, [query])

    return (
        <>
            <ThemeTable
                header={
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: 0.5,
                    }}>
                        <TextField
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchRounded sx={{ fontSize: '18px' }} /></InputAdornment>,
                            }}
                            variant="standard"
                            placeholder='search...'
                            sx={{
                                flex: 1,
                                maxWidth: '280px'
                            }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                keywordSearch(event.target.value)
                            }} />

                        <IconButton
                            onClick={fetchPaginatedServices}
                            size='small'
                            sx={{
                                mr: 2,
                                background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                                boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                                border: `1px solid ${theme.palette.background.paper}`,
                            }}>
                            <Sync />
                        </IconButton>
                    </Box>
                }
                table={
                    <Table stickyHeader size="small" aria-label="service table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='stickyLeft'>Request Id</TableCell>
                                <TableCell>Client & Farmer</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Acreage</TableCell>
                                <TableCell>Important Dates</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Crop & Operator</TableCell>
                                <TableCell>Provider</TableCell>
                                <TableCell align={'center'}>Other Details</TableCell>
                                <TableCell>Payment Type</TableCell>
                                <TableCell className='stickyRight'>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{
                            '& .MuiTableCell-root': {
                                p: '0 16px',
                            },
                        }}>
                            {fetchingPaginatedServices
                                ? <TableRow>
                                    <TableCell colSpan={11} >
                                        <Box className={'flexCenter'} sx={{
                                            minHeight: { xs: '65vh', sm: '65vh' },
                                        }}>
                                            <TableLoading />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                : paginatedServices?.data?.length > 0
                                    ? paginatedServices?.data?.map((service: any, i: number) => (
                                        <TableRow key={i} className={'animate__animated animate__fadeIn'}>
                                            <TableCell className='stickyLeft'>
                                                <Typography sx={{
                                                    fontSize: '13px',
                                                    display: 'grid',
                                                    color: service?.order_type == '4' ? theme.palette.info.main : (service?.order_type == '5' ? theme.palette.warning.main : theme.palette.text.primary),
                                                    '& span': {
                                                        fontSize: '11px',
                                                        fontWeight: 600,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '4px',
                                                        color: theme.palette.text.secondary,
                                                        '& svg': {
                                                            color: theme.palette.text.disabled,
                                                        },
                                                    }
                                                }} >
                                                    <strong>{capitalizeWords(service?.order_id)}</strong>
                                                    <span><CalendarMonthRounded sx={{ fontSize: '12px' }} /> {moment(service.order_date, 'YYYY-MM-DD').format('DD MMM YYYY')}</span>
                                                </Typography >
                                            </TableCell>
                                            <TableCell>
                                                <Typography sx={{
                                                    fontSize: '13px',
                                                    display: 'grid',
                                                    '& span': {
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '4px',
                                                        '& svg': {
                                                            color: theme.palette.text.disabled,
                                                        },
                                                        '& .limitedText': {
                                                            maxWidth: '150px',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                        },
                                                    },
                                                }}>
                                                    <span><strong>{camelCaseWords(service?.client_details?.regional_client_name)}</strong></span>
                                                    <span>
                                                        <span>
                                                            <AgricultureRounded sx={{ fontSize: '12px' }} />
                                                            <Tooltip title={`${camelCaseWords(service?.farmer_details?.farmer_name)}`} placement='top'>
                                                                <span className={'limitedText'}>{camelCaseWords(service?.farmer_details?.farmer_name)}</span>
                                                            </Tooltip>
                                                        </span>
                                                        -
                                                        <span> <PhoneRounded sx={{ fontSize: '12px' }} />{service?.farmer_details?.farmer_mobile_no}</span>
                                                    </span>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography sx={{
                                                    fontSize: '13px',
                                                    display: 'grid',
                                                    '& span': {
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '4px',
                                                        '& svg': {
                                                            color: theme.palette.text.disabled,
                                                        }
                                                    },
                                                }}>
                                                    <span><strong>{camelCaseWords(service?.farm_location?.field_area)}, {camelCaseWords(service?.farm_location?.village ?? '-')}</strong></span>
                                                    <span>
                                                        <span>
                                                            {service?.farm_location?.sub_district ?? '-'}
                                                        </span>
                                                        -
                                                        <span>
                                                            {service?.farm_location?.pin_code ?? '-'}
                                                        </span>
                                                    </span>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography sx={{
                                                    fontSize: '11px',
                                                    display: 'grid',
                                                    color: theme.palette.text.secondary,
                                                }}>
                                                    <span>Requested - <strong>{service?.requested_acreage}</strong></span>
                                                    <span>Sprayed &nbsp;&nbsp;&nbsp; - <strong>{service?.sprayed_acreage ?? 'n.a'}</strong></span>

                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography sx={{
                                                    fontSize: '13px',
                                                    display: 'grid',
                                                    '& span': {
                                                        fontSize: '11px',
                                                        fontWeight: 600,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '4px',
                                                        '& svg': {
                                                            color: theme.palette.text.disabled,
                                                        },
                                                    }
                                                }} >
                                                    <span style={{ color: theme.palette.info.main, }}>
                                                        Request - <CalendarMonthRounded sx={{ fontSize: '12px' }} />
                                                        {moment(service?.spray_date, 'YYYY-MM-DD').format('DD MMM YYYY')}
                                                    </span>

                                                    <span style={{ color: theme.palette.secondary.main, }}>
                                                        Delivery - {service?.delivery_date ? <><CalendarMonthRounded sx={{ fontSize: '12px' }} /> {moment(service?.delivery_date, 'YYYY-MM-DD').format('DD MMM YYYY')}</> : 'n.a'}
                                                    </span>

                                                </Typography >
                                            </TableCell>
                                            <TableCell>
                                                <Typography sx={{
                                                    fontSize: '11px',
                                                    display: 'grid',
                                                    color: theme.palette.text.secondary,
                                                    boxShadow: `0 0 12px -3px inset ${service?.payment_status == '5'
                                                        ? theme.palette.success.main
                                                        : service?.payment_status == '1' ? theme.palette.info.main
                                                            : theme.palette.warning.main
                                                        }`,
                                                    px: 1.5, py: 0.5, my: 0.5,
                                                    borderRadius: '8px',
                                                    width: '100%'
                                                }}>
                                                    <span>Discount - <span style={{ fontWeight: 600, color: theme.palette.error.main }}>
                                                        {currencyFormatter(service?.total_discount)}
                                                    </span>
                                                    </span>
                                                    <span>Total &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - <span style={{ fontWeight: 600, color: theme.palette.success.main }}>
                                                        {currencyFormatter(service?.total_amount)}
                                                    </span>
                                                    </span>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography sx={{
                                                    fontSize: '11px',
                                                    display: 'grid',
                                                    '& span': {
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '4px',
                                                        '& svg': {
                                                            color: theme.palette.text.disabled,
                                                        }
                                                    },
                                                }}>
                                                    <span><GrassRounded sx={{ fontSize: '12px' }} /> {camelCaseWords(service?.crop_name)}</span>
                                                    <span> <SensorOccupiedRounded sx={{ fontSize: '12px' }} />{service?.asset_operator?.name ?? 'na'}</span>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography sx={{
                                                    fontSize: '11px',
                                                    display: 'grid',
                                                    '& span': {
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '4px',
                                                        textTransform: 'uppercase',
                                                        '& svg': {
                                                            color: theme.palette.text.disabled,
                                                        }
                                                    },
                                                }}>
                                                    <span>{service?.provider_name ?? '-'}</span>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Tooltip title={`View Timeline`} arrow placement='top'>
                                                    <IconButton
                                                        onClick={() => {
                                                            setDialogFor('timeline')
                                                            setSelectedRequest({
                                                                id: `${service?.id}`,
                                                                orderId: `${service?.order_id}`,
                                                                name: `${service?.asset_operator?.name}`,
                                                                phone: service?.asset_operator?.phone,
                                                                asset: service?.asset?.asset_id,
                                                                droneAcreage: service?.drone_estimated_acreage,
                                                                payment_required: `${service?.payment_required}`,
                                                            })
                                                        }}
                                                    >
                                                        <Timeline />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={service?.order_status == '6' ? `Download Invoice` : ''} arrow placement='top'>
                                                    <IconButton
                                                        disabled={service?.order_status != '6' || !service?.invoice_no}
                                                        onClick={() => {
                                                            if (service?.order_status == '6') {
                                                                const link = document.createElement("a");
                                                                link.href = `${serverRouteWithoutApi}/zp/${btoa(service?.id)}`;
                                                                link.setAttribute("target", `_blank`);
                                                                link.setAttribute("download", `invoice-${service?.id}.pdf`);
                                                                document.body.appendChild(link);
                                                                link.click();
                                                                link.parentNode?.removeChild(link); // Clean up and remove the link
                                                                window.close()
                                                            }
                                                        }}
                                                    >
                                                        <DescriptionRounded />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Typography sx={{
                                                    fontSize: '13px',
                                                    textAlign: 'center',
                                                    color: service?.payment_type == '1' ? theme.palette.success.main : theme.palette.warning.main,
                                                }} >
                                                    {capitalizeWords(service?.payment_type == '1' ? 'Prepaid' : 'Postpaid')}
                                                </Typography >
                                            </TableCell>
                                            <TableCell className='stickyRight'>
                                                <Box sx={{
                                                    display: 'grid',
                                                    placeItems: 'center',
                                                    gap: 0.5,
                                                }}>
                                                    <Tooltip
                                                        arrow placement='top'
                                                        title={'Update Payment'}
                                                    >
                                                        <Box component={'span'}
                                                            onClick={() => navigate(`${service?.id}`)}
                                                            sx={{
                                                                fontSize: '10px',
                                                                cursor: 'pointer',

                                                            }}>{(service?.order_status == '1' && service?.payment_status == '1') ? `Update` : `View`}</Box>
                                                    </Tooltip>
                                                    <Tooltip
                                                        arrow placement='top'
                                                        title={service?.order_status == '0'
                                                            ? `${service?.cancel_remarks}`
                                                            : (service?.order_status == '1' && isRm) ? 'Change Status' : ''
                                                        }
                                                    >
                                                        <Chip
                                                            onClick={() => {
                                                                if (service?.order_status == '1' && isRm) {
                                                                    if (((service?.payment_status != '1' && service?.payment_status != '0') || service?.payment_type == '2') || service?.order_type == '4' || service?.order_type == '5') {
                                                                        setDialogFor('rmAction')
                                                                        setSelectedRequest({ id: `${service?.id}`, orderId: `${service?.order_id}`, provider_id: `${service?.provider_id}`, provider_name: `${service?.provider_name}` })
                                                                    } else {
                                                                        toast.error('Please update payment before assigning AO.')
                                                                    }
                                                                } else {
                                                                    toast.error(`You're not allowed to do so.`)
                                                                }
                                                            }}
                                                            size={'small'}
                                                            variant='outlined'
                                                            label={service?.order_status == '0' ? 'Cancelled'
                                                                : service?.order_status == '1' ? 'Created' :
                                                                    service?.order_status == '2' ? 'Assigned' :
                                                                        service?.order_status == '3' ? 'Accepted' :
                                                                            service?.order_status == '4' ? 'Started' :
                                                                                service?.order_status == '5' ? 'Complete' :
                                                                                    service?.order_status == '6' ? 'Delivered' :
                                                                                        'Canclled'
                                                            }
                                                            color={
                                                                service?.order_status == '0' ? 'error'
                                                                    : service?.order_status == '1' ? 'warning' :
                                                                        service?.order_status == '2' ? 'secondary' :
                                                                            service?.order_status == '3' ? 'info' :
                                                                                service?.order_status == '4' ? 'default' :
                                                                                    service?.order_status == '5' ? 'primary' :
                                                                                        service?.order_status == '6' ? 'success' :
                                                                                            'error'
                                                            }
                                                            sx={{
                                                                fontSize: '12px',
                                                                lineHeight: '12px',
                                                                p: '2px',
                                                                height: '18px',
                                                                minWidth: '72px',
                                                                textTransform: 'capitalize',
                                                                boxShadow: `0 0 12px -4px currentcolor inset`,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    : <TableRow>
                                        <TableCell colSpan={11} >
                                            <Box className={'flexCenter'} sx={{
                                                minHeight: { xs: '65vh', sm: '65vh' },
                                            }}>
                                                <NoDataView />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
                pagination={
                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[20, 50, 100, 200]}
                        count={+paginatedServices?.total}
                        page={query?.page}
                        onPageChange={(e, page) => setQuery({ ...query, page })}
                        rowsPerPage={query?.per_page}
                        onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                            setQuery({ ...query, per_page: parseInt(event.target.value, 10), page: 1 })
                        }
                    />
                }
            />

            {!!selectedRequest && dialogFor == 'timeline' &&
                <ThemeDialog
                    open={!!selectedRequest && dialogFor == 'timeline'}
                    title={'Request Timeline'}
                    onClickClose={() => setSelectedRequest(null)}
                    dialogBody={<ServiceTimeline requestDetails={selectedRequest} />} />
            }
            {!!selectedRequest && dialogFor == 'rmAction' &&
                <ThemeDialog
                    open={!!selectedRequest && dialogFor == 'rmAction'}
                    title={'Update Request'}
                    onClickClose={() => setSelectedRequest(null)}
                    dialogBody={
                        <RmActionForm
                            fetchServices={fetchPaginatedServices}
                            setSelectedRequest={setSelectedRequest}
                            selectedRequest={selectedRequest}
                            operators={operators}
                            providers={providers}
                        />
                    } />
            }
        </>
    )
}

const ThemeTable = (props: any) => {

    const { header, table, pagination } = props

    return (
        <Box sx={{
            height: '100%',
            display: 'flex',
            flexFlow: 'column',
        }}>
            {header}
            <ThemeTableContainer sx={{ flex: 1 }}>
                {table}
            </ThemeTableContainer>
            {pagination}
        </Box>
    )
}

