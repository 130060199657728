import { Box, IconButton, Typography, alpha, useTheme } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import demoImage from '../../../assets/images/illustartions/dragAndDrop.png'
import demoImage1 from '../../../assets/images/illustartions/farmer.png'
import pdfIcon from '../../../assets/images/illustartions/pdf-icon.svg'
import excelIcon from '../../../assets/images/illustartions/excel-sheet.svg'
import { CloseRounded, OpenInNewRounded } from '@mui/icons-material';

interface FileUploadInputProps {
    label?: string
    accept?: string
    errorMessage?: string
    reactHookProps?: any
    onClear?: () => void
    inputBlock?: ReactElement
    defaultImage?: string | null
    aspectRatio?: string
    cameraOnly?: boolean
}

const FileUploadInput = (props: FileUploadInputProps) => {

    const { label, accept, onClear, errorMessage, reactHookProps, inputBlock, defaultImage, cameraOnly = false, aspectRatio } = props

    const theme = useTheme()

    const [imagePreview, setImagePreview] = useState<string>(defaultImage ?? demoImage);
    const [doc, setDoc] = useState<File | null>(null);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];

        if (file) {
            // Read the selected file and set the image preview
            setDoc(file)
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        } else {
            // Clear the image preview if no file is selected
            setImagePreview(defaultImage ?? demoImage)
            setDoc(null)
        }
    };

    const onClearClick = () => {
        setDoc(null)
        setImagePreview(defaultImage ?? demoImage)
        onClear && onClear()
    }

    return (
        <Box
            sx={{
                flex: 1,
                maxHeight: '180px',
                // minWidth: '260px',
                aspectRatio: aspectRatio ? aspectRatio : '1.5/1',
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'stretch',
                padding: '0.5rem 0',
                borderRadius: '18px',
                background: theme.palette.mode == 'dark'
                    ? '#0e0e0e'
                    : `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.07)}, ${theme.palette.background.paper})`,
                boxShadow: theme.palette.mode == 'dark' ? 'none' : `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                border: `2px solid ${theme.palette.background.paper}`,
                position: 'relative',
                '& .imageBlock': {
                    flex: 1,
                    borderRadius: '10px',
                    display: 'grid',
                    placeItems: 'center',
                    cursor: 'pointer',
                    maxHeight: '90%',
                    mx: 0.4,
                    my: 1,
                    transition: 'all 200 ms ease-in-out',
                    position: 'relative',
                    outline: errorMessage && !doc?.name ? `1px dotted ${theme.palette.error.main}` : '0 dotted #838383',
                    '& input.fileInput': {
                        borderRadius: 'inherit',
                        position: 'absolute',
                        zIndex: 1,
                        inset: 0,
                        height: '100%',
                        width: '100%',
                        opacity: 0,
                        cursor: 'pointer',
                    },
                    '& img': {
                        maxWidth: '90%',
                        height: doc?.type?.includes('pdf') || doc?.type?.includes('sheet') ? '80px' : '150px',
                        objectFit: 'contain',
                        mx: 'auto',
                    },
                    '& .actionIcons': {
                        position: 'absolute',
                        height: '22px',
                        width: '22px',
                        p: '10px',
                        zIndex: 2,
                        top: '0.5rem',

                        '& svg': {
                            fontSize: '18px',
                        },
                        '&.close': {
                            right: '0.5rem',
                            color: theme.palette.error.main,
                        },
                        '&.newWindow': {
                            left: '0.5rem',
                            color: theme.palette.info.main,

                        },
                    },

                    '& .fileName': {
                        fontSize: '12px',
                        lineHeight: '12px',
                        maxWidth: '180px',
                        background: alpha(theme.palette.primary.main, 0.6),
                        color: theme.palette.primary.contrastText,
                        px: 1,
                        borderRadius: '4px',
                        position: 'absolute',
                        bottom: '4px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        textAlign: 'center',
                        '&.error': {
                            background: alpha(theme.palette.error.main, 0.2),
                            color: theme.palette.error.main,
                        },
                    },
                },
                '&:has(input:hover)': {
                    cursor: 'pointer',
                    '& .imageBlock': {
                        outline: `1px dotted #838383`,
                    },
                },
            }}>

            {label &&
                <Typography sx={{
                    fontSize: '10px',
                    lineHeight: '12px',
                    mb: 0,
                    fontFamily: theme.typography.h1.fontFamily,
                    fontWeight: 500,
                    position: 'absolute',
                    top: '-0.5rem',
                    left: '1rem',
                    background: theme.palette.background.default,
                    boxShadow: `0 0 12px -3px currentcolor inset`,
                    color: errorMessage && !doc?.name ? theme.palette.error.main : theme.palette.primary.main,
                    p: '2px 8px',
                    borderRadius: '50vh',
                }}>
                    {label}
                </Typography>
            }
            {inputBlock}

            <Box className={'imageBlock'}>
                {doc?.name &&
                    <>
                        <IconButton className={'clearFile actionIcons close animate__animated animate__fadeIn'} onClick={onClearClick}><CloseRounded /></IconButton>
                        {/* <IconButton className={'actionIcons newWindow animate__animated animate__fadeIn'}>
                        <OpenInNewRounded />
                    </IconButton> */}
                    </>
                }
                <input className="fileInput" capture={cameraOnly ? 'user' : undefined} type="file" accept={accept ?? ''} {...reactHookProps} onChange={handleImageChange} />

                <img src={doc?.type?.includes('pdf') ? pdfIcon : ((doc?.type?.includes('sheet') || doc?.type?.includes('csv')) ? excelIcon : imagePreview)} alt="Preview" style={{ maxWidth: '100%' }} loading='lazy' />
                {errorMessage && !doc?.name
                    ? <Typography className={'fileName error animate__animated animate__fadeIn'}>{errorMessage}</Typography>
                    : <Typography className={'fileName animate__animated animate__fadeIn'}>{doc?.name}</Typography>
                }
            </Box>
        </Box>
    );
};

export default FileUploadInput;
