import React from 'react'
import { alpha, lighten, styled, TextField, Theme } from "@mui/material"


export const ThemeTextField = styled(TextField)(({ theme }) => ({
    minHeight: '64px',
    width: '100%',
    margin: '0 !important',
    '& label': {
        top: '-2px',
        '&.Mui-focused': {
            top: 0,
            ...(theme.palette.mode == 'dark'
                ? { color: lighten(theme.palette.primary.light, 0.4) }
                : {})
        },
        '&.Mui-error': {
            color: theme.palette.error.main,
        },
    },
    '& .MuiFormHelperText-root': {
        marginTop: '-2px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: theme.typography.pxToRem(12),
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '16px',
        borderWidth: '1px',
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '2px',
            borderColor: `${alpha(theme.palette.primary.main, 0.5)} !important`,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#b7c2ce !important',
        },
        '& input': {
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 500,
            '&[type=number]': {
                MozAppearance: 'textfield',
                '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                },
            },
            '&:focus:valid': {
                color: theme.palette.success.main,
            },
            '&:focus:invalid': {
                color: theme.palette.warning.main,
            },
            '&:focus:valid + fieldset': {
                // borderWidth: '1px',
                borderRadius: '16px',
            },
            '&:invalid + fieldset': {
                // borderWidth: '1px',
                borderRadius: '16px',
            },
            '&:-webkit-autofill': {
                WebkitTextFillColor: theme.palette.text.primary,
                WebkitBoxShadow: `0 0 0px 1000px ${theme.palette.background.default} inset`,
            },
        },
        '& fieldset': {
            borderWidth: '1px',
            borderColor: theme.palette.action.hover,
            borderRadius: '16px',
            '&:hover': {
                borderWidth: '1px',
                // borderColor: '#83838350',
                borderRadius: '16px',
            }
        },
        '&.Mui-disabled fieldset': {
            background: alpha(theme.palette.text.disabled, 0.1),
        },
        '&.Mui-focused fieldset': {
            borderWidth: '2px',
            // background: theme.palette.primary.main + '07',
        },
        '&.Mui-error fieldset': {
            borderWidth: '1px',
            borderRadius: '16px',
            borderColor: `${theme.palette.error.main} !important`,
        },
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
        WebkitTextFillColor: theme.palette.text.secondary,
    },
    '& .MuiSelect-select': {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 500,
        paddingBlock: '7px !important',
    },
    '&:has(input[type="file"])': {
        '& .Mui-focused fieldset': {
            background: 'transparent !important',
        },
        "& .MuiOutlinedInput-root *:not(svg)": {
        },
    },
    '&:has(svg, input[type="file"])': {
        '& .MuiOutlinedInput-root *:not(svg)': {
            borderWidth: '1px',
        },
        '&.Mui-focused .MuiOutlinedInput-root': {
            borderWidth: '2px',
        },
    },
    '& .MuiOutlinedInput-root input::-webkit-file-upload-button': {
        fontSize: '16px',
        borderRadius: '20px',
        background: alpha(theme.palette.primary.main, 1),
        border: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.contrastText,
    }
}))


export const themeTextFieldSx = (theme: Theme) => ({
    flex: 1,
    margin: '0 !important',
    '& label': {
        top: '-2px',
        '&.Mui-focused': {
            top: 0,
            ...(theme.palette.mode == 'dark'
                ? { color: lighten(theme.palette.primary.light, 0.4) }
                : {})
        },
        '&.Mui-error': {
            color: theme.palette.error.main,
        },
    },
    '& .MuiFormHelperText-root': {
        marginTop: 0,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '16px',
        borderWidth: '1px',
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '2px',
            borderColor: `${alpha(theme.palette.primary.main, 0.5)} !important`,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#b7c2ce !important',
        },
        '& input': {
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 500,
            '&[type=number]': {
                MozAppearance: 'textfield',
                '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                },
            },
            '&:focus:valid': {
                color: theme.palette.success.main,
            },
            '&:focus:invalid': {
                color: theme.palette.warning.main,
            },
            '&:focus:valid + fieldset': {
                // borderWidth: '1px',
                borderRadius: '16px',
            },
            '&:invalid + fieldset': {
                // borderWidth: '1px',
                borderRadius: '16px',
            },
            '&:-webkit-autofill': {
                WebkitTextFillColor: theme.palette.text.primary,
                WebkitBoxShadow: `0 0 0px 1000px ${theme.palette.background.default} inset`,
            },
        },
        '& fieldset': {
            borderWidth: '1px',
            borderColor: theme.palette.action.hover,
            borderRadius: '16px',
            '&:hover': {
                borderWidth: '1px',
                // borderColor: '#83838350',
                borderRadius: '16px',
            }
        },
        '&.Mui-disabled fieldset': {
            background: alpha(theme.palette.text.disabled, 0.1),
        },
        '&.Mui-focused fieldset': {
            borderWidth: '2px',
            // background: theme.palette.primary.main + '07',
        },
        '&.Mui-error fieldset': {
            borderWidth: '1px',
            borderRadius: '16px',
            borderColor: `${theme.palette.error.main} !important`,
        },
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
        WebkitTextFillColor: theme.palette.text.secondary,
    },
    '& .MuiSelect-select': {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 500,
    },
    '&:has(input[type="file"])': {
        '& .Mui-focused fieldset': {
            background: 'transparent !important',
        },
        "& .MuiOutlinedInput-root *:not(svg)": {
        },
    },
    '&:has(svg, input[type="file"])': {
        '& .MuiOutlinedInput-root *:not(svg)': {
            borderWidth: '1px',
        },
        '&.Mui-focused .MuiOutlinedInput-root': {
            borderWidth: '2px',
        },
    },
    '& .MuiOutlinedInput-root input::-webkit-file-upload-button': {
        fontSize: '16px',
        borderRadius: '20px',
        background: alpha(theme.palette.primary.main, 1),
        border: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.contrastText,
    }
})