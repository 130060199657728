import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Operator } from "../models/operator";

export interface OperatorDetailsInterface {
  selectedOperator: Operator;
}

const initialState: OperatorDetailsInterface = {
  selectedOperator: {} as Operator,
};

export const operatorDetailSlice = createSlice({
  name: "operatorDetail",
  initialState,
  reducers: {
    updateOperatorDetail: (
      state,
      action: PayloadAction<OperatorDetailsInterface>
    ) => {
      state.selectedOperator = action.payload?.selectedOperator;
    },
  },
});

export const { updateOperatorDetail } = operatorDetailSlice.actions;

export default operatorDetailSlice.reducer;
