import React, { useEffect, useState } from 'react'
import { alpha, Box, Button, Divider, Typography, useMediaQuery, useTheme } from "@mui/material"
import logo from "../../assets/images/app-icons/agri-wing-logo.svg"
import Login from "./login"
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { getCookieValue } from "../../utils/app-helper";
import moment from "moment";
import AuthIllustrator from "./auth-illustrator";
import { colors } from '../../utils/colors';


export default function AuthLayout() {

    const theme = useTheme()
    const location = useLocation()
    const navigate = useNavigate()
    const revertScreen = useMediaQuery('(max-width:900px)')

    const fromLocation = location?.state?.fromLocation
    const fromPath = fromLocation?.pathname || '/'

    const accessToken = getCookieValue('accessToken')

    useEffect(() => {
        if (accessToken) navigate('/')
        // if (accessToken) navigate(fromPath, { state: fromLocation?.state, replace: true })
    }, [accessToken])

    return (
        <Box sx={{
            minHeight: { xs: 'auto', sm: '100vh' },
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                width: { xs: '100%', sm: '90%' },
                maxWidth: '1200px',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                minHeight: { xs: 'calc(100vh - 60px)', sm: 'min(90vh, 600px)' },
                gap: 2,
                '& .ctaText': {
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '0.8rem',
                    '& span': {
                        fontSize: '1.5rem',
                        fontWeight: 600,
                    }
                },
                '& .innerBox': {
                    minWidth: revertScreen ? '100%' : 'auto',
                },
            }}>

                <Box className={'innerBox'} sx={{
                    flex: '2 1 300px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& svg': {
                        maxWidth: '500px',
                        width: { xs: 'min(65%, 272px)', sm: '80%', md: '95%' },
                        my: revertScreen ? 4 : 0
                    },
                }}>
                    <AuthIllustrator />
                    {/*<img className={'animate__animated animate__zoomIn'} src={sideImage} alt={'scheduler'}*/}
                    {/*     aria-label={'scheduler logo'}/>*/}
                </Box>

                <Box className={'innerBox animate__animated animate__fadeIn'} sx={{
                    flex: '1 1 300px',
                }}>
                    <Box component={'form'}
                        sx={{
                            flex: 1,
                            width: { xs: '90%', sm: '100%' },
                            mx: 'auto',
                            display: 'flex',
                            flexFlow: 'column',
                            '& .formBlock': {
                                width: '100%',
                                maxWidth: { xs: '400px', sm: '320px' },
                                flex: 1,
                                mx: 'auto',
                                display: 'flex',
                                flexFlow: 'column',
                                paddingTop: '2rem',
                                '& .formContainer': {
                                    flex: 1,
                                    minHeight: '240px',
                                    display: 'flex',
                                    flexFlow: 'column',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    paddingBottom: '2rem'
                                },
                                '& .MuiButton-root': {
                                    borderRadius: '12px',
                                    padding: '0.5rem 1rem',
                                    width: '100%',
                                },
                                '& .MuiLoadingButton-root': {
                                    backgroundImage: `linear-gradient(135deg, ${colors.primary}, ${colors.secondary})`,
                                    outline: `0px solid ${theme.palette.primary.main}`,
                                    outlineOffset: 0,
                                    boxShadow: 0,
                                    transition: 'all 100ms ease',
                                    '&:hover': {
                                        outline: `2px solid ${theme.palette.primary.main}`,
                                        outlineOffset: '2px',
                                        '&:active': {
                                            transform: 'scale(0.98) !important'
                                        }
                                    },

                                },
                            },
                            '& .smallCtaButton': {
                                fontSize: '12px',
                                boxShadow: `0 0 10px -4px ${theme.palette.text.secondary} inset`,
                                borderRadius: '8px',
                                padding: '2px 16px',
                                marginLeft: '1rem',
                                color: theme.palette.text.primary,
                                textTransform: 'none',
                                letterSpacing: '1px'
                            },
                            '& .MuiDivider-root': {
                                borderColor: alpha(theme.palette.text.secondary, 0.2),
                                '&:before, &:after': {
                                    borderTop: `thin solid ${alpha(theme.palette.text.secondary, 0.2)}`,
                                    top: 0,
                                }
                            },
                            '& .logoImage': {
                                maxHeight: { xs: '60px', sm: '100px' },
                                objectFit: 'contain',
                            },
                        }}>

                        <img className={'logoImage animate__animated animate__fadeInUp'} src={logo} alt={'hey-there-logo'} />

                        {/*<Typography className={'ctaText animate__animated animate__fadeInUp'}*/}
                        {/*sx={{*/}
                        {/*    '& span':{*/}
                        {/*        background: `-webkit-linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,*/}
                        {/*        WebkitBackgroundClip: 'text',*/}
                        {/*        WebkitTextFillColor: 'transparent',*/}
                        {/*    },*/}
                        {/*}}>*/}
                        {/*    <span>Hey! there</span><br/> Login to view your schedule*/}
                        {/*</Typography>*/}

                        <Login />

                    </Box>
                </Box>

                {/*<Box className={'animate__animated animate__fadeInUp'} sx={{*/}
                {/*    textAlign: 'center',*/}
                {/*    minWidth: '100%',*/}
                {/*    '& .smallCtaButton': {*/}
                {/*        fontSize: '12px',*/}
                {/*        boxShadow: `0 0 10px -4px ${theme.palette.text.secondary} inset`,*/}
                {/*        borderRadius: '8px',*/}
                {/*        padding: '2px 16px',*/}
                {/*        marginLeft: '1rem',*/}
                {/*        color: theme.palette.text.primary,*/}
                {/*        textTransform: 'none',*/}
                {/*        letterSpacing: '1px'*/}
                {/*    },*/}

                {/*}}>*/}
                {/*    <Divider sx={{mb: 1}}/>*/}
                {/*    <Typography variant={'caption'} sx={{textAlign: 'center'}}>*/}
                {/*        © {moment().format('YYYY')} ETERNITY, All rights reserved. Coded with ❤️*/}
                {/*    </Typography>*/}
                {/*<Typography variant={'caption'} sx={{textAlign: 'center'}}>*/}
                {/*    Don't have an account*/}
                {/*    <Button className={'smallCtaButton'} size={'small'}*/}
                {/*            onClick={() => setLoginForm(false)}>Register</Button>*/}
                {/*</Typography>*/}
                {/*</Box>*/}
            </Box>
        </Box>
    )
}