import { useCallback, useState } from "react";
import API from "../api";

export function useChemicals() {
  const [chemicals, setChemicals] = useState<any[]>([]);
  const [fetchingChemicals, setFetchingChemicals] = useState(false);

  const fetchChemicals = useCallback(async () => {
    try {
      setFetchingChemicals(true);
      const response = await API.get("/get_chemical_list", {
        headers: { Accept: "application/json" },
      });

      if (response.data?.statuscode === "200") {
        setChemicals(
          [
            ...response.data?.data,
            ...[{ id: "other", chemical_name: "Others" }],
          ] || []
        );
      } else {
        console.error(
          "Failed to fetch chemicals:",
          response.data?.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching chemicals:", error);
    } finally {
      setFetchingChemicals(false);
    }
  }, []);

  return {
    fetchChemicals,
    chemicals,
    fetchingChemicals,
  };
}
