import { Box, IconButton, alpha, useMediaQuery, useTheme } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react'
import { ThemeDataTable } from '../../components/theme-data-table-container';
import TableLoading from '../../components/theme-table-container/TableLoading';
import NoDataView from '../../components/noDataView';
import TitleBar from '../../components/container/TitleBar';
import PageContainer from '../../components/container/page-container';
import API from '../../api';
import { camelCaseWords, capitalizeWords, currencyFormatter, s3BucketUrl } from '../../utils/app-helper';
import { Sync } from '@mui/icons-material';
import { useDocumentTitle } from '../../hooks/useDocumnetTitle';
import { AttachmentIcon } from '../../components/icons';

export default function AllTransactionsReport() {

    useDocumentTitle('All Transactions')

    const theme = useTheme()
    const [transaction, setTransaction] = useState<any[]>([])
    const [fetching, setFetching] = useState<boolean>(true)

    const fetchTransactions = useCallback(() => {
        setFetching(true)
        API.get('download_transaction_report')
            .then((res) => {
                setTransaction(res.data?.data)
            })
            .catch((err) => console.error(err))
            .finally(() => setFetching(false))
    }, [])

    useEffect(() => {
        fetchTransactions()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'All Transactions Report'} secondChild={
                <IconButton
                    onClick={fetchTransactions}
                    size='small'
                    sx={{
                        mr: 2,
                        background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                        boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                        border: `1px solid ${theme.palette.background.paper}`,
                    }}>
                    <Sync />
                </IconButton>
            } />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    '& .iconButton': {
                        height: '24px',
                        width: '24px',
                        borderRadius: '50vh',
                        // boxShadow: `0 0 12px -4px ${theme.palette.info.main} inset`,
                        display: 'grid',
                        placeItems: 'center',
                        color: theme.palette.info.main,
                        '& svg': {
                            height: '16px',
                            width: '16px',

                        },

                    },
                }}>

                <AllTransactionsTable data={transaction} fetching={fetching} />
            </Box>
        </PageContainer>
    )
}

const AllTransactionsTable = (props: any) => {

    const { data, fetching } = props
    const theme = useTheme()

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', flex: 1, minWidth: 80 },
        {
            field: 's_no', headerName: 'Order Number', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value?.toUpperCase() : '-'
        },
        {
            field: 'client_name', headerName: 'Client Name', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value?.toUpperCase() : '-'
        },
        {
            field: 'payment_mode', headerName: 'Payment Mode', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value?.toUpperCase() : '-'
        },
        {
            field: 'payment_type', headerName: 'Order Payment Type', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value?.toUpperCase() : '-'
        },
        {
            field: 'payment_status', headerName: 'Payment Status', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ?
                value?.toUpperCase() == 'PREPAID_PAYMENT_ONLINE' ? 'PARTIALLY ONLINE'
                    : value?.toUpperCase() == 'PREPAID_PAYMENT_OFFLINE' ? 'PARTIALLY OFFLINE'
                        : value?.toUpperCase()
                : '-'
        },
        {
            field: 'received_amount', headerName: 'Received Amount', flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams) => currencyFormatter(params.value)
        },
        {
            field: 'reference_no', headerName: 'Reference No', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value?.toUpperCase() : '-'
        },
        {
            field: 'base_price', headerName: 'Base Price', flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams) => currencyFormatter(params.value)
        },
        { field: 'actual_acreage', headerName: 'Actual Acreage', flex: 1, minWidth: 160 },
        { field: 'general_discount', headerName: 'General Discount', flex: 1, minWidth: 160 },
        { field: 'subvention_discount', headerName: 'Subvention Discount', flex: 1, minWidth: 160 },
        { field: 'client_discount', headerName: 'Client Discount', flex: 1, minWidth: 160 },
        { field: 'extra_discount', headerName: 'Extra Discount', flex: 1, minWidth: 160 },
    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetching}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}


