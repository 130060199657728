import { alpha, Box, Typography, useTheme } from "@mui/material"
import moment from "moment"
import { ClockIcon, CommentIcon } from "../../../../components/icons"

const SingleAttendance = (props: any) => {

    const { data } = props

    const date = data?.date
    const details = data?.data

    const theme = useTheme()

    const color = moment(date, 'DD-MM-YYYY').get('day') == 0 && !details ? theme.palette.text.disabled
        : (details?.on_leave == '0' && details?.attendance_status != '0') ? theme.palette.success.main
            : (details?.on_leave == '1' && details?.attendance_status != '0') ? theme.palette.warning.main :
                theme.palette.error.main

    const status = moment(date, 'DD-MM-YYYY').get('day') == 0 && !details ? 'Week Off'
        : (details?.on_leave == '0' && details?.attendance_status != '0') ? 'Present'
            : (details?.on_leave == '1' && details?.attendance_status != '0') ? 'Leave' :
                'Absent'


    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                flexWrap: 'wrap',
                borderLeft: `4px solid ${color}`,
                borderRadius: '12px',
                p: 0.5,
                boxShadow: '0 0 12px -3px #83838380',
                mb: 1,
                minHeight: '44px',
                '&:last-of-type': {
                    mb: 0,
                },
                '& .des': {
                    color: theme.palette.text.secondary,
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    fontSize: theme.typography.pxToRem(12),
                    '& svg': {
                        color: theme.palette.text.disabled,
                        height: '14px',
                        width: '14px',
                        mr: '6px',
                        flex: '0 0 14px'
                    },
                }
            }}>
            <Box
                className={'flexCenter'}
                sx={{
                    background: alpha(color, 0.1),
                    color: color,
                    borderRadius: '8px',
                    gap: 0.5,
                    width: '56px',
                    px: 1,
                    alignSelf: 'stretch',

                    '& .day': {
                        fontSize: theme.typography.pxToRem(10),
                        lineHeight: theme.typography.pxToRem(5),
                        fontWeight: 600,
                    },
                    '& .date': {
                        fontSize: theme.typography.pxToRem(20),
                        lineHeight: theme.typography.pxToRem(20),
                        fontWeight: 600,

                    }
                }}
            >
                <Typography className={'day'}>{moment(date, 'DD-MM-YYYY').format('ddd')}</Typography>
                <Typography className={'date'}>{moment(date, 'DD-MM-YYYY').format('DD')}</Typography>
            </Box>

            <Typography
                className='des'
                sx={{ flex: '1 1 120px', }}>
                {(details?.on_leave == '0' && details?.attendance_status != '0')
                    ? <>
                        <span className={'flexTopCenter'}>
                            <ClockIcon />
                            <strong>{details?.in ? moment(details?.in, 'HH:mm;ss').format('HH:mm A') : '-'} - {details?.out ? moment(details?.out, 'HH:mm;ss').format('HH:mm A') : '-'}</strong>
                        </span>
                        <span className={'flexTopCenter'}>
                            <CommentIcon /> <span className={'line-clamp clamp-1'}>{details?.remarks ?? '-'}</span>
                        </span>
                    </>
                    : <>
                        <span style={{ color: color }}><strong>{status}</strong></span>
                        <span className={'flexTopCenter'}>
                            <CommentIcon /> <span className={'line-clamp clamp-1'}>{details?.user_remarks ? details?.user_remarks : (details?.remarks ?? '-')}</span>
                        </span>
                    </>}

            </Typography>
            <Typography
                className='des'
                sx={{
                    flex: '1 1 100px',
                }}>
                {details?.on_leave == '0' && details?.attendance_status != 0
                    ? <>
                        <span style={{ color: details?.out_of_station == '1' ? theme.palette.success.main : theme.palette.error.main, }}>Out of Station</span>
                        <span style={{ color: details?.field_activity == '1' ? theme.palette.success.main : theme.palette.error.main, }}>Field Activity</span>
                    </>
                    : '-'
                }
            </Typography>
        </Box>
    )
}

export default SingleAttendance

