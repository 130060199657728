import { useCallback, useState } from "react";
import API from "../api";

export function useRegionalClients() {
  const [regionalClients, setRegionalClients] = useState<any[]>([]);
  const [fetchingRegionalClients, setFetchingRegionalClients] = useState(false);

  const fetchRegionalClients = useCallback(async () => {
    try {
      setFetchingRegionalClients(true);
      const response = await API.get("/get_all_clients_list", {
        headers: { Accept: "application/json" },
      });

      if (response.data?.statuscode === "200") {
        setRegionalClients(response.data?.data || []);
      } else {
        console.error(
          "Failed to fetch Clients:",
          response.data?.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching Clients:", error);
    } finally {
      setFetchingRegionalClients(false);
    }
  }, []);

  return {
    fetchRegionalClients,
    regionalClients,
    fetchingRegionalClients,
  };
}
