import { Autocomplete, Box, Button, IconButton, MenuItem, TextField, Typography, alpha, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import API from '../../api'
import toast from 'react-hot-toast'
import { RootState } from '../../store/store'
import operatorIllustration from '../../assets/images/illustartions/support.png'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { ThemeTextField } from '../../components/inputs/theme-text-field'
import { DeleteOutlineRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { updateProcessState } from '../../slices/process-slice'




interface ClusterFormProps {
    closeDialog: () => void;
    fetchClusters: () => void;
}

const ClusterForm: React.FC<ClusterFormProps> = ({ closeDialog, fetchClusters }) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const selectedCluster: any = useSelector((state: RootState) => state.clusterDetails.selectedCluster);
    const [submitting, setSubmitting] = useState(false);

    const [clientStatus, setClientStatus] = useState(selectedCluster?.status == '0' ? 'inactive' : 'active');

    const [searchedSubDistrict, setSearchedSubDistrict] = useState('');
    const [subDistricts, setSubDistricts] = useState<any[]>([]);
    const [fetchingSubDistricts, setFetchingSubDistricts] = useState(false);

    const { control, handleSubmit, setValue, watch, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: selectedCluster?.id ? selectedCluster?.cluster_name : '',
            area: selectedCluster?.id ? (JSON.parse(selectedCluster?.area_details) ?? []).map((a: any, i: number) => ({
                area: a
            })) : [{ area: { district: "", state: "", sub_district: "" } }]
        },
    });
    const inputData = watch();
    const { fields, remove, append } = useFieldArray({ name: "area", control });

    const appendAddressRows = () => append({ district: "", state: "", sub_district: "" });

    const fetchSubDistrict = useCallback((village_name: string) => {
        setFetchingSubDistricts(true)
        API.get(`/fetch-villages`, {
            params: {
                state_name: '',
                district_name: '',
                subdistrict_name: village_name,
                village_name: '',
            }
        }).then((res) => {
            if (res.data?.statuscode == '200') {
                setSubDistricts(res.data?.village_data?.map((item: any) => ({
                    district: item?.district_name,
                    state: item?.state_name,
                    sub_district: item?.subdistrict_name,
                })).filter((el: any, index: number, array: any) =>
                    index ===
                    array.findIndex(
                        (item: any) =>
                            item.district === el.district &&
                            item.state === el.state &&
                            item.sub_district === el.sub_district
                    )
                )
                    ?? [])
            } else {
                setSubDistricts([])
            }
        })
            .catch((err) => { toast.success("Oops! Something bad at our end") })
            .finally(() => {
                setFetchingSubDistricts(false)
            })
    }, [searchedSubDistrict]);

    useEffect(() => {
        searchedSubDistrict.length == 3 && fetchSubDistrict(searchedSubDistrict);
    }, [searchedSubDistrict]);

    const onSubmit = (data: any) => {
        console.log('data', data)
        const modifiedArea = data?.area?.map((item: any, index: number) => ({
            district: item?.area?.district,
            state: item?.area?.state,
            sub_district: item?.area?.sub_district,
        }))
        const newData = {
            name: data?.name,
            area: JSON.stringify(modifiedArea),
            ...(selectedCluster?.id && { id: `${selectedCluster?.id}` })
        }
        console.log('newData', newData)

        dispatch(updateProcessState(true))
        setSubmitting(true)

        const url = !selectedCluster?.id ? '/submit_clusters' : '/update_cluster'

        API.post(url, { ...newData })
            .then((res) => {
                if (res.data?.statuscode == '200') {
                    toast.success(`Cluster ${selectedCluster?.id ? 'updated' : 'created'} successfully!`)
                    reset()
                    closeDialog()
                    fetchClusters()
                }
                if (res.data?.status == 'error') {
                    toast.error(`Error! \n ${res.data?.msg}`)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setSubmitting(false)
                dispatch(updateProcessState(false))
            })
    }

    return (
        <Box
            sx={{
                minWidth: '280px',
                width: { xs: '100%', md: '70vw' },
                maxWidth: '650px',
                minHeight: '200px',
                '& .boxTitle': {
                    fontWeight: 500,
                    fontSize: '18px',
                    mb: 3,
                },
                '& .formInput': {
                    flex: '1 1 260px',
                },
            }}>

            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 3,
                        mx: { xs: 0, sm: 3, md: 5 },
                    }}>

                    <Box
                        sx={{
                            flex: '1 1 300px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                            '& img': {
                                objectFit: 'contain',
                                maxHeight: '120px',
                                maxWidth: '90%',
                            },
                            '& .selectedClusterDetail': {
                                flex: '1 1 200px',
                                display: 'flex',
                                flexFlow: 'column',
                                alignItems: 'flex-end',
                                justifyContent: 'center',
                                '& .detail': {
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: theme.typography.h1.fontFamily,
                                    color: theme.palette.text.secondary,
                                },

                            },
                        }}>
                        <img src={operatorIllustration} alt={'a'} />
                    </Box>
                    <Box
                        sx={{
                            flex: '1 1 300px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            columnGap: 3,
                            '& .farmAddresses': {
                                minWidth: '100%',
                            },
                        }}>
                        <Controller name={`name`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.name)}
                                    helperText={(errors?.name?.message ?? '').toString()}
                                    size={'small'} label={'Cluster Name'}
                                    placeholder={'eg. Cluster 1'}
                                />
                            )} />

                        <Box className={'farmAddresses'} sx={{
                            '& .formInputGroup': {
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                gap: 1,
                                '& .farmsInputArea': {
                                    flex: 1,
                                },
                            },
                        }}>
                            {fields.map((field: any, index: number) => (
                                <Box className={'formInputGroup'} key={field.id}>
                                    <Box className={'farmsInputArea'}>
                                        <Controller name={`area.${index}.area`}
                                            control={control}
                                            rules={{ required: { value: true, message: 'Required' } }}
                                            render={({ field: { onChange, value } }) => (
                                                <Autocomplete size={'small'}
                                                    autoComplete
                                                    className={'formItem'}
                                                    onClose={() => {
                                                        setSearchedSubDistrict('')
                                                        setSubDistricts([])
                                                    }}
                                                    onInputChange={(e, inputvalue) => {
                                                        setSearchedSubDistrict(inputvalue)
                                                        inputvalue?.length <= 3 && setSubDistricts([])
                                                    }}
                                                    onChange={(e, data) => { onChange(data) }}
                                                    noOptionsText={searchedSubDistrict.length < 3 ? 'Min 3 characters required to search' : 'No district Found'}
                                                    value={value || null}
                                                    loading={fetchingSubDistricts}
                                                    sx={{ flex: '1 1 250px' }}
                                                    options={subDistricts}
                                                    renderOption={(props, option, { selected }) => (
                                                        <Box component={'li'} {...props} key={props.id}
                                                            sx={{
                                                                p: '2px 6px',
                                                                display: 'flex',
                                                                alignItems: 'flex-start',
                                                                justifyContent: 'flex-start',
                                                                flexFlow: 'column',
                                                                textAlign: 'left',
                                                                gap: '2px',
                                                                borderRadius: '12px',
                                                                mx: '2px',
                                                                '& .village': {
                                                                    width: '100%',
                                                                    fontSize: '15px',
                                                                    lineHeight: '15px',
                                                                    fontWeight: 500,
                                                                    color: theme.palette.text.secondary,
                                                                },
                                                                '& .state': {
                                                                    width: '100%',
                                                                    fontSize: '12px',
                                                                    lineHeight: '12px',
                                                                    fontWeight: 500,
                                                                    color: theme.palette.text.disabled,
                                                                },
                                                            }}>
                                                            <Typography className={'village'}>
                                                                {option?.sub_district}
                                                            </Typography>
                                                            <Typography className={'state'}>
                                                                {option?.district}, {option?.state}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                    getOptionLabel={(option) => option.sub_district ?? ''}
                                                    renderInput={(params) => (
                                                        <ThemeTextField
                                                            {...params} required
                                                            size={'small'} label={`Sub District - ${index + 1} `}
                                                            placeholder={'select subdistrict'}
                                                        />
                                                    )} />
                                            )} />
                                    </Box>
                                    <Box className={'groupTop'}>
                                        <Box className={'actionBlock'}>
                                            {fields.length > 1 &&
                                                <IconButton
                                                    size={'small'}
                                                    color={'error'}
                                                    onClick={() => remove(index)}
                                                >
                                                    <DeleteOutlineRounded />
                                                </IconButton>
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </Box>

                        <Button
                            size={'small'}
                            onClick={appendAddressRows}
                            sx={{
                                mx: 'auto',
                                order: { xs: 5, sm: 5 },
                            }}>
                            Add Sub-district
                        </Button>

                    </Box>
                </Box>
            </Box>

            <Box className={'actionBox'}
                sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                    '& .MuiButton-root': {
                        textTransform: 'none',
                        minWidth: { xs: 'auto', md: '150px' },
                        '&.buttonGradient': {
                            backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                            outline: `0px solid ${theme.palette.primary.main}`,
                            outlineOffset: 0,
                            boxShadow: 0,
                            transition: 'all 100ms ease',
                            '&:hover': {
                                outline: `2px solid ${theme.palette.primary.main}`,
                                outlineOffset: '2px',
                                '&:active': {
                                    transform: 'scale(0.98) !important'
                                }
                            },
                        },
                    },
                }}>
                <Button className={''} variant={'outlined'} onClick={closeDialog}>Discard & Close</Button>
                <LoadingButton loading={submitting} endIcon={<></>} loadingPosition={'end'}
                    className={'buttonGradient'} variant={'contained'}
                    onClick={handleSubmit(onSubmit)}>
                    {!selectedCluster?.id ? 'Save & Create' : 'Save & Update'}
                </LoadingButton>
            </Box>
        </Box >
    )
}

export default ClusterForm;
