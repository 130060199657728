import React, { useCallback, useEffect, useState } from 'react'
import PageContainer from '../../components/container/page-container'
import TitleBar from '../../components/container/TitleBar'
import { Autocomplete, Box, Button, Chip, InputAdornment, InputLabel, MenuItem, TextField, Typography, alpha, createFilterOptions, useTheme } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { updateProcessState } from '../../slices/process-slice'
import { Close, CloseRounded, CreditCardOutlined, SearchRounded } from '@mui/icons-material'
import { ThemeTextField, themeTextFieldSx } from '../../components/inputs/theme-text-field'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import FileUploadInput from '../../components/inputs/fileUploadInput'
import dgeIllustration from '../../assets/images/illustartions/support.png'
import { LoadingButton } from '@mui/lab'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router'
import moment from 'moment'
import API from '../../api'
import { s3BucketUrl } from '../../utils/app-helper'
import { RootState } from '../../store/store'
import { User } from '../../models/user'
import ElevatedContainer from '../../components/container/elevated-container'
import { DGE } from '../../models/dge'
import { updateDgeDetail } from '../../slices/dge-detail-slice'
import { useSupervisors } from '../../hooks/useSupervisors'



function CreateDge() {

    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser


    const [submitting, setSubmitting] = useState(false)

    const { handleSubmit, register, clearErrors, control, reset, setValue, watch, formState: { errors } } = useForm()
    const data = watch()
    const [taggedAreas, setTaggedAreas] = useState<any[]>([])

    const resetForm = () => {
        reset()
        setValue('dl_photo', null)
    }

    const onSubmit = (data: any) => {
        const dlSize = (data?.dl_photo[0]?.size / (1024 * 1024)) > 2 // to compare size max is 2mb
        if (dlSize) {
            toast.error(`Error! Please change  DL Image' \n Max 2mb allowed.`)
        } else {
            // setSubmitting(true)
            // dispatch(updateProcessState(true))
            const newData = {
                emp_id: (data?.emp_id ?? '').toUpperCase(),
                name: data?.name,
                mobile_no: data?.mobile_no,
                date_of_joining: `${moment(data?.date_of_joining).format('YYYY-MM-DD')}`,
                dl_no: data?.dl_no,
                state: data?.state,
                district: data?.district,
                sub_district: data?.sub_district,
                tagged_area: JSON.stringify(taggedAreas),
                reporting_manager_id: data?.supervisor?.id,
                reporting_manager: data?.supervisor?.name,
                remarks: data?.remarks,
                dl_photo: data?.dl_photo[0],
                user_id: data?.user_id,
                user_password: data?.user_password,
            }
            API.post('/submit_dge_details', { ...newData },
                {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then((res) => {
                    if (res.data?.status == 'success') {
                        toast.success('DGE created successfully!')
                        reset()
                        navigate('/fleet-management/dge')
                    }
                    if (res.data?.status == 'error') {
                        toast.error(`Error! \n ${res.data?.msg}`)
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    setSubmitting(false)
                    dispatch(updateProcessState(false))
                })
        }
    }


    useEffect(() => {
        dispatch(updateDgeDetail({ selectedDge: {} as DGE }))
    }, [])

    return (
        <Box className={'animate__animated animate__fadeIn'}
            sx={{
                flex: 1,
                position: 'relative',

            }}>
            <TitleBar title={'Create DGE'} backIcon />
            <PageContainer>

                <Box sx={{
                    flex: 1,
                    maxWidth: { xs: '100vw', sm: 'min(80vw, 1200px)' },
                    px: {
                        xs: 0, sm: 10
                    },
                    mx: 'auto',
                    '& .formRow': {
                        padding: '0.5rem ',
                        borderRadius: '18px',
                        background: theme.palette.mode == 'dark'
                            ? '#0e0e0e'
                            : `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.07)}, ${theme.palette.background.paper})`,
                        boxShadow: theme.palette.mode == 'dark' ? 'none' : `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                        border: `2px solid ${theme.palette.background.paper}`,
                    }
                }}>
                    <DgeForm
                        control={control}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        errors={errors}
                        register={register}
                        data={data}
                        taggedAreas={taggedAreas}
                        setTaggedAreas={setTaggedAreas}
                    />

                    <Box sx={{
                        // maxWidth: '600px',
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap-reverse',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        my: 4,
                        '& .MuiButton-root': {
                            flex: '1 0 160px',
                            maxWidth: { xs: '100%', sm: '190px' },
                            textWrap: 'nowrap',
                            textTransform: 'capitalize'
                        },
                    }}>

                        <Button
                            size={'large'}
                            variant={'outlined'}
                            onClick={resetForm}>
                            Discard Changes
                        </Button>
                        <LoadingButton
                            variant={'contained'}
                            size={'large'}
                            loading={submitting}
                            endIcon={<></>}
                            loadingPosition={'end'}
                            onClick={handleSubmit(onSubmit)}>
                            Create
                        </LoadingButton>

                    </Box>

                </Box>

            </PageContainer >
        </Box>
    )
}

export default CreateDge


export const DgeForm = (props: any) => {
    const { control, setValue, clearErrors, errors, register, data, taggedAreas, setTaggedAreas } = props

    const theme = useTheme()

    const [searchedSubDistrict, setSearchedSubDistrict] = useState("");
    const [subDistricts, setSubDistricts] = useState<any[]>([]);
    const [fetchingSubDistricts, setFetchingSubDistricts] = useState(false);
    const selectedDge: DGE = useSelector((state: RootState) => state.dgeDetails.selectedDge)
    const [dgeStatus, setDgeStatus] = useState<string>(selectedDge.status == '1' ? 'active' : 'inactive')


    const fetchSubDistrict = useCallback(
        (subdistrict_name: string) => {
            setFetchingSubDistricts(true);
            API.get(`/fetch-villages`, { params: { subdistrict_name } })
                .then((res) => {
                    if (res.data?.statuscode === "200") {
                        setSubDistricts(res.data?.village_data);
                    } else {
                        setSubDistricts([]);
                    }
                })
                .catch((err) => {
                    toast.success("Oops! Something bad at our end");
                })
                .finally(() => {
                    setFetchingSubDistricts(false);
                });
        },
        [searchedSubDistrict]
    );

    const filterOptions = createFilterOptions({
        matchFrom: "any",
        stringify: (option: any) =>
            `${option?.subdistrict_name}`,
    });


    const { fetchSupervisors, fetchingSupervisors, supervisors } = useSupervisors()


    useEffect(() => {
        fetchSupervisors('admin,rm,hr,rtl')
    }, [])

    return (
        <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 3,
            width: '100%',

        }}>
            <Box sx={{
                flex: '1 1 300px',
                display: 'grid',
                '& img': {
                    objectFit: 'contain',
                    maxHeight: 'min(30vh, 300px)',
                    maxWidth: '90%',

                },
            }}>
                <img src={dgeIllustration} alt={'a'} className={'animate__animated animate__fadeInUp'} />
            </Box>

            <Box sx={{
                flex: '2 1 500px',
                display: 'grid',
                alignContent: 'center',
                pt: 2,
            }}>
                <Box className={'flexTopTop wrap'} sx={{ columnGap: 2, }}>
                    <Controller name={`emp_id`}
                        control={control}
                        rules={{ required: { value: true, message: '' } }}
                        defaultValue={''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} required
                                className={'animate__animated animate__fadeInUp formInput'}
                                error={Boolean(errors?.emp_id)}
                                helperText={(errors?.emp_id?.message ?? '').toString()}
                                disabled={!!selectedDge?.id}
                                size={'small'} label={'Employee ID'}
                                onBlur={(e) => {
                                    setValue(`emp_id`, `${e.target?.value?.toUpperCase()}`)
                                }} placeholder={'********'}
                                sx={{ flex: 1, maxWidth: '200px' }}
                            />
                        )} />

                    <Controller name={`name`}
                        control={control}
                        rules={{ required: { value: true, message: '' } }}
                        defaultValue={''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} required
                                className={'animate__animated animate__fadeInUp formInput'}
                                error={Boolean(errors?.name)}
                                helperText={(errors?.name?.message ?? '').toString()}
                                size={'small'} label={'Name'}
                                placeholder={'Enter name'}
                                sx={{ minWidth: '200px', flex: 1, }}
                            />
                        )} />
                </Box>
                <Box className={'flexTopTop wrap'} sx={{ columnGap: 2, }}>
                    <Controller name="date_of_joining"
                        control={control}
                        defaultValue={null}
                        rules={{
                            required: { value: true, message: 'Required' },
                        }}
                        render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <MobileDatePicker
                                    {...field}
                                    disableFuture
                                    disabled={!!selectedDge?.id}
                                    format={'DD-MM-YYYY'}
                                    label="Joining Date"
                                    className={'animate__animated animate__fadeInUp formInput'}
                                    value={field.value ? moment(field.value) : null}
                                    onChange={(date) => {
                                        setValue('date_of_joining', date);
                                        clearErrors('date_of_joining')
                                    }}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            required: true,
                                            sx: themeTextFieldSx,
                                            style: { width: '100%', maxWidth: '200px', marginBottom: '24px !important' },
                                            error: Boolean(errors?.date_of_joining),
                                            helperText: errors?.date_of_joining?.message?.toString() ?? '',
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        )}
                    />

                    <Controller name={`mobile_no`}
                        control={control}
                        rules={{
                            required: { value: true, message: '' },
                            pattern: { value: /^[6789]\d{9}$/, message: 'Invalid mobile number' },
                        }}
                        defaultValue={''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} required
                                className={'animate__animated animate__fadeInUp'}
                                error={Boolean(errors?.mobile_no)} type={'number'}
                                helperText={(errors?.mobile_no?.message ?? '').toString()}
                                disabled={!!selectedDge?.id}
                                size={'small'} label={'Mobile'}
                                placeholder={'XXXX XXX XXX'}
                                sx={{ minWidth: '200px', flex: 1, }}
                            />
                        )} />
                </Box>


                <Controller
                    name={`headQuarter`}
                    control={control}
                    rules={{ required: { value: true, message: "Required" } }}
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                        <Autocomplete
                            size={"small"}
                            autoComplete
                            className={'animate__animated animate__fadeInUp'}
                            onClose={() => {
                                setSearchedSubDistrict("");
                                setSubDistricts([]);
                            }}
                            onInputChange={(e, inputvalue) => {
                                setSearchedSubDistrict(inputvalue);
                                inputvalue?.length <= 3 && setSubDistricts([]);
                            }}
                            onChange={(e, data) => {
                                onChange(data);
                                setValue(`district`, `${data?.district_name ?? ''}`, { shouldDirty: true, shouldValidate: true });
                                setValue(`sub_district`, `${data?.subdistrict_name ?? ''}`, { shouldDirty: true, shouldValidate: true });
                                setValue(`state`, `${data?.state_name ?? ''}`, { shouldDirty: true, shouldValidate: true });
                            }}
                            filterOptions={filterOptions}
                            noOptionsText={
                                subDistricts.length > 0
                                    ? "No Village Found"
                                    : "Search with atleast 3 characters of sub district"
                            }
                            value={value || null}
                            loading={fetchingSubDistricts}
                            sx={{
                                flex: "1 1 250px",
                                "& .MuiOutlinedInput-root": {
                                    paddingRight: "0.15rem !important",
                                },
                            }}
                            options={subDistricts}
                            isOptionEqualToValue={((option: any, value: any) => option.subdistrict_name == value.subdistrict_name)}
                            renderOption={(props, option, { selected }) => (
                                <Box
                                    component={"li"}
                                    {...props}
                                    key={props.id}
                                    sx={{
                                        p: "2px 6px",
                                        display: "flex",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        flexFlow: "column",
                                        textAlign: "left",
                                        gap: "2px",
                                        borderRadius: "12px",
                                        mx: "2px",
                                        "& .village": {
                                            width: "100%",
                                            fontSize: "15px",
                                            lineHeight: "15px",
                                            fontWeight: 500,
                                            color: theme.palette.text.secondary,
                                        },
                                        "& .state": {
                                            width: "100%",
                                            fontSize: "12px",
                                            lineHeight: "12px",
                                            fontWeight: 500,
                                            color: theme.palette.text.disabled,
                                        },
                                    }}
                                >
                                    <Typography className={"village"}>
                                        {/* {option?.vil_town_name}, */}
                                        {option?.subdistrict_name}
                                    </Typography>
                                    <Typography className={"state"}>
                                        {option?.district_name}, {option?.state_name}
                                    </Typography>
                                </Box>
                            )}
                            getOptionLabel={(option) => option.subdistrict_name ?? ""}
                            renderInput={(params) => (
                                <ThemeTextField
                                    {...params}
                                    required
                                    error={Boolean(errors?.headQuarter)}
                                    helperText={(errors?.headQuarter?.message ??
                                        data?.sub_district
                                        ? `${data?.sub_district}, ${data?.district}, ${data?.state}`
                                        : ""
                                    ).toString()}
                                    size={"small"}
                                    label={"Head Quarter"}
                                    placeholder={"Sub-district name"}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: "search",
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button
                                                    size="small"
                                                    sx={{
                                                        boxShadow: `0 0 12px -3px inset ${theme.palette.primary.main}`,
                                                        color: theme.palette.primary.main,
                                                    }}
                                                >
                                                    <SearchRounded
                                                        onClick={() =>
                                                            searchedSubDistrict?.length >= 3 &&
                                                            fetchSubDistrict(searchedSubDistrict)
                                                        }
                                                    />
                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    )}
                />


                <input type="hidden" {...register("state", { required: true })} />
                <input type="hidden" {...register("district", { required: true })} />
                <input type="hidden" {...register("sub_district", { required: true })} />


                <Controller
                    name={`supervisor`}
                    control={control}
                    rules={{ required: { value: true, message: "Required" } }}
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                        <Autocomplete
                            size={"small"}
                            autoComplete
                            className={'animate__animated animate__fadeInUp'}
                            onChange={(e, data) => {
                                onChange(data);
                                setValue(`supervisor`, data, { shouldDirty: true, shouldValidate: true });
                            }}
                            noOptionsText={supervisors.length < 1 ? "No Village Found" : ""}
                            value={value || null}
                            loading={fetchingSupervisors}
                            sx={{
                                flex: "1 1 250px",
                                "& .MuiOutlinedInput-root": {
                                    paddingRight: "0.15rem !important",
                                },
                            }}
                            options={supervisors?.filter((el: any) => el?.status == '1')}
                            isOptionEqualToValue={((option: any, value: any) => option.id == value.id)}
                            renderOption={(props, option, { selected }) => (
                                <Box
                                    component={"li"}
                                    {...props}
                                    key={props.id}
                                    sx={{
                                        p: "2px 24px 2px 6px",
                                        display: "flex",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        flexFlow: "column",
                                        textAlign: "left",
                                        gap: "2px",
                                        borderRadius: "12px",
                                        mx: "2px",
                                        position: 'relative',

                                        "& .village": {
                                            width: "100%",
                                            fontSize: "15px",
                                            lineHeight: "15px",
                                            fontWeight: 500,
                                            color: theme.palette.text.secondary,
                                        },
                                        "& .state": {
                                            width: "100%",
                                            fontSize: "12px",
                                            lineHeight: "12px",
                                            fontWeight: 500,
                                            color: theme.palette.text.disabled,
                                        },
                                    }}
                                >
                                    <Typography className={"village"}>{option?.name}</Typography>
                                    <Typography className={"state"}>{option?.email}</Typography>
                                    <Typography sx={{
                                        position: 'absolute',
                                        right: '4px',
                                        textTransform: 'uppercase',
                                        fontSize: '10px',
                                        fontWeight: 600,
                                        background: alpha(theme.palette.warning.main, 0.15),
                                        color: theme.palette.warning.main,
                                        borderRadius: '50vh',
                                        padding: '2px 8px',
                                    }}>{option?.role == 'operator' ? 'Captain' : option?.role}</Typography>
                                </Box>
                            )}
                            getOptionLabel={(option) => option.name ?? ""}
                            renderInput={(params) => (
                                <ThemeTextField
                                    {...params}
                                    required
                                    error={Boolean(errors?.supervisor)}
                                    helperText={(errors?.supervisor?.message ?? "").toString()}
                                    size={"small"}
                                    label={"Supervisor"}
                                    placeholder={"Select Supervisor"}

                                />
                            )}
                        />
                    )}
                />

            </Box>

            <Box
                className={'flexTopTop wrap'}
                sx={{
                    minWidth: '100%',
                    gap: 2,
                    alignItems: 'stretch',
                }}>
                <Box
                    className={'animate__animated animate__fadeIn'}
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        justifyItems: 'stretch',
                        gridTemplateColumns: `repeat( auto-fit, minmax(250px, 1fr) )`,
                        gap: 2,
                        flexWrap: 'wrap',
                        flex: '1 1 300px',
                        // minWidth: '100%'
                    }}>

                    <FileUploadInput
                        label={'Driving License'}
                        inputBlock={
                            <Controller name={`dl_no`}
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Required' },
                                    maxLength: { value: 22, message: 'Invlaid Details' }
                                }}
                                defaultValue={''}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        required
                                        className={'formInput'}
                                        error={Boolean(errors?.dl_no)}
                                        size={'small'}
                                        id="standard-basic"
                                        variant="standard"
                                        placeholder='License Number'
                                        onBlur={(e) => {
                                            setValue(`dl_no`, `${e.target?.value?.toUpperCase()}`)
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                <CreditCardOutlined sx={{ fontSize: '16px' }} />
                                            </InputAdornment>,
                                        }}
                                        sx={{
                                            mx: 2, mt: 1,
                                            '& input': {
                                                fontSize: '14px'
                                            },
                                        }} />
                                )} />
                        }
                        accept={'image/png, image/gif, image/jpeg'}
                        reactHookProps={{ ...register('dl_photo', { required: (data.dl_no == "" || selectedDge?.id) ? false : 'Image required', }) }}
                        onClear={() => setValue('dl_photo', null)}
                        errorMessage={errors.dl_photo?.message?.toString() ?? errors.dl_no?.message?.toString()}
                        defaultImage={selectedDge?.id ? `${s3BucketUrl}/dge_dl_photos/${selectedDge?.dl_photo}` : null}
                    />

                </Box>

                <ElevatedContainer
                    label='Operational Areas'
                    sx={{
                        // minWidth: '100%'
                        flex: '2 1 300px',
                    }}>

                    <Controller
                        name={`Operational Areas`}
                        control={control}
                        rules={{ required: { value: false, message: "Required" } }}
                        defaultValue={null}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                size={"small"}
                                autoComplete
                                className={'animate__animated animate__fadeInUp'}
                                onClose={() => {
                                    setSearchedSubDistrict("");
                                    setSubDistricts([]);
                                }}
                                onInputChange={(e, inputvalue) => {
                                    setSearchedSubDistrict(inputvalue);
                                    inputvalue?.length <= 3 && setSubDistricts([]);
                                }}
                                onChange={(e, data) => {
                                    onChange(data);
                                    const uniqueData = Array.from(new Map([...taggedAreas, data]?.map((item: any) => {
                                        const key = `${item?.state_name}-${item?.district_name}-${item?.subdistrict_name}-${item?.village_name}`;
                                        return [key, item];
                                    })).values());
                                    data && setTaggedAreas([...uniqueData])
                                    setSearchedSubDistrict('');

                                }}
                                filterOptions={filterOptions}
                                noOptionsText={
                                    subDistricts.length > 0
                                        ? "No Village Found"
                                        : "Search with atleast 3 characters of sub district"
                                }
                                value={value || null}
                                loading={fetchingSubDistricts}
                                sx={{
                                    flex: "1 1 250px",
                                    "& .MuiOutlinedInput-root": {
                                        paddingRight: "0.15rem !important",
                                    },
                                }}
                                options={subDistricts}
                                isOptionEqualToValue={((option: any, value: any) => option.subdistrict_name == value.subdistrict_name)}
                                renderOption={(props, option, { selected }) => (
                                    <Box
                                        component={"li"}
                                        {...props}
                                        key={props.id}
                                        sx={{
                                            p: "2px 6px",
                                            display: "flex",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            flexFlow: "column",
                                            textAlign: "left",
                                            gap: "2px",
                                            borderRadius: "12px",
                                            mx: "2px",
                                            "& .village": {
                                                width: "100%",
                                                fontSize: "15px",
                                                lineHeight: "15px",
                                                fontWeight: 500,
                                                color: theme.palette.text.secondary,
                                            },
                                            "& .state": {
                                                width: "100%",
                                                fontSize: "12px",
                                                lineHeight: "12px",
                                                fontWeight: 500,
                                                color: theme.palette.text.disabled,
                                            },
                                        }}
                                    >
                                        <Typography className={"village"}>
                                            {/* {option?.vil_town_name}, */}
                                            {option?.subdistrict_name}
                                        </Typography>
                                        <Typography className={"state"}>
                                            {option?.district_name}, {option?.state_name}
                                        </Typography>
                                    </Box>
                                )}
                                getOptionLabel={(option) => option.subdistrict_name ?? ""}
                                renderInput={(params) => (
                                    <ThemeTextField
                                        {...params}
                                        required
                                        error={Boolean(errors?.headQuarter)}
                                        helperText={(errors?.headQuarter?.message ?? "").toString()}
                                        size={"small"}
                                        placeholder={"Search sub-district"}
                                        InputProps={{
                                            ...params.InputProps,
                                            type: "search",
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button
                                                        size="small"
                                                        sx={{
                                                            boxShadow: `0 0 12px -3px inset ${theme.palette.primary.main}`,
                                                            color: theme.palette.primary.main,
                                                        }}
                                                    >
                                                        <SearchRounded
                                                            onClick={() =>
                                                                searchedSubDistrict?.length >= 3 &&
                                                                fetchSubDistrict(searchedSubDistrict)
                                                            }
                                                        />
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        )}
                    />

                    <Box className={'flexTopTop wrap'}
                        sx={{
                            width: '100%',
                            gap: 1,
                        }}>
                        {taggedAreas?.map((area: any, index: number) =>
                            <Chip key={index} label={`${area?.subdistrict_name}, ${area?.district_name}`}
                                onDelete={() => {
                                    setTaggedAreas(taggedAreas?.filter((_: any, i: number) => i !== index))
                                }}
                                className={'animate__animated animate__fadeInUp'}
                            />
                        )}
                    </Box>

                </ElevatedContainer>
            </Box>

            <Box
                className={'flexTopTop wrap'}
                sx={{
                    width: '100%',
                    gap: 2,
                    '& .formInput': {
                        flex: '1 1 180px',
                    },
                }}
            >
                <Controller name={`user_id`}
                    control={control}
                    rules={{
                        required: { value: true, message: '' },
                        pattern: { value: /^[^\s\W]+$/, message: 'No space or special character allowed' }
                    }}
                    defaultValue={''}
                    render={({ field }) => (
                        <ThemeTextField
                            {...field} required
                            className={'animate__animated animate__fadeInUp formInput'}
                            error={Boolean(errors?.user_id)}
                            disabled={!!selectedDge?.id}
                            helperText={(errors?.user_id?.message ?? '').toString()}
                            size={'small'} label={'Username'}
                            placeholder={'eg. swanstore'}
                        />
                    )} />

                <Controller name={`user_password`}
                    control={control}
                    rules={{ required: { value: true, message: '' }, minLength: { value: 8, message: 'Min 8 characters required' } }}
                    defaultValue={''}
                    render={({ field }) => (
                        <ThemeTextField
                            {...field} required
                            className={'animate__animated animate__fadeInUp formInput'}
                            error={Boolean(errors?.user_password)}
                            helperText={(errors?.user_password?.message ?? '').toString()}
                            size={'small'} label={'Password'}
                            placeholder={'********'}
                        />
                    )} />

                {!!selectedDge?.id &&
                    <>
                        {dgeStatus == 'inactive' &&
                            <Controller
                                name={`date_of_leaving`}
                                control={control}
                                rules={{ required: { value: true, message: 'Required' } }}
                                defaultValue={moment() ?? ''}
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <MobileDatePicker
                                            {...field}
                                            disableFuture
                                            label="Last Working Date"
                                            format={'DD-MM-YYYY'}
                                            className={'formInput animate__animated animate__fadeIn'}
                                            sx={{
                                                '& .MuiFormControl-root.flex1': {
                                                    '& .MuiTextField-root': {
                                                        marginBottom: '24px !important',
                                                    },
                                                },

                                                '& .MuiOutlinedInput-root': {
                                                    mb: '24px !important',
                                                },
                                            }}
                                            slotProps={{
                                                textField: {
                                                    required: true,
                                                    size: 'small',
                                                    sx: themeTextFieldSx,
                                                    style: { width: '200px', marginBottom: '24px' },
                                                    error: Boolean(errors?.date_of_leaving),
                                                    helperText: errors?.date_of_leaving?.message?.toString() ?? '',
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                        }
                        <Box
                            sx={{
                                boxShadow: `0 0 12px -3px #83838390`,
                                borderRadius: '16px',
                                p: '4px 4px 4px 12px',
                                textAlign: 'center',
                                flex: 1,
                                minWidth: '200px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                width: '240px',
                                '& .tabLabel': {
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    minWidth: '70px',
                                    flex: 1,
                                },
                                '& .MuiInput-root': {
                                    color: theme.palette.primary.contrastText,
                                    background: dgeStatus == 'active'
                                        ? `${alpha(theme.palette.success.main, 1)} !important`
                                        : `${alpha(theme.palette.error.main, 1)} !important`,
                                    borderRadius: '12px',
                                    '&:before, &:after': {
                                        display: 'none',
                                    },
                                    '& .MuiSelect-select': {
                                        flex: 1,
                                        borderRadius: '12px',
                                        padding: '4px 24px 4px 18px',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        fontWeight: 500,
                                    },
                                },

                            }}>
                            <InputLabel className={'tabLabel'}>Status</InputLabel>
                            <TextField
                                required select variant="standard" value={dgeStatus}
                                onChange={() => setDgeStatus(prev => prev == 'inactive' ? 'active' : 'inactive')}
                                size={'small'} placeholder={'Choose option'}
                                sx={{ flex: 1 }}
                            >
                                <MenuItem key={'active'} value={'active'}>Active</MenuItem>
                                <MenuItem key={'inactive'} value={'inactive'}>In-active</MenuItem>
                            </TextField>
                        </Box>
                    </>
                }
            </Box>
        </Box>
    )
}