import { useCallback, useEffect, useState } from "react";
import API from "../api";

export function useAllBatteries() {
  const [allBatteries, setAllBatteries] = useState<any[]>([]);
  const [fetchingAllBatteries, setFetchingAllBatteries] = useState(false);

  const fetchAllBatteries = useCallback(async () => {
    try {
      setFetchingAllBatteries(true);
      const response = await API.get("/get_all_batteries", {
        headers: { Accept: "application/json" },
      });

      if (response.data.status === 'success') {
        setAllBatteries(response.data?.data || []);
      } else {
        console.error(
          "Failed to fetch batteries:",
          response.data?.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching batteries:", error);
    } finally {
      setFetchingAllBatteries(false);
    }
  }, []);

  return {
    fetchAllBatteries,
    allBatteries,
    fetchingAllBatteries,
  };
}
