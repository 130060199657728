import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, FormControlLabel, IconButton, InputAdornment, MenuItem, TextField, Typography, alpha, useTheme } from '@mui/material'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { CreditCardOutlined, DeleteOutlineRounded } from '@mui/icons-material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import operatorIllustration from '../../assets/images/illustartions/support.png'
import { LoadingButton } from '@mui/lab'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router'
import moment from 'moment'
import { updateProcessState } from '../../slices/process-slice'
import { stateList } from '../../utils/sample-data'
import PageContainer from '../../components/container/page-container'
import TitleBar from '../../components/container/TitleBar'
import { ThemeTextField, themeTextFieldSx } from '../../components/inputs/theme-text-field'
import FileUploadInput from '../../components/inputs/fileUploadInput'
import API from '../../api'
import { FormBlockTitle } from '../../components/title/FormBlockTitle'

function CreateClient() {

    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const regClient = {
        id: '',
        gst_no: '',
        state: null,
        address: '',
    }

    const { handleSubmit, register, clearErrors, control, reset, setValue, watch, formState: { errors } } = useForm()
    const { fields, remove, append } = useFieldArray({ name: "regional_clients", control })
    const appendRegClient = () => append(regClient)

    const [submitting, setSubmitting] = useState(false)

    const data = watch()

    const resetForm = () => {
        reset()
        setValue('logo_img', null)
        setValue('qr_code', null)
        setValue('sign_img', null)
    }


    const onSubmit = (data: any) => {
        const qrSize = (data?.qr_code[0]?.size / (1024 * 1024)) > 2 // to compare size max is 2mb
        const logoSize = (data?.logo_img[0]?.size / (1024 * 1024)) > 2
        const signSize = (data?.sign_img[0]?.size / (1024 * 1024)) > 2
        if (qrSize || logoSize || signSize) {
            toast.error(`Error! Please change ${qrSize ? 'QR Image' : logoSize ? 'Logo Image' : 'Sign Image'} \n Max 2mb allowed.`)
        } else {
            setSubmitting(true)
            dispatch(updateProcessState(true))
            const { regional_clients, pan_no, ...restData } = data
            console.log('data', data)
            const updatedRegionalClients = regional_clients.map((r: any, i: number) => ({
                gst_no: r.gst_no?.toUpperCase(),
                regional_client_name: r.state.label,
                state: r.state.id,
                address: r.address,
                action_key: 'create',

            }))
            const newData = {
                base_client: {
                    ...restData,
                    action_key: 'create',
                    pan_no: data?.pan_no?.toUpperCase(),
                    logo_img: data?.logo_img[0],
                    qr_code: data?.qr_code[0],
                    sign_img: data?.sign_img[0],
                },
                regional_clients: updatedRegionalClients
            }
            console.log(newData)
            API.post('/submit_client_details', { ...newData },
                {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then((res) => {
                    if (res.data?.status == 'success') {
                        toast.success('Client created successfully!')
                        reset()
                        navigate('/clients/base')
                    }
                    if (res.data?.status == 'error') {
                        toast.error(`Error! \n ${res.data?.msg}`)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setSubmitting(false)
                    dispatch(updateProcessState(false))
                })
        }
    }

    return (
        <PageContainer>
            <TitleBar title={'Create Client'} backIcon />

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 3,
                    mx: { xs: 0, sm: 3, md: 5 },
                    '& .formInputsBlock': {
                        padding: '0.5rem 1rem',
                        borderRadius: '18px',
                        background: theme.palette.mode == 'dark'
                            ? '#0e0e0e'
                            : `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.07)}, ${theme.palette.background.paper})`,
                        boxShadow: theme.palette.mode == 'dark' ? 'none' : `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                        border: `2px solid ${theme.palette.background.paper}`,
                        '& .blockHeading': {
                            width: '100%',
                            fontFamily: theme.typography.h1.fontFamily,
                            fontSize: '14px',
                            fontWeight: 600,
                            color: theme.palette.primary.main,
                            pl: 2,
                            mb: 1,
                        },
                        '& .groupTop': {
                            display: 'flex',
                            width: '100%',
                            alignItems: 'flex-end',
                            justifyContent: 'space-between',
                            mb: 0.5,
                            minHeight: '32px',
                            '& .titleBlock': {
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            },
                            '& .actionBlock': {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                '& .MuiFormControlLabel-root': {
                                    '& .MuiCheckbox-root': {
                                        p: '8px',
                                        '& svg': {
                                            height: '1rem',
                                            width: '1rem',
                                        },
                                    },
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: '14px'
                                    },
                                },
                                '& .MuiIconButton-root': {
                                    '& svg': {
                                        height: '22px',
                                        fontSize: '20px'
                                    },
                                }
                            },
                        },
                    },

                    '& .regionalClients': {
                        width: '100%',
                        display: 'flex',
                        flexFlow: 'column',
                        alignItems: 'center',
                        columnGap: 2,
                        justifyContent: 'stretch',
                        px: { xs: 0, sm: 3, md: 5 },
                        pb: 4,
                        '& .regionalClient': {
                            background: { xs: theme.palette.background.paper, sm: 'transparent' },
                            width: { xs: '100%', sm: '90%' },
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            columnGap: 3,
                            '& .formInput': {
                                flex: '1 1 140px',
                                '&.grow-1': {
                                    flex: '2 1 200px'
                                },
                            },
                        },
                    },
                }}>
                <Box className={'formInputsBlock'}
                    sx={{
                        flex: '1 1 300px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        columnGap: 3,
                        '& .formInput': {
                            flex: '1 1 250px',
                        },
                    }}>
                    <Typography className={'blockHeading'}>Basic Info</Typography>

                    <>
                        <Controller name={`client_name`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.client_name)}
                                    helperText={(errors?.client_name?.message ?? '').toString()}
                                    size={'small'} label={'Client Legal Name'}
                                    placeholder={'Enter name'}
                                />
                            )} />
                        <Controller name={`pan_no`}
                            control={control}
                            rules={{
                                required: { value: true, message: 'Required' },
                                pattern: { value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, message: 'invalid pan' },
                            }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.pan_no)}
                                    helperText={(errors?.pan_no?.message ?? '').toString()}
                                    size={'small'} label={'PAN Number'}
                                    placeholder={'XXXXXXXXXX'}
                                />
                            )} />
                        <Controller name={`cin`}
                            control={control}
                            rules={{
                                required: { value: true, message: 'Required' },
                            }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.cin)}
                                    helperText={(errors?.cin?.message ?? '').toString()}
                                    size={'small'} label={'CIN'}
                                    placeholder={'XXXXXXXXXX'}
                                />
                            )} />
                        <Controller name={`phone_number`}
                            control={control}
                            rules={{
                                required: { value: true, message: 'Required' },
                                pattern: { value: /^[789]\d{9}$/, message: 'Invalid mobile number' },
                            }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.phone_number)} type={'number'}
                                    helperText={(errors?.phone_number?.message ?? '').toString()}
                                    size={'small'} label={'Mobile'}
                                    placeholder={'XXXX XXX XXX'}
                                />
                            )} />
                        <Controller name={`signature_name`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.signature_name)}
                                    helperText={(errors?.signature_name?.message ?? '').toString()}
                                    size={'small'} label={'Signatory Name'}
                                    placeholder={'Enter name'}
                                />
                            )} />
                        <Controller name={`gst_nature`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.gst_nature)} select
                                    helperText={(errors?.gst_nature?.message ?? '').toString()}
                                    size={'small'} label={'GST Nature'}
                                    placeholder={'Please slect nature'}
                                >
                                    <MenuItem value={'exempt'}>Exempt</MenuItem>
                                    <MenuItem value={'applicable'}>Applicable</MenuItem>
                                </ThemeTextField>
                            )} />
                        <Controller name={`registration_address`}
                            control={control}
                            rules={{
                                required: { value: true, message: 'Required' },
                            }}
                            defaultValue={''}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required sx={{ width: '100%', marginBottom: '1rem !important', }} multiline rows={3}
                                    error={Boolean(errors?.registration_address)}
                                    helperText={(errors?.registration_address?.message ?? '').toString()}
                                    size={'small'} label={'Registered Address'}
                                    placeholder={'XXXXXXXXXX'}
                                />
                            )} />
                    </>


                    <Box className={'groupTop'}>
                        <Box className={'titleBlock'}>
                            <FormBlockTitle title={`Regional Clients`} icon={false} />
                        </Box>
                    </Box>

                    <Box className="regionalClients">

                        {fields.map((field, index) => {
                            return (
                                <Box className="regionalClient animate__animated animate__fadeIn" key={field.id}>
                                    <Controller name={`regional_clients.${index}.state`}
                                        control={control}
                                        rules={{ required: { value: true, message: 'Required' } }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                {...field}
                                                autoHighlight
                                                options={stateList}
                                                className={'formItem'}
                                                sx={{ flex: '1 1 250px' }}
                                                onChange={(e, data) => setValue(`regional_clients.${index}.state`, data, { shouldValidate: true })}
                                                getOptionLabel={(option) => `${option.label}`}
                                                renderOption={(props, option) => (
                                                    <Box component="li" {...props} sx={{ fontSize: '14px', fontWeight: 600 }}>
                                                        {option.label}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <ThemeTextField
                                                        {...params} required
                                                        error={Boolean((errors as any)?.regional_clients?.[index]?.state)}
                                                        helperText={(errors as any)?.regional_clients?.[index]?.state?.message ?? ''}
                                                        size={'small'} label={'State'}
                                                        placeholder={'eg. Haryana'}
                                                    />
                                                )}
                                            />
                                        )} />
                                    <Controller name={`regional_clients.${index}.gst_no`}
                                        control={control}
                                        rules={{
                                            required: { value: true, message: 'Required' },
                                            pattern: {
                                                value: /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ][0-9a-zA-Z]{1}$/,
                                                message: 'Invalid GSTIN'
                                            }
                                        }}
                                        defaultValue={''}
                                        render={({ field }) => (
                                            <ThemeTextField
                                                {...field} required className={'formInput'}
                                                error={Boolean((errors as any)?.regional_clients?.[index]?.gst_no)}
                                                helperText={(errors as any)?.regional_clients?.[index]?.gst_no?.message ?? ''}
                                                size={'small'} label={'GSTIN'}
                                                placeholder={'***************'}
                                            />
                                        )} />
                                    <Controller name={`regional_clients.${index}.address`}
                                        control={control}
                                        rules={{ required: { value: true, message: 'Required' } }}
                                        defaultValue={''}
                                        render={({ field }) => (
                                            <ThemeTextField
                                                {...field} required className={'formInput grow-1'}
                                                error={Boolean((errors as any)?.regional_clients?.[index]?.address)}
                                                helperText={(errors as any)?.regional_clients?.[index]?.address?.message ?? ''}
                                                size={'small'} label={'Address'}
                                                placeholder={'address here'}
                                            />
                                        )} />
                                    <Box className={'actionBlock'}>
                                        {true &&
                                            <IconButton
                                                size={'small'}
                                                color={'error'}
                                                onClick={() => {
                                                    remove(index)
                                                }}
                                            >
                                                <DeleteOutlineRounded />
                                            </IconButton>
                                        }
                                    </Box>
                                </Box>
                            )
                        })}

                        <Button variant={'outlined'} size={'small'} onClick={appendRegClient}>
                            Add Row
                        </Button>
                    </Box>
                </Box>

                <Box className="formInputsBlock"
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        columnGap: 3,
                        '& .formInput': {
                            flex: '1 1 250px',
                        },

                    }}>
                    <Typography className={'blockHeading'}>Bank Info</Typography>

                    <Controller name={`account_no`}
                        control={control}
                        rules={{ required: { value: true, message: 'Required' } }}
                        defaultValue={''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} required className={'formInput'}
                                error={Boolean(errors?.account_no)}
                                helperText={(errors?.account_no?.message ?? '').toString()}
                                size={'small'} label={'Account Number'}
                                placeholder={'***************'}
                            />
                        )} />
                    <Controller name={`ifsc`}
                        control={control}
                        rules={{ required: { value: true, message: 'Required' } }}
                        defaultValue={''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} required className={'formInput'}
                                error={Boolean(errors?.ifsc)}
                                helperText={(errors?.ifsc?.message ?? '').toString()}
                                size={'small'} label={'IFSC'}
                                placeholder={'***********'}
                            />
                        )} />
                    <Controller name={`branch_name`}
                        control={control}
                        rules={{ required: { value: true, message: 'Required' } }}
                        defaultValue={''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} required className={'formInput'}
                                error={Boolean(errors?.branch_name)}
                                helperText={(errors?.branch_name?.message ?? '').toString()}
                                size={'small'} label={'Branch'}
                                placeholder={'eg. Azad Nagar, Hisar'}
                            />
                        )} />
                    <Controller name={`bank_name`}
                        control={control}
                        rules={{ required: { value: true, message: 'Required' } }}
                        defaultValue={''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} required className={'formInput'}
                                error={Boolean(errors?.bank_name)}
                                helperText={(errors?.bank_name?.message ?? '').toString()}
                                size={'small'} label={'Bank'}
                                placeholder={'eg. State bank of India'}
                            />
                        )} />
                    <Controller name={`upi_id`}
                        control={control}
                        rules={{ required: { value: true, message: 'Required' } }}
                        defaultValue={''}
                        render={({ field }) => (
                            <ThemeTextField
                                {...field} required className={'formInput'}
                                error={Boolean(errors?.upi_id)}
                                helperText={(errors?.upi_id?.message ?? '').toString()}
                                size={'small'} label={'UPI Address'}
                                placeholder={'***************'}
                            />
                        )} />
                </Box>

                <Box sx={{
                    width: '100%',
                    display: 'grid',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    justifyItems: 'stretch',
                    gridTemplateColumns: `repeat( auto-fit, minmax(250px, 1fr) )`,
                    gap: 2,
                    flexWrap: 'wrap',
                }}>

                    <FileUploadInput
                        label={'QR Code'}
                        accept={'image/png, image/gif, image/jpeg'}
                        reactHookProps={{ ...register('qr_code', { required: 'Image required', }) }}
                        onClear={() => setValue('qr_code', null)}
                        errorMessage={errors.qr_code?.message?.toString() ?? ''}
                    />
                    <FileUploadInput
                        label={'Company Logo'}
                        accept={'image/png, image/gif, image/jpeg'}
                        reactHookProps={{ ...register('logo_img', { required: 'Image required', }) }}
                        onClear={() => setValue('logo_img', null)}
                        errorMessage={errors.logo_img?.message?.toString() ?? ''}
                    />
                    <FileUploadInput
                        label={'Signature'}
                        accept={'image/png, image/gif, image/jpeg'}
                        reactHookProps={{ ...register('sign_img', { required: 'Image required', }) }}
                        onClear={() => setValue('sign_img', null)}
                        errorMessage={errors.sign_img?.message?.toString() ?? ''}
                    />

                </Box>

                <Box sx={{
                    order: 4,
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap-reverse',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                    my: 4,
                    '& .MuiButton-root': {
                        flex: '1 0 160px',
                        maxWidth: { xs: '100%', sm: '190px' },
                        textWrap: 'nowrap',
                        textTransform: 'capitalize'
                    },
                }}>

                    <Button
                        size={'large'}
                        variant={'outlined'}
                        onClick={resetForm}>
                        Discard Changes
                    </Button>
                    <LoadingButton
                        variant={'contained'}
                        size={'large'}
                        loading={submitting}
                        endIcon={<></>}
                        loadingPosition={'end'}
                        onClick={handleSubmit(onSubmit)}>
                        Create
                    </LoadingButton>

                </Box>

            </Box>

        </PageContainer >
    )
}

export default CreateClient