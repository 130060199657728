import { CurrencyRupeeRounded, DeleteOutlineRounded } from '@mui/icons-material'
import { Autocomplete, Box, Button, CircularProgress, IconButton, InputAdornment, MenuItem, Typography, alpha, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { ThemeTextField } from '../../components/inputs/theme-text-field'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { stateList } from '../../utils/sample-data'
import { FormBlockTitle } from '../../components/title/FormBlockTitle'
import { camelCaseWords } from '../../utils/app-helper'
import API from '../../api'
import { useAllCrops } from '../../hooks/useAllCrops'
import { LoadingButton } from '@mui/lab'
import { updateProcessState } from '../../slices/process-slice'
import { useDispatch } from 'react-redux'
import toast from 'react-hot-toast'



interface SelectedCropDetailsType {
    crop_details: any[]
    availability: any[]
}

export default function CropManagementForm(props: any) {

    const { cropId, closeDialog, allCrops, fetchingAllCrops, fetchAvailableCrops } = props


    const theme = useTheme()
    const dispatch = useDispatch()

    const [submitting, setSubmitting] = useState(false)

    const [featchinData, setFetchingData] = useState(true)
    const [selectedCropDetails, setSelectedCropDetails] = useState<SelectedCropDetailsType>({} as SelectedCropDetailsType)

    const availability = { id: '', state: '', state_price: '' }


    const { control, handleSubmit, setError, setValue, clearErrors, register, reset, formState: { errors } } = useForm(
        {
            defaultValues: {
                crop: '',
                crop_id: '',
                crop_name: '',
                base_price: '',
                availability: [availability]
            }
        }
    )

    const { fields, remove, append } = useFieldArray({ name: "availability", control })
    const appendRow = () => append(availability)


    const getCropDetail = useCallback(() => {
        setFetchingData(true)
        API.post('/get_crop_details', { crop_id: `${cropId}` })
            .then((res) => {
                console.log(res.data?.data)
                setSelectedCropDetails(res.data?.data)
                setFetchingData(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const onSubmit = (data: any) => {
        console.log('data', data)
        dispatch(updateProcessState(true))
        const modifiedavailability = data.availability.map((sp: any, index: number) => ({
            ...(cropId ? { id: sp.id, } : {}),
            ...(index < selectedCropDetails.availability?.length ? { state: sp.state, } : { state: sp.state?.id, }),
            state_price: sp.state_price,
        }))
        const { crop, availability, ...restData } = data
        const newData = {
            cropData: {
                crop_id: !!cropId ? `${cropId}` : data.crop.id,
                crop_name: data.crop.crop_name,
                base_price: data.base_price,
                availability: modifiedavailability
            },
        }
        setSubmitting(true)
        console.log('newData', newData)
        let duplicateEnteries = false

        const stateSet = new Set()

        for (const item of newData.cropData.availability) {
            if (stateSet.has(item.state)) {
                duplicateEnteries = true
                toast.error(`Duplicate state found: ${item.state}`)
                setSubmitting(false)
                dispatch(updateProcessState(false))
                return
            }
            stateSet.add(item.state);
        }

        const url = !!cropId ? 'update_crop_prices' : 'submit_crop_prices'

        !duplicateEnteries &&
            API.post(url, { ...newData })
                .then((res) => {
                    if (res.data?.status == 'success') {
                        toast.success(`Crop price ${!!cropId ? 'updated' : 'added'} successfully!`)
                        closeDialog()
                        fetchAvailableCrops()
                    }
                    if (res.data?.status == 'error') {
                        toast.error(`Error! \n ${res.data?.msg}`)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setSubmitting(false)
                    dispatch(updateProcessState(false))
                })

    }

    useEffect(() => {
        !!cropId && getCropDetail()
    }, [])

    useEffect(() => {
        if (selectedCropDetails?.crop_details?.length > 0) {
            setValue('base_price', selectedCropDetails.crop_details[0].base_price, { shouldValidate: true })
            setValue('availability', selectedCropDetails.availability, { shouldValidate: true })
        }
    }, [selectedCropDetails])
    return (
        <Box component={'form'}
            sx={{
                pt: 3,
                mx: 'auto',
                width: '90vw',
                maxWidth: '800px',
                minHeight: 'calc(100vh - 144px)',
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'stretch',
                justifyContent: 'flex-start',
                position: 'relative',
                gap: 2,
                '& .boxTitle': {
                    fontWeight: 500,
                    fontSize: '18px',
                    mb: 3,
                },
                '& .formInput': {
                    flex: '1 1 220px',
                    '&.state': {
                        flex: '2 1 220px !important'
                    },
                    '&.price': {
                        flex: '1 1 120px !important'
                    },
                },
            }}>

            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 3,
                    mx: { xs: 0, sm: 3, md: 5 },
                    minHeight: '240px',
                }}>
                {!!cropId && featchinData
                    ? <CircularProgress size={'small'} />
                    : <>
                        <Box
                            sx={{
                                flex: '1 1 300px',
                                display: 'flex',
                                alignContent: 'flex-start',
                                flexWrap: 'wrap',
                                columnGap: 3,
                            }}>
                            {!!cropId
                                ? <Typography sx={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: theme.typography.h1.fontFamily,
                                    color: theme.palette.text.secondary,
                                    flex: '1 1 300px'
                                }}>
                                    Crop Name: {selectedCropDetails?.crop_details[0]?.crop_name}
                                </Typography>
                                : <Controller name={`crop`}
                                    control={control}
                                    rules={{ required: { value: true, message: 'Required' } }}
                                    render={({ field: { onChange, value } }) => (
                                        <Autocomplete size={'small'}
                                            autoComplete
                                            disabled={!!cropId}
                                            loading={fetchingAllCrops}
                                            className={'formInput'}
                                            onChange={(e, data) => onChange(data)}
                                            noOptionsText={'No Crops Available'}
                                            // value={value || null}
                                            sx={{ flex: '1 1 250px' }}
                                            options={allCrops}
                                            getOptionLabel={(option) => camelCaseWords(option?.crop_name) ?? ''}
                                            isOptionEqualToValue={((option: any, value: any) => option.id == value.id)}
                                            renderOption={(props, option, { selected }) => (
                                                <Box component={'li'} {...props} key={props.id}
                                                    sx={{
                                                        p: '2px 6px',
                                                        textAlign: 'left',
                                                        borderRadius: '12px',
                                                        mx: '2px',
                                                        fontSize: '15px',
                                                        lineHeight: '15px',
                                                        fontWeight: 500,
                                                        color: theme.palette.text.secondary,
                                                    }}>
                                                    {camelCaseWords(option?.crop_name)}
                                                </Box>

                                            )}
                                            renderInput={(params) => (
                                                <ThemeTextField
                                                    {...params} required
                                                    error={Boolean(errors?.crop)}
                                                    helperText={(errors?.crop?.message ?? '').toString()}
                                                    size={'small'} label={'Crop'}
                                                    placeholder={'Please select crop'}
                                                />
                                            )} />
                                    )} />
                            }
                            <Controller name={`base_price`}
                                control={control}
                                rules={
                                    {
                                        required: { value: true, message: 'Required' },
                                        min: { value: 1, message: 'Really! Is it free?' },
                                    }}
                                defaultValue={''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} required className={'formInput'}
                                        error={Boolean(errors?.base_price)} type={'number'}
                                        helperText={(errors?.base_price?.message ?? '').toString()}
                                        size={'small'} label={'Base Price'}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><CurrencyRupeeRounded sx={{ fontSize: '16px' }} /></InputAdornment>,
                                        }}
                                        placeholder={'eg. 12'}
                                    />
                                )} />

                            <Box className={'animate__animated animate__fadeIn'}
                                sx={{
                                    boxShadow: { xs: 'none', sm: '0 0 12px -3px #83838360 inset' },
                                    width: '100%',
                                    py: 2,
                                    borderRadius: '18px',
                                    textAlign: 'center',
                                    maxHeight: { xs: 'auto', sm: 'calc(100vh - 280px)' },
                                    overflow: 'auto',
                                }}>
                                <Box sx={{ width: '100%', mb: { xs: 1, sm: 3 } }}>
                                    <FormBlockTitle icon={false} title={'State Wise Price'} />
                                </Box>

                                {fields.map((field: any, index: number) => (
                                    <Box
                                        key={field.id}
                                        sx={{
                                            width: '100%',
                                            px: 2,
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            alignItems: 'flex-start',
                                            columnGap: 3,
                                            borderRadius: '18px',
                                            pt: { xs: 2, sm: 0 },
                                            background: { xs: alpha(theme.palette.primary.main, 0.06), sm: 'transparent' },

                                        }}>
                                        {!!cropId && <input {...register(`availability.${index}.id`)} value={index} style={{ display: 'none' }} />}
                                        {index < selectedCropDetails.availability?.length
                                            ? <>
                                                <input {...register(`availability.${index}.id`)} value={index} style={{ display: 'none' }} />
                                                <Controller name={`availability.${index}.state`}
                                                    control={control}
                                                    rules={{ required: { value: true, message: 'Required' } }}
                                                    render={({ field }) => (
                                                        <ThemeTextField
                                                            {...field} required disabled
                                                            sx={{ flex: '1 1 120px', textAlign: 'left' }} select
                                                            error={Boolean(errors?.availability?.[index]?.state)}
                                                            helperText={(errors?.availability?.[index]?.state?.message ?? '').toString()}
                                                            size={'small'} label={'State'}
                                                        >
                                                            {stateList.map((s, i) => <MenuItem key={i} value={s.id}>{s.label}</MenuItem>)}
                                                        </ThemeTextField>
                                                    )} />
                                            </>
                                            : <Controller name={`availability.${index}.state`}
                                                control={control}
                                                rules={{ required: { value: true, message: 'Required' } }}
                                                render={({ field: { onChange, value } }) => (
                                                    <Autocomplete size={'small'}
                                                        autoComplete
                                                        // disabled={!!cropId}
                                                        onChange={(e, data) => onChange(data)}
                                                        noOptionsText={'No Crops Available'}
                                                        // value={value || null}
                                                        sx={{ flex: '2 1 200px' }}
                                                        options={stateList}
                                                        getOptionLabel={(option) => option.label ?? ''}
                                                        isOptionEqualToValue={((option: any, value: any) => option.id == value.id)}
                                                        renderOption={(props, option, { selected }) => (
                                                            <Box component={'li'} {...props} key={props.id}
                                                                sx={{
                                                                    p: '2px 6px',
                                                                    display: 'flex',
                                                                    alignItems: 'flex-start',
                                                                    justifyContent: 'flex-start',
                                                                    flexFlow: 'column',
                                                                    textAlign: 'left',
                                                                    gap: '2px',
                                                                    borderRadius: '12px',
                                                                    mx: '2px',
                                                                    '& .village': {
                                                                        width: '100%',
                                                                        fontSize: '15px',
                                                                        lineHeight: '15px',
                                                                        fontWeight: 500,
                                                                        color: theme.palette.text.secondary,
                                                                    },
                                                                }}>
                                                                <Typography className={'village'}>
                                                                    {option?.label}
                                                                </Typography>
                                                            </Box>

                                                        )}
                                                        renderInput={(params) => (
                                                            <ThemeTextField
                                                                {...params} required
                                                                error={Boolean(errors?.availability?.[index]?.state)}
                                                                helperText={(errors?.availability?.[index]?.state?.message ?? '').toString()}
                                                                size={'small'} label={'State'}
                                                                placeholder={'Please select state'}
                                                            />
                                                        )} />
                                                )} />
                                        }
                                        <Controller name={`availability.${index}.state_price`}
                                            control={control}
                                            rules={
                                                {
                                                    required: { value: true, message: 'Required' },
                                                    min: { value: 1, message: 'Really! Is it free?' },
                                                }}
                                            render={({ field }) => (
                                                <ThemeTextField
                                                    {...field} required
                                                    sx={{ flex: '1 1 120px' }}
                                                    error={Boolean(errors?.availability?.[index]?.state_price)} type={'number'}
                                                    helperText={(errors?.availability?.[index]?.state_price?.message ?? '').toString()}
                                                    size={'small'} label={'Price'}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"><CurrencyRupeeRounded sx={{ fontSize: '16px' }} /></InputAdornment>,
                                                    }}
                                                    placeholder={'eg. 12'}
                                                />
                                            )} />
                                        <Box className={'actionBlock'}>
                                            {!(index < selectedCropDetails.availability?.length) &&
                                                <IconButton
                                                    size={'small'}
                                                    color={'error'}
                                                    onClick={() => {
                                                        remove(index)
                                                    }}
                                                >
                                                    <DeleteOutlineRounded />
                                                </IconButton>
                                            }
                                        </Box>
                                    </Box>
                                ))}

                                <Button
                                    variant='contained'
                                    size={'small'}
                                    sx={{
                                        fontSize: '12px',
                                        textTransform: 'capitalize',
                                        mx: 'auto',
                                    }}
                                    onClick={appendRow}
                                >Add</Button>
                            </Box>
                        </Box>
                    </>
                }
            </Box>

            <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexWrap: 'wrap-reverse',
                gap: 3,
                position: { xs: 'relative', sm: 'sticky' },
                bottom: 0,
                zIndex: 1,
                background: theme.palette.background.default,
            }}>
                <Button className={''} variant={'outlined'} onClick={closeDialog}>Discard & Close</Button>
                <LoadingButton loading={submitting} endIcon={<></>} loadingPosition={'end'}
                    className={'buttonGradient'} variant={'contained'}
                    onClick={handleSubmit(onSubmit)}>
                    {!!cropId ? 'Save & Create' : 'Save & Update'}
                </LoadingButton>
            </Box>
        </Box>
    )
}
