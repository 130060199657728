import { Box } from '@mui/material'

function ThemeLoader() {
    return (
        <Box sx={{
            '& .loader': {
                width: `35px`,
                aspectRatio: 1,
                '--_g': 'no-repeat radial-gradient(farthest-side, #72D82D 94%, transparent)',
                background: `var(--_g) 0 0, var(--_g) 100% 0, var(--_g) 100% 100%, var(--_g) 0 100%`,
                backgroundSize: `40% 40%`,
                animation: `l38 .5s infinite`,
            },
            ['@keyframes l38']: {
                '100%': {
                    backgroundPosition: `100% 0,100% 100%,0 100%,0 0`,
                },
            },
        }}>
            <div className="loader"></div>
        </Box>
    )
}

export default ThemeLoader