import React, { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { Box, Button, alpha, useTheme } from '@mui/material';
import { Farmer } from '../../../models/farmer';
import { updateProcessState } from '../../../slices/process-slice';
import LoadingView from '../../../components/loading-view';
import API from '../../../api';
import FarmerPersonalInfo from '../../farmers/farmerPersonalInfo';
import { LoadingButton } from '@mui/lab';
import PageContainer from '../../../components/container/page-container';
import TitleBar from '../../../components/container/TitleBar';

function OperatorUpdateFarmer() {

    const params = useParams()
    const { farmerId } = params
    const dispatch = useDispatch()
    const [fetchingFarmer, setFetchingFarmer] = useState(false)
    const [farmer, setFarmer] = useState<Farmer>({} as Farmer)


    const fetchFarmer = useCallback(() => {
        setFetchingFarmer(true)

        dispatch(updateProcessState(true));

        API.get(`/get-farmer-info?id=${atob(`${farmerId}`)}`)
            .then((res) => {
                if (res.data?.statuscode == "200") {
                    setFarmer(res.data?.farmer_data[0])
                }
            })
            .catch((err) => {
                toast.success("Oops! Something bad at our end");
            })
            .finally(() => {
                setFetchingFarmer(false)
                dispatch(updateProcessState(false))
            });
    }, [])

    useEffect(() => {
        fetchFarmer()
    }, [])
    return (
        fetchingFarmer
            ? <LoadingView />
            : <FarmerProfileForm farmer={farmer} />
    )
}


const FarmerProfileForm = (props: any) => {

    const { farmer } = props

    const { handleSubmit, control, setError, clearErrors, reset, setValue, register, watch, formState: { errors } } = useForm({
        defaultValues: {
            gender: '',
            income: '',
            education_level: '',
            date_of_birth: null,
            wedding_anniversary: null,
            attitude: '',
            lifestyle: '',
            professional_info: '',
            influence: null,
            hobbies: '',
            favourite_activities: '',
            intrests: '',
            mobile_phone_used: '',
            social_media_platform: '',
            tech_proficiency: '',
            prferred_communication: '',
            email_id: '',
            ratings: null,
            suggestion_for_improvement: '',
            fb_link: '',
            insta_link: '',
            yt_link: '',
        }
    })

    const inputData = watch()


    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [submitting, setSubmitting] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const [activeStep, setActiveStep] = React.useState(0);
    const [channel, setChannel] = React.useState('whatsapp');
    const [rating, setRating] = React.useState<number | null>(0)
    const [influence, setInfluence] = React.useState<number | null>(20)

    const resetForm = () => {
        reset()
        setActiveStep(0)
    }

    const onSubmit = (data: any) => {
        setSubmitting(true)
        dispatch(updateProcessState(true))

        const socialPlateformsSelected = [data.Facebook ? 'Facebook' : '', data.Instagram ? 'Instagram' : '', data.Youtube ? 'Youtube' : '',].toString()
        const newData = {
            farmer_details: {
                farmer_id: farmer?.id,
                profile: [
                    {
                        gender: data.gender,
                        income: data.income,
                        education_level: data.education_level,
                        date_of_birth: data.date_of_birth,
                        wedding_anniversary: data.wedding_anniversary,
                        attitude: data.attitude,
                        lifestyle: data.lifestyle,
                        professional_info: data.professional_info,
                        influence: `${influence}`,
                        hobbies: data.hobbies,
                        favourite_activities: data.favourite_activities,
                        intrests: data.intrests,
                        mobile_phone_used: data.mobile_phone_used,
                        social_media_platform: socialPlateformsSelected,
                        tech_proficiency: data.tech_proficiency,
                        email_id: data?.email_id,
                        ratings: `${rating}`,
                        suggestion_for_improvement: data.suggestion_for_improvement,
                        prferred_communication: channel == 'whatsapp' ? 'whatsapp' : 'email',
                        social_profile: JSON.stringify([
                            { name: 'facebook', link: data?.fb_link ?? '' },
                            { name: 'instagram', link: data?.insta_link ?? '' },
                            { name: 'youtube', link: data?.yt_link ?? '' }
                        ])
                    }
                ]
            },

        }
        API.post('/edit_farmer_profile', { ...newData })
            .then((res) => {
                if (res.data?.status == 'success') {
                    toast.success('Farmer details update successfully!')
                    resetForm()
                    navigate(-1)
                }
                if (res.data?.status == 'error') {
                    toast.error(`Error! \n ${res.data?.msg}`)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setSubmitting(false)
                setSubmitted(true)
                dispatch(updateProcessState(false))
            })
    }

    useEffect(() => {
        if (farmer?.farmer_profile_info) {
            setChannel(`${farmer.farmer_profile_info[0]?.prferred_communication}`)
            setRating(+(farmer.farmer_profile_info[0]?.ratings ?? 4))
            setInfluence(+(farmer.farmer_profile_info[0]?.influence ?? 80))
            setValue('gender', farmer?.farmer_profile_info[0]?.gender ?? '', { shouldValidate: true })
            setValue('income', farmer?.farmer_profile_info[0]?.income ?? '', { shouldValidate: true })
            setValue('education_level', farmer?.farmer_profile_info[0]?.education_level ?? '', { shouldValidate: true })
            setValue('attitude', farmer?.farmer_profile_info[0]?.attitude ?? '', { shouldValidate: true })
            setValue('lifestyle', farmer?.farmer_profile_info[0]?.lifestyle ?? '', { shouldValidate: true })
            setValue('professional_info', farmer?.farmer_profile_info[0]?.professional_info ?? '', { shouldValidate: true })
            setValue('hobbies', farmer?.farmer_profile_info[0]?.hobbies ?? '', { shouldValidate: true })
            setValue('favourite_activities', farmer?.farmer_profile_info[0]?.favourite_activities ?? '', { shouldValidate: true })
            setValue('intrests', farmer?.farmer_profile_info[0]?.intrests ?? '', { shouldValidate: true })
            setValue('mobile_phone_used', farmer?.farmer_profile_info[0]?.mobile_phone_used ?? '', { shouldValidate: true })
            setValue('social_media_platform', farmer?.farmer_profile_info[0]?.social_media_platform ?? '', { shouldValidate: true })
            setValue('tech_proficiency', farmer?.farmer_profile_info[0]?.tech_proficiency ?? '', { shouldValidate: true })
            setValue('prferred_communication', farmer?.farmer_profile_info[0]?.prferred_communication ?? '', { shouldValidate: true })
            setValue('email_id', farmer?.farmer_profile_info[0]?.email_id ?? '', { shouldValidate: true })
            setValue('suggestion_for_improvement', farmer?.farmer_profile_info[0]?.suggestion_for_improvement ?? '', { shouldValidate: true })

            setValue('fb_link', JSON.parse(farmer?.farmer_profile_info[0]?.social_profile).filter((el: any) => el?.name == 'facebook')[0]?.link ?? '', { shouldValidate: true })
            setValue('insta_link', JSON.parse(farmer?.farmer_profile_info[0]?.social_profile).filter((el: any) => el?.name == 'instagram')[0]?.link ?? '', { shouldValidate: true })
            setValue('yt_link', JSON.parse(farmer?.farmer_profile_info[0]?.social_profile).filter((el: any) => el?.name == 'youtube')[0]?.link ?? '', { shouldValidate: true })

        }
    }, [farmer])




    return (
        <Box className={'animate__animated animate__fadeIn'}
            sx={{
                flex: 1,
                position: 'relative',
                // background: `url(${farmBg}) no-repeat center bottom / contain`,
                // pb: 18,
                backgroundAttachment: 'fixed',
                '& input[type="checkbox"]:checked + label.labelName, & input[type="radio"]:checked + label.labelName': {
                    boxShadow: `0 0 28px -8px ${theme.palette.primary.light} inset`,
                    '& .labelText': {
                        fontSize: '14px',
                        fontWeight: 500,
                        color: theme.palette.primary.main,
                    },
                    '& svg': {
                        color: theme.palette.primary.main,
                    },
                },
                '& .labelName:hover': {
                    transform: 'scale(0.98)',
                },
                '& label.labelName': {
                    cursor: 'pointer',
                    borderRadius: '16px',
                    py: 0.7,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    pr: 2, pl: 1.2,
                    transition: 'all 300ms ease-in-out',
                    boxShadow: `0 0 28px -8px ${alpha(theme.palette.text.secondary, 0.2)} inset`,
                    '& .labelText': {
                        color: theme.palette.text.secondary,
                        fontSize: '14px',
                        fontWeight: 500,
                    },
                    '& svg': {
                        height: '18px',
                        width: '18px',
                        color: theme.palette.text.secondary,
                    },
                },
            }}>

            <TitleBar title={'Update Farmer'} backIcon />
            <PageContainer>
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        gap: 2,
                        px: { xs: 0, sm: '3%' },
                        '& .formInputGroup': {
                            padding: '0.5rem 1rem 0.2rem',
                            borderRadius: '18px',
                            mb: 2,
                            background: theme.palette.mode == 'dark'
                                ? '#0e0e0e'
                                : `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.07)}, ${theme.palette.background.paper})`,
                            boxShadow: theme.palette.mode == 'dark' ? 'none' : `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                            border: `2px solid ${theme.palette.background.paper}`,
                            '& .farmsInputArea': {
                                display: 'flex',
                                flexWrap: 'wrap',
                                columnGap: 3,
                                '& .formInput': {
                                    flex: '1 1 220px',
                                },

                            },
                            '& .groupTop': {
                                display: 'flex',
                                width: '100%',
                                alignItems: 'flex-end',
                                justifyContent: 'space-between',
                                mb: 0.5,
                                minHeight: '32px',
                                '& .titleBlock': {
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                },
                                '& .actionBlock': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    '& .MuiFormControlLabel-root': {
                                        '& .MuiCheckbox-root': {
                                            p: '8px',
                                            '& svg': {
                                                height: '1rem',
                                                width: '1rem',
                                            },
                                        },
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '14px'
                                        },
                                    },
                                    '& .MuiIconButton-root': {
                                        '& svg': {
                                            height: '22px',
                                            fontSize: '20px'
                                        },
                                    }
                                },
                            },
                        },
                        '& .farmAddresses': {
                            width: '100%',
                        },

                    }}>
                    <Box
                        sx={{
                            width: '100%',
                            minHeight: '45vh',
                            '& .formbox': {
                                width: '100%',
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                gap: 2,
                                mx: 'auto',
                                // px: '3%',
                                '& .formInputGroup': {
                                    width: '100%',
                                    padding: '0.5rem 1rem 0.2rem',
                                    borderRadius: '18px',
                                    mb: 2,
                                    background: theme.palette.mode == 'dark'
                                        ? '#0e0e0e'
                                        : `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.07)}, ${theme.palette.background.paper})`,
                                    boxShadow: theme.palette.mode == 'dark' ? 'none' : `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                                    border: `2px solid ${theme.palette.background.paper}`,
                                    '& .farmsInputArea': {
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        columnGap: 3,
                                        '& .formInput': {
                                            flex: '1 1 220px',
                                        },

                                    },
                                    '& .groupTop': {
                                        display: 'flex',
                                        width: '100%',
                                        alignItems: 'flex-end',
                                        justifyContent: 'space-between',
                                        mb: 0.5,
                                        minHeight: '32px',
                                        '& .titleBlock': {
                                            flex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        },
                                        '& .actionBlock': {
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                            '& .MuiFormControlLabel-root': {
                                                '& .MuiCheckbox-root': {
                                                    p: '8px',
                                                    '& svg': {
                                                        height: '1rem',
                                                        width: '1rem',
                                                    },
                                                },
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: '14px'
                                                },
                                            },
                                            '& .MuiIconButton-root': {
                                                '& svg': {
                                                    height: '22px',
                                                    fontSize: '20px'
                                                },
                                            }
                                        },
                                    },
                                },

                                '& .formInput': {
                                    flex: '1 1 220px',
                                    minWidth: { xs: '100%', sm: 'auto' },
                                },

                                '& .farmAddresses': {
                                    width: '100%',
                                    '& .farms': {
                                        padding: '0.5rem 1rem 0.2rem',
                                        borderRadius: '18px',
                                        mb: 2,
                                        background: theme.palette.mode == 'dark'
                                            ? '#0e0e0e'
                                            : `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.07)}, ${theme.palette.background.paper})`,
                                        boxShadow: theme.palette.mode == 'dark' ? 'none' : `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                                        border: `2px solid ${theme.palette.background.paper}`,
                                        '& .farmsInputArea': {
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            columnGap: 3,
                                            '& .formInput': {
                                                flex: '1 1 220px',
                                            },

                                        },

                                    },
                                },
                            },
                        }}>
                        <Box className="personalInfo"
                            sx={{ display: 'flex' }}>
                            <FarmerPersonalInfo
                                farmer={farmer}
                                control={control}
                                errors={errors}
                                register={register}
                                channel={channel}
                                setChannel={setChannel}
                                rating={rating}
                                setValue={setValue}
                                setRating={setRating}
                                influence={influence}
                                setInfluence={setInfluence}
                                watch={watch}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        width: '94%',
                        display: 'flex',
                        flexWrap: 'wrap-reverse',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        py: { xs: '2.5rem', sm: 7 },
                        mx: 'auto',
                        background: alpha(theme.palette.background.default, 0.3),
                        backdropFilter: 'blur(4px)',
                        '& .MuiButton-root': {
                            flex: '1 0 160px',
                            maxWidth: { xs: '100%', sm: '190px' },
                            textWrap: 'nowrap',
                            textTransform: 'capitalize'
                        },
                    }}>

                    <Button
                        size={'large'}
                        variant={'outlined'}
                        sx={{ mr: 1 }}
                        onClick={resetForm}>
                        Discard Changes
                    </Button>
                    <LoadingButton
                        variant={'contained'}
                        size={'large'}
                        disabled={Object.keys(errors)?.length > 0 && activeStep !== 0}
                        loading={submitting}
                        endIcon={<></>}
                        loadingPosition={'end'}
                        onClick={handleSubmit(onSubmit)}>
                        Update
                    </LoadingButton>

                </Box>
            </PageContainer >
        </Box >
    )
}

export default OperatorUpdateFarmer