import React, { useCallback, useEffect, useState } from 'react'
import {
    Box,
    Button,
    Chip,
    InputAdornment,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
    alpha,
    useMediaQuery,
    useTheme, Autocomplete,
} from "@mui/material"
import { RemoveRedEyeRounded } from "@mui/icons-material"
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams, GridToolbar } from "@mui/x-data-grid"

import { ThemeDataTable } from "../../../components/theme-data-table-container"
import API from "../../../api"
import PageContainer from '../../../components/container/page-container'
import TitleBar from '../../../components/container/TitleBar'
import TableLoading from '../../../components/theme-table-container/TableLoading'
import NoDataView from '../../../components/theme-table-container/NoData-'
import { useNavigate } from 'react-router'
import ThemeDialog from '../../../components/dialog-box/theme-dialog'
import { Controller, useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { ThemeTextField, themeTextFieldSx } from '../../../components/inputs/theme-text-field'
import operatorIllustration from '../../../assets/images/illustartions/support.png'
import { SupportToggler } from '../../../components/inputs/support-toggler'
import { useDispatch, useSelector } from 'react-redux'
import { updateProcessState } from '../../../slices/process-slice'
import toast from 'react-hot-toast'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import moment, { Moment } from 'moment'
import { camelCaseWords } from "../../../utils/app-helper";
import { useAssets } from '../../../hooks/useAssets'
import { RootState } from '../../../store/store'
import { User } from '../../../models/user'


export default function Assets() {

    const theme = useTheme()
    const { assets, fetchAssets, fetchingAssets } = useAssets()

    const [asset, setAsset] = useState({})
    const [assetDetailType, setAssetDetailType] = useState<'create' | 'edit'>('create')
    const [assetDetailDialogOpen, setAssetDetailDialogOpen] = useState(false)
    const closeAssetDetailDialog = () => {
        setAssetDetailDialogOpen(false)
        setAssetDetailType('create')
        setAsset({})
    }

    useEffect(() => {
        fetchAssets()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Assets'} secondChild={<CreateOptions
                closeDialog={closeAssetDetailDialog}
                fetchAssets={fetchAssets}
                setAssetDetailDialogOpen={setAssetDetailDialogOpen} />} />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    '& .buttonGradient': {
                        backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        outline: `0px solid ${theme.palette.primary.main}`,
                        outlineOffset: 0,
                        boxShadow: 0,
                        textTransform: 'none',
                        transition: 'all 100ms ease',
                        '&:hover': {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: '2px',
                            '&:active': {
                                transform: 'scale(0.98) !important'
                            }
                        },
                    },

                }}>
                <AssetsTable
                    data={assets}
                    fetchingAssets={fetchingAssets}
                    setAsset={setAsset}
                    setAssetDetailDialogOpen={setAssetDetailDialogOpen}
                    setAssetDetailType={setAssetDetailType}
                />
            </Box>

            {assetDetailDialogOpen &&
                <ThemeDialog
                    open={assetDetailDialogOpen}
                    dialogBody={
                        <AssetDetailForm
                            closeDialog={closeAssetDetailDialog}
                            type={assetDetailType}
                            asset={asset}
                            fetchAssets={fetchAssets}
                        />
                    } />
            }
        </PageContainer>
    )
}


const AssetsTable = (props: any) => {

    const navigate = useNavigate()
    const { data, fetchingAssets, setAsset, setAssetDetailDialogOpen, setAssetDetailType } = props
    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser
    const columns: GridColDef[] = [
        {
            field: 'code', headerName: 'Code', width: 120,
            renderCell: (params: GridRenderCellParams) =>
                <Chip
                    size={'small'}
                    variant='outlined'
                    label={params.row?.asset_id}
                    color={params.row?.status == '1' ? 'success' : 'error'}
                    sx={{
                        fontSize: '12px',
                        lineHeight: '12px',
                        minWidth: '60px',
                        p: '2px',
                        height: '18px',
                        textTransform: 'capitalize',
                        boxShadow: `0 0 12px -4px currentcolor inset`,
                    }} />
        },
        { field: 'asset_name', headerName: 'Name', flex: 1, minWidth: 160 },
        { field: 'model', headerName: 'Model', flex: 1, minWidth: 160 },
        { field: 'uin', headerName: 'UIN', flex: 1, minWidth: 160 },
        { field: 'mfg_year', headerName: 'Make Year', flex: 1, minWidth: 120 },
        {
            field: 'asset_capacity', headerName: 'Chemical Capacity (ltr)', flex: 1, minWidth: 100, headerAlign: 'right',
            renderCell: (params: GridRenderCellParams) => (
                <Typography sx={{ textAlign: 'right', flex: 1, fontSize: '14px', pr: 2, }}>{(+params.value)?.toFixed(2)}</Typography>
            )
        },
        {
            field: 'asset_spray_capacity', headerName: 'Spray Capacity (acre)', flex: 1, minWidth: 100, headerAlign: 'right',
            renderCell: (params: GridRenderCellParams) => (
                <Typography sx={{ textAlign: 'right', flex: 1, fontSize: '14px', pr: 2, }}>{(+params.value)?.toFixed(2)}</Typography>
            )
        },
        {
            field: 'battery_ids', headerName: 'Assigned Batteries', flex: 1, minWidth: 150,
            renderCell: (params: GridRenderCellParams) => (
                <Typography sx={{ flex: 1, fontSize: '14px', pr: 2, }}>{params.value?.length > 0 ? params.value : '-'}</Typography>
            )
        },
        {
            field: 'owned_by_name', headerName: 'Provider Name', flex: 1, minWidth: 150,
            renderCell: (params: GridRenderCellParams) => (
                <Typography sx={{ flex: 1, fontSize: '14px', pr: 2, textTransform: 'uppercase' }}>{params.value}</Typography>
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            type: 'actions',
            cellClassName: 'stickyRight',
            headerClassName: 'stickyLeft',
            getActions: (params: GridRowParams<any>) => [
                <Tooltip title={`View ${params.row?.asset_id}`} arrow>
                    <GridActionsCellItem
                        icon={<RemoveRedEyeRounded />}
                        label="View Asset"
                        onClick={() => {
                            if (params.row?.status == '0') {
                                toast.error(`Can't view inactive asset`)
                            } else if (currentUser?.provider_id != params.row?.owned_by_id) {
                                toast.error(`Sorry, Not owned by you.`)
                            } else {
                                setAsset(params.row)
                                setAssetDetailDialogOpen(true)
                                setAssetDetailType('edit')
                            }
                        }}
                    />
                </Tooltip>,
            ],
        },
    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetchingAssets}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}

const CreateOptions = (props: any) => {

    const { closeDialog, fetchAssets, setAssetDetailDialogOpen } = props

    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <Button
                id="basic-button"
                variant='contained'
                className={'buttonGradient animate__animated animate__fadeInUp'}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                Add New
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    setAssetDetailDialogOpen(true)
                    handleClose()
                }}>Create</MenuItem>
                <MenuItem onClick={handleClose}>Import</MenuItem>
            </Menu>
        </div >
    )
}


const ImportFormDialog = () => {

    return (
        <Box>
        </Box>
    )
}


const AssetDetailForm = (props: any) => {

    const { closeDialog, type, asset, fetchAssets } = props

    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector((state: RootState) => state.userAuth.currentUser)

    const [fetchedBatteries, setFetchedBatteries] = useState<any[]>([])

    const [selectedBatteries, setSelectedBatteries] = useState<any[]>([])
    const [assetStatus, setAssetStatus] = useState<string>('active')

    const { control, handleSubmit, setError, setValue, clearErrors, register, reset, formState: { errors } } = useForm(
        {
            defaultValues: {
                uin: asset?.uin ?? '',
                model: asset?.model ?? '',
                mfg_year: asset?.mfg_year ?? '',
                asset_capacity: asset?.asset_capacity ?? '',
                asset_spray_capacity: asset?.asset_spray_capacity ?? '',
                battery_ids: asset?.battery_ids ?? '',
            }
        }
    )

    const [submitting, setSubmitting] = useState<boolean>(false)

    const availableBatteries = () => {
        dispatch(updateProcessState(true))
        API.get('/get_batteries_to_assign')
            .then((res) => {
                console.log('battery', res.data)
                if (res.data?.data?.length > 0) {
                    const modifiedBatteries = res.data?.data.reduce((accumulator: any[], currentValue: any) => {
                        const numericPart = currentValue.battery_id.match(/\d+/)[0];
                        const existingGroup = accumulator.find((group) => group.numericPart === numericPart);
                        if (existingGroup) existingGroup.ids.push(currentValue.id);
                        else accumulator.push({ numericPart: numericPart, ids: [currentValue.id], assignStatus: currentValue.assign_status });
                        return accumulator;
                    }, []);

                    console.log('modifiedBatteries', modifiedBatteries)
                    const batteryItems = modifiedBatteries.map((group: any) => ({ label: group.numericPart, value: group.ids.join(','), assignStatus: group.assignStatus }))
                    setFetchedBatteries(batteryItems)
                    setSelectedBatteries(batteryItems.filter((item: any) => {
                        const filterList = `${asset?.battery_ids}`.split(',').map((filterItem: any) => filterItem.trim());
                        const itemValues = item.value.split(',').map((v: any) => v.trim());
                        return filterList.some((filterItem: any) => itemValues.includes(filterItem))
                    }))
                } else {
                    setFetchedBatteries([]);
                }

            })
            .catch((err) => console.log(err))
            .finally(() => {
                dispatch(updateProcessState(false))
            })
    }

    const removeAsset = () => {
        API.post('/delete_asset', { id: asset?.id })
            .then((res) => {
                if (res.data?.statuscode == '200') {
                    toast.success('Asset removed successfully!')
                    reset()
                    closeDialog()
                    fetchAssets()
                }
                if (res.data?.status == 'error') {
                    toast.error(`Error! \n ${res.data?.msg}`)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setSubmitting(false)
                dispatch(updateProcessState(false))
            })
    }

    const onSubmit = (data: any) => {

        dispatch(updateProcessState(true))
        setSubmitting(true)
        if (assetStatus == 'inactive') {
            removeAsset()
        } else {
            if (type == 'create') {
                const { battery_ids, mfg_year, ...restData } = data
                const newData = {
                    asset_details: {
                        ...restData,
                        mfg_year: +moment(data.mfg_year).format('YYYY'),
                        battery_ids: selectedBatteries.map((item: any) => item.value).join(',')
                    }
                }
                console.log('newData', newData)
                API.post('/submit_asset_details', { ...newData })
                    .then((res) => {
                        if (res.data?.statuscode == '200') {
                            toast.success('Asset created successfully!')
                            reset()
                            closeDialog()
                            fetchAssets()
                        }
                        if (res.data?.status == 'error') {
                            toast.error(`Error! \n ${res.data?.msg}`)
                        }
                    })
                    .catch((err) => console.log(err))
                    .finally(() => {
                        setSubmitting(false)
                        dispatch(updateProcessState(false))
                    })
            } else if (type == 'edit') {
                const { battery_ids, mfg_year, ...restData } = data
                const newData = {
                    asset_details: {
                        ...restData,
                        id: asset?.id,
                        mfg_year: +moment(data.mfg_year).format('YYYY'),
                        battery_ids: selectedBatteries.map((item: any) => item.value).join(',')
                    }
                }
                console.log('newData', newData)
                API.post('/edit_asset', { ...newData })
                    .then((res) => {
                        if (res.data?.statuscode == '200') {
                            toast.success('Asset created successfully!')
                            reset()
                            closeDialog()
                            fetchAssets()
                        }
                        if (res.data?.status == 'error') {
                            toast.error(`Error! \n ${res.data?.msg}`)
                        }
                    })
                    .catch((err) => console.log(err))
                    .finally(() => {
                        setSubmitting(false)
                        dispatch(updateProcessState(false))
                    })
            }
        }
    }

    const releaseAsset = () => {
        if ((user?.role?.name == 'admin' || user?.role?.name == 'super admin') && asset?.assigned_status == '1') {
            dispatch(updateProcessState(true))
            API.post('/remove_asset_assign_status', { asset_id: asset?.id })
                .then((res) => {
                    if (res.data?.status == 'success') {
                        toast.success('Asset released successfully!')
                        closeDialog()
                        fetchAssets()
                    }
                    if (res.data?.status == 'error') {
                        toast.error(`Error! \n ${res.data?.msg}`)
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    dispatch(updateProcessState(false))
                })
        }
    }

    useEffect(() => {
        availableBatteries()
    }, [])
    return (
        <Box
            sx={{
                minWidth: '280px',
                width: { xs: '100%', md: '70vw' },
                maxWidth: '650px',
                minHeight: '200px',
                '& .boxTitle': {
                    fontWeight: 500,
                    fontSize: '18px',
                    mb: 3,
                },
                '& .formInput': {
                    flex: '1 1 260px',
                },
            }}>

            <Typography className={'boxTitle'} onClick={() => console.log(fetchedBatteries)}>
                {type == 'edit'
                    ? 'Update Asset'
                    : 'Create Asset'
                }
            </Typography>

            <Box component={'form'}
                sx={{
                    '& .actionBox': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        mt: 3,
                        '& .MuiButton-root': {
                            textTransform: 'none',
                            minWidth: { xs: 'auto', md: '150px' },
                            flex: { xs: 1, md: 0 },
                            '&.buttonGradient': {
                                backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                outline: `0px solid ${theme.palette.primary.main}`,
                                outlineOffset: 0,
                                boxShadow: 0,
                                transition: 'all 100ms ease',
                                '&:hover': {
                                    outline: `2px solid ${theme.palette.primary.main}`,
                                    outlineOffset: '2px',
                                    '&:active': {
                                        transform: 'scale(0.98) !important'
                                    }
                                },
                            },
                        },
                    },
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 3,
                        mx: { xs: 0, sm: 3, md: 5 },
                        '& .MuiTextField-root': {
                            '&.date': {
                                marginBottom: '24px !important'
                            },
                        },
                    }}>
                    <Box
                        sx={{
                            flex: '1 1 300px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                            '& img': {
                                objectFit: 'contain',
                                maxHeight: '120px',
                                maxWidth: '90%',
                            },
                            '& .assetDetail': {
                                flex: '1 1 200px',
                                display: 'flex',
                                flexFlow: 'column',
                                alignItems: 'flex-end',
                                justifyContent: 'center',
                                '& .detail': {
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: theme.typography.h1.fontFamily,
                                    color: theme.palette.text.secondary,
                                },

                            },
                        }}>
                        <img src={operatorIllustration} alt={'a'} />
                        {type == 'edit' &&
                            <Box className={'assetDetail'}>
                                <Box
                                    sx={{
                                        boxShadow: `0 0 12px -3px #83838390`,
                                        borderRadius: '16px',
                                        p: '4px',
                                        textAlign: 'center',
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        width: '100px',
                                        mb: 1,
                                        '& .MuiInput-root': {
                                            color: theme.palette.primary.contrastText,
                                            background: assetStatus == 'active'
                                                ? `${alpha(theme.palette.success.main, 1)} !important`
                                                : `${alpha(theme.palette.error.main, 1)} !important`,
                                            borderRadius: '12px',
                                            '&:before, &:after': {
                                                display: 'none',
                                            },
                                            '& .MuiSelect-select': {
                                                borderRadius: '12px',
                                                padding: '2px 16px 4px 12px',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                fontWeight: 500,
                                            },
                                        },

                                    }}>
                                    <TextField
                                        required select variant="standard" value={assetStatus}
                                        onChange={(e) => setAssetStatus(e.target.value)}
                                        size={'small'} placeholder={'Choose option'}
                                        sx={{ flex: 1 }}
                                    >
                                        <MenuItem key={'active'} value={'active'}>Active</MenuItem>
                                        <MenuItem key={'inactive'} value={'inactive'}>In-active</MenuItem>
                                    </TextField>
                                </Box>
                                <Typography className={'detail'}>Code : <span>{asset?.asset_id}</span></Typography>
                                <Typography className={'detail'}>UIN : <span>{asset?.uin}</span></Typography>
                                {(user?.role?.name == 'admin' || user?.role?.name == 'super admin') &&
                                    <Tooltip title={asset?.assigned_status == '1' ? 'Click to release' : ''} placement='top' arrow>
                                        <Button
                                            onClick={releaseAsset}
                                            size='small'
                                            sx={{
                                                textTransform: 'none',
                                                background: alpha(theme.palette.warning.main, 0.1),
                                                color: theme.palette.warning.main,
                                                px: 2,
                                                '&:hover': {
                                                    color: theme.palette.background.default,
                                                    background: theme.palette.warning.main,

                                                },
                                            }}>
                                            {asset?.assigned_status == '1' ? 'Assigned' : 'Unassigned'}
                                        </Button>
                                    </Tooltip>
                                }
                            </Box>
                        }
                    </Box>
                    <Box sx={{
                        flex: '1 1 300px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        columnGap: 3,
                    }}>
                        {!asset?.id &&
                            <Controller name={`uin`}
                                control={control}
                                rules={{ required: { value: true, message: 'Required' } }}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} required className={'formInput'}
                                        // sx={{ width: '100%' }}
                                        error={Boolean(errors?.uin)}
                                        helperText={(errors?.uin?.message ?? '').toString()}
                                        size={'small'} label={'UIN'}
                                        placeholder={'eg. qwert1234'}
                                    />
                                )} />
                        }
                        <Controller name={`model`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.model)}
                                    helperText={(errors?.model?.message ?? '').toString()}
                                    size={'small'} label={'Model'}
                                    placeholder={'eg. agribot'}
                                />
                            )} />
                        <Controller name="mfg_year"
                            control={control}
                            defaultValue={null}
                            rules={{
                                required: { value: true, message: 'Required' },
                            }}
                            render={({ field }) => (
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <MobileDatePicker
                                        {...field}
                                        disableFuture
                                        label="Make Year"
                                        className={'formInput date'}
                                        openTo="year"
                                        views={['year']}
                                        format="YYYY"
                                        value={field.value ? moment(field.value) : null}
                                        onChange={(date) => {
                                            setValue('mfg_year', date);
                                            clearErrors('mfg_year')
                                        }}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                sx: themeTextFieldSx,
                                                style: { width: '100%' },
                                                error: Boolean(errors?.mfg_year),
                                                helperText: errors?.mfg_year?.message?.toString() ?? '',
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                        <Controller name={`asset_capacity`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.asset_capacity)} type={'number'}
                                    helperText={(errors?.asset_capacity?.message ?? '').toString()}
                                    size={'small'} label={'Tank Capacity'}
                                    onBlur={(e) => {
                                        setValue(`asset_capacity`, `${parseFloat((+e.target.value).toFixed(2))}`)
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">liters</InputAdornment>,
                                    }}
                                    placeholder={'eg. 12'}
                                />
                            )} />

                        <Controller name={`asset_spray_capacity`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.asset_spray_capacity)} type={'number'}
                                    helperText={(errors?.asset_spray_capacity?.message ?? '').toString()}
                                    size={'small'} label={'Flying Capacity'}
                                    onBlur={(e) => {
                                        setValue(`asset_spray_capacity`, `${parseFloat((+e.target.value).toFixed(2))}`)
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">Acreage</InputAdornment>,
                                    }}
                                    placeholder={'eg. 12'}
                                />
                            )} />

                        <Autocomplete
                            multiple
                            size="small"
                            value={selectedBatteries}
                            onChange={(e, data) => {
                                console.log(data)
                                console.log(selectedBatteries)
                                console.log(fetchedBatteries)
                                setSelectedBatteries(data)
                            }}
                            options={fetchedBatteries}
                            getOptionLabel={(option) => option.label}
                            getOptionDisabled={option =>
                                option?.assignStatus == '1'
                                &&
                                (asset?.battery_ids != ''
                                    ? !option.value?.includes(`${asset?.battery_ids}`)
                                    : true)
                            }
                            filterSelectedOptions
                            className={'formInput'}
                            renderInput={(params) => (
                                <ThemeTextField
                                    {...params}
                                    size={'small'} label={'Batteries'}
                                    placeholder={'Please select batteries'}
                                />
                            )}
                        />
                    </Box>
                </Box>

                <Box className={'actionBox'}>
                    <Button className={''} variant={'outlined'} onClick={closeDialog}>Discard & Close</Button>
                    <LoadingButton loading={submitting} endIcon={<></>} loadingPosition={'end'}
                        className={'buttonGradient'} variant={'contained'}
                        onClick={handleSubmit(onSubmit)}>
                        {type.type == 'create' ? 'Save & Create' : 'Save & Update'}
                    </LoadingButton>
                </Box>

            </Box>
        </Box >
    )
}