import React, { Suspense, useEffect, useState } from 'react'
import { alpha, Box, Button, Chip, Divider, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import API from '../../api'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import dp from '../../assets/images/app-icons/agri-wing-logo.svg'
import { IdIcon, MailIcon, TagIcon } from '../../components/icons'
import { ArrowRight } from '@mui/icons-material'
import { DetailItem } from '../my-account'

import avatar from '../../assets/images/illustartions/avatar-1.png'
import ThemeDialog from '../../components/dialog-box/theme-dialog'
import LoadingView from '../../components/loading-view'
import ViewUserAttendance from '../hr/attendance/viewUserAttendance'

function MyAssociates() {

    const theme = useTheme()

    const user = useSelector((state: RootState) => state?.userAuth?.currentUser)

    const smallScreen = useMediaQuery('(max-width:600px)')

    const [team, setTeam] = useState<any[]>([])
    const [selectedUser, setSelectedUser] = useState<any | null>(null)

    const unselectUser = () => setSelectedUser(null)

    const fetchTeam = () => {
        API.post('/get_assigned_employees', { supervisor_id: `${user?.id}` })
            .then((res) => {
                setTeam(res.data?.data[0]?.assigned_employees)
                console.log(res.data)
            })
            .catch((err) => console.error(err))
    }

    useEffect(() => {
        fetchTeam()
    }, [])

    return (
        <>
            <Box
                sx={{
                    flex: 1,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                    gap: 3,
                }}>
                {team?.map((member: any, i: number) => <UserBlock key={i} member={member} viewAttendnace={() => setSelectedUser(member)} />)}
            </Box>

            {!!selectedUser &&
                <ThemeDialog
                    size={smallScreen ? "large" : 'small'}
                    dialogBody={
                        <Box sx={{
                            minHeight: '400px',
                            width: { xs: 'auto', sm: 'min(80vw, 600px)' }
                        }}>
                            <Suspense fallback={<LoadingView />}>
                                <ViewUserAttendance userId={btoa(`${selectedUser?.id}`)} multiView showUser />
                            </Suspense>
                        </Box>
                    }
                    open={!!selectedUser}
                    dialogAction={
                        <Typography
                            variant='caption'
                            onClick={unselectUser}
                            sx={{
                                cursor: 'pointer',
                                userSelect: 'none',
                            }}
                        >
                            Discard & Close
                        </Typography>
                    }
                />

            }

        </>
    )
}

export default MyAssociates

const UserBlock = (props: any) => {

    const { member, viewAttendnace } = props

    const theme = useTheme()

    return (
        <Box className={'flexCenter'}
            sx={{
                flexFlow: 'column',
                background: alpha(theme.palette.primary.main, 0.1),
                borderRadius: '2.5rem',
                p: '1rem',
                transition: 'all 200ms ease-in-out',
                userSelect: 'none',
                '&:hover': {
                    borderRadius: '2rem',
                    '& .detailBox': {
                        borderRadius: '1rem',
                    },
                },
                '& img': {
                    height: '6rem',
                    width: '6rem',
                    objectFit: 'contain',
                    background: theme.palette.background.default,
                    borderRadius: '50vh',
                },
                '& .name': {
                    fontWeight: 600,
                    fontFamily: theme.typography.h1.fontFamily,
                    fontSize: theme.typography.pxToRem(16),
                    color: theme.palette.text.primary,
                    mb: 0.5, pl: 1,
                },
                '& .detailBox': {
                    mt: 1,
                    flexFlow: 'column',
                    background: theme.palette.background.default,
                    borderRadius: '1.5rem',
                    pt: 2, pr: 1, pl: 2,
                    alignSelf: 'stretch',
                    gap: 0.5,
                    transition: 'all 200ms ease-in-out',
                },
                '& .detailLine': {
                    fontSize: theme.typography.pxToRem(14),
                    gap: 1,
                    fontWeight: 600,
                    overflow: 'hidden',
                    '&.small': {
                        fontSize: theme.typography.pxToRem(12),
                        '& svg': {
                            height: '14px',
                            width: '14px',
                        },
                    },
                    '& svg': {
                        height: '18px',
                        width: '18px',
                        color: theme.palette.text.disabled,
                    },
                    '& .seperator': {
                        color: theme.palette.text.disabled,
                        fontWeight: 400,
                    },
                },
                '& .link': {
                    maxWidth: 'max-content',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.3,
                    ml: 1.5,
                    color: theme.palette.info.main,
                    transition: 'all 200ms ease-in-out',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    '& svg': {
                        opacity: 0,
                        transition: 'all 200ms ease-in-out',
                    },
                    '&:hover': {
                        color: theme.palette.info.dark,
                        gap: 0.8,
                        '& svg': {
                            opacity: 1,
                        },
                    },
                },
            }}>
            <img src={avatar} alt="avatar" />
            <Typography className={'name line-clamp clamp-1'}>{(member?.name ?? '').toUpperCase()}</Typography>

            <Box className={'flexTopTop detailBox'} >
                <Tooltip title={'Employee Code'}><Typography className={'flexTopCenter detailLine small'}><IdIcon /><span className={'seperator'}>|</span>{(member?.emp_id ?? '')?.toUpperCase()}</Typography></Tooltip>
                <Tooltip title={'Role'}><Typography className={'flexTopCenter detailLine small'}><TagIcon /><span className={'seperator'}>|</span>{(member?.role == 'operator' && member?.is_supervisor_head == '1') ? 'CAPTAIN' : member?.role?.toUpperCase()}</Typography></Tooltip>
                <Tooltip title={'Email'}><Typography className={'flexTopCenter detailLine small'}><MailIcon /><span className={'seperator'}>|</span> {member?.email}</Typography></Tooltip>

                <Box className={'flexCenter'} sx={{
                    minWidth: '100%',
                    my: 1,
                    borderTop: '1px solid #83838360',
                    pt: 1,
                }}>
                    <Typography onClick={viewAttendnace} className={'link'}>View Attendnace <ArrowRight /></Typography>
                </Box>
            </Box>


        </Box>
    )
}
