import { CheckRounded, ClearRounded, EditRounded } from "@mui/icons-material";
import { Box, IconButton, Typography, alpha, useTheme } from "@mui/material";
import { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";

const SignaturePad = (props: any) => {

    const { imageData, setImageData, label } = props
    const theme = useTheme()

    const canvasRef = useRef<SignatureCanvas | null>(null);

    const handleSignature = () => canvasRef.current && setImageData(canvasRef.current.toDataURL())

    const clearSignature = () => {
        if (canvasRef.current) {
            canvasRef.current.clear();
            setImageData(null);
        }
    };

    return (
        <Box
            sx={{
                padding: '0.5rem 0',
                borderRadius: '18px',
                background: theme.palette.mode == 'dark'
                    ? '#0e0e0e'
                    : `linear-gradient(145deg, ${alpha(theme.palette.primary.main, 0.07)}, ${theme.palette.background.paper})`,
                boxShadow: theme.palette.mode == 'dark' ? 'none' : `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                border: `2px solid ${theme.palette.background.paper}`,
                position: 'relative',
                display: 'flex',
                flexWrap: 'wrap',
                alignIems: 'center',
                pt: 2,
                justifyContent: 'center',
                '& .imageCanvas, & img': {
                    height: '160px',
                    width: '96%',
                    background: '#fff',
                    borderRadius: '12px',
                },
                '& .imageCanvas': {
                    display: !imageData ? 'block' : 'none'
                },
                '& img': {
                    display: imageData ? 'block' : 'none'

                },
            }}>
            <Typography sx={{
                fontSize: '10px',
                lineHeight: '12px',
                mb: 0,
                fontFamily: theme.typography.h1.fontFamily,
                fontWeight: 500,
                position: 'absolute',
                top: '-0.5rem',
                left: '1rem',
                background: theme.palette.background.default,
                boxShadow: `0 0 12px -3px currentcolor inset`,
                color: theme.palette.primary.main,
                p: '2px 8px',
                borderRadius: '50vh',
            }}>
                {label ? label : 'Signature'}
            </Typography>

            <SignatureCanvas penColor="green" ref={canvasRef} canvasProps={{ className: 'imageCanvas' }} />
            <img src={imageData} alt="image Data" />


            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1,
                    mt: -4,
                    '& .MuiIconButton-root': {
                        '& svg': {
                            height: '1.2rem',
                            width: '1.2rem',
                        },
                        '&.add': {
                            display: imageData ? 'none' : 'inline',
                        },
                        '&.edit': {
                            display: !imageData ? 'none' : 'inline',
                        },
                    },
                }}>
                {imageData
                    ? <IconButton size='small' color='success' onClick={clearSignature}><EditRounded /></IconButton>
                    : <>
                        <IconButton size='small' color='error' onClick={clearSignature}><ClearRounded /></IconButton>
                        <IconButton size='small' color='success' onClick={handleSignature}><CheckRounded /></IconButton>
                    </>
                }
            </Box>
        </Box>
    )
}

export default SignaturePad
