import React, { useCallback, useEffect, useState } from 'react'
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    CircularProgress,
    InputAdornment,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
    alpha,
    useMediaQuery,
    useTheme
} from "@mui/material"
import { RemoveRedEyeRounded } from "@mui/icons-material"
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams, GridToolbar } from "@mui/x-data-grid"

import { ThemeDataTable } from "../../../components/theme-data-table-container"
import API from "../../../api"
import PageContainer from '../../../components/container/page-container'
import TitleBar from '../../../components/container/TitleBar'
import TableLoading from '../../../components/theme-table-container/TableLoading'
import NoDataView from '../../../components/theme-table-container/NoData-'
import { useNavigate } from 'react-router'
import { updateProcessState } from '../../../slices/process-slice'
import { useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { camelCaseWords, capitalizeWords } from '../../../utils/app-helper'
import operatorIllustration from '../../../assets/images/illustartions/support.png'
import { Controller, useForm } from 'react-hook-form'
import { ThemeTextField } from '../../../components/inputs/theme-text-field'
import { LoadingButton } from '@mui/lab'
import ThemeDialog from '../../../components/dialog-box/theme-dialog'
import { stateList } from '../../../utils/sample-data'
import LoadingView from '../../../components/loading-view'


export default function BaseClientList() {

    const theme = useTheme()
    const dispatch = useDispatch()
    const [clients, setClients] = useState<any[]>([])
    const [fetchingClients, setFetchingClients] = useState(true)
    const [regClientDetailType, setRegClientDetailType] = useState<'create' | 'edit'>('create')
    const [regClientDetailDialogOpen, setRegClientDialogOpen] = useState(false)
    const [regionalClient, setRegionalClient] = useState({} as any)

    const closeRegClientDetailDialog = () => {
        setRegClientDialogOpen(false)
        setRegClientDetailType('create')
        setRegionalClient({})
    }

    const fetchClients = useCallback(() => {
        setFetchingClients(true)
        dispatch(updateProcessState(true));
        API.get(`/get_all_base_clients`)
            .then((res) => {
                if (res.data?.statuscode == "200") {
                    setClients(res.data?.data)
                }
            })
            .catch((err) => {
                toast.success("Oops! Something bad at our end");
            })
            .finally(() => {
                setFetchingClients(false)
                dispatch(updateProcessState(false))
            });
    }, [])


    useEffect(() => {
        fetchClients()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Base Clients'} secondChild={<CreateOptions setRegClientDialogOpen={setRegClientDialogOpen} setRegClientDetailType={setRegClientDetailType} />} />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    '& .buttonGradient': {
                        backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        outline: `0px solid ${theme.palette.primary.main}`,
                        outlineOffset: 0,
                        boxShadow: 0,
                        textTransform: 'none',
                        transition: 'all 100ms ease',
                        '&:hover': {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: '2px',
                            '&:active': {
                                transform: 'scale(0.98) !important'
                            }
                        },
                    },

                }}>

                {!fetchingClients &&
                    <BaseClientsTable
                        data={clients}
                        fetchingClients={fetchingClients}
                        setRegionalClient={setRegionalClient}
                        setRegClientDialogOpen={setRegClientDialogOpen}
                        setRegClientDetailType={setRegClientDetailType}
                    />
                }

                {regClientDetailDialogOpen &&
                    <ThemeDialog
                        open={regClientDetailDialogOpen}
                        dialogBody={
                            <RegClientDetailForm
                                closeDialog={closeRegClientDetailDialog}
                                type={regClientDetailType}
                                regClient={regionalClient}
                                fetchAssets={fetchClients}
                            />
                        } />
                }
            </Box>
        </PageContainer>
    )
}


const BaseClientsTable = (props: any) => {

    const navigate = useNavigate()
    const { data, fetchingClients, setRegionalClient, setRegClientDetailType, setRegClientDialogOpen } = props

    const columns: GridColDef[] = [
        {
            field: 'client_name', headerName: 'Name', flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams<Date>) => (
                <Typography sx={{ fontSize: '14px', textTransform: 'capitalize' }}>{params.value}</Typography>
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            type: 'actions',
            cellClassName: 'stickyRight',
            headerClassName: 'stickyLeft',
            getActions: (params: GridRowParams<any>) => [
                <Tooltip title={`View ${params.row?.id}`} arrow>
                    <GridActionsCellItem
                        icon={<RemoveRedEyeRounded />}
                        label="View Farmer"
                        onClick={() => {
                            navigate(`${btoa(params.row?.id)}`)
                        }}
                    />
                </Tooltip>,
            ],
        },
    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetchingClients}
            disableRowSelectionOnClick

            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}

const CreateOptions = (props: any) => {

    const { setRegClientDialogOpen, setRegClientDetailType } = props
    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <Button
                id="basic-button"
                variant='contained'
                className={'buttonGradient animate__animated animate__fadeInUp'}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                Add New
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    navigate('create')
                }}>Create</MenuItem>
                <MenuItem onClick={handleClose}>Import</MenuItem>
            </Menu>
        </div >
    )
}

const ImportFormDialog = () => {

    return (
        <Box>
        </Box>
    )
}


const RegClientDetailForm = (props: any) => {

    const { closeDialog, type, regClient, fetchAssets } = props

    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [regionalClient, setRegionalClient] = useState(regClient)
    const [regClientStatus, setAssetStatus] = useState<string>('active')


    const [baseClients, setBaseClients] = useState<any[]>([])

    const { control, handleSubmit, setError, setValue, clearErrors, register, reset, formState: { errors } } = useForm({
        defaultValues: {
            base_client: regionalClient?.base_client ? '1' : '',
            gst_number: regionalClient?.gst_number ? '321432134' : '',
            state: regionalClient?.state ? { label: 'Andhra Pradesh', id: 'andhra_pradesh' } : null,
            address: regionalClient?.address ? 'fesdfesf' : '',
        }
    })

    const [submitting, setSubmitting] = useState<boolean>(false)

    const removeAsset = () => {
        API.post('/delete_regClient', { id: regClient?.id })
            .then((res) => {
                if (res.data?.statuscode == '200') {
                    toast.success('Asset removed successfully!')
                    reset()
                    closeDialog()
                    fetchAssets()
                }
                if (res.data?.status == 'error') {
                    toast.error(`Error! \n ${res.data?.msg}`)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setSubmitting(false)
                dispatch(updateProcessState(false))
            })
    }

    const onSubmit = (data: any) => {
        console.log(data)
        dispatch(updateProcessState(true))
        setSubmitting(true)
        setTimeout(() => {
            setSubmitting(false)
            dispatch(updateProcessState(false))
        }, 1500)

        // if (regClientStatus == 'inactive') {
        //     removeAsset()
        // } else {
        //     if (type == 'create') {
        //         // console.log(data.mfg_year)
        //         const newData = { regClient_details: { ...data } }
        //         API.post('/submit_regClient_details', { ...newData })
        //             .then((res) => {
        //                 if (res.data?.statuscode == '200') {
        //                     toast.success('Regional Client created successfully!')
        //                     reset()
        //                     closeDialog()
        //                     fetchAssets()
        //                 }
        //                 if (res.data?.status == 'error') {
        //                     toast.error(`Error! \n ${res.data?.msg}`)
        //                 }
        //             })
        //             .catch((err) => console.log(err))
        //             .finally(() => {
        //                 setSubmitting(false)
        //                 dispatch(updateProcessState(false))
        //             })
        //     } else if (type == 'edit') {
        //         const newData = { regClient_details: { ...data, id: regClient?.id } }
        //         API.post('/edit_regClient', { ...newData })
        //             .then((res) => {
        //                 if (res.data?.statuscode == '200') {
        //                     toast.success('Asset created successfully!')
        //                     reset()
        //                     closeDialog()
        //                     fetchAssets()
        //                 }
        //                 if (res.data?.status == 'error') {
        //                     toast.error(`Error! \n ${res.data?.msg}`)
        //                 }
        //             })
        //             .catch((err) => console.log(err))
        //             .finally(() => {
        //                 setSubmitting(false)
        //                 dispatch(updateProcessState(false))
        //             })
        //     }
        // }
    }


    useEffect(() => {
        setBaseClients([
            { id: '1', label: 'Corteva' },
            { id: '2', label: 'FMC' },
        ])
    }, [])


    useEffect(() => {
        if (type == 'edit') {
            setRegionalClient({
                base_client: '1',
                gst_number: 'qwert',
                state: { label: 'Andhra Pradesh', id: 'andhra_pradesh' },
                address: 'qwertyu',
            })
        }
    }, [])
    return (
        <Box
            sx={{
                minWidth: '280px',
                width: { xs: '100%', md: '70vw' },
                maxWidth: '650px',
                minHeight: '200px',
                '& .boxTitle': {
                    fontWeight: 500,
                    fontSize: '18px',
                    mb: 3,
                },
                '& .formInput': {
                    flex: '1 1 260px',
                },
            }}>

            <Typography className={'boxTitle'}>
                {type == 'edit'
                    ? 'Update Regional Client'
                    : 'Create Regional Client'
                }
            </Typography>

            <Box component={'form'}
                sx={{
                    '& .actionBox': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        mt: 3,
                        '& .MuiButton-root': {
                            textTransform: 'none',
                            minWidth: { xs: 'auto', md: '150px' },
                            flex: { xs: 1, md: 0 },
                            '&.buttonGradient': {
                                backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                outline: `0px solid ${theme.palette.primary.main}`,
                                outlineOffset: 0,
                                boxShadow: 0,
                                transition: 'all 100ms ease',
                                '&:hover': {
                                    outline: `2px solid ${theme.palette.primary.main}`,
                                    outlineOffset: '2px',
                                    '&:active': {
                                        transform: 'scale(0.98) !important'
                                    }
                                },
                            },
                        },
                    },
                }}>



                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 3,
                        mx: { xs: 0, sm: 3, md: 5 },
                    }}>

                    <Box
                        sx={{
                            flex: '1 1 300px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                            '& img': {
                                objectFit: 'contain',
                                maxHeight: '120px',
                                maxWidth: '90%',
                            },
                            '& .regClientDetail': {
                                flex: '1 1 200px',
                                display: 'flex',
                                flexFlow: 'column',
                                alignItems: 'flex-end',
                                justifyContent: 'center',
                                '& .detail': {
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: theme.typography.h1.fontFamily,
                                    color: theme.palette.text.secondary,
                                },

                            },
                        }}>
                        <img src={operatorIllustration} alt={'a'} />
                        {type == 'edit' &&
                            <Box className={'regClientDetail'}>
                                <Box
                                    sx={{
                                        boxShadow: `0 0 12px -3px #83838390`,
                                        borderRadius: '16px',
                                        p: '4px',
                                        textAlign: 'center',
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        width: '100px',
                                        mb: 1,
                                        '& .MuiInput-root': {
                                            color: theme.palette.primary.contrastText,
                                            background: regClientStatus == 'active'
                                                ? `${alpha(theme.palette.success.main, 1)} !important`
                                                : `${alpha(theme.palette.error.main, 1)} !important`,
                                            borderRadius: '12px',
                                            '&:before, &:after': {
                                                display: 'none',
                                            },
                                            '& .MuiSelect-select': {
                                                borderRadius: '12px',
                                                padding: '2px 16px 4px 12px',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                fontWeight: 500,
                                            },
                                        },

                                    }}>
                                    <TextField
                                        required select variant="standard" value={regClientStatus}
                                        onChange={(e) => setAssetStatus(e.target.value)}
                                        size={'small'} placeholder={'Choose option'}
                                        sx={{ flex: 1 }}
                                    >
                                        <MenuItem key={'active'} value={'active'}>Active</MenuItem>
                                        <MenuItem key={'inactive'} value={'inactive'}>In-active</MenuItem>
                                    </TextField>
                                </Box>
                            </Box>
                        }
                    </Box>
                    <Box sx={{
                        flex: '1 1 300px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        columnGap: 3,
                    }}>
                        <Controller name={`base_client`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.base_client)}
                                    disabled={type == 'edit'} select
                                    helperText={(errors?.base_client?.message ?? '').toString()}
                                    size={'small'} label={'Base Client'}
                                    placeholder={'eg. fmc'}
                                >
                                    {baseClients.map((client, index) => <MenuItem key={index} value={client.id}>{client.label}</MenuItem>)}
                                </ThemeTextField>
                            )} />
                        <Controller name={`state`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete size={'small'}
                                    autoComplete
                                    className={'formItem'}
                                    onChange={(e, data) => onChange(data)}
                                    noOptionsText={'No States Found'}
                                    value={value || null}
                                    sx={{ flex: '1 1 250px' }}
                                    options={stateList}
                                    getOptionLabel={(option) => option.label ?? ''}
                                    isOptionEqualToValue={((option: any, value: any) => option.id == value.id)}
                                    renderOption={(props, option, { selected }) => (
                                        <Box component={'li'} {...props} key={props.id}
                                            sx={{
                                                p: '2px 6px',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                justifyContent: 'flex-start',
                                                flexFlow: 'column',
                                                textAlign: 'left',
                                                gap: '2px',
                                                borderRadius: '12px',
                                                mx: '2px',
                                                '& .village': {
                                                    width: '100%',
                                                    fontSize: '15px',
                                                    lineHeight: '15px',
                                                    fontWeight: 500,
                                                    color: theme.palette.text.secondary,
                                                },
                                            }}>
                                            <Typography className={'village'}>
                                                {option?.label}
                                            </Typography>
                                        </Box>

                                    )}
                                    renderInput={(params) => (
                                        <ThemeTextField
                                            {...params} required
                                            error={Boolean(errors?.state)}
                                            helperText={(errors?.state?.message ?? '').toString()}
                                            size={'small'} label={'State'}
                                            placeholder={'Please select state'}
                                        />
                                    )} />
                            )} />
                        <Controller name={`gst_number`}
                            control={control}
                            rules={{
                                required: { value: true, message: 'Required' },
                                pattern: { value: /^([a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{1,10})$/, message: 'invalid gst' },
                            }}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.gst_number)}
                                    helperText={(errors?.gst_number?.message ?? '').toString()}
                                    size={'small'} label={'GSTIN'}
                                    placeholder={'****************'}
                                />
                            )} />
                        <Controller name={`address`}
                            control={control}
                            rules={{ required: { value: true, message: 'Required' } }}
                            render={({ field }) => (
                                <ThemeTextField
                                    {...field} required className={'formInput'}
                                    error={Boolean(errors?.address)}
                                    helperText={(errors?.address?.message ?? '').toString()}
                                    size={'small'} label={'Address'}
                                    placeholder={'eg. address here'}
                                />
                            )} />
                    </Box>
                </Box>



                <Box className={'actionBox'}>
                    <Button className={''} variant={'outlined'} onClick={closeDialog}>Discard & Close</Button>
                    <LoadingButton loading={submitting} endIcon={<></>} loadingPosition={'end'}
                        className={'buttonGradient'} variant={'contained'}
                        onClick={handleSubmit(onSubmit)}>
                        {type.type == 'create' ? 'Save & Create' : 'Save & Update'}
                    </LoadingButton>
                </Box>
            </Box>
        </Box>
    )
}