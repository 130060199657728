import React, { useCallback, useEffect, useState } from 'react'
import {
    Box,
    Button,
    Chip,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material"
import { CalendarMonthRounded, CalendarViewMonthRounded, RemoveRedEyeRounded } from "@mui/icons-material"
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams, GridToolbar } from "@mui/x-data-grid"

import { ThemeDataTable } from "../../../components/theme-data-table-container"
import API from "../../../api"
import PageContainer from '../../../components/container/page-container'
import TitleBar from '../../../components/container/TitleBar'
import TableLoading from '../../../components/theme-table-container/TableLoading'
import NoDataView from '../../../components/theme-table-container/NoData-'
import { useNavigate } from 'react-router'
import moment from 'moment'
import { Operator } from '../../../models/operator'
import { useDispatch, useSelector } from 'react-redux'
import { updateOperatorDetail } from '../../../slices/operator-detail-slice'
import { User } from '../../../models/user'
import { RootState } from '../../../store/store'
import toast from 'react-hot-toast'


export default function Operators() {

    const theme = useTheme()
    const [operators, setOperators] = useState<Operator[]>([])
    const [fetchingOperators, setFetchingOperators] = useState(false)


    const fetchOperators = useCallback(() => {
        setFetchingOperators(true)
        API.get('/get_all_operators', { headers: { "Accept": "application/json" } })
            .then((res) => {
                res.data.status == 'success' &&
                    setOperators(res.data?.data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => setFetchingOperators(false))
    }, [])

    useEffect(() => {
        fetchOperators()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Operators'} secondChild={<CreateOptions />} />

            <Box sx={{
                flex: 1,
                height: '100vh',
                maxHeight: 'calc(100vh - 189px)',
                transition: 'all 350ms ease-in-out',
                '& .buttonGradient': {
                    backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    outline: `0px solid ${theme.palette.primary.main}`,
                    outlineOffset: 0,
                    boxShadow: 0,
                    textTransform: 'none',
                    transition: 'all 100ms ease',
                    '&:hover': {
                        outline: `2px solid ${theme.palette.primary.main}`,
                        outlineOffset: '2px',
                        '&:active': {
                            transform: 'scale(0.98) !important'
                        }
                    },
                },

            }}>

                <OperatorsTable data={operators} fetchingOperators={fetchingOperators} />
            </Box>
        </PageContainer>
    )
}


const OperatorsTable = (props: any) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { data, fetchingOperators } = props
    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser

    const columns: GridColDef[] = [
        {
            field: 'code', headerName: 'Code', width: 120,
            renderCell: (params: GridRenderCellParams) =>
                <Chip
                    size={'small'}
                    variant='outlined'
                    label={params.row?.code}
                    color={params.row?.status == '1' ? 'success' : 'error'}
                    sx={{
                        fontSize: '12px',
                        lineHeight: '12px',
                        minWidth: '60px',
                        p: '2px',
                        height: '18px',
                        textTransform: 'capitalize',
                        boxShadow: `0 0 12px -4px currentcolor inset`,
                    }} />
        },
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 160 },
        {
            field: 'phone', headerName: 'Mobile', width: 160, flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams<String>) => (
                <Tooltip title={params.value != '' ? params.value : ''} arrow>
                    <Typography
                        sx={{ fontSize: '13px' }}>{params.value != '' ? `+91 ${params.value}` : '-na-'}</Typography>
                </Tooltip>
            )
        },
        {
            field: 'start_date', headerName: 'Joining Date', width: 160, flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams<Date>) => (
                <Typography sx={{ fontSize: '13px' }}>
                    <CalendarMonthRounded sx={{ fontSize: '12px' }} /> {moment(params.value).format('DD MMM YYYY')}
                </Typography>
            )
        },
        { field: 'rpc_no', headerName: 'RPC Number', flex: 1, minWidth: 160 },
        { field: 'dl_no', headerName: 'DL Number', flex: 1, minWidth: 160 },
        { field: 'aadhaar_no', headerName: 'Aadhar Number', flex: 1, minWidth: 160 },
        {
            field: 'end_date', headerName: 'End Date', width: 160, flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams<Date>) => (
                <Typography sx={{ fontSize: '13px' }}>
                    {params.value && <CalendarMonthRounded sx={{ fontSize: '12px' }} />} {params.value ?? '-'}
                </Typography>
            )
        },
        {
            field: 'owned_by_name', headerName: 'Provider Name', width: 160, flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams<Date>) => (
                <Typography sx={{ fontSize: '13px' }}>
                    {params.value ?? '-'}
                </Typography>
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            type: 'actions',
            cellClassName: 'stickyRight',
            headerClassName: 'stickyLeft',
            getActions: (params: GridRowParams<any>) => [
                <Tooltip title={(currentUser?.provider_id == params.row?.owned_by_id) ? `View ${params.row?.code}` : 'Not owned by you'} arrow>
                    <GridActionsCellItem
                        icon={<RemoveRedEyeRounded />}
                        label="View Farmer"
                        // disabled={currentUser?.provider_id != params.row?.owned_by_id}
                        onClick={() => {
                            if (currentUser?.provider_id == params.row?.owned_by_id) {
                                navigate(`${btoa(params.row?.id)}`)
                                dispatch(updateOperatorDetail({ selectedOperator: { ...params.row } as Operator }))
                            } else {
                                toast.error(`Sorry, Not owned by you.`)
                            }

                        }}
                    />
                </Tooltip>,
            ],
        },

        { field: 'status', headerName: 'Status', flex: 1, minWidth: 160 },

    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetchingOperators}
            disableColumnMenu
            disableRowSelectionOnClick
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            columnVisibilityModel={{
                status: false,
            }}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}

const CreateOptions = () => {
    const navigate = useNavigate()
    return (
        <Button
            variant='contained'
            className={'buttonGradient animate__animated animate__fadeInUp'}
            onClick={() => navigate('create')}
        >
            Add New
        </Button>
    )
}

const ImportFormDialog = () => {

    return (
        <Box>
        </Box>
    )
}
